import { Add } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { notification } from 'antd';
import CustomTableCell from '../../components/CustomTableCell';
import CustomTableLabel from '../../components/CustomTableLabel';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import CustomPagination from '../../components/Pagination/CustomPagination';
import {
  FeatureFlag,
  ListFeatureFlagsRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  listFeatureFlagsAction,
  setSelectedFeatureFlagAction,
} from '../../redux/actions/feature_flags.action';
import {
  featureFlagsListSelector,
  featureFlagsLoadingSelector,
  featureFlagsTotalSizeSelector,
  processingFeatureFlagSelector,
} from '../../redux/selectors/feature_flags.selectors';
import { ELLIPSIS_STYLE } from '../../utils/constants';
import { formatDate } from '../../utils/helpers';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));

const FeatureFlags: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featureFlags = useSelector(featureFlagsListSelector);
  const totalSize = useSelector(featureFlagsTotalSizeSelector);
  const loading = useSelector(featureFlagsLoadingSelector);
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const pagedFeatureFlags = featureFlags.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, contextHolder] = notification.useNotification();
  const processingFeatureFlag = useSelector(processingFeatureFlagSelector);

  useEffect(() => {
    setPage(0);
    const req = ListFeatureFlagsRequest.create({
      pageSize: rowsPerPage,
      page: page,
    });
    dispatch(listFeatureFlagsAction(req, true));
  }, []);

  const onTableRowClick = (featureFlag: FeatureFlag) => {
    navigate(`/feature-flags/${featureFlag.id}/update`);
    dispatch(setSelectedFeatureFlagAction(featureFlag));
  };

  const onCreateFeatureFlag = () => {
    navigate('/feature-flags/create');
  };

  return (
    <>
      <Box paddingX={'60px'} paddingY={'60px'}>
        {contextHolder}
        <Box display={'flex'} height={'40px'}>
          <Box flexGrow={1} display={'flex'} justifyContent={'end'}>
            <Box
              display={'flex'}
              component={'button'}
              sx={{ border: 'none', backgroundColor: 'transparent' }}
              alignItems={'center'}
              onClick={() => onCreateFeatureFlag()}
            >
              <CustomTypography
                typographyType={TypographyType.MediumPara}
                color='#1669F7'
                sx={{ cursor: 'pointer' }}
              >
                <Add sx={{ marginTop: '4px' }} />
              </CustomTypography>
              <CustomTypography
                typographyType={TypographyType.MediumPara}
                color='#1669F7'
                sx={{ cursor: 'pointer' }}
              >
                Create Feature Flag
              </CustomTypography>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}></Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          paddingTop={'30px'}
        >
          <CustomTypography typographyType={TypographyType.Header2}>
            Feature Flags
          </CustomTypography>
        </Box>
        <Box>
          {loading || processingFeatureFlag ? (
            <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer
                sx={{ marginTop: '30px' }}
                className='table-header-style'
              >
                <Table id={'pending-tasks-table'} sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                      <CustomTableCell title='Name'>
                        <CustomTableLabel label='Name' />
                      </CustomTableCell>
                      <CustomTableCell title='Description'>
                        <CustomTableLabel label='Description' />
                      </CustomTableCell>
                      <CustomTableCell title='Updated Time'>
                        <CustomTableLabel label='Updated Time' />
                      </CustomTableCell>
                      <CustomTableCell title='Updated By'>
                        <CustomTableLabel label='Updated By' />
                      </CustomTableCell>
                      <CustomTableCell title='Default Status'>
                        <CustomTableLabel label='Default Status' />
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pagedFeatureFlags.map((t, index) => (
                      <StyledTableRow
                        key={index}
                        tabIndex={0}
                        role='row'
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 !== 0 ? '#F6F6F6' : 'white',
                          ':hover': { backgroundColor: '#1669F74D' },
                          ':focus': { backgroundColor: '#1669F74D' },
                        }}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            onTableRowClick(t);
                          }
                        }}
                        onClick={() => onTableRowClick(t)}
                      >
                        <CustomTableCell>
                          <span title={t.name || 'NO DATA'}>
                            {t.name || 'NO DATA'}
                          </span>
                        </CustomTableCell>
                        <CustomTableCell>
                          <CustomTypography
                            typographyType={TypographyType.Label}
                            sx={{
                              ...ELLIPSIS_STYLE,
                            }}
                          >
                            {t.description || '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.updatedAt ? formatDate(t.updatedAt) : '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.updatedBy || '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.rule?.enabled ? 'Enabled' : 'Disabled'}
                          </CustomTypography>
                        </CustomTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {!pagedFeatureFlags.length && (
                  <CustomTypography
                    sx={{
                      textAlign: 'center',
                      textJustify: 'center',
                      color: '#475467',
                      marginTop: '40px',
                    }}
                  >
                    There are no feature flags available
                  </CustomTypography>
                )}
              </TableContainer>
              <CustomPagination
                isShowSelectFilter={false}
                rowsPerPage={rowsPerPage}
                totalSize={totalSize}
                page={page}
                onPageChange={(p) => {
                  setPage(p);
                  const req = ListFeatureFlagsRequest.create({
                    pageSize: rowsPerPage,
                    page: page,
                  });
                  dispatch(listFeatureFlagsAction(req));
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(FeatureFlags);
