import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import ReviewFilterBox from './components/ReviewFilterBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  infoToDetectChangesSelector,
  selectedTaskEntityInfoSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import { EntityFilter } from '../../../../../../utils/constants';
import {
  getNeedAttentionEntitiesCount,
  getPredictedEntitiesCount,
  getReviewedEntitiesCount,
} from '../../../../../../utils/ReviewTaskUtils';
import {
  setSelectedEntityIdAction,
  updateSelectedReviewFilterSection,
} from '../../../../../../redux/actions/review_task.action';
import { checkForUnsavedChanges } from '../../../../../../utils/UnsavedChangesUtils';

interface Props {
  selectedReviewFilterSection: EntityFilter | undefined;
}

const ReviewFilterSection: React.FC<Props> = ({
  selectedReviewFilterSection,
}) => {
  const dispatch = useDispatch();

  const entityInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);

  const needAttentionEntitiesCount = useMemo(
    () => getNeedAttentionEntitiesCount(entityInfoMap),
    [entityInfoMap],
  );

  const predictedEntitiesCount = useMemo(
    () => getPredictedEntitiesCount(entityInfoMap),
    [entityInfoMap],
  );

  const reviewedEntitiesCount = useMemo(
    () => getReviewedEntitiesCount(entityInfoMap),
    [entityInfoMap],
  );

  const handleClick = useCallback(
    (entityFilter: EntityFilter | null) => {
      // check if floating modal is open and have unsaved changes if yes show warning
      // block if unsaved changes are present
      if (checkForUnsavedChanges(infoToDetectChanges)) {
        return;
      }
      dispatch(setSelectedEntityIdAction(null));
      dispatch(
        updateSelectedReviewFilterSection(
          selectedReviewFilterSection === entityFilter ? null : entityFilter,
        ),
      );
    },
    [infoToDetectChanges, selectedReviewFilterSection],
  );

  return (
    <>
      <Box
        padding={'10px 0 5px 0'}
        borderBottom={'2px solid #F2F4F7'}
        marginBottom={'10px'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          role='tablist'
          width={'100%'}
          flexDirection={{
            xs: 'column',
            md: 'column',
            sm: 'column',
            lg: 'row',
          }}
          alignItems={'center'}
          padding={'0 10px'}
        >
          <Box
            sx={{
              width: '100%',
              padding: '0 2px',
              flex: 0.8,
            }}
          >
            <ReviewFilterBox
              label='Predictions'
              value='All'
              borderColor='#101828'
              valueStyle={{
                fontSize: '20px',
                fontWeight: '600',
              }}
              labelStyle={{
                paddingBottom: '4px',
                fontSize: '12px',
                fontWeight: '600',
              }}
              selected={selectedReviewFilterSection == null}
              onClick={() => {
                handleClick(null);
              }}
            />
          </Box>
          <Box sx={{ border: '2px solid #F2F4F7', height: '20px' }} />

          <Box
            sx={{
              width: '100%',
              padding: '0 2px',
              flex: 1,
            }}
          >
            <ReviewFilterBox
              label='Needs Attention'
              value={`${needAttentionEntitiesCount}`}
              borderColor='#DD6A6A'
              selected={
                selectedReviewFilterSection === EntityFilter.NEED_ATTENTION
              }
              valueStyle={{
                fontSize: '24px',
                fontWeight: '700',
              }}
              labelStyle={{
                paddingBottom: '4px',
                fontSize: '12px',
                fontWeight: '600',
              }}
              onClick={() => handleClick(EntityFilter.NEED_ATTENTION)}
            />
          </Box>
          <Box sx={{ border: '2px solid #F2F4F7', height: '20px' }} />

          <Box
            sx={{
              width: '100%',
              padding: '0 2px',
              flex: 0.8,
            }}
          >
            <ReviewFilterBox
              label='Predicted'
              value={`${predictedEntitiesCount}`}
              borderColor='#0500FF'
              selected={selectedReviewFilterSection === EntityFilter.PREDICTED}
              valueStyle={{
                fontSize: '24px',
                fontWeight: '700',
              }}
              labelStyle={{
                paddingBottom: '4px',
                fontSize: '12px',
                fontWeight: '600',
              }}
              onClick={() => handleClick(EntityFilter.PREDICTED)}
            />
          </Box>
          <Box sx={{ border: '2px solid #F2F4F7', height: '20px' }} />

          <Box
            sx={{
              width: '100%',
              padding: '0 2px',
              flex: 0.8,
            }}
          >
            <ReviewFilterBox
              label='Reviewed'
              value={`${reviewedEntitiesCount}`}
              borderColor='#3C6B61'
              selected={selectedReviewFilterSection === EntityFilter.REVIEWED}
              valueStyle={{
                fontSize: '24px',
                fontWeight: '700',
              }}
              labelStyle={{
                paddingBottom: '4px',
                fontSize: '12px',
                fontWeight: '600',
              }}
              onClick={() => handleClick(EntityFilter.REVIEWED)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(ReviewFilterSection);
