import {
  Workflow,
  WorkflowTaskStatus,
} from 'protos/pb/v1alpha1/orbot_workflow';
import { ExecutionInfo } from './types';
import {
  containConditionAction,
  containControlFlow,
  containForeachAction,
} from './action-classifier';
import { ControlType } from 'protos/pb/v1alpha1/element';
import {
  ActionGroup,
  ActionParamValue,
  ExecutedAction,
  PreparedAction,
} from 'protos/pb/v1alpha1/orbot_action';
import { ClickableWidgetInfo } from './types';
import { getActionGroupById } from './workflow';

export function cumulativeSum(values: number[]) {
  const sums: number[] = [];
  let sum = 0;
  for (let i = 0; i < values.length; i++) {
    sums[i] = sum;
    sum += values[i];
  }
  return sums;
}

export function getNumStepsForActions(
  actions: ActionGroup[] | undefined,
): number {
  return (actions || [])
    .map(getNumStepsForAction)
    .reduce((acc, val) => acc + val, 0);
}

// Here the numSteps is the number of steps we gonna show to users.
// And it implicitly assume that one actionGroup cannot contain multiple control flow actions.
export function getNumStepsForPreparedAction(action: PreparedAction): number {
  if (action.foreachAction) {
    return getNumStepsForActions(action.foreachAction.actions);
  } else if (action.conditionAction) {
    return (
      getNumStepsForActions(action.conditionAction.trueActions) +
      getNumStepsForActions(action.conditionAction.falseActions)
    );
  } else {
    // If one actionGroup has multiple preparedActions, we don't count.
    // In our new design, PreparedAction inside ActionGroup is for internal usage, we won't
    return 0;
  }
}

/**
 * Get the number of user-visible steps in an action group recursively.
 * - for ActionGroup with only one action, we only show the ActionGroup and it counts as one step
 * - for Foreach/Condition actions, it would be the number of steps in all blocks plus one.
 */
export function getNumStepsForAction(actionGroup: ActionGroup): number {
  let numSteps = 1; // the ActionGroup itself always counts
  const prepareActions = actionGroup.preparedActions;
  if (!prepareActions || !containControlFlow(prepareActions)) {
    return numSteps;
  }
  for (const prepareAction of prepareActions) {
    numSteps += getNumStepsForPreparedAction(prepareAction);
  }
  return numSteps;
}

export function getNumStepsForWorkflow(workflow: Workflow): number {
  if (workflow.taskExecution) {
    return getNumStepsForActions(workflow.taskExecution?.generatedActionGroups);
  } else {
    return workflow.processes!.reduce((numSteps, process) => {
      return numSteps + getNumStepsForActions(process.generatedActionGroups);
    }, 0);
  }
}

export function numOfStepsInConditionAction(
  action: PreparedAction,
  slicedExecutedActions: ExecutedAction[],
): number {
  if (!action.conditionAction) return -1;
  const trueActionGroups = action.conditionAction.trueActions;
  const falseActionGroups = action.conditionAction.falseActions;
  const conditionResult = getConditionResultFromExecutedActions(
    action!,
    slicedExecutedActions,
  );

  let actionGroups: ActionGroup[] | undefined;
  if (conditionResult) {
    actionGroups = trueActionGroups;
  } else {
    actionGroups = falseActionGroups;
  }

  let numSteps = 0;
  actionGroups?.forEach((actionGroup) => {
    const actions = actionGroup.preparedActions!;
    if (containConditionAction(actions)) {
      const conditionAction = actions.find((a) => a.conditionAction);
      // We don't need to slice executedActions because nested condition won't cause duplicated executions.
      numSteps += numOfStepsInConditionAction(
        conditionAction!,
        slicedExecutedActions,
      );
    }

    // We don't go inside foreach.
    // We need to count condition itself as one step.
    // Every other actionGroup is counted as one step.
    numSteps += 1;
  });
  return numSteps;
}

// One step means one actionGroup
// For condition action, we need to count trueActions or falseActions
// Count Foreach action as one, don't go inside.
export function numOfDirectStepsInForeachAction(
  action: PreparedAction,
  slicedExecutedActions: ExecutedAction[],
): number {
  if (!action.foreachAction) return -1;
  if (action.foreachAction.actions?.length === 0) return 0;
  const actionGroups = action.foreachAction.actions!;
  let numSteps = 0;
  actionGroups.forEach((actionGroup) => {
    const actions = actionGroup.preparedActions!;
    if (containForeachAction(actions)) {
      numSteps += 1;
    } else if (containConditionAction(actions)) {
      const conditionAction = actions.find((a) => a.conditionAction);
      numSteps +=
        numOfStepsInConditionAction(conditionAction!, slicedExecutedActions) +
        1;
    } else {
      numSteps += 1;
    }
  });
  return numSteps;
}

export const getStatusText = (status: WorkflowTaskStatus | undefined) => {
  switch (status) {
    case WorkflowTaskStatus.SUCCESS:
      return 'Completed';
    case WorkflowTaskStatus.FAIL:
      return 'Error';
    case WorkflowTaskStatus.EXECUTING:
      return 'Execution in progress';
    case WorkflowTaskStatus.PENDING:
      return 'Waiting for execution';
    case WorkflowTaskStatus.WAITING_FOR_REVIEW:
      return 'Pending review';
    case WorkflowTaskStatus.TERMINATED:
      return 'Terminated';
    default:
      return 'Unknown';
  }
};

export function getElementTypeText(type: ControlType): string {
  switch (type) {
    case ControlType.BUTTON:
      return 'Button';
    case ControlType.CHECKBOX:
      return 'Checkbox';
    case ControlType.LINK:
      return 'Link';
    case ControlType.TEXTBOX:
      return 'Input';
    case ControlType.SELECT:
      return 'Select';
    default:
      return 'Unknown';
  }
}

export function getParamValue(
  param: ActionParamValue,
  executedActions: ExecutedAction[],
): any {
  if (param.referenceValue) {
    return JSON.parse(
      executedActions.find((a) => a.preparedActionUuid === param.referenceValue)
        ?.outputValue || '""',
    );
  }

  if (param.partialReferenceValue) {
    const referenceOutput = JSON.parse(
      executedActions.find(
        (a) =>
          a.preparedActionUuid === param.partialReferenceValue?.referenceValue,
      )?.outputValue || '""',
    );
    return referenceOutput[param.partialReferenceValue.referenceValueKey!];
  }

  throw new Error(
    'Only referenceValue and partialReferenceValue are supported',
  );
}

/**
 * Only apply to Condition action
 * This function tries to get the condition result (true/false) from executedActions and workflow action.
 * ExecutedActions is the sliced array of all executedActions that only contains one foreach execution, which can make sure correct condition can be located in nested foreach blocks.
 */
export function getConditionResultFromExecutedActions(
  action: PreparedAction,
  executedActions: ExecutedAction[],
): boolean {
  if (!action.conditionAction) {
    throw new Error(`The action ${action.uuid} is not a condition action`);
  }
  const uuid = action?.uuid;
  const conditionExecutedAction = executedActions.find(
    (a) => a.preparedActionUuid === uuid,
  );

  const args = conditionExecutedAction?.paramValues!.map((p) => JSON.parse(p));

  if (!args || args.length === 0) {
    if (!action.conditionAction.condition) {
      throw new Error(
        `Condition result is null. Please check the action ${action.uuid}`,
      );
    }
    const value = getParamValue(
      action.conditionAction.condition,
      executedActions,
    );
    // consider empty object and array to be false
    return (
      value &&
      (!Array.isArray(value) || value.length > 0) &&
      (typeof value !== 'object' || Object.keys(value).length > 0)
    );
  }
  // arg could be null, we treat it as false
  // e.g
  // getElement could return undefined, undefined will be serialized to null
  return !!args[0];
}

/**
 * This function only applies to foreach
 * This function tries to calculate the boundary, number of steps for each foreach execution.
 */
export function calcForEachExecutions(
  action: PreparedAction,
  nextAction: PreparedAction | undefined,
  executedActions: ExecutedAction[],
): ExecutionInfo[] {
  if (!action.foreachAction || !action.foreachAction.actions) return [];
  if (action.foreachAction.actions?.length === 0) return [];
  const uuid = action.uuid;
  const nextUuid = nextAction?.uuid;
  const firstForeachActionUuid =
    action.foreachAction.actions![0].preparedActions![0].uuid;
  const executions = [];
  let i = 0;
  while (
    i < executedActions.length &&
    executedActions[i].preparedActionUuid !== uuid
  ) {
    i++;
  }
  let lastExecutionIndex = -1;
  while (i < executedActions.length) {
    if (executedActions[i].preparedActionUuid === nextUuid) {
      break;
    }
    if (executedActions[i].preparedActionUuid === firstForeachActionUuid) {
      if (lastExecutionIndex !== -1) {
        executions.push({
          startIndex: lastExecutionIndex,
          endIndex: i,
          numSteps: numOfDirectStepsInForeachAction(
            action,
            executedActions.slice(lastExecutionIndex, i),
          ),
        });
      }
      lastExecutionIndex = i;
    }
    i++;
  }
  if (lastExecutionIndex !== -1) {
    executions.push({
      startIndex: lastExecutionIndex,
      endIndex: i,
      numSteps: numOfDirectStepsInForeachAction(
        action,
        executedActions.slice(lastExecutionIndex, i),
      ),
    });
  }
  return executions;
}

/**
 * remove <start><end> annotations
 */
export function removeElementAnnotation(description: string) {
  return description.replaceAll('<start>', '').replaceAll('<end>', '');
}

/**
 * ExecutedActions is the sliced array of all executedActions that only contains one createTask execution, which can make sure correct task can be located in foreach blocks.
 * */
export function getTaskIdFromExecutedActions(
  action: PreparedAction,
  executedActions: ExecutedAction[],
): string {
  if (!action.createTaskAction) {
    throw Error(`The action ${action.uuid} is not a createTask action`);
  }
  const uuid = action?.uuid;
  const executedAction = executedActions.find(
    (a) => a.preparedActionUuid === uuid,
  );
  return JSON.parse(executedAction?.outputValue || '""');
}

export const getClickableWidgetInfo = (
  description: string,
): ClickableWidgetInfo | null => {
  const regex = /^(.*?)<start>(.*?)<end>(.*)$/;
  const match = description.match(regex);
  if (!match) {
    return null;
  }
  return {
    clickableText: match[2],
    prefix: match[1],
    suffix: match[3],
  };
};

// Reference: packages/extension/src/utils/workflow/index.ts > getActionAfter method
// This method has been moved from extension to workflow-utils
export function getActionAfter(
  action: PreparedAction,
  actionGroups: ActionGroup[],
  found: boolean = false,
): PreparedAction | undefined {
  for (const actionGroup of actionGroups) {
    for (const preparedAction of actionGroup.preparedActions ?? []) {
      if (preparedAction.conditionAction) {
        const trueBranch = getActionAfter(
          action,
          preparedAction.conditionAction.trueActions ?? [],
          found,
        );
        if (trueBranch) {
          return trueBranch;
        }
        found ||=
          getActionGroupById(
            action.uuid!,
            preparedAction.conditionAction.trueActions ?? [],
          ) !== undefined;
        const falseBranch = getActionAfter(
          action,
          preparedAction.conditionAction.falseActions ?? [],
          found,
        );
        if (falseBranch) {
          return falseBranch;
        }
        found ||=
          getActionGroupById(
            action.uuid!,
            preparedAction.conditionAction.falseActions ?? [],
          ) !== undefined;
      } else if (preparedAction.foreachAction) {
        const loop = getActionAfter(
          action,
          preparedAction.foreachAction.actions ?? [],
          found,
        );
        if (loop) {
          return loop;
        }
        found ||=
          getActionGroupById(
            action.uuid!,
            preparedAction.foreachAction.actions ?? [],
          ) !== undefined;
      } else if (found) {
        return preparedAction;
      } else if (preparedAction.uuid === action.uuid) {
        found = true;
      }
    }
  }

  return undefined;
}
