import { NavigateFunction } from 'react-router-dom';
import { NAME_EMAIL_PREFIX } from './constants';

export const composeUserFilters = (
  searchQuery: string,
  navigate: NavigateFunction,
  updateURL = true,
) => {
  let filter = '';
  const searchParams = new URLSearchParams();
  if (searchQuery) {
    filter += `${NAME_EMAIL_PREFIX}=${searchQuery}`;
    searchParams.append(NAME_EMAIL_PREFIX, searchQuery);
  }
  // Update URL search parameters
  if (updateURL) {
    navigate({ search: `?${searchParams.toString()}` });
  }

  // Return the updated filter string
  return filter.length && filter[0] === ',' ? filter.substring(1) : filter;
};
