import { WorkflowDetailsType } from '../actions/actions.constants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { orbotService } from '../../services/OrbotService';
import {
  getWorkflowFailure,
  setWorkflowSuccess,
  setHasUnsavedChanges,
  updateStatus,
  setLoading,
} from '../actions/workflow_details.constants';
import {
  GetWorkflowRequest,
  UpdateWorkflowRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { Status } from '../reducers/workflow_detail.reducer';

interface GetOrbotWorkflow {
  type: typeof WorkflowDetailsType.FETCH_WORKFLOW;
  payload: { req: GetWorkflowRequest };
}

function* getOrbotWorkflow(action: GetOrbotWorkflow): any {
  try {
    yield put(setLoading(true));
    const { response: workflow, error } = yield call(
      orbotService.getWorkflowById,
      action.payload.req,
    );

    if (workflow) {
      yield put(setWorkflowSuccess(workflow)); // Dispatch success action with response
    } else {
      yield put(getWorkflowFailure(error as Error)); // Dispatch failure action with error
    }
  } catch (error) {
    yield put(getWorkflowFailure(error as Error)); // Dispatch failure action if any unexpected error occurs
  } finally {
    yield put(setLoading(false));
  }
}

interface SaveWorkflowRequest {
  type: typeof WorkflowDetailsType.SAVE_WORKFLOW;
  payload: { workflow: Workflow; orgId: string | undefined };
}

function* saveWorkflow(action: SaveWorkflowRequest): any {
  if (!action.payload?.workflow) {
    throw Error('Workflow is not set');
  }

  try {
    yield put(updateStatus(Status.Loading));

    // API call to update workflow
    const request: UpdateWorkflowRequest = {
      // We will just revert the workflow migration until everything has been moved to the new Action object
      workflow: action.payload?.workflow,
      updateMask: [
        'display_name',
        'task_execution.generated_action_groups',
        'processes',
        'actions_for_review',
        'low_confidence_threshold',
        'reviewer_ids',
      ],
      orgId: action.payload?.orgId,
    };

    const updatedWorkflow: Workflow = yield call(
      orbotService.updateWorkflow,
      request,
    );

    yield put(updateStatus(Status.Loading));
    yield put(setWorkflowSuccess(updatedWorkflow));
    yield put(setHasUnsavedChanges(false));
    yield put(updateStatus(Status.Success));
  } catch (e) {
    yield put(updateStatus(Status.Error));
  }
}

function* workflowDetailsSaga() {
  yield all([
    takeLatest(WorkflowDetailsType.FETCH_WORKFLOW, getOrbotWorkflow),
    takeLatest(WorkflowDetailsType.SAVE_WORKFLOW, saveWorkflow),
  ]);
}

export default workflowDetailsSaga;
