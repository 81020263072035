import { Box } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React from 'react';
import { Workflow, WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  formatDateTime,
  formatDuration,
} from '../../../../../../utils/helpers';
import { WorkflowWithTask } from '../../../../../../redux/reducers/workflow_task.reducer';
import {
  HIGH_CONFIDENCE_THRESHOLD,
  MAX_SCORE_FOR_LOW_CONFIDENCE,
  SAMPLE_ACTIONS_TO_REVIEW,
} from '../../../../../../utils/constants';

interface Props {
  workflowTask: WorkflowTask;
  workflow: Workflow;
}

const getConfidenceScores = (task: WorkflowWithTask): number[] => {
  const confidenceScores = [];

  for (const action of task?.task?.executedActions || []) {
    const { predictedOutputValue } = action;
    const outputJson = predictedOutputValue
      ? JSON.parse(predictedOutputValue)
      : undefined;
    if (
      outputJson &&
      typeof outputJson === 'object' &&
      'confidence' in outputJson
    ) {
      const score = outputJson.confidence;
      confidenceScores.push(score);
    }
  }
  return confidenceScores;
};

function getConfidenceText(
  score: number | undefined,
  workflow: Workflow,
): string {
  if (isLowConfidence(score, workflow)) {
    return 'low';
  }
  return 'high';
}

function isLowConfidence(
  score: number | undefined,
  workflow: Workflow,
): boolean {
  if (workflow.lowConfidenceThreshold === undefined) return true;
  const normalizedThreshold = Math.floor(workflow.lowConfidenceThreshold * 100);
  if (normalizedThreshold === HIGH_CONFIDENCE_THRESHOLD) return true;

  if (score !== undefined && score > MAX_SCORE_FOR_LOW_CONFIDENCE) {
    return false;
  }
  // Sample 10% of the actions to review
  const random = Math.random();
  if (random < SAMPLE_ACTIONS_TO_REVIEW) {
    return true;
  } else {
    return false;
  }
}

const Summary: React.FC<Props> = ({ workflowTask, workflow }) => {
  const humanReviewInvolved = workflowTask.executedActions?.some(
    (action) => action.humanReviewTriggered,
  )
    ? 'Yes'
    : 'No';

  const confidenceScores = getConfidenceScores({
    workflow,
    task: workflowTask,
  });

  const confidenceScoreText = confidenceScores
    .map((score) => getConfidenceText(score, workflow))
    .join('/');

  return (
    <Box
      padding={'36px'}
      bgcolor={OrbyColorPalette['white-0']}
      borderRadius={'12px'}
      marginTop={'24px'}
      minHeight={252}
    >
      <OrbyTypography
        size='xl'
        weight='medium'
        color={OrbyColorPalette['black-0']}
      >
        Summary
      </OrbyTypography>

      <Box
        marginTop={'24px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <OrbyTypography color={OrbyColorPalette['grey-600']}>
              Execution start time
            </OrbyTypography>
            <OrbyTypography size='md'>
              {workflowTask?.startTime &&
                `${formatDateTime(workflowTask?.startTime).date}, ${formatDateTime(workflowTask?.startTime).time}`}
            </OrbyTypography>
          </Box>

          <Box>
            <OrbyTypography color={OrbyColorPalette['grey-600']}>
              Execution end time
            </OrbyTypography>
            <OrbyTypography size='md'>
              {workflowTask?.endTime &&
                `${formatDateTime(workflowTask?.endTime).date}, ${formatDateTime(workflowTask?.endTime).time}`}
            </OrbyTypography>
          </Box>

          <Box>
            <OrbyTypography color={OrbyColorPalette['grey-600']}>
              Duration
            </OrbyTypography>
            <OrbyTypography size='md'>
              {formatDuration(workflowTask?.startTime, workflowTask?.endTime)}
            </OrbyTypography>
          </Box>

          <Box>
            <OrbyTypography color={OrbyColorPalette['grey-600']}>
              Human review
            </OrbyTypography>
            <OrbyTypography size='md'>{humanReviewInvolved}</OrbyTypography>
          </Box>
        </Box>

        <Box>
          <OrbyTypography color={OrbyColorPalette['grey-600']}>
            Confidence score before review
          </OrbyTypography>
          <OrbyTypography size='md'>{confidenceScoreText}</OrbyTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default Summary;
