/**
 * This file is used to show the select dropdown
 * which contains the list of classification labels
 */
import { Box, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allowEditingTaskSelector,
  getClassificationLabelsListSelector,
  getOriginalPredictedResultSelector,
  getPredictedResultSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import { updatePredictedClassification } from '../../../../../redux/actions/review_task.action';
import { ELLIPSIS_STYLE } from '../../../../../utils/constants';
import { PredictionResult } from 'protos/pb/v1alpha2/execution_steps';

const ClassificationOptions: React.FC = () => {
  const classificationLabelsList = useSelector(
    getClassificationLabelsListSelector,
  );
  const predictedResult = useSelector(
    getPredictedResultSelector,
  ) as PredictionResult;
  const originalPredictedResult = useSelector(
    getOriginalPredictedResultSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const [selectedClassificationLabel, setSelectedClassificationLabel] =
    useState(predictedResult.classificationLabel);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        justifyContent={'space-between'}
        marginX={'12px'}
        marginTop={'11px'}
        marginBottom={'auto'}
      >
        <Box id={'doc-type-label'} display={'none'}>
          {' '}
          Classification Type{' '}
        </Box>
        <CustomTypography
          id='Document Type'
          component={'label'}
          typographyType={TypographyType.Label}
          color='#545454'
        >
          Classification Type
        </CustomTypography>
        <Select
          aria-hidden={false}
          placeholder='Classification Type'
          labelId='doc-type-label'
          fullWidth
          // Test Classification
          readOnly={!allowEditingTask}
          value={selectedClassificationLabel}
          onChange={(event) => {
            setSelectedClassificationLabel(event.target.value);
            dispatch(updatePredictedClassification(event.target.value));
          }}
          sx={{ marginTop: '5px', textTransform: 'capitalize' }}
          renderValue={(value) =>
            value === predictedResult.classificationLabel
              ? predictedResult.classificationLabel
              : value
          }
        >
          {classificationLabelsList?.map((label) => {
            const isRecommended =
              label === originalPredictedResult?.classificationLabel;
            return (
              <MenuItem
                title={label}
                key={label}
                value={label}
                // 27vw is the review page side bar min width
                // 24px is the combined margin of left and right
                sx={{ width: 'calc(27vw - 24px)' }}
              >
                <CustomTypography
                  sx={{
                    ...ELLIPSIS_STYLE,
                    textTransform: 'capitalize',
                  }}
                >
                  {label}
                </CustomTypography>
                {isRecommended && (
                  <CustomTypography
                    sx={{
                      borderRadius: '4px',
                      bgcolor: '#E3FCEF',
                      padding: '4px',
                      marginLeft: '5px',
                    }}
                    typographyType={TypographyType.Label}
                  >
                    Predicted
                  </CustomTypography>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </>
  );
};

export default React.memo(ClassificationOptions);
