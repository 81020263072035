import { ItemDescription, WidgetContainer } from './Styled';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { removeElementAnnotation } from 'workflow-utils/src/helper';
import { ActionObject } from 'workflow-utils/src/types';
import { useSelector } from 'react-redux';
import { workflowAllDetailsSelector } from '../../../redux/selectors/workflow_details.selectors';

interface IProps {
  action: ActionObject;
  traceIndices?: number[];
}

const LinkedWorkflow: React.FC<IProps> = ({ action }) => {
  // Local states
  const description = removeElementAnnotation(action.description || '');
  const [processName, setProcessName] = useState<string>('');

  const { processId } = useMemo(
    () => ({
      workflowId: action?.createTask?.workflowId,
      processId: action?.createTask?.processId,
    }),
    [action],
  );

  // All the information of workflow details
  const { workflow } = useSelector(workflowAllDetailsSelector);

  useEffect(() => {
    if (workflow?.processes && workflow.processes.length > 0) {
      const process = processId
        ? workflow.processes.find((p) => p.id === processId)
        : workflow.processes[0];

      setProcessName(process?.description ?? processName);
    }
  }, [workflow?.processes]);

  return (
    <WidgetContainer>
      <ItemDescription>
        {description}:{' '}
        <Link to={`/my-workflows/definition/${action?.createTask?.workflowId}`}>
          {/* TODO: Work on the link */}
          {processName}
        </Link>
      </ItemDescription>
    </WidgetContainer>
  );
};

export default LinkedWorkflow;
