import { observer } from 'mobx-react';
import { ItemDescription, WidgetContainer } from './Styled';
import React from 'react';
import { Chip, chipClasses } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import { ActionObject } from 'workflow-utils/src/types';

interface IProps {
  action: ActionObject;
}

const IfCondition: React.FC<IProps> = observer(({ action }) => {
  const description = action.description || '';
  let conditionTextIdx = -1;
  if (description.toLowerCase().indexOf('if') >= 0) {
    conditionTextIdx = description.toLowerCase().indexOf('if') + 2;
  }
  if (description.toLowerCase().indexOf('whether') >= 0) {
    conditionTextIdx = description.toLowerCase().indexOf('whether') + 7;
  }

  return (
    <WidgetContainer>
      <ItemDescription>
        {description.substring(0, conditionTextIdx)}
      </ItemDescription>

      <Chip
        sx={{
          height: 'auto',
          backgroundColor: '#E6E2FF',
          color: OrbyColorPalette['grey-900'],
          '&:hover': {
            backgroundColor: '#E6E2FF',
            color: OrbyColorPalette['grey-900'],
          },
          [`& .${chipClasses.label}`]: {
            display: 'block',
            fontSize: 16,
            whiteSpace: 'normal',
          },
        }}
        label={description.substring(conditionTextIdx)}
        color={'secondary'}
        variant='filled'
      />
    </WidgetContainer>
  );
});

export default IfCondition;
