import React, { FC, memo, useEffect } from 'react';
import PdfIcon from '../static/icons/pdf-icon.svg';
import { File } from 'protos/automation_mining/ontology/data_models';
import CustomFilePicker from './CustomFilePicker';

interface Props {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  workflowName: string;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
}

const CustomPdfFilePicker: FC<Props> = ({
  files,
  setFiles,
  workflowName,
  error,
  setError,
  isUploading,
}) => {
  useEffect(() => {
    // Reset error state when workflow name changes
    setError(false);
  }, [workflowName]);

  return (
    <CustomFilePicker
      files={files}
      setFiles={setFiles}
      error={error}
      setError={setError}
      acceptedFormat={'.pdf'}
      acceptedMIMEFormat={['application/pdf']}
      acceptedFormatMessage={'Only PDF files are allowed'}
      fileIcon={PdfIcon}
      allowMultipleFiles={true}
      isUploading={isUploading}
    />
  );
};

export default memo(CustomPdfFilePicker);
