import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import {
  DeleteBatchTasksRequest,
  ListTasksRequest,
  Task,
  TaskSTATUS,
  UpdateBatchTasksRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  addAlpha,
  allowSelectionForPendingTask,
  areAllTasksSelected,
  formatDate,
  groupTasksByError,
  handleSelectAllTasks,
  handleTaskCheckboxClick,
  isAdmin,
  isTaskSelected,
} from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedTaskSelector,
  deleteTaskErrorSelector,
  deletingTaskSelector,
  listPendingTasksErrorSelector,
  pendingTasksListSelector,
  pendingTasksLoadingSelector,
  pendingTasksTotalSizeSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToDeleteSelector,
  tasksFailedToReassignSelector,
  updateBatchTasksErrorSelector,
  updateBatchTasksLoadingSelector,
  updateBatchTasksSuccessSelector,
} from '../../redux/selectors/taskV2.selectors';
import { Add } from '@mui/icons-material';
import {
  clearDeleteTaskAction,
  createTaskClearAction,
  deleteTaskAction,
  listPendingTasksAction,
  listPendingTasksErrorAction,
  resetReassignmentStateAction,
  updateBatchTasksAction,
} from '../../redux/actions/taskV2.action';
import { notification } from 'antd';
import AddTaskModal from './AddTaskModal';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import PendingTasksAdditionalRound from './PendingTasksAdditionalRound';
import { useNavigate } from 'react-router-dom';
import { clearReviewState } from '../../redux/actions/review_task.action';
import WorkflowFilterSelect from './WorkflowFilterSelect';
import {
  composeTaskFiltersAndUpdateNetworkURL,
  getFilterValuesFromUrl,
  getWorkflowIdsFromParamString,
} from '../Utils/taskV2Utils';
import {
  ActionType,
  ADMIN_TASK_FILTER,
  DeleteTaskType,
  DISPLAY_NAME_PREFIX,
  ELLIPSIS_STYLE,
  ReassignmentOptions,
  USER_TASK_FILTER,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import SearchTaskField from './SearchTaskField';
import UserAssignmentMenu from './UserAssignmentMenu';
import TaskCheckbox from './TaskCheckbox';
import CustomTableLabel from '../../components/CustomTableLabel';
import TaskDeletionModal from './TaskDeletionModal';
import { ReactComponent as DeleteIcon } from '../../static/icons/deleteIcon.svg';
import '../../styles/table.css';
import TaskFilterSelect from './TaskFilterSelect';
import { User } from 'protos/pb/v1alpha1/user';
import { useIsOrgChanged } from '../../hooks/useIsOrgChanged';
import TaskHeader from './TaskHeader';
import { isTaskAssignable } from '../../utils/RbacUtils';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));
const getConfidenceColor = (confidence: string) => {
  switch (confidence) {
    case 'high':
      return '#E3FCEF';
    case 'mid':
      return '#FFF2BF';
    case 'low':
      return '#FFBFBF';
  }
};

const PendingTasks: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tasks: Task[] = useSelector(pendingTasksListSelector);
  const loading = useSelector(pendingTasksLoadingSelector);
  const totalSize = useSelector(pendingTasksTotalSizeSelector) ?? 0;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pagedTasks = tasks.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const [modal2Open, setModal2Open] = useState(false);
  const listPendingTasksError = useSelector(listPendingTasksErrorSelector);
  const deleteTaskError = useSelector(deleteTaskErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const workflowColors = useSelector(workflowColorsSelector);
  const user = useSelector(loggedInUserSelector);
  const addedTask = useSelector(addedTaskSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const deletingTask = useSelector(deletingTaskSelector);
  const urlSearchParams = new URLSearchParams(location.search);
  const isOrgChanged = useIsOrgChanged(selectedOrgInfo?.orgResourceName || '');
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamString(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(
    () =>
      getFilterValuesFromUrl(isAdminView, urlSearchParams, user as User)
        .selectedUsers,
  );
  const [selectedOption, setSelectedOption] = useState<string>(
    () =>
      getFilterValuesFromUrl(isAdminView, urlSearchParams, user as User)
        .filterOption,
  );
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]); // for batch delete
  const [selectedTask, setSelectedTask] = useState<Task | null>(null); // for single delete

  const [selectedUserForAssignment, setSelectedUserForAssignment] =
    useState<string>(ReassignmentOptions.DEFAULT);
  // State for selected Additional Round Tasks
  const [selectedARTasks, setSelectedARTasks] = useState([]);
  const ressignmentLoading = useSelector(updateBatchTasksLoadingSelector);
  const ressignmentError = useSelector(updateBatchTasksErrorSelector);
  const ressignmentSuccess = useSelector(updateBatchTasksSuccessSelector);
  const tasksFailedToReassign = useSelector(tasksFailedToReassignSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );
  const [deleteType, setDeleteType] = useState<ActionType | null>(null);
  const allSelectedTasks = useMemo(
    () => [...selectedTasks, ...selectedARTasks],
    [selectedTasks, selectedARTasks],
  );
  const isUserAssignmentVisible = !!allSelectedTasks.length;

  useEffect(() => {
    if (selectedUserForAssignment !== ReassignmentOptions.DEFAULT) {
      const tasks = allSelectedTasks.map((t: Task) => {
        return {
          name: t.name,
          displayName: t.displayName,
          username: selectedUserForAssignment,
          organizationResourceName: t.organizationResourceName,
        } as Task;
      });
      dispatch(updateBatchTasksAction({ tasks } as UpdateBatchTasksRequest));
    }
  }, [selectedUserForAssignment]);

  const resetReassignmentState = () => {
    setSelectedTasks([]);
    setSelectedARTasks([]);
    setSelectedUserForAssignment(ReassignmentOptions.DEFAULT);
    dispatch(resetReassignmentStateAction());
  };

  useEffect(() => {
    if (ressignmentError) {
      if (typeof ressignmentError === 'string') {
        openError(Error(ressignmentError));
      } else {
        openGroupError(ressignmentError);
      }
      resetReassignmentState();
      setTimeout(() => dispatch(resetReassignmentStateAction(true)), 3000);
    }
  }, [ressignmentError]);

  useEffect(() => {
    if (ressignmentSuccess) {
      openSuccess(ressignmentSuccess, false);
      resetReassignmentState();

      // this check is just for optimization to not refresh the list when selected filter is All tasks
      // Let say selected filter is unassigned only and person just assigned those unassigned tasks to
      // some person, in cases like that we refresh the list to avoid inconsistencies
      // this behavior will be true for all filters other than All Tasks

      if (
        ![
          ADMIN_TASK_FILTER.ALL_TASKS as string,
          USER_TASK_FILTER.ALL_TASKS as string,
        ].includes(selectedOption)
      ) {
        // set page number to zero and refresh the whole list
        setPage(0);
        listTasks(1, rowsPerPage, true);
      }
    }
  }, [ressignmentSuccess]);

  useEffect(() => {
    resetReassignmentState(); // reset all filters when either org changes or component mounts
    return () => setDisplayNamePrefix(''); // reset search query only when org changes not when component mounts
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      openSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
      );
      dispatch(clearDeleteTaskAction());
      // set page number to zero and refresh the whole list
      setPage(0);
      listTasks(1, rowsPerPage, true);
    }
  }, [tasksDeletedSuccessfully]);

  const openGroupError = (
    groupedErrorTasks: ReturnType<typeof groupTasksByError>,
    isReassignment = true,
  ) => {
    api.error({
      message: 'Error',
      description: (
        <div>
          {groupedErrorTasks.map((e, index) => (
            <div key={index}>
              <p>
                {`${e.taskNames.length} ${
                  e.taskNames.length > 1 ? 'Tasks were' : 'Task was'
                } not ${isReassignment ? 'reassigned' : 'deleted'} due to: `}
                <p style={{ fontWeight: 500, display: 'inline' }}>
                  {e.errorMsg}:
                </p>{' '}
              </p>
              <ul>
                {e.taskNames.map((n) => (
                  <li key={n}>{n}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
      placement: 'topRight',
      duration: null,
    });
  };

  const openError = (error: Error) => {
    api.error({
      message: 'Error',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (message: string, autoClose = true) => {
    api.success({
      message: 'Success',
      description: message,
      placement: 'topRight',
      duration: autoClose ? 4.5 : null,
    });
  };

  useEffect(() => {
    if (listPendingTasksError) {
      openError(listPendingTasksError);
      dispatch(listPendingTasksErrorAction(undefined));
    }
  }, [listPendingTasksError]);

  useEffect(() => {
    if (deleteTaskError) {
      openError(deleteTaskError);
      dispatch(clearDeleteTaskAction());
    }
  }, [deleteTaskError]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      openGroupError(groupTasksByError(tasksFailedToDelete), false);
      dispatch(clearDeleteTaskAction());
    }
  }, [tasksFailedToDelete]);

  useEffect(() => {
    if (isOrgChanged.isOrgChanged) {
      setSelectedOption(
        isAdminView ? ADMIN_TASK_FILTER.ALL_TASKS : USER_TASK_FILTER.ALL_TASKS,
      );
      setSelectedUsers(new Set());
    }
  }, [isOrgChanged]);

  const listTasks = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildRequest();
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listPendingTasksAction(req, refresh));
  };

  useEffect(() => {
    setPage(0);
    setSelectedTasks([]);
    listTasks(1, rowsPerPage, true);
  }, [
    selectedOrgInfo,
    selectedWorkflows,
    displayNamePrefix,
    selectedOption,
    selectedUsers,
  ]);

  useEffect(() => {
    if (addedTask) {
      const timeout = setTimeout(() => {
        setPage(0);
        listTasks(1, rowsPerPage, true);
        setSelectedARTasks([]);
        setSelectedTasks([]);
        dispatch(createTaskClearAction()); // clear the added task
      }, 1000); // wait for the modal to close
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [addedTask]);

  const buildRequest = () => {
    const req: ListTasksRequest = ListTasksRequest.fromJSON({
      filter: composeTaskFiltersAndUpdateNetworkURL(
        'status=READY-CREATED,round=1',
        user?.email as string,
        isAdminView,
        navigate,
        selectedWorkflows,
        displayNamePrefix,
        [...selectedUsers.values()],
        selectedOption,
      ),
      parent: selectedOrgInfo?.orgResourceName,
    });
    // req.filter = composeTaskFiltersAndUpdateNetworkURL(
    //   'status=READY-CREATED,round=1',
    //   user?.email as string,
    //   isAdminView,
    //   navigate,
    //   selectedWorkflows,
    //   displayNamePrefix,
    //   [...selectedUsers.values()],
    //   selectedOption
    // )
    // req.parent = selectedOrgInfo?.orgResourceName
    return req;
  };

  useEffect(() => {
    if (tasks) {
      const workflowNames = tasks.map(
        (t) => t.workflowDisplayName || t.organizationResourceName,
      );
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [tasks]);

  const onTableRowClick = (task: Task) => {
    if (task.status !== TaskSTATUS.CREATED) {
      dispatch(clearReviewState());
      navigate(`/${task.name}/automation-review`);
    }
  };

  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    const req: DeleteBatchTasksRequest = {
      names: itemsToDelete.map((item) => item.name) as string[],
      deletedReason: deleteReason,
    };
    dispatch(
      deleteTaskAction({
        deleteType: DeleteTaskType.PENDING,
        req,
      }),
    );
    setSelectedTasks([]); // clear Additional round selected tasks
    setSelectedARTasks([]); // clear first round selected tasks
    handleCloseDeletionModal();
  };

  const handleCloseDeletionModal = () => {
    setDeleteType(null);
    setSelectedTask(null);
  };

  const renderDelete = (t: Task) => {
    return t.status !== TaskSTATUS.CREATED ? (
      <IconButton
        sx={{ ml: '20px' }}
        aria-label='Delete Task'
        onClick={(e) => {
          if (t.status !== TaskSTATUS.CREATED) {
            e.stopPropagation();
            setSelectedTask(t);
            setDeleteType(ActionType.SINGLE);
          }
        }}
      >
        <DeleteIcon color='#6B6B6B' />
      </IconButton>
    ) : (
      <Box sx={{ paddingX: '4px', paddingY: '10px' }} />
    );
  };

  const handleAssignToClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAssignToClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box paddingX={'60px'} paddingBottom={'20px'} paddingTop={'60px'}>
        {contextHolder}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'start'}
            gap={'16px'}
          >
            <SearchTaskField
              sx={{
                marginLeft: '0px !important',
                width: '225px',
                '& .MuiOutlinedInput-input': { paddingY: '10.5px' },
              }}
              displayNamePrefix={displayNamePrefix}
              setDisplayNamePrefix={setDisplayNamePrefix}
            />
            <Box display={'flex'} gap={'16px'}>
              <WorkflowFilterSelect
                selectedWorkflows={selectedWorkflows}
                setSelectedWorkflows={setSelectedWorkflows}
              />
              <TaskFilterSelect
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            </Box>
          </Box>
          <Button
            onClick={() => setModal2Open(true)}
            sx={{
              flexShrink: 0,
              backgroundColor: '#0500FF',
              color: '#FFFFFF',
              borderRadius: '40px',
              padding: '10px 18px',
              '&:hover': {
                backgroundColor: '#0500FF',
              },
            }}
          >
            <Add sx={{ marginRight: '4px' }} />
            <CustomTypography
              color={'#FFFFFF'}
              weight={600}
              size={'16px'}
              lineHeight={'24px'}
            >
              Add Task
            </CustomTypography>
          </Button>
        </Box>
        <TaskHeader
          pageTitle={'Pending Tasks'}
          selectedItems={allSelectedTasks}
          onCancel={() => {
            setSelectedARTasks([]);
            setSelectedTasks([]);
          }}
          tertiaryLabel={ReassignmentOptions.UNASSIGN}
          onTertiaryClick={() => {
            // we set empty string in case we want to unassign
            setSelectedUserForAssignment('');
          }}
          onSecondaryClick={() => {
            if (isAdminView) {
              setDeleteType(ActionType.BATCH);
            }
          }}
          secondaryLabel={isAdminView ? 'Delete' : ''}
          onPrimaryClick={handleAssignToClick}
          primaryLabel={'Assign to'}
        />
        {isUserAssignmentVisible && (
          <UserAssignmentMenu
            selectedUser={selectedUserForAssignment}
            setSelectedUser={setSelectedUserForAssignment}
            canAssignToOthers={
              (selectedTask && isTaskAssignable(selectedTask)) ||
              (selectedTasks.length > 0 &&
                selectedTasks.every((t) => isTaskAssignable(t)))
            }
            handleClose={handleAssignToClose}
            anchorEl={anchorEl}
          />
        )}
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          paddingTop={'12px'}
        >
          <CustomTypography
            sx={{ marginLeft: '12px' }}
            size={'16px'}
            weight={600}
          >
            First Round
          </CustomTypography>
        </Box>
        <Box>
          {loading || deletingTask ? (
            <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer
                sx={{ marginTop: '30px' }}
                className='table-header-style'
              >
                <Table id={'pending-tasks-table'} sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                      {pagedTasks.length > 0 && (
                        <CustomTableCell
                          sx={{ width: '50px' }}
                          ellipsis={false}
                        >
                          <TaskCheckbox
                            disabled={
                              ressignmentLoading ||
                              !pagedTasks.some((t) =>
                                allowSelectionForPendingTask(t),
                              )
                            }
                            checked={areAllTasksSelected(
                              selectedTasks,
                              pagedTasks,
                            )}
                            title='Select all Pending tasks'
                            onClick={(e) =>
                              handleSelectAllTasks(
                                e,
                                selectedTasks,
                                pagedTasks,
                                setSelectedTasks,
                              )
                            }
                          />
                        </CustomTableCell>
                      )}
                      <CustomTableCell>
                        <CustomTableLabel label='Task Name' />
                      </CustomTableCell>
                      <CustomTableCell title='Workflow'>
                        <CustomTableLabel label='Workflow' />
                      </CustomTableCell>
                      <CustomTableCell title='Time Modified'>
                        <CustomTableLabel label='Time Modified' />
                      </CustomTableCell>
                      <CustomTableCell title='Confidence Level'>
                        <CustomTableLabel label='Confidence Level' />
                      </CustomTableCell>
                      <CustomTableCell title='Pending Reviewer'>
                        <CustomTableLabel label='Pending Reviewer' />
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pagedTasks.map(
                      (t, index) =>
                        t.status !== TaskSTATUS.OBSOLETE && (
                          <StyledTableRow
                            key={index}
                            tabIndex={0}
                            role='row'
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: tasksFailedToReassign.includes(
                                t.name as string,
                              )
                                ? '#FEF3F2'
                                : index % 2 !== 0
                                  ? '#F6F6F6'
                                  : 'white',
                              ':hover': { backgroundColor: '#1669F74D' },
                              ':focus': { backgroundColor: '#1669F74D' },
                            }}
                            onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                onTableRowClick(t);
                              }
                            }}
                            onClick={() => onTableRowClick(t)}
                          >
                            {
                              <CustomTableCell ellipsis={false}>
                                <TaskCheckbox
                                  disabled={
                                    ressignmentLoading ||
                                    !allowSelectionForPendingTask(t)
                                  }
                                  checked={isTaskSelected(t, selectedTasks)}
                                  onClick={(e) =>
                                    handleTaskCheckboxClick(
                                      e,
                                      t,
                                      selectedTasks,
                                      setSelectedTasks,
                                    )
                                  }
                                  sx={{ marginRight: '20px' }}
                                  title={
                                    allowSelectionForPendingTask(t)
                                      ? `Select ${t.displayName}`
                                      : 'Created tasks cannot be reassigned'
                                  }
                                />
                              </CustomTableCell>
                            }
                            <CustomTableCell>
                              <span title={t.displayName || 'NO DATA'}>
                                {t.displayName || 'NO DATA'}
                              </span>
                            </CustomTableCell>
                            <CustomTableCell>
                              <Box
                                title={
                                  t.workflowDisplayName ||
                                  t.organizationResourceName
                                }
                                sx={{
                                  paddingX: '10px',
                                  paddingY: '5px',
                                  backgroundColor: addAlpha(
                                    workflowColors[
                                      (t.workflowDisplayName as string) ||
                                        (t.organizationResourceName as string)
                                    ],
                                    0.4,
                                  ),
                                  width: 'fit-content',
                                  maxWidth: '100%',
                                  borderRadius: '4px',
                                }}
                              >
                                <CustomTypography
                                  typographyType={TypographyType.Label}
                                  sx={{
                                    ...ELLIPSIS_STYLE,
                                  }}
                                >
                                  {t.workflowDisplayName ||
                                    t.organizationResourceName}
                                </CustomTypography>
                              </Box>
                            </CustomTableCell>
                            <CustomTableCell ellipsis={false}>
                              {t.status !== TaskSTATUS.CREATED ? (
                                <CustomTypography>
                                  {t.readyTime ? formatDate(t.readyTime) : '-'}
                                </CustomTypography>
                              ) : (
                                <Box
                                  width={'106px'}
                                  height={'11px'}
                                  borderRadius={'15px'}
                                  bgcolor={'#E4E5E7'}
                                ></Box>
                              )}
                            </CustomTableCell>
                            {t.status !== TaskSTATUS.CREATED ? (
                              <CustomTableCell>
                                <Box
                                  title='High'
                                  sx={{
                                    paddingX: '10px',
                                    paddingY: '5px',
                                    backgroundColor: addAlpha(
                                      getConfidenceColor('high') as string,
                                      0.4,
                                    ),
                                    borderRadius: '4px',
                                    width: 'fit-content',
                                  }}
                                >
                                  <CustomTypography
                                    typographyType={TypographyType.Label}
                                  >
                                    High
                                  </CustomTypography>
                                </Box>
                              </CustomTableCell>
                            ) : (
                              <CustomTableCell>
                                <Box
                                  width={'106px'}
                                  height={'11px'}
                                  borderRadius={'15px'}
                                  bgcolor={'#E4E5E7'}
                                ></Box>
                              </CustomTableCell>
                            )}
                            {
                              <CustomTableCell
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{ ...ELLIPSIS_STYLE }}
                                  title={t.username || 'Not assigned'}
                                >
                                  <CustomTypography
                                    sx={{
                                      ...ELLIPSIS_STYLE,
                                      maxWidth: '100%',
                                      marginTop: '5px',
                                    }}
                                  >
                                    {t.username || 'Not assigned'}
                                  </CustomTypography>
                                </Box>
                                {isAdminView && renderDelete(t)}
                              </CustomTableCell>
                            }
                          </StyledTableRow>
                        ),
                    )}
                  </TableBody>
                </Table>
                {!pagedTasks.length && (
                  <CustomTypography
                    sx={{
                      textAlign: 'center',
                      textJustify: 'center',
                      color: '#475467',
                      marginTop: '40px',
                    }}
                  >
                    There are no first-round tasks available
                  </CustomTypography>
                )}
              </TableContainer>
              <CustomPagination
                rowsPerPage={rowsPerPage}
                totalSize={totalSize}
                page={page}
                onRowsPerPageChange={(rows) => {
                  setPage(0);
                  // Refresh is needed when rows per page is changes to fetch fresh data
                  listTasks(1, rows, true);
                  setRowsPerPage(rows);
                  setSelectedTasks([]);
                }}
                isShowSelectFilter={totalSize > rowsPerPage}
                onPageChange={(p) => {
                  setPage(p);
                  if (p >= page && tasks.length <= p * rowsPerPage) {
                    listTasks(p + 1, rowsPerPage, false);
                  }
                  setSelectedTasks([]);
                }}
              />
            </>
          )}
        </Box>
        <AddTaskModal open={modal2Open} setOpen={setModal2Open} />
      </Box>
      <Box>
        <PendingTasksAdditionalRound
          selectedWorkflows={selectedWorkflows}
          displayNamePrefix={displayNamePrefix ?? ''}
          selectedOption={selectedOption}
          selectedUsers={selectedUsers}
          selectedTasks={selectedARTasks}
          setSelectedTasks={setSelectedARTasks}
          handleDeletion={(t: Task) => {
            setSelectedTask(t);
            setDeleteType(ActionType.SINGLE);
          }}
        />
      </Box>
      <TaskDeletionModal
        selectedTasks={
          deleteType === ActionType.BATCH
            ? allSelectedTasks
            : selectedTask
              ? [selectedTask]
              : []
        }
        open={!!deleteType}
        handleClose={handleCloseDeletionModal}
        onSubmit={handleDelete}
      />
    </>
  );
};

export default React.memo(PendingTasks);
