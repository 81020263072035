import { Box, Button, Slider } from '@mui/material';
import React, { FC, memo } from 'react';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import styled from '@emotion/styled';
import { WorkflowMode } from 'protos/pb/v1alpha2/workflows_service';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

const marks = [
  {
    value: 0,
    label: '0%',
  },

  {
    value: 100,
    label: '100%',
  },
];

const PrettoSlider = styled(Slider)({
  color: OrbyColorPalette['green-700'],
  height: 11,
  marginTop: '14px',
  width: '100%',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    width: 0,
    height: 0,
  },
  '& .MuiSlider-markLabel': {
    fontSize: '12px',
    color: OrbyColorPalette['black-0'],
    top: '-10px',
  },
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    backgroundColor: OrbyColorPalette['white-0'],
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'transparent',
  },
  '& .MuiSlider-valueLabel > span': {
    '::after': {
      content: '"%"',
    },
    borderRadius: '50%',
    backgroundColor: 'transparent',
    color: OrbyColorPalette['black-0'],
    fontWeight: 'bold',
    position: 'absolute',
    top: '23.5px',
    fontSize: '8px',
  },
});

const WorkflowModeString = {
  1: 'Default mode',
  2: 'Auto-pilot mode',
  3: 'Assisted mode',
};

interface AddUserReviewModeProps {
  selectedMode: WorkflowMode;
  showSlider?: boolean;
  mode: WorkflowMode;
  percent?: number;
  setPercent?: (percent: number) => void;
  onClick: (mode: WorkflowMode) => void;
  edit?: boolean;
  ariaDescribedBy: string;
  disabled?: boolean;
}

const AddUserReviewMode: FC<AddUserReviewModeProps> = ({
  selectedMode,
  showSlider,
  mode,
  percent,
  setPercent,
  onClick,
  // edit,
  ariaDescribedBy,
  disabled,
}) => {
  const selected = selectedMode === mode;

  return (
    <Box>
      <Button
        disabled={disabled}
        autoFocus={mode === WorkflowMode.DEFAULT}
        aria-describedby={ariaDescribedBy}
        sx={{
          padding: 0,
          borderRadius: '8px',
          border: 0,
          backgroundColor: OrbyColorPalette['white-0'],
          width: '180px',
          height: '48px',
        }}
        aria-label={`${
          WorkflowModeString[mode as keyof typeof WorkflowModeString]
        }`}
        onClick={() => {
          onClick(mode);
        }}
      >
        <Box
          width={'180px'}
          height={'48px'}
          bgcolor={
            selected
              ? OrbyColorPalette['green-700']
              : OrbyColorPalette['blueGrey-50']
          }
          borderRadius={'8px'}
          display={'flex'}
          paddingX={'14px'}
          alignItems={'center'}
          border={`1px solid ${OrbyColorPalette['blueGrey-100']}`}
          sx={{ cursor: 'pointer' }}
        >
          {!selected && (
            <RadioButtonUnchecked
              sx={{
                color: OrbyColorPalette['grey-600'],
                marginRight: '8px',
                height: '20px',
                width: '20px',
              }}
            />
          )}
          {selected && (
            <RadioButtonChecked
              sx={{
                color: OrbyColorPalette['white-0'],
                marginRight: '8px',
                height: '20px',
                width: '20px',
              }}
            />
          )}
          <OrbyTypography
            color={
              selected
                ? OrbyColorPalette['white-0']
                : OrbyColorPalette['grey-600']
            }
          >
            {WorkflowModeString[mode as keyof typeof WorkflowModeString]}
          </OrbyTypography>
        </Box>
      </Button>
      {showSlider && selected && (
        <PrettoSlider
          aria-label='pretto slider'
          value={percent}
          valueLabelDisplay='on'
          marks={marks}
          onChange={(_, value) => {
            setPercent && setPercent(value as number);
          }}
        />
      )}
    </Box>
  );
};

export default memo(AddUserReviewMode);
