import * as React from 'react';
import { Box, Popover, Typography, styled } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';

const ClickableText = styled(Typography, {
  shouldForwardProp(propName) {
    return propName !== 'isEditable';
  },
})<{ open: boolean; isEditable: boolean }>(({ open, isEditable }) => ({
  display: 'inline-block',
  cursor: isEditable ? 'pointer' : 'inherit',
  fontSize: 18,
  lineHeight: '36px',
  borderBottom: `1px solid ${open ? OrbyColorPalette['blue-700'] : OrbyColorPalette['grey-400']}`,
  color: OrbyColorPalette['grey-900'],
}));

type ClickableTextWithPopupProps = {
  isEditable: boolean;
  text: string;
  renderPopover: (open: boolean, handleClose: () => void) => React.ReactNode;
};

export const ClickableTextWithPopover: React.FC<
  ClickableTextWithPopupProps
> = ({ isEditable, text, renderPopover }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!isEditable) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ClickableText onClick={handleClick} open={open} isEditable={isEditable}>
        {text}
      </ClickableText>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderPopover(open, handleClose)}
      </Popover>
    </Box>
  );
};
