import {
  ReconcileItems,
  SmartAction,
} from 'protos/pb/v1alpha1/actionprocessing';

export const smartActions: SmartAction = {
  reconcileLineItems: ReconcileItems.fromJSON({
    fields: [
      {
        name: 'line item/amount',
        text: {
          type: 'MONEY',
        },
      },
      {
        name: 'line item/quantity',
      },
    ],
    source: {
      fieldGroups: [
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '175.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '350.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Wiper Aluminium 18 Inch",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '14.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '280.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Scrub Pad with Sponge 3",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '1.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '96.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '96.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Plastic Bucket 10Ltr JAV",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '12.5000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '125.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Yellow Cloth Big (Per Pc",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST5',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '12.5000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '250.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Check Cloth Big (Per Pc)",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST5',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '106.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1,060.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'233M MICROFIBER CLOTH (Per",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST12',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '3.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '750.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '2,250.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Dry Mop Stick 50cms (Per",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '12.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '240.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Sponge White (Per Pc)",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '85.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '170.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'235LTR BLACK BUCKET & MUG",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '93.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '186.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Soft Broom Stick With Pl",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST0',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '140.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '280.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Plastic Soft Broom Stick",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '7.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '145.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1,015.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Crew Urinal Screen (Per",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '47.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '470.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Odonil Air Freshener Zip",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '35.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '350.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Air Freshner Cake Odonil",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '102.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1,020.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Room Freshner Odonil 300",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '17.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '340.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Stainless Steel Scrubber",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '7.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '107.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '749.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Prill Liquid Dish Wash 5",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '8.00',
              },
            },
            {
              name: 'line item/unit cost',
              value: {
                text: '355.0000',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '2,840.00',
              },
            },
            {
              name: 'line item/description',
              value: {
                text: "Dec'23Dry Mop Refill 50cms (Pe",
              },
            },
            {
              name: 'line item/date',
              value: {
                text: '',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: 'INGST18',
              },
            },
          ],
        },
      ],
    },
    target: {
      fieldGroups: [
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '350.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '7',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1015.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '280.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '340.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '186.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '0%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '250.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '5%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1020.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '7',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '749.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '125.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '5%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '20',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '240.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '1',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '96.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '350.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '8',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '2840.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '280.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '3',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '2250.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '1060.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '12%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '10',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '470.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
        {
          fields: [
            {
              name: 'line item/quantity',
              value: {
                text: '2',
              },
            },
            {
              name: 'line item/amount',
              value: {
                text: '170.00',
              },
            },
            {
              name: 'line item/tax rate',
              value: {
                text: '18%',
              },
            },
          ],
        },
      ],
      documents: [
        {
          document: {
            gcsUri:
              'gs://orby_user_files_dev/document/643436b756fec98deb575c50/95a97ba9-6d90-11ef-891b-56f5cc52eccd',
          },
          fields: [
            {
              name: 'line item/description',
            },
            {
              name: 'line item/date',
            },
          ],
        },
      ],
    },
  }),
};
