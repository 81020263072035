import { Box } from '@mui/material';
import {
  DocumentPage,
  DocumentPageToken,
} from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTextSegmentIdAction,
  setTokenListToHighlightAction,
  updateEntityInfoAction,
} from '../../../../../../../redux/actions/review_task.action';
import {
  allowEditingTaskSelector,
  selectedEntityIdSelector,
  selectedEntityInfoSelector,
  selectedTextSegmentIdSelector,
  selectedTextSegmentInfoSelector,
  taskForReviewSelector,
  tokenListPageToHighlightSelector,
  tokenListToHighlightSelector,
  tokensInDocumentSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import {
  doesStyleHaveValues,
  getPdfPageIndexForBoundingbox,
  getYCoordForCurrentPage,
  makeDivDraggableOnClick,
  makeDraggableDiv,
  makeResizableDiv,
} from '../../../../../../../utils/BoundingBoxUtils';
import { boxPositionValuesUtilV2 } from '../../../../../../../utils/BoxPositionValuesUtilV2';
import { tokenValuesUtil } from '../../../../../../../utils/TokenValuesUtil';
import { PADDING_BW_PDF_PAGES } from '../../../../../../../utils/constants';
import { getSelectedTaskDocument } from '../../../../../../../utils/helpers';
import './BoundingBox.css';
import {
  getEntityInfoWithHighlightedTextSegments,
  getPositionToHighlightTokens,
  getVerticesAndPageOfHighlightedTokens,
  getVerticesFromStyles,
} from '../../../../../../../utils/TokensHighlightUtils';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { getNormalizedVertices } from '../../../../../../../utils/ReviewTaskUtils';
import debounce from 'lodash/debounce';

interface Props {
  boxWidth: number;
  boxHeight: number;
  boxTop: number;
  boxLeft: number;
  scale: number;
  currentPage: number;
}

const BoundingBox: React.FC<Props> = ({
  boxHeight,
  boxWidth,
  boxLeft,
  boxTop,
  scale,
  currentPage,
}) => {
  const backgroundColor = '#1669F7';
  const refBox = useRef(null);
  const dispatch = useDispatch();
  const selectedTask = useSelector(taskForReviewSelector);
  const tokensInDocument = useSelector(tokensInDocumentSelector);
  const selectedEntityId = useSelector(selectedEntityIdSelector);
  const selectedTextSegmentId = useSelector(selectedTextSegmentIdSelector);
  const selectedEntityInfo = useSelector(selectedEntityInfoSelector);
  const selectedTextSegmentInfo = useSelector(selectedTextSegmentInfoSelector);
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedEntityInfoRef = useRef(selectedEntityInfo);
  const selectedTextSegmentInfoRef = useRef(selectedTextSegmentInfo);
  const highlightedTokenList = useSelector(tokenListToHighlightSelector);
  const highlightedTokenListPage = useSelector(
    tokenListPageToHighlightSelector,
  );
  const [stopHighlight, setStopHighlight] = useState(false);

  // THIS ADDS OPACITY TO THE COLOR FOR BOUNDING BOX
  function addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  // THIS IS TO ADD TEXT SEGMENTS TO THE ENTITY INFO BASED ON HIGHLIGHTED TOKENS, AND UPDATE ENTITY INFO
  useEffect(() => {
    if (stopHighlight && highlightedTokenList?.length) {
      const entityInfo = getEntityInfoWithHighlightedTextSegments(
        highlightedTokenList as DocumentPageToken[],
        { ...(selectedEntityInfo as EntityInfo) },
        selectedTask as Task,
        highlightedTokenListPage as number,
        selectedTextSegmentId,
      );
      dispatch(updateEntityInfoAction(entityInfo));
      dispatch(setTokenListToHighlightAction());
    }
  }, [stopHighlight]);

  const setHighlightedTokens = (styles?: {
    startX: string;
    startY: string;
    endX: string;
    endY: string;
  }) => {
    // If styles are not provided, stop highlighting and return
    if (!styles) {
      setStopHighlight(true);
      return;
    }
    // If styles are provided, continue highlighting
    setStopHighlight(false);
    const { pageCorrespondingStyles, page } =
      getVerticesAndPageOfHighlightedTokens(
        styles,
        selectedTask as Task,
        scale,
      );
    // Dispatch an action to set the token list to highlight
    dispatch(setTokenListToHighlightAction(pageCorrespondingStyles, page));
  };

  const debouncedUpdateEntityInfo = useCallback(
    debounce((newEntityInfo) => {
      dispatch(updateEntityInfoAction(newEntityInfo, false, true));
    }, 300),
    [],
  );

  const getUpdatedCoordinates = (styles: CSSStyleDeclaration) => {
    // If the styles values are empty no calculation should be done
    if (!doesStyleHaveValues(styles)) return;

    const vertices = getVerticesFromStyles(styles, scale);

    const entityInfo = selectedEntityInfoRef.current;
    const defaultTextSegmentInfo = {
      id: '0',
      page: currentPage,
      entityId: entityInfo?.id,
      modifiedPage: currentPage,
    };
    const textSegmentInfo =
      selectedTextSegmentInfoRef.current ?? defaultTextSegmentInfo;

    const selectedTaskDocument =
      getSelectedTaskDocument(selectedTask)?.documents?.[0];

    // total padding will be CURRENT PADDING / scale
    const padding = PADDING_BW_PDF_PAGES / scale;
    const page = getPdfPageIndexForBoundingbox(
      selectedTaskDocument?.pages as DocumentPage[],
      vertices[0].y as number,
      padding,
      textSegmentInfo.modifiedPage || textSegmentInfo.page,
    );

    const pdfBoxHeight = selectedTaskDocument?.pages?.[page]?.image?.height;
    const pdfBoxWidth = selectedTaskDocument?.pages?.[page]?.image?.width;

    if (
      selectedTaskDocument?.pages &&
      page >= 0 &&
      page < selectedTaskDocument?.pages?.length
    ) {
      const pageCorrespondingVertices = vertices.map((v) => {
        const ver = v as Vertex;
        ver.y = getYCoordForCurrentPage(
          selectedTaskDocument?.pages as DocumentPage[],
          ver.y as number,
          padding,
          textSegmentInfo.modifiedPage || textSegmentInfo.page,
        );
        return ver;
      });
      const tokensInPage = tokensInDocument[page];
      const collidingTokens = boxPositionValuesUtilV2.getCollidingTokens(
        tokensInPage,
        pageCorrespondingVertices,
      );
      const textForDocument = selectedTaskDocument.text || '';
      // THE NEW SEGMENT INFO WILL UPDATE HERE WHEN POSITION OF BOUNDING BOX IS CHANGED
      const newTextSegmentInfo = {
        ...textSegmentInfo,
        vertices,
        modifiedPage: page,
        page: page,
        pageCorrespondingVertices,
        normalizedVertices: getNormalizedVertices(
          pdfBoxHeight as number,
          pdfBoxWidth as number,
          pageCorrespondingVertices,
        ),
        text: tokenValuesUtil.getTextFromTokens(
          collidingTokens,
          textForDocument,
        ),
        textFromTokens: tokenValuesUtil.getTextFromTokens(
          collidingTokens,
          textForDocument,
        ),
        startIndex:
          collidingTokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
            ?.startIndex || 0,
        endIndex:
          collidingTokens?.[collidingTokens.length - 1]?.layout?.textAnchor
            ?.textSegments?.[0]?.endIndex || 0,
        lastAdjustedPadding: padding,
      };
      const newEntityInfo = {
        ...entityInfo,
        isModified: true,
        textSegments: {
          ...entityInfo?.textSegments,
          [newTextSegmentInfo.id]: newTextSegmentInfo,
        },
      };
      // real time update
      dispatch(updateEntityInfoAction(newEntityInfo as EntityInfo, true));
      // TODO: Add comment
      debouncedUpdateEntityInfo({ ...newEntityInfo });
      if (!selectedTextSegmentInfoRef.current) {
        dispatch(setSelectedTextSegmentIdAction(newTextSegmentInfo.id));
      }
    }
  };

  /**
   * This function calculates the position and dimensions of a bounding box.
   * It first initializes propBoxPosition with default values.
   * If a selectedTextSegmentInfo exists with vertices, it calculates a text segment's box position.
   * Then, it checks if the text segment's box matches the default box; if yes, it returns the default box; otherwise,
   * it returns the text segment's box.
   * This resolves the zoom in and zoom out rendering issue.
   */
  const getBoxPositionValues = () => {
    const propBoxPosition = {
      top: boxTop,
      left: boxLeft,
      width: boxWidth,
      height: boxHeight,
    };

    if (!selectedTextSegmentInfo?.vertices?.length) {
      return propBoxPosition;
    }
    const textSegmentBoxPosition = boxPositionValuesUtilV2.getBoxPositionValues(
      selectedTextSegmentInfo.vertices,
      1,
      boxPositionValuesUtilV2.getAdjustedPadding(
        selectedTextSegmentInfo,
        scale,
      ),
    );

    if (
      boxPositionValuesUtilV2.hasSameBoxPositionValues(
        textSegmentBoxPosition,
        propBoxPosition,
      )
    ) {
      return propBoxPosition;
    } else {
      return textSegmentBoxPosition;
    }
  };

  // THIS UPDATES THE REF VARIABLES WHEN ANY ONE DEPENDENCY CHANGES
  // SO THE LISTENER (getUpdatedCoordinates) USES UPDATED VALUES AT THE TIME OF CALCULATION
  useEffect(() => {
    selectedEntityInfoRef.current = selectedEntityInfo;
    selectedTextSegmentInfoRef.current = selectedTextSegmentInfo;
  }, [selectedEntityInfo, selectedTextSegmentInfo]);

  // THIS MAKES THE SELECTED BOUNDING BOX RESIZABLE, DRAGGABLE AND MOVABLE ACROSS PAGE ON CLICK
  useEffect(() => {
    const ele = refBox.current;
    if (allowEditingTask && ele) {
      makeResizableDiv(ele, getUpdatedCoordinates);
      makeDraggableDiv(ele, getUpdatedCoordinates);
      // THIS IS THE HEIGHT OF PAGE ALONG WITH 8px PADDING GIVEN B/W PAGES
      let heightToRemove = 0;
      const pages: DocumentPage[] = getSelectedTaskDocument(selectedTask)
        ?.documents?.[0]?.pages as DocumentPage[];
      for (let page = 0; page < currentPage; page++) {
        heightToRemove +=
          (pages?.[page]?.image?.height as number) * scale +
          PADDING_BW_PDF_PAGES;
      }
      // TO DETERMINE THE HEIGHT THAT HAS TO BE REMOVED
      // e.g if currentPage is 4 and user moved the box to page 8, we determine the position from page 1
      // To get the height that needs to be added -> Height from page 1 - Height of current page (i.e from page 4)
      // if currently it's at 4455px and user moved it to 8950px (respective to page 1)
      // then total height that need to be increased will be 8950px - 4455px
      makeDivDraggableOnClick(ele, getUpdatedCoordinates, heightToRemove);
      getPositionToHighlightTokens(setHighlightedTokens);
    }
  }, [allowEditingTask]);

  // THIS SETS THE DIMENSIONS AND POSITION OF BOUNDING BOX WHICH GETS DISPLAYED
  // WHEN ANY ENTITY OR TEXT SEGMENT OF ANY ENTITY IS SELECTED
  useEffect(() => {
    const ele = refBox.current as unknown as HTMLElement;
    const boxPosition = getBoxPositionValues();
    if (ele) {
      ele.style.width = boxPosition.width * scale + 'px';
      ele.style.height = boxPosition.height * scale + 'px';
      ele.style.top = boxPosition.top * scale + 'px';
      ele.style.left = boxPosition.left * scale + 'px';
    }
  }, [
    selectedTextSegmentId,
    selectedEntityId,
    selectedTextSegmentInfo?.vertices,
  ]);

  // Change resizer styles based on the scale
  const resizerSize = 12 * scale + 'px';
  const resizerStyles = { width: resizerSize, height: resizerSize };
  const resizerPosition = 1 - (12 * scale) / 2;
  const resizerAdjustment = 5 * scale;

  return (
    <Box className='wrapper'>
      <Box
        id={'bounding-box'}
        className='resizable draggable'
        ref={refBox}
        bgcolor={addAlpha(backgroundColor, 0.4)}
        zIndex={2}
      >
        <Box className='resizers'>
          <Box
            style={{
              ...resizerStyles,
              left: resizerPosition,
              top: resizerPosition,
            }}
            className='resizer top-left'
          />
          <Box
            style={{
              ...resizerStyles,
              right: resizerPosition,
              top: resizerPosition,
            }}
            className='resizer top-right'
          />
          <Box
            style={{
              ...resizerStyles,
              left: resizerPosition,
              bottom: resizerPosition,
            }}
            className='resizer bottom-left'
          />
          <Box
            style={{
              ...resizerStyles,
              right: resizerPosition,
              bottom: resizerPosition,
            }}
            className='resizer bottom-right'
          />
        </Box>
        <Box
          style={{
            left: resizerAdjustment,
            right: resizerAdjustment,
            height: resizerAdjustment,
          }}
          className='resizer top'
        />
        <Box
          style={{
            top: resizerAdjustment,
            bottom: resizerAdjustment,
            width: resizerAdjustment,
          }}
          className='resizer right'
        />
        <Box
          style={{
            left: resizerAdjustment,
            right: resizerAdjustment,
            height: resizerAdjustment,
          }}
          className='resizer bottom'
        />
        <Box
          style={{
            top: resizerAdjustment,
            bottom: resizerAdjustment,
            width: resizerAdjustment,
          }}
          className='resizer left'
        />
      </Box>
    </Box>
  );
};

export default React.memo(BoundingBox);
