import {
  Box,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import CustomTypography from '../../../../components/CustomTypography';
import React, { FC } from 'react';

interface Props {
  entityDetails: {
    name: string;
    isParent?: boolean;
    parent?: string;
  }[];
  name: string;
  getFieldProps: any;
  padding?: string;
  height?: string;
  width?: string;
}

export const EntityFilterSelect: FC<Props> = ({
  entityDetails,
  getFieldProps,
  name,
  padding = '10px',
  height = '0px',
  width = '30%',
}) => {
  const primaryTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: padding,
            fontSize: '14px',
            height: height,
            marginLeft: '5px',
            color: '#545454',
          },
        },
      },
    },
  });

  return (
    <FormControl
      sx={{
        width: width,
        height: '32px',
      }}
    >
      <ThemeProvider theme={primaryTheme}>
        <Select
          placeholder='Select object'
          {...getFieldProps(name)}
          renderValue={(selected: any) => (
            <Box title={selected.name}>{selected.name}</Box>
          )}
        >
          {/* When there is no value selected, we display the placeholder and for the validation to
               work, the value of the selected value (which is placeholder in the case) should be
               an empty string as per MUI */}
          <MenuItem value={''}>
            <CustomTypography color='#B6B6B6'>Select object</CustomTypography>
          </MenuItem>
          {entityDetails.map((item: any) => (
            <MenuItem
              disabled={item.isParent}
              sx={{
                paddingLeft: item.parent && '35px',
                '&.Mui-disabled': {
                  opacity: 'unset',
                },
              }}
              key={item.parent + item.name}
              value={item}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </FormControl>
  );
};

export default EntityFilterSelect;
