import { MenuItem, Select } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import {
  AssignmentMode,
  ELLIPSIS_STYLE,
  Filter,
} from '../../../../utils/constants';

interface AssignmentFilterSelectProps {
  value: AssignmentMode;
  setValue: (val: string) => void;
}
const AssignmentFilterSelect: React.FC<AssignmentFilterSelectProps> = ({
  value,
  setValue,
}) => {
  const assignmentOptions: Filter[] = [
    { label: 'Leave tasks unassigned', value: AssignmentMode.UNASSIGNED },
    { label: 'Assign tasks to users', value: AssignmentMode.ASSIGNED },
  ];
  const getAssignmentOptionFilterLabel = () => {
    return assignmentOptions.find((o) => value === o.value)?.label;
  };
  return (
    <Select
      renderValue={() => (
        <CustomTypography
          sx={{ ...ELLIPSIS_STYLE }}
          size='16px'
          weight={500}
          lineHeight='24px'
        >
          {getAssignmentOptionFilterLabel()}
        </CustomTypography>
      )}
      MenuProps={{
        MenuListProps: { 'aria-label': 'Select Task Assignment Mode' },
        PaperProps: {
          style: {
            maxHeight: '200px',
          },
        },
      }}
      sx={{
        width: '320px',
        minWidth: '20%',
        height: '35px',
      }}
      value={value}
    >
      {assignmentOptions.map((option) => (
        <MenuItem
          onClick={() => setValue(option.value)}
          key={option.value}
          value={option.value}
        >
          <CustomTypography
            weight={500}
            size='16px'
            lineHeight='24px'
            color='#101828'
          >
            {option.label}
          </CustomTypography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(AssignmentFilterSelect);
