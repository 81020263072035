import { Box, Step, StepContent, Stepper } from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssignmentConfig,
  Connector,
  WorkflowInfo,
} from 'protos/pb/v1alpha2/connector';
import ConnectorGeneralContent from './Steps/ConnectorGeneralContent';
import {
  createConnectorAction,
  createConnectorErrorAction,
} from '../../../redux/actions/connector.action';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import {
  createdConnectorSelector,
  processConnectorErrorSelector,
  processingConnectorSelector,
} from '../../../redux/selectors/connector.selectors';
import { notification } from 'antd';
import { getCompleteCompositeGroupCondition } from '../../../utils/helpers';
import { useFetchWorkflowFilters } from '../../../hooks/useFetchWorkflowFilters';
import { FormikValues } from 'formik';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import StepLabelComponent from '../components/StepLabelComponent';
import StepperActionComponent from '../components/StepperActionComponent';
import CreateConnectorCondition from './Steps/CreateConnectorCondition';

export enum CreationStep {
  StepOne,
  StepTwo,
}

const STEPS = [
  {
    title: 'Add name and description',
    background: '#E8F4E3',
    iconColor: '#3BA755',
  },
  {
    title: 'Create condition',
    background: '#E8E8FC',
    iconColor: '#4F52B2',
  },
];

const ConnectorCreationPage: FC = () => {
  const [creationTriggered, setCreationTriggered] = useState(false);
  const [activeStep, setActiveStep] = useState<CreationStep>(
    CreationStep.StepOne,
  );
  const [connector, setConnector] = useState(Connector.create({}));
  const [moveToStep, setMoveToStep] = useState<CreationStep | null>(null);
  const [isStepOneClicked, setIsStepOneClicked] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const connectorError = useSelector(processConnectorErrorSelector);
  const addingConnector = useSelector(processingConnectorSelector);
  const createdConnector = useSelector(createdConnectorSelector);
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  const [api, contextHolder] = notification.useNotification();

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (connectorError && creationTriggered) {
      setConnector(connector);
      openError(connectorError);
      dispatch(createConnectorErrorAction(undefined));
    }
  }, [connectorError]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (createdConnector && creationTriggered) {
      openSuccess(
        'Connector ' + createdConnector.name + ' is created successfully',
      );
      setConnector(connector);
      setTimeout(() => {
        setActiveStep(0);
        navigate(-1);
      }, 1000);
    }
  }, [createdConnector]);

  const handleSubmitGeneralContent = (values: FormikValues) => {
    // @ts-ignore
    const c: Connector = Connector.create(connector);
    c.displayName = values.connector_name;
    c.description = values.description;
    setConnector(c);
    setActiveStep(activeStep + 1);
  };

  const handleSubmitConnectorCondition = (values: FormikValues) => {
    const c: Connector = connector;

    c.sourceWorkflow = WorkflowInfo.create({
      workflowResourceName: values.source_workflow,
    });
    c.destinationWorkflow = WorkflowInfo.create({
      workflowResourceName: values.destination_workflow,
    });
    c.groupCondition = getCompleteCompositeGroupCondition(
      values.conditionGroups,
    );
    c.orgResourceName = selectedOrgInfo?.orgResourceName;
    c.assignmentConfig = AssignmentConfig.create({
      preserveAssignee: values.preserve_assignee,
    });
    dispatch(createConnectorAction(c));
    setCreationTriggered(true);
  };

  const getStepContent = (index: number) => {
    if (index === CreationStep.StepOne) {
      return (
        <ConnectorGeneralContent
          connector={connector}
          onSubmit={handleSubmitGeneralContent}
          isStepOneClicked={isStepOneClicked}
          setIsStepOneClicked={setIsStepOneClicked}
        />
      );
    } else if (index === CreationStep.StepTwo) {
      return (
        <CreateConnectorCondition
          workflowFilters={workflowFilters!}
          workflowFiltersLoading={workflowFiltersLoading}
          onSubmit={handleSubmitConnectorCondition}
        />
      );
    }
  };

  const handleStepUpdate = (step: number) => {
    setMoveToStep(step);
  };

  useEffect(() => {
    if (moveToStep !== null) {
      if (activeStep === CreationStep.StepOne) {
        setIsStepOneClicked(true);
      } else if (activeStep === CreationStep.StepTwo) {
        setActiveStep(moveToStep);
      }
    }
    setTimeout(() => {
      setMoveToStep(null);
    }, 100);
  }, [moveToStep]);

  return (
    <Box
      bgcolor={OrbyColorPalette['white-0']}
      paddingX={'48px'}
      paddingTop={'64px'}
      width={'100%'}
    >
      {contextHolder}

      {/* HEADER */}
      <OrbyTypography
        size='display-xs'
        weight='semibold'
        sx={{
          marginBottom: '24px',
        }}
      >
        Connector creation
      </OrbyTypography>
      <OrbyTypography
        size='md'
        color={OrbyColorPalette['grey-600']}
        sx={{
          marginBottom: '24px',
        }}
      >
        Imagine connectors as bridges that connect various workflows, based on
        specific conditions.
      </OrbyTypography>

      {/* STEPPER */}
      <Stepper
        sx={{
          '& .MuiStepContent-root': {
            borderColor: OrbyColorPalette['blue-700'],
            borderLeftWidth: '2px',
            marginLeft: '24px',
          },
          '& .MuiStepConnector-root': {
            maxHeight: '12px',
          },
          '& .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['blue-700'],
            borderLeftWidth: '2px',
            marginLeft: '12px',
          },
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['grey-300'],
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
      >
        {STEPS.map((step, index) => {
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabelComponent
                title={step.title}
                isActiveStep={activeStep === index}
                step={index + 1}
                isStepCompleted={activeStep > index}
                updateStep={handleStepUpdate}
              />
              <StepContent>
                <Box>{getStepContent(index)}</Box>

                {/* ACTIONS */}
                <StepperActionComponent
                  loading={addingConnector}
                  step={activeStep}
                  setActiveStep={setActiveStep}
                  totalSteps={STEPS.length - 1}
                  activeStep={activeStep}
                  setMoveToStep={setMoveToStep}
                />
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default memo(ConnectorCreationPage);
