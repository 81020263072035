import React, { useRef } from 'react';
import CustomTypography from '../../components/CustomTypography';
import CustomModal from '../../components/CustomModal';
import { Box } from '@mui/material';
import ItemDeletionForm, {
  FormikFormRef,
} from '../PendingTasks/ItemDeletionForm/ItemDeletionForm';

interface ConnectorDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (deletionReason: string) => void;
  isMultiple?: boolean;
}

const ConnectorDeletionModal: React.FC<ConnectorDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
  isMultiple = false,
}) => {
  const formRef = useRef(null);
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() =>
        (formRef.current as unknown as FormikFormRef)?.submitForm()
      }
      heading={isMultiple ? 'Delete Items?' : 'Delete Item?'}
      content={
        <>
          <Box sx={{ width: '433px', mb: '24px' }}>
            <CustomTypography>
              Are you sure you want to delete this connector?
            </CustomTypography>
          </Box>
          <ItemDeletionForm ref={formRef} onSubmit={onSubmit} />
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(ConnectorDeletionModal);
