import {
  Box,
  Button,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';

import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { ActionObject } from 'workflow-utils/src/types';

export interface FieldListProps {
  fields: string[];
  actionId: string;
  action: ActionObject;
  isEditable: boolean;
  processId?: string;
  onClose(): void;
}

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '320px',
}));

/**
 * Allow to view and edit list of fields.
 */
const FieldList: React.FC<FieldListProps> = ({
  fields,
  action,
  onClose,
  isEditable,
  processId,
}) => {
  const dispatch = useDispatch();

  const originalFieldsText = fields.join('\n');
  const [fieldsText, setFieldsText] = useState(originalFieldsText);

  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setFieldsText(value);
  };

  const onUpdate = () => {
    const fields = fieldsText
      .split('\n')
      .map((value) => value.trim())
      .filter((value) => value);

    const flagKeywords = {
      ...action!.flagKeywords,
      fields,
    };
    const updated: ActionObject = {
      action: {
        ...action,
        flagKeywords,
      },
      description: action?.description,
    };
    dispatch(updateAction(action, updated, processId));
    onClose();
  };

  return (
    <Container spacing={2}>
      <Typography color={OrbyColorPalette['grey-500']}>
        List of fields to flag, one per line.
      </Typography>
      <Typography color={OrbyColorPalette['grey-500']}>
        Keeping it empty will flag all fields.
      </Typography>
      <TextField
        multiline
        fullWidth
        value={fieldsText}
        onChange={onChangeText}
        InputProps={{
          readOnly: !isEditable,
        }}
      />
      <Box>
        <Button
          variant='outlined'
          disabled={fieldsText === originalFieldsText}
          onClick={onUpdate}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default FieldList;
