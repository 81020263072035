import { ApplicationName } from '../../utils/protos/enums';

export const getStepsInfoFromApplication = (
  applicationName: string,
  isEmail: boolean,
) => {
  switch (applicationName) {
    case ApplicationName.GoogleDrive:
    case ApplicationName.SftpServer:
    case ApplicationName.Outlook:
    case ApplicationName.Gmail:
      return {
        title: 'Create trigger condition',
        background: '#E8E8FC',
        iconColor: '#4F52B2',
      };
    case ApplicationName.MSExcel:
    case ApplicationName.GoogleSheets:
      return {
        title: 'Create system action',
        background: '#dfeff8',
        iconColor: '#6198DE',
      };
    case ApplicationName.DocumentClassification:
      return {
        title: 'Classification',
        background: '#FBBA2345',
        iconColor: '#F6BD16',
      };
    case ApplicationName.EntityExtraction:
      return {
        title: `Extract data from ${isEmail ? 'email' : 'file'}`,
        background: '#FBBA2345',
        iconColor: '#F6BD16',
      };
    case ApplicationName.GenerateOutput:
      return {
        title: 'Create system action',
        background: '#5DB9E429',
        iconColor: '#6198DE',
      };
  }
};
