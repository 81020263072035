import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { OrbyButton } from 'orby-ui/src';

interface StepperActionComponentProps {
  loading: boolean;
  totalSteps: number;
  step: number;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<any>>;
  setMoveToStep: React.Dispatch<React.SetStateAction<any>>;
}

const StepperActionComponent: FC<StepperActionComponentProps> = (props) => {
  const {
    loading,
    totalSteps,
    step,
    setActiveStep,
    activeStep,
    setMoveToStep,
  } = props;

  const navigate = useNavigate();

  return (
    <Box
      paddingBottom={'20px'}
      sx={{
        display: 'flex',
        justifyContent: 'end',
        gap: '25px',
      }}
    >
      <OrbyButton
        ariaLabel='Cancel'
        variant='primary-outline'
        sx={{
          width: '120px',
        }}
        onClick={() => navigate(-1)}
        disabled={loading}
        label='Cancel'
      />
      {step > 0 && (
        <OrbyButton
          ariaLabel='Previous'
          onClick={() => {
            setMoveToStep(null);
            setActiveStep(step - 1);
          }}
          sx={{
            width: '120px',
          }}
          variant='primary-outline'
          disabled={loading}
          label='Previous'
        />
      )}
      {step != totalSteps && (
        <OrbyButton
          variant='primary-contained'
          ariaLabel='Next'
          sx={{
            width: '200px',
          }}
          form={`form${activeStep + 1}`}
          type='submit'
          disabled={loading}
          label='Next'
        />
      )}
      {step === totalSteps && (
        <OrbyButton
          variant='primary-contained'
          form={`form${activeStep + 1}`}
          type='submit'
          sx={{
            width: '200px',
          }}
          disabled={loading}
          loading={loading}
          label='Submit'
        />
      )}
    </Box>
  );
};

export default memo(StepperActionComponent);
