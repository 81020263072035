import React from 'react';
import CustomTypography from '../../../../../components/CustomTypography';
import {
  AssignmentMode,
  ELLIPSIS_STYLE,
  Filter,
} from '../../../../../utils/constants';
import { OrbyMenuItem, OrbySelect } from 'orby-ui/src';

interface AssignmentFilterSelectProps {
  value: AssignmentMode;
  setValue: (val: string) => void;
}
const AssignmentFilterSelect: React.FC<AssignmentFilterSelectProps> = ({
  value,
  setValue,
}) => {
  const assignmentOptions: Filter[] = [
    { label: 'Leave tasks unassigned', value: AssignmentMode.UNASSIGNED },
    { label: 'Assign tasks to users', value: AssignmentMode.ASSIGNED },
  ];
  const getAssignmentOptionFilterLabel = () => {
    return assignmentOptions.find((o) => value === o.value)?.label;
  };
  return (
    <OrbySelect
      width='320px'
      renderValue={() => (
        <CustomTypography
          sx={{ ...ELLIPSIS_STYLE }}
          size='16px'
          weight={500}
          lineHeight='24px'
        >
          {getAssignmentOptionFilterLabel()}
        </CustomTypography>
      )}
      MenuProps={{
        MenuListProps: { 'aria-label': 'Select Task Assignment Mode' },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      value={value}
    >
      {assignmentOptions.map((option) => (
        <OrbyMenuItem
          onClick={() => setValue(option.value)}
          width='320px'
          key={option.value}
          value={option.value}
          title={option.label}
        />
      ))}
    </OrbySelect>
  );
};

export default React.memo(AssignmentFilterSelect);
