import { Box, CircularProgress } from '@mui/material';
import React, { FC, memo } from 'react';

interface ReviewPageLoaderProps {
  contextHolder: any;
}

const ReviewPageLoader: FC<ReviewPageLoaderProps> = ({ contextHolder }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
    >
      {contextHolder}
      <CircularProgress />
    </Box>
  );
};

export default memo(ReviewPageLoader);
