import { useEffect } from 'react';

// this hook is used to prevent child element going out of bonds wrt to parent element
export const usePreventOverflow = (
  resizableChildRef: React.RefObject<HTMLElement>,
  parentRef: React.RefObject<HTMLElement>,
  dependencies: any[] = [],
) => {
  useEffect(() => {
    const resizableChildElement = resizableChildRef?.current;
    const parentElement = parentRef?.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // Loop through each entry in the observer
      for (const entry of entries) {
        // If the observed element is the target element, update the height state
        if (entry.target === resizableChildElement) {
          if (parentElement) {
            // get parent's bottom position relative to view port
            const parentBottom = parentElement.getBoundingClientRect().bottom;

            // get child's bottom position relative to view port
            const childBottom =
              resizableChildElement.getBoundingClientRect().bottom;
            // check if child's bottom exceeds that of parent's bottom
            // this essentially means child has gone out of bounds with respect to parent's viewport
            if (childBottom > parentBottom)
              // adjust the child's top position accordingly
              resizableChildElement.style.top = `${
                parentElement.clientHeight - resizableChildElement.clientHeight
              }px`;
          }
        }
      }
    });

    // Start observing the target element for size changes
    if (resizableChildElement) {
      resizeObserver.observe(resizableChildElement);
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, [...dependencies]);
};
