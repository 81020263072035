import { Box, SxProps, Theme } from '@mui/material';
import { OrbyButton } from 'orby-ui/src';
import React, { FC, memo } from 'react';

interface CustomComboButtonsNewProp {
  primaryLabel: string;
  secondaryLabel: string;
  size: 'small' | 'medium' | 'large';
  marginLeft?: string | number;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  secondaryLoading?: boolean;
  sx?: SxProps<Theme>;
  reverse?: boolean;
  secondaryAutoFocus?: boolean;
  secondaryId?: string;
}

// THIS COMPONENT IS USED TO DISPLAY TWO BUTTONS - PRIMARY BUTTON (DISPLAYS FIRST), SECONDARY BUTTON (DISPLAYS AFTER PRIMARY)
// EACH OF WHICH HAS ITS OWN PROPS LIKE label, onClick, disabled, sx, loading, title (for tooltip) and id
const CustomComboButtonsNew: FC<CustomComboButtonsNewProp> = ({
  primaryLabel,
  secondaryLabel,
  onPrimaryClick,
  onSecondaryClick,
  primaryDisabled,
  secondaryDisabled,
  size,
  marginLeft,
  secondaryLoading,
  sx,
  reverse,
  secondaryId,
}) => {
  const spaceBetween = size === 'large' ? '48px' : '23px';
  const padding = size === 'large' ? 2 : 1;
  return (
    <Box
      sx={sx}
      padding={padding}
      marginLeft={marginLeft ?? 0}
      display={'flex'}
      flexDirection={reverse ? 'row' : 'row-reverse'}
      alignItems={'center'}
      gap={spaceBetween}
    >
      <OrbyButton
        variant='primary-contained'
        ariaLabel={secondaryLabel}
        id={secondaryId}
        size={size}
        disabled={secondaryDisabled}
        onClick={onSecondaryClick}
        loading={secondaryLoading}
        label={secondaryLabel}
      />
      <OrbyButton
        ariaLabel={primaryLabel}
        variant='monochrome-outline'
        onClick={onPrimaryClick}
        disabled={primaryDisabled}
        size={size}
        label={primaryLabel}
      />
    </Box>
  );
};

export default memo(CustomComboButtonsNew);
