import produce from 'immer';
import {
  TaskStatusStats,
  TaskSummaryStats,
} from 'protos/pb/v1alpha2/dashboard_service';
import { Reducer } from 'redux';
import { DashboardActionType } from '../actions/actions.constants';

export interface DashboardState {
  taskSummary?: TaskSummaryStats;
  taskStatus?: TaskStatusStats;
  statsError?: Error;
}

const initialState: DashboardState = {};

export const dashboardReducer: Reducer<DashboardState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: DashboardState = initialState,
  action: any,
) =>
  produce(state, (draft: DashboardState) => {
    switch (action.type) {
      case DashboardActionType.GET_DASHBOARD_STATS: {
        draft.taskStatus = undefined;
        draft.taskSummary = undefined;
        break;
      }
      case DashboardActionType.GET_DASHBOARD_STATS_COMPLETED: {
        const { taskStatus, taskSummary } = action.payload;

        if (taskStatus) {
          draft.taskStatus = taskStatus;
        }
        if (taskSummary) {
          draft.taskSummary = taskSummary;
        }
        break;
      }
      case DashboardActionType.GET_DASHBOARD_STATS_ERROR: {
        draft.statsError = action.payload;
        break;
      }
      default:
        break;
    }
  });
