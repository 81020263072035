import { Box } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import React, { FC, memo } from 'react';
import * as Yup from 'yup';
import { Connector } from 'protos/pb/v1alpha2/connector';

interface Props {
  connector: Connector;
  onSubmit: (values: FormikValues) => void;
}

const ConnectorGeneralContent: FC<Props> = ({ onSubmit, connector }) => {
  const formik = useFormik({
    initialValues: {
      connector_name: connector.displayName || '',
      description: connector.description || '',
    },
    validationSchema: Yup.object({
      connector_name: Yup.string()
        .required('Connector name is required.')
        .matches(/^[^/]*$/, 'Connector name cannot contain "/".')
        .max(100, 'Connector name must be at most 100 characters.'),
    }),
    onSubmit: onSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id='form1' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box display={'flex'} gap={'50px'} justifyContent={'space-between'}>
          <Box width={'70%'} marginBottom={'15px'}>
            <Box marginLeft={'14px'} marginTop={'21px'}>
              <CustomTextField
                label='Connector name'
                name='connector_name'
                size='small'
                value={''}
                extraProps={getFieldProps('connector_name')}
                error={Boolean(touched.connector_name && errors.connector_name)}
                helperText={touched.connector_name && errors.connector_name}
                hasDescription={true}
                autoFocus
              />
            </Box>
            <Box marginLeft={'14px'} marginTop={'11px'}>
              <CustomTextField
                label='Description'
                name='description'
                size='small'
                value={''}
                extraProps={getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(ConnectorGeneralContent);
