import * as React from 'react';
import {
  Box,
  BoxProps,
  Typography,
  styled,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';
import { OrbyCheckbox } from 'orby-ui/src';
import { grey } from '@mui/material/colors';
import { WorkflowActionsForReview } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  LOW_CONFIDENCE_THRESHOLD,
  HIGH_CONFIDENCE_THRESHOLD,
} from '../../../utils/constants';

const OptionContainer = styled(Box)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    borderRadius: '8px',
    border: `1px solid ${checked ? theme.palette.secondary.main : '#D2D2D2'}`,
    cursor: 'pointer',
  }),
);

const OptionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(4),
}));

const OptionBody = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(2),
  backgroundColor: 'rgba(227, 252, 239, 0.3)',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
}));

const OptionBodyText = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '20px',
  color: grey[700],
}));

const Title = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  color: grey[700],
}));

const Description = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: grey[500],
}));

interface SettingsOptionProps extends Omit<BoxProps, 'onChange'> {
  title: string;
  description: string;
  children?: JSX.Element;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const SettingsOption: React.FC<SettingsOptionProps> = ({
  title,
  description,
  checked,
  onChange,
  children,
  ...props
}) => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(!checked);
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(!checked);
  };

  return (
    <OptionContainer checked={checked} onClick={onClick} {...props}>
      <OptionHeader>
        <Box justifyContent='space-between'>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Box>
        <OrbyCheckbox
          checked={checked}
          onClick={handleCheckboxClick}
          size='md'
          title={title}
        />
      </OptionHeader>
      {checked && children}
    </OptionContainer>
  );
};

interface ActiveReviewModeSettingOptionProps {
  actionsForReview: WorkflowActionsForReview;
  setActionsForReview(actionsForReview: WorkflowActionsForReview): void;
}

export function ActiveReviewModeSettingsOption(
  props: ActiveReviewModeSettingOptionProps,
) {
  return (
    <SettingsOption
      title='Active review mode'
      description='Orby AI will present all tasks for user review, this means Orby will reach out to the reviewer for feedback on every step'
      checked={props.actionsForReview === WorkflowActionsForReview.ALL_ACTIONS}
      onChange={() => {
        props.setActionsForReview(WorkflowActionsForReview.ALL_ACTIONS);
      }}
      sx={{
        width: '588px',
      }}
    />
  );
}

interface AssistModeSettingOptionProps {
  actionsForReview: WorkflowActionsForReview;
  setActionsForReview(actionsForReview: WorkflowActionsForReview): void;
  lowConfidenceThreshold?: number;
  setLowConfidenceThreshold(threshold: number): void;
}

export function AssistModeSettingsOption(props: AssistModeSettingOptionProps) {
  const handleThresholdChange = async (event: SelectChangeEvent<number>) => {
    const value = event.target.value;
    if (typeof value === 'string') {
      console.error(`Unexpected value: ${value}`);
    } else {
      props.setLowConfidenceThreshold(value / 100);
    }
  };

  // it's tricky to compare float numbers, so here we convert to integers for comparison
  const normalizedThreshold = Math.floor(
    (props.lowConfidenceThreshold || 0.8) * 100,
  );
  const helperText =
    normalizedThreshold === LOW_CONFIDENCE_THRESHOLD
      ? 'Review is requested for predictions with low confidence, this mode requires human review less often'
      : 'Review is requested unless the predictions have high confidence, this mode requires human review more often';

  return (
    <SettingsOption
      title='Assisted mode (Default)'
      description='Orby will ask for help based on conditions like low confidence'
      checked={props.actionsForReview != WorkflowActionsForReview.ALL_ACTIONS}
      onChange={() =>
        props.setActionsForReview(
          WorkflowActionsForReview.LOW_CONFIDENCE_ACTIONS,
        )
      }
      sx={{
        width: '588px',
      }}
    >
      <OptionBody spacing={2}>
        <Stack direction='row' spacing={4} alignItems='center'>
          <OptionBodyText>
            Trigger human review when the confidence score is{' '}
          </OptionBodyText>
          <FormControl size='small'>
            <Select
              value={normalizedThreshold}
              onChange={handleThresholdChange}
              inputProps={{
                'aria-label': `${normalizedThreshold}`,
              }}
            >
              <MenuItem value={HIGH_CONFIDENCE_THRESHOLD}>Show more</MenuItem>
              <MenuItem value={LOW_CONFIDENCE_THRESHOLD}>Show less</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <OptionBodyText>{helperText}</OptionBodyText>
      </OptionBody>
    </SettingsOption>
  );
}
