import { Collapse } from '@mui/material';
import React, { useState } from 'react';

import ActionItem from './ActionItem';
import {
  ColumnContainer,
  ExecutionContainer,
  ItemDescription,
  WidgetContainer,
  ActionContainer,
} from './Styled';
import {
  getNumStepsForAction,
  calcForEachExecutions,
} from 'workflow-utils/src/v2/helper';
import { ScreenChip, SuccessChip } from './Chip';
import { OrbyColorPalette } from 'orby-ui/src';
import Dot from '../../../../../../components/Dot';
import { ActionObject, ExecutedActionObject } from 'workflow-utils/src/types';
import { cumulativeSum } from 'workflow-utils/src/helper';

interface IForeachExecutionProps {
  action: ActionObject;
  // sliced from the original executedActions based on the executionInfo.
  executedActions: ExecutedActionObject[];
  startIndex: number;
  numSteps: number;
  executionIndex: number;
}

const ForeachExecution: React.FC<IForeachExecutionProps> = ({
  action,
  startIndex,
  executionIndex,
  numSteps,
  executedActions,
}) => {
  const [isExecutionStepsOpen, setIsExecutionStepsOpen] = useState(false);
  const hasSteps = numSteps > 0;
  const actions = action.foreach?.loopActions || [];
  const startIndices = cumulativeSum(actions.map(getNumStepsForAction)).map(
    (i) => i + startIndex,
  );

  return (
    <ColumnContainer>
      <ExecutionContainer key={executionIndex} sx={{ gap: 3 }}>
        <WidgetContainer sx={{ gap: 3 }}>
          <Dot hexColor={OrbyColorPalette['green-500']} />
          <ItemDescription>Execution {executionIndex + 1}</ItemDescription>
        </WidgetContainer>
        {hasSteps && (
          <ScreenChip
            isOpen={isExecutionStepsOpen}
            label={`${numSteps} steps`}
            onClick={() => setIsExecutionStepsOpen(!isExecutionStepsOpen)}
          />
        )}
      </ExecutionContainer>
      {hasSteps && (
        <Collapse sx={{ width: '100%' }} in={isExecutionStepsOpen}>
          {actions.map((a, i) => (
            <ActionItem
              key={i}
              action={a}
              startIndex={startIndices[i]}
              executedActions={executedActions}
            />
          ))}
        </Collapse>
      )}
    </ColumnContainer>
  );
};

interface IForeachActionProps {
  action: ActionObject;
  executedActions: ExecutedActionObject[];
  startIndex: number;
  nextAction?: ActionObject;
}

const ForeachAction: React.FC<IForeachActionProps> = ({
  action,
  nextAction,
  startIndex,
  executedActions,
}) => {
  const executions = calcForEachExecutions(action, nextAction, executedActions);
  return (
    <ActionContainer sx={{ pl: 5 }}>
      <SuccessChip
        sx={{ mb: 2 }}
        label={`There are ${executions.length} executions in the loop`}
      />
      {executions.map((executionInfo, i) => (
        <ForeachExecution
          key={i}
          action={action}
          startIndex={startIndex}
          executionIndex={i}
          numSteps={executionInfo.numSteps}
          // This slice is super important to support nested foreach action.
          executedActions={executedActions.slice(
            executionInfo.startIndex,
            executionInfo.endIndex,
          )}
        />
      ))}
    </ActionContainer>
  );
};

export { ForeachExecution, ForeachAction };
