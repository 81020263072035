import { ArrowBack, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteInvoiceAction,
  deleteInvoiceErrorAction,
  listInvoicesAction,
  listInvoicesErrorAction,
  setSelectedInvoiceAction,
} from '../../redux/actions/erp.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteInvoiceErrorSelector,
  deletingInvoiceSelector,
  invoiceListSelector,
  invoiceLoadingSelector,
  listInvoiceErrorSelector,
} from '../../redux/selectors/erp.selectors';
import { loggedInUserSelector } from '../../redux/selectors/user.selectors';
import {
  DeleteInvoiceRequest,
  InvoiceSTATUS,
  ListInvoicesRequest,
} from 'protos/pb/demo/invoice_service';
import useOutsideClickDetector from '../../hooks/useOutsideClickDetector';
import { Form, FormikProvider, useFormik } from 'formik';
import { toastService } from '../../services/ToastService';
import '../../styles/table.css';

const ListInvoicePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoicesLoading = useSelector(invoiceLoadingSelector);
  const invoicesList = useSelector(invoiceListSelector);
  const listInvoiceError = useSelector(listInvoiceErrorSelector);
  const deletingInvoice = useSelector(deletingInvoiceSelector);
  const deleteInvoiceError = useSelector(deleteInvoiceErrorSelector);
  const user = useSelector(loggedInUserSelector);
  const [popupIndex, setPopupIndex] = useState<string | undefined>(undefined);

  const ref = useOutsideClickDetector<HTMLDivElement>(() =>
    setPopupIndex(undefined),
  );

  useEffect(() => {
    if (!invoicesList.length) {
      const req: ListInvoicesRequest = {};
      req.parent = `users/${user?.email as string}`;
      dispatch(listInvoicesAction(req));
    }
  }, []);

  useEffect(() => {
    if (listInvoiceError) {
      toastService.showWarning(listInvoiceError);
      dispatch(listInvoicesErrorAction(undefined));
    }
    if (deleteInvoiceError) {
      toastService.showWarning(deleteInvoiceError);
      dispatch(deleteInvoiceErrorAction(undefined));
    }
  }, [listInvoiceError, deleteInvoiceError]);

  const getFilterString = (values: {
    number: string;
    status: undefined;
    vendor_name: undefined;
    from: string;
    to: string;
  }) => {
    let filter = '';
    Object.entries(values).map((val) => {
      if (val[1]) {
        if (val[0] == 'from') {
          filter += `due_date>=${val[1]},`;
        } else if (val[0] == 'to') {
          filter += `due_date<=${val[1]},`;
        } else {
          filter += `${val[0]}=${val[1]},`;
        }
      }
    });
    if (filter.length > 0) {
      return filter.substring(0, filter.length - 1);
    }
    return filter;
  };

  const formik = useFormik({
    initialValues: {
      number: '',
      status: undefined,
      vendor_name: undefined,
      from: '',
      to: '',
    },
    onSubmit: (values) => {
      const req: ListInvoicesRequest = {};
      req.parent = `users/${user?.email as string}`;
      req.filter = getFilterString(values);
      dispatch(listInvoicesAction(req));
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Box sx={{ paddingX: '45px', paddingY: '15px' }}>
      <Box display={'flex'}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack
            sx={{ color: 'black', fontSize: '32px', paddingRight: '12px' }}
          />
        </IconButton>
        <Typography fontSize={'48px'} fontWeight={700}>
          My ERP
        </Typography>
      </Box>
      <Typography fontSize={'36px'} fontWeight={700} marginTop={'30px'}>
        My Invoices
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box marginBottom={'15px'} marginTop={'25px'} display={'flex'}>
            <TextField
              autoComplete='number'
              size='small'
              label='Invoice #'
              InputLabelProps={{ shrink: true }}
              sx={{ marginRight: '15px', width: '12%' }}
              {...getFieldProps('number')}
            />
            <FormControl
              size='small'
              sx={{ marginRight: '20px', width: '12%' }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                placeholder='Status'
                label='Status'
                {...getFieldProps('status')}
              >
                <MenuItem key={'draft'} value={'draft'}>
                  DRAFT
                </MenuItem>
                <MenuItem key={'submitted'} value={'submitted'}>
                  SUBMITTED
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoComplete='vendor_name'
              size='small'
              label='Vendor Name'
              InputLabelProps={{ shrink: true }}
              sx={{ marginRight: '15px', width: '12%' }}
              {...getFieldProps('vendor_name')}
            />
            {/* this will be undone in future */}
            {/* <TextField
              autoComplete="from"
              type="date"
              size="small"
              label="From"
              InputLabelProps={{ shrink: true }}
              sx={{ marginRight: '15px', width: '12%' }}
              {...getFieldProps('from')}
            />
            <TextField
              autoComplete="to"
              label="To"
              type="date"
              size="small"
              InputLabelProps={{ shrink: true }}
              sx={{ marginRight: '15px', width: '12%' }}
              {...getFieldProps('to')}
            /> */}
            <Box sx={{ width: '12%' }}>
              <Button color='primary' variant='contained' type='submit'>
                APPLY
              </Button>
            </Box>
            <Box
              sx={{ width: '38%' }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'end'}
            >
              <Button
                color='primary'
                variant='contained'
                type='button'
                id={'create-invoice-btn'}
                onClick={() => {
                  dispatch(setSelectedInvoiceAction(undefined));
                  navigate('/erp/create-invoice');
                }}
              >
                CREATE AN INVOICE
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
      {invoicesLoading ? (
        <Box display={'flex'} justifyContent={'center'}>
          {' '}
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            sx={{ paddingBottom: '30px' }}
            className={'table-header-style'}
          >
            <Table id={'invoices-table'}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 550 }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Due date</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Invoice #</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Vendor Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoicesList.map((i, index) => (
                  <TableRow key={index} component={'tr'}>
                    <TableCell>
                      {InvoiceSTATUS[i.status as InvoiceSTATUS]}
                    </TableCell>
                    <TableCell>{i.invoiceDueDate}</TableCell>
                    <TableCell>{i.number}</TableCell>
                    <TableCell>{i.vendorName}</TableCell>
                    <TableCell>
                      <Box position={'relative'}>
                        {popupIndex === i.name && (
                          <Card
                            ref={ref}
                            sx={{
                              position: 'absolute',
                              zIndex: 30,
                              paddingX: '12px',
                              paddingY: '8px',
                              left: '40px',
                            }}
                          >
                            <Box
                              width={'95px'}
                              paddingX={'12px'}
                              paddingY={'4px'}
                              sx={{
                                ':hover': { backgroundColor: '#1669F752' },
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                dispatch(setSelectedInvoiceAction(i));
                                navigate('/erp/create-invoice');
                              }}
                            >
                              <Typography fontWeight={550}>Edit</Typography>
                            </Box>
                            <Box
                              sx={{
                                ':hover': { backgroundColor: '#1669F752' },
                                cursor: 'pointer',
                              }}
                              width={'95px'}
                              paddingX={'12px'}
                              paddingY={'4px'}
                              onClick={() => {
                                const req: DeleteInvoiceRequest = {};
                                req.name = i.name;
                                dispatch(deleteInvoiceAction(req));
                              }}
                            >
                              {deletingInvoice ? (
                                <CircularProgress size={30} />
                              ) : (
                                <Typography fontWeight={550}>Delete</Typography>
                              )}
                            </Box>
                          </Card>
                        )}
                        <IconButton
                          onClick={() => {
                            setPopupIndex(i.name);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default React.memo(ListInvoicePage);
