import {
  Box,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  TableHead,
} from '@mui/material';
import CustomTableCell from '../../../../../../../components/CustomTableCell';
import { Reorder, useDragControls } from 'framer-motion';
import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedEntityIdsForAnnotationAction,
  showColumnsForTableAnnotationAction,
  updateOrderOfTableEntitiesAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  ELLIPSIS_STYLE,
  MIN_TABLE_POPOVER_CELL_WITH,
} from '../../../../../../../utils/constants';
import { ReactComponent as ReorderHeaderSvg } from '../../../../../../../static/icons/reorder-header.svg';
import { MoreVert } from '@mui/icons-material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import TableModalHeaderMenu from './TableModalHeaderMenu';
import { selectedEntityIdsForAnnotationSelector } from '../../../../../../../redux/selectors/review_task.selectors';

interface Props {
  rows: EntityInfo[];
  getHiddenRows: () => EntityInfo[];
  setDraggingElemId: (x: string) => void;
  handleHeaderCellKeyDown: (e: React.KeyboardEvent, index: number) => void;
  selectedEntitiesByRowOrder: EntityInfo[];
  handleEntityHeaderClick: (e: EntityInfo) => void;
  columnTypeToResize: string;
  showResizeLine: (x: string) => void;
  hideResizeLine: () => void;
  startResizingColumn: (e: React.MouseEvent, type: string) => void;
}

interface ItemProps extends Props {
  row: EntityInfo;
  index: number;
}

const ReorderItem: FC<ItemProps> = ({
  rows,
  row,
  setDraggingElemId,
  index,
  handleHeaderCellKeyDown,
  selectedEntitiesByRowOrder,
  handleEntityHeaderClick,
  columnTypeToResize,
  showResizeLine,
  hideResizeLine,
  startResizingColumn,
}) => {
  const dispatch = useDispatch();
  const controls = useDragControls();
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const getBorder = (row: EntityInfo) => {
    const entitiesWithSameType = selectedEntitiesByRowOrder?.filter(
      (e) => e.type === row.type,
    );
    // Check if all entities are selected
    if (
      document.activeElement?.id === `cell-${row.type}` &&
      !entitiesWithSameType?.some(
        (e) => !selectedEntityIdsForAnnotation.includes(e.id),
      )
    ) {
      return `2px solid ${OrbyColorPalette['blue-700']}`;
    }
    return `0px solid transparent`;
  };

  return (
    <Reorder.Item
      id={`cell-${row.type}`}
      as='th'
      transition={{ duration: 0 }}
      value={row}
      dragListener={false}
      dragControls={controls}
      onDrag={() => setDraggingElemId(`cell-${row.type}`)}
      onDragEnd={() => {
        setDraggingElemId('');
      }}
      title={row.type}
      tabIndex={0}
      onKeyDown={(e) => handleHeaderCellKeyDown(e, index)}
      onKeyUpCapture={(e) => {
        if (e.key === 'Tab') {
          if (document.activeElement?.id === `cell-${row.type}`) {
            const columnElementType = document.activeElement.id.replace(
              'cell-',
              '',
            );
            const allEntitiesWithSameType = selectedEntitiesByRowOrder?.filter(
              (e) => e.type === columnElementType,
            );
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                allEntitiesWithSameType.map((e) => e.id),
              ),
            );
          }
        }
      }}
      // onClick does not prevent focus while on down does
      onClick={() => {
        handleEntityHeaderClick(row);
      }}
      style={{
        listStyleType: 'none',
        maxWidth: '100%',
        cursor: 'pointer',
        outline: 'none',
        position: 'relative',
        padding: '2px 8px',
        width: `${MIN_TABLE_POPOVER_CELL_WITH}px`,
        border: `1px solid ${OrbyColorPalette['grey-300']}`,
      }}
    >
      {/* This is to show border on header cell */}
      <Box
        sx={{
          position: 'absolute',
          top: '-0.5px',
          left: '-0.6px',
          right: '-0.5px',
          bottom: '-1px',
          zIndex: 1,
          border: getBorder(row),
          // Hide bottom border to avoid double borders.
          borderBottom: 'none',
        }}
      />
      <Box
        key={row.type}
        onPointerDown={(e) => controls.start(e)}
        sx={{
          cursor: 'grab',
          ':active': { cursor: 'grabbing' },
          height: '18px',
          position: 'absolute',
          opacity: '0',
          ':hover': {
            opacity: '1',
          },
          width: '100%',
          top: '-21px',
          left: '0px',
        }}
      >
        <ReorderHeaderSvg title='Drag to move' />
      </Box>
      {/* TODO: Wrap Column name upto 3 lines */}
      <Box display={'flex'} justifyContent={'space-between'}>
        <OrbyTypography
          weight='medium'
          sx={{
            ...ELLIPSIS_STYLE,
            maxWidth: '100%',
          }}
        >
          {row.type}
        </OrbyTypography>
        <TableModalHeaderMenu
          row={row}
          disabled={rows.length === 1}
          showSmall
        />
      </Box>
      <Box
        role='none'
        sx={{
          position: 'absolute',
          top: 0,
          right: '-1px',
          zIndex: 3,
          width: '2px',
          height: '105%',
          opacity: columnTypeToResize === row.type ? '1' : '0',
          backgroundColor: OrbyColorPalette['blue-700'],
          cursor: 'col-resize',
        }}
        onMouseEnter={() => showResizeLine(row.type)}
        onMouseLeave={hideResizeLine}
        onMouseDown={(e) => {
          startResizingColumn(e, `cell-${row.type}`);
        }}
      />
    </Reorder.Item>
  );
};

const TableHeader: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { rows, getHiddenRows } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <TableHead>
      <Reorder.Group
        as='tr'
        axis='x'
        values={rows}
        onReorder={(entityList: EntityInfo[]) => {
          dispatch(
            updateOrderOfTableEntitiesAction([
              ...entityList,
              ...getHiddenRows(),
            ]),
          );
        }}
      >
        <CustomTableCell
          sx={{ width: '20px', padding: '0px', border: 'none' }}
        />
        <CustomTableCell
          width={'34px'}
          padding='none'
          sx={{ border: `1px solid ${OrbyColorPalette['grey-300']}` }}
        />
        {rows?.map((row, index) => (
          <ReorderItem index={index} key={row.type} row={row} {...props} />
        ))}
        <CustomTableCell
          ellipsis={false}
          sx={{
            border: 'none',
            width: '34px',
            paddingY: '3px',
            paddingX: '5px',
          }}
        >
          <IconButton
            onClick={handleMenuClick}
            sx={{ width: '24px', height: '24px' }}
          >
            <MoreVert
              sx={{ width: '18px', color: OrbyColorPalette['black-0'] }}
            />
          </IconButton>
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            open={openMenu}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
            sx={{
              '& .MuiPopover-paper': {
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                dispatch(showColumnsForTableAnnotationAction());
                setAnchorEl(null);
              }}
            >
              <OrbyTypography sx={{ color: OrbyColorPalette['black-0'] }}>
                Show all
              </OrbyTypography>
            </MenuItem>
          </Menu>
        </CustomTableCell>
        {/* DO NOT REMOVE This Extra Table Cell, 
                  it ensure correct resizing of table columns */}
        <CustomTableCell
          sx={{ visibility: 'hidden', padding: '0px', border: 'none' }}
        />
      </Reorder.Group>
    </TableHead>
  );
};

export default memo(TableHeader);
