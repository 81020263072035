import { Reorder, useDragControls } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { TableBodyProps } from '../TableBody';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  allowEditingTaskSelector,
  rowOrderInfoForSelectedParentEntitySelector,
  selectedEntityIdsForAnnotationSelector,
  selectedParentEntityInfoSelector,
} from '../../../../../../../../../redux/selectors/review_task.selectors';
import { EntityInfo } from '../../../../../../../../../redux/reducers/review_task.reducer';
import { focusNextElement } from '../../../../../../../../../utils/FocusUtils';
import {
  deleteSelectedEntity,
  setSelectedEntityIdsForAnnotationAction,
} from '../../../../../../../../../redux/actions/review_task.action';
import CustomTableCell from '../../../../../../../../../components/CustomTableCell';
import { Box } from '@mui/material';
import { ReactComponent as ReorderBodySvg } from '../../../../../../../../../static/icons/reorder-body.svg';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import RowCellMenu from './RowCellMenu';
import TableRowCell from './TableRowCell';
import NormalizationRow from './NormalizationRow';

interface Props extends TableBodyProps {
  id: string;
  rowIndex: number;
}
const ReorderTableRow: FC<Props> = (props) => {
  const {
    id,
    getChildEntitiesFromParentId,
    setDraggingElemId,
    rowIndex,
    columnTypeToResize,
    showResizeLine,
    hideResizeLine,
    startResizingColumn,
    showNormalizedValues,
  } = props;

  const dispatch = useDispatch();
  const controls = useDragControls();

  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );

  const rowOrderInfoForTableEntities = useSelector(
    rowOrderInfoForSelectedParentEntitySelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const [action, setAction] = useState<{
    cell?: EntityInfo;
    event?: Event;
    type: string;
  } | null>(null);
  const focusNextElem = useCallback(() => {
    const table = document.getElementById('table-modal-body');

    if (table) {
      const focusableElements = table.querySelectorAll(
        '[tabindex]:not([tabindex="-1"])',
      );
      const currentElement = document.getElementById(`cell-${id}-menu-button`);
      focusNextElement(focusableElements, currentElement as Element);
    }
  }, [id]);

  const handleDrag = useCallback(() => setDraggingElemId(id), [id]);
  const handleDragEnd = useCallback(() => {
    setDraggingElemId('');
  }, []);
  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => controls.start(e),
    [],
  );

  const handleDelete = useCallback(() => {
    // focus next element before remove the row to retain the focus
    focusNextElem();

    const childEntities = getChildEntitiesFromParentId(id) || [];
    // We are removing those selected entity ids which are being deleted using the button
    const remainingEntities = selectedEntityIdsForAnnotation.filter(
      (e) => !childEntities.find((c) => c.id === e),
    );
    dispatch(setSelectedEntityIdsForAnnotationAction(remainingEntities));
    dispatch(deleteSelectedEntity(id));
  }, [
    focusNextElem,
    id,
    getChildEntitiesFromParentId,
    selectedEntityIdsForAnnotation,
  ]);

  const hideBorderTop = useCallback(
    (cell: EntityInfo) => {
      // Keep the top border for the first row or when normalized values are shown
      if (rowIndex === 0 || showNormalizedValues) {
        return false;
      }
      const entitiesWithSameType = props.getEntitiesWithSameType(cell.type);
      // Hide the top border if the previous row's cell with the same type is selected.
      return selectedEntityIdsForAnnotation.includes(
        entitiesWithSameType[rowIndex - 1]?.id,
      );
    },
    [
      rowIndex,
      showNormalizedValues,
      selectedEntityIdsForAnnotation,
      props.getEntitiesWithSameType,
    ],
  );

  useEffect(() => {
    if (action) {
      setAction(null);
      switch (action.type) {
        case 'CELL_CLICK': {
          props.handleEntityRowClick(action.event as any, action.cell!);
          break;
        }
        case 'CELL_KEY_DOWN': {
          props.handleTableCellKeyDown(action.event as any, action.cell!);
          break;
        }
        case 'ROW_DELETE': {
          handleDelete();
          break;
        }
      }
    }
  }, [action]);
  return (
    <>
      <Reorder.Item
        as='tr'
        id={id}
        value={id}
        transition={{ duration: 0 }}
        style={{
          listStyleType: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
        dragListener={false}
        dragControls={controls}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        <CustomTableCell
          sx={{
            padding: '0px',
            borderBottom: 'none',
            position: 'relative',
          }}
        >
          <Box
            onPointerDown={handlePointerDown}
            sx={{
              cursor: 'grab',
              ':active': { cursor: 'grabbing' },
              height: '30px',
              position: 'absolute',
              opacity: '0',
              ':hover': {
                opacity: '1',
              },
              width: '100%',
              top: 0,
              left: '-2px',
            }}
          >
            <ReorderBodySvg title='Drag to move' />
          </Box>
        </CustomTableCell>
        <CustomTableCell
          width={'34px'}
          padding='none'
          sx={{
            border: `1px solid ${OrbyColorPalette['grey-300']}`,
          }}
        >
          <OrbyTypography
            color={OrbyColorPalette['grey-500']}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {rowIndex + 1}
          </OrbyTypography>
        </CustomTableCell>
        {getChildEntitiesFromParentId(id)?.map((cell: EntityInfo) => (
          <TableRowCell
            allowEditingTask={!!allowEditingTask}
            key={cell.id}
            isShowResizeLine={props.columnTypeToResize === cell.type}
            cell={cell}
            isCellSelected={selectedEntityIdsForAnnotation.includes(cell.id)}
            hideBorderTop={hideBorderTop(cell)}
            openTextPopover={props.openTextPopover}
            setClipboardEntity={props.setClipboardEntity}
            handlePaste={props.handlePaste}
            clipboardEntity={props.clipboardEntity}
            showResizeLine={props.showResizeLine}
            hideResizeLine={props.hideResizeLine}
            startResizingColumn={props.startResizingColumn}
            setAction={setAction}
          />
        ))}
        <RowCellMenu
          key={id}
          setAction={setAction}
          selectedParentEntityInfoId={selectedParentEntityInfo?.id}
          rowId={id}
          allowEditingTask={!!allowEditingTask}
          isMoreThanOneRowPresent={rowOrderInfoForTableEntities.length > 1}
          rowIndex={rowIndex}
        />
      </Reorder.Item>
      {showNormalizedValues && (
        <NormalizationRow
          id={id}
          getChildEntitiesFromParentId={getChildEntitiesFromParentId}
          columnTypeToResize={columnTypeToResize}
          hideResizeLine={hideResizeLine}
          showResizeLine={showResizeLine}
          startResizingColumn={startResizingColumn}
        />
      )}
    </>
  );
};

export default memo(ReorderTableRow);
