import produce from 'immer';
import { Reducer } from 'redux';
import { AutomationMiningActionType } from '../actions/actions.constants';

export interface AutomationMiningState {
  mimeType: string;
  activityGraph: string;
  loadingActivityGraph: boolean;
  activityGraphLoaded: boolean;
}

const initialState: AutomationMiningState = {
  mimeType: '',
  activityGraph: '',
  loadingActivityGraph: false,
  activityGraphLoaded: false,
};

export const automationMiningReducer: Reducer<AutomationMiningState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AutomationMiningState = initialState,
  action,
) =>
  produce(state, (draft: AutomationMiningState) => {
    switch (action.type) {
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH: {
        draft.loadingActivityGraph = true;
        draft.activityGraphLoaded = true;
        break;
      }
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_COMPLETED: {
        draft.mimeType = action.payload.mimeType;
        draft.activityGraph = action.payload.activityGraph;
        draft.loadingActivityGraph = false;
        draft.activityGraphLoaded = false;
        break;
      }
      case AutomationMiningActionType.GENERATE_ACTIVITY_GRAPH_ERROR:
        draft.mimeType = '';
        draft.activityGraph = '';
        draft.loadingActivityGraph = false;
        draft.activityGraphLoaded = false;
        break;
      default:
        break;
    }
  });
