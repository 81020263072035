import produce from 'immer';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { Reducer } from 'redux';
import { WorkflowDetailsType } from '../actions/actions.constants';
import {
  ActionableElementObject,
  ActionObject,
} from 'workflow-utils/src/types';
import {
  addActionAfter,
  getActionById,
  getActions,
  removeAction,
} from 'workflow-utils/src/v2/workflow';
import * as uuid from 'uuid';

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export enum WidgetType {
  WidgetSelector = 'WidgetSelector',
  IfElse = 'IfElse',
  Click = 'Click',
  Checkbox = 'Checkbox',
  Loop = 'Loop',
  Extract = 'Extract',
  Compare = 'Compare',
}

export interface WorkflowDetailsState {
  workflow: Workflow | null;
  actionableElementsDict: Record<string, ActionableElementObject[]>;
  status: Status;
  hasUnsavedChanges: boolean;
  invalidateChanges: Set<string>;
  loading: boolean;
}

export const initialState: WorkflowDetailsState = {
  workflow: null,
  actionableElementsDict: {},
  status: Status.Idle,
  hasUnsavedChanges: false,
  invalidateChanges: new Set<string>(),
  loading: true,
};

export const workflowDetailsReducer: Reducer<WorkflowDetailsState> = (
  state: WorkflowDetailsState = initialState,
  action: any,
) =>
  produce(state, (draft: WorkflowDetailsState) => {
    switch (action.type) {
      case WorkflowDetailsType.SET_WORKFLOW_SUCCESS: {
        draft.workflow = action.payload;
        break;
      }
      case WorkflowDetailsType.CHANGE_LOADING_STATUS: {
        draft.loading = action.payload;
        break;
      }
      case WorkflowDetailsType.GET_WORKFLOW_FAILURE: {
        draft.workflow = action.payload;
        draft.loading = false;
        break;
      }

      case WorkflowDetailsType.UPDATE_WORKFLOW_TITLE: {
        const title = action.payload.trim();
        draft.workflow = {
          ...draft.workflow,
          displayName: title,
        };
        draft.hasUnsavedChanges = true;
        draft.invalidateChanges = title
          ? new Set(
              [...state.invalidateChanges].filter(
                (field) => field !== 'displayName',
              ),
            )
          : new Set([...state.invalidateChanges, 'displayName']);
        break;
      }
      case WorkflowDetailsType.SET_HAS_UNSAVED_CHANGES: {
        draft.hasUnsavedChanges = action.payload;
        break;
      }
      case WorkflowDetailsType.UPDATE_STATUS: {
        draft.status = action.payload;
        break;
      }
      case WorkflowDetailsType.ADD_INVALID_CHANGE: {
        draft.invalidateChanges = new Set([
          ...state.invalidateChanges,
          action.payload,
        ]);
        break;
      }
      case WorkflowDetailsType.DELETE_INVALID_CHANGE: {
        draft.invalidateChanges = new Set(
          [...state.invalidateChanges].filter(
            (field) => field !== action.payload,
          ),
        );
        break;
      }
      case WorkflowDetailsType.SET_ACTIONS_FOR_REVIEW: {
        if (draft.workflow) {
          draft.workflow.actionsForReview = action.payload;
          draft.hasUnsavedChanges = true;
        }
        break;
      }
      case WorkflowDetailsType.SET_LOW_CONFIDENCE_THRESHOLD: {
        if (draft.workflow) {
          draft.workflow.lowConfidenceThreshold = action.payload;
          draft.hasUnsavedChanges = true;
        }
        break;
      }
      case WorkflowDetailsType.ADD_REVIEWER: {
        if (draft.workflow) {
          if (draft.workflow.reviewerIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.reviewerIds = [
            ...(draft.workflow.reviewerIds ?? []),
            action.payload,
          ];
          draft.hasUnsavedChanges = true;
        }

        break;
      }

      case WorkflowDetailsType.REMOVE_REVIEWER: {
        if (draft.workflow) {
          if (!draft.workflow.reviewerIds?.includes(action.payload)) {
            break;
          }

          draft.workflow.reviewerIds = (
            draft.workflow.reviewerIds ?? []
          ).filter((reviewerId) => reviewerId !== action.payload);

          draft.hasUnsavedChanges = true;
        }
        break;
      }

      case WorkflowDetailsType.ADD_ACTION_AFTER: {
        const { actionId, processId } = action.payload;

        const actions = getActions(draft.workflow!, processId);
        addActionAfter(
          {
            // TODO: we currently rely on the widget type for creating empty action
            // We should be able to deduct from an empty action and remove the need to pass widget type
            widget: WidgetType.WidgetSelector,
            description: '',
            id: uuid.v4(),
          },
          actionId,
          actions,
        );

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.REMOVE_ACTION: {
        const { actionId, processId } = action.payload;
        const actions = getActions(draft?.workflow, processId);
        const actionById = getActionById(actionId, actions);

        let referencedId: string | undefined = undefined;
        if (actionById?.condition?.thenActions) {
          referencedId = actionById.condition!.condition!.referenceValue;
        } else if (actionById?.setValue) {
          referencedId = actionById.setValue!.fieldValue?.referenceValue;
        }

        if (referencedId) {
          removeAction(referencedId, actions ?? []);
        }

        removeAction(actionId, actions ?? []);

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.ADD_FALSE_CONDITION_ACTION: {
        const { actionId, processId } = action.payload;

        const actions = getActions(draft.workflow, processId);
        const actionById = getActionById(actionId, actions);
        if (
          actionById?.condition &&
          (actionById.condition.thenActions ?? []).length > 0 &&
          (actionById.condition.elseActions ?? []).length === 0
        ) {
          actionById.condition.elseActions = [
            {
              // TODO: we currently rely on the widget type for creating empty action
              // We should be able to deduct from an empty action and remove the need to pass widget type
              widget: WidgetType.WidgetSelector,
              description: '',
              id: uuid.v4(),
            } as ActionObject,
          ];
        }

        // Mark the draft state as having unsaved changes.
        draft.hasUnsavedChanges = true;

        break;
      }

      case WorkflowDetailsType.UPDATE_ACTION: {
        const {
          action: actionItem,
          updated,
          processId,
        }: {
          action: ActionObject;
          updated: ActionObject;
          processId?: string;
        } = action.payload;

        if (!updated) {
          break;
        }

        const id = actionItem.id;
        const actions = getActions(draft.workflow, processId);
        const selectedElementToUpdate = getActionById(id!, actions!)!;

        Object.assign(selectedElementToUpdate, updated.action);
        selectedElementToUpdate.description = updated.description;

        draft.hasUnsavedChanges = true;

        break;
      }

      default:
        break;
    }
  });
