import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import {
  TasksClientImpl,
  Task,
  GetTaskRequest,
  CopyTasksResponse,
  CopyTasksRequest,
  UpdateBatchTasksResponse,
  UpdateBatchTasksRequest,
  DeleteBatchTasksRequest,
  DeleteBatchTasksResponse,
  RetryTasksRequest,
  RetryTasksResponse,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  ListTasksResponse,
  ListTasksRequest,
  CreateTaskRequest,
  UpdateTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import { storageService } from './StorageService';

export class TasksV2Service {
  private static instance: TasksV2Service;

  private static client = new TasksClientImpl(rpcWithErrorHandling);

  public static getInstance(): TasksV2Service {
    if (!this.instance) {
      this.instance = new TasksV2Service();
    }
    return this.instance;
  }

  async getTasksList(
    req: ListTasksRequest,
  ): Promise<{ response?: ListTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.ListTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createTask(
    req: CreateTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.CreateTask(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateTask(
    req: UpdateTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.UpdateTask(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateBatchTasks(
    req: UpdateBatchTasksRequest,
  ): Promise<{ response?: UpdateBatchTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.UpdateBatchTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getTask(
    req: GetTaskRequest,
  ): Promise<{ response?: Task; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.GetTask(
        req,
        getMetaData({ authorization }),
      );
      return { response: Task.fromJSON(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteTask(
    req: DeleteBatchTasksRequest,
  ): Promise<{ response?: DeleteBatchTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.DeleteBatchTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async copyTask(
    req: CopyTasksRequest,
  ): Promise<{ response?: CopyTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.CopyTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async retryTask(
    req: RetryTasksRequest,
  ): Promise<{ response?: RetryTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await TasksV2Service.client.RetryTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const tasksV2Service = TasksV2Service.getInstance();
