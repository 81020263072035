import { Box, StepIconProps } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import StepIcon from '../../pages/workflow-creation/StepIcon';
import React, { FC, memo } from 'react';
import { STEP_CONTENT_WIDTH } from '../../utils/constants';

interface WorkflowCreationStepProps extends StepIconProps {
  title: string;
  iconColor: string;
  background: string;
  isNumberType?: boolean;
  index?: number;
  ariaLabel?: string;
  sx: any;
}

const WorkflowCreationStep: FC<WorkflowCreationStepProps> = (props) => {
  const {
    iconColor,
    title,
    background,
    completed: showSecondry,
    sx,
    isNumberType,
    index,
    ariaLabel,
  } = props;

  return (
    <Box
      bgcolor={background}
      height={'48px'}
      display={'flex'}
      width={STEP_CONTENT_WIDTH}
      maxWidth={STEP_CONTENT_WIDTH}
      sx={sx}
    >
      <StepIcon
        bgColor={iconColor}
        color={'white'}
        icon={
          isNumberType && (
            <CustomTypography
              component={'span'}
              typographyType={TypographyType.Header3}
            >
              {(index ?? 0) + 1}
            </CustomTypography>
          )
        }
        showSecondry={showSecondry}
      />
      <CustomTypography
        ariaLabel={ariaLabel}
        component={'span'}
        role='definition'
        color='#000000'
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 3,
          flex: 1,
        }}
      >
        {title}
      </CustomTypography>
      {isNumberType && (
        <StepIcon
          bgColor={background}
          color={iconColor}
          showSecondry={showSecondry}
        />
      )}
    </Box>
  );
};

export default memo(WorkflowCreationStep);
