import { SxProps, TableCell, TableCellProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';

interface CustomTableCellProps extends TableCellProps {
  children?: any;
  sx?: SxProps<Theme>;
  title?: string;
  ellipsis?: boolean;
  id?: string;
}

const CustomTableCell: FC<CustomTableCellProps> = (props) => {
  const { children, sx, title, ellipsis = true, id } = props;
  return (
    <TableCell
      {...props}
      id={id}
      title={title}
      sx={{
        ...sx,
        whiteSpace: ellipsis ? 'nowrap' : 'normal',
        overflow: ellipsis ? 'hidden' : 'normal',
        textOverflow: ellipsis ? 'ellipsis' : 'normal',
        maxWidth: '100%',
      }}
    >
      {children}
    </TableCell>
  );
};

export default memo(CustomTableCell);
