import React, { FC, memo, useCallback } from 'react';
import { EntityInfo } from '../../../../../../../../../redux/reducers/review_task.reducer';
import { useDispatch } from 'react-redux';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  setSelectedEntityIdsForAnnotationAction,
  updateEntityInfoForTableAnnotationAction,
} from '../../../../../../../../../redux/actions/review_task.action';
import { detectUndoRedoKeyPress } from '../../../../../../../../../utils/helpers';
import CustomTableCell from '../../../../../../../../../components/CustomTableCell';
import { getBackgroundColorForTableRows } from '../../../../../../../../../utils/ReviewTaskUtils';
import { Box } from '@mui/material';
import { ELLIPSIS_STYLE } from '../../../../../../../../../utils/constants';
import TableModalChildMenu from '../../TableModalChildMenu';
import ResizeLine from './ResizeLine';

interface Props {
  openTextPopover: (cell: EntityInfo) => void;
  setClipboardEntity: (cell: EntityInfo) => void;
  handlePaste: (cell: EntityInfo) => void;
  clipboardEntity: EntityInfo | undefined;
  cell: EntityInfo;
  showResizeLine: (type: string) => void;
  hideResizeLine: () => void;
  startResizingColumn: (e: React.MouseEvent, type: string) => void;
  allowEditingTask: boolean;
  isCellSelected: boolean;
  hideBorderTop: boolean;
  isShowResizeLine: boolean;
  setAction: React.Dispatch<
    React.SetStateAction<{
      cell?: EntityInfo;
      event?: Event;
      type: string;
    } | null>
  >;
}

const TableRowCell: FC<Props> = ({
  openTextPopover,
  setClipboardEntity,
  handlePaste,
  clipboardEntity,
  cell,
  showResizeLine,
  hideResizeLine,
  startResizingColumn,
  allowEditingTask,
  isCellSelected,
  hideBorderTop,
  isShowResizeLine,
  setAction,
}) => {
  const dispatch = useDispatch();

  const getBorder = useCallback(() => {
    return isCellSelected
      ? `2px solid ${OrbyColorPalette['blue-700']} !important`
      : '0px solid transparent';
  }, [isCellSelected]);

  const handleOnCopy = useCallback(() => {
    setClipboardEntity(cell);
  }, [cell]);

  const handleOnPaste = useCallback(() => {
    handlePaste(cell);
  }, [cell, handlePaste]);

  const handleShowResizeLine = useCallback(
    () => showResizeLine(cell.type),
    [cell.type],
  );

  const handleStartResizing = useCallback(
    (e: React.MouseEvent) => {
      startResizingColumn(e, `cell-${cell.type}`);
    },
    [cell.type],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isCellSelected) {
        // Regular expression to match lowercase letters, uppercase letters, and numbers
        const regex = /^[a-zA-Z0-9]$/;
        if (!(e.ctrlKey || e.metaKey) && regex.test(e.key)) {
          // Clear the text popover value when the popover is not active and the user starts typing in any cell,
          // mimicking Excel's behavior
          const newEntityInfo = {
            ...cell,
            entityText: '',
            extraEntityText: '', // for notes entity
          };
          if (allowEditingTask) {
            dispatch(
              updateEntityInfoForTableAnnotationAction(
                newEntityInfo as EntityInfo,
              ),
            );
          }
          openTextPopover(cell);
          return;
        }
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        openTextPopover(cell);
      }
      setAction({ event: e as any, cell, type: 'CELL_KEY_DOWN' });
    },
    [isCellSelected, cell.id, openTextPopover, allowEditingTask],
  );

  const handleDoubleClick = useCallback(
    () => openTextPopover(cell),
    [openTextPopover, cell.id],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
      setAction({ cell, event: e as any, type: 'CELL_CLICK' });
    },
    [cell.id],
  );

  const handleKeyUpCapture = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Tab') {
        if (document.activeElement?.id === `cell-${cell.id}`) {
          dispatch(setSelectedEntityIdsForAnnotationAction([cell.id]));
        }
      }
      if (detectUndoRedoKeyPress(e)) {
        e.preventDefault();
        handleClick(e as any);
      }
    },
    [cell.id, handleClick],
  );
  return (
    <CustomTableCell
      // prefix the id with 'cell' for focus detection purpose
      id={`cell-${cell.id}`}
      title={cell.isExtra ? cell.extraEntityText : cell.entityText}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onKeyUpCapture={handleKeyUpCapture}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}
      ellipsis={false}
      sx={{
        maxWidth: '100%',
        cursor: 'pointer',
        ':focus': {
          outline: 'none',
        },
        position: 'relative',
        paddingX: '8px',
        paddingY: '2px',
        border: `1px solid ${OrbyColorPalette['grey-300']}`,
        bgcolor: getBackgroundColorForTableRows(cell),
      }}
    >
      {/* This is to show border on body cell */}
      <Box
        sx={{
          position: 'absolute',
          top: '-0.5px',
          left: '-0.6px',
          right: '-0.5px',
          bottom: '-1px',
          zIndex: 1,
          border: getBorder(),
          borderTop: hideBorderTop ? 'none !important' : getBorder(),
        }}
      />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <OrbyTypography
          color={OrbyColorPalette['grey-500']}
          sx={{
            ...ELLIPSIS_STYLE,
            maxWidth: '100%',
          }}
        >
          {cell.isExtra ? cell.extraEntityText : cell.entityText}
        </OrbyTypography>
        {!cell.isExtra && (
          <TableModalChildMenu
            cell={cell}
            onCopy={handleOnCopy}
            onPaste={handleOnPaste}
            // Enable only if clipboardEntity is present and is not a notes entity
            enablePaste={!!clipboardEntity && !clipboardEntity.isExtra}
            openTextPopover={openTextPopover}
            showSmall
          />
        )}
      </Box>
      <ResizeLine
        open={isShowResizeLine}
        onMouseEnter={handleShowResizeLine}
        onMouseLeave={hideResizeLine}
        onMouseDown={handleStartResizing}
      />
    </CustomTableCell>
  );
};
export default memo(TableRowCell);
