import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import {
  addAlpha,
  allowSelectionForArchivedResources,
  formatDate,
  handleSelectAllClick,
  handleTaskCheckboxClick,
  isSelectAllChecked,
  isTaskSelected,
} from '../../utils/helpers';
import { MoreVert, CallMadeRounded, RefreshRounded } from '@mui/icons-material';
import CustomPagination from '../../components/Pagination/CustomPagination';
import {
  ArchivedResourceType,
  DeclinedTaskTabs,
  DISPLAY_NAME_PREFIX,
  ELLIPSIS_STYLE,
  FilteredResource,
  ActionType,
} from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import SearchTaskField from '../PendingTasks/SearchTaskField';
import TaskCheckbox from '../PendingTasks/TaskCheckbox';
import RestoreItemsConfirmationModel from './RestoreItemsConfirmationModel';
import RestoreItemsSuccessModel from './RestoreItemsSuccessModel';
import CustomTableLabel from '../../components/CustomTableLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  archivedResourcesListingErrorSelector,
  archivedResourcesListLoadingSelector,
  archivedResourcesListSelector,
  archivedResourcesRestoredSelector,
  archivedResourcesRestoringErrorSelector,
  archivedResourcesRestoringSelector,
  archivedResourcesTotalSizeSelector,
} from '../../redux/selectors/archived_resources.selectors';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import { toastService } from '../../services/ToastService';
import {
  ListArchivedResourcesRequest,
  RestoreArchivedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import {
  listArchivedResourcesAction,
  restoreArchivedResourcesAction,
  restoreArchivedResourcesErrorAction,
} from '../../redux/actions/archived_resource.action';
import ArchivedResourcesFilterSelect from './ArchivedResourcesFilterSelect';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { useNavigate } from 'react-router-dom';
import CustomToastContent from '../../components/CustomToastContent';
import { getPathAccToResource } from '../Utils/archivedResourcesUtils';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { v4 as uuidv4 } from 'uuid';
import TaskHeader from '../PendingTasks/TaskHeader';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));

const ArchivedResourcesTable: React.FC = () => {
  const urlSearchParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isRestoreSuccessModalOpen, setIsRestoreSuccessModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [restoreType, setRestoreType] = useState<ActionType | null>(null);
  const [selectedResources, setSelectedResources] = useState<
    FilteredResource[]
  >([]); // for batch restore
  const [selectedResource, setSelectedResource] =
    useState<FilteredResource | null>(null); // for single restore
  const [itemsPendingForRestoration, setItemsPendingForRestoration] = useState<
    FilteredResource[]
  >([]);
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX),
  );

  const getValidResourceType = () => {
    const type = urlSearchParams.get('type') as ArchivedResourceType;
    if (Object.values(ArchivedResourceType).includes(type)) {
      return type;
    }
    return ArchivedResourceType.TASK;
  };

  const [resourceType, setResourceType] = useState<ArchivedResourceType>(
    getValidResourceType(),
  );
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const totalSize = useSelector(archivedResourcesTotalSizeSelector) ?? 0;
  const archivedItems = useSelector(archivedResourcesListSelector);
  const loading = useSelector(archivedResourcesListLoadingSelector);
  const listingError = useSelector(archivedResourcesListingErrorSelector);
  const restoring = useSelector(archivedResourcesRestoringSelector);
  const restored = useSelector(archivedResourcesRestoredSelector);
  const restoringError = useSelector(archivedResourcesRestoringErrorSelector);
  const workflowColors = useSelector(workflowColorsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const pagedData = archivedItems.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );
  const ArchivedResourceTypeColors = {
    [ArchivedResourceType.TASK]: '#FEDF89',
    [ArchivedResourceType.WORKFLOW]: '#8966ED',
    [ArchivedResourceType.CONNECTOR]: '#D1E9FF',
  };

  const composeFilters = () => {
    const newSearchParams = new URLSearchParams();
    let filters = '';

    // By default, the filter will contain the value of type={task} so need to explicitly include it.
    if (resourceType !== ArchivedResourceType.TASK) {
      filters += `type=${resourceType}`;
      newSearchParams.append('type', resourceType);
    }

    // If display name prefix is not empty, append to status string
    if (displayNamePrefix) {
      filters += `,${DISPLAY_NAME_PREFIX}=${displayNamePrefix}`;
      newSearchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
    }

    // Update URL search parameters for workflow and display name
    navigate({ search: `?${newSearchParams.toString()}` });

    // Return the updated status string
    return filters.length && filters[0] === ','
      ? filters.substring(1)
      : filters;
  };

  const listArchivedResources = (
    pageSize = 10,
    pageNumber = 1,
    refresh = true,
  ) => {
    const req: ListArchivedResourcesRequest = {};
    req.pageSize = pageSize;
    req.pageNumber = pageNumber;
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    req.filter = composeFilters();
    dispatch(listArchivedResourcesAction(req, refresh));
  };

  const resetFilters = () => {
    clearSelectedResources();
    setDisplayNamePrefix(null);
    setResourceType(ArchivedResourceType.TASK);
  };

  const filteredResources = useMemo((): FilteredResource[] => {
    return pagedData.map((data) => {
      if (data.workflow) {
        return data.workflow;
      } else if (data.connector) {
        return data.connector;
      } else {
        return data.task;
      }
    }) as FilteredResource[];
  }, [pagedData]);

  useEffect(() => {
    if (
      resourceType === ArchivedResourceType.TASK &&
      filteredResources.length > 0 &&
      'workflowDisplayName' in filteredResources[0] // to make ensure that data is indeed of Task type
    ) {
      const workflowNames = (filteredResources as Task[]).map(
        (t) => t.workflowDisplayName || t.organizationResourceName,
      );
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [filteredResources]);

  const showRestoreError = (msg: string, pendingItems: FilteredResource[]) => {
    const isBatch = pendingItems.length > 1;
    toastService.showError(
      <CustomToastContent
        type='error'
        content={
          <CustomTypography
            sx={{ fontSize: '14px', color: 'black', mt: '8px' }}
          >
            {`${pendingItems.length} ${resourceType}${
              isBatch ? 's were' : ' was'
            } not restored because of ${msg}`}
          </CustomTypography>
        }
        buttonTitle={'Try again'}
        icon={<RefreshRounded />}
        onClick={() => handleRestore(pendingItems)}
      />,
      { position: 'top-right' },
    );
  };

  const showListingError = (msg: string) => {
    toastService.showError(
      <CustomToastContent
        type='error'
        content={
          <CustomTypography
            sx={{ fontSize: '14px', color: 'black', mt: '8px' }}
          >
            {msg}
          </CustomTypography>
        }
      />,
      { position: 'top-right' },
    );
  };

  const openSuccess = (msg: string) => {
    const toastId = `toast-${uuidv4()}`;
    toastService.showSuccess(
      <CustomToastContent
        type='success'
        content={
          <CustomTypography
            sx={{ fontSize: '14px', color: 'black', mt: '8px' }}
          >
            {msg}
          </CustomTypography>
        }
        buttonTitle={'Go to page'}
        icon={<CallMadeRounded />}
        onClick={() => {
          handleNavigation(false);
          toastService.dismiss(toastId);
        }}
      />,
      {
        position: 'top-right',
        style: {
          borderWidth: '0px !important',
          backgroundColor: '#EBF8F2',
        },
        toastId,
      },
    );
  };

  useEffect(() => {
    if (isMenuOpen) {
      const removeTabIndex = () => {
        const element = document.querySelector('[data-testid="sentinelEnd"]');
        if (element) {
          element.removeAttribute('tabindex');
        }
      };

      removeTabIndex();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const sentinelStartElements = document.querySelectorAll(
        'div[data-testid="sentinelStart"]',
      );
      const sentinelEndElements = document.querySelectorAll(
        'div[data-testid="sentinelEnd"]',
      );

      sentinelStartElements.forEach((element) => {
        element.removeAttribute('tabindex');
      });

      sentinelEndElements.forEach((element) => {
        element.removeAttribute('tabindex');
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      const elm = document.getElementById('root');
      elm?.removeAttribute('aria-hidden');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (listingError) {
      showListingError(listingError.message);
    }
  }, [listingError]);

  useEffect(() => {
    if (restoringError && itemsPendingForRestoration.length > 0) {
      showRestoreError(restoringError.message, [...itemsPendingForRestoration]);
      console.error(restoringError);
      setItemsPendingForRestoration([]);
      dispatch(restoreArchivedResourcesErrorAction());
    }
  }, [restoringError]);

  useEffect(() => {
    if (restored && itemsPendingForRestoration.length > 0) {
      // if multiple resources are restored (> 1) then show modal otherwise show toast
      if (itemsPendingForRestoration.length > 1) {
        setIsRestoreSuccessModalOpen(true);
      } else {
        openSuccess(`1 ${resourceType} was successfully restored.`);
      }
      clearSelectedResources();
      setItemsPendingForRestoration([]);
      dispatch(restoreArchivedResourcesErrorAction());
      setPage(0);
      listArchivedResources(rowsPerPage);
    }
  }, [restored]);

  useEffect(() => {
    setPage(0);
    listArchivedResources(rowsPerPage);
    clearSelectedResources();
  }, [selectedOrgInfo, displayNamePrefix, resourceType]);

  useEffect(() => {
    return () => resetFilters(); // reset filters upon clean up and organization change
  }, [selectedOrgInfo]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    item: FilteredResource,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedResource(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseRestoreModal = () => {
    setRestoreType(null);
    setSelectedResource(null);
  };

  const handleCloseRestoreSuccessModal = () => {
    setIsRestoreSuccessModalOpen(false);
  };

  const handleRestore = (itemsToRestore: FilteredResource[]) => {
    // whether its batch or single restore we always send the array of resource names
    const req: RestoreArchivedResourcesRequest = {};
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    req.name = itemsToRestore.map((item) => item.name) as string[];
    dispatch(restoreArchivedResourcesAction(req));
    setItemsPendingForRestoration(itemsToRestore);
    handleCloseRestoreModal();
    clearSelectedResources();
  };

  const handleNavigation = (isBatch = true) => {
    /* We can only determine appropriate url in the following cases
       1. Restore is Single
       2. Restore is Batch but not of type `task` 
          because based on task status, tasks can belong to multiple tabs eg: 'completed-tasks', 'deleted-tasks' 
          so determination of url would not be possible since we can have multiple urls */
    if (resourceType !== ArchivedResourceType.TASK || !isBatch) {
      // Only defined in case of 'task' otherwise undefined
      const taskStatus = (itemsPendingForRestoration[0] as Task | undefined)
        ?.status;
      const path = getPathAccToResource(resourceType, taskStatus);
      if (path === 'declined-tasks') {
        const tab =
          taskStatus === TaskSTATUS.OBSOLETE
            ? DeclinedTaskTabs.SYSTEM_DECLINED
            : DeclinedTaskTabs.USER_DECLINED;

        navigate(`/${path}`, {
          // this is state is used for automatic tab switching inside 'decline-tasks' route see https://orby-ai.atlassian.net/browse/OA-2558
          state: {
            tab,
          },
        });
        return;
      }
      navigate(`/${path}`);
      return;
    }
    handleCloseRestoreSuccessModal();
  };

  const clearSelectedResources = () => {
    setSelectedResources([]);
  };

  const getUnrestorableReason = () => {
    if (resourceType === ArchivedResourceType.TASK) {
      return 'Cannot restore since workflow was deleted';
    } else if (resourceType === ArchivedResourceType.CONNECTOR) {
      return 'Cannot restore since either source or destination workflow was deleted';
    }
    return '';
  };

  const renderMenuIcon = (t: FilteredResource, disabled = false) => {
    return (
      <Box>
        <IconButton
          aria-label='more'
          id={'long-button' + t.displayName}
          aria-controls={isMenuOpen ? 'long-menu' + t.displayName : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup='true'
          onClick={(e: React.MouseEvent<HTMLElement>) => handleMenuClick(e, t)}
          disabled={disabled}
        >
          <MoreVert />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <Box paddingX={'60px'} paddingY={'60px'}>
        <Box display={'flex'} alignItems={'center'}>
          <ArchivedResourcesFilterSelect
            resourceType={resourceType}
            setResourceType={setResourceType}
          />
          <SearchTaskField
            displayNamePrefix={displayNamePrefix ?? ''}
            setDisplayNamePrefix={setDisplayNamePrefix}
            placeholder='Search'
          />
        </Box>
        <TaskHeader
          pageTitle={'Deleted Items'}
          selectedItems={selectedResources}
          onCancel={clearSelectedResources}
          secondaryLabel={'Restore'}
          onSecondaryClick={() => setRestoreType(ActionType.BATCH)}
          primaryLabel={''}
        />
        <Box>
          {loading || restoring ? (
            <Box paddingTop={'60px'} display={'flex'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer
                sx={{ marginTop: '12px' }}
                className={'table-header-style'}
              >
                <Table
                  id={'archived-resources-table'}
                  sx={{ tableLayout: 'fixed' }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                      <CustomTableCell sx={{ width: '50px' }} ellipsis={false}>
                        <TaskCheckbox
                          disabled={
                            !filteredResources.some(
                              allowSelectionForArchivedResources,
                            )
                          }
                          checked={isSelectAllChecked(
                            selectedResources as Task[],
                            filteredResources.filter(
                              allowSelectionForArchivedResources,
                            ) as Task[],
                          )}
                          title={`Select all ${resourceType}s`}
                          onClick={(e) =>
                            handleSelectAllClick(
                              e,
                              selectedResources,
                              filteredResources.filter(
                                allowSelectionForArchivedResources,
                              ),
                              setSelectedResources as React.Dispatch<
                                React.SetStateAction<Task[]>
                              >,
                            )
                          }
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomTableLabel label='Name' />
                      </CustomTableCell>
                      {resourceType === ArchivedResourceType.TASK && (
                        <CustomTableCell title='Workflow'>
                          <CustomTableLabel label='Workflow' />
                        </CustomTableCell>
                      )}
                      <CustomTableCell
                        title='Type'
                        sx={{
                          width:
                            resourceType === ArchivedResourceType.TASK
                              ? '10%'
                              : '15%',
                        }}
                      >
                        <CustomTableLabel label='Type' />
                      </CustomTableCell>
                      <CustomTableCell title='Time Deleted'>
                        <CustomTableLabel label='Time Deleted' />
                      </CustomTableCell>
                      <CustomTableCell title='By'>
                        <CustomTableLabel label='By' />
                      </CustomTableCell>
                      <CustomTableCell title='Notes'>
                        <CustomTableLabel label='Notes' />
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredResources.map((t, index) => (
                      <StyledTableRow
                        key={index}
                        tabIndex={0}
                        role='row'
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 !== 0 ? '#F6F6F6' : 'transparent',
                          ':hover': { backgroundColor: '#1669F74D' },
                          ':focus': { backgroundColor: '#1669F74D' },
                        }}
                      >
                        <CustomTableCell ellipsis={false}>
                          <TaskCheckbox
                            disabled={!allowSelectionForArchivedResources(t)}
                            checked={isTaskSelected(
                              t as Task,
                              selectedResources as Task[],
                            )}
                            onClick={(e) =>
                              handleTaskCheckboxClick(
                                e,
                                t as Task,
                                selectedResources as Task[],
                                setSelectedResources as React.Dispatch<
                                  React.SetStateAction<Task[]>
                                >,
                              )
                            }
                            sx={{ marginRight: '20px' }}
                            title={
                              allowSelectionForArchivedResources(t)
                                ? `Select ${t.displayName}`
                                : getUnrestorableReason()
                            }
                          />
                        </CustomTableCell>
                        <CustomTableCell title={t.displayName}>
                          {t.displayName}
                        </CustomTableCell>
                        {resourceType === ArchivedResourceType.TASK &&
                          'workflowDisplayName' in t && (
                            <CustomTableCell>
                              <Box
                                title={
                                  t.workflowDisplayName ||
                                  t.organizationResourceName
                                }
                                sx={{
                                  paddingX: '10px',
                                  paddingY: '5px',
                                  backgroundColor: addAlpha(
                                    workflowColors[
                                      (t.workflowDisplayName as string) ||
                                        (t.organizationResourceName as string)
                                    ],
                                    0.4,
                                  ),
                                  width: 'fit-content',
                                  maxWidth: '100%',
                                  borderRadius: '4px',
                                }}
                              >
                                <CustomTypography
                                  typographyType={TypographyType.Label}
                                  sx={{
                                    ...ELLIPSIS_STYLE,
                                  }}
                                >
                                  {t.workflowDisplayName ||
                                    t.organizationResourceName}
                                </CustomTypography>
                              </Box>
                            </CustomTableCell>
                          )}
                        <CustomTableCell>
                          <Box
                            title={resourceType}
                            sx={{
                              paddingX: '10px',
                              paddingY: '5px',
                              backgroundColor: addAlpha(
                                ArchivedResourceTypeColors[resourceType],
                                resourceType === ArchivedResourceType.WORKFLOW
                                  ? 0.2
                                  : 0,
                              ),
                              width: 'fit-content',
                              maxWidth: '100%',
                              borderRadius: '4px',
                            }}
                          >
                            <CustomTypography
                              typographyType={TypographyType.Label}
                              sx={{
                                ...ELLIPSIS_STYLE,
                                textTransform: 'capitalize',
                              }}
                            >
                              {resourceType}
                            </CustomTypography>
                          </Box>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography color='#545454'>
                            {t?.deletedObjectInfo?.deletedTime &&
                              formatDate(t?.deletedObjectInfo?.deletedTime)}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell
                          title={t?.deletedObjectInfo?.deletedBy}
                        >
                          {t?.deletedObjectInfo?.deletedBy}
                        </CustomTableCell>
                        <CustomTableCell
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{ ...ELLIPSIS_STYLE }}
                            title={t?.deletedObjectInfo?.deletedReason}
                          >
                            <CustomTypography
                              sx={{
                                ...ELLIPSIS_STYLE,
                                maxWidth: '100%',
                                marginTop: '5px',
                              }}
                            >
                              {t?.deletedObjectInfo?.deletedReason}
                            </CustomTypography>
                          </Box>
                          {!allowSelectionForArchivedResources(t) ? (
                            <Tooltip
                              arrow
                              PopperProps={{
                                sx: {
                                  '& .MuiTooltip-arrow': { color: '#000' },
                                  '& .MuiTooltip-tooltip': {
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                  },
                                },
                              }}
                              title={getUnrestorableReason()}
                              placement='bottom-end'
                            >
                              {renderMenuIcon(t, true)}
                            </Tooltip>
                          ) : (
                            renderMenuIcon(t)
                          )}
                        </CustomTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {!filteredResources.length && (
                  <CustomTypography
                    sx={{
                      textAlign: 'center',
                      textJustify: 'center',
                      color: '#475467',
                      marginTop: '40px',
                    }}
                  >
                    {`There are no deleted ${resourceType}s available`}
                  </CustomTypography>
                )}
              </TableContainer>
              <Menu
                autoFocus={false}
                disableAutoFocusItem={true}
                aria-hidden={false}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    maxHeight: '87px',
                    width: '124px',
                    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '4px',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setRestoreType(ActionType.SINGLE);
                    handleMenuClose();
                  }}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'10px'}
                    alignItems={'center'}
                  >
                    <CustomTypography>Restore</CustomTypography>
                  </Box>
                </MenuItem>
              </Menu>
              <CustomPagination
                rowsPerPage={rowsPerPage}
                totalSize={totalSize}
                page={page}
                onRowsPerPageChange={(rows) => {
                  clearSelectedResources();
                  setPage(0);
                  setRowsPerPage(rows);
                  listArchivedResources(rows);
                }}
                onPageChange={(p) => {
                  clearSelectedResources();
                  setPage(p);
                  if (p >= page && archivedItems.length <= p * rowsPerPage) {
                    listArchivedResources(rowsPerPage, p + 1, false);
                  }
                }}
              />
            </>
          )}
        </Box>
        <RestoreItemsConfirmationModel
          open={!!restoreType}
          isMultiple={
            restoreType === ActionType.BATCH && selectedResources.length > 0
          }
          handleClose={handleCloseRestoreModal}
          onSubmit={() =>
            handleRestore(
              restoreType === ActionType.BATCH
                ? selectedResources
                : selectedResource
                  ? [selectedResource]
                  : [],
            )
          }
        />
        <RestoreItemsSuccessModel
          open={isRestoreSuccessModalOpen}
          handleClose={handleCloseRestoreSuccessModal}
          // Don't show links for multiple tasks restore
          // https://github.com/orby-ai-engineering/orby-web-app/pull/917#issuecomment-1995472950
          isShowLink={resourceType !== ArchivedResourceType.TASK}
          resourceType={resourceType}
          onSubmit={handleNavigation}
        />
      </Box>
    </>
  );
};

export default React.memo(ArchivedResourcesTable);
