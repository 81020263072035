import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import {
  ListWorkflowsRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  listWorkflowTemplateAction,
  listWorkflowTemplateErrorAction,
} from '../../redux/actions/workflow.action';
import {
  listTemplateWorkflowErrorSelector,
  workflowTemplatesListSelector,
  workflowTemplatesLoadingSelector,
  workflowTemplatesTotalSizeSelector,
} from '../../redux/selectors/workflow.selectors';
import { getLogoByName } from './helper';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { notification } from 'antd';
import { getApplicationNames } from '../../utils/helpers';
import { ControlPoint } from '@mui/icons-material';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import { WORKFLOW_TEMPLATE_ROWS_PER_PAGE } from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import '../../styles/table.css';

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:nth-of-type(even)': {
    backgroundColor: '#FAFAFA',
  },
  '& td, & th': {
    border: 0,
  },
}));

const TemplateWorkflowTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workflows: Workflow[] = useSelector(workflowTemplatesListSelector);
  const loading = useSelector(workflowTemplatesLoadingSelector);
  const totalSize = useSelector(workflowTemplatesTotalSizeSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const listWorkflowError = useSelector(listTemplateWorkflowErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const pagedTempWorkflows = workflows.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  const openError = (error: Error) => {
    api.info({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (listWorkflowError) {
      openError(listWorkflowError);
      dispatch(listWorkflowTemplateErrorAction(undefined));
    }
  }, [listWorkflowError]);

  useEffect(() => {
    setPage(0);
    const req = buildRequest();
    dispatch(listWorkflowTemplateAction(req, true /* Refresh */));
  }, [selectedOrgInfo]);

  const buildRequest = () => {
    const req: ListWorkflowsRequest = {};
    req.filter = 'is_template=true';
    if (selectedOrgInfo) {
      req.orgResourceName = selectedOrgInfo.orgResourceName;
    }
    return req;
  };

  return (
    <Box>
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
      >
        Workflow Templates
      </CustomTypography>
      <CustomTypography
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        typographyType={TypographyType.MediumPara}
      >
        Build workflows that automate your repetitive and tedious work. Get
        started with one of the templates!
      </CustomTypography>
      {/* <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <CustomSearchField placeHolder="Search..." />
          <CustomFilterSelect
            color="#B6B6B6"
            filters={[
              { value: '1', label: 'Example Filter' },
              { value: '2', label: 'Example Filter2' },
            ]}
            value={'1'}
          />
          <IconButton sx={{ marginTop: '10px', color: '#545454' }}>
            <FilterList />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'20px'}
          marginTop={'10px'}
        >
          <Box display={'flex'} alignItems={'center'} gap={'4px'}>
            <CustomTypography color="#6B6B6B">Last Modified</CustomTypography>
            <IconButton sx={{ height: '0px', width: '0px' }}>
              <ImportExportOutlined />
            </IconButton>
          </Box>
          <IconButton sx={{ height: '24px', width: '24px' }}>
            <GridViewOutlined />
          </IconButton>
          <IconButton sx={{ height: '24px', width: '24px' }}>
            <FormatListBulleted />
          </IconButton>
        </Box>
      </Box> */}
      {loading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          height={'20%'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          sx={{ marginTop: '30px' }}
          className={'table-header-style'}
        >
          <Table id={'template-workflows-table'} sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                <CustomTableCell title='Workflow'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Workflow
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Description'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Description
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Apps involved'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Apps involved
                  </CustomTypography>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedTempWorkflows.map((d) => (
                <StyledTableRow
                  key={d.name}
                  role='row'
                  tabIndex={0}
                  sx={{
                    cursor: 'pointer',
                    ':hover': { backgroundColor: '#1669F74D' },
                    ':focus': { backgroundColor: '#1669F74D' },
                  }}
                  onClick={() =>
                    navigate('/workflow/create', {
                      state: { workflow: d },
                    })
                  }
                >
                  <CustomTableCell title={d.displayName}>
                    <CustomTypography
                      component={'span'}
                      lineHeight='18px'
                      color='#000000'
                      typographyType={TypographyType.Header5}
                    >
                      {d.displayName}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell title={d.description}>
                    <CustomTypography component={'span'} color='#6B6B6B'>
                      {d.description}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell
                    ellipsis={false}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box display={'flex'} flexWrap={'wrap'}>
                      {getApplicationNames(d).map((app: string) => {
                        const logo = getLogoByName(app);
                        if (logo) {
                          return (
                            <Box key={logo} marginRight={'10px'}>
                              <img src={logo} title={app} alt={app} />
                            </Box>
                          );
                        }
                      })}
                    </Box>
                    <Tooltip title={'Create Workflow'} enterDelay={1000}>
                      <IconButton aria-label={'Create Workflow'}>
                        <ControlPoint
                          fontSize='small'
                          sx={{ color: '#1669F7' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </CustomTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CustomPagination
        ariaLabel={'Please select template rows per page'}
        rowsPerPage={rowsPerPage}
        totalSize={totalSize}
        page={page}
        rows={WORKFLOW_TEMPLATE_ROWS_PER_PAGE}
        justifyContent={'end'}
        onRowsPerPageChange={(rows) => {
          setPage(0);
          const req = buildRequest();
          // Refresh is needed when rows per page is changes to fetch fresh data
          dispatch(listWorkflowTemplateAction(req, true /* Refresh */));
          setRowsPerPage(rows as number);
        }}
        onPageChange={(p) => {
          setPage(p);
          if (p >= page && workflows.length <= p * rowsPerPage) {
            const req = buildRequest();
            req.pageNumber = p + 1;
            req.pageSize = rowsPerPage;
            dispatch(listWorkflowTemplateAction(req));
          }
        }}
      />
    </Box>
  );
};

export default React.memo(TemplateWorkflowTable);
