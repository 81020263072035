import React, { forwardRef } from 'react';
import { Box, BoxProps, Typography, styled } from '@mui/material';

const RowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}));

const WidgetContainer: React.FC<any> = forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => (
    <RowContainer
      ref={ref}
      className='widget-container'
      pl={'12px'}
      pr={'12px'}
      gap={2}
      {...props}
    >
      {props.children}
    </RowContainer>
  ),
);

// Add displayName for better debugging
WidgetContainer.displayName = 'WidgetContainer';

const ItemDescription = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  fontSize: 18,
  lineHeight: '36px',
  borderRadius: '7px',
  color: theme.palette.grey[900],
}));

export { RowContainer, WidgetContainer, ItemDescription };
