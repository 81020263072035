import { styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const Dot = styled(CircleIcon, {
  shouldForwardProp(propName) {
    return propName !== 'hexColor';
  },
})<{ hexColor: string }>(({ theme, hexColor }) => ({
  fontSize: 10,
  color: hexColor,
  marginRight: theme.spacing(2),
}));

export default Dot;
