import ActionItem from './ActionItem';
import { ColumnContainer, RowContainer, ActionContainer } from './Styled';
import { getConditionResultFromExecutedActions } from 'workflow-utils/src/v2/helper';
import { getNumStepsForActions } from 'workflow-utils/src/helper';
import React from 'react';
import { InfoChip, SuccessChip } from './Chip';
import { ActionObject, ExecutedActionObject } from 'workflow-utils/src/types';

interface IConditionActionProps {
  action: ActionObject;
  // sliced from the original executedActions based on the executionInfo.
  executedActions: ExecutedActionObject[];
  startIndex: number;
}

const ConditionAction: React.FC<IConditionActionProps> = ({
  action,
  startIndex,
  executedActions,
}: IConditionActionProps) => {
  const numTrueSteps = getNumStepsForActions(action.condition?.thenActions);
  const isConditionTrue = getConditionResultFromExecutedActions(
    action,
    executedActions,
  );

  return (
    <ActionContainer key={startIndex}>
      <RowContainer sx={{ gap: 3, mb: 2 }}>
        <InfoChip label='Was the condition true?' />
        <SuccessChip label={isConditionTrue ? 'Yes' : 'No'} />
      </RowContainer>
      {isConditionTrue ? (
        <ColumnContainer>
          {action.condition?.thenActions?.map((ag, i) => (
            <ActionItem
              key={i}
              action={ag}
              startIndex={startIndex + i}
              executedActions={executedActions}
            />
          ))}
        </ColumnContainer>
      ) : (
        <ColumnContainer>
          {action.condition?.elseActions?.map((ag, i) => (
            <ActionItem
              key={i}
              action={ag}
              startIndex={numTrueSteps + startIndex + i}
              executedActions={executedActions}
            />
          ))}
        </ColumnContainer>
      )}
    </ActionContainer>
  );
};

export { ConditionAction };
