import { createSelector } from 'reselect';
import { utilitySelector } from './app.selectors';

export const getGmailLabelsSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.gmailLabels,
);

export const loadingGmailLabelsSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.loadingGmailLabels,
);

export const getGmailLabelsErrorSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.getGmailLabelsError,
);

export const getScheduledTasksSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.tasks,
);

export const loadingScheduledTasksSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.loadingTasks,
);

export const scheduledTasksErrorSelector = createSelector(
  [utilitySelector],
  (utilityState) => utilityState.tasksError,
);
