import {
  Box,
  CircularProgress,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import WorkflowCreationStep from '../../pages/workflow-creation/WorkflowCreationStep';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import WorkflowGeneralContent from './step-contents/WorkflowGeneralContent';
import TriggerConditionContent from './step-contents/TriggerConditionContent';
import CreateSystemActionContent from './step-contents/CreateSystemActionContent';
import {
  ManualAssignment,
  ReviewerList,
  ReviewTriggerCondition,
  UpdateWorkflowRequest,
  Workflow,
  WorkflowAssignmentOption,
  WorkflowLearningSettings,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';
import AddUserStepContent from './step-contents/AddUserStepContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorkflowAction,
  getWorkflowErrorAction,
  setSelectedWorkflowAction,
  updateWorkflowAction,
  updateWorkflowErrorAction,
} from '../../redux/actions/workflow.action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  processingWorkflowSelector,
  processWorkflowErrorSelector,
  selectedWorkflowErrorSelector,
  selectedWorkflowLoadingSelector,
  selectedWorkflowSelector,
  updatedWorkflowSelector,
} from '../../redux/selectors/workflow.selectors';
import { notification } from 'antd';
import ExtractSchemaDefinition, {
  SchemaEntity,
} from './step-contents/ExtractSchemaDefinition';
import EmailTriggerContent from './step-contents/EmailTriggerContent';
import { ApplicationName } from '../../utils/protos/enums';
import { SFTPParamTriggerTriggerType } from 'protos/application/application_params';
import SftpTriggerContent from './step-contents/SftpTriggerContent';
import {
  addUsersToReviewList,
  checkReviewerListUserChanged,
  findSelectedTrigger,
  getActionIndex,
  getApplicationNames,
  getGroupConditionExtractedFields,
  getStepConstants,
  getTriggerIndex,
  hasEntityDetailsChanged,
  hasGroupConditionsChanged,
} from '../../utils/helpers';
import ClassificationSchemaDefinition from './step-contents/ClassificationSchemaDefinition';
import {
  AssignmentMode,
  GDRIVE_SFTP_MAPPING_COLUMNS,
  GMAIL_MAPPING_COLUMNS,
  STEP_CONTENT_LAST_STEP_WIDTH,
  STEP_CONTENT_WIDTH,
} from '../../utils/constants';
import {
  CompositeGroupCondition,
  Condition,
  ConditionOptions,
  ConditionType,
} from 'protos/pb/v1alpha2/connector';
import GenerateOutput from './step-contents/GenerateOutput';
import {
  EntityDataType,
  EntityDetails,
  GenerateOutputParamActionType,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  checkAssignmentOptionsChanged,
  checkManualAssignmentStatusChange,
  getWorkflowAssignmentOptions,
  handleSubmitEmailTriggerForm,
  handleSubmitSheetActionForm,
  updateStepsTriggerOutlookLabels,
} from '../../utils/WorkflowUtils';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import { FormikValues } from 'formik';
import _ from 'lodash';

const WorkflowUpdatePage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  // This variable is added to submit the form when previous button is clicked. This has been
  // passed as a prop to all the steps and when it is set true, the values that has been filled till
  // the click gets saved and user is navigated to previous step. After the form is saved, this gets reset
  // to false, so it won't affect the default save functionality when Next button is pressed
  const [previousClicked, setPreviousClicked] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { workflow_id } = useParams();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const selectedWorkflow = useSelector(selectedWorkflowSelector);
  const selectedWorkflowLoading = useSelector(selectedWorkflowLoadingSelector);
  const selectedWorkflowError = useSelector(selectedWorkflowErrorSelector);
  const [updationTriggered, setUpdationTriggered] = useState(false);
  const [workflow, setWorkflow] = useState<Workflow | undefined>();
  const isYamlWorkflow = location.state?.isYamlWorkflow;
  const navigate = useNavigate();
  const updatedWorkflow = useSelector(updatedWorkflowSelector);
  const workflowError = useSelector(processWorkflowErrorSelector);
  const updatingWorkflow = useSelector(processingWorkflowSelector);
  const [api, contextHolder] = notification.useNotification();
  const [fieldMaskPaths, setFieldMaskPaths] = useState(new Set<string>());
  const selectedTrigger = workflow ? findSelectedTrigger(workflow) : undefined;
  const STEPS = workflow ? getStepConstants(workflow) : [];
  const isLastStep = activeStep === STEPS.length - 1;

  const hasEmailTrigger = useMemo(() => {
    if (!workflow) return false;
    const applications = getApplicationNames(workflow);
    return (
      applications.includes(ApplicationName.Gmail) ||
      applications.includes(ApplicationName.Outlook)
    );
  }, [workflow]);

  const CustomConnector = styled(StepConnector)(() => ({
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#263244',
      borderLeftWidth: '2.6px',
      minHeight: '40px',
      marginLeft: '10px',
    },
  }));

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openWarning = (msg: string) => {
    api.info({
      message: 'Warning',
      description: msg,
      placement: 'topRight',
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (!workflow && selectedWorkflow) {
      if (
        selectedWorkflow.organizationResourceName ===
        selectedOrgInfo?.orgResourceName
      ) {
        setWorkflow(selectedWorkflow);
      } else {
        openWarning('Workflow does not belong to this organization');
        setTimeout(() => {
          navigate('/workflow');
        }, 1500);
      }
    }
  }, [selectedWorkflow, workflow]);

  useEffect(() => {
    if (selectedWorkflowError) {
      openError(selectedWorkflowError);
      dispatch(getWorkflowErrorAction());
    }
  }, [selectedWorkflowError]);

  useEffect(() => {
    if (workflowError && updationTriggered) {
      openError(workflowError);
      dispatch(updateWorkflowErrorAction(undefined));
    }
  }, [workflowError]);

  useEffect(() => {
    setFieldMaskPaths(new Set<string>());
    if (!selectedWorkflow) {
      dispatch(getWorkflowAction(`workflows/${workflow_id}`));
    }
    return () => {
      setUpdationTriggered(false);
      dispatch(setSelectedWorkflowAction(undefined));
    };
  }, []);

  useEffect(() => {
    if (updatedWorkflow && updationTriggered) {
      openSuccess(
        'Workflow ' + updatedWorkflow.name + ' is updated successfully',
      );
      if (workflow) {
        workflow.name = workflow.templateResourceName;
      }
      setWorkflow(workflow);
      setTimeout(() => {
        setActiveStep(-1);
        navigate(-1);
      }, 1000);
    }
  }, [updatedWorkflow]);

  const handleSubmitGeneralContent = (values: FormikValues) => {
    const w: Workflow = Workflow.fromJSON(workflow) as Workflow;
    if (w.displayName != values.workflow_name) {
      w.displayName = values.workflow_name;
      setFieldMaskPaths((prev) => {
        return prev.add('display_name');
      });
    }
    if (w.description != values.description) {
      w.description = values.description;
      setFieldMaskPaths((prev) => {
        return prev.add('description');
      });
    }
    if (w.manualTimeCostInMinutes != values.time_spent) {
      w.manualTimeCostInMinutes = values.time_spent;
      setFieldMaskPaths((prev) => {
        return prev.add('manual_time_cost_in_minutes');
      });
    }
    setWorkflow(w);
    setActiveStep(activeStep + 1);
  };

  const handleSubmitEmailTriggerContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow as Workflow;
    handleSubmitEmailTriggerForm(w, values);
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitSftpTriggerContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow) as Workflow;
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.type = SFTPParamTriggerTriggerType.NEW_OBJECT;
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.folderPath = values['folder_path'];
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitTriggerConditionContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow) as Workflow;
    const gdriveIndex = getTriggerIndex(w, ApplicationName.GoogleDrive);
    w.steps![gdriveIndex.stepIndex!].triggers![
      gdriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.id = values['file_id'];
    w.steps![gdriveIndex.stepIndex!].triggers![
      gdriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.path = values['folder_url'];
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitClassificationSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow as Workflow;
    const classificationLabelsIndex = getActionIndex(
      w,
      ApplicationName.DocumentClassification,
    );
    const gsheetsIndex = getActionIndex(w, ApplicationName.GoogleSheets);
    const originalClassificationLabels =
      w.steps![classificationLabelsIndex.stepIndex!].actions![
        classificationLabelsIndex.actionIndex!
      ].classification!.classificationLabels!;
    let localSchemaChange = false;

    if (
      originalClassificationLabels.length !=
      values.schemaClassificationLabels.length
    ) {
      localSchemaChange = true;
    }

    if (!localSchemaChange) {
      const originalSchemaDefinitionMap: { [key: string]: boolean } = {};
      originalClassificationLabels.forEach((label: string) => {
        originalSchemaDefinitionMap[label] = true;
      });

      // We will iterate over new schema and see if there's any change in schema
      for (const schemaClassification of values.schemaClassificationLabels) {
        if (!(schemaClassification in originalSchemaDefinitionMap)) {
          localSchemaChange = true;
          break;
        }
      }
    }

    if (localSchemaChange) {
      w.steps![classificationLabelsIndex.stepIndex!].actions![
        classificationLabelsIndex.actionIndex!
      ].classification!.classificationLabels =
        values.schemaClassificationLabels;
      let defaultEntities: string[] = [];
      if (gsheetsIndex.actionIndex !== -1) {
        if (
          [ApplicationName.GoogleDrive, ApplicationName.SftpServer].includes(
            selectedTrigger!,
          )
        ) {
          defaultEntities = GDRIVE_SFTP_MAPPING_COLUMNS;
        }
        if (hasEmailTrigger) {
          defaultEntities = GMAIL_MAPPING_COLUMNS;
        }
        w.steps![gsheetsIndex.stepIndex!].actions![
          gsheetsIndex.actionIndex!
        ].gsheets!.action!.addRowOption!.mappingColumns = defaultEntities;
      }
      setFieldMaskPaths((prev) => {
        return prev.add('steps');
      });
    }
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitExtractSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow) as Workflow;
    const entityExtractionIndex = getActionIndex(
      w,
      ApplicationName.EntityExtraction,
    );
    const originalEntitiesDetails =
      w.steps![entityExtractionIndex.stepIndex!].actions![
        entityExtractionIndex.actionIndex!
      ].entityExtraction!.entitiesDetails!;

    const entitiesDetails: EntityDetails[] = [];
    const simpleSchemaEntities = values.schemaEntities.filter(
      (e: SchemaEntity) => !e.parentEntityId,
    );
    simpleSchemaEntities.forEach((simpleSchemaEntity: SchemaEntity) => {
      const properties: EntityDetails[] = [];
      if (
        simpleSchemaEntity.normalizationType ===
        EntityDataType.ENTITY_TYPE_NESTED
      ) {
        values.schemaEntities
          .filter(
            (e: SchemaEntity) => e.parentEntityId === simpleSchemaEntity.id,
          )
          .forEach((childEntity: SchemaEntity) => {
            properties.push(
              EntityDetails.create({
                entityType: childEntity.entityName,
                normalizationType: childEntity.normalizationType,
                properties: [],
              }),
            );
          });
      }
      entitiesDetails.push(
        EntityDetails.create({
          entityType: simpleSchemaEntity.entityName,
          normalizationType: simpleSchemaEntity.normalizationType,
          properties,
        }),
      );
    });

    if (hasEntityDetailsChanged(originalEntitiesDetails, entitiesDetails)) {
      w.steps![entityExtractionIndex.stepIndex!].actions![
        entityExtractionIndex.actionIndex!
      ].entityExtraction!.entitiesDetails = entitiesDetails;
      w.steps![entityExtractionIndex.stepIndex!].actions![
        entityExtractionIndex.actionIndex!
      ].entityExtraction!.exampleDocCount = 0;
      // We only update schema with fieldmask steps
      setFieldMaskPaths((prev) => {
        return prev.add('steps');
      });
    }
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitGenerateOutputContent = (isNextClicked: boolean) => {
    const w: Workflow = Workflow.fromJSON(workflow) as Workflow;
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    const generateOutputApplicationIndex = getActionIndex(
      w,
      ApplicationName.GenerateOutput,
    );

    if (generateOutputApplicationIndex.actionIndex !== -1) {
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.encryptionRequired = sftpIndex.triggerIndex !== -1;
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.type =
        GenerateOutputParamActionType.ACTION_CREATE_JSON_FILE;
    }

    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitCreateSystemActionContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow as Workflow;
    handleSubmitSheetActionForm(w, values);
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitAddUserContent = (
    values: FormikValues,
    isSubmitClicked: boolean,
  ) => {
    const w = workflow as Workflow;
    let hasAssignmentOptionsChanged = false;
    let hasRoundOneUserChanged = false;
    let hasRoundTwoUserChanged = false;
    let hasRoundOneTriggerConditionsChanged = false;
    let hasNeedAttentionThresholdDefaultModeChanged = false;
    let hasLearningSettingsChanged = false;
    let hasWorkflowAdminsChanged = false;
    const reviewerLists: ReviewerList[] = [];
    let roundOneUsers = ReviewerList.create({});
    let roundTwoUsers = ReviewerList.create({});
    const learningSettings = WorkflowLearningSettings.create({
      reviewers: Object.values(values.learningSettings),
    });
    hasLearningSettingsChanged = !_.isEqual(
      selectedWorkflow?.learningSettings,
      learningSettings,
    );
    hasWorkflowAdminsChanged = !_.isEqual(
      selectedWorkflow?.admins,
      values.workflowAdmins,
    );

    // Round One
    // Check if round one users have changed
    hasRoundOneUserChanged = checkReviewerListUserChanged(values.users, w, 1);
    hasAssignmentOptionsChanged =
      checkAssignmentOptionsChanged(
        values.showAdvanced ? values : undefined,
        w,
      ) || checkManualAssignmentStatusChange(values, workflow);
    roundOneUsers = addUsersToReviewList(values.users);
    roundOneUsers.roundNumber = 1;
    if (values.assignmentMode === AssignmentMode.UNASSIGNED) {
      roundOneUsers.assignmentOption = WorkflowAssignmentOption.create({
        manualAssignment: ManualAssignment.create({
          users: roundOneUsers.users,
        }),
      });
      // empty the reviewer's list
      roundOneUsers.users = [];
    } else if (values.showAdvanced) {
      roundOneUsers.assignmentOption = getWorkflowAssignmentOptions(values);
    }
    reviewerLists.push(roundOneUsers);

    // Round Two
    const oldRoundTwoReviewerList = w?.reviewerLists?.find(
      (reviewerList) => reviewerList.roundNumber === 2,
    );
    // Check if we have round two users
    if (values.roundTwoUsers.length > 0) {
      // Check if round two users have changed
      hasRoundTwoUserChanged = checkReviewerListUserChanged(
        values.roundTwoUsers,
        w,
        2,
      );
      if (
        !oldRoundTwoReviewerList ||
        (oldRoundTwoReviewerList &&
          (hasRoundTwoUserChanged ||
            oldRoundTwoReviewerList?.triggerCondition?.percentOfRandomSample !==
              values.samplePercentage))
      ) {
        hasRoundTwoUserChanged = true;
      }
      roundTwoUsers = addUsersToReviewList(values.roundTwoUsers);
      roundTwoUsers.roundNumber = 2;
      roundTwoUsers.triggerCondition = ReviewTriggerCondition.create({});
      roundTwoUsers.triggerCondition.conditionType =
        ConditionType.RANDOM_SAMPLE_PERCENT;
      roundTwoUsers.triggerCondition.conditionOptions = ConditionOptions.create(
        {},
      );
      roundTwoUsers.triggerCondition.conditionOptions.percentOfRandomSample =
        values.samplePercentage;
      reviewerLists.push(roundTwoUsers);
    } else if (oldRoundTwoReviewerList && values.roundTwoUsers.length <= 0) {
      hasRoundTwoUserChanged = true;
    }

    reviewerLists[0].triggerCondition = ReviewTriggerCondition.create({});
    if (values.selectedMode === WorkflowMode.ASSISTED) {
      if (
        w?.reviewerLists?.[0]?.triggerCondition?.conditionType !==
        values.conditionType
      ) {
        hasRoundOneTriggerConditionsChanged = true;
      }
      reviewerLists[0].triggerCondition.conditionType = values.conditionType;
      reviewerLists[0].triggerCondition.conditionOptions =
        ConditionOptions.create({});
      if (values.conditionType !== ConditionType.ANY_EMPTY_PREDICTIONS) {
        if (values.conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD) {
          reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            CompositeGroupCondition.create({});
          reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            getGroupConditionExtractedFields(values);
          if (
            hasGroupConditionsChanged(
              w?.reviewerLists?.[0].triggerCondition?.conditionOptions
                ?.groupCondition?.conditions as Condition[],
              reviewerLists?.[0]?.triggerCondition?.conditionOptions
                ?.groupCondition?.conditions as Condition[],
            )
          ) {
            hasRoundOneTriggerConditionsChanged = true;
          }
        } else {
          if (
            w?.reviewerLists?.[0]?.triggerCondition?.conditionOptions
              ?.confidenceScore !== Number(values.confidenceScore)
          ) {
            hasRoundOneTriggerConditionsChanged = true;
          }
          reviewerLists[0].triggerCondition.conditionOptions.confidenceScore =
            Number(values.confidenceScore);
        }
      }
    } else if (values.selectedMode === WorkflowMode.DEFAULT) {
      const threshold = Number(values.needAttentionThresholdDefaultMode) / 100;
      if (w.needAttentionThresholdDefaultMode !== threshold) {
        hasNeedAttentionThresholdDefaultModeChanged = true;
      }
      w.needAttentionThresholdDefaultMode = threshold;
    } else {
      reviewerLists[0].triggerCondition.conditionType =
        ConditionType.UNSPECIFIED;
    }

    /**
     * IF WORKFLOW TYPE IS OUTLOOK
     * LOOK FOR outlook-all-emails label and remove it
     * The reason for doing this is because Outlook doesn’t have a label for ‘Inbox'.
     * However by sending no labels for an outlook workflow while creating workflow, we can achieve the same behaviour.
     */
    updateStepsTriggerOutlookLabels(w);

    const fieldMask = fieldMaskPaths;
    if (
      hasRoundTwoUserChanged ||
      hasRoundOneUserChanged ||
      hasAssignmentOptionsChanged ||
      hasRoundOneTriggerConditionsChanged
    ) {
      fieldMask.add('reviewer_lists');
      setFieldMaskPaths((prev) => {
        return prev.add('reviewer_lists');
      });
      w.reviewerLists = reviewerLists;
    }
    if (hasLearningSettingsChanged) {
      fieldMask.add('learning_settings');
      setFieldMaskPaths((prev) => {
        return prev.add('learning_settings');
      });
      w.learningSettings = learningSettings;
    }
    if (hasNeedAttentionThresholdDefaultModeChanged) {
      fieldMask.add('need_attention_threshold_default_mode');
      setFieldMaskPaths((prev) => {
        return prev.add('need_attention_threshold_default_mode');
      });
    }
    if (w.mode !== values.selectedMode) {
      fieldMask.add('mode');
      setFieldMaskPaths((prev) => {
        return prev.add('mode');
      });
      w.mode = values.selectedMode;
    }
    if (hasWorkflowAdminsChanged) {
      fieldMask.add('admins');
      setFieldMaskPaths((prev) => {
        return prev.add('admins');
      });
      w.admins = values.workflowAdmins;
      w.adminEmailMessage = values.adminEmailMessage;
      w.sendAdminEmail = true;
    }
    setWorkflow(w);
    const req: UpdateWorkflowRequest = {};
    req.workflow = w;
    // If workflow is updated from yaml then we will update all the fields
    if (isYamlWorkflow) {
      req.fieldMask = [
        'description',
        'manual_time_cost_in_minutes',
        'steps',
        'mode',
        'reviewer_lists',
      ];
    } else {
      if (fieldMask.size > 0) {
        req.fieldMask = [...fieldMask];
      } else if (isSubmitClicked) {
        openWarning('No fields are modified');
        return;
      }
    }
    if (isSubmitClicked) {
      dispatch(updateWorkflowAction(req));
      setUpdationTriggered(true);
    } else {
      setActiveStep(activeStep - 1);
      setPreviousClicked(false);
    }
  };

  const getStepForApplication = (applicationName: string, index: number) => {
    const formId = `form${index + 1}`;
    switch (applicationName) {
      case ApplicationName.Outlook:
      case ApplicationName.Gmail:
        return (
          <EmailTriggerContent
            formId={formId}
            onSubmit={handleSubmitEmailTriggerContent}
            workflow={workflow!}
            edit
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.SftpServer:
        return (
          <SftpTriggerContent
            formId={formId}
            workflow={workflow as Workflow}
            onSubmit={handleSubmitSftpTriggerContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.GoogleDrive:
        return (
          <TriggerConditionContent
            formId={formId}
            workflow={workflow as Workflow}
            edit
            onSubmit={handleSubmitTriggerConditionContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.DocumentClassification:
        return (
          <ClassificationSchemaDefinition
            formId={formId}
            workflow={workflow as Workflow}
            onSubmit={handleSubmitClassificationSchemaDefinition}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.MSExcel:
      case ApplicationName.GoogleSheets:
        return (
          <CreateSystemActionContent
            formId={formId}
            workflow={workflow as Workflow}
            edit
            onSubmit={handleSubmitCreateSystemActionContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.GenerateOutput:
        return (
          <GenerateOutput
            formId={formId}
            onSubmit={handleSubmitGenerateOutputContent}
            previousClicked={previousClicked}
          />
        );
      // THIS CASE WILL BE REPLACED BY EXTRACT ENTITY APPLICATION WHEN ADDED FROM BE
      default:
        return (
          <ExtractSchemaDefinition
            formId={formId}
            workflow={workflow as Workflow}
            onSubmit={handleSubmitExtractSchemaDefinition}
            isEmailExtraction={hasEmailTrigger}
            previousClicked={previousClicked}
          />
        );
    }
  };

  const getStepContent = (index: number) => {
    if (index === 0) {
      return (
        <WorkflowGeneralContent
          edit
          workflow={workflow as Workflow}
          onSubmit={handleSubmitGeneralContent}
        />
      );
    }
    const applications = getApplicationNames(workflow as Workflow);
    if (index > applications.length) {
      return (
        <AddUserStepContent
          originalWorkflow={selectedWorkflow}
          formId={`form${index + 1}`}
          edit
          workflow={workflow as Workflow}
          onSubmit={handleSubmitAddUserContent}
          isEmailExtraction={hasEmailTrigger}
          previousClicked={previousClicked}
        />
      );
    }
    return getStepForApplication(applications[index - 1], index);
  };

  if (selectedWorkflowLoading || !workflow) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        height={'100vh'}
        alignItems={'center'}
      >
        {contextHolder}
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box bgcolor={'#F6F8FC'} paddingX={'50px'} paddingTop={'50px'}>
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '8px' }}
      >
        Edit Workflow: {workflow.displayName}
      </CustomTypography>
      <CustomTypography
        sx={{ marginBottom: '40px' }}
        typographyType={TypographyType.MediumPara}
      >
        {workflow.description}
      </CustomTypography>
      <Stepper
        sx={{
          marginBottom: '80px',
          '& .MuiStepConnector-root .MuiStepConnector-line': {
            minHeight: '48px',
            borderColor: '#031026',
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {STEPS.map((step, index) => {
          const isLastStep = index === STEPS.length - 1;
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabel
                StepIconComponent={(props: StepIconProps) => (
                  <>
                    <WorkflowCreationStep
                      key={index}
                      {...props}
                      {...step}
                      sx={{}}
                      ariaLabel={`Edit Workflow Step ${index + 1} ${
                        step.title
                      }`}
                    />
                  </>
                )}
              ></StepLabel>
              <StepContent
                sx={
                  !isLastStep
                    ? {
                        borderLeft: 'none !important',
                        paddingLeft: '0px !important',
                        marginLeft: '0px  !important',
                      }
                    : {
                        borderLeft: 'none !important',
                        marginTop: '30px',
                        paddingLeft: '0px',
                        marginLeft: '50px !important',
                      }
                }
              >
                <Box
                  display={'flex'}
                  width={
                    isLastStep
                      ? STEP_CONTENT_LAST_STEP_WIDTH
                      : STEP_CONTENT_WIDTH
                  }
                  bgcolor={'#FFFFFF'}
                >
                  {getStepContent(index)}
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Box
        paddingBottom={'20px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '25px',
        }}
      >
        <CustomButton
          variant='outlined'
          onClick={() => navigate(-1)}
          disabled={updatingWorkflow}
        >
          Cancel
        </CustomButton>
        {activeStep > 0 && (
          <CustomButton
            onClick={() => setPreviousClicked(true)}
            variant='outlined'
            disabled={updatingWorkflow}
          >
            Previous
          </CustomButton>
        )}
        {activeStep < STEPS.length - 1 && (
          <CustomButton form={`form${activeStep + 1}`} type='submit'>
            Next
          </CustomButton>
        )}
        <CustomButton
          form={`form${activeStep + 1}`}
          type='submit'
          invisible={!isLastStep}
          disabled={updatingWorkflow}
          loading={updatingWorkflow}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default memo(WorkflowUpdatePage);
