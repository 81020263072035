import { Box } from '@mui/material';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { ReactComponent as SettingsIcon } from '../../../../static/icons/settings-rounded.svg';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface Props {
  workflow: Workflow;
  onSubmit: (values: FormikValues) => void;
  edit?: boolean;
  hideNote?: boolean;
  step: number;
  moveToStep: number | null;
}

const WorkflowGeneralContent: FC<Props> = ({
  onSubmit,
  workflow,
  edit,
  hideNote,
  step,
  moveToStep,
}) => {
  const formik = useFormik({
    initialValues: {
      workflow_name: workflow.displayName || '',
      description: workflow.description || '',
      time_spent: workflow.manualTimeCostInMinutes || '',
    },
    validationSchema: Yup.object({
      workflow_name: Yup.string()
        .required('Workflow name is required.')
        .matches(/^[^/]*$/, 'Workflow name cannot contain "/".')
        .max(100, 'Workflow name must be at most 100 characters.'),
      time_spent: Yup.number().test(
        'positive',
        'Please provide a positive number for Time spent on the process manually (minutes)',
        (value) => (value as number) > 0,
      ),
    }),
    onSubmit: onSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  return (
    <FormikProvider value={formik}>
      <Form id='form1' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box
          display={'flex'}
          marginY={'32px'}
          paddingLeft={'19px'}
          justifyContent={'space-between'}
        >
          {/* FORM */}
          <Box>
            {/* WORKFLOW NAME */}
            <Box>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                What&apos;s the name and description of the workflow?
              </OrbyTypography>

              <OrbyTextField
                placeholder='Workflow name'
                {...getFieldProps('workflow_name')}
                width='448px'
                error={
                  touched.workflow_name && errors.workflow_name
                    ? errors.workflow_name
                    : ''
                }
                disabled={edit}
              />
            </Box>
            {/* WORKFLOW DESCRIPTION */}
            <Box marginTop={'12px'}>
              <OrbyTextField
                multiline={true}
                rows={5}
                placeholder='Describe this workflow'
                {...getFieldProps('description')}
                width='448px'
                inputStyle={{
                  padding: '10px 14px',
                }}
                inputSx={{
                  padding: '0!important',
                }}
                error={
                  touched.description && errors.description
                    ? errors.description
                    : ''
                }
              />
            </Box>
            <Box marginTop={'24px'}>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                How long do you spend on this process?
              </OrbyTypography>

              <OrbyTextField
                placeholder='Time spent on the process manually (minutes)'
                {...getFieldProps('time_spent')}
                width='448px'
                type='number'
                error={
                  touched.time_spent && errors.time_spent
                    ? errors.time_spent
                    : ''
                }
              />
            </Box>
          </Box>
          {!hideNote && (
            <Box
              bgcolor={OrbyColorPalette['green-50']}
              borderRadius={'10px'}
              height={'fit-content'}
              padding={'16px'}
              display={'flex'}
              gap={'16px'}
              width={'315px'}
            >
              <Box>
                <SettingsIcon
                  style={{ color: OrbyColorPalette['green-500'] }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                paddingRight={'10px'}
              >
                <span id='workflow_name'>
                  <OrbyTypography color={OrbyColorPalette['grey-700']}>
                    &quot;Workflow name&quot; needs to be unique in your
                    organization.
                  </OrbyTypography>
                </span>
                <span id='time_spent'>
                  <OrbyTypography color={OrbyColorPalette['grey-700']}>
                    &quot;Time spent on the process manually&quot; is used to
                    calculate time that Orby saves for you through this
                    workflow.
                  </OrbyTypography>
                </span>
                {!edit && (
                  <OrbyTypography color={OrbyColorPalette['grey-700']}>
                    Name cannot be changed after workflow creation.
                  </OrbyTypography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(WorkflowGeneralContent);
