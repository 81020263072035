import React, { useEffect, useState } from 'react';
import { Container, Box } from '@mui/material';

import OrbyLoginLogo from '../../static/orby-logo-main.svg';
import OrbyBackground from '../../static/orby-bg.svg';
import { LoginForm } from './components/LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { storageService } from '../../services/StorageService';
import { logoutAction } from '../../redux/actions/auth.action';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    const token = await storageService.getStoredToken();
    const req: LogoutRequest = {};
    if (token) {
      req.name = token.sessionId as string;
    }
    dispatch(logoutAction(req));
  };

  useEffect(() => {
    if (user && !selectedOrgInfo && !loggingOut) {
      setTimeout(() => {
        setLoggingOut(true);
        handleLogout();
      }, 5000);
    }
  }, [user, selectedOrgInfo, loggingOut]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        minWidth: '1400px',
        background: 'linear-gradient(63.57deg, #3E1C96 0%, #6941C6 99.43%)',
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'scroll',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: '1200px',
          minWidth: '1200px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '788px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.96) 0%, rgba(249, 245, 255, 0.96) 100%)',
          borderRadius: '10px',
        }}
      >
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {/* LEFT SIDE IMAGE BACKGROUND */}
          <Container
            sx={{
              borderRadius: '10px',
              backgroundImage: `url(${OrbyBackground})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              paddingLeft: '60px!important',
              paddingTop: '60px',
              display: 'flex',
              flex: 1,
            }}
          >
            <Box marginBottom={4} sx={{ width: '100%' }}>
              <img src={OrbyLoginLogo} alt='Orby Logo' />
            </Box>
          </Container>
          {/* RIGHT SIDE LOGIN FORM */}
          <LoginForm />
        </Container>
      </Box>
    </Box>
  );
};

export default React.memo(LoginPage);
