import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import React, { FC, memo } from 'react';

const headerTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: '8px',
            '&::placeholder': {
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 400,
              color: 'B6B6B6',
            },
          },
        },
      },
    },
  },
});
interface CustomSearchFieldProps {
  type?: 'primary' | 'secondry';
  placeHolder?: string;
  onChange: (x: any) => void;
  autoFocus?: boolean;
}

const CustomSearchField: FC<CustomSearchFieldProps> = ({
  type = 'primary',
  placeHolder,
  onChange,
  autoFocus = false,
}) => {
  const isPrimary = type === 'primary' ? true : false;
  return (
    <Box sx={{ marginY: 1, marginTop: 2 }}>
      <ThemeProvider theme={isPrimary ? headerTheme : {}}>
        <TextField
          autoFocus={autoFocus}
          variant='outlined'
          fullWidth
          autoComplete='search'
          type='search'
          placeholder={placeHolder ?? 'Search'}
          onChange={onChange}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          InputProps={{
            startAdornment: (
              <Box>
                <InputAdornment position='start'>
                  <IconButton tabIndex={-1} sx={{ width: '10px' }}>
                    <GridSearchIcon fontSize='medium' />
                  </IconButton>
                </InputAdornment>
              </Box>
            ),
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default memo(CustomSearchField);
