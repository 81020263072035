import { Box, Typography, styled } from '@mui/material';
import { RowContainer } from './Styled';

import DuplicateDetectedResult from './DuplicateDetectedResult';
import React, { useContext } from 'react';
import { OrbyColorPalette } from 'orby-ui/src';
import { traverseActionsRecursively } from 'workflow-utils/src/v2/workflow';
import { ActionsContext } from './ActionsContext';
import { ActionObject, ExecutedActionObject } from 'workflow-utils/src/types';

const Container = styled(RowContainer)(() => ({
  display: 'block',
  marginBottom: '10px',
  justifyContent: 'flex-start',
  borderRadius: '8px',
  backgroundColor: OrbyColorPalette['indigo-50'],
  padding: '5px 15px',
  wordWrap: 'break-word',
  color: OrbyColorPalette['grey-900'],
  '& .MuiTypography-root': {
    lineHeight: '25px',
    fontSize: '18px',
  },
}));

function getConfidenceScore(executedAction: ExecutedActionObject): number {
  if (executedAction.predictedOutputValue === '') {
    return 0;
  }

  try {
    const predictedOutputValue = JSON.parse(
      executedAction.predictedOutputValue!,
    );
    if (
      typeof predictedOutputValue === 'object' &&
      isNaN(Number(predictedOutputValue.confidence))
    ) {
      return predictedOutputValue.confidence;
    }
  } catch (error) {
    console.error('Error parsing predicted output value:', error);
  }

  return 0;
}

interface Props {
  action: ActionObject;
}

const DuplicateDetected: React.FC<Props> = ({ action }) => {
  const { actions, executedActions } = useContext(ActionsContext)!;

  const targetExecutedAction = executedActions.find(
    (eAction) => eAction.preparedActionUuid === action.id,
  );

  if (!targetExecutedAction) {
    console.error(`targetExecutedAction not found, action's id:`, action.id);
    return null;
  }

  const referenceActionIds: string[] = [];
  const referenceActions: ActionObject[] = [];

  if (action?.detectDuplicateLineItems?.source?.referenceValue) {
    referenceActionIds.push(
      action.detectDuplicateLineItems.source.referenceValue,
    );
  }

  traverseActionsRecursively(actions, (a) => {
    if (referenceActionIds.includes(a.id!)) {
      referenceActions.push(a);
    }
  });

  // TODO: in the future, maybe have more fields neet to display. From now, just display getFormAction name (not implemented in extension as well)
  const formNames: string[] = [];
  referenceActions.forEach((pa) => {
    if (pa.getForm?.formLocator?.name) {
      formNames.push(pa.getForm?.formLocator.name);
    }
  });

  let duplicates: { itemIndices: [number, number] }[] = [];

  if (targetExecutedAction.outputValue !== '') {
    try {
      const outputValue = JSON.parse(targetExecutedAction.outputValue!);
      if (
        typeof outputValue === 'object' &&
        outputValue.duplicates &&
        Array.isArray(outputValue.duplicates)
      ) {
        duplicates = outputValue.duplicates;
      }
    } catch (error) {
      console.error('Error parsing output value:', error);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Container>
        <Typography component={'span'}>
          Detect duplicate items within the following elements:
        </Typography>
        <Typography component={'span'}>{formNames.join(', ')}</Typography>
      </Container>

      <DuplicateDetectedResult
        duplicates={duplicates}
        score={getConfidenceScore(targetExecutedAction)}
      />
    </Box>
  );
};

export default DuplicateDetected;
