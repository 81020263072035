import React, { FC, memo, useState } from 'react';
import { Box, FormControl } from '@mui/material';
import {
  FieldArray,
  Form,
  FormikProvider,
  FormikValues,
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import {
  Connector,
  LogicalOperator,
  Operator,
} from 'protos/pb/v1alpha2/connector';
import { getGroupConditionsFromCompositeGroupConditions } from '../../../../utils/helpers';
import ConditionGroupBox from './ConditionGroupBox';
import { Filter } from '../../../../utils/constants';
import {
  OrbyButton,
  OrbyCheckbox,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import { SourceWorkflowSelect } from '../../components/SourceWorkflowSelect';
import { DestinationWorkflowSelect } from '../../components/DestinationWorkflowSelect';
import { ReactComponent as AddButtonIcon } from '../../../../static/icons/add-button-plus-blue.svg';

interface Props {
  connector?: Connector;
  workflowFilters: Filter[];
  workflowFiltersLoading: boolean;
  onSubmit: (values: FormikValues) => void;
}

const validationSchema = Yup.object().shape({
  source_workflow: Yup.string().required('Source Workflow is required'),
  destination_workflow: Yup.string().required(
    'Destination Workflow is required',
  ),
  conditionGroups: Yup.array().of(
    Yup.object().shape({
      conditions: Yup.array().of(
        Yup.object().shape({
          operator: Yup.string().required('Operator is required'),
          value: Yup.string().required('Value is required'),
          attributeType: Yup.object().shape({
            parent: Yup.string(),
            name: Yup.string().required('Attribute is required'),
          }),
        }),
      ),
    }),
  ),
});

const CreateConnectorCondition: FC<Props> = ({
  onSubmit,
  connector,
  workflowFilters,
  workflowFiltersLoading,
}) => {
  const [checked, setChecked] = useState(
    connector?.assignmentConfig?.preserveAssignee || false,
  );

  const initialValues = () => {
    if (!connector) {
      return {
        source_workflow: undefined,
        destination_workflow: undefined,
        preserve_assignee: checked,
        conditionGroups: [
          {
            conditions: [
              {
                operator: Operator.GREATER_THAN,
                value: '',
                logical_operator: undefined,
                attributeType: { parent: '', name: '' },
              },
            ],
            logical_operator: undefined,
          },
        ],
      };
    }
    return {
      source_workflow: connector.sourceWorkflow?.workflowResourceName,
      destination_workflow: connector.destinationWorkflow?.workflowResourceName,
      preserve_assignee: checked,
      conditionGroups: getGroupConditionsFromCompositeGroupConditions(
        connector.groupCondition!,
        undefined,
        undefined,
        true,
      ),
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id='form2' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box
          id='connector-condition-wrapper'
          paddingY={'32px'}
          paddingX={'19px'}
          width={'75%'}
        >
          <Box>
            <FormControl
              size='small'
              sx={{ width: '100%' }}
              error={Boolean(touched.source_workflow && errors.source_workflow)}
            >
              <Box id={'role-label'} display={'none'} tabIndex={-1}>
                {values.source_workflow
                  ? values.source_workflow + 'selected'
                  : 'Select Source Workflow'}
              </Box>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                For
              </OrbyTypography>

              {/* SOURCE WORKFLOW SELECT */}
              <SourceWorkflowSelect
                formik={formik}
                workflowFilters={workflowFilters}
                workflowFiltersLoading={workflowFiltersLoading}
              />
            </FormControl>
          </Box>

          <Box marginTop={'32px'}>
            <FieldArray name='conditionGroups'>
              {({ push, form, remove }) => {
                return (
                  <>
                    <Box>
                      {form.values.conditionGroups.map(
                        (group: any, groupIndex: number) => (
                          <ConditionGroupBox
                            key={groupIndex}
                            getFieldProps={getFieldProps}
                            groupIndex={groupIndex}
                            group={group}
                            removeGroup={remove}
                            setFieldValue={setFieldValue}
                            values={values}
                            formik={formik}
                            isLast={form.values.conditionGroups.length === 1}
                          />
                        ),
                      )}
                    </Box>
                    <Box marginTop={'16px'}>
                      <OrbyButton
                        label='Add condition group'
                        variant='primary-outline'
                        startIcon={<AddButtonIcon />}
                        onClick={() => {
                          push({
                            conditions: [
                              {
                                operator: Operator.GREATER_THAN,
                                value: '',
                                attributeType: {
                                  name: '',
                                  parent: '',
                                },
                              },
                            ],
                            logical_operator: LogicalOperator.AND,
                          });
                        }}
                      />
                    </Box>
                  </>
                );
              }}
            </FieldArray>
          </Box>

          <Box
            marginTop={'32px'}
            flexDirection={'column'}
            gap={'6px'}
            display={'flex'}
          >
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
            >
              Go to
            </OrbyTypography>

            {/* DESTINATION WORKFLOW SELECT */}
            <DestinationWorkflowSelect
              workflowFiltersLoading={workflowFiltersLoading}
              formik={formik}
              workflowFilters={workflowFilters}
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={'8px'}
            marginTop={'32px'}
          >
            <OrbyCheckbox
              size='md'
              checked={checked}
              onClick={(e) => {
                if (e.target instanceof HTMLInputElement) {
                  setFieldValue('preserve_assignee', e.target.checked);
                  setChecked(e.target.checked);
                }
              }}
              title='Preserve Assignee'
              label="Assign destination workflow's tasks to the first round reviewers of associated source workflow's tasks if possible."
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(CreateConnectorCondition);
