import { Box, FormControl } from '@mui/material';
import { FormikValues, getIn } from 'formik';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import React, { FC } from 'react';

interface Props {
  entityDetails: {
    name: string;
    isParent?: boolean;
    parent?: string;
  }[];
  name: string;
  formik: FormikValues;
  width: string;
}

export const EntityFilterSelect: FC<Props> = ({
  entityDetails,
  name,
  formik,
  width,
}) => {
  return (
    <FormControl
      size='small'
      sx={{ width: width }}
      error={Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))}
    >
      <Box id={'role-label'} display={'none'} tabIndex={-1}>
        {getIn(formik.values, name)
          ? getIn(formik.values, name)?.name + 'selected'
          : 'Please select'}
      </Box>
      <OrbySelect
        width={width}
        aria-hidden={false}
        value={formik.values[name]}
        renderValue={() => {
          const renderText =
            getIn(formik.values, name)?.name || 'Please select';
          return (
            <OrbyTypography
              size={getIn(formik.values, name)?.name ? 'md' : 'sm'}
              color={
                getIn(formik.values, name)?.name
                  ? OrbyColorPalette['grey-900']
                  : OrbyColorPalette['grey-500']
              }
              weight={getIn(formik.values, name)?.name ? 'medium' : 'regular'}
            >
              {renderText}
            </OrbyTypography>
          );
        }}
        onChange={(e) => {
          formik.setFieldValue(name, JSON.parse(e.target.value as string));
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {entityDetails.map((entityDetail) => (
          <OrbyMenuItem
            sx={{
              paddingLeft: entityDetail.parent && '35px',
            }}
            width={width}
            key={entityDetail.parent + entityDetail.name}
            value={JSON.stringify(entityDetail)}
            title={entityDetail.name}
          />
        ))}
      </OrbySelect>
    </FormControl>
  );
};

export default EntityFilterSelect;
