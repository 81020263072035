import { Box, Tooltip } from '@mui/material';
import React, { FC, memo } from 'react';
import CustomTypography from '../../../../../../components/CustomTypography';
import { EntityInfo } from '../../../../../../redux/reducers/review_task.reducer';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { isShowNormalizedValue } from '../../../../../../utils/ReviewTaskUtils';
import { HelpOutlineOutlined } from '@mui/icons-material';
import NormalizedTextField from './components/NormalizedTextField';
import NormalizedMoneyField from './components/NormalizedMoneyField';
import NormalizedDateField from './components/NormalizedDateField';
import { useSelector } from 'react-redux';
import { allowEditingTaskSelector } from '../../../../../../redux/selectors/review_task.selectors';

interface FloatingModalNormalizedSectionProps {
  selectedEntity: EntityInfo;
  task: Task;
  isTableView?: boolean;
}

const FloatingModalNormalizedSection: FC<
  FloatingModalNormalizedSectionProps
> = ({ selectedEntity, task, isTableView: isTable = false }) => {
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const label = isTable ? 'Normalized Value' : '';

  return (
    <Box>
      {/* THE BELOW SECTION SHOWS NORMALIZED VALUE */}
      {selectedEntity.normalizedEntityType &&
      isShowNormalizedValue(selectedEntity.normalizedEntityType) ? (
        <Box paddingBottom={isTable ? '0px' : '20px'}>
          {!isTable && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              paddingBottom={'8px'}
            >
              <CustomTypography
                lineHeight='20px'
                size={'14px'}
                color='#344054'
                weight={500}
              >
                Normalized Value
              </CustomTypography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#000',
                      borderRadius: '8px',
                    },
                  },
                }}
                aria-label='More info'
                title='You can format data using normalized value.
                 It will not affect the prediction performance'
                placement='top-start'
              >
                <HelpOutlineOutlined
                  fontSize='small'
                  sx={{ color: '#101828', marginRight: '2px' }}
                />
              </Tooltip>
            </Box>
          )}
          {![
            EntityDataType.ENTITY_TYPE_MONEY,
            EntityDataType.ENTITY_TYPE_DATE,
          ].includes(selectedEntity?.normalizedEntityType) && (
            <NormalizedTextField
              task={task}
              entity={selectedEntity}
              placeholder={'Normalized Value'}
              readonly={!allowEditingTask}
              multiline={isTable ? false : true}
              name='normalized-value'
              size='full'
              label={label}
              insideTableLayout={isTable}
            />
          )}
          {selectedEntity?.normalizedEntityType ===
            EntityDataType.ENTITY_TYPE_MONEY && (
            <NormalizedMoneyField
              label={label}
              task={task}
              selectedEntity={selectedEntity}
              units={selectedEntity?.normalizedValue?.moneyValue?.units ?? 0}
              nanos={selectedEntity?.normalizedValue?.moneyValue?.nanos ?? 0}
              currencyCode={
                selectedEntity?.normalizedValue?.moneyValue
                  ?.currencyCode as string
              }
              insideTableLayout={isTable}
            />
          )}
          {selectedEntity?.normalizedEntityType ===
            EntityDataType.ENTITY_TYPE_DATE && (
            <NormalizedDateField
              label={label}
              task={task}
              selectedEntity={selectedEntity}
              year={selectedEntity?.normalizedValue?.dateValue?.year}
              month={selectedEntity?.normalizedValue?.dateValue?.month}
              day={selectedEntity?.normalizedValue?.dateValue?.day}
              insideTableLayout={isTable}
            />
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default memo(FloatingModalNormalizedSection);
