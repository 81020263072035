import {
  Autocomplete,
  Box,
  CircularProgress,
  ListItem,
  TextField,
} from '@mui/material';
import React, { FC, memo, useState } from 'react';
import { ELLIPSIS_STYLE } from '../utils/constants';
import CustomTypography from './CustomTypography';
import { Check, ExpandMore, Search } from '@mui/icons-material';

interface Props {
  value: string;
  onChange: (x: any) => void;
  options: { label: string; value: string }[];
  placeholder: string;
  loading: boolean;
}

const CustomSearchSelect: FC<Props> = ({
  value,
  onChange,
  options,
  placeholder,
  loading,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectedOption = options.find((option) => option.value === value);
  const [searchValue, setSearchValue] = useState('');

  const highlightText = (text: string) => {
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchValue.toLowerCase() ? (
        <Box component={'span'} key={index} sx={{ color: '#6941C6' }}>
          {part}
        </Box>
      ) : (
        part
      ),
    );
  };

  return (
    <Autocomplete
      options={options}
      value={selectedOption || null}
      onChange={(e: any, val: any) => {
        onChange(val?.value || '');
        setSearchValue('');
      }}
      blurOnSelect
      disableClearable
      loading={loading}
      openOnFocus
      loadingText={
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      }
      noOptionsText={
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {searchValue ? 'No related workflow found' : 'No options'}
        </Box>
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
          title={params.inputProps.value.label}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '8px',
              paddingRight: '14px !important',
            },
            '& .MuiInputBase-input': {
              height: '15px',
              fontWeight: 500,
              fontSize: '16px',
            },
          }}
          placeholder={placeholder}
          variant='outlined'
          fullWidth
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFocused ? (
                  <Search />
                ) : (
                  <ExpandMore sx={{ color: '#667085' }} />
                )}
              </>
            ),
          }}
        />
      )}
      renderOption={(props: any, option: any) => (
        <ListItem
          {...props}
          key={option.value}
          title={option.label}
          sx={{
            padding: '14px !important',
            display: 'flex',
            justifyContent: 'space-between !important',
            ':hover': {
              '& .MuiTypography-root': {
                color: '#344054',
              },
            },
          }}
        >
          <CustomTypography
            weight={500}
            size={'16px'}
            sx={{ ...ELLIPSIS_STYLE }}
            color={
              selectedOption?.value === option.value ? '#344054' : '#101828'
            }
          >
            {highlightText(option.label)}
          </CustomTypography>
          {selectedOption?.value === option.value && (
            <Check sx={{ color: '#6941C6' }} />
          )}
        </ListItem>
      )}
    />
  );
};

export default memo(CustomSearchSelect);
