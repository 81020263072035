import {
  Box,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import React, { FC, memo, useEffect, useRef } from 'react';
import { GridSearchIcon } from '@mui/x-data-grid';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';

interface SearchTaskFieldProps {
  displayNamePrefix: string;
  setDisplayNamePrefix: (x: string) => void;
  placeholder?: string;
  clearOnOrgChange?: boolean; // clears field value when organization changes
  sx?: SxProps<Theme>;
}

const SearchTaskField: FC<SearchTaskFieldProps> = ({
  placeholder = 'Search by task name',
  clearOnOrgChange = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const handleValueReset = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    return () => {
      if (clearOnOrgChange) handleValueReset();
    };
  }, [selectedOrgInfo]);

  return (
    <TextField
      inputRef={inputRef}
      size={'small'}
      sx={{ width: '350px', marginLeft: '16px', ...props.sx }}
      variant='outlined'
      autoComplete='search'
      type='search'
      placeholder={placeholder}
      defaultValue={props.displayNamePrefix}
      onChange={_.debounce((event) => {
        props.setDisplayNamePrefix(event.target.value?.trim());
      }, 400)}
      InputProps={{
        startAdornment: (
          <Box>
            <InputAdornment position='start'>
              <IconButton sx={{ width: '10px' }}>
                <GridSearchIcon fontSize='medium' />
              </IconButton>
            </InputAdornment>
          </Box>
        ),
      }}
    />
  );
};

export default memo(SearchTaskField);
