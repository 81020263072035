import { Box, Step, StepContent, Stepper } from '@mui/material';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';

import {
  ManualAssignment,
  ReviewerList,
  ReviewTriggerCondition,
  Workflow,
  WorkflowAssignmentOption,
  WorkflowLearningSettings,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWorkflowAction,
  createWorkflowErrorAction,
} from '../../../redux/actions/workflow.action';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createdWorkflowSelector,
  processingWorkflowSelector,
  processWorkflowErrorSelector,
} from '../../../redux/selectors/workflow.selectors';
import { notification } from 'antd';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import {
  addUsersToReviewList,
  findSelectedTrigger,
  getActionIndex,
  getApplicationNames,
  getGroupConditionExtractedFields,
  getStepConstants,
  getTriggerIndex,
} from '../../../utils/helpers';
import { ApplicationName } from '../../../utils/protos/enums';

import { SFTPParamTriggerTriggerType } from 'protos/application/application_params';
import {
  AssignmentMode,
  GDRIVE_SFTP_MAPPING_COLUMNS,
  GMAIL_MAPPING_COLUMNS,
} from '../../../utils/constants';
import {
  EntityDataType,
  EntityDetails,
  GenerateOutputParamActionType,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  CompositeGroupCondition,
  ConditionOptions,
  ConditionType,
} from 'protos/pb/v1alpha2/connector';
import { FormikValues } from 'formik';
import {
  getWorkflowAssignmentOptions,
  handleSubmitEmailTriggerForm,
  handleSubmitSheetActionForm,
  updateStepsTriggerOutlookLabels,
} from '../../../utils/WorkflowUtils';

import WorkflowGeneralContent from './Steps/WorkflowGeneralContent';
import TriggerConditionContent from './Steps/TriggerConditionContent';
import CreateSystemActionContent from './Steps/CreateSystemActionContent';
import AddUserStepContent from './Steps/AddUserStepContent';
import GenerateOutput from './Steps/GenerateOutput';
import ClassificationSchemaDefinition from './Steps/ClassificationSchemaDefinition';
import SftpTriggerContent from './Steps/SftpTriggerContent';
import ExtractSchemaDefinition, {
  SchemaEntity,
} from './Steps/ExtractSchemaDefinition';
import EmailTriggerContent from './Steps/EmailTriggerContent';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import StepLabelComponent from '../components/StepLabelComponent';
import StepperActionComponent from '../components/StepperActionComponent';

const getWorkflowObject = (workflowObject: Workflow) => {
  if (workflowObject) {
    return Workflow.fromJSON(workflowObject);
  }
  return Workflow.create({});
};

const WorkflowCreationPage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  // This variable is added to submit the form when previous button is clicked. This has been
  // passed as a prop to all the steps and when it is set true, the values that has been filled till
  // the click gets saved and user is navigated to previous step. After the form is saved, this gets reset
  // to false, so it won't affect the default save functionality when Next button is pressed
  const [previousClicked, setPreviousClicked] = useState(false);
  const location = useLocation();
  const [creationTriggered, setCreationTriggered] = useState(false);
  const [moveToStep, setMoveToStep] = useState<number | null>(null);
  const [stepsSubmitted, setStepsSubmitted] = useState<Array<number>>([]);

  const templateWorkflow = getWorkflowObject(location.state.workflow);
  const temporalWorkFlowName = templateWorkflow?.displayName ?? '';
  const temporalWorkFlowDescription = templateWorkflow?.description ?? '';
  /**
   * If it is not created via yaml from the workflow template
   * then we need to clear the name and description
   */
  if (!location.state?.isYamlWorkflow) {
    templateWorkflow.displayName = '';
    templateWorkflow.description = '';
  }
  const [workflow, setWorkflow] = useState<Workflow>(templateWorkflow);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workflowError = useSelector(processWorkflowErrorSelector);
  const addingWorkflow = useSelector(processingWorkflowSelector);
  const createdWorkflow = useSelector(createdWorkflowSelector);
  const user = useSelector(loggedInUserSelector)!;
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();

  const selectedTrigger = findSelectedTrigger(templateWorkflow);
  const STEPS = getStepConstants(workflow);

  const hasEmailTrigger = useMemo(() => {
    const applications = getApplicationNames(workflow);
    return (
      applications.includes(ApplicationName.Gmail) ||
      applications.includes(ApplicationName.Outlook)
    );
  }, [workflow]);

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  // This is to validate if user clicks on any step, then we validate that the step has been submitted
  const validateSteps = (
    moveToStep: number,
    stepsSubmittedArray: Array<number>,
  ) => {
    return Array.from(Array(moveToStep).keys()).every((step) =>
      stepsSubmittedArray.includes(step),
    );
  };

  const handleSubmitGeneralContent = (values: FormikValues) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    w.displayName = values.workflow_name;
    w.description = values.description;
    w.manualTimeCostInMinutes = parseInt(values.time_spent);
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(activeStep + 1);
    }
    setMoveToStep(null);
  };

  const handleSubmitEmailTriggerContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow;
    handleSubmitEmailTriggerForm(w, values);
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitTriggerConditionContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const gDriveIndex = getTriggerIndex(w, ApplicationName.GoogleDrive);
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.id = values['file_id'];
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.path = values['folder_url'];
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.ownerEmail = user.email;
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitSftpTriggerContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.type = SFTPParamTriggerTriggerType.NEW_OBJECT;
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.folderPath = values['folder_path'];
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitExtractSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const entityExtractionIndex = getActionIndex(
      w,
      ApplicationName.EntityExtraction,
    );
    const entitiesDetails: EntityDetails[] = [];
    const simpleSchemaEntities = values.schemaEntities.filter(
      (e: SchemaEntity) => !e.parentEntityId,
    );
    simpleSchemaEntities.forEach((simpleSchemaEntity: SchemaEntity) => {
      const properties: EntityDetails[] = [];
      if (
        simpleSchemaEntity.normalizationType ===
        EntityDataType.ENTITY_TYPE_NESTED
      ) {
        values.schemaEntities
          .filter(
            (e: SchemaEntity) => e.parentEntityId === simpleSchemaEntity.id,
          )
          .forEach((childEntity: SchemaEntity) => {
            properties.push(
              EntityDetails.create({
                entityType: childEntity.entityName,
                normalizationType: childEntity.normalizationType,
                properties: [],
              }),
            );
          });
      }
      entitiesDetails.push(
        EntityDetails.create({
          entityType: simpleSchemaEntity.entityName,
          normalizationType: simpleSchemaEntity.normalizationType,
          properties,
        }),
      );
    });
    w.steps![entityExtractionIndex.stepIndex!].actions![
      entityExtractionIndex.actionIndex!
    ].entityExtraction!.entitiesDetails = entitiesDetails;
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitGenerateOutputContent = (isNextClicked: boolean) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    const generateOutputApplicationIndex = getActionIndex(
      w,
      ApplicationName.GenerateOutput,
    );

    if (generateOutputApplicationIndex.actionIndex !== -1) {
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.encryptionRequired = sftpIndex.triggerIndex !== -1;
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.type =
        GenerateOutputParamActionType.ACTION_CREATE_JSON_FILE;
    }

    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitClassificationSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const classificationLabelsIndex = getActionIndex(
      w,
      ApplicationName.DocumentClassification,
    );
    const gsheetsIndex = getActionIndex(w, ApplicationName.GoogleSheets);
    w.steps![classificationLabelsIndex.stepIndex!].actions![
      classificationLabelsIndex.actionIndex!
    ].classification!.classificationLabels = values.schemaClassificationLabels;
    let defaultEntities: string[] = [];
    if (gsheetsIndex.actionIndex !== -1) {
      if (
        [ApplicationName.GoogleDrive, ApplicationName.SftpServer].includes(
          selectedTrigger!,
        )
      ) {
        defaultEntities = GDRIVE_SFTP_MAPPING_COLUMNS;
      }
      if (hasEmailTrigger) {
        defaultEntities = GMAIL_MAPPING_COLUMNS;
      }
      w.steps![gsheetsIndex.stepIndex!].actions![
        gsheetsIndex.actionIndex!
      ].gsheets!.action!.addRowOption!.mappingColumns = defaultEntities;
    }
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitCreateSystemActionContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow;
    handleSubmitSheetActionForm(w, values);
    setWorkflow(w);
    const stepsSubmittedArray = [
      ...new Set([...stepsSubmitted, activeStep]),
    ].sort();
    setStepsSubmitted(stepsSubmittedArray);
    if (moveToStep != null && validateSteps(moveToStep, stepsSubmittedArray)) {
      setActiveStep(moveToStep);
    } else {
      setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    }
    setMoveToStep(null);
    setPreviousClicked(false);
  };

  const handleSubmitAddUserContent = (
    values: FormikValues,
    isSubmitClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    let roundOneUsers = ReviewerList.create({});
    roundOneUsers = addUsersToReviewList(values.users);
    if (values.assignmentMode === AssignmentMode.UNASSIGNED) {
      roundOneUsers.assignmentOption = WorkflowAssignmentOption.create({
        manualAssignment: ManualAssignment.create({
          users: roundOneUsers.users,
        }),
      });
      // empty the users list for round one since we have already included them in manual assignment option
      roundOneUsers.users = [];
    } else if (values.showAdvanced) {
      roundOneUsers.assignmentOption = getWorkflowAssignmentOptions(values);
    }
    w.learningSettings = WorkflowLearningSettings.create({
      reviewers: Object.values(values.learningSettings),
    });
    roundOneUsers.roundNumber = 1;
    w.reviewerLists = [];
    w.reviewerLists.push(roundOneUsers);
    if (values.roundTwoUsers.length > 0) {
      let roundTwoUsers = ReviewerList.create({});
      roundTwoUsers = addUsersToReviewList(values.roundTwoUsers);
      roundTwoUsers.roundNumber = 2;
      roundTwoUsers.triggerCondition = ReviewTriggerCondition.create({});
      roundTwoUsers.triggerCondition.conditionType =
        ConditionType.RANDOM_SAMPLE_PERCENT;
      roundTwoUsers.triggerCondition.conditionOptions = ConditionOptions.create(
        {},
      );
      roundTwoUsers.triggerCondition.conditionOptions.percentOfRandomSample =
        values.samplePercentage;
      w.reviewerLists.push(roundTwoUsers);
    }
    w.reviewerLists[0].triggerCondition = ReviewTriggerCondition.create({});
    if (values.selectedMode === WorkflowMode.ASSISTED) {
      w.reviewerLists[0].triggerCondition.conditionType = values.conditionType;
      w.reviewerLists[0].triggerCondition.conditionOptions =
        ConditionOptions.create({});
      if (values.conditionType !== ConditionType.ANY_EMPTY_PREDICTIONS) {
        if (values.conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD) {
          w.reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            CompositeGroupCondition.create({});
          w.reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            getGroupConditionExtractedFields(values);
        } else {
          w.reviewerLists[0].triggerCondition.conditionOptions.confidenceScore =
            Number(values.confidenceScore);
        }
      }
    } else if (values.selectedMode === WorkflowMode.DEFAULT) {
      w.needAttentionThresholdDefaultMode =
        Number(values.needAttentionThresholdDefaultMode) / 100;
    } else {
      w.reviewerLists[0].triggerCondition.conditionType =
        ConditionType.UNSPECIFIED;
    }

    /**
     * IF WORKFLOW TYPE IS OUTLOOK
     * LOOK FOR outlook-all-emails label and remove it
     * The reason for doing this is because Outlook doesn’t have a label for ‘Inbox'.
     * However by sending no labels for an outlook workflow while creating workflow, we can achieve the same behaviour.
     */
    updateStepsTriggerOutlookLabels(w);

    w.mode = values.selectedMode;
    w.templateResourceName = w.name;
    w.organizationResourceName = selectedOrgInfo?.orgResourceName;
    setWorkflow(w);
    if (isSubmitClicked) {
      w.name = undefined;
      dispatch(createWorkflowAction(w));
      setCreationTriggered(true);
    } else {
      setActiveStep(activeStep - 1);
      setPreviousClicked(false);
    }

    /**
     * Add workflow admins
     */
    w.admins = values.workflowAdmins;
    w.adminEmailMessage = values.adminEmailMessage;
    w.sendAdminEmail = true;
  };

  const getStepForApplication = (applicationName: string, index: number) => {
    const formId = `form${index + 1}`;
    switch (applicationName) {
      case ApplicationName.Gmail:
      case ApplicationName.Outlook:
        return (
          <EmailTriggerContent
            formId={formId}
            onSubmit={handleSubmitEmailTriggerContent}
            workflow={workflow}
            edit={false}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      case ApplicationName.SftpServer:
        return (
          <SftpTriggerContent // DONE
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitSftpTriggerContent}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      case ApplicationName.GoogleDrive:
        return (
          <TriggerConditionContent // DONE
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitTriggerConditionContent}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      case ApplicationName.DocumentClassification:
        return (
          <ClassificationSchemaDefinition
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitClassificationSchemaDefinition}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      case ApplicationName.MSExcel:
      case ApplicationName.GoogleSheets:
        return (
          <CreateSystemActionContent
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitCreateSystemActionContent}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      case ApplicationName.GenerateOutput:
        return (
          <GenerateOutput
            formId={formId}
            onSubmit={handleSubmitGenerateOutputContent}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
      // THIS CASE WILL BE REPLACED BY EXTRACT ENTITY APPLICATION WHEN ADDED FROM BE
      default:
        return (
          <ExtractSchemaDefinition
            formId={formId}
            workflow={workflow}
            isEmailExtraction={hasEmailTrigger}
            onSubmit={handleSubmitExtractSchemaDefinition}
            previousClicked={previousClicked}
            moveToStep={moveToStep}
            step={index}
          />
        );
    }
  };

  const getStepContent = (index: number) => {
    if (index === 0) {
      return (
        <WorkflowGeneralContent
          workflow={workflow}
          onSubmit={handleSubmitGeneralContent}
          moveToStep={moveToStep}
          step={index}
        />
      );
    }
    const applications = getApplicationNames(workflow);
    if (index > applications.length) {
      return (
        <AddUserStepContent
          formId={`form${index + 1}`}
          workflow={workflow}
          onSubmit={handleSubmitAddUserContent}
          isEmailExtraction={hasEmailTrigger}
          previousClicked={previousClicked}
        />
      );
    }
    return getStepForApplication(applications[index - 1], index);
  };

  const handleStepUpdate = (step: number) => {
    setMoveToStep(null);
    setTimeout(() => {
      setMoveToStep(step);
    }, 50);
  };

  useEffect(() => {
    if (moveToStep !== null) {
      if (activeStep > moveToStep) {
        setActiveStep(moveToStep);
      }
    }
  }, [moveToStep]);

  useEffect(() => {
    if (workflowError && creationTriggered) {
      // revert back the name since its not saved successfully
      workflow.name = workflow.templateResourceName;
      setWorkflow(workflow);
      openError(workflowError);
      dispatch(createWorkflowErrorAction(undefined));
    }
  }, [workflowError]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (createdWorkflow && creationTriggered) {
      openSuccess(
        'workflow ' + createdWorkflow.name + ' is created successfully',
      );
      workflow.name = workflow.templateResourceName;
      setWorkflow(workflow);
      setTimeout(() => {
        setActiveStep(0);
        navigate('/workflow');
      }, 1000);
    }
  }, [createdWorkflow]);

  return (
    <Box
      bgcolor={OrbyColorPalette['white-0']}
      paddingX={'48px'}
      paddingY={'64px'}
      width={'100%'}
    >
      {contextHolder}
      <OrbyTypography
        size='display-xs'
        weight='semibold'
        sx={{
          marginBottom: '24px',
        }}
      >
        Workflow Creation from {temporalWorkFlowName}
      </OrbyTypography>
      <OrbyTypography
        size='md'
        color={OrbyColorPalette['grey-600']}
        sx={{
          marginBottom: '24px',
        }}
      >
        {temporalWorkFlowDescription}
      </OrbyTypography>

      {/* STEPPER */}
      <Stepper
        sx={{
          '& .MuiStepContent-root': {
            borderColor: OrbyColorPalette['blue-700'],
            borderLeftWidth: '2px',
            marginLeft: '24px',
          },
          '& .MuiStepConnector-root': {
            maxHeight: '12px',
          },
          '& .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['grey-300'],
            borderLeftWidth: '2px',
            marginLeft: '12px',
          },
          '& .MuiStepConnector-lineVertical': {
            height: '0px',
          },
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['grey-300'],
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
      >
        {STEPS.map((step, index) => {
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabelComponent
                title={step.title}
                isActiveStep={activeStep === index}
                step={index + 1}
                isStepCompleted={activeStep > index}
                updateStep={handleStepUpdate}
              />
              <StepContent>
                {/* CONTENT */}
                <Box>{getStepContent(index)}</Box>

                {/* ACTIONS */}
                <StepperActionComponent
                  loading={addingWorkflow}
                  step={activeStep}
                  setActiveStep={setActiveStep}
                  totalSteps={STEPS.length - 1}
                  activeStep={activeStep}
                  setMoveToStep={setMoveToStep}
                />
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default memo(WorkflowCreationPage);
