import { Box } from '@mui/material';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import React, { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { Connector } from 'protos/pb/v1alpha2/connector';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface Props {
  connector: Connector;
  onSubmit: (values: FormikValues) => void;
  isStepOneClicked: boolean;
  setIsStepOneClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConnectorGeneralContent: FC<Props> = ({
  onSubmit,
  connector,
  isStepOneClicked,
  setIsStepOneClicked,
}) => {
  const formik = useFormik({
    initialValues: {
      connector_name: connector.displayName || '',
      description: connector.description || '',
    },
    validationSchema: Yup.object({
      connector_name: Yup.string()
        .required('Connector name is required.')
        .matches(/^[^/]*$/, 'Connector name cannot contain "/".')
        .max(100, 'Connector name must be at most 100 characters.'),
    }),
    onSubmit: onSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (isStepOneClicked) {
      // call formik submit
      formik.submitForm();
      setIsStepOneClicked(false);
    }
  }, [isStepOneClicked]);

  return (
    <FormikProvider value={formik}>
      <Form id='form1' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box paddingY={'32px'} paddingX={'19px'}>
          <Box>
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
            >
              What&apos;s the name and description of the connector?
            </OrbyTypography>
          </Box>
          <Box marginTop={'6px'}>
            <OrbyTextField
              placeholder='Connector name'
              {...getFieldProps('connector_name')}
              width='448px'
              error={
                touched.connector_name && errors.connector_name
                  ? errors.connector_name
                  : ''
              }
            />
          </Box>
          <Box marginTop={'12px'}>
            <OrbyTextField
              multiline={true}
              rows={5}
              placeholder='Describe this connector'
              {...getFieldProps('description')}
              width='448px'
              inputStyle={{
                padding: '10px 14px',
              }}
              inputSx={{
                padding: '0!important',
              }}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ''
              }
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(ConnectorGeneralContent);
