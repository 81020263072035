import { GetWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import { WorkflowDetailsType } from './actions.constants';
import {
  Workflow,
  WorkflowActionsForReview,
} from 'protos/pb/v1alpha1/orbot_workflow';
import { ActionObject } from 'workflow-utils/src/types';
import { Status } from '../reducers/workflow_detail.reducer';

export const setLoading = (payload: boolean) => ({
  type: WorkflowDetailsType.CHANGE_LOADING_STATUS,
  payload,
});

export const setWorkflowSuccess = (payload: Workflow) => ({
  type: WorkflowDetailsType.SET_WORKFLOW_SUCCESS,
  payload,
});

export const getWorkflowFailure = (error: Error) => ({
  type: WorkflowDetailsType.GET_WORKFLOW_FAILURE,
  payload: error,
});

export const fetchWorkflow = (req: GetWorkflowRequest) => ({
  type: WorkflowDetailsType.FETCH_WORKFLOW,
  payload: { req },
});

export const updateWorkflowTitle = (title: string) => ({
  type: WorkflowDetailsType.UPDATE_WORKFLOW_TITLE,
  payload: title,
});

export const setHasUnsavedChanges = (hasUnsavedChanges: boolean) => ({
  type: WorkflowDetailsType.SET_HAS_UNSAVED_CHANGES,
  payload: hasUnsavedChanges,
});

export const addInvalidChange = (field: Set<string>) => ({
  type: WorkflowDetailsType.ADD_INVALID_CHANGE,
  payload: field,
});

export const deleteInvalidChange = (field: Set<string>) => ({
  type: WorkflowDetailsType.DELETE_INVALID_CHANGE,
  payload: field,
});

export const setActionForReview = (
  actionsForReview: WorkflowActionsForReview[],
) => ({
  type: WorkflowDetailsType.SET_ACTIONS_FOR_REVIEW,
  payload: actionsForReview,
});

export const setLowConfidenceThreshold = (threshold: number) => ({
  type: WorkflowDetailsType.SET_LOW_CONFIDENCE_THRESHOLD,
  payload: threshold,
});

export const addReviewer = (userId: string) => ({
  type: WorkflowDetailsType.ADD_REVIEWER,
  payload: userId,
});

export const removeReviewer = (userId: string) => ({
  type: WorkflowDetailsType.REMOVE_REVIEWER,
  payload: userId,
});

export const saveWorkflow = (workflow: Workflow, orgId?: string) => ({
  type: WorkflowDetailsType.SAVE_WORKFLOW,
  payload: { workflow, orgId },
});

export const updateStatus = (status: Status) => ({
  type: WorkflowDetailsType.UPDATE_STATUS,
  payload: status,
});

export const addActionAfter = (actionId: string, processId: string) => ({
  type: WorkflowDetailsType.ADD_ACTION_AFTER,
  payload: { actionId, processId },
});

export const removeAction = (actionId: string, processId: string) => ({
  type: WorkflowDetailsType.REMOVE_ACTION,
  payload: { actionId, processId },
});

export const addFalseConditionAction = (
  actionId: string,
  processId: string,
) => ({
  type: WorkflowDetailsType.ADD_FALSE_CONDITION_ACTION,
  payload: { actionId, processId },
});

export const updateAction = (
  action: ActionObject,
  updated: ActionObject,
  processId?: string,
  isTrueAction?: boolean,
) => ({
  type: WorkflowDetailsType.UPDATE_ACTION,
  payload: {
    action,
    updated,
    processId,
    isTrueAction,
  },
});
