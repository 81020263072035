import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrbotWorkflowFiltersAction } from '../redux/actions/filter_options.action';
import {
  orbotWorkflowFiltersErrorSelector,
  orbotWorkflowFiltersLoadingSelector,
  orbotWorkflowFiltersSelector,
} from '../redux/selectors/filter_options.selectors';

export const useFetchOrbotWorkflowFilters = (orgResourceName?: string) => {
  const dispatch = useDispatch();
  const orbotWorkflowFilters = useSelector(orbotWorkflowFiltersSelector);
  const orbotWorkflowFiltersLoading = useSelector(
    orbotWorkflowFiltersLoadingSelector,
  );
  const orbotWorkflowFiltersError = useSelector(
    orbotWorkflowFiltersErrorSelector,
  );

  useEffect(() => {
    if (orgResourceName) {
      dispatch(getOrbotWorkflowFiltersAction(orgResourceName));
    }
  }, [orgResourceName]);

  return {
    orbotWorkflowFilters,
    orbotWorkflowFiltersLoading,
    orbotWorkflowFiltersError,
  };
};
