import { PersonOutline, KeyboardArrowDown } from '@mui/icons-material';
import {
  Autocomplete,
  TextField,
  InputAdornment,
  outlinedInputClasses,
} from '@mui/material';
import React, { useState } from 'react';
import { User } from 'protos/pb/v1alpha1/user';

interface UserEmailAutocompleteProps {
  onSelect: (user: User) => void;
  users: Array<User>;
}

const UserEmailAutocomplete: React.FC<UserEmailAutocompleteProps> = ({
  onSelect,
  users,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <Autocomplete
      id='user-email-autocomplete'
      sx={{ paddingRight: '16px' }}
      options={users}
      getOptionLabel={(user: User) => user.email!}
      inputValue={inputValue}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'reset') {
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Select team member'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <PersonOutline />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <KeyboardArrowDown />
              </InputAdornment>
            ),
          }}
          sx={{
            [`&& .${outlinedInputClasses.root}`]: { paddingRight: '16px' },
          }}
        />
      )}
      onChange={(_, user) => {
        if (user) {
          onSelect(user);
        }
      }}
    />
  );
};

export default UserEmailAutocomplete;
