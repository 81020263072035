import {
  DeleteWorkflowRequest,
  ListWorkflowsRequest,
  UpdateWorkflowRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import { WorkflowActionType } from './actions.constants';

export const listWorkflowAction = (
  req: ListWorkflowsRequest,
  refresh = false,
) => ({
  type: WorkflowActionType.LIST_WORKFLOWS,
  req,
  refresh,
});

export const listWorkflowCompletedAction = (
  workflows: Workflow[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: WorkflowActionType.LIST_WORKFLOWS_COMPLETED,
  payload: workflows,
  nextPageToken,
  totalSize,
  refresh,
});

export const listWorkflowErrorAction = (error?: Error) => ({
  type: WorkflowActionType.LIST_WORKFLOWS_ERROR,
  payload: error,
});

export const listWorkflowTemplateAction = (
  req: ListWorkflowsRequest,
  refresh = false,
) => ({
  type: WorkflowActionType.LIST_TEMPLATE_WORKFLOWS,
  req,
  refresh,
});

export const listWorkflowTemplateCompletedAction = (
  workflows: Workflow[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: WorkflowActionType.LIST_TEMPLATE_WORKFLOWS_COMPLETED,
  payload: workflows,
  nextPageToken,
  totalSize,
  refresh,
});

export const listWorkflowTemplateErrorAction = (error?: Error) => ({
  type: WorkflowActionType.LIST_TEMPLATE_WORKFLOWS_ERROR,
  payload: error,
});

export const createWorkflowAction = (workflow: Workflow) => ({
  type: WorkflowActionType.CREATE_WORKFLOW,
  payload: workflow,
});

export const createWorkflowCompletedAction = (workflow: Workflow) => ({
  type: WorkflowActionType.CREATE_WORKFLOW_COMPLETED,
  payload: workflow,
});

export const createWorkflowErrorAction = (error: Error | undefined) => ({
  type: WorkflowActionType.CREATE_WORKFLOW_ERROR,
  payload: error,
});

export const updateWorkflowAction = (data: UpdateWorkflowRequest) => ({
  type: WorkflowActionType.UPDATE_WORKFLOW,
  payload: data,
});

export const updateWorkflowCompletedAction = (workflow: Workflow) => ({
  type: WorkflowActionType.UPDATE_WORKFLOW_COMPLETED,
  payload: workflow,
});

export const updateWorkflowErrorAction = (error: Error | undefined) => ({
  type: WorkflowActionType.UPDATE_WORKFLOW_ERROR,
  payload: error,
});

export const deleteWorkflowAction = (payload: DeleteWorkflowRequest) => ({
  type: WorkflowActionType.DELETE_WORKFLOW,
  payload,
});

export const deleteWorkflowCompletedAction = (name: string) => ({
  type: WorkflowActionType.DELETE_WORKFLOW_COMPLETED,
  payload: name,
});

export const deleteWorkflowErrorAction = (error: string) => ({
  type: WorkflowActionType.DELETE_WORKFLOW_ERROR,
  payload: error,
});

export const setSelectedWorkflowAction = (workflow: Workflow | undefined) => ({
  type: WorkflowActionType.SET_SELECTED_WORKFLOW,
  payload: workflow,
});

export const getWorkflowAction = (workflowName: string) => ({
  type: WorkflowActionType.GET_WORKFLOW,
  payload: workflowName,
});

export const getWorkflowCompletedAction = (workflow: Workflow) => ({
  type: WorkflowActionType.GET_WORKFLOW_COMPLETED,
  payload: workflow,
});

export const getWorkflowErrorAction = (error?: string) => ({
  type: WorkflowActionType.GET_WORKFLOW_ERROR,
  payload: error,
});

export const setWorkflowColorAction = (workflows: string[]) => ({
  type: WorkflowActionType.SET_WORKFLOW_COLORS,
  payload: workflows,
});
