import React from 'react';
import { ItemDescription, WidgetContainer } from './Styled';
import KeywordList from './KeywordList';
import FieldList from './FieldList';
import { ClickableTextWithPopover } from './ClickableTextWithPopover';
import { ActionObject } from 'workflow-utils/src/types';

interface IProps {
  action: ActionObject;
  isEditable: boolean;
  processId?: string;
}

const FlagKeywordsDescription: React.FC<IProps> = ({
  action,
  isEditable,
  processId,
}) => {
  const flagKeywordsAction = action?.flagKeywords;
  if (!flagKeywordsAction) {
    return null;
  }

  const { keywords } = flagKeywordsAction;
  const renderKeywordsPopover = (open: boolean, handleClose: () => void) => (
    <KeywordList
      onClose={handleClose}
      actionId={action.id!}
      action={action}
      text={'text'}
      isEditable={isEditable}
      keywords={flagKeywordsAction.keywords!}
      processId={processId}
    />
  );

  const renderFieldPopover = (open: boolean, handleClose: () => void) => (
    <FieldList
      onClose={handleClose}
      actionId={action.id!}
      action={action}
      isEditable={isEditable}
      fields={flagKeywordsAction.fields!}
      processId={processId}
    />
  );
  return (
    <WidgetContainer sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ItemDescription>Flag the</ItemDescription>
      <ClickableTextWithPopover
        isEditable={isEditable}
        text={'form'}
        renderPopover={renderFieldPopover}
      />
      <ItemDescription>
        for any of the following keywords:&nbsp;
      </ItemDescription>
      <ClickableTextWithPopover
        isEditable={isEditable}
        text={getKeywordsDescription(keywords ?? [])}
        renderPopover={renderKeywordsPopover}
      />
    </WidgetContainer>
  );
};

function getKeywordsDescription(keywords: string[]): string {
  if (keywords.length > 0 && keywords.length < 3) {
    return keywords.join(' , ');
  }
  if (keywords.length >= 3) {
    return `${keywords[0]}, ${keywords[1]}, ${keywords[2]} and ${keywords.length - 3} more`;
  }
  return 'No keywords provided';
}

export default FlagKeywordsDescription;
