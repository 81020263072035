import {
  Box,
  Divider,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import WorkflowCreationStep from '../../pages/workflow-creation/WorkflowCreationStep';
import React, { FC, memo, useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import CreateConnectorCondition from '../workflow-creation/step-contents/CreateConnectorCondition';
import {
  AssignmentConfig,
  Connector,
  WorkflowInfo,
} from 'protos/pb/v1alpha2/connector';
import ConnectorGeneralContent from './Steps/ConnectorGeneralContent';
import {
  createConnectorAction,
  createConnectorErrorAction,
} from '../../redux/actions/connector.action';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import {
  createdConnectorSelector,
  processConnectorErrorSelector,
  processingConnectorSelector,
} from '../../redux/selectors/connector.selectors';
import { notification } from 'antd';
import { getCompleteCompositeGroupCondition } from '../../utils/helpers';
import {
  STEP_CONTENT_LAST_STEP_WIDTH,
  STEP_CONTENT_WIDTH,
} from '../../utils/constants';
import { useFetchWorkflowFilters } from '../../hooks/useFetchWorkflowFilters';
import { FormikValues } from 'formik';

enum CreationStep {
  StepOne,
  StepTwo,
}

const STEPS = [
  {
    title: 'Workflow connector',
    background: '#E8F4E3',
    iconColor: '#3BA755',
  },
  {
    title: 'Create condition',
    background: '#E8E8FC',
    iconColor: '#4F52B2',
  },
];

const CustomConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#263244',
    borderLeftWidth: '2.6px',
    minHeight: '40px',
    marginLeft: '10px',
  },
}));

const ConnectorCreationPage: FC = () => {
  const [creationTriggered, setCreationTriggered] = useState(false);
  const [activeStep, setActiveStep] = useState<CreationStep>(
    CreationStep.StepOne,
  );
  const [connector, setConnector] = useState(Connector.create({}));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const connectorError = useSelector(processConnectorErrorSelector);
  const addingConnector = useSelector(processingConnectorSelector);
  const createdConnector = useSelector(createdConnectorSelector);
  const { workflowFilters } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  const [api, contextHolder] = notification.useNotification();

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (connectorError && creationTriggered) {
      setConnector(connector);
      openError(connectorError);
      dispatch(createConnectorErrorAction(undefined));
    }
  }, [connectorError]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (createdConnector && creationTriggered) {
      openSuccess(
        'Connector ' + createdConnector.name + ' is created successfully',
      );
      setConnector(connector);
      setTimeout(() => {
        setActiveStep(0);
        navigate(-1);
      }, 1000);
    }
  }, [createdConnector]);

  const handleSubmitGeneralContent = (values: FormikValues) => {
    // @ts-ignore
    const c: Connector = Connector.create(connector);
    c.displayName = values.connector_name;
    c.description = values.description;
    setConnector(c);
    setActiveStep(activeStep + 1);
  };

  const handleSubmitConnectorCondition = (values: FormikValues) => {
    const c: Connector = connector;

    c.sourceWorkflow = WorkflowInfo.create({
      workflowResourceName: values.source_workflow,
    });
    c.destinationWorkflow = WorkflowInfo.create({
      workflowResourceName: values.destination_workflow,
    });
    c.groupCondition = getCompleteCompositeGroupCondition(
      values.conditionGroups,
    );
    c.orgResourceName = selectedOrgInfo?.orgResourceName;
    c.assignmentConfig = AssignmentConfig.create({
      preserveAssignee: values.preserve_assignee,
    });
    dispatch(createConnectorAction(c));
    setCreationTriggered(true);
  };

  const getStepContent = (index: number) => {
    if (index === CreationStep.StepOne) {
      return (
        <ConnectorGeneralContent
          connector={connector}
          onSubmit={handleSubmitGeneralContent}
        />
      );
    } else if (index === CreationStep.StepTwo) {
      return (
        <CreateConnectorCondition
          workflowFilters={workflowFilters!}
          onSubmit={handleSubmitConnectorCondition}
        />
      );
    }
  };

  return (
    <Box
      bgcolor={'#F6F8FC'}
      paddingX={'50px'}
      paddingTop={'50px'}
      width={'100%'}
    >
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Connector creation
      </CustomTypography>
      <CustomTypography
        sx={{ marginBottom: '40px' }}
        typographyType={TypographyType.MediumPara}
      >
        Imagine connectors as bridges that connect various workflows, based on
        specific conditions.
      </CustomTypography>
      <Stepper
        sx={{
          overflow: 'none',
          marginBottom: '80px',
          '& .MuiStepConnector-root .MuiStepConnector-line': {
            minHeight: '48px',
            borderColor: '#031026',
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {STEPS.map((step, index) => {
          const isLastStep = index === STEPS.length - 1;
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabel
                StepIconComponent={(props: StepIconProps) => (
                  <>
                    <WorkflowCreationStep
                      sx={{}}
                      key={index}
                      {...props}
                      {...step}
                    />
                  </>
                )}
              ></StepLabel>
              <StepContent
                sx={
                  !isLastStep
                    ? {
                        borderLeft: 'none !important',
                        paddingLeft: '0px !important',
                        marginLeft: '0px  !important',
                      }
                    : {
                        borderLeft: 'none !important',
                        marginTop: '30px',
                        paddingLeft: '0px',
                        marginLeft: '50px !important',
                      }
                }
              >
                <Box
                  display={'flex'}
                  width={
                    isLastStep
                      ? STEP_CONTENT_LAST_STEP_WIDTH
                      : STEP_CONTENT_WIDTH
                  }
                  bgcolor={'#FFFFFF'}
                  position={'relative'}
                >
                  {getStepContent(index)}
                  {isLastStep && (
                    <>
                      <Divider
                        orientation='vertical'
                        variant='fullWidth'
                        sx={{
                          borderLeft: '2.6px solid #031026',
                          height: '48px',
                          position: 'absolute',
                          left: '-27.3px',
                          top: '-30px',
                        }}
                      />
                      <Divider
                        sx={{
                          borderTop: '2.6px solid #031026',
                          width: '27.3px',
                          position: 'absolute',
                          left: '-27.3px',
                          top: '18px',
                        }}
                      />
                    </>
                  )}
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Box
        paddingBottom={'20px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '25px',
        }}
      >
        <CustomButton
          ariaLabel='Cancel'
          variant='outlined'
          onClick={() => navigate(-1)}
          disabled={addingConnector}
        >
          Cancel
        </CustomButton>
        {activeStep > 0 && (
          <CustomButton
            ariaLabel='Previous'
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
            variant='outlined'
            disabled={addingConnector}
          >
            Previous
          </CustomButton>
        )}
        <CustomButton
          ariaLabel='Next'
          invisible={activeStep !== 0}
          form={'form1'}
          type='submit'
          disabled={addingConnector}
        >
          Next
        </CustomButton>
        <CustomButton
          ariaLabel='Submit'
          form={'form2'}
          type='submit'
          invisible={activeStep !== 1}
          disabled={addingConnector}
          loading={addingConnector}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default memo(ConnectorCreationPage);
