import { Box, Typography, styled } from '@mui/material';
import { ItemDescription, WidgetContainer } from './Styled';
import React from 'react';
import { OrbyColorPalette } from 'orby-ui/src';
import { ClickableWidgetInfo, ActionObject } from 'workflow-utils/src/types';

export interface ClickableWidgetProps {
  action: ActionObject;
  clickableWidgetInfo: ClickableWidgetInfo;
}

const ClickableText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  fontSize: 18,
  lineHeight: '36px',
  cursor: 'unset',
  borderBottom: `1px solid ${OrbyColorPalette['grey-400']}`,
  color: theme.palette.text.secondary,
}));

const ClickableWidget: React.FC<ClickableWidgetProps> = ({
  clickableWidgetInfo,
}) => {
  const text = clickableWidgetInfo.clickableText || '';
  return (
    <WidgetContainer>
      <ItemDescription>{clickableWidgetInfo.prefix}</ItemDescription>
      <Box>
        <ClickableText>{text}</ClickableText>
      </Box>
      <ItemDescription>{clickableWidgetInfo.suffix}</ItemDescription>
    </WidgetContainer>
  );
};

export default ClickableWidget;
