import { createSelector } from 'reselect';
import { filterOptionsSelector } from './app.selectors';
import { User } from 'protos/pb/v1alpha1/user';

export const workflowFiltersLoadingSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.workflowFiltersLoading,
);

export const workflowFiltersSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.workflowFilters,
);

export const workflowFiltersErrorSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.workflowFiltersError,
);

export const userFiltersLoadingSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.userFiltersLoading,
);

export const userFiltersErrorSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.userFiltersError,
);

export const userFiltersSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.userFilters,
);

export const orbotWorkflowFiltersSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.orbotWorkflowFilters,
);

export const orbotWorkflowFiltersErrorSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.orbotWorkflowFiltersError,
);

export const orbotWorkflowFiltersLoadingSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.orbotWorkflowFiltersLoading,
);

export const userDetailsAsArraySelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => filterOptions.userDetails,
);

export const userDetailsMapSelector = createSelector(
  [filterOptionsSelector],
  (filterOptions) => {
    const userDetailsMap: { [email: string]: User } = {};
    filterOptions.userDetails?.forEach((user) => {
      if (user.email) {
        userDetailsMap[user.email] = user;
      }
    });
    return userDetailsMap;
  },
);
