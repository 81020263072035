import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ELLIPSIS_STYLE } from '../utils/constants';
import CustomTypography, { TypographyType } from './CustomTypography';
interface CustomTableLabelProps {
  label: string;
}
const CustomTableLabel: React.FC<CustomTableLabelProps> = ({ label }) => {
  return (
    <Box display={'flex'} maxWidth={'100%'} alignItems={'center'} gap={1}>
      <CustomTypography
        sx={{ ...ELLIPSIS_STYLE }}
        typographyType={TypographyType.Header5}
        color='#6B6B6B'
      >
        {label}
      </CustomTypography>
    </Box>
  );
};

export default memo(CustomTableLabel);
