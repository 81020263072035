import produce from 'immer';
import { Invoice } from 'protos/pb/demo/invoice_service';
import { Reducer } from 'redux';
import { ERPActionType } from '../actions/actions.constants';
import { toastService } from '../../services/ToastService';

export interface ERPState {
  invoices: Invoice[];
  loadingInvoices: boolean;
  processingInvoice: boolean;
  deletingInvoice: boolean;
  deleteInvoiceError?: string;
  nextPageToken?: string;
  totalSize?: number;
  selectedInvoice?: Invoice;
  listInvoiceError?: string;
}

const initialState: ERPState = {
  invoices: [],
  loadingInvoices: false,
  processingInvoice: false,
  deletingInvoice: false,
};

export const erpReducer: Reducer<ERPState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ERPState = initialState,
  action: any,
) =>
  produce(state, (draft: ERPState) => {
    switch (action.type) {
      case ERPActionType.LIST_INVOICES:
        draft.loadingInvoices = true;
        draft.listInvoiceError = undefined;
        break;
      case ERPActionType.LIST_INVOICES_COMPLETED: {
        draft.invoices = [...action.payload];
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingInvoices = false;
        break;
      }
      case ERPActionType.LIST_INVOICES_ERROR:
        draft.loadingInvoices = false;
        draft.listInvoiceError = action.payload;
        break;
      case ERPActionType.UPDATE_INVOICE:
      case ERPActionType.CREATE_INVOICE:
        draft.processingInvoice = true;
        break;
      case ERPActionType.CREATE_INVOICE_COMPLETED:
      case ERPActionType.UPDATE_INVOICE_COMPLETED: {
        const invoice: Invoice = action.payload;
        const index: number = draft.invoices.findIndex(
          (i) => i.name === invoice.name,
        );
        if (index >= 0) {
          draft.invoices.splice(index, 1, invoice);
          toastService.showSuccess('Invoice Updated Successfully');
        } else {
          draft.invoices.push(invoice);
          toastService.showSuccess('Invoice Added Successfully');
        }
        draft.processingInvoice = false;
        break;
      }
      case ERPActionType.CREATE_INVOICE_ERROR:
      case ERPActionType.UPDATE_INVOICE_ERROR:
        draft.processingInvoice = false;
        break;
      case ERPActionType.SET_SELECTED_INVOICE:
        draft.selectedInvoice = action.payload;
        break;
      case ERPActionType.DELETE_INVOICE:
        draft.deletingInvoice = true;
        break;
      case ERPActionType.DELETE_INVOICE_COMPLETED: {
        const invoiceName = action.payload;
        draft.invoices = draft.invoices.filter((i) => i.name !== invoiceName);
        draft.deletingInvoice = false;
        break;
      }
      case ERPActionType.DELETE_INVOICE_ERROR:
        draft.deletingInvoice = false;
        draft.deleteInvoiceError = action.payload;
        break;
      default:
        break;
    }
  });
