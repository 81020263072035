import { UserOrgRole } from 'protos/pb/v1alpha1/user';

/**
 * Admins can edit any workflow in the organization.
 */
function canEditWorkflow(role: UserOrgRole | undefined) {
  return role === UserOrgRole.ROLE_ADMIN;
}

function canCreateWorkflow(role: UserOrgRole | undefined) {
  return role === UserOrgRole.ROLE_ADMIN;
}

function canDeleteWorkflow(role: UserOrgRole | undefined) {
  return role === UserOrgRole.ROLE_ADMIN;
}

function canFilterUsers(role: UserOrgRole | undefined) {
  return role === UserOrgRole.ROLE_ADMIN;
}

export {
  canEditWorkflow,
  canCreateWorkflow,
  canDeleteWorkflow,
  canFilterUsers,
};
