/**
 * This file is used to render the document on the right side
 * on the Review Page
 */
import { ZoomInRounded, ZoomOutRounded } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  entitySidePanelCollapsedSelector,
  getDocumentActivityTypeSelector,
} from '../../../../redux/selectors/review_task.selectors';
import {
  DEFAULT_REVIEW_PAGE_ZOOM,
  DEFAULT_TABLE_MODAL_WIDTH,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  FLOATING_MODAL_WIDTH,
  PADDING_BW_PDF_PAGES,
  REVIEW_PAGE_MAX_ZOOM,
  REVIEW_PAGE_MIN_ZOOM,
  REVIEW_PAGE_ZOOM_IN_VALUE,
} from '../../../../utils/constants';
import ReviewPageSearchField from './components/ReviewPageSearchField';
import { getUrl } from '../../../../utils/helpers';
import DocumentLayoutOverlay from './components/DocumentLayoutOverlay';
import ReviewPageFloatingModalWrapper from './components/ReviewPageFloatingModalWrapper';
import { Activity } from 'protos/automation_mining/automation_mining';
import ReviewPageTableModal from './components/ReviewPageTableModal/ReviewPageTableModal';
import './reviewPageDocumentLayout.css';

interface Props {
  task: Task;
  documentStep: ExecutionStep;
  sideDrawerCollapse: boolean;
}

const ReviewPageDocumentLayout: React.FC<Props> = ({
  task,
  documentStep,
  sideDrawerCollapse,
}) => {
  const [scale, setScale] = useState(DEFAULT_REVIEW_PAGE_ZOOM);
  const [numPages, setNumPages] = useState(0);
  const [left, setLeft] = useState('0px');
  const pdfRef = useRef<HTMLElement>(null);
  const documentActivityType = useSelector(getDocumentActivityTypeSelector);
  const [tableLeft, setTableLeft] = useState('0px');
  const isSideEntityPanelCollapsed = useSelector(
    entitySidePanelCollapsedSelector,
  );

  // 27vw for review sidebar, 50px for zoom in/out buttons
  // 100vw - 27vw - 50px - 10px
  // 73vw is the view port width for the pdf
  // 50px is the width of the zoom in/out buttons
  // 5px is the box width that is added on the left of pdf
  // 20px is for collapse view
  const sideNavWidth = sideDrawerCollapse
    ? DRAWER_WIDTH_COLLAPSED
    : DRAWER_WIDTH_EXPANDED;

  const completeBoxWidth = isSideEntityPanelCollapsed
    ? `calc(100vw - 50px - 20px - 20px - ${sideNavWidth}px)`
    : `calc(73vw - 50px - 5px - 20px - ${sideNavWidth}px)`;

  const baseDocumentWidth = isSideEntityPanelCollapsed
    ? window.innerWidth
    : window.innerWidth * 0.73;

  const availableWidth = baseDocumentWidth - 10 - 20 - sideNavWidth;

  useEffect(() => {
    if (task) {
      setNumPages(documentStep?.documents?.[0]?.pages?.length as number);
    }
  }, [task]);

  const Page: React.FC<{ page: number }> = ({ page }) => {
    const ref = useRef(null);
    return (
      <Box
        sx={{ width: '100%' }}
        bgcolor={'#F5F5F5'}
        paddingTop={page ? `${PADDING_BW_PDF_PAGES}px` : '0px'}
      >
        <Box ref={ref} position={'relative'} sx={{ width: 'fit-content' }}>
          <Box
            component={'img'}
            alt={task.displayName}
            // WE ARE USING COMPLETE HEIGHT AND WIDTH OF THE IMAGE TO DISPLAY THE DOC SO
            // CALCULATING THE VERTICES BECOMES EASIER, JUST HANDLE THE ZOOM IN/OUT SCALE
            width={
              (documentStep?.documents?.[0]?.pages?.[page]?.image?.width ?? 0) *
                scale +
              'px'
            }
            height={
              (documentStep?.documents?.[0]?.pages?.[page]?.image?.height ??
                0) *
                scale +
              'px'
            }
            src={getUrl(
              documentStep?.documents?.[0]?.pages?.[page]?.image
                ?.content as Uint8Array,
              documentStep?.documents?.[0]?.pages?.[page]?.image
                ?.mimeType as string,
            )}
            sx={{ userSelect: 'none' }}
          />
          <DocumentLayoutOverlay
            currentPage={page}
            scale={scale}
            documentActivityType={documentActivityType as Activity}
            documentStep={documentStep}
          />
        </Box>
      </Box>
    );
  };

  const createPages = useCallback(() => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(<Page key={i} page={i} />);
    }
    return pages;
  }, [numPages, task, scale]);

  const zoomIn = () => {
    if (scale < REVIEW_PAGE_MAX_ZOOM) {
      setScale(Math.round((scale + REVIEW_PAGE_ZOOM_IN_VALUE) * 100) / 100);
    }
  };

  const zoomOut = () => {
    if (scale > REVIEW_PAGE_MIN_ZOOM) {
      setScale(Math.round((scale - REVIEW_PAGE_ZOOM_IN_VALUE) * 100) / 100);
    }
  };

  const getLeftPosition = () => {
    if (pdfRef.current) {
      const width = (pdfRef.current as unknown as HTMLElement).clientWidth;
      const documentWidth =
        (documentStep?.documents?.[0]?.pages?.[0]?.image?.width ?? 0) * scale;
      // It total width of box is greater than pdf and modal combined, we should show modal on right
      // otherwise show it over the pdf
      if (width > documentWidth + FLOATING_MODAL_WIDTH)
        setLeft(`${documentWidth + 10}px`);
      else setLeft(`${width - FLOATING_MODAL_WIDTH}px`);
    } else {
      setLeft(completeBoxWidth);
    }
  };

  const getTableLeftPosition = () => {
    if (pdfRef.current) {
      const width = pdfRef.current.clientWidth;
      const documentWidth =
        (documentStep?.documents?.[0]?.pages?.[0]?.image?.width ?? 0) * scale;
      // It total width of box is greater than pdf and modal combined, we should show table on right
      // otherwise show it over the pdf
      if (width > documentWidth + DEFAULT_TABLE_MODAL_WIDTH) {
        setTableLeft(`${documentWidth + 20}px`);
      } else {
        setTableLeft(`${Math.max(width - DEFAULT_TABLE_MODAL_WIDTH, 0)}px`);
      }
    } else {
      setTableLeft(completeBoxWidth);
    }
  };

  useEffect(() => {
    getTableLeftPosition();
    getLeftPosition();
  }, [pdfRef.current, sideDrawerCollapse]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        getTableLeftPosition();
        getLeftPosition();
      },
      false,
    );
  }, []);

  return (
    <Box display={'flex'} width={'100%'} ref={pdfRef} position={'relative'}>
      <Box
        display={'flex'}
        maxWidth={completeBoxWidth}
        minWidth={completeBoxWidth}
      >
        {/* ADDED THIS EXTRA BOX TO MAKE THE CLICK TO SHIFT B-BOX FUNCTIONALITY WORK
        AS ADDING OVERFLOW TO THE INNER BOX WAS NOT WORKING */}
        {/* We do not pass the width here because the width of each page which is rendered using createPages()
            can be different based on the pdf page size
        */}
        <Box id='pdf-panel-wrapper' overflow={'auto'}>
          <Box id={'pdf-panel'}> {createPages()}</Box>
        </Box>
        <ReviewPageFloatingModalWrapper
          left={left}
          scale={scale}
          pdfRef={pdfRef}
          availableWidth={availableWidth}
        />
        <Box>
          <ReviewPageTableModal
            availableWidth={availableWidth}
            documentWidth={
              (documentStep?.documents?.[0]?.pages?.[0]?.image?.width ?? 0) *
              scale
            }
            left={tableLeft}
            pdfRef={pdfRef}
          />
        </Box>
        <Box position={'absolute'} zIndex={10} top={0} right={10}>
          <ReviewPageSearchField />
        </Box>
      </Box>
      <Box
        padding={1}
        paddingTop={5}
        position={'absolute'}
        top={10}
        right={0}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'start'}
        width={'50px'}
      >
        <Tooltip
          title={scale > REVIEW_PAGE_MIN_ZOOM ? 'Zoom Out' : 'Cannot Zoom Out'}
          placement='right'
          arrow
        >
          <IconButton
            onClick={zoomOut}
            size='small'
            sx={{
              cursor: scale > REVIEW_PAGE_MIN_ZOOM ? 'zoom-out' : 'not-allowed',
            }}
          >
            <ZoomOutRounded sx={{ color: '#000000' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={scale < REVIEW_PAGE_MAX_ZOOM ? 'Zoom In' : 'Cannot Zoom In'}
          placement='right'
          arrow
        >
          <IconButton
            size='small'
            onClick={zoomIn}
            sx={{
              transform: 'scaleX(-1)',
              cursor: scale < REVIEW_PAGE_MAX_ZOOM ? 'zoom-in' : 'not-allowed',
            }}
          >
            <ZoomInRounded sx={{ color: '#000000' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default React.memo(ReviewPageDocumentLayout);
