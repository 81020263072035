import { Chip, Typography, styled } from '@mui/material';
import { RowContainer } from './Styled';
import { OrbyColorPalette } from 'orby-ui/src';
import React from 'react';

const Container = styled(RowContainer)(({ theme }) => ({
  display: 'block',
  width: '100%',
  marginBottom: '10px',
  justifyContent: 'flex-start',
  fontSize: '18px',
  borderRadius: '8px',
  backgroundColor: OrbyColorPalette['green-50'],
  padding: theme.spacing(5),
  wordWrap: 'break-word',
  '& .keywords': {
    color: OrbyColorPalette['grey-900'],
    fontWeight: 700,
    fontStyle: 'italic',
  },
  '& .MuiTypography-root': {
    lineHeight: '25px',
    fontSize: '18px',
  },
}));

const MatchedChip = styled(Chip)(({ theme }) => ({
  color: OrbyColorPalette['green-700'],
  backgroundColor: '#dafbe8',
  padding: theme.spacing(0, 2),
  marginRight: '15px',
  marginTop: '-2px',
}));

interface Props {
  keywords: string[];
}

const FlagKeywordsIdentified: React.FC<Props> = ({ keywords }) => {
  if (keywords.length === 0) {
    return (
      <Container sx={{ backgroundColor: OrbyColorPalette['grey-25'] }}>
        <MatchedChip
          sx={{
            backgroundColor: OrbyColorPalette['grey-100'],
            color: OrbyColorPalette['grey-700'],
          }}
          size='small'
          label='No keyword identified'
        />
      </Container>
    );
  }

  return (
    <Container>
      <MatchedChip
        size='small'
        label={keywords.length + ' keyword identified'}
      />
      <Typography className='keywords' component={'span'}>
        {keywords.join(', ')}
      </Typography>
    </Container>
  );
};

export default FlagKeywordsIdentified;
