import { Box, MenuItem, Select, Typography } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { SchemaEntity } from './ExtractSchemaDefinition';
import CustomTypography from '../../../components/CustomTypography';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import { ELLIPSIS_STYLE } from '../../../utils/constants';
import AddChildEntityField from './components/AddChildEntityField';

interface ChildEntityProps {
  isChildEntity?: boolean;
  schemaEntities?: SchemaEntity[];
}

interface ExtractSelectSchemaFieldProps extends ChildEntityProps {
  entity: SchemaEntity;
  handleAddEntity: (entityString: string) => void;
  handleDeleteEntity: (schemaEntity: SchemaEntity) => void;
  handleUpdateEntityDataType: (
    schemaEntity: SchemaEntity,
    type: EntityDataType,
  ) => void;
  isDefaultEntity: boolean;
  hasError?: boolean;
  isEmailExtraction?: boolean;
}

const dropdownPositionMargins = {
  1: '-9px',
  2: '-23px',
  3: '-67px',
  4: '-75px',
  5: '-16px',
};

const entityDataTypes = [
  {
    label: 'Text',
    value: EntityDataType.ENTITY_TYPE_TEXT,
  },
  {
    label: 'Date',
    value: EntityDataType.ENTITY_TYPE_DATE,
  },
  {
    label: 'Money',
    value: EntityDataType.ENTITY_TYPE_MONEY,
  },
  {
    label: 'Number-float',
    value: EntityDataType.ENTITY_TYPE_FLOAT,
  },
  {
    label: 'Number-integer',
    value: EntityDataType.ENTITY_TYPE_INTEGER,
  },
  {
    label: 'Parent entity',
    value: EntityDataType.ENTITY_TYPE_NESTED,
  },
  {
    label: 'Notes',
    value: EntityDataType.ENTITY_TYPE_ANNOTATION,
  },
];

const getTitleForDataTypes = (type: string) => {
  switch (type) {
    case 'Text':
      return 'Normalize text by removing leading and trailing whitespaces, and replacing continuous whitespaces with a single space. For example, "  Hello  World " will be normalized to "Hello World".';
    case 'Date':
      return 'Normalize date values into ISO 8601 format YYYY-MM-DD.';
    case 'Money':
      return 'Normalize monetary values into currency code in ISO 4217, unit and decimal parts (if applicable). The accepted range is between -9,223,372,036,854,775,808  and 9,223,372,036,854,775,807. Can be used for large numbers out-of-bounds for the Float and Integer data types.';
    case 'Number-float':
      return 'Normalize decimal numbers. Note: This type can handle decimal numbers up to a 7-digit precision level, any digits beyond that will be subject to rounding. For example, 9831209.1 will be rounded to 9831209.0. When more than 7-digit accuracy is needed, use the Money type instead.';
    case 'Number-integer':
      return ' Normalize integers. Note: Supports numbers between -2,147,483,648  and 2,147,483,647. Use the Money data type for out-of-bounds numbers';
    case 'Parent entity':
      return 'Choose this option to define nested entity relationships such as table entities or nested list. For example: the parent entity is ‘Product Category’ and the child entity is ‘Product Name’';
    case 'Notes':
      return 'Choose this option to let reviewers add notes not predicted by Orby AI during task review. The "*" name prefix is reserved and enforced for Notes entities. For example: reviewer can use an entity type called “*summary” to add document summary.';
  }
};

const ExtractSelectSchemaField: FC<ExtractSelectSchemaFieldProps> = (props) => {
  const {
    entity,
    isDefaultEntity,
    schemaEntities,
    isChildEntity,
    handleAddEntity,
    handleDeleteEntity,
    handleUpdateEntityDataType,
    hasError,
    isEmailExtraction,
  } = props;
  const isParent =
    entity.normalizationType === EntityDataType.ENTITY_TYPE_NESTED;

  const [tooltipLabel, setTooltipLabel] = React.useState<string | null>(null);
  const [open, setOpen] = useState(false);

  // GETTING TOP AND LEFT POSITION OF MENU LIST AND RETURNING CALCULATED TOOLTIP POSITION
  const getTooltipPosition = () => {
    const element = document.getElementById('data-types-menu-list');
    const rect = element?.getBoundingClientRect();
    const top = rect?.top;
    const left = (rect?.right as number) + 15; // Add 15 for gap between tooltip and menu.
    return { top, left };
  };

  // RETURNING TOP POSITION OF HOVERED ELEMENT OF MENU LIST
  const getTooltipCaretTop = () => {
    const hoveredElement = document.getElementById(tooltipLabel as string);
    return hoveredElement?.getBoundingClientRect()?.top ?? 0;
  };

  return (
    <Box
      sx={{
        padding: '10px',
      }}
      display={'inline-block'}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          background: '#F6F8FC',
          borderRadius: '50px',
          border: hasError ? '1px solid #C34343' : '1px solid #E2E2E2',
        }}
      >
        <Select
          placeholder='Entity Type'
          labelId='entity-type'
          disabled={isDefaultEntity}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setTooltipLabel(null);
            setOpen(false);
          }}
          MenuProps={{
            MenuListProps: {
              id: 'data-types-menu-list',
            },
            transitionDuration: 0,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            sx: {
              '& .MuiPaper-root': {
                boxShadow: 'none',
                border: '1px solid #E2E2E2',
                marginTop: '2px',
                marginLeft: (
                  dropdownPositionMargins as { [key: number]: string }
                )[entity.normalizationType as EntityDataType],
              },
            },
          }}
          sx={{
            borderRadius: '25px 0px 0px 25px',
            border: '0px solid transparent',
            fontSize: '12px',
            '& .MuiSelect-select': {
              paddingLeft: '16px',
              paddingTop: '8px',
              paddingBottom: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeft: 'none',
              borderTop: 'none',
              borderColor: '#E2E2E2 !important',
              borderBottom: 'none',
            },
          }}
          onChange={(e) => {
            handleUpdateEntityDataType(entity, e.target.value as number);
          }}
          value={entity.normalizationType}
        >
          {entityDataTypes.map((entityDataType) => {
            if (
              entityDataType.label === 'Parent entity' &&
              (isChildEntity || isEmailExtraction)
            ) {
              return;
            }
            return (
              <MenuItem
                id={entityDataType.label}
                key={entityDataType.label}
                onMouseEnter={() => {
                  if (open) {
                    setTooltipLabel(entityDataType.label);
                  }
                }}
                onMouseLeave={() => setTooltipLabel(null)}
                sx={{ fontSize: '12px', paddingLeft: '13px' }}
                value={entityDataType.value}
              >
                {entityDataType.label}
              </MenuItem>
            );
          })}
        </Select>
        {tooltipLabel && (
          <Box
            sx={{
              position: 'fixed',
              top: getTooltipPosition()?.top,
              left: getTooltipPosition()?.left,
              backgroundColor: '#101828',
              borderRadius: '4px',
              padding: '10px',
              minHeight: isChildEntity ? '196px' : '226px',
              maxWidth: '300px',
              zIndex: 1,
            }}
          >
            <ArrowLeft
              fontSize='large'
              sx={{
                color: '#101828',
                position: 'fixed',
                left: getTooltipPosition()?.left - 20,
                top: getTooltipCaretTop() - 3,
              }}
            />
            <CustomTypography weight={600} size='12px' color='#FFFFFF'>
              {tooltipLabel}
            </CustomTypography>
            <CustomTypography
              sx={{ paddingTop: '6px' }}
              size='12px'
              color='#FFFFFF'
            >
              {getTitleForDataTypes(tooltipLabel)}
            </CustomTypography>
          </Box>
        )}
        <Box title={entity.entityName}>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#666666',
              padding: '0px 16px 0px 16px',
              ...ELLIPSIS_STYLE,
              // Set the width to '350px' when it's a parent entity; otherwise, set 'auto' with a maximum of '250px'.
              maxWidth: isParent ? 'auto' : '250px',
              width: isParent ? '350px' : 'auto',
            }}
            aria-live='assertive'
            aria-label={entity.entityName}
          >
            {entity.entityName}
          </Typography>
        </Box>
        {!isDefaultEntity && (
          <Box display={'flex'} sx={{ paddingRight: '16px' }}>
            <CloseIcon
              onClick={() => handleDeleteEntity(entity)}
              sx={{
                cursor: 'pointer',
                fontSize: '12px',
                color: '#5C4AD4',
              }}
            />
          </Box>
        )}
      </Box>
      {isParent && (
        <AddChildEntityField
          parentEntity={entity}
          schemaEntities={schemaEntities as SchemaEntity[]}
          handleAddEntity={handleAddEntity}
          handleDeleteEntity={handleDeleteEntity}
          handleUpdateEntityDataType={handleUpdateEntityDataType}
        />
      )}
    </Box>
  );
};

export default memo(ExtractSelectSchemaField);
