/**
 * This file consists of functions from the workflow-utils/src/helper.ts file
 * We have migrated the data structure - Action group and Prepared Action to the new Action
 */
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { getActionById } from './workflow';
import {
  containConditionAction,
  containControlFlow,
  containForeachAction,
} from './action-classifier';
import { ActionObject, ExecutedActionObject, ExecutionInfo } from '../types';
import { getParamValue } from '../helper';

export function getNumStepsForAction(action: Action): number {
  let numSteps = 1; // the ActionGroup itself always counts
  if (!action || !containControlFlow(action)) {
    return numSteps;
  }

  if (action.foreach) {
    numSteps += getNumStepsForActions(action?.foreach?.loopActions);
  } else if (action.condition) {
    numSteps +=
      getNumStepsForActions(action.condition.thenActions) +
      getNumStepsForActions(action.condition.elseActions);
  }

  return numSteps;
}

export function getNumStepsForActions(actions: Action[] | undefined): number {
  return (actions || [])
    .map(getNumStepsForAction)
    .reduce((acc, val) => acc + val, 0);
}

export function getActionAfter(
  action: Action,
  actions: Action[],
  found: boolean = false,
): Action | undefined {
  for (const actionItem of actions) {
    if (actionItem.condition) {
      const trueBranch = getActionAfter(
        action,
        actionItem.condition.thenActions ?? [],
        found,
      );
      if (trueBranch) {
        return trueBranch;
      }
      found ||=
        getActionById(action.id!, actionItem.condition.thenActions ?? []) !==
        undefined;
      const falseBranch = getActionAfter(
        action,
        actionItem.condition.elseActions ?? [],
        found,
      );
      if (falseBranch) {
        return falseBranch;
      }
      found ||=
        getActionById(action.id!, actionItem.condition.elseActions ?? []) !==
        undefined;
    } else if (actionItem.foreach) {
      const loop = getActionAfter(
        action,
        actionItem.foreach.loopActions ?? [],
        found,
      );
      if (loop) {
        return loop;
      }
      found ||=
        getActionById(action.id!, actionItem.foreach.loopActions ?? []) !==
        undefined;
    } else if (found) {
      return actionItem;
    } else if (actionItem.id === action.id) {
      found = true;
    }
  }

  return undefined;
}

export function getTaskIdFromExecutedActions(
  action: Action = {},
  executedActions: ExecutedActionObject[] = [],
): string {
  if (!action || !action.createTask) {
    throw Error(`The action ${action?.id} is not a createTask action`);
  }
  const uuid = action?.id;
  const executedAction = executedActions?.find(
    (a) => a.preparedActionUuid === uuid,
  );
  return JSON.parse(executedAction?.outputValue || '""');
}

export function calcForEachExecutions(
  action: ActionObject,
  nextAction: ActionObject | undefined,
  executedActions: ExecutedActionObject[],
): ExecutionInfo[] {
  if (!action.foreach || !action.foreach.loopActions) return [];
  if (action.foreach.loopActions?.length === 0) return [];
  const uuid = action.id;
  const nextUuid = nextAction?.id;
  const firstForeachActionUuid = action?.foreach?.loopActions?.[0]?.id;
  const executions = [];
  let i = 0;
  while (
    i < executedActions.length &&
    executedActions[i].preparedActionUuid !== uuid
  ) {
    i++;
  }
  let lastExecutionIndex = -1;
  while (i < executedActions.length) {
    if (executedActions[i].preparedActionUuid === nextUuid) {
      break;
    }
    if (executedActions[i].preparedActionUuid === firstForeachActionUuid) {
      if (lastExecutionIndex !== -1) {
        executions.push({
          startIndex: lastExecutionIndex,
          endIndex: i,
          numSteps: numOfDirectStepsInForeachAction(
            action,
            executedActions.slice(lastExecutionIndex, i),
          ),
        });
      }
      lastExecutionIndex = i;
    }
    i++;
  }
  if (lastExecutionIndex !== -1) {
    executions.push({
      startIndex: lastExecutionIndex,
      endIndex: i,
      numSteps: numOfDirectStepsInForeachAction(
        action,
        executedActions.slice(lastExecutionIndex, i),
      ),
    });
  }
  return executions;
}

export function numOfDirectStepsInForeachAction(
  action: ActionObject,
  slicedExecutedActions: ExecutedActionObject[],
): number {
  if (!action.foreach || !action.foreach.loopActions) return -1;
  if (action.foreach.loopActions?.length === 0) return 0;
  const foreachActions = action.foreach.loopActions!;
  let numSteps = 0;
  foreachActions.forEach((a) => {
    if (containForeachAction(a)) {
      numSteps += 1;
    } else if (containConditionAction(a)) {
      numSteps += numOfStepsInConditionAction(a!, slicedExecutedActions) + 1;
    } else {
      numSteps += 1;
    }
  });
  return numSteps;
}

export function numOfStepsInConditionAction(
  action: ActionObject,
  slicedExecutedActions: ExecutedActionObject[],
): number {
  if (!action.condition) return -1;
  const trueActionGroups = action.condition.thenActions;
  const falseActionGroups = action.condition.elseActions;
  const conditionResult = getConditionResultFromExecutedActions(
    action!,
    slicedExecutedActions,
  );

  let actions: ActionObject[] | undefined;
  if (conditionResult) {
    actions = trueActionGroups;
  } else {
    actions = falseActionGroups;
  }

  let numSteps = 0;
  actions?.forEach((a) => {
    if (containConditionAction(a)) {
      const conditionAction = actions.find((a) => a.condition);
      // We don't need to slice executedActions because nested condition won't cause duplicated executions.
      numSteps += numOfStepsInConditionAction(
        conditionAction!,
        slicedExecutedActions,
      );
    }

    // We don't go inside foreach.
    // We need to count condition itself as one step.
    // Every other actionGroup is counted as one step.
    numSteps += 1;
  });
  return numSteps;
}

export function getConditionResultFromExecutedActions(
  action: ActionObject,
  executedActions: ExecutedActionObject[],
): boolean {
  if (!action.condition) {
    throw new Error(`The action ${action.id} is not a condition action`);
  }
  const uuid = action?.id;
  const conditionExecutedAction = executedActions.find(
    (a) => a.preparedActionUuid === uuid,
  );

  const args = conditionExecutedAction?.paramValues!.map((p) => JSON.parse(p));

  if (!args || args.length === 0) {
    if (!action.condition.condition) {
      throw new Error(
        `Condition result is null. Please check the action ${action.id}`,
      );
    }
    const value = getParamValue(action.condition.condition, executedActions);
    // consider empty object and array to be false
    return (
      value &&
      (!Array.isArray(value) || value.length > 0) &&
      (typeof value !== 'object' || Object.keys(value).length > 0)
    );
  }
  // arg could be null, we treat it as false
  // e.g
  // getElement could return undefined, undefined will be serialized to null
  return !!args[0];
}
