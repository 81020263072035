import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { StatsCard } from '../../components/Card/Card';
import { notification } from 'antd';
import './Dashboard.css';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import { msToTime } from '../../utils/helpers';
import { getDashboardStatsErrorAction } from '../../redux/actions/dashboard.action';
import {
  statsErrorSelector,
  taskStatusSelector,
  taskSummarySelector,
} from '../../redux/selectors/dashboard.selectors';
import SummaryBox from './components/SummaryBox';
import DashboardHeader from './components/DashboardHeader';
import { OrbyTypography } from 'orby-ui/src';

const Dashboard: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const taskStatus = useSelector(taskStatusSelector);
  const taskSummary = useSelector(taskSummarySelector);
  const statsError = useSelector(statsErrorSelector);

  useEffect(() => {
    if (statsError) {
      openError(statsError);
      dispatch(getDashboardStatsErrorAction());
    }
  }, [statsError]);

  const openError = (error: Error) => {
    api.info({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
    });
  };

  return (
    <Box
      height={'100vh'}
      overflow={'hidden'}
      bgcolor={'#F7F9FC'}
      padding={'60px'}
      sx={{ overflow: 'auto' }}
    >
      <Box width={'100%'}>
        {contextHolder}
        <DashboardHeader />
        <OrbyTypography weight='semibold' size='xl' sx={{ marginTop: '98px' }}>
          Overview
        </OrbyTypography>
        <Box
          marginTop={'24px'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <StatsCard
            title={'Tasks completed'}
            value={taskStatus?.completedTasksCount ?? '0'}
          />
          <StatsCard
            title={'Total hours saved'}
            value={
              taskSummary?.totalHoursSaved && taskSummary.totalHoursSaved > 0
                ? msToTime(taskSummary.totalHoursSaved / 1000000, false)
                : taskStatus?.completedTasksCount &&
                    taskStatus.completedTasksCount > 0
                  ? '0'
                  : '-'
            }
          />
          <StatsCard
            title={'Orby’s accuracy'}
            value={
              taskSummary?.totalAccuracy && taskSummary.totalAccuracy > 0
                ? `${(taskSummary.totalAccuracy * 100).toFixed(1)}%`
                : taskStatus?.completedTasksCount &&
                    taskStatus.completedTasksCount > 0
                  ? '0'
                  : '-'
            }
          />
        </Box>
        <OrbyTypography weight='semibold' size='xl' sx={{ marginTop: '52px' }}>
          Task Summary
        </OrbyTypography>
        <Box
          marginTop={'24px'}
          padding={'28px'}
          border={'1px solid #F4EBFF'}
          height='212px'
          bgcolor={'#FFFFFF'}
          borderRadius={'12px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={'12px'} alignItems={'baseline'}>
            <OrbyTypography weight='semibold' size='display-xs'>
              {taskStatus
                ? (taskStatus?.totalTaskCount ?? 0) +
                  (taskStatus?.createdTasksCount ?? 0)
                : '0'}
            </OrbyTypography>
            <OrbyTypography weight='medium'>Total Tasks</OrbyTypography>
          </Box>
          <Box
            paddingX='62px'
            paddingY='23px'
            display={'flex'}
            justifyContent={'space-between'}
          >
            <SummaryBox count={taskStatus?.createdTasksCount} title='Created' />
            <SummaryBox count={taskStatus?.pendingTasksCount} title='Pending' />
            <SummaryBox
              count={taskStatus?.completedTasksCount}
              title='Completed'
            />
            <SummaryBox
              count={taskStatus?.declinedTasksCount}
              title='Declined'
            />
            <SummaryBox
              count={taskStatus?.errorTasksCount}
              title='Error'
              showBorder={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
