import { Box, TextField } from '@mui/material';
import CustomTypography from '../../../../../../../components/CustomTypography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedEntityIdAction,
  updateTaskEntityInfoAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  allowEditingTaskSelector,
  getSelectedReviewFilterSectionSelector,
  infoToDetectChangesSelector,
  selectedEntityIdSelector,
  selectedEntityInfoSelector,
  selectedTaskEntityInfoSelector,
  suggestionAnnotationTaskEntityInfoSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import {
  goToVisibleElement,
  isEntityNeedAttention,
  isEntityPredicted,
  shiftToNextEntity,
} from '../../../../../../../utils/ReviewTaskUtils';
import UserCheck from '../../../../../../../static/icons/user-check.svg';
import { Done } from '@mui/icons-material';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { EntityFilter } from '../../../../../../../utils/constants';
import { checkForUnsavedChanges } from '../../../../../../../utils/UnsavedChangesUtils';

interface Props {
  entity: EntityInfo;
  task: Task;
  disabled?: boolean;
}

const EntitiesInfoBox: React.FC<Props> = ({ entity, task, disabled }) => {
  const dispatch = useDispatch();
  const selectedEntityId = useSelector(selectedEntityIdSelector);
  const suggestionAnnotationTaskEntityInfo = useSelector(
    suggestionAnnotationTaskEntityInfoSelector,
  );

  // this state contains all info necessary to detect unsaved changes
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);
  const selectedEntityInfo = useSelector(selectedEntityInfoSelector);
  const entitiesInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const [extraText, setExtraText] = React.useState(
    entity.extraEntityText ?? '',
  );
  const [isValueFocused, setIsValueFocused] = React.useState(false);

  const getBackgroundColor = () => {
    if (disabled) {
      return '#f5fbf7';
    }
    if (selectedEntityId === entity.id) {
      return 'rgb(255, 255, 255)';
    } else if (entity.isReviewed) {
      return '#F6FEF9';
    } else if (isEntityPredicted(entity)) {
      return 'rgb(246, 248, 252)';
    }
    return '#FCF6F7';
  };

  const getBorderColor = () => {
    if (disabled) {
      return '2px solid transparent';
    }
    if (selectedEntityId === entity.id) {
      if (isEntityNeedAttention(entity)) {
        return '2px solid #B15843';
      }
      return '2px solid rgb(22, 105, 247)';
    } else {
      if (task.status !== TaskSTATUS.READY) {
        return '2px solid #dddbda';
      }
      return '2px solid transparent';
    }
  };

  // SINCE WE ARE STORING UPDATE INFO OF SELECTED ENTITY IN selectedEntityInfo,
  // IT HAS TO BE SEPARATELY USED TO GET UPDATED VALUE ON MOVING BOX
  const getTextForEntity = () => {
    if (entity.id !== selectedEntityId) {
      return entity.entityText;
    }
    return (selectedEntityInfo as EntityInfo).entityText;
  };

  const handleEntityClick = () => {
    // If disabled, prevent click behaviour
    if (disabled) {
      return;
    }
    // check if floating modal is open and have unsaved changes if yes show warning
    // block if unsaved changes are present
    if (checkForUnsavedChanges(infoToDetectChanges)) {
      return;
    }
    if (entity.isExtra) {
      setIsValueFocused(true);
    }
    if (entity.id === selectedEntityId) {
      goToVisibleElement('bounding-box');
    } else {
      dispatch(setSelectedEntityIdAction(entity.id));
    }
  };

  const handleUpdateNotesEntity = (selectNextEntity = true) => {
    if (extraText != null) {
      const newEntityInfo = {
        ...entity,
        isReviewed: true,
        isModified: true,
        extraEntityText: extraText,
      };
      dispatch(updateTaskEntityInfoAction(entity.id, newEntityInfo));
      // Select next entity in sidebar list
      if (selectNextEntity) {
        shiftToNextEntity(
          newEntityInfo,
          entitiesInfoMap,
          selectedReviewFilterSection as EntityFilter,
        );
      }
    }
    setIsValueFocused(false);
  };

  // SCROLLING TO THE SELECTED ENTITY INFO BOX HERE
  useEffect(() => {
    if (selectedEntityId === entity.id) {
      if (entity.isExtra) {
        setIsValueFocused(true);
      }
      const element = document.getElementById(`entity-${entity.id}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [selectedEntityId]);

  return (
    <>
      <Box
        id={`entity-${entity.id}`}
        onClick={handleEntityClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleEntityClick();
          }
        }}
        paddingTop={'10px'}
      >
        <Box
          tabIndex={0}
          id={`entity-box-${entity.id}`} // this id is used get this element and focus on this element in EntitiesInfo.tsx
          width={'100%'}
          padding={'8px 12px'}
          display={'flex'}
          position={'relative'}
          borderRadius={'4px'}
          minHeight={'60px'}
          sx={{
            ':focus': {
              border: '2px solid rgb(22, 105, 247)',
            },
            backgroundColor: getBackgroundColor(),
            cursor: entity.id !== selectedEntityId ? 'pointer' : 'auto',
            border: getBorderColor(),
          }}
        >
          <Box width={'100%'}>
            <Box display={'block'} marginBottom={'12px'}>
              <CustomTypography
                weight={400}
                lineHeight='16px'
                size='12px'
                color={disabled ? '#878891' : 'rgb(84, 84, 84)'}
                sx={{
                  overflowWrap: 'break-word',
                }}
              >
                {entity.type}
              </CustomTypography>
            </Box>
            <Box width={'100%'} display={'block'} gap={'3px'}>
              {entity.isExtra ? (
                <>
                  {!isValueFocused || !allowEditingTask ? (
                    <CustomTypography
                      weight={500}
                      lineHeight='18px'
                      color='#222222'
                    >
                      {extraText}
                    </CustomTypography>
                  ) : (
                    <TextField
                      sx={{
                        width: '100%',
                        padding: 0,
                        paddingRight: '15px',
                        '& .MuiInput-root:after': {
                          borderBottom: getBorderColor(),
                        },
                      }}
                      variant='standard'
                      autoFocus
                      value={extraText}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleUpdateNotesEntity();
                        }
                        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyY') {
                          e.preventDefault();
                          document.execCommand('redo');
                        }
                      }}
                      onChange={(event) => {
                        setExtraText(event.target.value);
                      }}
                      onBlur={() => {
                        // If any smart annotation is suggested to user, it draws the focus to itself
                        // leading to onBlur and updation of notes entity if the immediate next entity
                        // is notes entity. To prevent this, we check whether or not any suggestion is
                        // being displayed to user
                        if (!suggestionAnnotationTaskEntityInfo) {
                          handleUpdateNotesEntity(false);
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <CustomTypography
                  weight={500}
                  lineHeight='18px'
                  size='14px'
                  sx={{
                    paddingRight: '10px', //  To make sure the text doesn't overflow on the icon
                  }}
                  color={disabled ? '#939392' : 'rgb(34, 34, 34)'}
                >
                  {getTextForEntity()}
                </CustomTypography>
              )}
            </Box>
          </Box>
          {/* Check if entity is reviewed with modifications*/}
          {entity.isReviewed && entity.isModified && (
            <Box
              sx={{ opacity: disabled ? 0.6 : 1 }}
              title='This means a human has reviewed and modified this entity'
              position={'absolute'}
              right={5}
              bottom={5}
              component={'img'}
              src={UserCheck}
            />
          )}
          {/* Check if entity is reviewed without modifications*/}
          {entity.isReviewed && !entity.isModified && (
            <Box
              title='This means Orby predicted originally and a human confirmed'
              position={'absolute'}
              right={5}
              bottom={5}
            >
              <Done
                fontSize={'small'}
                sx={{ color: disabled ? '#97b6a8' : '#054F31' }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(EntitiesInfoBox);
