import React, { FC, memo, useCallback } from 'react';
import { EntityInfo } from '../../../../../../../../../redux/reducers/review_task.reducer';
import { useDispatch } from 'react-redux';
import { copySelectedEntity } from '../../../../../../../../../redux/actions/review_task.action';
import CustomTableCell from '../../../../../../../../../components/CustomTableCell';
import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import CustomTypography from '../../../../../../../../../components/CustomTypography';

interface Props {
  allowEditingTask: boolean;
  isMoreThanOneRowPresent: boolean;
  rowIndex: number;
  selectedParentEntityInfoId?: string;
  rowId: string;
  setAction: React.Dispatch<
    React.SetStateAction<{
      cell?: EntityInfo;
      event?: Event;
      type: string;
    } | null>
  >;
}
const RowMenuCell: FC<Props> = ({
  allowEditingTask,
  isMoreThanOneRowPresent,
  rowIndex,
  selectedParentEntityInfoId,
  rowId,
  setAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleInsertRow = useCallback(
    (position: 'up' | 'down', rowOrderIndex: number) => {
      if (selectedParentEntityInfoId) {
        const indexToAddRow =
          position === 'up' ? rowOrderIndex : rowOrderIndex + 1;
        dispatch(
          copySelectedEntity(
            selectedParentEntityInfoId,
            false,
            false,
            indexToAddRow,
          ),
        );
      }
    },
    [selectedParentEntityInfoId],
  );

  const handleInsertAbove = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      handleInsertRow('up', rowIndex);
      handleMenuClose(e);
    },
    [rowIndex, handleInsertRow],
  );

  const handleInsertBelow = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      handleInsertRow('down', rowIndex);
      handleMenuClose(e);
    },
    [rowIndex, handleInsertRow],
  );

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <CustomTableCell
      ellipsis={false}
      sx={{
        border: 'none',
        width: '34px',
        paddingY: '3px',
        paddingX: '5px',
      }}
    >
      {allowEditingTask && (
        <IconButton
          id={`cell-${rowId}-menu-button`}
          onClick={handleMenuClick}
          sx={{ width: '24px', height: '24px' }}
        >
          <MoreVert
            sx={{ width: '18px', color: OrbyColorPalette['black-0'] }}
          />
        </IconButton>
      )}
      {openMenu && (
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={{
            '& .MuiPopover-paper': {
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          {isMoreThanOneRowPresent && (
            <MenuItem
              onClick={(e) => {
                setAction({ type: 'ROW_DELETE' });
                handleMenuClose(e);
              }}
            >
              <OrbyTypography sx={{ color: OrbyColorPalette['black-0'] }}>
                Delete
              </OrbyTypography>
            </MenuItem>
          )}
          <MenuItem onClick={handleInsertAbove}>
            <CustomTypography sx={{ color: '#000000' }}>
              Insert above
            </CustomTypography>
          </MenuItem>
          <MenuItem onClick={handleInsertBelow}>
            <CustomTypography sx={{ color: '#000000' }}>
              Insert below
            </CustomTypography>
          </MenuItem>
        </Menu>
      )}
    </CustomTableCell>
  );
};

export default memo(RowMenuCell);
