import React, { useEffect, useState } from 'react';
import HITLTaskOverview from './components/HITLTaskOverview';
import { OrbyColorPalette } from 'orby-ui/src';
import { Box } from '@mui/material';
import HITLExtractedDataReview from './components/HITLExtractedDataReview';
import HITLSystemReference from './components/HITLSystemReference';
import { ReactComponent as ExpandIcon } from '../../static/icons/expand.svg';
import { GetTaskRequest } from 'protos/pb/v1alpha2/tasks_service';
import { getTaskForHitlAction } from '../../redux/actions/review_task.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  HitlDataSelector,
  HitlLoadingSelector,
} from '../../redux/selectors/review_task.selectors';

interface Props {
  sideDrawerCollapse: boolean;
}

// Remove the below once we have the data from api
const invoiceDetails = {
  issueList: [
    {
      label: 'Workflow: Invoice reconciliation',
      color: '#F2F4F7',
      fontColor: OrbyColorPalette['grey-700'],
    },
    // {
    //   label: 'Issue: 1 unmatched invoice item in PO',
    //   color: '#FFEAD5',
    //   fontColor: OrbyColorPalette['orange-700'],
    // },
  ],
  executionStartTime: 'May 4, 2024  06:09 PST',
  executionEndTime: '',
  timePause: '3 Hours',
  creator: 'Jane Doe',
  creatorEmail: 'jd@orby.ai',
  workflowName: 'Workflow: Invoice reconciliation',
};

const Overlay: React.FC<{
  isExpanded: boolean;
  background: keyof typeof OrbyColorPalette;
}> = ({ isExpanded, background }) => (
  <Box
    sx={{
      opacity: isExpanded ? '50%' : '100%',
      background: isExpanded ? OrbyColorPalette[background] : 'unset',
      width: '100%',
      height: '100%',
      zIndex: 999,
      position: 'absolute',
    }}
  />
);

const ExpandIconButton: React.FC<{
  onClick: () => void;
  isExpanded: boolean;
  position: 'top' | 'bottom';
}> = ({ onClick, isExpanded, position }) => {
  // Function to determine the rotation based on position and isExpanded
  const getTransform = (isExpanded: boolean, position: 'top' | 'bottom') => {
    if (position === 'bottom' && isExpanded) return 'rotate(0deg)';
    if (position === 'bottom' && !isExpanded) return 'rotate(180deg)';
    if (position === 'top' && isExpanded) return 'rotate(180deg)';
    if (position === 'top' && !isExpanded) return 'rotate(0deg)';
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        [position === 'top' ? 'top' : 'bottom']: '4%',
        left: '-18px',
        transform: getTransform(isExpanded, position),
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <ExpandIcon />
    </Box>
  );
};

const Divider: React.FC = () => (
  <Box
    sx={{
      position: 'relative',
      borderLeft: '1px solid',
      borderColor: OrbyColorPalette['grey-300'],
      height: '100%',
    }}
  />
);

const HITLDetails: React.FC<Props> = () => {
  const [isLeftSideExpanded, setIsLeftSideExpanded] = useState(false);
  const [isRightSideExpanded, setIsRightSideExpanded] = useState(false);
  const loading: boolean = useSelector(HitlLoadingSelector);
  const hitlData = useSelector(HitlDataSelector);
  // const error = useSelector(HitlErrorSelector);  //will use once we have the api response

  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setIsLeftSideExpanded((prev) => !prev);
    setIsRightSideExpanded(false);
  };

  const handleRightExpandClick = () => {
    setIsRightSideExpanded((prev) => !prev);
    setIsLeftSideExpanded(false);
  };

  useEffect(() => {
    const req: GetTaskRequest = GetTaskRequest.fromJSON({
      name: '66db16b503b7724a5c42e0e0.pdf',
    }); //append taskName here
    dispatch(getTaskForHitlAction(req));
  }, []);

  return (
    !loading && (
      <>
        {/* Header Or Overview Zone */}
        <HITLTaskOverview
          title={hitlData?.task?.name || 'Invoice #1234'}
          workflowName={
            hitlData?.task?.workflowDisplayName || invoiceDetails?.workflowName
          }
          data={invoiceDetails}
        />

        <Box
          display='flex'
          flexDirection='row'
          height='calc(100% - 170px)'
          position='relative'
        >
          {/* Left Panel or ExtractedDataReviewZone */}
          <Box
            sx={{
              width: isLeftSideExpanded ? '90%' : '60%',
              transition: 'width 0.2s ease',
              background: 'white',
              height: '100%',
              zIndex: isLeftSideExpanded ? 2 : 0,
              position: 'absolute',
            }}
          >
            {isRightSideExpanded && (
              <Overlay isExpanded={isRightSideExpanded} background='grey-900' />
            )}
            <HITLExtractedDataReview isExpanded={isLeftSideExpanded} />
            {/* data={hitlData?.smartAction} */}
          </Box>

          {/* Partition and Expand/Collapse Icons */}
          <Box
            sx={{
              position: 'absolute',
              left: isLeftSideExpanded
                ? '90%'
                : isRightSideExpanded
                  ? '10%'
                  : '61%',
              height: '100%',
              zIndex: 10,
              transition: 'left 0.3s ease, transform 0.3s ease',
              transform:
                isLeftSideExpanded || isRightSideExpanded
                  ? 'none'
                  : 'translateX(0)',
            }}
          >
            <Divider />
            {!isRightSideExpanded && (
              <ExpandIconButton
                onClick={handleExpandClick}
                isExpanded={isLeftSideExpanded}
                position='top'
              />
            )}
            {!isLeftSideExpanded && (
              <ExpandIconButton
                onClick={handleRightExpandClick}
                isExpanded={isRightSideExpanded}
                position='bottom'
              />
            )}
          </Box>

          {/* Right Panel or SystemReferenceZone */}
          <Box
            sx={{
              width: isRightSideExpanded ? '90%' : '39%',
              transition: 'width 0.3s ease',
              background: 'white',
              zIndex: isRightSideExpanded ? 2 : 0,
              height: '100%',
              position: 'absolute',
              right: 0,
            }}
          >
            {isLeftSideExpanded && (
              <Overlay isExpanded={isLeftSideExpanded} background='grey-900' />
            )}
            <HITLSystemReference />
            {/* data={hitlData?.smartAction} */}
          </Box>
        </Box>
      </>
    )
  );
};

export default React.memo(HITLDetails);
