import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, ChipProps, styled, svgIconClasses } from '@mui/material';
import { WorkflowTaskStatus } from 'protos/pb/v1alpha1/orbot_workflow';

import { chipClasses } from '@mui/material/Chip';
import { OrbyColorPalette } from 'orby-ui/src';
import React from 'react';
import { getStatusText } from 'workflow-utils/src/helper';

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '22px',
  lineHeight: '22px',
  [`& .${chipClasses.label}`]: {
    paddingRight: theme.spacing(2),
  },
}));

const StyledErrorChip = styled(StyledChip)(() => ({
  backgroundColor: OrbyColorPalette['error-50'],
  color: OrbyColorPalette['error-700'],
  [`& .${svgIconClasses.root}`]: {
    color: OrbyColorPalette['error-700'],
  },
}));

const StyledSuccessChip = styled(StyledChip)(() => ({
  backgroundColor: OrbyColorPalette['green-50'],
  color: OrbyColorPalette['green-700'],
}));

const StyledExecutingChip = styled(StyledChip)(() => ({
  color: OrbyColorPalette['indigo-700'],
  backgroundColor: OrbyColorPalette['indigo-50'],
}));

const StyledPendingChip = styled(StyledChip)(() => ({
  color: OrbyColorPalette['purple-700'],
  backgroundColor: OrbyColorPalette['purple-50'],
}));

const StyledWaitingForReviewChip = styled(StyledChip)(() => ({
  color: OrbyColorPalette['warning-700'],
  backgroundColor: OrbyColorPalette['warning-50'],
}));

const StyledTerminatedChip = styled(StyledChip)(() => ({
  color: OrbyColorPalette['error-700'],
  backgroundColor: OrbyColorPalette['error-50'],
}));

const StyledInfoChip = styled(StyledChip)(() => ({
  color: OrbyColorPalette['grey-700'],
  backgroundColor: OrbyColorPalette['grey-100'],
}));

const StyledScreenChip = styled(StyledChip)(() => ({
  backgroundColor: '#F4F3FF',
  color: '#5925DC',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#DAD8F7',
    color: '#3D1F8C',
  },
  [`& .${svgIconClasses.root}`]: {
    color: OrbyColorPalette['purple-600'],
  },
}));

interface IProps extends ChipProps {
  label: string;
}

interface IScreenChipProps extends IProps {
  label: string;
  isOpen: boolean;
}

export const ScreenChip = ({ label, isOpen, ...props }: IScreenChipProps) => {
  return (
    <StyledScreenChip
      icon={
        isOpen ? (
          <KeyboardArrowUpIcon sx={{ width: 12, height: 12 }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ width: 12, height: 12 }} />
        )
      }
      label={label}
      {...props}
    />
  );
};

export const ErrorChip: React.FC<IProps> = ({ label, ...props }: IProps) => {
  return <StyledErrorChip label={label} {...props} />;
};

export const SuccessChip: React.FC<IProps> = ({ label, ...props }: IProps) => {
  return <StyledSuccessChip label={label} {...props} />;
};

export const ExecutingChip: React.FC<IProps> = ({
  label,
  ...props
}: IProps) => {
  return <StyledExecutingChip label={label} {...props} />;
};

export const PendingChip: React.FC<IProps> = ({ label, ...props }: IProps) => {
  return <StyledPendingChip label={label} {...props} />;
};

export const WaitingForReviewChip: React.FC<IProps> = ({
  label,
  ...props
}: IProps) => {
  return <StyledWaitingForReviewChip label={label} {...props} />;
};

export const TerminatedChip: React.FC<IProps> = ({
  label,
  ...props
}: IProps) => {
  return <StyledTerminatedChip label={label} {...props} />;
};

export const InfoChip: React.FC<IProps> = ({ label, ...props }: IProps) => {
  return <StyledInfoChip label={label} {...props} />;
};

interface IStatusChipProps {
  status: WorkflowTaskStatus | undefined;
}

export const WorkflowStatusChip = ({ status, ...props }: IStatusChipProps) => {
  const text = getStatusText(status);
  switch (status) {
    case WorkflowTaskStatus.SUCCESS:
      return <SuccessChip label={text} {...props} />;
    case WorkflowTaskStatus.FAIL:
      return <ErrorChip label={text} {...props} />;
    case WorkflowTaskStatus.EXECUTING:
      return <ExecutingChip label={text} {...props} />;
    case WorkflowTaskStatus.PENDING:
      return <PendingChip label={text} {...props} />;
    case WorkflowTaskStatus.TERMINATED:
      return <TerminatedChip label={text} {...props} />;
    case WorkflowTaskStatus.WAITING_FOR_REVIEW:
      return <WaitingForReviewChip label={text} {...props} />;
    default:
      return <Chip label={text} {...props} />;
  }
};
