import { Box, Button } from '@mui/material';
import React from 'react';
import CustomTypography from './CustomTypography';

interface Props {
  type: 'error' | 'success';
  content: React.ReactNode;
  buttonTitle?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const CustomToastContent: React.FC<Props> = ({
  type,
  content,
  buttonTitle,
  onClick,
  icon,
}) => {
  return (
    <Box>
      <CustomTypography
        sx={{
          textTransform: 'capitalize',
          fontFamily: 'inter',
          fontSize: '18px',
          fontWeight: '600',
          color: type === 'error' ? '#C92A1D' : '#448D6A',
        }}
      >
        {type}
      </CustomTypography>
      {content}
      {buttonTitle && (
        <Button
          startIcon={icon ? icon : undefined}
          onClick={onClick}
          variant='outlined'
          sx={{
            mt: '16px',
            borderColor: '#D0D5DD',
            flexGrow: 1,
            borderRadius: '40px',
            backgroundColor: 'white',
            color: '#344054',
            marginRight: '5px',
            textTransform: 'capitalize',
            '&:hover': {
              opacity: 0.8,
              backgroundColor: '#fff',
              borderColor: '#D0D5DD',
            },
          }}
        >
          <CustomTypography sx={{ fontWeight: 600 }}>
            {buttonTitle}
          </CustomTypography>
        </Button>
      )}
    </Box>
  );
};

export default React.memo(CustomToastContent);
