import { Box, Button, Stack, Tooltip } from '@mui/material';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface TaskSelectionHeaderProps {
  selectedItems: Array<any>;
  onCancel: () => void;
  primaryLabel: string;
  secondaryLabel: string;
  tertiaryLabel?: string;
  onPrimaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSecondaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTertiaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  primaryLabelDisabled?: boolean;
  primaryLabelDisabledTooltip?: string;
  secondaryLabelDisabled?: boolean;
  secondaryLabelDisabledTooltip?: string;
  tertiaryLabelDisabled?: boolean;
  tertiaryLabelDisabledTooltip?: string;
}

const PermissionErrorTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip arrow placement='top' {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 220,
  },
}));

const TaskSelectionHeader: React.FC<TaskSelectionHeaderProps> = ({
  selectedItems,
  onCancel,
  onPrimaryClick,
  onSecondaryClick,
  onTertiaryClick,
  primaryLabel,
  secondaryLabel,
  tertiaryLabel,
  primaryLabelDisabled,
  primaryLabelDisabledTooltip,
  secondaryLabelDisabled,
  secondaryLabelDisabledTooltip,
  tertiaryLabelDisabled,
  tertiaryLabelDisabledTooltip,
}) => {
  const isItemsSelected = useMemo(
    () => selectedItems.length > 0,
    [selectedItems],
  );
  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderRadius={'4px'}
    >
      <Stack direction='row' alignItems={'center'} marginLeft={'12px'}>
        {isItemsSelected && (
          <>
            <OrbyTypography
              size='md'
              weight='semibold'
              color={OrbyColorPalette['grey-900']}
            >
              {selectedItems.length}
            </OrbyTypography>
            <OrbyTypography
              sx={{ marginLeft: '8px' }}
              size='md'
              weight='regular'
              color={OrbyColorPalette['grey-900']}
            >
              selected on this page
            </OrbyTypography>
            <Button
              variant='text'
              sx={{ marginLeft: '16px' }}
              onClick={onCancel}
            >
              <OrbyTypography
                size='sm'
                weight='semibold'
                color={OrbyColorPalette['blue-700']}
              >
                Cancel
              </OrbyTypography>
            </Button>
          </>
        )}
      </Stack>

      {isItemsSelected && (
        <Stack spacing={2} direction='row' marginRight={'8px'}>
          {tertiaryLabel && (
            <PermissionErrorTooltip
              title={tertiaryLabelDisabled && tertiaryLabelDisabledTooltip}
            >
              <span>
                <OrbyButton
                  onClick={onTertiaryClick}
                  size='small'
                  variant='primary-outline'
                  ariaLabel={tertiaryLabel}
                  label={tertiaryLabel}
                  disabled={tertiaryLabelDisabled}
                />
              </span>
            </PermissionErrorTooltip>
          )}

          {secondaryLabel && (
            <PermissionErrorTooltip
              title={secondaryLabelDisabled && secondaryLabelDisabledTooltip}
            >
              <span>
                <OrbyButton
                  onClick={onSecondaryClick}
                  size='small'
                  variant='primary-outline'
                  ariaLabel={secondaryLabel}
                  label={secondaryLabel}
                  disabled={secondaryLabelDisabled}
                />
              </span>
            </PermissionErrorTooltip>
          )}

          {primaryLabel && (
            <PermissionErrorTooltip
              title={primaryLabelDisabled && primaryLabelDisabledTooltip}
            >
              <span>
                <OrbyButton
                  onClick={onPrimaryClick}
                  size='small'
                  variant='primary-contained'
                  ariaLabel={primaryLabel}
                  label={primaryLabel}
                  disabled={primaryLabelDisabled}
                />
              </span>
            </PermissionErrorTooltip>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default React.memo(TaskSelectionHeader);
