import { Box, Button, Chip, Stack } from '@mui/material';
import CustomTypography from '../../../../../components/CustomTypography';
import { isFeatureFlagEnabled } from '../../../../FeatureFlags/FeatureFlagUtils';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EntityInfo } from '../../../../../redux/reducers/review_task.reducer';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../../../../utils/constants';
import { getPercentValue } from '../../../../../utils/helpers';
import {
  closeFloatingModal,
  getFloatingModalLabel,
  isEntityNeedAttention,
} from '../../../../../utils/ReviewTaskUtils';

interface FloatingModalChipsProps {
  entity: EntityInfo;
  task: Task;
}

const FloatingModalChips: React.FC<FloatingModalChipsProps> = ({
  entity,
  task,
}) => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );
  const isNeedAttention = useMemo(
    () => isEntityNeedAttention(entity),
    [entity],
  );
  const attentionLabel = useMemo(() => getFloatingModalLabel(entity), [entity]);

  const getConfidenceLabel = useCallback(() => {
    const percent = `${
      task.status === TaskSTATUS.COMPLETED
        ? '100'
        : getPercentValue(entity?.confidenceScore ?? 0)
    }%`;
    if (isNeedAttention) {
      return `Low confidence: ${percent}`;
    }
    return `High confidence: ${percent}`;
  }, [task, entity, isNeedAttention]);

  return (
    <Box
      bgcolor={'white'}
      padding={'24px 24px 0px 24px'}
      display={'flex'}
      alignItems={attentionLabel ? 'flex-start' : 'center'}
      justifyContent={'space-between'}
    >
      {entity.parentEntityId && entity.parentEntityType && showNewNestedUI ? (
        <Button
          onClick={() => closeFloatingModal(entity)}
          variant='outlined'
          sx={{
            width: '62px',
            borderRadius: '40px',
            border: '1px solid #D0D5DD',
            marginRight: '16px',
            marginTop: attentionLabel ? '-5px' : '0px',
          }}
        >
          <CustomTypography weight={600} color={'#344054'}>
            Back
          </CustomTypography>
        </Button>
      ) : (
        <span />
      )}
      <Stack
        direction='row'
        flexWrap={'wrap'}
        justifyContent={'flex-end'}
        gap={2}
      >
        {attentionLabel && (
          <Chip
            size='small'
            aria-label='reason of needing attention'
            label={attentionLabel}
            sx={{
              backgroundColor: '#DD6A6A',
              color: 'white',
              fontSize: '12px',
            }}
          />
        )}

        <Chip
          size='small'
          aria-label='Confidence Score'
          label={getConfidenceLabel()}
          sx={{
            backgroundColor: isNeedAttention ? '#FFF856' : '#A6F4C5',
            fontSize: '12px',
          }}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(FloatingModalChips);
