import React from 'react';
import { Link } from 'react-router-dom';
import { ItemDescription, WidgetContainer } from './Styled';

import { getTaskIdFromExecutedActions } from 'workflow-utils/src/v2/helper';
import { removeElementAnnotation } from 'workflow-utils/src/helper';

import { useSelector } from 'react-redux';
import { linkedTasksSelector } from '../../../../../../redux/selectors/workflow_task.selectors';
import { ActionObject, ExecutedActionObject } from 'workflow-utils/src/types';

interface IProps {
  action: ActionObject;
  executedActions: ExecutedActionObject[];
}

/**
 * executedActions are sliced actions, which only contains the branch in one forloop execution.
 */
const LinkedTask: React.FC<IProps> = ({ action, executedActions }) => {
  const description = removeElementAnnotation(action.description || '');

  // Getting the linked tasks for this workflow from redux
  const linkedTasks = useSelector(linkedTasksSelector) || [];

  const taskId = getTaskIdFromExecutedActions(action, executedActions);
  const workflowName = linkedTasks.find((task) => task?.task?.id === taskId)
    ?.workflow?.displayName;

  return (
    <WidgetContainer>
      <ItemDescription>
        {description}:{' '}
        <Link to={`/executions/${taskId}`} replace>
          {workflowName}
        </Link>
      </ItemDescription>
    </WidgetContainer>
  );
};

export default LinkedTask;
