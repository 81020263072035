import React, { FC, memo, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  CircularProgress,
  Grid,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { getLogoByName } from './helper';
import {
  checkActions,
  checkTriggers,
  getApplicationNames,
  isAdmin,
} from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWorkflowAction,
  listWorkflowAction,
  listWorkflowErrorAction,
  setSelectedWorkflowAction,
  updateWorkflowAction,
} from '../../redux/actions/workflow.action';
import {
  listWorkflowErrorSelector,
  processWorkflowErrorSelector,
  updatedWorkflowSelector,
  workflowListSelector,
  workflowTemplatesListSelector,
  workflowsLoadingSelector,
  workflowsTotalSizeSelector,
} from '../../redux/selectors/workflow.selectors';
import {
  UpdateWorkflowRequest,
  DeleteWorkflowRequest,
  ListWorkflowsRequest,
  Workflow,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';
import WorkflowContextMenu from './WorkflowContextMenu';
import { useNavigate } from 'react-router-dom';
import TemplateWorkflowTable from './TemplateWorkflowTable';

import CustomPagination from '../../components/Pagination/CustomPagination';
import { notification } from 'antd';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import ConnectorsTable from './ConnectorsTable';
import { ELLIPSIS_STYLE, ROWS_PER_PAGE } from '../../utils/constants';
import TaskCopyModal from './TaskCopyModal/TaskCopyModal';
import TaskCopySuccessConfirmation from './TaskCopyModal/TaskCopySuccessConfirmation';
import { ApplicationName } from '../../utils/protos/enums';
import jsyaml from 'js-yaml';
import UploadYamlComponent from './UploadYaml/UploadYaml';
import { parseWorkflowProtoToJson } from '../../utils/jsonToYamlParser';
import {
  createSftpClassificationWorkflowObject,
  createSftpExtractionWorkflowObject,
} from '../../utils/yamlToJsonParser';
import UploadIcon from '../../static/icons/upload_icon.svg';
import { validateYamlWorkflow } from '../../utils/yamlParserValidation';
import CustomTableCell from '../../components/CustomTableCell';
import WorkflowDeletionModal from '../../pages/workflow-creation/WorkflowDeletionModal';
import {
  getCreationDetails,
  getIdFromResourceName,
} from '../../utils/WorkflowUtils';
import '../../styles/table.css';
import { User } from 'protos/pb/v1alpha1/user';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../utils/constants';
import ShareWorkflowModal from './ShareWorkflowModal/ShareWorkflowModal';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FAFAFA',
  },
  '& td, & th': {
    border: 0,
  },
}));

const WorkflowTable: FC = () => {
  const dispatch = useDispatch();
  const [isYamlModalOpen, setIsYamlModalOpen] = useState({
    open: false,
    isEdit: false,
  });
  const [yamlFileError, setYamlFileError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isShareWorkflowModalOpen, setIsShareWorkflowModalOpen] =
    React.useState(false);
  const [showTaskCopyConfirmModal, setShowTaskCopyConfirmModal] =
    React.useState(false);
  const [selectedWorkflow, setSelectedWorkflow] =
    React.useState<Workflow | null>(null);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const workflows: Workflow[] = useSelector(workflowListSelector);
  const workflowsTemplates: Workflow[] = useSelector(
    workflowTemplatesListSelector,
  );
  const loading = useSelector(workflowsLoadingSelector);
  const totalSize = useSelector(workflowsTotalSizeSelector) ?? 0;
  const [workflowPage, setWorkflowPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [updationTriggered, setUpdationTriggered] = useState(false);
  const [deletionTriggered, setDeletionTriggered] = useState(false);
  const listWorkflowError = useSelector(listWorkflowErrorSelector);
  const [api, contextHolder] = notification.useNotification();
  const updatedWorkflow = useSelector(updatedWorkflowSelector);
  const workflowError = useSelector(processWorkflowErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const user = useSelector(loggedInUserSelector);
  const userPermissions = useSelector(userPermissionsSelector);

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);

  const canCreateWorkflow =
    isAdminView || (isRbacEnabled && userPermissions.createWorkflow);
  const canListWorkflows =
    isAdminView || (isRbacEnabled && userPermissions.listWorkflows);
  const canListConnectors =
    isAdminView || (isRbacEnabled && userPermissions.listConnectors);

  const pagedNonTempWorkflows = workflows.slice(
    workflowPage * rowsPerPage,
    (workflowPage + 1) * rowsPerPage,
  );
  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };
  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };
  useEffect(() => {
    if (listWorkflowError) {
      openError(listWorkflowError);
      dispatch(listWorkflowErrorAction());
    }
  }, [listWorkflowError]);
  useEffect(() => {
    if (updatedWorkflow && (updationTriggered || deletionTriggered)) {
      if (updationTriggered) {
        openSuccess('Workflow ' + '' + ' is updated successfully');
      } else {
        openSuccess('Workflow ' + '' + ' is deleted successfully');
      }
      // TODO: change this logic when batch deletion is supported
      if (totalSize > workflows.length) {
        const req = buildRequest();
        req.pageNumber = workflows.length + 1;
        req.pageSize = 1;
        dispatch(listWorkflowAction(req));
      }
    }
  }, [updatedWorkflow]);

  useEffect(() => {
    if (workflowError && (updationTriggered || deletionTriggered)) {
      // revert back the name since its not saved successfully
      openError(workflowError);
    }
  }, [workflowError]);

  useEffect(() => {
    setWorkflowPage(0);
    const req = buildRequest();
    // ADDIND TRUE TO REFRESH THE LIST INSTEAD OF APPENDING WORKFLOWS
    dispatch(listWorkflowAction(req, true));
    setUpdationTriggered(false);
    setDeletionTriggered(false);
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (workflowError) {
      openError(workflowError);
    }
  }, [workflowError]);

  const buildRequest = () => {
    const req: ListWorkflowsRequest = {};
    req.filter = composeFilters();
    req.pageSize = rowsPerPage;
    if (selectedOrgInfo?.orgResourceName) {
      req.orgResourceName = selectedOrgInfo?.orgResourceName;
    }
    return req;
  };

  const composeFilters = (): string => {
    let status = 'is_template=false';

    if (!isAdminView) {
      // if user is not org admin but is workflow admin for some workflows,
      // add this filter to only show workflows that user is workflow admin of
      if (isRbacEnabled && userPermissions.listWorkflows) {
        status += ',is_workflow_admin=true';
      }
      return (status += ',status=status_enabled');
    }
    return status;
  };

  // const handleDisableSwitch = (checked: boolean, name: string) => {
  //   const updatedWorkflow = Workflow.deserialize(pagedNonTempWorkflows.find((w) => w.name === name).serialize())
  //   updatedWorkflow.status = checked ? WorkflowStatus.STATUS_ENABLED : WorkflowStatus.STATUS_DISABLED
  //   dispatch(updateWorkflowAction(updatedWorkflow))
  // }

  const isSFTPToJsonWorkflow = (workflow: Workflow) => {
    const sftpIndex = checkTriggers(workflow, [ApplicationName.SftpServer]);
    const generateOutputIndex = checkActions(workflow, [
      ApplicationName.GenerateOutput,
      ApplicationName.EntityExtraction,
      ApplicationName.DocumentClassification,
    ]);
    if (sftpIndex && generateOutputIndex) {
      return true;
    }
  };

  const handleYamlEditWorkflow = (jsonData: any) => {
    let parsedWorkflow = Workflow.create(selectedWorkflow!);
    // Check if the workflow template name in the YAML file matches the selected workflow template name
    // If the template name doesn't match, then the user is trying to edit a different workflow
    if (
      jsonData.templateResourceName != selectedWorkflow?.templateResourceName
    ) {
      setYamlFileError("Workflow template doesn't match");
      return;
    }
    // Check if the workflow display name in the YAML file matches the selected workflow display name
    if (jsonData?.displayName != selectedWorkflow?.displayName) {
      setYamlFileError("Workflow display name doesn't match");
      return;
    }
    const isSftpTrigger = checkTriggers(selectedWorkflow as Workflow, [
      ApplicationName.SftpServer,
    ]);
    const isClassificationGenerateOutputAction = checkActions(
      selectedWorkflow as Workflow,
      [ApplicationName.DocumentClassification, ApplicationName.GenerateOutput],
    );
    const isExtractionGenerateOutputAction = checkActions(
      selectedWorkflow as Workflow,
      [ApplicationName.EntityExtraction, ApplicationName.GenerateOutput],
    );
    // Check if the workflow is a classification workflow or an extraction workflow
    if (
      isSftpTrigger &&
      (isClassificationGenerateOutputAction || isExtractionGenerateOutputAction)
    ) {
      try {
        if (isClassificationGenerateOutputAction) {
          parsedWorkflow = createSftpClassificationWorkflowObject(
            parsedWorkflow,
            jsonData,
            user as User,
          );
        } else {
          parsedWorkflow = createSftpExtractionWorkflowObject(
            parsedWorkflow,
            jsonData,
            user as User,
          );
        }
      } catch (error: any) {
        setYamlFileError(`${error.message}`);
        return;
      }
      // Navigate to the update workflow page with the parsed workflow
      dispatch(setSelectedWorkflowAction(parsedWorkflow as Workflow));
      const id = getIdFromResourceName(parsedWorkflow?.name as string);
      if (id) {
        navigate(`/workflow/${id}/update`, {
          state: { isYamlWorkflow: true },
        });
      } else {
        openError(Error('Something went wrong'));
      }
    } else {
      // Handle the case where the YAML file is invalid
      setYamlFileError('Invalid YAML file');
      return;
    }
  };

  const handleYamlCreateWorkflow = (jsonData: any) => {
    const templateResourceName = jsonData.templateResourceName;
    // Find the workflow template that matches the template resource name
    // If the workflow template is not found, then the YAML file is invalid since user doesn't have access to the workflow template
    const workflowTemplate = workflowsTemplates.find(
      (workflowTemplate) => workflowTemplate.name === templateResourceName,
    );
    if (!workflowTemplate) {
      setYamlFileError('Workflow template not found');
      return;
    }

    let parsedWorkflow = Workflow.create(workflowTemplate);

    const isSftpTrigger = checkTriggers(workflowTemplate, [
      ApplicationName.SftpServer,
    ]);
    const isClassificationGenerateOutputAction = checkActions(
      workflowTemplate,
      [ApplicationName.DocumentClassification, ApplicationName.GenerateOutput],
    );
    const isExtractionGenerateOutputAction = checkActions(workflowTemplate, [
      ApplicationName.EntityExtraction,
      ApplicationName.GenerateOutput,
    ]);
    // Check if the workflow is a classification workflow or an extraction workflow
    if (
      isSftpTrigger &&
      (isClassificationGenerateOutputAction || isExtractionGenerateOutputAction)
    ) {
      if (isClassificationGenerateOutputAction) {
        parsedWorkflow = createSftpClassificationWorkflowObject(
          parsedWorkflow,
          jsonData,
          user as User,
          true,
        );
      } else {
        parsedWorkflow = createSftpExtractionWorkflowObject(
          parsedWorkflow,
          jsonData,
          user as User,
          true,
        );
      }
      // Navigate to the create workflow page with the parsed workflow
      navigate('/workflow/create', {
        state: { workflow: parsedWorkflow, isYamlWorkflow: true },
      });
    } else {
      // Handle the case where the YAML file is invalid
      setYamlFileError('Invalid YAML file');
      return;
    }
  };

  // Handle file selection
  const handleYamlFileChange = (file: File | undefined) => {
    setYamlFileError(null);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const yamlContent = e.target?.result as string;
          // Convert the yaml content to JSON
          const jsonData = jsyaml.load(yamlContent);
          if (jsonData) {
            /**
             * VALIDATIONS FOR YAML FILE
             */
            validateYamlWorkflow(jsonData);
            /**
             * Handle workflow creation or modification
             */
            if (selectedWorkflow && isYamlModalOpen.isEdit) {
              handleYamlEditWorkflow(jsonData);
            } else {
              handleYamlCreateWorkflow(jsonData);
            }
          } else {
            // Handle the case where the YAML file is invalid
            setYamlFileError('Invalid YAML file');
            return;
          }
        } catch (error: any) {
          // Handle any errors that occur during parsing
          setYamlFileError(error.message || 'Error parsing YAML');
          return;
        }
      };
      reader.readAsText(file);
    } else {
      setYamlFileError('File is required.');
      return;
    }
  };

  /**
   * Download the workflow as a YAML file
   */
  const downloadYamlFile = (workflow: Workflow) => {
    const yamlString = parseWorkflowProtoToJson(workflow);
    const element = document.createElement('a');
    const file = new Blob([yamlString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = workflow.displayName + '.yaml';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  };

  const isWorkflowAdmin = (workflow: Workflow, user: User | undefined) => {
    if (!user || !user.email) {
      return false;
    }
    return (
      workflow.creatorEmail === user.email ||
      workflow.admins?.includes(user.email)
    );
  };

  const renderMenu = (d: Workflow, i: number) => {
    return (
      (isAdminView || (isRbacEnabled && isWorkflowAdmin(d, user))) && (
        <>
          {/* Currently update status of workflow not required */}
          {/* <CustomTableCell >
      <Stack direction="row" spacing={1} alignItems="center">
        <CustomTypography
          weight={500}
          typographyType={TypographyType.Header5}
        >
          Off
        </CustomTypography>
        <IOSSwitch
          checked={d.status === WorkflowStatus.STATUS_ENABLED}
          onChange={(e) => {
            handleDisableSwitch(e.target.checked, d.name)
          }}
        />
        <CustomTypography
          weight={500}
          typographyType={TypographyType.Header5}
        >
          On
        </CustomTypography>
      </Stack>
    </CustomTableCell> */}
          <WorkflowContextMenu
            id={(d.name as string) + i}
            onUpdate={() => {
              const id = getIdFromResourceName(d.name as string);
              if (id) {
                navigate(`/workflow/${id}/update`);
              } else {
                openError(Error('Something went wrong'));
              }
            }}
            openCopyModel={() => {
              setIsModalOpen(true);
              setSelectedWorkflow(d);
            }}
            onDelete={() => {
              setIsDeletionModalOpen(true);
              setSelectedWorkflow(d);
            }}
            isShowCopyTo={true}
            downloadYaml={() => {
              downloadYamlFile(d);
            }}
            openYamlModal={() => {
              setSelectedWorkflow(d);
              setIsYamlModalOpen({
                open: true,
                isEdit: true,
              });
            }}
            isShowYamlConfig={isSFTPToJsonWorkflow(d)} // We only want to show yaml config for sftp workflows
            isShowShareWorkflow={isRbacEnabled}
            openShareWorkflow={() => {
              setSelectedWorkflow(d);
              setIsShareWorkflowModalOpen(true);
            }}
          />
        </>
      )
    );
  };

  const handleDelete = (deleteReason: string) => {
    const req: DeleteWorkflowRequest = {
      name: selectedWorkflow?.name,
      deletedReason: deleteReason,
    };
    dispatch(deleteWorkflowAction(req));
    setDeletionTriggered(true);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
    setSelectedWorkflow(null);
  };

  const handleShareWorkflow = (
    workflowAdmins: string[],
    adminEmailMessage: string,
  ) => {
    const req: UpdateWorkflowRequest = {};
    req.workflow = { ...selectedWorkflow! };
    req.workflow.admins = workflowAdmins;
    req.workflow.sendAdminEmail = true;
    req.workflow.adminEmailMessage = adminEmailMessage;
    req.fieldMask = ['admins'];
    dispatch(updateWorkflowAction(req));
    setIsShareWorkflowModalOpen(false);
    setUpdationTriggered(true);
    setSelectedWorkflow(null);
  };

  return (
    <Box
      bgcolor={'white'}
      height={'100%'}
      paddingX={'60px'}
      paddingTop={'60px'}
    >
      {contextHolder}
      {canCreateWorkflow && <TemplateWorkflowTable />}
      <Grid
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid>
          <CustomTypography
            component={'h1'}
            typographyType={TypographyType.Header2}
          >
            {isAdminView ? 'Your Organization’s Workflow' : 'Your Workflows'}
          </CustomTypography>

          <CustomTypography
            sx={{ marginTop: '20px', marginBottom: '20px' }}
            typographyType={TypographyType.MediumPara}
          >
            {canListWorkflows
              ? 'You and other admins have configured the following workflows'
              : 'You will be assigned tasks to review'}
          </CustomTypography>
        </Grid>
        {canCreateWorkflow && (
          <CustomTypography
            onClick={() =>
              setIsYamlModalOpen({
                open: true,
                isEdit: false,
              })
            }
            sx={{
              cursor: 'pointer',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              color: '#1570EF',
            }}
          >
            <img
              alt='Upload from'
              style={{ height: '18px', paddingRight: '4px' }}
              src={UploadIcon}
            />{' '}
            Upload
          </CustomTypography>
        )}
      </Grid>
      {/* <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <CustomSearchField placeHolder="Search..." />
          <CustomFilterSelect
            color="#B6B6B6"
            filters={[
              { value: '1', label: 'Example Filter' },
              { value: '2', label: 'Example Filter2' },
            ]}
            value={'1'}
          />
          <IconButton sx={{ marginTop: '10px', color: '#545454' }}>
            <FilterList />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'20px'}
          marginTop={'10px'}
        >
          <Box display={'flex'} alignItems={'center'} gap={'4px'}>
            <CustomTypography color="#6B6B6B">Last Modified</CustomTypography>
            <IconButton sx={{ height: '0px', width: '0px' }}>
              <ImportExportOutlined />
            </IconButton>
          </Box>
          <IconButton sx={{ height: '24px', width: '24px' }}>
            <GridViewOutlined />
          </IconButton>
          <IconButton sx={{ height: '24px', width: '24px' }}>
            <FormatListBulleted />
          </IconButton>
        </Box>
      </Box> */}
      {/* <TextField
        size={'small'}
        variant="outlined"
        autoComplete="search"
        type="search"
        placeholder="Search..."
        onChange={() => {
          // setWorkflowNameFilter(event.target.value)
          // setWorkflowPage(0)
        }}
        InputProps={{
          startAdornment: (
            <Box>
              <InputAdornment position="start">
                <IconButton sx={{ width: '10px' }}>
                  <GridSearchIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            </Box>
          ),
        }}
      /> */}
      {loading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          height={'20%'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          sx={{ marginTop: '30px' }}
          className={'table-header-style'}
        >
          <Table id={'workflows-table'} sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                <CustomTableCell title={'Workflow'}>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Workflow
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title={'Description'}>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Description
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title={'Apps involved'}>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Apps involved
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title={'Created by'}>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Created by
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title={'Workflow mode'}>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Workflow mode
                  </CustomTypography>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedNonTempWorkflows.map((d, i) => (
                <StyledTableRow
                  key={d.name}
                  role='row'
                  tabIndex={0}
                  sx={{
                    cursor: 'pointer',
                    ':hover': { backgroundColor: '#1669F74D' },
                    ':focus': { backgroundColor: '#1669F74D' },
                  }}
                >
                  <CustomTableCell title={d.displayName}>
                    <CustomTypography
                      component={'span'}
                      lineHeight='18px'
                      color='#000000'
                      typographyType={TypographyType.Header5}
                    >
                      {d.displayName}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell title={d.description}>
                    <CustomTypography
                      component={'span'}
                      color='#6B6B6B'
                      sx={{ ariaHidden: 'true' }}
                    >
                      {d.description}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell ellipsis={false}>
                    <Box display={'flex'} flexWrap='wrap'>
                      {getApplicationNames(d).map((app: string) => {
                        const logo = getLogoByName(app);
                        if (logo) {
                          return (
                            <Box key={logo} marginRight={'10px'}>
                              <img src={logo} title={app} alt={app} />
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell title={getCreationDetails(d)}>
                    <CustomTypography component={'span'} color='#6B6B6B'>
                      {d.creatorEmail}
                    </CustomTypography>
                  </CustomTableCell>
                  {d.mode !== WorkflowMode.UNSPECIFIED ? (
                    <CustomTableCell
                      ellipsis={false}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Box
                          title={WorkflowMode[d.mode as WorkflowMode]}
                          sx={{
                            paddingX: '10px',
                            paddingY: '5px',
                            backgroundColor:
                              d.mode === WorkflowMode.DEFAULT
                                ? '#6CE95066'
                                : '#178CFC66',
                            borderRadius: '4px',
                          }}
                        >
                          <CustomTypography
                            sx={{ ...ELLIPSIS_STYLE }}
                            typographyType={TypographyType.Label}
                          >
                            {WorkflowMode[d.mode as WorkflowMode]}
                          </CustomTypography>
                        </Box>
                        {d.reviewerLists && d.reviewerLists.length > 1 && (
                          <Box
                            title={d.reviewerLists.length + ' Review rounds'}
                            sx={{
                              paddingX: '10px',
                              paddingY: '5px',
                              backgroundColor: '#E8E8FC',
                              borderRadius: '4px',
                            }}
                          >
                            <CustomTypography
                              sx={{ ...ELLIPSIS_STYLE }}
                              typographyType={TypographyType.Label}
                            >
                              {d.reviewerLists.length} Review rounds
                            </CustomTypography>
                          </Box>
                        )}
                      </Box>
                      {renderMenu(d, i)}
                    </CustomTableCell>
                  ) : (
                    <CustomTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        width={'106px'}
                        height={'11px'}
                        borderRadius={'15px'}
                        bgcolor={'#E4E5E7'}
                      ></Box>
                      {renderMenu(d, i)}
                    </CustomTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {!pagedNonTempWorkflows.length && (
            <CustomTypography
              sx={{
                textAlign: 'center',
                textJustify: 'center',
                color: '#475467',
                marginTop: '40px',
              }}
            >
              There are no organization workflows available
            </CustomTypography>
          )}
        </TableContainer>
      )}
      <CustomPagination
        ariaLabel={'Please select workflow rows per page.'}
        rowsPerPage={rowsPerPage}
        totalSize={totalSize}
        page={workflowPage}
        rows={ROWS_PER_PAGE}
        justifyContent={'end'}
        onRowsPerPageChange={(rows) => {
          setWorkflowPage(0);
          const req = buildRequest();
          req.pageSize = rows as number;
          // Refresh is needed when rows per page is changes to fetch fresh data
          dispatch(listWorkflowAction(req, true /* Refresh */));
          setRowsPerPage(rows as number);
        }}
        isShowSelectFilter={totalSize > rowsPerPage}
        onPageChange={(p) => {
          setWorkflowPage(p);
          if (p >= workflowPage && workflows.length <= p * rowsPerPage) {
            const req = buildRequest();
            req.pageNumber = p + 1;
            req.pageSize = rowsPerPage;
            dispatch(listWorkflowAction(req));
          }
        }}
      />
      <WorkflowDeletionModal
        open={isDeletionModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleDelete}
      />
      {canListConnectors && <ConnectorsTable />}
      {selectedWorkflow && (
        <TaskCopyModal
          open={isModalOpen}
          setOpen={() => {
            setIsModalOpen(false);
            setSelectedWorkflow(null);
          }}
          workflow={selectedWorkflow}
          setShowTaskCopyConfirmModal={setShowTaskCopyConfirmModal}
        />
      )}
      <TaskCopySuccessConfirmation
        showTaskCopyConfirmModal={showTaskCopyConfirmModal}
        setShowTaskCopyConfirmModal={setShowTaskCopyConfirmModal}
      />
      <UploadYamlComponent
        yamlFileError={yamlFileError}
        handleFileChange={handleYamlFileChange}
        open={isYamlModalOpen.open}
        isEdit={isYamlModalOpen.isEdit}
        setYamlFileError={setYamlFileError}
        setOpen={(open) => {
          setIsYamlModalOpen({
            open: open,
            isEdit: false,
          });
          setYamlFileError(null);
        }}
      />
      {selectedWorkflow && (
        <ShareWorkflowModal
          open={isShareWorkflowModalOpen}
          setOpen={() => {
            setIsShareWorkflowModalOpen(false);
            setSelectedWorkflow(null);
          }}
          workflow={selectedWorkflow}
          onSubmit={handleShareWorkflow}
        />
      )}
    </Box>
  );
};

export default memo(WorkflowTable);
