import { Box, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../../../components/CustomTypography';
import React, { useState } from 'react';
import {
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import EntitiesInfoBox from './EntitiesInfoBox';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  copySelectedEntityOldUIAction,
  deleteSelectedEntityOldUIAction,
} from '../../../../../../../redux/actions/review_task.action';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import ControlPointDuplicate from '@mui/icons-material/ControlPointDuplicate';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';

interface Props {
  entities: EntityInfo[];
  nestedType: string;
  parentEntityId: string;
  task: Task;
  disabled?: boolean;
  allowDeletion: boolean;
}

const NestedEntitiesInfoBox: React.FC<Props> = ({
  nestedType,
  entities,
  parentEntityId,
  task,
  disabled,
  allowDeletion,
}) => {
  const [showNestedEntities, setShowNestedEntities] = useState(true);
  const allowEditingTask = useSelector(allowEditingTaskSelector);

  const dispatch = useDispatch();
  // This is used to show an incomplete label if the nested entities are not reviewed and text is not present and task should be in ready status
  const isIncomplete = entities.some(
    (entity) =>
      !entity.isReviewed &&
      task.status === TaskSTATUS.READY &&
      !entity.entityText,
  );
  return (
    <Box paddingTop={'10px'}>
      <Box bgcolor={'#FCF9F9'} padding={'13px'} borderRadius={'4px'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <CustomTypography
              color='#101828'
              weight={500}
              typographyType={TypographyType.Header5}
              sx={{ display: 'flex' }}
            >
              {nestedType} ({entities.length})
              {isIncomplete && (
                <CustomTypography color='#C34343'>
                  - Incomplete
                </CustomTypography>
              )}
            </CustomTypography>
            <IconButton
              sx={{ width: '24px', height: '24px' }}
              aria-label={
                showNestedEntities
                  ? 'Expand nested entities'
                  : 'Collapse nested entities'
              }
              title={
                showNestedEntities
                  ? 'Expand nested entities'
                  : 'Collapse nested entities'
              }
              size='small'
              onClick={() => {
                setShowNestedEntities(!showNestedEntities);
              }}
            >
              {showNestedEntities ? (
                <KeyboardArrowDown
                  sx={{ color: '#14181F' }}
                  fontSize={'small'}
                />
              ) : (
                <KeyboardArrowUp sx={{ color: '#14181F' }} fontSize='small' />
              )}
            </IconButton>
          </Box>
          {allowEditingTask && (
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                aria-label='Create New'
                title='Create New'
                onClick={() => {
                  dispatch(copySelectedEntityOldUIAction(parentEntityId));
                }}
                sx={{ width: '24px', height: '18px' }}
              >
                <ControlPointDuplicate
                  sx={{ color: '#000000', width: '24px', height: '18px' }}
                />
              </IconButton>
              {allowDeletion && (
                <IconButton
                  aria-label='Delete'
                  title='Delete'
                  size='small'
                  onClick={() => {
                    dispatch(deleteSelectedEntityOldUIAction(parentEntityId));
                  }}
                >
                  <DeleteOutline fontSize={'small'} sx={{ color: '#000000' }} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
        {showNestedEntities && (
          <Box
            bgcolor={'#FFFFFF'}
            borderRadius={'4px'}
            paddingX={'10px'}
            paddingBottom={'10px'}
            marginTop={'15px'}
            display={'flex'}
            alignItems={'center'}
          >
            <Box flexGrow={1}>
              {entities.map((entity: EntityInfo) => (
                <EntitiesInfoBox
                  task={task}
                  key={entity.id}
                  entity={entity}
                  disabled={disabled}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(NestedEntitiesInfoBox);
