import { Box } from '@mui/material';
import { DocumentPageToken } from 'protos/google/cloud/documentai/v1/document';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTokenForHighlightAction } from '../../../../../redux/actions/review_task.action';
import {
  isMultipleCellsSelectedSelector,
  allowEditingTaskSelector,
  selectedEntityIdSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedEntityInfoSelector,
  selectedTableEntitiesInfoSelector,
  taskForReviewSelector,
  tokenForHighlightSelector,
  tokenListToHighlightSelector,
  tokensInDocumentSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import { addMouseMoveListener } from '../../../../../utils/BoundingBoxUtils';
import { boxPositionValuesUtilV2 } from '../../../../../utils/BoxPositionValuesUtilV2';
import { getSelectedTaskDocument } from '../../../../../utils/helpers';

interface Props {
  scale: number;
  currentPage: number;
}

const DocumentBoundingBoxHighlight: React.FC<Props> = ({
  scale,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenForHighlightSelector);
  const tokensInDocument = useSelector(tokensInDocumentSelector);
  const selectedTask = useSelector(taskForReviewSelector);
  const selectedTaskDocument =
    getSelectedTaskDocument(selectedTask)?.documents?.[0];
  const selectedEntityId = useSelector(selectedEntityIdSelector);
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const selectedEntityInfo = useSelector(selectedEntityInfoSelector);
  const selectedTableEntitiesInfo = useSelector(
    selectedTableEntitiesInfoSelector,
  );
  const isMultipleCellsSelected = useSelector(isMultipleCellsSelectedSelector);
  const tokenListToHighlight = useSelector(tokenListToHighlightSelector);
  const allowEditingTask = useSelector(allowEditingTaskSelector);

  const setToken = (token?: DocumentPageToken) => {
    dispatch(setTokenForHighlightAction(token));
  };

  useEffect(() => {
    if (currentPage === 0 && selectedTaskDocument) {
      addMouseMoveListener(
        scale,
        selectedTaskDocument,
        tokensInDocument,
        setToken,
      );
    }
  }, [selectedTaskDocument]);

  const isNotesTypeEntity =
    selectedEntityInfo?.normalizedEntityType ===
    EntityDataType.ENTITY_TYPE_ANNOTATION;
  // This is to check if the box that is to be highlighted lies under the bounding box
  const liesUnderBBox = document
    .getElementById('bounding-box')
    ?.matches(':hover');

  if (
    !token ||
    isNotesTypeEntity ||
    liesUnderBBox ||
    // If no simple entity is selected and entities inside table has either 0 or more than
    // one entities selected or if one ID is selected but it is notes type, we do not show highlight in the doc
    (!selectedEntityId && selectedEntityIdsForAnnotation.length !== 1) ||
    (selectedEntityIdsForAnnotation.length === 1 &&
      selectedTableEntitiesInfo?.find(
        (e) => e.id === selectedEntityIdsForAnnotation[0],
      )?.isExtra) ||
    tokenListToHighlight
  )
    return <Box />;

  const hasToken = tokensInDocument[currentPage].includes(token);

  if (!allowEditingTask || !hasToken || isMultipleCellsSelected) return <Box />;

  const boxPositionValues =
    boxPositionValuesUtilV2.addPaddingToBoxPositionValues(
      2, // This is the padding we are adding in the position
      boxPositionValuesUtilV2.getBoxPositionValues(
        token?.layout?.boundingPoly?.vertices,
        scale,
      ),
    );

  return (
    <Box
      id={'focused-token'}
      position={'absolute'}
      sx={{ border: '2px solid #0500FF', borderRadius: '2px' }}
      // To make the word inside the highlight visible, the box has been shifted 2px above and to the left
      // To add the same space on right and bottom of the box, that shift of 2px plus 2px gap is to be added = 4px
      top={boxPositionValues.top}
      left={boxPositionValues.left}
      width={boxPositionValues.width}
      height={boxPositionValues.height}
    />
  );
};

export default React.memo(DocumentBoundingBoxHighlight);
