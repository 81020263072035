import { Box } from '@mui/material';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import React, { FC, memo } from 'react';
import CustomTextField from './CustomTextField';
import CustomFilterSelect from './generic/CustomFilterSelect';
import { CLASSIFICATION_LABEL_SELECT } from '../utils/constants';
import { CURRENCY_CODES } from '../utils/normalization/normalization.constants';

interface AttributeContentProps {
  attributeType: EntityDataType | typeof CLASSIFICATION_LABEL_SELECT;
  name: string;
  getFieldProps: any;
  setFieldValue: any;
  getClassificationLabelsList: () => { value: string; label: string }[];
  align?: 'top' | 'bottom';
  disabled?: boolean;
}

const AttributeContent: FC<AttributeContentProps> = ({
  attributeType,
  name,
  getFieldProps,
  setFieldValue,
  getClassificationLabelsList,
  align = 'bottom',
  disabled = false,
}) => {
  const alignBottom = align === 'bottom';

  switch (attributeType) {
    case EntityDataType.ENTITY_TYPE_MONEY: {
      const values = getFieldProps(name)?.value?.split(' ');
      const currencyCode = values[1] ?? CURRENCY_CODES[0];

      return (
        <Box display={'flex'} width={'100%'} gap={1} alignItems={'center'}>
          <CustomTextField
            size='full'
            value={values[0]}
            label=''
            height={'23.4px'}
            type='number'
            gapTop={alignBottom ? '8px' : 0}
            onChange={(e) =>
              setFieldValue(name, e.target.value + ' ' + currencyCode)
            }
            placeholder='Enter value'
            disabled={disabled}
          />
          <CustomFilterSelect
            width={'40%'}
            padding={'8.7px'}
            height={'23px'}
            gapTop={alignBottom ? '-5px' : '-9px'}
            filters={CURRENCY_CODES.map((c) => ({ label: c, value: c }))}
            onChange={(e) => setFieldValue(name, values[0] + ' ' + e)}
            value={currencyCode?.toUpperCase()}
            disabled={disabled}
          />
        </Box>
      );
    }
    case EntityDataType.ENTITY_TYPE_DATE:
      return (
        <CustomTextField
          name={name}
          size='full'
          value={''}
          height={'23.4px'}
          label=''
          gapTop={alignBottom ? '8px' : 0}
          type='date'
          extraProps={getFieldProps(name)}
          placeholder='Select Date'
          disabled={disabled}
        />
      );
    case EntityDataType.ENTITY_TYPE_FLOAT:
    case EntityDataType.ENTITY_TYPE_INTEGER:
      return (
        <CustomTextField
          name={name}
          size='full'
          gapTop={alignBottom ? '8px' : 0}
          value={''}
          height={'23.4px'}
          label=''
          type='number'
          extraProps={getFieldProps(name)}
          placeholder='Enter value'
          disabled={disabled}
        />
      );
    case CLASSIFICATION_LABEL_SELECT:
      return (
        <CustomFilterSelect
          name={name}
          width={'100%'}
          padding={'8.7px'}
          height={'23px'}
          gapTop={alignBottom ? '-4.5px' : '0px'}
          filters={getClassificationLabelsList()}
          value={''}
          extraProps={getFieldProps(name)}
          disabled={disabled}
        />
      );
    default:
      return (
        <CustomTextField
          name={name}
          size='full'
          height={'23.4px'}
          value={''}
          gapTop={alignBottom ? '8px' : 0}
          label=''
          extraProps={getFieldProps(name)}
          placeholder='Enter text or value'
          disabled={disabled}
        />
      );
  }
};

export default memo(AttributeContent);
