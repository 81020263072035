import React, { useEffect, useState } from 'react';
import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { IconButton, TextField, styled } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTextSegmentIdAction,
  updateEntityInfoAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  ISO_8601_DATE_FORMAT,
  TEXT_SEGMENT_FIELD_ID,
} from '../../../../../../../utils/constants';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';
import { detectUndoRedoKeyPress } from '../../../../../../../utils/helpers';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    padding: '8px 14px 8px 14px',
    border: '1px solid #D0D5DD',
    backgroundColor: 'white',
    borderRadius: '8px',
    '&.Mui-focused': {
      border: '1px solid #1669F7',
      backgroundColor: 'white',
    },
  },
});

interface CustomTextFieldProps {
  value: string;
  segmentIndex: string;
  taskStatus: TaskSTATUS;
  onPressEnter: () => void;
  handleDeleteTextSegment: (textSegmentId: string) => void;
  entity: EntityInfo;
  errorMessage?: string;
  isAutoFocus?: boolean;
  onFocus?: () => void;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  value,
  segmentIndex,
  onPressEnter,
  handleDeleteTextSegment,
  entity,
  isAutoFocus = false,
}) => {
  const dispatch = useDispatch();
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    if (value !== fieldValue) setFieldValue(value);
  }, [value]);

  const getPlaceHolderAccToEntityType = () => {
    switch (entity.normalizedEntityType) {
      case EntityDataType.ENTITY_TYPE_DATE: {
        return ISO_8601_DATE_FORMAT;
      }
      case EntityDataType.ENTITY_TYPE_MONEY:
      case EntityDataType.ENTITY_TYPE_FLOAT: {
        return '0.000000000';
      }
      case EntityDataType.ENTITY_TYPE_INTEGER: {
        return 'Enter an integer';
      }
      case EntityDataType.ENTITY_TYPE_TEXT: {
        return 'Enter text here';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <StyledTextField
      autoFocus={isAutoFocus}
      onFocus={(e) => {
        const length = e.target.value.length;
        e.target.setSelectionRange(length, length);
      }}
      variant='filled'
      id={TEXT_SEGMENT_FIELD_ID}
      inputProps={{
        'aria-label': 'Edit exported data',
      }}
      placeholder={getPlaceHolderAccToEntityType()}
      value={fieldValue}
      onKeyDown={(e) => {
        // The second condition is added to make sure when focus is on remove button below
        // and when user presses enter this code does not get executed
        if (
          e.key === 'Enter' &&
          document.activeElement?.id === TEXT_SEGMENT_FIELD_ID
        ) {
          e.preventDefault();
          e.stopPropagation();
          onPressEnter();
        }
        if (detectUndoRedoKeyPress(e)) {
          e.preventDefault();
        }
      }}
      onClick={() => {
        dispatch(setSelectedTextSegmentIdAction(segmentIndex));
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (allowEditingTask) {
          // THIS IS ADDED TO FIX CURSOR MOVING TO END OF THE TEXT
          setFieldValue(event.target.value);

          /**
           * Set the updated value
           */
          const textSegmentInfo = entity.textSegments[segmentIndex];
          const newTextSegmentInfo = {
            ...textSegmentInfo,
            text: event.target.value,
          };
          const newEntityInfo = {
            ...entity,
            isModified: true,
            textSegments: {
              ...entity.textSegments,
              [newTextSegmentInfo.id]: newTextSegmentInfo,
            },
          };
          dispatch(updateEntityInfoAction(newEntityInfo));
        }
      }}
      multiline
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <>
            <IconButton
              aria-label='Remove row'
              title='Remove row'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteTextSegment(segmentIndex);
              }}
              sx={{
                // We don't want to show the delete icon if there is only one text segment
                visibility:
                  !allowEditingTask ||
                  Object.keys(entity.textSegments).length <= 1
                    ? 'hidden'
                    : 'visible',
                padding: '4px',
              }}
            >
              {allowEditingTask && (
                <RemoveCircleOutline fontSize='small' sx={{ color: 'black' }} />
              )}
            </IconButton>
          </>
        ),
      }}
      sx={{
        width: '100%',
        marginBottom: '15px',
        padding: 0,
        input: {
          paddingY: '0px',
          paddingX: '0px',
          fontSize: '14px',
        },
        caretColor: allowEditingTask ? '' : 'transparent',
      }}
    />
  );
};

export default CustomTextField;
