import { ActionObject } from 'workflow-utils/src/types';
import { ItemDescription, WidgetContainer } from './Styled';
import React from 'react';
import { removeElementAnnotation } from 'workflow-utils/src/helper';

interface IProps {
  action: ActionObject;
}

const PlainDescription: React.FC<IProps> = ({ action }) => {
  const description = removeElementAnnotation(action.description || '');
  return (
    <WidgetContainer>
      <ItemDescription>{description}</ItemDescription>
    </WidgetContainer>
  );
};

export default PlainDescription;
