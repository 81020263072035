import { Box } from '@mui/material';
import {
  Document,
  DocumentPageToken,
  DocumentTextAnchorTextSegment,
} from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  searchTextSelector,
  searchedTokensInDocumentSelector,
  searchedTokensStartIndicesSelector,
  selectedTokenIndexSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import { boxPositionValuesUtilV2 } from '../../../../../utils/BoxPositionValuesUtilV2';
import { splitTokenText } from '../../../../../utils/DocumentSearchUtil';
import { tokenValuesUtil } from '../../../../../utils/TokenValuesUtil';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  document: Document;
  currentPage: number;
  scale: number;
}

const DocumentTokensLayout: React.FC<Props> = ({
  document,
  scale,
  currentPage,
}) => {
  const defaultFontSize = 30;
  const fontSize = String(defaultFontSize * scale);
  const searchText = useSelector(searchTextSelector) ?? '';
  const searchedTokensStartIndices =
    useSelector(searchedTokensStartIndicesSelector) ?? [];
  const searchedTokensInDocument = useSelector(
    searchedTokensInDocumentSelector,
  );
  const selectedTokenIndex = useSelector(selectedTokenIndexSelector);

  const isSubstringOfSearchTextCaseInsensitive = (str: string) => {
    const subString = str.trim().toLowerCase();
    if (searchText.includes(subString)) return true;
    return false;
  };

  return (
    <>
      {
        // Iterate over the matched index here
        searchedTokensStartIndices.map((startIndex: number) => {
          // These are the tokens which were added as they matched the searched string
          const tokens: DocumentPageToken[] =
            searchedTokensInDocument?.[`${currentPage}-${startIndex}`] || [];

          if (tokens.length === 0) return;

          if (
            startIndex >=
            (tokens?.[tokens.length - 1]?.layout?.textAnchor?.textSegments?.[0]
              ?.endIndex as number)
          ) {
            return;
          }
          if (
            startIndex + searchText.length <=
            (tokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
              ?.startIndex as number)
          ) {
            return;
          }

          return (
            <Box key={uuidv4()} display={'flex'}>
              {
                // For given matched index, iterate over tokens to render them one by one
                tokens.map((token: DocumentPageToken, index: number) => {
                  const tokenText =
                    token?.layout?.textAnchor?.textSegments?.length ?? 0
                      ? tokenValuesUtil.getSubStringFromTextSegment(
                          token?.layout?.textAnchor
                            ?.textSegments?.[0] as DocumentTextAnchorTextSegment,
                          document.text as string,
                        )
                      : '';
                  const boxPositionValues =
                    boxPositionValuesUtilV2.getBoxPositionValues(
                      token?.layout?.boundingPoly?.vertices as Vertex[],
                      scale,
                    );

                  return (
                    <Box
                      key={uuidv4()}
                      // Used id in this format, so we can make them visible over screen using goToVisibleTooltip
                      // when the toggle value matches with this search result
                      id={index === 0 ? `token-index-${startIndex}` : undefined}
                      position={'absolute'}
                      display={'flex'}
                      sx={{ userSelect: 'none', fontSize: `${fontSize}px` }}
                      top={boxPositionValues.top}
                      left={boxPositionValues.left}
                    >
                      {/* Here the token text is split into substring so we can highlight the actual searched
                          match string
                      */}
                      {splitTokenText(
                        token,
                        tokenText,
                        startIndex,
                        startIndex + searchText.length,
                      ).map((str) => (
                        <Box
                          display={'flex'}
                          key={uuidv4()}
                          // To highlight the searched word with yellow background
                          bgcolor={
                            isSubstringOfSearchTextCaseInsensitive(str)
                              ? startIndex === selectedTokenIndex
                                ? 'orange'
                                : 'yellow'
                              : undefined
                          }
                        >
                          {str}
                        </Box>
                      ))}
                    </Box>
                  );
                })
              }
            </Box>
          );
        })
      }
    </>
  );
};

export default React.memo(DocumentTokensLayout);
