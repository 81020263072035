import {
  CreateInvoiceRequest,
  DeleteInvoiceRequest,
  Invoice,
  ListInvoicesRequest,
  ListInvoicesResponse,
  UpdateInvoiceRequest,
} from 'protos/pb/demo/invoice_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ERPActionType } from '../actions/actions.constants';
import {
  createInvoiceCompletedAction,
  createInvoiceErrorAction,
  deleteInvoiceCompletedAction,
  deleteInvoiceErrorAction,
  listInvoicesCompletedAction,
  listInvoicesErrorAction,
  updateInvoiceCompletedAction,
  updateInvoiceErrorAction,
} from '../actions/erp.action';
import { invoiceService } from '../../services/InvoiceService';

export function* listInvoicesSaga(data: {
  type: ERPActionType;
  payload: ListInvoicesRequest;
}): any {
  try {
    const response: ListInvoicesResponse = yield call(
      invoiceService.listInvoices,
      data.payload,
    );
    yield put(listInvoicesCompletedAction(response.invoices!));
  } catch (e: any) {
    yield put(
      listInvoicesErrorAction(
        (e?.errors?.length && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* createInvoiceSaga(data: {
  type: ERPActionType;
  payload: CreateInvoiceRequest;
}): any {
  try {
    const response: Invoice = yield call(
      invoiceService.createInvoice,
      data.payload,
    );
    yield put(createInvoiceCompletedAction(response));
  } catch (e: any) {
    yield put(
      createInvoiceErrorAction(
        (e?.errors?.length && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* updateInvoiceSaga(data: {
  type: ERPActionType;
  payload: UpdateInvoiceRequest;
}): any {
  try {
    const response: Invoice = yield call(
      invoiceService.updateInvoice,
      data.payload,
    );
    yield put(updateInvoiceCompletedAction(response));
  } catch (e: any) {
    yield put(
      updateInvoiceErrorAction(
        (e?.errors?.length && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* deleteInvoiceSaga(data: {
  type: ERPActionType;
  payload: DeleteInvoiceRequest;
}): any {
  try {
    yield call(invoiceService.deleteInvoice, data.payload);
    yield put(deleteInvoiceCompletedAction(data.payload.name!));
  } catch (e: any) {
    yield put(
      deleteInvoiceErrorAction(
        (e?.errors?.length && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* erpSaga() {
  yield all([
    takeLatest(ERPActionType.LIST_INVOICES, listInvoicesSaga),
    takeLatest(ERPActionType.CREATE_INVOICE, createInvoiceSaga),
    takeLatest(ERPActionType.UPDATE_INVOICE, updateInvoiceSaga),
    takeLatest(ERPActionType.DELETE_INVOICE, deleteInvoiceSaga),
  ]);
}

export default erpSaga;
