import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import Header from './component/Header';
import Summary from './component/Summary';
import TaskBanner from './component/TaskBanner';
import { GetWorkflowTaskRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  clearWorkflowTask,
  getWorkflowWithTask,
} from '../../../../../redux/actions/workflow_task.constants';
import {
  workflowTaskLoadingSelector,
  workflowByIdSelector,
  workflowTaskDetailSelector,
} from '../../../../../redux/selectors/workflow_task.selectors';
import { selectedOrgInfoSelector } from '../../../../../redux/selectors/user.selectors';
import { formatDateTime } from '../../../../../utils/helpers';
import {
  Workflow,
  WorkflowTaskStatus,
} from 'protos/pb/v1alpha1/orbot_workflow';
import TaskDetails from './component/TaskDetails';
import FullPageLoader from '../../../../../components/FullPageLoader';
import { getActions } from 'workflow-utils/src/v2/workflow';

const UiAutomationsDetail: React.FC = () => {
  // Getting the taskId from the params
  const { workflow_task_id: taskId } = useParams<{
    workflow_task_id: string;
  }>();

  const dispatch = useDispatch();

  const isLoading = useSelector(workflowTaskLoadingSelector);
  const workflowTaskData = useSelector(workflowTaskDetailSelector);
  const workflowData = useSelector(workflowByIdSelector);

  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;

  useEffect(() => {
    if (!taskId || !orgId) return;

    const req: GetWorkflowTaskRequest = {
      taskId,
      orgId,
    };
    dispatch(getWorkflowWithTask(req));

    return () => {
      // Clearing the data that was already present in the states
      dispatch(clearWorkflowTask());
    };
  }, [taskId, orgId]);

  const getTitle = () => {
    if (!workflowData?.data) {
      return '';
    }
    return workflowData?.data?.displayName;
  };

  const getTaskDescription = () => {
    if (!workflowTaskData.data) {
      return '';
    }
    if (workflowTaskData?.data?.endTime) {
      const { date, time } = formatDateTime(workflowTaskData?.data?.endTime);
      return `Task executed on ${date}, ${time}`;
    }
    if (workflowTaskData?.data?.discoverTime) {
      const { date, time } = formatDateTime(
        workflowTaskData?.data?.discoverTime,
      );
      return `Task added on ${date}, ${time}`;
    }
    return `Task recently added`;
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  const actions =
    getActions(
      workflowData?.data as Workflow,
      workflowTaskData?.data?.processId,
    ) || [];

  return (
    <>
      <Header
        title={getTitle() || 'NA'}
        description={getTaskDescription()}
        status={workflowTaskData.data?.status}
      />
      <Box
        padding={'32px 95px'}
        bgcolor={OrbyColorPalette['grey-100']}
        borderTop={`1px solid ${OrbyColorPalette['grey-300']}`}
        minHeight={'calc(100vh - 116px)'}
      >
        <TaskBanner
          taskStatus={workflowTaskData.data?.status || 0}
          errorMessage={workflowTaskData.data?.errorMessage}
        />

        <Summary
          workflowTask={workflowTaskData?.data || {}}
          workflow={workflowData?.data as Workflow}
        />

        {workflowTaskData?.data?.status === WorkflowTaskStatus.SUCCESS ? (
          <Box
            bgcolor={OrbyColorPalette['white-0']}
            borderRadius={'12px'}
            marginTop={'24px'}
            paddingBottom={'32px'}
          >
            <Box padding={'36px 0 8px 36px'}>
              <OrbyTypography
                size='xl'
                weight='medium'
                color={OrbyColorPalette['black-0']}
              >
                Workflow steps
              </OrbyTypography>
            </Box>

            <Box padding={'20px'}>
              <TaskDetails
                actions={actions}
                executedActions={workflowTaskData?.data?.executedActions || []}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default UiAutomationsDetail;
