import { Box, CircularProgress, Menu, MenuItem } from '@mui/material';
import CustomSearchField from '../../components/CustomSearchField';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { FC, memo, useEffect, useState } from 'react';
import { ELLIPSIS_STYLE, ReassignmentOptions } from '../../utils/constants';
import { Check } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { userFiltersSelector } from '../../redux/selectors/filter_options.selectors';
import { getUserFiltersAction } from '../../redux/actions/filter_options.action';

interface Props {
  selectedUser: string;
  setSelectedUser: (x: string) => void;
  canAssignToOthers: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
}

const UserAssignmentMenu: FC<Props> = ({
  selectedUser,
  setSelectedUser,
  canAssignToOthers,
  handleClose,
  anchorEl,
}) => {
  const dispatch = useDispatch();

  const [userEmailList, setUserEmailList] = useState<string[]>([]);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [options, setOptions] = useState<string[]>([]);
  const userFilters = useSelector(userFiltersSelector);
  const loggedInUser = useSelector(loggedInUserSelector) ?? {};

  useEffect(() => {
    if (userFilters.length) {
      const emails = userFilters.map((f) => f.label);
      setUserEmailList(emails);
      setOptions(emails);
    }
  }, [userFilters]);

  useEffect(() => {
    if (canAssignToOthers && selectedOrgInfo) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
  }, [selectedOrgInfo]);

  const handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = userEmailList.filter(
      (opt) => opt.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  const renderLabel = (label: string, selected = false) => {
    return (
      <CustomTypography
        typographyType={TypographyType.Header4}
        lineHeight='24px'
        color={selected ? '#475467' : '#101828'}
        weight={500}
        sx={{
          paddingRight: '0px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </CustomTypography>
    );
  };

  const renderMenuItem = ({
    key,
    label,
    value,
    onClick,
    tabIndex,
  }: {
    key?: React.Key | null;
    label: string;
    value: string;
    onClick?: () => void;
    tabIndex: number;
  }) => {
    return (
      <MenuItem
        onClick={() => onClick?.()}
        tabIndex={tabIndex}
        key={key}
        value={value}
        sx={{
          paddingY: canAssignToOthers ? '10px' : 0,
          paddingX: '14px',
          marginBottom: canAssignToOthers ? '4px' : 0,
          height: '44px',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box
            width={canAssignToOthers ? '80%' : '100%'}
            display={'flex'}
            gap={1}
            alignItems={'center'}
            title={label}
          >
            {renderLabel(label, selectedUser === value)}
          </Box>
          {canAssignToOthers &&
            (selectedUser === value ? (
              <Check sx={{ color: '#7F56D9' }} />
            ) : (
              <Box width={'24px'}></Box>
            ))}
        </Box>
      </MenuItem>
    );
  };
  return canAssignToOthers ? (
    <Menu
      id={'user-assignment-menu'}
      MenuListProps={{
        'aria-labelledby': 'user-assignment-button',
      }}
      sx={{
        marginTop: '8px',
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          maxHeight: '350px',
          width: '445px',
          overflow: 'auto',
          borderRadius: '12px',
        },
      }}
      open={!!anchorEl}
      onClose={() => {
        setOptions(userEmailList);
        handleClose();
      }}
    >
      {renderMenuItem({
        label: ReassignmentOptions.ASSIGN_TO_MYSELF,
        value: ReassignmentOptions.ASSIGN_TO_MYSELF,
        key: ReassignmentOptions.ASSIGN_TO_MYSELF,
        onClick: () => {
          setSelectedUser(loggedInUser.email as string);
          handleClose();
        },
        tabIndex: 1,
      })}
      <Box tabIndex={-1} paddingX={'14px'}>
        <CustomSearchField
          autoFocus
          placeHolder='Search'
          onChange={handleUserSearch}
        />
      </Box>
      {!userEmailList.length ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={'30px'} />
        </Box>
      ) : (
        options.map((opt, index) => {
          return renderMenuItem({
            label: opt,
            value: opt,
            key: index,
            onClick: () => {
              setSelectedUser(opt);
              handleClose();
            },
            tabIndex: index + 1,
          });
        })
      )}
    </Menu>
  ) : (
    <Menu
      id={'user-assignment-menu'}
      MenuListProps={{
        'aria-labelledby': 'user-assignment-button',
      }}
      sx={{
        marginTop: '8px',
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          maxHeight: '350px',
          width: '445px',
          overflow: 'auto',
          borderRadius: '12px',
        },
      }}
      open={!!anchorEl}
      onClose={() => {
        setOptions(userEmailList);
        handleClose();
      }}
    >
      {renderMenuItem({
        label: ReassignmentOptions.ASSIGN_TO_MYSELF,
        value: ReassignmentOptions.ASSIGN_TO_MYSELF,
        key: ReassignmentOptions.ASSIGN_TO_MYSELF,
        onClick: () => {
          setSelectedUser(loggedInUser.email as string);
          handleClose();
        },
        tabIndex: 1,
      })}
    </Menu>
  );
};

export default memo(UserAssignmentMenu);
