import {
  Box,
  Button,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { ActionObject } from 'workflow-utils/src/types';

export interface KeywordListProps {
  keywords: string[];
  actionId: string;
  text: string;
  action: ActionObject;
  isEditable: boolean;
  processId?: string;

  onClose(): void;
}

const EMPTY_KEYWORD_ERROR_MESSAGE = 'Keyword list cannot be emtpy';

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '450px',
}));

/**
 * Allow to view and edit list of keywords.
 */
const KeywordList: React.FC<KeywordListProps> = ({
  keywords,
  action,
  text,
  onClose,
  isEditable,
  processId,
}) => {
  const dispatch = useDispatch();

  const originalKeywordsText = keywords.join(',');
  const [keywordsText, setKeywordsText] = useState(originalKeywordsText);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setKeywordsText(value);
    if (value === '') {
      setErrorMessage(EMPTY_KEYWORD_ERROR_MESSAGE);
    }
  };

  const onUpdate = () => {
    const keywords = keywordsText
      .split(',')
      .map((value) => value.trim())
      .filter((value) => value);

    if (keywords.length === 0) {
      setErrorMessage(EMPTY_KEYWORD_ERROR_MESSAGE);
      return;
    }

    let description = keywords[0];
    if (keywords.length > 1) {
      description += ` and ${keywords.length - 1} others`;
    }
    const updated: ActionObject = {
      ...action,
      description: action!.description!.replace(text, description),
      action: {
        flagKeywords: {
          keywords,
        },
      },
    };

    dispatch(updateAction(action, updated, processId));

    onClose();
  };

  return (
    <Container spacing={2}>
      <OrbyTypography color={OrbyColorPalette['grey-900']} weight='bold'>
        Here are the keywords flagged
      </OrbyTypography>
      <Typography color={OrbyColorPalette['grey-900']}>
        Keywords should be separated by commas, and they do not need to be case
        sensitive
      </Typography>
      <TextField
        multiline
        fullWidth
        value={keywordsText}
        onChange={onChangeText}
        InputProps={{
          readOnly: !isEditable,
        }}
      />
      {errorMessage && (
        <Typography fontSize='small' color={OrbyColorPalette['error-700']}>
          Error: {errorMessage}
        </Typography>
      )}
      <Box>
        <Button
          variant='outlined'
          disabled={
            errorMessage !== '' || keywordsText === originalKeywordsText
          }
          onClick={onUpdate}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default KeywordList;
