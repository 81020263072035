import { CheckCircleRounded, WarningRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { FC, memo } from 'react';
import { ApplicationName } from '../../utils/protos/enums';

interface ConnectionMessageProps {
  connected: boolean;
  application: string;
  onConnect?: () => void;
  edit?: boolean;
}

const ConnectionMessage: FC<ConnectionMessageProps> = ({
  connected,
  application,
  onConnect,
  edit = false,
}) => {
  return (
    <Box
      bgcolor={!connected ? '#FFFAE6' : '#E3FCEF'}
      borderRadius={'10px'}
      height={'fit-content'}
      padding={'16px'}
      display={'flex'}
      gap={'20px'}
      marginLeft={'36px'}
      marginTop={'50px'}
      width={'400px'}
    >
      {!connected ? (
        <WarningRounded sx={{ color: '#fe8b01' }} />
      ) : (
        <CheckCircleRounded sx={{ color: '#006644' }} />
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
        alignItems={'start'}
      >
        <CustomTypography
          component={'span'}
          typographyType={TypographyType.Header4}
          color='#545454'
        >
          {!connected ? 'Connection Required' : 'Connected'}
        </CustomTypography>
        <CustomTypography color='#545454'>
          {`This trigger requires connection to ${application}`}
        </CustomTypography>
        {!connected && (
          <Button
            aria-label='Connect.'
            variant='text'
            onClick={onConnect}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              padding: '0px 1px',
            }}
          >
            <CustomTypography
              component={'span'}
              lineHeight='20px'
              color='#0052CC'
            >
              Connect
            </CustomTypography>
          </Button>
        )}
        {!edit &&
          [
            ApplicationName.Gmail.toString(),
            ApplicationName.Outlook.toString(),
          ].includes(application) && (
            <CustomTypography
              sx={{ marginTop: '10px' }}
              color='#545454'
              size='14px'
            >
              Labels cannot be changed after workflow creation
            </CustomTypography>
          )}
      </Box>
    </Box>
  );
};

export default memo(ConnectionMessage);
