import * as React from 'react';
import { ActionObject } from 'workflow-utils/src/types';
import { ItemDescription, WidgetContainer } from './Styled';
import { EditableText } from './EditableText';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';

export interface ClickableWidgetProps {
  action: ActionObject;
  isEditable: boolean;
  processId?: string;
}

const GotoActionDescription: React.FC<ClickableWidgetProps> = ({
  action,
  isEditable,
  processId,
}) => {
  const dispatch = useDispatch();
  // We will always have one action in the group in Click case
  if (!action.goto) {
    return null;
  }

  // URL could be dynamic if it comes from previous action output or environment variable
  let url = '';
  if (action.goto?.url?.jsonValue) {
    url = JSON.parse(action.goto.url.jsonValue);
  } else {
    return null;
  }

  const onTextEditFinish = React.useCallback(
    (newUrl: string) => {
      if (!action.goto) {
        throw new Error('goto is expected');
      }
      if (!URL.canParse(newUrl)) {
        throw new Error(`Please enter a valid URL`);
      }

      const updated: ActionObject = {
        description: action!.description!.replace(url, newUrl),
        action: {
          ...action,
          goto: {
            ...action.goto,
            url: {
              jsonValue: JSON.stringify(newUrl),
            },
          },
        },
      };
      dispatch(updateAction(action, updated, processId));
    },
    [action?.description, action?.goto?.url?.jsonValue],
  );

  return (
    <WidgetContainer sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {url ? (
        <>
          <ItemDescription>Go to URL:</ItemDescription>
          <EditableText
            initialText={url}
            disabled={!isEditable}
            variant='inline'
            fontSize={18}
            onTextEditFinish={onTextEditFinish}
          />
        </>
      ) : (
        <ItemDescription>{action.description ?? 'Go to URL'}</ItemDescription>
      )}
    </WidgetContainer>
  );
};

export default GotoActionDescription;
