import * as React from 'react';
import CustomTypography from '../../components/CustomTypography';
import CustomModal from '../../components/CustomModal';

interface RestoreItemsConfirmationModelProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  isMultiple?: boolean;
}

const RestoreItemsConfirmationModel: React.FC<
  RestoreItemsConfirmationModelProps
> = ({ open, handleClose, onSubmit, isMultiple = false }) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={onSubmit}
      heading={isMultiple ? 'Restore Items?' : 'Restore Item?'}
      content={
        <CustomTypography>
          {`Selected ${
            isMultiple ? 'items' : 'item'
          } will be restored. Confirm to proceed?`}
        </CustomTypography>
      }
      primaryLabel={'Confirm'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(RestoreItemsConfirmationModel);
