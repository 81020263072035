import { Box, CircularProgress, ListSubheader } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { FormikValues } from 'formik';
import _ from 'lodash';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import React, { FC, useEffect } from 'react';
import { Filter } from '../../../utils/constants';

interface Props {
  workflowFilters: Filter[];
  formik: FormikValues;
  workflowFiltersLoading: boolean;
}

export const SourceWorkflowSelect: FC<Props> = ({
  workflowFilters,
  formik,
  workflowFiltersLoading,
}) => {
  const [options, setOptions] = React.useState(workflowFilters);

  useEffect(() => {
    setOptions(workflowFilters);
  }, [workflowFilters]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = workflowFilters.filter(
      (opt) => opt.label.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  return (
    <>
      <OrbySelect
        width='448px'
        aria-hidden={false}
        value={formik.values.source_workflow}
        renderValue={() => {
          const renderText =
            workflowFilters.find(
              (f) => f.value === formik.values.source_workflow,
            )?.label || 'Select source workflow';
          return (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
            >
              {renderText}
            </OrbyTypography>
          );
        }}
        onChange={(e) => {
          formik.setFieldValue('source_workflow', e.target.value);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ListSubheader
          sx={{
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <OrbyTextField
            variant='flat'
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
              e.stopPropagation()
            }
            width={'100%'}
            tabIndex={0}
            name={'search-workflow'}
            disabled={false}
            placeholder='Search workflow'
            startAdornment={
              <GridSearchIcon
                sx={{ color: OrbyColorPalette['grey-900'] }}
                fontSize='medium'
              />
            }
            onChange={_.debounce((event) => handleSearch(event), 300)}
          />
        </ListSubheader>
        {workflowFiltersLoading ? (
          <Box
            display={'flex'}
            padding={'10px 0px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress
              sx={{ color: OrbyColorPalette['purple-900'] }}
              size={'30px'}
            />
          </Box>
        ) : (
          options.map((option) => (
            <OrbyMenuItem
              width='448px'
              key={option.value}
              value={option.value}
              title={option.label}
            />
          ))
        )}
      </OrbySelect>

      <OrbyTypography
        color={OrbyColorPalette['error-500']}
        sx={{
          marginTop: '3px',
        }}
      >
        {formik.touched.source_workflow && formik.errors.source_workflow}
      </OrbyTypography>
    </>
  );
};
