import { Box } from '@mui/material';
import React, { FC, memo } from 'react';
import { ModeOutlined, CheckOutlined } from '@mui/icons-material';

type StepIconProps = {
  color: string;
  showSecondry: boolean | undefined;
  bgColor: string;
  icon?: any;
};

const StepIcon: FC<StepIconProps> = ({
  color,
  showSecondry,
  bgColor,
  icon,
}) => {
  return (
    <Box
      bgcolor={bgColor}
      color={color}
      width={'48px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {icon ? icon : showSecondry ? <CheckOutlined /> : <ModeOutlined />}
    </Box>
  );
};

export default memo(StepIcon);
