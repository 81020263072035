import {
  badgeClasses,
  Box,
  BoxProps,
  chipClasses,
  Collapse,
  iconButtonClasses,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { getClickableWidgetInfo } from 'workflow-utils/src/helper';
import { OrbyBadge, OrbyColorPalette } from 'orby-ui/src';
import FlagKeywords from './FlagKeywords';
import DuplicateDetected from './DuplicateDetected';
import IfCondition from './IfCondition';
import PlainDescription from './PlainDescription';
import LinkedTask from './LinkedTask';
import ClickableWidget from './Clickable';
import { ColumnContainer, RowContainer } from './Styled';
import { ScreenChip } from './Chip';
import { ForeachAction } from './ForeachExecution';
import { ConditionAction } from './ConditionAction';
import { ActionObject, ExecutedActionObject } from 'workflow-utils/src/types';
import {
  containClickAction,
  containConditionAction,
  containSmartAction,
  containControlFlow,
  hasDeteDuplicateLineItems,
  hasFlagKeywords,
  containCreateTaskAction,
} from 'workflow-utils/src/v2/action-classifier';

interface IActionProps extends BoxProps {
  action: ActionObject;
  startIndex: number;
  executedActions: ExecutedActionObject[];
}

const ActionItem: React.FC<IActionProps> = ({
  action,
  startIndex,
  executedActions,
  ...props
}) => {
  const [isScreenShotOpen, setIsScreenShotOpen] = useState(false);

  const hasControlFlow = containControlFlow(action);
  const hasSmartAction = containSmartAction(action);
  const hasFlagKeyword = hasFlagKeywords(action);
  const hasDetected = hasDeteDuplicateLineItems(action);

  let imgUrl;

  if (!hasControlFlow) {
    const executedAction = executedActions.find((ea: ExecutedActionObject) => {
      return !action.jsFunction && action.id === ea.preparedActionUuid;
    });
    imgUrl = executedAction?.screenshot?.url;
  }

  const DescriptionContainer = styled(RowContainer)(() => {
    const hoverStyle = {
      '& .widget-container': {
        backgroundColor: OrbyColorPalette['grey-50'],
      },
      [`& .${iconButtonClasses.root}, & .${chipClasses.root}`]: {
        visibility: 'visible',
      },
    };
    const normalStyle = {
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '10px',
      '&:hover': hoverStyle,
    };
    return normalStyle;
  });

  const ScreenShot = styled(Box)<BoxProps>(() => ({
    width: '100%',
    borderRadius: '20px',
    padding: '32px 44px',
    backgroundColor: '#FAFAFA',
  }));

  const renderDescription = (
    action: ActionObject,
    executedActions: ExecutedActionObject[],
  ): JSX.Element => {
    if (containConditionAction(action)) {
      return <IfCondition action={action} />;
    } else if (containClickAction(action)) {
      const description = action.description || '';
      const clickableWidgetInfo = getClickableWidgetInfo(description);
      if (clickableWidgetInfo) {
        return (
          <ClickableWidget
            action={action}
            clickableWidgetInfo={clickableWidgetInfo}
          />
        );
      }
    } else if (containCreateTaskAction(action)) {
      return <LinkedTask action={action} executedActions={executedActions} />;
    }
    return <PlainDescription action={action} />;
  };

  const renderControlFlowActions = (
    action: ActionObject,
    startIndex: number,
    executedActions: ExecutedActionObject[],
  ) => {
    if (action.foreach)
      return (
        <ForeachAction
          action={action}
          startIndex={startIndex}
          executedActions={executedActions}
        />
      );
    if (action.condition)
      return (
        <ConditionAction
          action={action}
          startIndex={startIndex}
          executedActions={executedActions}
        />
      );
  };

  const StyledActionItem = styled(ColumnContainer)({
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    [`& .${badgeClasses.badge}`]: {
      position: 'static',
      transform: 'none',
      marginRight: '4px',
    },
  });

  // TODO: Implement this function
  // actions can only contain one smart action.
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const renderSmartAction = (
    action: ActionObject,
    startIndex: number,
    executedActions: ExecutedActionObject[],
  ): JSX.Element => {
    return <></>;
  };

  return (
    <>
      <StyledActionItem {...props}>
        <ColumnContainer>
          <DescriptionContainer sx={{ gap: 2, alignItems: 'flex-start' }}>
            <RowContainer sx={{ alignItems: 'flex-start' }}>
              <OrbyBadge
                // Using #027A48 hexcode static because it's not defined in Color Palette
                backgroundColor={'#027A48'}
                size={'small'}
                badgeName={`${startIndex + 1}`}
                textColor={OrbyColorPalette['white-0']}
                boxSx={{ width: 30, height: 30, justifyContent: 'center' }}
              />

              {hasFlagKeyword && <FlagKeywords action={action} />}

              {hasDetected && <DuplicateDetected action={action} />}

              {!hasFlagKeyword &&
                !hasDetected &&
                renderDescription(action, executedActions)}
            </RowContainer>
            {imgUrl && (
              <ScreenChip
                sx={{ visibility: 'hidden', marginTop: '9px' }}
                isOpen={isScreenShotOpen}
                label='1 screen'
                onClick={() => setIsScreenShotOpen(!isScreenShotOpen)}
              />
            )}
          </DescriptionContainer>
          {imgUrl && (
            <RowContainer sx={{ gap: 3, marginBottom: '12px' }}>
              <Box sx={{ width: '60px', height: '100%' }} />
              <Collapse in={isScreenShotOpen}>
                <ScreenShot>
                  <img src={imgUrl} width='100%' alt='' />
                </ScreenShot>
              </Collapse>
            </RowContainer>
          )}

          {hasControlFlow &&
            renderControlFlowActions(action!, startIndex + 1, executedActions)}

          {hasSmartAction &&
            renderSmartAction(action!, startIndex + 1, executedActions)}
        </ColumnContainer>
      </StyledActionItem>
    </>
  );
};

export default ActionItem;
