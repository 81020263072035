import React, { FC, memo, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { FieldArray, FormikValues, getIn } from 'formik';
import {
  AttributeType,
  Condition,
  LogicalOperator,
  Operator,
} from 'protos/pb/v1alpha2/connector';
import deleteIcon from '../../../../static/icons/delete.svg';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  getAttributeDataType,
  getAttributeFilters,
  getClassificationLabels,
} from '../../../../utils/helpers';
import EntityFilterSelect from '../../components/EntityFilterSelect';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { workflowService } from '../../../../services/WorkflowService';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import ConditionOperatorSelect from './ConditionOperatorSelect';
import AttributeContent from '../../../../pages/MyWorkflows/components/AttributeContent';
import { ReactComponent as AddButtonIcon } from '../../../../static/icons/add-button-plus-blue.svg';
import { LogicalOperatorGroupButton } from '../../components/LogicalOperatorGroupButton';
import { calculateWidths } from '../../MyWorkflowsHelper';

interface ConditionGroupBoxProps {
  groupIndex: number;
  getFieldProps: any;
  group: any;
  removeGroup: any;
  setFieldValue: any;
  values: any;
  isLast: boolean;
  formik: FormikValues;
}

const ConditionGroupBox: FC<ConditionGroupBoxProps> = ({
  groupIndex,
  getFieldProps,
  group,
  removeGroup,
  setFieldValue,
  values,
  isLast,
  formik,
}) => {
  // TODO: use store to fetch workflow Details after this PR is merged https://github.com/orby-ai-engineering/orby-web-app/pull/930
  const [workflow, setWorkflow] = useState<Workflow | null>(null);

  const fetchWorkflowDetails = async (id: string) => {
    const { response } = await workflowService.getWorkflow(id);
    if (response) {
      setWorkflow(response);
    }
  };

  useEffect(() => {
    if (values?.source_workflow) {
      setWorkflow(null);
      fetchWorkflowDetails(values.source_workflow);
    }
  }, [values?.source_workflow]);

  // This function categorizes conditions into parent-child relationships,
  // ensuring nested child conditions inside parent entities.
  // It also preserves the condition index for easy editing or deletion.
  const getUpdatedConditions = () => {
    return group.conditions.reduce(
      (
        accumulator: {
          parent?: string;
          children?: { attributeType: AttributeType; conditionIndex: number }[];
          conditionIndex?: number;
        }[],
        condition: Condition,
        conditionIndex: number,
      ) => {
        const parent = condition?.attributeType?.parent;
        if (parent) {
          // Check if the parent already exists in the accumulator
          const existingParent = accumulator.find(
            (item) => item.parent === parent,
          );

          if (existingParent) {
            // If the parent exists, add the current condition as a child
            existingParent.children!.push({
              attributeType: condition.attributeType!,
              conditionIndex,
            });
          } else {
            // If the parent doesn't exist, create a new parent with the current condition as a child
            accumulator.push({
              parent,
              children: [
                { attributeType: condition.attributeType!, conditionIndex },
              ],
            });
          }
        } else {
          // If there's no parent, add the condition as a standalone item
          accumulator.push({
            conditionIndex,
          });
        }

        return accumulator;
      },
      [],
    );
  };

  useEffect(() => {
    if (!isLast && !group?.conditions?.length) {
      removeGroup(groupIndex);
    }
  }, [isLast, group]);

  const getClassificationLabelsList = (): {
    value: string;
    label: string;
  }[] => {
    if (!values.source_workflow && !workflow) return [];
    return getClassificationLabels(workflow as Workflow);
  };

  const getAttributes = () => {
    if (!values.source_workflow && !workflow) return [];
    return getAttributeFilters(workflow as Workflow);
  };

  const getAttributeType = (attribute: string) => {
    if (!values.source_workflow && !workflow)
      return EntityDataType.ENTITY_TYPE_NESTED;
    return getAttributeDataType(attribute, workflow as Workflow);
  };

  const getAttributeContent = (
    attribute: string,
    name: string,
    width: number,
  ) => {
    return (
      <AttributeContent
        attributeType={getAttributeType(attribute)}
        name={name}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        getClassificationLabelsList={getClassificationLabelsList}
        formik={formik}
        width={`${width}px`}
      />
    );
  };

  const renderConditionBox = (
    groupIndex: number,
    conditionIndex: number,
    remove: (index: number) => void,
    isParent = false,
  ) => {
    // If for any first condition in the list, logical operator has a value then we set it to undefined because there is no condition
    // above it to add a logical operator. Otherwise this results in wrong condition group object
    if (
      conditionIndex === 0 &&
      values.conditionGroups[groupIndex].conditions[conditionIndex]
        .logical_operator !== undefined
    ) {
      setFieldValue(
        `conditionGroups.${groupIndex}.conditions.${conditionIndex}.logical_operator`,
        undefined,
      );
    }

    let boxWidth = 855 - 32 - 48; // Box width - 32px outer box padding and 48px inner box padding
    if (isParent) {
      boxWidth -= 48;
    }

    const { selectWidth, equalityWidth, inputWidth, deleteWidth } =
      calculateWidths(boxWidth);

    return (
      <>
        {conditionIndex > 0 && (
          <LogicalOperatorGroupButton
            value={
              values.conditionGroups[groupIndex].conditions[conditionIndex]
                .logical_operator
            }
            onClick={(operator: LogicalOperator) => {
              setFieldValue(
                `conditionGroups.${groupIndex}.conditions.${conditionIndex}.logical_operator`,
                operator,
              );
            }}
          />
        )}
        <Box
          key={`conditionGroups.${groupIndex}.conditions.${conditionIndex}`}
          flexDirection={'column'}
          gap={'8px'}
          alignItems={'center'}
        >
          {/* FORM VALUES */}
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            {/* WHEN */}
            <Box width={'39px'}>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-900']}
              >
                When
              </OrbyTypography>
            </Box>

            {/* ENTITY FILTER */}
            <EntityFilterSelect
              width={`${selectWidth}px`}
              formik={formik}
              entityDetails={getAttributes()}
              name={`conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType`}
            />

            {/* CONDITION OPERATOR */}
            <ConditionOperatorSelect
              width={`${equalityWidth}px`}
              formik={formik}
              name={`conditionGroups.${groupIndex}.conditions.${conditionIndex}.operator`}
            />

            {/* CONDITION VALUE */}
            <Box>
              {getAttributeContent(
                getFieldProps(
                  `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
                ).value,
                `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
                inputWidth,
              )}
            </Box>

            {/* DELETE ICON */}
            <Box
              width={deleteWidth}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <IconButton
                aria-label='Delete Group'
                onClick={() => {
                  remove(conditionIndex);
                }}
              >
                <img width={'16px'} src={deleteIcon} alt='Delete' />
              </IconButton>
            </Box>
          </Box>

          {(Boolean(
            getIn(
              formik.touched,
              `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
            ) &&
              getIn(
                formik.errors,
                `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
              ),
          ) ||
            Boolean(
              getIn(
                formik.touched,
                `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
              ) &&
                getIn(
                  formik.errors,
                  `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
                ),
            )) && (
            <Box
              marginTop={'3px'}
              display={'flex'}
              gap={'8px'}
              alignItems={'center'}
            >
              <Box width={'39px'}></Box>

              <Box width={`${selectWidth}px`}>
                {/* ERROR OF CONDITIONAL ATTRIBUTE TYPE (ENTITY FILTER) */}
                {Boolean(
                  getIn(
                    formik.touched,
                    `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
                  ) &&
                    getIn(
                      formik.errors,
                      `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
                    ),
                ) && (
                  <OrbyTypography color={OrbyColorPalette['error-500']}>
                    {
                      getIn(
                        formik.errors,
                        `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
                      ) as string
                    }
                  </OrbyTypography>
                )}
              </Box>

              <Box width={`${equalityWidth}px`}></Box>

              {/* CONDITION VALUE */}
              <Box width={`${inputWidth}px`}>
                {/* ERROR OF CONDITIONAL VALUE */}
                {Boolean(
                  getIn(
                    formik.touched,
                    `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
                  ) &&
                    getIn(
                      formik.errors,
                      `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
                    ),
                ) && (
                  <OrbyTypography color={OrbyColorPalette['error-500']}>
                    {
                      getIn(
                        formik.errors,
                        `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
                      ) as string
                    }
                  </OrbyTypography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <Box key={groupIndex}>
      <FieldArray name={`conditionGroups.${groupIndex}.conditions`}>
        {({ push, remove }) => (
          <Box key={`conditionGroups.${groupIndex}`} width={'100%'}>
            {/* CONDITION GROUP LOGICAL OPERATOR */}

            {groupIndex > 0 && (
              <Box height={'56px'} alignItems={'center'} display={'flex'}>
                <LogicalOperatorGroupButton
                  value={values.conditionGroups[groupIndex].logical_operator}
                  onClick={(operator: LogicalOperator) => {
                    setFieldValue(
                      `conditionGroups.${groupIndex}.logical_operator`,
                      operator,
                    );
                  }}
                />
              </Box>
            )}
            {/* CONDITION BOXES */}
            <Box
              minHeight={'168px'}
              bgcolor={OrbyColorPalette['grey-50']}
              borderRadius={'8px'}
              padding={'24px'}
              width={'855px'}
            >
              <Box paddingBottom={'16px'}>
                <OrbyTypography
                  size='md'
                  weight='semibold'
                  color={OrbyColorPalette['grey-900']}
                >
                  Condition group :
                </OrbyTypography>
              </Box>

              {/* CONDITION BOX */}
              {getUpdatedConditions().map(
                ({
                  parent,
                  conditionIndex,
                  children,
                }: {
                  parent: string;
                  conditionIndex: number;
                  children: {
                    attributeType: AttributeType;
                    conditionIndex: number;
                  }[];
                }) => {
                  if (parent) {
                    return (
                      <Box
                        padding={'24px'}
                        borderRadius={'8px'}
                        border={`1px solid ${OrbyColorPalette['grey-200']}`}
                        key={parent}
                        bgcolor={OrbyColorPalette['blue-50']}
                        marginTop={'12px'}
                      >
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          paddingBottom={'10px'}
                        >
                          <OrbyTypography
                            size='sm'
                            weight='medium'
                            color={OrbyColorPalette['grey-900']}
                          >
                            {parent}
                          </OrbyTypography>
                          <IconButton
                            aria-label='Delete parent'
                            size='small'
                            sx={{ marginTop: '10px' }}
                            onClick={() => {
                              // Deleting all the related children when a parent entity is deleted.
                              setFieldValue(
                                `conditionGroups.${groupIndex}.conditions`,
                                group.conditions.filter(
                                  (c: Condition) =>
                                    c.attributeType!.parent !== parent,
                                ),
                              );
                            }}
                          >
                            <img src={deleteIcon} alt='Delete' />
                          </IconButton>
                        </Box>
                        {children.map(
                          ({ conditionIndex }: { conditionIndex: number }) => {
                            return renderConditionBox(
                              groupIndex,
                              conditionIndex,
                              remove,
                              true,
                            );
                          },
                        )}
                      </Box>
                    );
                  } else {
                    return renderConditionBox(
                      groupIndex,
                      conditionIndex,
                      remove,
                    );
                  }
                },
              )}

              {/* ADD CONDITION */}
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                marginTop={'16px'}
              >
                <IconButton
                  sx={{
                    borderRadius: '6px',
                    padding: 0,
                  }}
                  onClick={() => {
                    push({
                      attributeType: {
                        name: '',
                        parent: '',
                      },
                      operator: Operator.GREATER_THAN,
                      value: '',
                      logical_operator: LogicalOperator.AND,
                    });
                  }}
                >
                  <AddButtonIcon style={{ marginRight: '5px' }} />
                  <OrbyTypography
                    color={OrbyColorPalette['blue-700']}
                    size='sm'
                    weight={'semibold'}
                  >
                    Add condition
                  </OrbyTypography>
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

export default memo(ConditionGroupBox);
