import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, ListSubheader } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyTextField,
  OrbyTypography,
  OrbySelect,
  OrbyMenuItem,
} from 'orby-ui/src';

import { Filter, ELLIPSIS_STYLE } from '../../../utils/constants';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ALL_TASK_VALUE, UNASSIGNED_TASK_VALUE } from '../TaskHelpers';
import _ from 'lodash';
import { GridSearchIcon } from '@mui/x-data-grid';
import {
  userFiltersErrorSelector,
  userFiltersLoadingSelector,
  userFiltersSelector,
} from '../../../redux/selectors/filter_options.selectors';
import { User } from 'protos/pb/v1alpha1/user';
import { toastService } from '../../../services/ToastService';
import {
  getUserFiltersAction,
  getUserFiltersErrorAction,
} from '../../../redux/actions/filter_options.action';

export const VALUE = 'value';
export const LABEL = 'label';
export interface Props {
  selectedUsers: Array<string>;
  setSelectedUsers: (data: Array<string>) => void;
  user: User;
  isAdminView: boolean;
  isShowAssignedToMe?: boolean;
  isShowUnassignedOnly?: boolean;
  allValue?: Record<string, string>;
  userFilterMappedValue?: 'value' | 'label';
  width?: string;
  menuWidth?: string;
}

const UserFilter: React.FC<Props> = ({
  selectedUsers,
  setSelectedUsers,
  user,
  isAdminView,
  isShowAssignedToMe = true,
  isShowUnassignedOnly = true,
  width = '160px',
  menuWidth = '320px',
  allValue = ALL_TASK_VALUE,
  userFilterMappedValue = LABEL,
}) => {
  const dispatch = useDispatch();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const userFilters = useSelector(userFiltersSelector);
  const userFiltersError = useSelector(userFiltersErrorSelector);
  const userFiltersLoading = useSelector(userFiltersLoadingSelector);
  const loggedInUser = useSelector(loggedInUserSelector) ?? {};

  // WORKFLOWS OPTIONS
  const [userOptions, setUserOptions] = useState<Filter[]>([]);
  const [options, setOptions] = useState<Filter[]>([]);

  const ALL_VALUE = allValue;
  /**
   * HANDLE WORKFLOW FILTER SEARCH
   */
  const handleWorkflowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = userOptions.filter(
      (opt) => opt.label.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  /**
   * GET RENDER VALUE IF ONLY ONE ELEMENT IS SELECTED
   */
  const getRenderValueIfSingle = (value: string) => {
    if (value.toLowerCase() === UNASSIGNED_TASK_VALUE.value.toLowerCase()) {
      return UNASSIGNED_TASK_VALUE.label;
    } else if (value.toLowerCase() === user.email!.toLowerCase()) {
      return isShowAssignedToMe ? 'Assign to me' : user.email;
    }
    return value;
  };

  /**
   * HANDLE RENDER VALUE
   */
  const handleRenderValue = () => {
    const noOfUsers = selectedUsers.length;
    if (noOfUsers === 0) {
      return (
        <Box overflow={'hidden'} title={ALL_VALUE.label}>
          {renderLabel(ALL_VALUE.label)}
        </Box>
      );
    } else {
      if (selectedUsers.length === 1) {
        const label =
          userFilterMappedValue === VALUE
            ? userFilters.find((f) => f.value === selectedUsers[0])?.label ||
              selectedUsers[0]
            : selectedUsers[0];
        return (
          <Box
            overflow={'hidden'}
            display={'flex'}
            gap={'4px'}
            alignItems={'center'}
            title={getRenderValueIfSingle(label)}
          >
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              sx={{
                paddingRight: '10px',
                ...ELLIPSIS_STYLE,
              }}
            >
              {getRenderValueIfSingle(label)}
            </OrbyTypography>
          </Box>
        );
      } else {
        const value = (
          <Box
            overflow={'hidden'}
            display={'flex'}
            gap={'4px'}
            alignItems={'center'}
          >
            <OrbyTypography
              size='sm'
              weight='semibold'
              color={OrbyColorPalette['blue-700']}
            >
              {noOfUsers}
            </OrbyTypography>
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
              sx={{
                paddingRight: '10px',
                ...ELLIPSIS_STYLE,
              }}
            >
              selected users
            </OrbyTypography>
          </Box>
        );
        return (
          <Box overflow={'hidden'} title={`${noOfUsers} users selected`}>
            {value}
          </Box>
        );
      }
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <OrbyTypography
        size='sm'
        color={OrbyColorPalette['grey-900']}
        weight={'regular'}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </OrbyTypography>
    );
  };

  /**
   * Set Filter Options
   */
  const setFilterOptions = () => {
    const userOptions = userFilters
      .map((w: Filter) => ({
        value: userFilterMappedValue === VALUE ? w.value : w.label,
        label: w.label,
      }))
      .filter((user) => {
        if (isShowAssignedToMe) {
          return user.label !== loggedInUser.email;
        } else {
          return true;
        }
      });
    setUserOptions(userOptions);
    setOptions(userOptions);
  };

  /**
   * USE EFFECT
   */
  useEffect(() => {
    if (userFilters) {
      setFilterOptions();
    }
  }, [userFilters]);

  /**
   * FETCH USERS
   */
  useEffect(() => {
    if (userFiltersError) {
      toastService.showError(userFiltersError.message, {
        position: 'top-right',
      });
      dispatch(getUserFiltersErrorAction());
    }
  }, [userFiltersError]);

  useEffect(() => {
    if (isAdminView && selectedOrgInfo) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
  }, [selectedOrgInfo]);

  return (
    <>
      <OrbySelect
        onClose={() => {
          setFilterOptions();
        }}
        renderValue={handleRenderValue}
        multiple={true}
        value={
          selectedUsers.length === 0
            ? [ALL_VALUE.value]
            : [...selectedUsers.values()]
        }
        width={selectedUsers.length >= 2 ? '200px' : width}
        onChange={(event) => {
          const value = event.target.value as Array<string>;
          if (value) {
            if (
              value[value.length - 1] === ALL_VALUE.value ||
              value.length === 0
            ) {
              setSelectedUsers([]);
            } else {
              // REMOVE THE DEFAULT VALUE
              let filteredSelectedUsers: Array<string> = [];
              if (isAdminView) {
                // IN CASE OF ADMIN IF ANY OTHER VALUE IS SELECTED REMOVE 'ALL' FROM THE OPTIONS
                filteredSelectedUsers = value.filter(
                  (o) => o !== ALL_VALUE.value,
                );
              } else {
                // IN CASE OF USERS ONLY ONE VALUE CAN BE SELECTED FROM THE OPTIONS
                filteredSelectedUsers = [value[value.length - 1]];
              }

              setSelectedUsers(filteredSelectedUsers);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={menuWidth}
          key={ALL_VALUE.value}
          title={ALL_VALUE.label}
          value={ALL_VALUE.value}
          isSelected={selectedUsers.length === 0}
        />
        {isShowUnassignedOnly && (
          <OrbyMenuItem
            width={menuWidth}
            key={UNASSIGNED_TASK_VALUE.value}
            title={UNASSIGNED_TASK_VALUE.label}
            value={UNASSIGNED_TASK_VALUE.value}
            isSelected={selectedUsers.includes(UNASSIGNED_TASK_VALUE.value)}
          />
        )}
        {isShowAssignedToMe && (
          <OrbyMenuItem
            width={menuWidth}
            key={user.email!}
            title={'Assigned to me'}
            value={user.email!}
            isSelected={selectedUsers.includes(user.email!)}
          />
        )}
        {isAdminView && (
          <ListSubheader
            sx={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <OrbyTextField
              variant='flat'
              onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              width={'100%'}
              tabIndex={0}
              name={'search-email'}
              disabled={false}
              placeholder='Search email'
              startAdornment={
                <GridSearchIcon
                  sx={{ color: OrbyColorPalette['grey-900'] }}
                  fontSize='medium'
                />
              }
              onChange={_.debounce((event) => handleWorkflowSearch(event), 300)}
            />
          </ListSubheader>
        )}
        {isAdminView &&
          (userFiltersLoading ? (
            <Box
              display={'flex'}
              padding={'10px 0px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress
                sx={{ color: OrbyColorPalette['purple-900'] }}
                size={'30px'}
              />
            </Box>
          ) : options.length > 0 ? (
            options.map((option) => {
              return (
                <OrbyMenuItem
                  width={menuWidth}
                  key={option.value}
                  title={option.label}
                  value={option.value}
                  isSelected={selectedUsers.includes(option.value)}
                />
              );
            })
          ) : (
            <Box
              display={'flex'}
              padding={'10px 0px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
              >
                No users found
              </OrbyTypography>
            </Box>
          ))}
      </OrbySelect>
    </>
  );
};

export default React.memo(UserFilter);
