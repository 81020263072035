import { CheckOutlined, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBaseActions,
  Fade,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import CustomTypography from '../../../../../../../components/CustomTypography';
import { IOSSwitch } from '../../../../../../../components/switch/IosType';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmTableEntitiesInfoAction,
  clearAllTableEntitiesInfoAction,
} from '../../../../../../../redux/actions/review_task.action';
import {
  getSelectedReviewFilterSectionSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoSelector,
  selectedTaskEntityInfoSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import {
  getLastEntityForParent,
  shiftToNextEntity,
} from '../../../../../../../utils/ReviewTaskUtils';
import { EntityFilter } from '../../../../../../../utils/constants';

interface Props {
  isMinWidthReached: boolean;
  showNormalizedValues: boolean;
  setShowNormalizedValues: (x: boolean) => void;
}

const TableModalFooter: React.FC<Props> = ({
  isMinWidthReached,
  showNormalizedValues,
  setShowNormalizedValues,
}) => {
  const dispatch = useDispatch();
  const tableEntities = useSelector(selectedTableEntitiesInfoSelector);
  const actionRef = useRef<ButtonBaseActions | null>(null);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const entitiesInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isErrorPresent = useMemo(() => {
    return !!tableEntities?.length && tableEntities.some((e) => !!e.error);
  }, [tableEntities]);

  useEffect(() => {
    // focus the `This is correct` button initially when table modal opens
    actionRef.current?.focusVisible();
  }, [selectedParentEntityInfo?.type]);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      padding={'16px'}
    >
      {isMinWidthReached ? (
        <>
          <IconButton
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            }}
            sx={{
              width: '2rem',
              height: '2rem',
              border: '1px solid #D0D5DD',
            }}
          >
            <MoreVert fontSize='small' sx={{ color: '#000000' }} />
          </IconButton>

          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              e.stopPropagation();
              handleClose();
            }}
            TransitionComponent={Fade}
            sx={{
              '& .MuiPopover-paper': {
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                dispatch(clearAllTableEntitiesInfoAction());
                handleClose();
              }}
            >
              <CustomTypography sx={{ color: '#000000' }}>
                Clear all
              </CustomTypography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setShowNormalizedValues(!showNormalizedValues);
                handleClose();
              }}
            >
              <CustomTypography sx={{ color: '#000000' }}>
                {showNormalizedValues ? 'Hide' : 'Show'} Normalized Value
              </CustomTypography>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Box display={'flex'} gap={1}>
          <IOSSwitch
            checked={showNormalizedValues}
            onChange={() => setShowNormalizedValues(!showNormalizedValues)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowNormalizedValues(!showNormalizedValues);
              }
            }}
          />
          <OrbyTypography color={OrbyColorPalette['grey-500']}>
            Show Normalized Value
          </OrbyTypography>
        </Box>
      )}
      <Box
        display={'flex'}
        gap={'16px'}
        flexGrow={1}
        justifyContent={'flex-end'}
      >
        {!isMinWidthReached && (
          <Button
            onClick={() => {
              dispatch(clearAllTableEntitiesInfoAction());
            }}
            variant='outlined'
            sx={{
              ':disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important',
                opacity: 0.2,
              },
              borderRadius: '40px',
              borderColor: '#D0D5DD',
              maxWidth: '102px',
              flexGrow: 1,
              flexShrink: 1,
              padding: '4px 14px',
            }}
          >
            <OrbyTypography
              weight='semibold'
              color={OrbyColorPalette['grey-700']}
            >
              Clear all
            </OrbyTypography>
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(confirmTableEntitiesInfoAction());
            const lastEntity = getLastEntityForParent(
              entitiesInfoMap,
              selectedParentEntityInfo?.type as string,
            );
            if (lastEntity) {
              shiftToNextEntity(
                /* The reason we are passing the last child entity  
                   is that we want to skip all other child entities associated with this table. 
                   These child entities are already included in this table, meaning we don't 
                   need to reselect them as next entity
  
                   eg: 
                      1.  Simple/Table Entity       -> Previously Selected
  
                      2.  Child Entity 1          ┑ 
                      3.  Child Entity 2          |
                      4.  Child Entity 3          | -> Parent Entity -> Currently Selected
                      5.  Child Entity 4          |
                      6.  Child Entity 5          ┚ -> Last Entity 
   
                      7.  Simple/table Entity       -> To be selected Next
                */
                lastEntity,
                entitiesInfoMap,
                selectedReviewFilterSection as EntityFilter,
              );
            }
          }}
          variant='contained'
          startIcon={
            <CheckOutlined
              fontSize='small'
              sx={{ color: `${OrbyColorPalette['white-0']}!important` }}
            />
          }
          disabled={isErrorPresent}
          action={(actions) => (actionRef.current = actions)}
          sx={{
            ':disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
              backgroundColor: '#0500FF',
              opacity: 0.2,
            },
            borderRadius: '40px',
            backgroundColor: '#0500FF',
            maxWidth: '172px',
            flexGrow: 1,
            flexShrink: 1,
            padding: '4px 14px',
          }}
        >
          <OrbyTypography weight='semibold' color={OrbyColorPalette['white-0']}>
            This is correct
          </OrbyTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(TableModalFooter);
