import { Box, TextField } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { SelectedExtractedField } from '../../../../utils/constants';

interface SearchFieldProps {
  selectedValues: SelectedExtractedField[];
  dropdownValues: SelectedExtractedField[];
  handleAdd: (e: SelectedExtractedField[]) => void;
  isChildDropdown?: boolean;
  searchStyle: any;
  className: string;
  ariaDescribeBy: string;
  ariaLabel: string;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dropdownWidth: string;
  height: string;
  top: string;
  inputProps?: any;
}

const SearchNestedEntities: FC<SearchFieldProps> = (props) => {
  const {
    selectedValues,
    dropdownValues,
    handleAdd,
    searchStyle,
    className,
    ariaLabel,
    ariaDescribeBy,
    placeholder,
    disabled,
    autoFocus,
    dropdownWidth,
    height,
    top,
    inputProps,
    isChildDropdown,
  } = props;
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchListState, setSearchListState] =
    useState<SelectedExtractedField[]>(dropdownValues);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        setSearchValue('');
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  /**
   * The following code is to prevent the enter key from
   * submitting the form and also prevent default enter key behavior
   * when the user is typing in the search field and hits enter.
   */
  useEffect(() => {
    function handleEnterKey(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        if (showSuggestions) {
          event.preventDefault();
        }
      }
    }
    document.addEventListener('keydown', handleEnterKey);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleEnterKey);
    };
  });

  useEffect(() => {
    setSearchListState(
      dropdownValues.filter((p) => !selectedValues.includes(p)),
    );
  }, [selectedValues]);

  const getAllParentExtractedFields = () => {
    const parents: SelectedExtractedField[] = [];
    for (const entity of dropdownValues) {
      const parent = dropdownValues.find((p) => p.parent === entity.entityName);
      if (parent && !entity.parent && !parents.includes(parent)) {
        parents.push(entity);
      }
    }
    return parents;
  };

  const getAllNormalExtractedFields = () => {
    const normalFields: SelectedExtractedField[] = [];
    for (const entity of searchListState) {
      const isParent = searchListState.find(
        (p) => p.parent === entity.entityName,
      );
      if (!isParent && !entity.parent) {
        normalFields.push(entity);
      }
    }
    return normalFields;
  };

  return (
    <Box>
      <Box position={'relative'} width={'97%'} maxWidth={'97%'}>
        <TextField
          role='textbox'
          className={className}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribeBy}
          style={{ ...searchStyle }}
          value={searchValue}
          placeholder={placeholder}
          disabled={disabled}
          InputProps={{ ...inputProps }}
          autoFocus={autoFocus}
          onClick={() => {
            setShowSuggestions(true);
          }}
          onChange={(event) => {
            setSearchValue(event.target.value);
            setSearchListState(
              dropdownValues.filter(
                (val: SelectedExtractedField) =>
                  val?.entityName
                    .toLowerCase()
                    .includes(event.target.value?.toLowerCase()) &&
                  !selectedValues.includes(val),
              ),
            );
          }}
          sx={{
            marginTop: '8px',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              height,
              background: '#fff',
            },
            '& .MuiOutlinedInput-input': {
              lineHeight: '16px',
              fontSize: '12px',
              fontWeight: 400,
              WebkitTextFillColor: '#000000 !important',
            },
          }}
        />
        {((searchValue != '' && searchListState.length > 0) ||
          showSuggestions) && (
          <Box
            maxHeight={'200px'}
            overflow={'auto'}
            ref={searchFieldRef}
            borderRadius={'4px'}
            sx={{ background: '#fff' }}
            position={'absolute'}
            top={top}
            width={dropdownWidth}
            zIndex={1}
            border={'1px solid #CCCCCC'}
            display={'block'}
          >
            {searchListState.length > 0 ? (
              <>
                {isChildDropdown &&
                  searchListState.map((child) => (
                    <Box
                      onClick={() => {
                        handleAdd([child]);
                        setSearchValue('');
                        setShowSuggestions(false);
                      }}
                      key={child.entityName}
                      style={{
                        fontSize: '12px',
                        padding: '6px 32px',
                        cursor: 'pointer',
                      }}
                    >
                      {child.entityName}
                    </Box>
                  ))}
                {getAllParentExtractedFields().map((parent) => {
                  const childEntities = dropdownValues.filter(
                    (e) => e.parent === parent.entityName,
                  );
                  const filteredEntities = childEntities.filter((c) =>
                    searchListState.includes(c),
                  );

                  if (filteredEntities.length === 0)
                    return <Box key={parent.entityName} />;

                  return (
                    <>
                      <Box
                        onClick={() => {
                          // Adding all child entities with parent
                          const entitiesToAdd = [...filteredEntities];
                          // Add parent only when it is not present in selectedValues
                          if (!selectedValues.includes(parent)) {
                            entitiesToAdd.push(parent);
                          }
                          handleAdd(entitiesToAdd);
                          setSearchValue('');
                          setShowSuggestions(false);
                        }}
                        key={parent.entityName}
                        style={{
                          fontSize: '12px',
                          padding: '6px 12px',
                          cursor: 'pointer',
                        }}
                      >
                        {parent.entityName}
                      </Box>
                      {filteredEntities.map((child) => (
                        <Box
                          onClick={() => {
                            const entitiesToAdd = [child];
                            // Add parent with the child if it is not already selected and present in search list.
                            if (
                              !selectedValues.includes(parent) &&
                              dropdownValues.includes(parent)
                            ) {
                              entitiesToAdd.push(parent);
                            }
                            handleAdd(entitiesToAdd);
                            setSearchValue('');
                            setShowSuggestions(false);
                          }}
                          key={child.entityName}
                          style={{
                            fontSize: '12px',
                            padding: '6px 32px',
                            cursor: 'pointer',
                          }}
                        >
                          {child.entityName}
                        </Box>
                      ))}
                    </>
                  );
                })}
                {getAllNormalExtractedFields().map((entity) => {
                  return (
                    <Box
                      onClick={() => {
                        handleAdd([entity]);
                        setSearchValue('');
                        setShowSuggestions(false);
                      }}
                      key={entity.entityName}
                      style={{
                        fontSize: '12px',
                        padding: '6px 12px',
                        cursor: 'pointer',
                      }}
                    >
                      {entity.entityName}
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box
                textAlign={'center'}
                sx={{
                  cursor: 'not-allowed',
                  fontSize: '12px',
                  padding: '10px 20px',
                }}
              >
                No results found
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchNestedEntities);
