import { Box, Divider } from '@mui/material';
import React, { FC, memo } from 'react';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  count: string | number | undefined;
  title: string;
  showBorder?: boolean;
}

const SummaryBox: FC<Props> = ({ count, title, showBorder = true }) => {
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <OrbyTypography weight='semibold' size='display-sm'>
          {count ?? '0'}
        </OrbyTypography>
        <OrbyTypography
          weight='medium'
          color={OrbyColorPalette['grey-600']}
          sx={{
            paddingTop: '8px',
          }}
        >
          {title}
        </OrbyTypography>
      </Box>
      {showBorder && <Divider orientation='vertical' />}
    </>
  );
};

export default memo(SummaryBox);
