import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, ListSubheader, Menu } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  OrbyColorPalette,
  OrbyTextField,
  OrbyTypography,
  OrbyMenuItem,
} from 'orby-ui/src';

import { Filter } from '../../../utils/constants';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { GridSearchIcon } from '@mui/x-data-grid';
import {
  userFiltersErrorSelector,
  userFiltersLoadingSelector,
  userFiltersSelector,
} from '../../../redux/selectors/filter_options.selectors';
import { User } from 'protos/pb/v1alpha1/user';
import { ALL_USERS_VALUE } from '../ExecutionHelpers';
import {
  getUserFiltersAction,
  getUserFiltersErrorAction,
} from '../../../redux/actions/filter_options.action';
import { toastService } from '../../../services/ToastService';

export interface Props {
  selectedUsers: Array<string>;
  setSelectedUsers: (data: Array<string>) => void;
  user: User;
  isAdminView: boolean;
}

const ExecutionUserFilter: React.FC<Props> = ({
  selectedUsers,
  setSelectedUsers,
  user,
  isAdminView,
}) => {
  const dispatch = useDispatch();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const userFilters = useSelector(userFiltersSelector);
  const userFiltersError = useSelector(userFiltersErrorSelector);
  const userFiltersLoading = useSelector(userFiltersLoadingSelector);
  const loggedInUser = useSelector(loggedInUserSelector) ?? {};

  // USERS OPTIONS
  const [userOptions, setUserOptions] = useState<Filter[]>([]);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [options, setOptions] = useState<Filter[]>([]);

  /**
   * HANDLE USER FILTER SEARCH
   */
  const handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = userOptions.filter(
      (opt) => opt.label.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  /**
   * HANDLE RENDER VALUE
   */
  const handleRenderValue = () => {
    if (!selectedUsers.length) {
      return ALL_USERS_VALUE.label;
    } else {
      return `${selectedUsers.length} ${selectedUsers.length === 1 ? 'user' : 'users'} selected`;
    }
  };

  /**
   * Set Filter Options
   */
  const setFilterOptions = () => {
    const userOptions = userFilters
      .map((w) => ({
        value: w.value.replace('users/', ''),
        label: w.label,
      }))
      .filter((user) => user.label !== loggedInUser.email);
    setUserOptions(userOptions);
    setOptions(userOptions);
  };

  /**
   * HANDLE USERS FILTER SELECT
   */
  const handleUsersSelectChange = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const value = event.currentTarget.dataset.myValue || '';
    if (!value || value === ALL_USERS_VALUE.value) {
      setSelectedUsers([]);
    } else {
      /**
       * CHECK IF THE STATUS ALREADY EXISTS IN THE FILTER
       */
      if (selectedUsers.includes(value)) {
        setSelectedUsers(selectedUsers.filter((s) => s !== value));
      } else {
        setSelectedUsers([...selectedUsers, value]);
      }
    }
  };

  /**
   * USE EFFECT
   */
  useEffect(() => {
    if (userFilters) {
      setFilterOptions();
    }
  }, [userFilters]);

  /**
   * FETCH USERS
   */
  useEffect(() => {
    if (userFiltersError) {
      toastService.showError(userFiltersError.message, {
        position: 'top-right',
      });
      dispatch(getUserFiltersErrorAction());
    }
  }, [userFiltersError]);

  useEffect(() => {
    if (isAdminView && selectedOrgInfo) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
  }, [selectedOrgInfo]);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        tabIndex={0}
        role='button'
        sx={{
          cursor: 'pointer',
        }}
        onClick={(e) => {
          setStatusAnchorEl(e.currentTarget);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setStatusAnchorEl(e.currentTarget);
          }
        }}
      >
        <OrbyTypography
          color={OrbyColorPalette['blue-900']}
          size={'sm'}
          weight={'semibold'}
        >
          {handleRenderValue()}
        </OrbyTypography>
        {statusAnchorEl ? (
          <ArrowDropUpIcon
            sx={{ color: OrbyColorPalette['blue-900'] }}
            fontSize='medium'
          />
        ) : (
          <ArrowDropDownIcon
            sx={{ color: OrbyColorPalette['blue-900'] }}
            fontSize='medium'
          />
        )}
      </Box>

      <Menu
        id='basic-menu'
        anchorEl={statusAnchorEl}
        autoFocus={false}
        open={!!statusAnchorEl}
        onClose={() => {
          setStatusAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            border: `1px solid ${OrbyColorPalette['grey-100']}`,
            boxShadow:
              '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
            overflow: 'visible',
            borderRadius: '8px',
            minWidth: '208px',
            maxHeight: '320px',
            overflowY: 'auto',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <OrbyMenuItem
          width='320px'
          key={ALL_USERS_VALUE.value}
          title={ALL_USERS_VALUE.label}
          value={ALL_USERS_VALUE.value}
          onClick={handleUsersSelectChange}
          isSelected={selectedUsers.length === 0}
        />
        <OrbyMenuItem
          width='320px'
          key={user.email!}
          title={'Executions by me'}
          value={user.id!}
          onClick={handleUsersSelectChange}
          isSelected={selectedUsers.includes(user.id!)}
        />
        {/* SEARCH FILTER */}
        {isAdminView && (
          <ListSubheader
            sx={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <OrbyTextField
              variant='flat'
              onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              width={'100%'}
              tabIndex={0}
              name={'search-email'}
              disabled={false}
              placeholder='Search email'
              startAdornment={
                <GridSearchIcon
                  sx={{ color: OrbyColorPalette['grey-900'] }}
                  fontSize='medium'
                />
              }
              onChange={_.debounce((event) => handleUserSearch(event), 300)}
            />
          </ListSubheader>
        )}
        {isAdminView &&
          (userFiltersLoading ? (
            <Box
              display={'flex'}
              padding={'10px 0px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress
                sx={{ color: OrbyColorPalette['purple-900'] }}
                size={'30px'}
              />
            </Box>
          ) : options.length > 0 ? (
            options.map((option) => {
              return (
                <OrbyMenuItem
                  width='320px'
                  onClick={handleUsersSelectChange}
                  key={option.value}
                  title={option.label}
                  value={option.value}
                  isSelected={selectedUsers.includes(option.value)}
                />
              );
            })
          ) : (
            <Box
              display={'flex'}
              padding={'10px 0px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
              >
                No users found
              </OrbyTypography>
            </Box>
          ))}
      </Menu>
    </>
  );
};

export default React.memo(ExecutionUserFilter);
