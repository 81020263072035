import { Box } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../../../components/CustomTypography';
import React from 'react';

interface ReviewFilterBoxProps {
  borderColor: string;
  label: string;
  value?: string;
  onClick: () => void;
  selected: boolean;
  style?: any;
  valueStyle?: any;
  labelStyle?: any;
}

const ReviewFilterBox: React.FC<ReviewFilterBoxProps> = ({
  borderColor,
  label,
  value,
  selected,
  style,
  onClick,
  valueStyle,
  labelStyle,
}) => {
  return (
    <Box
      role='button'
      tabIndex={0}
      onClick={onClick}
      title={label}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      marginTop={'6px'}
      paddingY={'8px'}
      paddingX={'0px'}
      sx={{
        width: 'auto',
        borderRadius: '4px',
        cursor: 'pointer',
        ...style,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {value && (
          <CustomTypography
            sx={{ ...valueStyle }}
            typographyType={TypographyType.Header3}
          >
            {value}
          </CustomTypography>
        )}
        <Box
          sx={{
            marginTop: '5px',
            paddingX: '2px',
            borderBottom: `2px solid ${selected ? borderColor : 'transparent'}`,
          }}
        >
          <CustomTypography
            color={'#101828'}
            sx={{ ...labelStyle }}
            typographyType={TypographyType.Label}
          >
            {label}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ReviewFilterBox);
