import React, { memo } from 'react';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';
import { useSelector } from 'react-redux';
import {
  getFeatureFlagsForOrgAndUserLoadingSelector,
  getFeatureFlagsForOrgAndUserSelector,
} from '../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../utils/constants';
import { Box, CircularProgress } from '@mui/material';

import MyWorkflows from './MyWorkflows';
import WorkflowTable from '../../components/workflow-table/WorkflowTable';

interface Props {
  sideDrawerCollapse: boolean;
}

const WorkflowHome: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const featureFlagsLoading = useSelector(
    getFeatureFlagsForOrgAndUserLoadingSelector,
  );
  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  if (featureFlagsLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vH'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isWebAppIntegrationEnabled ? (
    <MyWorkflows sideDrawerCollapse={sideDrawerCollapse} />
  ) : (
    <WorkflowTable />
  );
};

export default memo(WorkflowHome);
