import { KeyboardArrowDownRounded, OutputOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { Box, Divider, Fade, IconButton, Menu } from '@mui/material';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../redux/actions/auth.action';
import { storageService } from '../../services/StorageService';
import { setSelectedOrgInfo } from '../../redux/actions/user.action';
import DrawerUserCard from './DrawerUserCard';
import { OrbyColorPalette, OrbyMenuItem, OrbyTypography } from 'orby-ui/src';
import { useNavigate } from 'react-router-dom';
import { DRAWER_WIDTH_EXPANDED, ELLIPSIS_STYLE } from '../../utils/constants';

interface DrawerFooterProps {
  collapse: boolean;
}

const IntegratedDrawerFooter: FC<DrawerFooterProps> = ({ collapse }) => {
  const navigate = useNavigate();
  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenLogout = () => {
    setAnchorEl(document.getElementById('drawer-footer'));
  };

  const handleCloseLogout = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const token = await storageService.getStoredToken();
    if (token) {
      const req: LogoutRequest = {};
      req.name = token.sessionId;
      dispatch(logoutAction(req));
    }
  };

  return (
    <Box
      width='100%'
      display={'flex'}
      flexDirection={'column'}
      position={'absolute'}
      bottom={0}
      padding={'12px 24px 24px 24px'}
    >
      <Box>
        {/* USER CARD IMAGE */}
        <Box title={user?.email}>
          <DrawerUserCard
            email={user?.email || ''}
            fullName={user?.fullName}
            imageUrl={user?.profileImageUrl}
            collapse={collapse}
          />
        </Box>
        {/* HR */}
        <Divider
          id='drawer-footer'
          sx={{
            marginTop: '14px',
            marginBottom: '7px',
            width: '100%',
            border: `1px solid ${OrbyColorPalette['grey-600']}`,
          }}
        />
        {/* USER INFO */}
        <Box>
          <IconButton
            onClick={handleOpenLogout}
            aria-label='User Menu'
            sx={{
              padding: '0px',
              width: '100%',
              display: 'flex',
              maxHeight: '24px',
              justifyContent: collapse ? 'center' : 'space-between',
            }}
          >
            <Box
              display={collapse ? 'none' : 'flex'}
              sx={{
                transition: 'all 0.1s ease',
                opacity: collapse ? 0 : 1,
                pointerEvents: collapse ? 'none' : 'unset',
                maxWidth: `${DRAWER_WIDTH_EXPANDED - 100}px`,
              }}
            >
              <OrbyTypography
                color={OrbyColorPalette['white-0']}
                sx={{
                  textWrap: 'nowrap',
                  ...ELLIPSIS_STYLE,
                }}
              >
                {selectedOrgInfo?.orgDisplayName}
              </OrbyTypography>
            </Box>
            <KeyboardArrowDownRounded
              sx={{
                color: OrbyColorPalette['white-0'],
                width: '24px',
                height: '24px',
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseLogout}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            '&.MuiMenu-paper': {
              left: '0px !important',
              width: `${DRAWER_WIDTH_EXPANDED}px !important`,
              paddingTop: '10px',
            },
          },
        }}
      >
        <OrbyTypography
          sx={{ marginBottom: '12px', marginLeft: '12px' }}
          color={OrbyColorPalette['grey-600']}
        >
          Organizations
        </OrbyTypography>
        {user?.orgInfos?.map((org, index) => (
          <OrbyMenuItem
            width='100%'
            key={index}
            isSelected={
              selectedOrgInfo?.orgResourceName === org.orgResourceName
            }
            onClick={() => {
              dispatch(setSelectedOrgInfo(org));
              handleCloseLogout();
              setTimeout(() => {
                navigate({});
              }, 100);
            }}
            title={org.orgDisplayName ?? ''}
            value={org.orgDisplayName ?? ''}
          />
        ))}
        <Divider />
        <OrbyMenuItem
          width='100%'
          onClick={() => {
            handleLogout();
          }}
          key={'logout'}
          icon={<OutputOutlined fontSize='small' />}
          title={'Log out'}
          value={'logout'}
        />
      </Menu>
    </Box>
  );
};

export default memo(IntegratedDrawerFooter);
