import { CheckCircleRounded, WarningRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React, { FC, memo } from 'react';
import { ApplicationName } from '../../../utils/protos/enums';

interface ConnectionMessageProps {
  connected: boolean;
  application: string;
  onConnect?: () => void;
  edit?: boolean;
}

const ConnectionMessage: FC<ConnectionMessageProps> = ({
  connected,
  application,
  onConnect,
  edit = false,
}) => {
  return (
    <Box
      bgcolor={
        !connected
          ? OrbyColorPalette['warning-50']
          : OrbyColorPalette['green-50']
      }
      borderRadius={'10px'}
      height={'fit-content'}
      padding={'16px'}
      display={'flex'}
      gap={'20px'}
      width={'315px'}
    >
      {!connected ? (
        <WarningRounded sx={{ color: OrbyColorPalette['warning-500'] }} />
      ) : (
        <CheckCircleRounded sx={{ color: OrbyColorPalette['green-700'] }} />
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
        alignItems={'start'}
      >
        <OrbyTypography color={OrbyColorPalette['grey-700']}>
          {!connected ? 'Connection Required' : 'Connected'}
        </OrbyTypography>
        <OrbyTypography color={OrbyColorPalette['grey-700']}>
          {`This trigger requires connection to ${application}`}
        </OrbyTypography>
        {!connected && (
          <OrbyButton
            aria-label='Connect.'
            variant='primary-text-flat'
            onClick={onConnect}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              padding: '0px 1px',
            }}
            label='Connect'
          />
        )}
        {!edit &&
          [
            ApplicationName.Gmail.toString(),
            ApplicationName.Outlook.toString(),
          ].includes(application) && (
            <OrbyTypography
              color={OrbyColorPalette['grey-700']}
              sx={{ marginTop: '10px' }}
            >
              Labels cannot be changed after workflow creation
            </OrbyTypography>
          )}
      </Box>
    </Box>
  );
};

export default memo(ConnectionMessage);
