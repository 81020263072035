import { CheckOutlined } from '@mui/icons-material';
import { Box, Button, ButtonBaseActions } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntityInfoAction } from '../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../redux/reducers/review_task.reducer';
import {
  allowEditingTaskSelector,
  getSelectedReviewFilterSectionSelector,
  selectedTaskEntityInfoSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import {
  confirmReview,
  getBlankNormalizedValue,
  getDefaultTextSegment,
} from '../../../../../utils/ReviewTaskUtils';
import { EntityFilter } from '../../../../../utils/constants';
import { resetFloatingModalStyle } from '../../../../../utils/UnsavedChangesUtils';

interface Props {
  task: Task;
  entity: EntityInfo;
}

const FloatingModalFooter: React.FC<Props> = ({ task, entity }) => {
  const dispatch = useDispatch();
  const entitiesInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const actionRef = useRef<ButtonBaseActions | null>(null);

  const handleConfirm = () => {
    if (allowEditingTask) {
      confirmReview(
        entity,
        task,
        entitiesInfoMap,
        selectedReviewFilterSection as EntityFilter,
      );
    }
  };
  useEffect(() => {
    if (entity?.id && !Object.keys(entity.textSegments).length) {
      actionRef.current?.focusVisible();
    }
  }, [entity?.id]);

  const handleDecline = () => {
    const newEntityInfo: EntityInfo = { ...entity };
    resetFloatingModalStyle();
    /**
     * If user clicks on Clear all, in that case we will reset all textSegments and normalized value
     */
    newEntityInfo.error = null;
    // Create a new empty text segment
    newEntityInfo.textSegments = getDefaultTextSegment(newEntityInfo.id);
    newEntityInfo.normalizedValue = getBlankNormalizedValue(
      newEntityInfo.normalizedEntityType,
    );
    newEntityInfo.normalizedInputValue = '';
    newEntityInfo.entityText = '';
    dispatch(updateEntityInfoAction(newEntityInfo));
  };

  useEffect(() => {
    // Add an event listener for the Enter key
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        // Check if focus is not on any clickable element
        if (document.activeElement === document.body) {
          event.preventDefault();
          event.stopPropagation();
          handleConfirm();
        }
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [entity, entitiesInfoMap, selectedReviewFilterSection]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <CustomTypography typographyType={TypographyType.Label} color='#757575'>
        Press &quot;Enter&quot; to confirm and review next field
      </CustomTypography>
      <CustomTypography typographyType={TypographyType.Label} color='#757575'>
        Press &quot;Esc&quot; to discard any unconfirmed changes
      </CustomTypography>
      <Box display={'flex'} gap={'20px'} mt={'12px'}>
        {Object.keys(entity.textSegments).length > 0 && (
          <>
            <Button
              onClick={handleDecline}
              variant='outlined'
              sx={{
                width: '160px',
                borderRadius: '40px',
                py: '10px',
                border: '1px solid #0500FF',
              }}
            >
              <CustomTypography weight={600} color={'#0500FF'}>
                Clear all
              </CustomTypography>
            </Button>
          </>
        )}
        <Button
          onClick={handleConfirm}
          variant='contained'
          fullWidth
          action={(actions) => (actionRef.current = actions)}
          disabled={!!entity.error}
          startIcon={<CheckOutlined sx={{ color: 'white !important' }} />}
          sx={{
            ':disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
              backgroundColor: '#0500FF',
              opacity: 0.2,
            },
            borderRadius: '40px',
            py: '10px',
            backgroundColor: '#0500FF',
          }}
        >
          <CustomTypography weight={600} color={'white'}>
            This is correct
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(FloatingModalFooter);
