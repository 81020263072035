import { useEffect, useState } from 'react';
import * as React from 'react';
import {
  Box,
  BoxProps,
  chipClasses,
  Chip,
  inputBaseClasses,
  TextField,
  styled,
  useTheme,
} from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}:after`]: {
    borderBottomColor: theme.palette.secondary.main,
  },
}));

interface IProps extends BoxProps {
  initialText: string;
  emptyErrorMessage?: string;
  initialEditing?: boolean;
  onTextEditFinish?: (text: string) => void;
  onTextChange?: (text: string) => void;
  fontSize?: number;
  fontWeight?: number;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  variant?: 'default' | 'chip' | 'inline';
  backgroundColor?: string;
  // Always trigger onTextEditFinish when set to true, even if text is the same as initialText
  // It is set to true for smart texts used in JsFunctionActionWidget
  shouldAlwaysTriggerOnTextEditFinish?: boolean;
}

const getText = (
  text: string,
  handleTextClick: (e: React.MouseEvent) => void,
  errorMessage?: string,
  variant?: 'default' | 'chip' | 'inline',
  id?: string,
  fontSize?: number,
  fontWeight?: number,
  backgroundColor?: string,
) => {
  const theme = useTheme();
  if (variant === 'chip') {
    const _backgroundColor = errorMessage
      ? OrbyColorPalette['error-200']
      : backgroundColor ?? '#E6E2FF';
    return (
      <Chip
        sx={{
          height: 'auto',
          backgroundColor: _backgroundColor,
          color: OrbyColorPalette['grey-900'],
          '&:hover': {
            backgroundColor: _backgroundColor,
            color: OrbyColorPalette['grey-900'],
          },
          [`& .${chipClasses.label}`]: {
            display: 'block',
            fontSize,
            whiteSpace: 'normal',
          },
        }}
        label={errorMessage || text}
        color={errorMessage ? 'error' : 'secondary'}
        variant='filled'
        onClick={handleTextClick}
      />
    );
  }
  return (
    <OrbyTypography
      id={id}
      onClick={handleTextClick}
      sx={{
        fontSize,
        fontWeight,
        padding: theme.spacing(1, 0),
        cursor: 'text',
        ...(variant === 'inline' && {
          width: 'fit-content',
          borderBottom: `1px solid ${OrbyColorPalette['grey-400']}`,
        }),
      }}
    >
      {text}
    </OrbyTypography>
  );
};

const EditableText: React.FC<IProps> = ({
  initialText,
  emptyErrorMessage,
  placeholder,
  disabled = false,
  id,
  onTextEditFinish,
  onTextChange,
  fontSize,
  fontWeight,
  variant,
  initialEditing,
  backgroundColor,
  shouldAlwaysTriggerOnTextEditFinish,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  useEffect(() => {
    setIsEditing(initialEditing || false);
  }, [initialEditing]);

  const handleTextClick = (e: React.MouseEvent) => {
    if (disabled) return;
    if (!onTextChange && !onTextEditFinish) return;
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onTextChange?.(event.target.value);
  };

  const finishEditing = () => {
    setIsEditing(false);
    if (text === '') {
      setErrorMessage(emptyErrorMessage || '');
    } else {
      setErrorMessage('');
    }

    if (shouldAlwaysTriggerOnTextEditFinish || text !== initialText) {
      onTextEditFinish && onTextEditFinish(text);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      finishEditing();
    }
  };

  return (
    <Box {...props} sx={{ flex: 1 }}>
      {isEditing || text === '' ? (
        <StyledTextField
          id={id}
          aria-label={text}
          value={text}
          size='medium'
          variant='standard'
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          onClick={(e) => e.stopPropagation()}
          onBlur={() => finishEditing()}
          disabled={disabled}
          error={errorMessage !== ''}
          placeholder={placeholder}
          sx={{
            width: '100%',
            [`& .${inputBaseClasses.root}`]: {
              fontSize,
              fontWeight,
            },
          }}
          autoFocus
        />
      ) : (
        getText(
          text,
          handleTextClick,
          errorMessage,
          variant,
          id,
          fontSize,
          fontWeight,
          backgroundColor,
        )
      )}
    </Box>
  );
};

export { EditableText };
