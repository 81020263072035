import { Box, IconButton } from '@mui/material';
import React from 'react';
import UserCard from './UserCard';
import { IOSSwitch } from './switch/IosType';
import CloseIcon from '@mui/icons-material/Close';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface UserChipProps {
  email: string;
  fullName?: string;
  profileImageUrl?: string;
  onDelete?: () => void;
  usedForLearning: boolean;
  toggleLearningSettings: () => void;
  isPastUser?: boolean;
}
const UserChip: React.FC<UserChipProps> = ({
  email,
  fullName,
  profileImageUrl,
  onDelete,
  usedForLearning,
  toggleLearningSettings,
  isPastUser = false,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: isPastUser ? '0px' : '36px',
      }}
    >
      <Box sx={{ maxWidth: '200px' }}>
        <UserCard
          email={email}
          fullName={fullName}
          imageUrl={profileImageUrl}
        />
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        pl={'16px'}
        width={'300px'}
        justifyContent={'space-evenly'}
        flexShrink={0}
      >
        <IOSSwitch checked={usedForLearning} onClick={toggleLearningSettings} />
        <OrbyTypography
          color={OrbyColorPalette['grey-500']}
          sx={{ maxWidth: '200px', marginLeft: '8px' }}
        >
          Include feedback to improve your workflow model
        </OrbyTypography>
        {!isPastUser && (
          <IconButton onClick={onDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(UserChip);
