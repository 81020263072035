import SearchField from '../pages/MyWorkflows/CreateWorkflow/Steps/components/SearchField';
import { WorkflowUser } from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo } from 'react';

interface SearchAddUserFieldProps {
  width?: string;
  hasError?: boolean;
  searchList: string[];
  searchValues: WorkflowUser[];
  onChange: (x: any) => void;
  disabled?: boolean;
}

const SearchAddUserField: FC<SearchAddUserFieldProps> = ({
  width = '100%',
  searchList,
  searchValues,
  hasError,
  onChange,
  disabled,
}) => {
  return (
    <SearchField
      dropdownWidth={width}
      searchList={searchList}
      hasError={hasError}
      selectedValues={searchValues.map((user) => user.user)}
      handleAdd={onChange}
      placeholder='Start typing email'
      ariaLabel='Start typing email'
      ariaDescribeBy='invite_users'
      disabled={disabled}
    />
  );
};

export default memo(SearchAddUserField);
