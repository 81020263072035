import { Box } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../../../components/CustomTypography';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedEntityIdsForAnnotationAction,
  setSelectedParentEntityTypeAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  infoToDetectChangesSelector,
  selectedEntityInfoSelector,
  selectedParentEntityInfoSelector,
  selectedTaskEntityInfoSelector,
  taskForReviewSelector,
  rowOrderInfoForTableEntitiesSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import TableLined from '../../../../../../../static/icons/table_lined.svg';
import { isEntityPredicted } from '../../../../../../../utils/ReviewTaskUtils';
import UserCheck from '../../../../../../../static/icons/user-check.svg';
import { Done } from '@mui/icons-material';
import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { checkForUnsavedChanges } from '../../../../../../../utils/UnsavedChangesUtils';

interface Props {
  entityType: string;
  entityId: string;
}

const ParentEntitiesInfoBox: React.FC<Props> = ({ entityType, entityId }) => {
  const dispatch = useDispatch();
  const task = useSelector(taskForReviewSelector);
  const selectedEntity = useSelector(selectedEntityInfoSelector);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const rowOrderInfoForTableEntities = useSelector(
    rowOrderInfoForTableEntitiesSelector,
  );
  const selectedTaskEntityInfo = useSelector(selectedTaskEntityInfoSelector);

  // this state contains all info necessary to detect unsaved changes
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);

  const childEntitiesOfSelectedParent: EntityInfo[] = useMemo(() => {
    return Object.values(selectedTaskEntityInfo).filter((e) => {
      return e.parentEntityType === entityType;
    });
  }, [selectedTaskEntityInfo]);

  // If any entity which is not reviewed is not found
  const areAllEntitiesReviewed = !childEntitiesOfSelectedParent.some(
    (e) => !e.isReviewed,
  );
  // If any entity which is not predicted is not found
  const areAllEntitiesPredicted = !childEntitiesOfSelectedParent.some(
    (e) => !isEntityPredicted(e),
  );
  // If any entity that has been modified by a user is present
  const containsModifiedEntity = childEntitiesOfSelectedParent.some(
    (e) => e.isModified,
  );

  const showBorder = useMemo(() => {
    return (
      selectedEntity?.parentEntityType === entityType ||
      selectedParentEntityInfo?.type === entityType
    );
  }, [selectedEntity, selectedParentEntityInfo, entityType]);

  const getBackgroundColor = () => {
    if (areAllEntitiesReviewed) {
      return '#F6FEF9';
    } else if (areAllEntitiesPredicted) {
      return '#F5FAFF';
    }
    // Last case left means there must be a not in doc entity present among child entities
    return '#FFFBFA';
  };

  const borderColor = useMemo(() => {
    if (showBorder) return '#0500FF';
    if (task?.status !== TaskSTATUS.READY) return '#dddbda';
    return 'transparent';
  }, [task, showBorder]);

  return (
    <Box paddingTop={'10px'}>
      <Box
        role={'button'}
        id={`entity-box-${entityType}`} // this id is used get this element and focus on this element in EntitiesInfo.tsx
        tabIndex={0}
        bgcolor={getBackgroundColor()}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'5px'}
        padding={'20px 12px'}
        borderRadius={'4px'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            // block if unsaved changes are present
            if (checkForUnsavedChanges(infoToDetectChanges)) {
              return;
            }
            dispatch(setSelectedEntityIdsForAnnotationAction([]));
            dispatch(
              setSelectedParentEntityTypeAction({
                id: entityId,
                type: entityType,
              }),
            );
          }
        }}
        border={`2px solid ${borderColor}`}
        sx={{
          cursor: 'pointer',
          ':focus': {
            border: '2px solid rgb(22, 105, 247)',
          },
        }}
        onClick={() => {
          // block if unsaved changes are there
          if (checkForUnsavedChanges(infoToDetectChanges)) {
            return;
          }
          dispatch(setSelectedEntityIdsForAnnotationAction([]));
          dispatch(
            setSelectedParentEntityTypeAction({
              id: entityId,
              type: entityType,
            }),
          );
        }}
      >
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Box component={'img'} src={TableLined} marginTop={'2px'} />
          <CustomTypography
            color='#101828'
            weight={500}
            typographyType={TypographyType.Header5}
            sx={{ display: 'flex' }}
          >
            {entityType} ({rowOrderInfoForTableEntities[entityType].length})
          </CustomTypography>
        </Box>
        {areAllEntitiesReviewed && containsModifiedEntity && (
          <Box
            title='This means a human has reviewed and modified this entity'
            component={'img'}
            src={UserCheck}
          />
        )}
        {areAllEntitiesReviewed && !containsModifiedEntity && (
          <Box title='This means Orby predicted originally and a human confirmed'>
            <Done
              fontSize={'small'}
              sx={{ color: '#054F31', marginRight: '-6px' }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ParentEntitiesInfoBox);
