import {
  ArrowForwardIos,
  Check,
  KeyboardArrowDown,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  OutputOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../redux/actions/auth.action';
import { storageService } from '../../services/StorageService';
import { setSelectedOrgInfo } from '../../redux/actions/user.action';
import { DRAWER_WIDTH_EXPANDED, ELLIPSIS_STYLE } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

interface DrawerFooterProps {
  collapse: boolean;
  setCollapse: (x: boolean) => void;
}

const DrawerFooter: FC<DrawerFooterProps> = ({ collapse, setCollapse }) => {
  const navigate = useNavigate();
  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const iconRight = collapse ? 20 : 13;

  const handleOpenLogout = () => {
    setAnchorEl(document.getElementById('drawer-footer'));
  };

  const handleCloseLogout = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const token = await storageService.getStoredToken();
    if (token) {
      const req: LogoutRequest = {};
      req.name = token.sessionId;
      dispatch(logoutAction(req));
    }
  };

  return (
    <Box
      bgcolor={'#354051'}
      width={'100%'}
      height={'80px'}
      flexDirection={'column'}
      display={'flex'}
      id='drawer-footer'
      position='absolute'
      bottom={0}
      padding={'12px 12px 24px 12px'}
    >
      <IconButton
        onClick={() => setCollapse(!collapse)}
        aria-label={collapse ? 'Open Drawer' : 'Close Drawer'}
        sx={{
          position: 'absolute',
          top: -60,
          right: 10,
          '&:focus': { outline: '2px solid red', borderRadius: '5px' },
        }}
      >
        {collapse ? (
          <KeyboardDoubleArrowRight
            sx={{ color: '#B6B6B6', fontSize: '30px' }}
          />
        ) : (
          <KeyboardDoubleArrowLeft
            sx={{ color: '#B6B6B6', fontSize: '30px' }}
          />
        )}
      </IconButton>
      {!collapse && (
        <>
          <Box title={user?.email}>
            <CustomTypography
              weight={600}
              color={'#FFFFFF'}
              sx={{
                ...ELLIPSIS_STYLE,
                maxWidth: '200px',
                position: 'absolute',
                top: 20,
                left: 17,
              }}
            >
              {user?.email}
            </CustomTypography>
          </Box>
          <Box
            sx={{ position: 'absolute', top: 39, left: 17, right: 17 }}
            display={'flex'}
          >
            <CustomTypography color={'#B6B6B6'}>
              {selectedOrgInfo?.orgDisplayName}
            </CustomTypography>
            <IconButton
              onClick={handleOpenLogout}
              aria-label='User Menu'
              sx={{
                width: '25px',
                height: '19px',
                '&:focus': {
                  outline: '2px solid red',
                  borderRadius: '5px',
                  padding: '6px',
                },
              }}
            >
              <KeyboardArrowDown
                sx={{ color: '#B6B6B6', width: '25px', height: '19px' }}
              />
            </IconButton>
          </Box>
        </>
      )}
      {collapse && (
        <IconButton
          onClick={handleOpenLogout}
          aria-label='User Menu'
          sx={{
            position: 'absolute',
            top: '28px',
            right: iconRight,
            width: '25px',
            height: '19px',
            '&:focus': {
              outline: '2px solid red',
              borderRadius: '5px',
              padding: '6px',
            },
          }}
        >
          <ArrowForwardIos
            sx={{ color: '#B6B6B6', width: '25px', height: '19px' }}
          />
        </IconButton>
      )}
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseLogout}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            '&.MuiMenu-paper': {
              left: '0px !important',
              width: `${DRAWER_WIDTH_EXPANDED}px !important`,
              paddingTop: '10px',
            },
          },
        }}
      >
        <CustomTypography
          component={'span'}
          sx={{ marginLeft: '12px' }}
          typographyType={TypographyType.MediumPara}
          color='#6B6B6B'
        >
          Organizations
        </CustomTypography>
        {user?.orgInfos?.map((org, index) => (
          <MenuItem
            key={index}
            selected={selectedOrgInfo?.orgResourceName === org.orgResourceName}
            sx={{ marginY: '8px' }}
            onClick={() => {
              navigate({});
              dispatch(setSelectedOrgInfo(org));
              handleCloseLogout();
            }}
          >
            {selectedOrgInfo?.orgResourceName === org.orgResourceName && (
              <ListItemIcon>
                <Check sx={{ color: '#000000' }} />
              </ListItemIcon>
            )}
            <ListItemText title={org.orgDisplayName}>
              <CustomTypography
                sx={{
                  marginLeft:
                    selectedOrgInfo?.orgResourceName !== org.orgResourceName
                      ? '36px'
                      : '0px',
                  ...ELLIPSIS_STYLE,
                }}
                typographyType={TypographyType.Header4}
                color='#000000'
              >
                {org.orgDisplayName}
              </CustomTypography>
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem sx={{ marginTop: '15px' }} onClick={handleLogout}>
          <ListItemIcon>
            <OutputOutlined fontSize='small' sx={{ marginX: '10px' }} />
          </ListItemIcon>
          <ListItemText>
            <CustomTypography
              typographyType={TypographyType.Header4}
              color='#000000'
            >
              Log out
            </CustomTypography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default memo(DrawerFooter);
