import { Box } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import React, { FC, memo } from 'react';

interface Props {
  open: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onMouseDown: (e: React.MouseEvent) => void;
}

const ResizeLine: FC<Props> = ({
  open,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
}) => {
  return (
    <Box
      role='none'
      sx={{
        position: 'absolute',
        top: 0,
        right: '-1px',
        zIndex: 3,
        width: '2px',
        height: '105%',
        opacity: open ? '1' : '0',
        backgroundColor: OrbyColorPalette['blue-700'],
        border: 'none',
        cursor: 'col-resize',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    />
  );
};

export default memo(ResizeLine);
