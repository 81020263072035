import React from 'react';
import { Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { formatDateTime } from '../../../../utils/helpers';
import {
  WorkflowTask,
  WorkflowTaskStatus,
} from 'protos/pb/v1alpha1/orbot_workflow';
import UserCard from '../../../../components/UserCard';

export const getExecutionCell = (
  execution: WorkflowTask,
  displayNamePrefix: string,
): JSX.Element => {
  const executionName = execution.id || '';

  const startIndex = executionName
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={executionName}
      key={execution.id}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {executionName.substring(startIndex, endIndex)}
          </span>
          {executionName.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getExecutionWorkflowDisplayNameCell = (
  execution: WorkflowTask,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={execution.workflowDisplayName}
      key={execution.workflowId}
      label={execution.workflowDisplayName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

type WorkflowTaskStatusDetails = {
  color: string;
  label: string;
  value: string;
  backgroundColor: string;
  dotColor: string;
};

export const WorkflowTaskStatusEnum: Record<number, WorkflowTaskStatusDetails> =
  {
    [WorkflowTaskStatus.PENDING]: {
      color: OrbyColorPalette['blueGrey-700'],
      label: 'Waiting for execution',
      value: 'PENDING',
      backgroundColor: OrbyColorPalette['blueGrey-50'],
      dotColor: OrbyColorPalette['blueGrey-500'],
    },
    [WorkflowTaskStatus.EXECUTING]: {
      color: OrbyColorPalette['blue-800'],
      label: 'Execution in process',
      value: 'EXECUTING',
      backgroundColor: OrbyColorPalette['blue-50'],
      dotColor: OrbyColorPalette['blue-600'],
    },
    [WorkflowTaskStatus.WAITING_FOR_REVIEW]: {
      color: OrbyColorPalette['blue-600'],
      label: 'Pending Review',
      value: 'WAITING_FOR_REVIEW',
      backgroundColor: OrbyColorPalette['blue-50'],
      dotColor: OrbyColorPalette['blue-400'],
    },
    [WorkflowTaskStatus.SUCCESS]: {
      color: OrbyColorPalette['green-700'],
      label: 'Completed',
      value: 'SUCCESS',
      backgroundColor: OrbyColorPalette['green-50'],
      dotColor: OrbyColorPalette['green-500'],
    },
    [WorkflowTaskStatus.TERMINATED]: {
      color: OrbyColorPalette['pink-700'],
      label: 'Cancelled',
      value: 'TERMINATED',
      backgroundColor: OrbyColorPalette['pink-50'],
      dotColor: OrbyColorPalette['pink-500'],
    },
    [WorkflowTaskStatus.FAIL]: {
      color: OrbyColorPalette['orange-700'],
      label: 'Error',
      value: 'FAIL',
      backgroundColor: OrbyColorPalette['orange-50'],
      dotColor: OrbyColorPalette['orange-500'],
    },
  };

export const getExecutionStatusCell = (
  execution: WorkflowTask,
): JSX.Element | JSX.Element[] => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
      <Box
        key={execution.id}
        title={WorkflowTaskStatusEnum[execution.status!].label}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'6px'}
        sx={{
          paddingX: '8px',
          paddingY: '2px',
          backgroundColor:
            WorkflowTaskStatusEnum[execution.status!].backgroundColor,
          borderRadius: '16px',
          width: 'fit-content',
        }}
      >
        <CircleIcon
          sx={{
            width: '6px',
            height: '6px',
            color: WorkflowTaskStatusEnum[execution.status!].dotColor,
          }}
        />
        <OrbyTypography
          size='xs'
          weight='medium'
          color={WorkflowTaskStatusEnum[execution.status!].color}
        >
          {WorkflowTaskStatusEnum[execution.status!].label}
        </OrbyTypography>
      </Box>
    </Box>
  );
};

export const getExecutionTimeCell = (
  execution: WorkflowTask,
  executionTime?: Date,
): JSX.Element => {
  if (executionTime) {
    const { date, time } = formatDateTime(executionTime);
    return (
      <Box key={execution.id}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={execution.id}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getExecutionRanByCell = (execution: WorkflowTask): JSX.Element => {
  return (
    <UserCard
      key={execution.creator?.fullName}
      email={execution.creator?.username ?? ''}
      imageUrl={execution.creator?.imageUrl}
      fullName={execution.creator?.fullName}
    />
  );
};

export const getExecutionNameColumnWidth = (tableWidth: number) => {
  return (
    (tableWidth -
      getExecutionTimeWidth(tableWidth) -
      getExecutionTimeWidth(tableWidth) -
      188 -
      217) /
    2
  );
};

export const getExecutionTimeWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};

/**
 * Handle UI Execution Page Change
 */
export const handleUiExecutionPageChange = ({
  executions,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listExecutions,
}: {
  executions: WorkflowTask[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listExecutions: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && executions.length <= pageNumber * rowsPerPage) {
    listExecutions(pageNumber + 1, rowsPerPage, false);
  }
};
