import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import {
  ListTasksRequest,
  Task,
  TaskSTATUS,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  addAlpha,
  handleSelectAllTasks,
  handleTaskCheckboxClick,
  isAdmin,
  areAllTasksSelected,
  isTaskSelected,
  formatDate,
  allowSelectionForPendingTask,
} from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletingTaskSelector,
  listPendingTasksAdditionalRoundErrorSelector,
  pendingTasksAdditionalRoundListSelector,
  pendingTasksAdditionalRoundLoadingSelector,
  pendingTasksAdditionalRoundTotalSizeSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToReassignSelector,
  updateBatchTasksLoadingSelector,
} from '../../redux/selectors/taskV2.selectors';
import { ReactComponent as DeleteIcon } from '../../static/icons/deleteIcon.svg';
import {
  listPendingTasksAdditionalRoundAction,
  listPendingTasksAdditionalRoundErrorAction,
} from '../../redux/actions/taskV2.action';
import { notification } from 'antd';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import { clearReviewState } from '../../redux/actions/review_task.action';
import { composeTaskFiltersAndUpdateNetworkURL } from '../../pages/Utils/taskV2Utils';
import { ELLIPSIS_STYLE } from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import TaskCheckbox from './TaskCheckbox';
import CustomTableLabel from '../../components/CustomTableLabel';
import '../../styles/table.css';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));
const getConfidenceColor = (confidence: string) => {
  switch (confidence) {
    case 'high':
      return '#E3FCEF';
    case 'mid':
      return '#FFF2BF';
    case 'low':
      return '#FFBFBF';
  }
};

interface Props {
  selectedWorkflows: string[];
  displayNamePrefix: string;
  selectedTasks: Task[];
  setSelectedTasks: any;
  handleDeletion: (task: Task) => void;
  selectedUsers: Set<string>;
  selectedOption: string;
}

const PendingTasksAdditionalRound: React.FC<Props> = ({
  selectedWorkflows,
  displayNamePrefix,
  selectedTasks,
  setSelectedTasks,
  handleDeletion,
  selectedOption,
  selectedUsers,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tasks: Task[] = useSelector(pendingTasksAdditionalRoundListSelector);
  const loading = useSelector(pendingTasksAdditionalRoundLoadingSelector);
  const totalSize =
    useSelector(pendingTasksAdditionalRoundTotalSizeSelector) ?? 0;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pagedTasks = tasks.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const listPendingTasksError = useSelector(
    listPendingTasksAdditionalRoundErrorSelector,
  );
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const workflowColors = useSelector(workflowColorsSelector);
  const user = useSelector(loggedInUserSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const deletingTask = useSelector(deletingTaskSelector);
  const ressignmentLoading = useSelector(updateBatchTasksLoadingSelector);
  const tasksFailedToReassign = useSelector(tasksFailedToReassignSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  useEffect(() => {
    if (listPendingTasksError) {
      openError(listPendingTasksError);
      dispatch(listPendingTasksAdditionalRoundErrorAction(undefined));
    }
  }, [listPendingTasksError]);

  useEffect(() => {
    setPage(0);
    listTasks(1, rowsPerPage, true);
    setSelectedTasks([]);
  }, [
    selectedOrgInfo,
    selectedWorkflows,
    displayNamePrefix,
    selectedOption,
    selectedUsers,
  ]);

  const listTasks = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildRequest();
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listPendingTasksAdditionalRoundAction(req, refresh));
  };

  const buildRequest = () => {
    const req: ListTasksRequest = {};
    req.filter = composeTaskFiltersAndUpdateNetworkURL(
      'status=ready,round=2',
      user?.email as string,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      [...selectedUsers.values()],
      selectedOption,
    );
    req.parent = selectedOrgInfo?.orgResourceName;
    return req;
  };

  useEffect(() => {
    if (tasks) {
      const workflowNames = tasks.map(
        (t) => t.workflowDisplayName || t.organizationResourceName,
      );
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [tasks]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      // notification will shown by PendingTasks component so no need to show here otherwise we will two notifications
      // set page number to zero and refresh the whole list
      setPage(0);
      listTasks(1, rowsPerPage, true);
    }
  }, [tasksDeletedSuccessfully]);

  const onTableRowClick = (task: Task) => {
    if (task.status !== TaskSTATUS.CREATED) {
      dispatch(clearReviewState());
      navigate(`/${task.name}/automation-review`);
    }
  };

  const renderDelete = (t: Task) => {
    return t.status !== TaskSTATUS.CREATED ? (
      <IconButton
        sx={{ ml: '20px' }}
        aria-label='Delete Task'
        onClick={(e) => {
          if (t.status !== TaskSTATUS.CREATED) {
            e.stopPropagation();
            handleDeletion(t);
          }
        }}
      >
        <DeleteIcon color='#6B6B6B' />
      </IconButton>
    ) : (
      <Box sx={{ paddingX: '4px', paddingY: '10px' }} />
    );
  };

  return (
    <Box paddingX={'60px'}>
      {contextHolder}
      <CustomTypography sx={{ marginLeft: '12px' }} size={'16px'} weight={600}>
        Additional Round
      </CustomTypography>
      <Box>
        {loading || deletingTask ? (
          <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer
              sx={{ marginTop: '30px' }}
              className={'table-header-style'}
            >
              <Table
                id={'pending-tasks-additional-round-table'}
                sx={{ tableLayout: 'fixed' }}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                    {pagedTasks.length > 0 && (
                      <CustomTableCell sx={{ width: '50px' }} ellipsis={false}>
                        <TaskCheckbox
                          disabled={
                            ressignmentLoading ||
                            !pagedTasks.some((t) =>
                              allowSelectionForPendingTask(t),
                            )
                          }
                          checked={areAllTasksSelected(
                            selectedTasks,
                            pagedTasks,
                          )}
                          title='Select all Pending tasks'
                          onClick={(e) =>
                            handleSelectAllTasks(
                              e,
                              selectedTasks,
                              pagedTasks,
                              setSelectedTasks,
                            )
                          }
                        />
                      </CustomTableCell>
                    )}
                    <CustomTableCell>
                      <CustomTableLabel label='Task Name' />
                    </CustomTableCell>
                    <CustomTableCell title='Workflow'>
                      <CustomTableLabel label='Workflow' />
                    </CustomTableCell>
                    <CustomTableCell title='Time Modified'>
                      <CustomTableLabel label='Time Modified' />
                    </CustomTableCell>
                    <CustomTableCell title='Confidence Level'>
                      <CustomTableLabel label='Confidence Level' />
                    </CustomTableCell>
                    <CustomTableCell title='Pending Reviewer'>
                      <CustomTableLabel label='Pending Reviewer' />
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedTasks.map(
                    (t, index) =>
                      t.status !== TaskSTATUS.OBSOLETE && (
                        <StyledTableRow
                          key={index}
                          tabIndex={0}
                          role='row'
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: tasksFailedToReassign.includes(
                              t.name as string,
                            )
                              ? '#FEF3F2'
                              : index % 2 !== 0
                                ? '#F6F6F6'
                                : 'white',
                            ':hover': { backgroundColor: '#1669F74D' },
                            ':focus': { backgroundColor: '#1669F74D' },
                          }}
                          onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              onTableRowClick(t);
                            }
                          }}
                          onClick={() => onTableRowClick(t)}
                        >
                          <CustomTableCell ellipsis={false}>
                            <TaskCheckbox
                              disabled={
                                ressignmentLoading ||
                                !allowSelectionForPendingTask(t)
                              }
                              checked={isTaskSelected(t, selectedTasks)}
                              onClick={(e) =>
                                handleTaskCheckboxClick(
                                  e,
                                  t,
                                  selectedTasks,
                                  setSelectedTasks,
                                )
                              }
                              sx={{ marginRight: '20px' }}
                              title={
                                t.status === TaskSTATUS.CREATED
                                  ? 'Created tasks cannot be reassigned'
                                  : !isAdminView && t.username
                                    ? 'Already assigned tasks cannot be reassigned'
                                    : undefined
                              }
                            />
                          </CustomTableCell>
                          <CustomTableCell>
                            <span title={t.displayName || 'NO DATA'}>
                              {t.displayName || 'NO DATA'}
                            </span>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Box
                              title={
                                t.workflowDisplayName ||
                                t.organizationResourceName
                              }
                              sx={{
                                paddingX: '10px',
                                paddingY: '5px',
                                backgroundColor: addAlpha(
                                  workflowColors[
                                    (t.workflowDisplayName as string) ||
                                      (t.organizationResourceName as string)
                                  ],
                                  0.4,
                                ),
                                width: 'fit-content',
                                maxWidth: '100%',
                                borderRadius: '4px',
                              }}
                            >
                              <CustomTypography
                                typographyType={TypographyType.Label}
                                sx={{
                                  ...ELLIPSIS_STYLE,
                                }}
                              >
                                {t.workflowDisplayName ||
                                  t.organizationResourceName}
                              </CustomTypography>
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell ellipsis={false}>
                            {t.status !== TaskSTATUS.CREATED ? (
                              <CustomTypography>
                                {t.readyTime ? formatDate(t.readyTime) : '-'}
                              </CustomTypography>
                            ) : (
                              <Box
                                width={'106px'}
                                height={'11px'}
                                borderRadius={'15px'}
                                bgcolor={'#E4E5E7'}
                              ></Box>
                            )}
                          </CustomTableCell>
                          {t.status !== TaskSTATUS.CREATED ? (
                            <CustomTableCell>
                              <Box
                                title='High'
                                sx={{
                                  paddingX: '10px',
                                  paddingY: '5px',
                                  backgroundColor: addAlpha(
                                    getConfidenceColor('high') as string,
                                    0.4,
                                  ),
                                  borderRadius: '4px',
                                  width: 'fit-content',
                                }}
                              >
                                <CustomTypography
                                  typographyType={TypographyType.Label}
                                >
                                  High
                                </CustomTypography>
                              </Box>
                            </CustomTableCell>
                          ) : (
                            <CustomTableCell>
                              <Box
                                width={'106px'}
                                height={'11px'}
                                borderRadius={'15px'}
                                bgcolor={'#E4E5E7'}
                              ></Box>
                            </CustomTableCell>
                          )}
                          <CustomTableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{ ...ELLIPSIS_STYLE }}
                              title={t.username || 'Not assigned'}
                            >
                              <CustomTypography
                                sx={{
                                  ...ELLIPSIS_STYLE,
                                  maxWidth: '100%',
                                  marginTop: '5px',
                                }}
                              >
                                {t.username || 'Not assigned'}
                              </CustomTypography>
                            </Box>
                            {isAdminView && renderDelete(t)}
                          </CustomTableCell>
                        </StyledTableRow>
                      ),
                  )}
                </TableBody>
              </Table>
              {!pagedTasks.length && (
                <CustomTypography
                  sx={{
                    textAlign: 'center',
                    textJustify: 'center',
                    color: '#475467',
                    marginTop: '40px',
                  }}
                >
                  There are no additional-round tasks available
                </CustomTypography>
              )}
            </TableContainer>
            <CustomPagination
              rowsPerPage={rowsPerPage}
              totalSize={totalSize}
              page={page}
              onRowsPerPageChange={(rows) => {
                setPage(0);
                // Refresh is needed when rows per page is changes to fetch fresh data
                listTasks(1, rows, true);
                setRowsPerPage(rows);
                setSelectedTasks([]);
              }}
              onPageChange={(p) => {
                setPage(p);
                if (p >= page && tasks.length <= p * rowsPerPage) {
                  listTasks(p + 1, rowsPerPage, false);
                }
                setSelectedTasks([]);
              }}
              isShowSelectFilter={totalSize > rowsPerPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(PendingTasksAdditionalRound);
