import { createSelector } from 'reselect';
import { dashboardSelector } from './app.selectors';

export const taskStatusSelector = createSelector(
  [dashboardSelector],
  (dashboardState) => dashboardState.taskStatus,
);

export const taskSummarySelector = createSelector(
  [dashboardSelector],
  (dashboardState) => dashboardState.taskSummary,
);

export const statsErrorSelector = createSelector(
  [dashboardSelector],
  (dashboardState) => dashboardState.statsError,
);
