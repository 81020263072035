import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Frame from './Frame';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { GetWorkflowTemplateRequest } from 'protos/pb/v1alpha1/orbot_service';
import { getOrbotWorkflowTemplateAction } from '../../../redux/actions/workflow_task.constants';
import { getWorkflowTemplateSelector } from '../../../redux/selectors/workflow_task.selectors';
import { Box, Button, styled } from '@mui/material';
import Workflow from './Workflow';
import { Workflow as WorkflowObject } from 'protos/pb/v1alpha1/orbot_workflow';
import { copyTemplate } from './templates';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}));

const Template: React.FC = () => {
  const { template_id: templateId } = useParams<{
    template_id: string;
  }>();

  const template = useSelector(getWorkflowTemplateSelector);

  const user = useSelector(loggedInUserSelector);
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;

  const fetchTemplateRequest: GetWorkflowTemplateRequest = useMemo(() => {
    return {
      templateId,
      orgId,
    };
  }, [templateId]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!templateId) return;
    dispatch(getOrbotWorkflowTemplateAction(fetchTemplateRequest));
  }, [templateId]);

  const onUseTemplate = async (template: WorkflowObject | undefined) => {
    if (!template) return;
    const createdWorkflowId = await copyTemplate(template, orgId, user!.id!);
    navigate(`/workflow/${createdWorkflowId}/definition`, { replace: true });
  };

  return (
    <Frame
      title={template?.displayName}
      enableLogoBack
      description={`Created by Orby AI`}
      action={
        <Button
          sx={{ height: '36px', borderColor: '#0000001F' }}
          variant='outlined'
          onClick={() => onUseTemplate(template)}
        >
          TRY TEMPLATE
        </Button>
      }
    >
      <Container>
        <Workflow workflow={template ?? null} isEditable={false} />
      </Container>
    </Frame>
  );
};

export default Template;
