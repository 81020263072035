import { composeTaskFiltersAndUpdateNetworkURLV2 } from '../Utils/taskV2Utils';
import {
  DeleteBatchTasksRequest,
  ListTasksRequest,
  Task,
} from 'protos/pb/v1alpha2/tasks_service';
import { User } from 'protos/pb/v1alpha1/user';
import { NavigateFunction } from 'react-router-dom';
import { deleteTaskAction } from '../../redux/actions/taskV2.action';
import { DeleteTaskType } from '../../utils/constants';
import { Dispatch } from 'react';
import { WorkflowExecution } from 'protos/pb/v1alpha2/workflow_executions_service';

export const buildRequest = ({
  status,
  user,
  isAdminView,
  navigate,
  selectedWorkflows,
  displayNamePrefix,
  selectedUsers,
  orgResourceName,
}: {
  status: string;
  user: User;
  isAdminView: boolean;
  navigate: NavigateFunction;
  selectedWorkflows: string[];
  displayNamePrefix: string;
  selectedUsers: string[];
  orgResourceName: string;
}) => {
  const req: ListTasksRequest = ListTasksRequest.fromJSON({
    filter: composeTaskFiltersAndUpdateNetworkURLV2(
      status,
      user?.email as string,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      selectedUsers,
    ),
    parent: orgResourceName,
  });
  return req;
};

export const DEFAULT_WORKFLOW_VALUE = {
  label: 'All workflows',
  value: 'all-workflows',
};

export const ALL_TASK_VALUE = {
  label: 'All users',
  value: 'All tasks',
};

export const ALL_USER_VALUE = {
  label: 'All users',
  value: 'All users',
};

export const ALL_CREATOR_VALUE = {
  label: 'All creators',
  value: 'All creators',
};

export const UNASSIGNED_TASK_VALUE = {
  label: 'Unassigned only',
  value: 'OrbyUnassigned',
};

/**
 * DELETE TASKS
 */
export const handleDeleteTask = (
  deleteReason: string,
  itemsToDelete: Task[] | WorkflowExecution[],
  dispatch: Dispatch<any>,
) => {
  const req = DeleteBatchTasksRequest.create({
    names: itemsToDelete.map((item) => item.name) as string[],
    deletedReason: deleteReason,
  });
  dispatch(
    deleteTaskAction({
      deleteType: DeleteTaskType.PENDING,
      req,
    }),
  );
};

/**
 * CHECK IF ALL THE TASKS ARE SELECTED ON THE CURRENT PAGE
 */
export const areAllTasksSelected = (
  selectedTasks: Task[],
  tasks: Task[],
  page: number,
  rowsPerPage: number,
) => {
  if (selectedTasks.length) {
    const tasksOnCurrentPage = tasks.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    );
    return tasksOnCurrentPage.every((task) => selectedTasks.includes(task));
  }
  return false;
};

/**
 * Handle Task Page Change
 */
export const handleTaskPageChange = ({
  tasks,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listTasks,
  setSelectedTasks,
}: {
  tasks: Task[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listTasks: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedTasks: (tasks: Task[]) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && tasks.length <= pageNumber * rowsPerPage) {
    listTasks(pageNumber + 1, rowsPerPage, false);
  }
  setSelectedTasks([]);
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleTasksRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listTasks,
  setSelectedTasks,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listTasks: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedTasks: (tasks: Task[]) => void;
}) => {
  setPage(0);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listTasks(1, rowsNumber, true);
  setSelectedTasks([]);
};

export const getTaskNameColumnWidth = (tableWidth: number) => {
  // TABLE WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  return (tableWidth - getTimeModifiedWidth(tableWidth) - 217 - 69) / 2;
};

export const getTaskNameColumnWidthCompletedTab = (tableWidth: number) => {
  // TABLE WIDTH - TAG WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  return (tableWidth - 109 - getTimeModifiedWidth(tableWidth) - 270 - 69) / 2;
};

export const getTaskNameColumnWidthDeclinedTab = (tableWidth: number) => {
  // TABLE WIDTH - DECLINED REASON WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  return (tableWidth - 218 - getTimeModifiedWidth(tableWidth) - 217 - 69) / 2;
};

export const getTimeModifiedWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};
