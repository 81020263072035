import { Check } from '@mui/icons-material';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { FC, memo } from 'react';
import {
  ArchivedResourceFilters,
  ArchivedResourceType,
  ELLIPSIS_STYLE,
} from '../../utils/constants';

interface ArchivedResourcesFilterSelectProps {
  resourceType: ArchivedResourceType;
  setResourceType: (x: ArchivedResourceType) => void;
}

const ArchivedResourcesFilterSelect: FC<ArchivedResourcesFilterSelectProps> = ({
  resourceType,
  setResourceType,
}) => {
  const options = Object.values(ArchivedResourceType).map((e) => ({
    value: e,
    label: ArchivedResourceFilters[e],
  }));

  const handleSelectChange = (e: SelectChangeEvent) => {
    const value = e.target.value as ArchivedResourceType;
    setResourceType(value);
  };

  const handleRenderValue = () => {
    return (
      <Box title={resourceType}>
        {renderLabel(ArchivedResourceFilters[resourceType])}
      </Box>
    );
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <CustomTypography
        typographyType={TypographyType.Header4}
        color={isMenu ? '#101828' : '#344054'}
        weight={isMenu ? 600 : 500}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </CustomTypography>
    );
  };

  const renderMenuItem = ({
    key,
    label,
    value,
  }: {
    key: number;
    label: string;
    value: string;
  }) => {
    return (
      <MenuItem
        tabIndex={-1}
        key={key}
        value={value}
        sx={{
          paddingY: '10px',
          paddingX: '14px',
          marginBottom: '4px',
          height: '44px',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box
            width={'80%'}
            display={'flex'}
            gap={1}
            alignItems={'center'}
            title={label}
          >
            {renderLabel(label, true)}
          </Box>

          {resourceType === value ? (
            <Check sx={{ color: '#7F56D9' }} />
          ) : (
            <Box width={'24px'}></Box>
          )}
        </Box>
      </MenuItem>
    );
  };

  return (
    <Select
      sx={{ width: '220px', height: '40px' }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        sx: {
          '& .MuiMenu-paper': {
            maxHeight: '350px',
            width: '445px',
            overflowY: 'auto',
          },
        },
      }}
      renderValue={handleRenderValue}
      value={resourceType}
      onChange={handleSelectChange}
    >
      {options.map((opt, index) => {
        return renderMenuItem({
          ...opt,
          key: index,
        });
      })}
    </Select>
  );
};

export default memo(ArchivedResourcesFilterSelect);
