import { ControlPoint } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../../components/CustomTypography';
import React, { useMemo } from 'react';
import { EntityInfo } from '../../../../../../redux/reducers/review_task.reducer';
import CustomTextField from './components/CustomTextField';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { useDispatch, useSelector } from 'react-redux';
import {
  allowEditingTaskSelector,
  getSelectedReviewFilterSectionSelector,
  selectedTaskEntityInfoSelector,
  selectedTextSegmentIdSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import {
  setSelectedTextSegmentIdAction,
  updateEntityInfoAction,
} from '../../../../../../redux/actions/review_task.action';
import {
  confirmReview,
  getDefaultPDFPage,
  getNewTextSegmentId,
} from '../../../../../../utils/ReviewTaskUtils';
import { tokenValuesUtil } from '../../../../../../utils/TokenValuesUtil';
import { EntityFilter } from '../../../../../../utils/constants';

interface Props {
  entity: EntityInfo;
  task: Task;
  scale: number;
}

const FloatingModalBody: React.FC<Props> = ({ entity, task, scale }) => {
  const selectedTextSegmentId = useSelector(selectedTextSegmentIdSelector);
  const dispatch = useDispatch();
  const entitiesInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);

  const handleAddTextSegment = () => {
    const textSegments = {
      ...entity.textSegments[selectedTextSegmentId as string],
    };
    const newTextSegmentId = getNewTextSegmentId();
    const page =
      textSegments?.page === undefined
        ? getDefaultPDFPage(scale, task)
        : textSegments.page;
    // Create the new entity info with updated text segments
    const entityInfo: EntityInfo = {
      ...entity,
      textSegments: {
        ...entity.textSegments,
        [newTextSegmentId]: {
          ...textSegments,
          id: newTextSegmentId,
          entityId: entity.id,
          page,
          text: '',
          // do not generate bounding box
          vertices: [],
          normalizedVertices: [],
        },
      },
    };

    dispatch(updateEntityInfoAction(entityInfo));
    dispatch(setSelectedTextSegmentIdAction(newTextSegmentId));
  };

  const handleDeleteTextSegment = (segmentIndex: string) => {
    const textSegments = { ...entity.textSegments };
    const segmentKeys = Object.keys(textSegments);
    const indexOfDeletedSegment = segmentKeys.indexOf(segmentIndex);
    // Determine the new active segment index
    // If the deleted segment is not the first one, select the previous segment
    // Otherwise, select the next segment
    const newActiveSegmentIndex =
      segmentKeys[indexOfDeletedSegment - 1] ||
      segmentKeys[indexOfDeletedSegment + 1];

    dispatch(setSelectedTextSegmentIdAction(newActiveSegmentIndex));
    delete textSegments[segmentIndex];
    const entityInfo = { ...entity, textSegments };
    dispatch(updateEntityInfoAction(entityInfo));
  };

  const handleConfirm = () => {
    if (allowEditingTask) {
      confirmReview(
        entity,
        task,
        entitiesInfoMap,
        selectedReviewFilterSection as EntityFilter,
      );
    }
  };

  const _matchOCR = useMemo(() => {
    for (const segment of Object.values(entity.textSegments)) {
      // IF THE TEXT STORED IS SEGMENT AND TEXT CAPTURED THROUGH B-BOX IS DIFFERENT
      if (
        tokenValuesUtil.removeTrailingPunctuations(segment.text) !==
        segment.textFromTokens?.trim()
      ) {
        return false;
      }
    }
    return true;
  }, [entity, entity?.textSegments]);

  const getErrorForDisplay = () => {
    if (!allowEditingTask) {
      return '';
    }
    let error = '';
    if (!_matchOCR) {
      error += 'Warning: the value is different from the auto detected value';
    }
    if (entity.isNormalizationFailed) {
      error +=
        error.length > 0
          ? '. Normalization Failed'
          : 'Warning: Normalization failed';
    }
    return error;
  };

  return (
    <>
      <Box>
        {Object.keys(entity.textSegments).length && (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <CustomTypography
                lineHeight='20px'
                size={'14px'}
                color='#344054'
                sx={{ py: '8px' }}
                weight={500}
              >
                Value
              </CustomTypography>
              {allowEditingTask && (
                <IconButton
                  title='Add new highlight'
                  onClick={handleAddTextSegment}
                  sx={{
                    borderRadius: '5px !important',
                    p: '4px !important',
                  }}
                >
                  <ControlPoint
                    fontSize='small'
                    sx={{ color: '#101828', marginRight: '2px' }}
                  />
                  <CustomTypography
                    lineHeight='20px'
                    size={'12px'}
                    color='#101828'
                    weight={600}
                  >
                    Highlight in doc
                  </CustomTypography>
                </IconButton>
              )}
            </Box>
            <Box flex={1}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems='center'
                overflow={'auto'}
              >
                {Object.keys(entity.textSegments).map((key) => (
                  <CustomTextField
                    isAutoFocus={Object.keys(entity.textSegments)?.[0] == key}
                    key={key}
                    entity={entity}
                    value={entity.textSegments[key]?.text
                      ?.trim()
                      .replace(/\n/g, ' ')}
                    segmentIndex={key}
                    taskStatus={task.status as TaskSTATUS}
                    handleDeleteTextSegment={handleDeleteTextSegment}
                    onPressEnter={handleConfirm}
                  />
                ))}
              </Box>
              {getErrorForDisplay() && (
                <Box sx={{ pb: '10px', lineHeight: '16px', marginTop: '2px' }}>
                  <CustomTypography
                    component={'span'}
                    role='span'
                    typographyType={TypographyType.Label}
                    weight={500}
                    sx={{ alignSelf: 'start' }}
                    color='#B42318'
                  >
                    {getErrorForDisplay()}
                  </CustomTypography>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(FloatingModalBody);
