import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import store from './redux/store';
import getTheme from './theme';
import { Provider } from 'react-redux';
import OptionsApp from './pages/Options/Options';
import { Box, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sentryService } from './services/SentryService';

sentryService.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// recommended approach to create routes
const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <OptionsApp />
      </Box>
    ),
  },
]);
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <ToastContainer
        hideProgressBar
        style={{ width: 'fit-content', maxWidth: '400px', minWidth: '320px' }}
        pauseOnFocusLoss={false}
        toastStyle={{ backgroundColor: '#f2ffeb', border: '1px solid #72be47' }}
      />
      <ThemeProvider theme={getTheme()}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
