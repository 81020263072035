import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  styled,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAlpha,
  formatDate,
  groupTasksByError,
  handleSelectAllClick,
  handleTaskCheckboxClick,
  isAdmin,
  isSelectAllChecked,
  isTaskSelected,
} from '../../utils/helpers';
import {
  clearDeleteTaskAction,
  deleteTaskAction,
  listTasksAction,
  listTasksErrorAction,
  setCompletedTaskSuccessAction,
} from '../../redux/actions/taskV2.action';
import {
  completedTaskSuccessSelector,
  deleteTaskErrorSelector,
  deletingTaskSelector,
  listTasksErrorSelector,
  tasksListSelector,
  tasksLoadingSelector,
  tasksTotalSizeSelector,
  tasksFailedToDeleteSelector,
  tasksDeletedSuccessfullySelector,
} from '../../redux/selectors/taskV2.selectors';
import {
  DeleteBatchTasksRequest,
  ListTasksRequest,
  Review,
  Task,
} from 'protos/pb/v1alpha2/tasks_service';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { notification } from 'antd';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import { clearReviewState } from '../../redux/actions/review_task.action';
import WorkflowFilterSelect from '../../pages/PendingTasks/WorkflowFilterSelect';
import {
  composeTaskFiltersAndUpdateNetworkURL,
  getWorkflowIdsFromParamString,
} from '../Utils/taskV2Utils';
import {
  ActionType,
  DeleteTaskType,
  DISPLAY_NAME_PREFIX,
  ELLIPSIS_STYLE,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import SearchTaskField from '../../pages/PendingTasks/SearchTaskField';
import CustomTableLabel from '../../components/CustomTableLabel';
import { ReactComponent as DeleteIcon } from '../../static/icons/deleteIcon.svg';
import TaskDeletionModal from '../PendingTasks/TaskDeletionModal';
import '../../styles/table.css';
import TaskHeader from '../PendingTasks/TaskHeader';
import TaskCheckbox from '../PendingTasks/TaskCheckbox';
import UserCard from '../../components/UserCard';
import ReviewerMenu from '../../components/ReviewerMenu';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
  },
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));

const CompletedTasks: React.FC = () => {
  const navigate = useNavigate();
  const loading = useSelector(tasksLoadingSelector);
  const tasks = useSelector(tasksListSelector);
  const totalSize = useSelector(tasksTotalSizeSelector) ?? 0;
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const listTasksError = useSelector(listTasksErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const pagedTasks = tasks?.slice(page * pageSize, (page + 1) * pageSize) ?? [];
  const workflowColors = useSelector(workflowColorsSelector);
  const user = useSelector(loggedInUserSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const [deleteType, setDeleteType] = useState<ActionType | null>(null);
  const completedTaskSuccess = useSelector(completedTaskSuccessSelector);
  const urlSearchParams = new URLSearchParams(location.search);
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamString(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? undefined,
    ),
  );
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]); // for batch delete
  const [selectedTask, setSelectedTask] = useState<Task | null>(null); // for single delete
  const deletingTask = useSelector(deletingTaskSelector);
  const deleteTaskError = useSelector(deleteTaskErrorSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );
  // to display reviewers Menu
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openMessage = () => {
    api.success({
      message: 'Success',
      description: 'Task is completed successfully.',
      placement: 'topRight',
    });
  };

  const openSuccess = (message: string, autoClose = true) => {
    api.success({
      message: 'Notification',
      description: message,
      placement: 'topRight',
      duration: autoClose ? 4.5 : null,
    });
  };

  const openBatchTaskDeleteError = (
    groupedErrorTasks: {
      errorMsg: string;
      taskNames: string[];
    }[],
  ) => {
    api.error({
      message: 'Error',
      description: (
        <div>
          {groupedErrorTasks.map((e, index) => (
            <div key={index}>
              <p>
                {`${e.taskNames.length} ${
                  e.taskNames.length > 1 ? 'Tasks were' : 'Task was'
                } not deleted due to: `}
                <p style={{ fontWeight: 500, display: 'inline' }}>
                  {e.errorMsg}:
                </p>{' '}
              </p>
              <ul>
                {e.taskNames.map((n) => (
                  <li key={n}>{n}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
      placement: 'topRight',
      duration: null,
    });
  };

  useEffect(() => {
    if (completedTaskSuccess) {
      openMessage();
      dispatch(setCompletedTaskSuccessAction(undefined));
    }
  }, [completedTaskSuccess]);

  useEffect(() => {
    if (listTasksError) {
      openError(listTasksError);
      dispatch(listTasksErrorAction());
    }
  }, [listTasksError]);

  useEffect(() => {
    return () => setDisplayNamePrefix('');
  }, [selectedOrgInfo]);

  const listTasks = (
    pageNumber: number,
    rowsPerPage: number,
    refresh: boolean,
  ) => {
    const req = buildRequest();
    req.pageNumber = pageNumber;
    req.pageSize = rowsPerPage;
    dispatch(listTasksAction(req, refresh));
  };

  useEffect(() => {
    setPage(0);
    listTasks(1, pageSize, true); // refresh the list
    setSelectedTasks([]); // empty the selection upon filter change
  }, [selectedOrgInfo, selectedWorkflows, displayNamePrefix]);

  const buildRequest = () => {
    const req: ListTasksRequest = {};
    req.filter = composeTaskFiltersAndUpdateNetworkURL(
      'status=COMPLETED',
      user?.email as string,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
    );
    req.parent = selectedOrgInfo?.orgResourceName;
    return req;
  };

  useEffect(() => {
    if (tasks) {
      const workflowNames = tasks.map(
        (t) => t.workflowDisplayName || t.organizationResourceName,
      );
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [tasks]);

  // For System or internal Error
  useEffect(() => {
    if (deleteTaskError) {
      openError(deleteTaskError);
      dispatch(clearDeleteTaskAction());
    }
  }, [deleteTaskError]);

  useEffect(() => {
    if (tasksFailedToDelete!.length > 0) {
      openBatchTaskDeleteError(groupTasksByError(tasksFailedToDelete!));
      dispatch(clearDeleteTaskAction());
    }
  }, [tasksFailedToDelete]);

  useEffect(() => {
    if (tasksDeletedSuccessfully!.length > 0) {
      openSuccess(
        tasksDeletedSuccessfully!.length > 1
          ? `${tasksDeletedSuccessfully!.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
      );
      dispatch(clearDeleteTaskAction());

      // set page number to zero and refresh the whole list
      setPage(0);
      listTasks(1, pageSize, true);
    }
  }, [tasksDeletedSuccessfully]);

  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    const req: DeleteBatchTasksRequest = {
      names: itemsToDelete.map((item) => item.name) as string[],
      deletedReason: deleteReason,
    };
    dispatch(deleteTaskAction({ deleteType: DeleteTaskType.COMPLETED, req }));
    setSelectedTasks([]);
    handleCloseDeletionModal();
  };

  const handleCloseDeletionModal = () => {
    setDeleteType(null);
    setSelectedTask(null);
  };

  const renderDeleteIcon = (t: Task) => {
    return (
      <IconButton
        sx={{ ml: '20px' }}
        aria-label='Delete Task'
        onClick={(e) => {
          e.stopPropagation();
          setSelectedTask(t);
          setDeleteType(ActionType.SINGLE);
        }}
      >
        <DeleteIcon color='#6B6B6B' />
      </IconButton>
    );
  };

  return (
    <Box paddingX={'60px'} paddingY={'60px'}>
      {contextHolder}
      <WorkflowFilterSelect
        selectedWorkflows={selectedWorkflows}
        setSelectedWorkflows={setSelectedWorkflows}
      />
      <SearchTaskField
        sx={{ '& .MuiOutlinedInput-input': { paddingY: '10.5px' } }}
        displayNamePrefix={displayNamePrefix}
        setDisplayNamePrefix={setDisplayNamePrefix}
      />
      <TaskHeader
        pageTitle={'Completed Tasks'}
        selectedItems={selectedTasks}
        onCancel={() => setSelectedTasks([])}
        secondaryLabel={'Delete'}
        onSecondaryClick={() => setDeleteType(ActionType.BATCH)}
        primaryLabel={''}
      />
      {/* <Box
        display={'flex'}
        marginTop={'30px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={'20px'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <CustomSearchField />
          <CustomFilterSelect
            filters={[
              { value: 'all', label: 'All Tasks' },
              { value: 'all2', label: 'All Tasks2' },
            ]}
            value={'all'}
          />
          <CustomFilterSelect
            filters={[
              { value: 'all', label: 'All Confidence' },
              { value: 'all2', label: 'All Confidence2' },
            ]}
            value={'all'}
          />
        </Box>
      </Box> */}
      {/* Removed this UI that displays Completed Tasks Label as it is already present above*/}
      {/* <Box
        width={'127px'}
        height={'41px'}
        marginTop={'30px'}
        bgcolor={'#f2f8fe'}
        borderBottom={'4px solid #1668f6'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <CustomTypography
          color="#545454"
          typographyType={TypographyType.Header4}
        >
          Completed
        </CustomTypography>
      </Box> */}
      <Box>
        {loading || deletingTask ? (
          <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ marginTop: '12px' }}>
              <Table
                id={'completed-tasks-table'}
                sx={{ tableLayout: 'fixed' }}
                className={'table-header-style'}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                    {isAdminView && pagedTasks.length > 0 && (
                      <CustomTableCell sx={{ width: '50px' }} ellipsis={false}>
                        <TaskCheckbox
                          checked={isSelectAllChecked(
                            selectedTasks,
                            pagedTasks,
                          )}
                          title='Select all Completed tasks'
                          onClick={(e) =>
                            handleSelectAllClick(
                              e,
                              selectedTasks,
                              pagedTasks,
                              setSelectedTasks,
                            )
                          }
                        />
                      </CustomTableCell>
                    )}
                    <CustomTableCell title='Task Name'>
                      <CustomTableLabel label='Task Name' />
                    </CustomTableCell>
                    <CustomTableCell title='Workflow'>
                      <CustomTableLabel label='Workflow' />
                    </CustomTableCell>
                    <CustomTableCell title='Time Completed'>
                      <CustomTableLabel label='Time Completed' />
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{
                        width: '10%',
                      }}
                      title='Tag'
                    >
                      <CustomTableLabel label='Tag' />
                    </CustomTableCell>
                    {isAdminView && (
                      <CustomTableCell
                        sx={{
                          width: '30%',
                        }}
                        title='Users'
                      >
                        <CustomTableLabel label='Users' />
                      </CustomTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedTasks.map((s: Task, index: number) => (
                    <StyledTableRow
                      key={index}
                      tabIndex={0}
                      role='row'
                      sx={{
                        cursor: 'pointer',
                        ':hover': { backgroundColor: '#1669F74D' },
                        ':focus': { backgroundColor: '#1669F74D' },
                      }}
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          dispatch(clearReviewState());
                          navigate(`/${s.name}/automation-review`);
                        }
                      }}
                      onClick={() => {
                        dispatch(clearReviewState());
                        navigate(`/${s.name}/automation-review`);
                      }}
                    >
                      {isAdminView && (
                        <CustomTableCell ellipsis={false}>
                          <TaskCheckbox
                            checked={isTaskSelected(s, selectedTasks)}
                            onClick={(e) =>
                              handleTaskCheckboxClick(
                                e,
                                s,
                                selectedTasks,
                                setSelectedTasks,
                              )
                            }
                            sx={{ marginRight: '20px' }}
                          />
                        </CustomTableCell>
                      )}
                      <CustomTableCell
                        title={s.displayName || 'NO DATA'}
                        sx={{
                          ...ELLIPSIS_STYLE,
                          maxWidth: '300px',
                        }}
                      >
                        {s.displayName || 'NO DATA'}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Box
                          title={
                            s.workflowDisplayName || s.organizationResourceName
                          }
                          sx={{
                            width: 'fit-content',
                            maxWidth: '100%',
                            paddingX: '10px',
                            paddingY: '5px',
                            backgroundColor: addAlpha(
                              workflowColors![
                                (s.workflowDisplayName as string) ||
                                  (s.organizationResourceName as string)
                              ],
                              0.4,
                            ),
                            borderRadius: '4px',
                          }}
                        >
                          <CustomTypography
                            typographyType={TypographyType.Label}
                            sx={{
                              ...ELLIPSIS_STYLE,
                            }}
                          >
                            {s.workflowDisplayName ||
                              s.organizationResourceName}
                          </CustomTypography>
                        </Box>
                      </CustomTableCell>
                      <CustomTableCell ellipsis={false}>
                        {s?.completeTime ? formatDate(s.completeTime) : '-'}
                      </CustomTableCell>
                      <CustomTableCell>
                        {s.tags &&
                          s.tags.length > 0 &&
                          s.tags &&
                          s.tags.map((tag) => {
                            return (
                              <Box
                                title={tag[0].toUpperCase() + tag.slice(1)}
                                key={tag}
                                display={'flex'}
                                justifyContent={'center'}
                                paddingX='10px'
                                paddingY='5px'
                                borderRadius={'4px'}
                                width={'fit-content'}
                                maxWidth={'100%'}
                                bgcolor={
                                  tag === 'modified'
                                    ? '#FBC4B9'
                                    : tag === 'copied'
                                      ? '#EBEBEB'
                                      : '#FBBA2366'
                                }
                              >
                                <CustomTypography
                                  sx={{ ...ELLIPSIS_STYLE }}
                                  typographyType={TypographyType.Label}
                                >
                                  {tag[0].toUpperCase() + tag.slice(1)}
                                </CustomTypography>
                              </Box>
                            );
                          })}
                      </CustomTableCell>
                      {isAdminView && (
                        <CustomTableCell
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ ...ELLIPSIS_STYLE, width: '100%' }}>
                            {s.reviews && s.reviews.length > 0 && (
                              <Box display={'flex'}>
                                <Box flexShrink={1} overflow={'hidden'}>
                                  <UserCard
                                    email={
                                      s.reviews[s.reviews.length - 1].reviewer
                                        ?.username ?? ''
                                    }
                                    imageUrl={
                                      s.reviews[s.reviews.length - 1].reviewer
                                        ?.imageUrl
                                    }
                                    fullName={
                                      s.reviews[s.reviews.length - 1].reviewer
                                        ?.fullName
                                    }
                                  />
                                </Box>
                                {s.reviews.length > 1 && (
                                  <Box
                                    role='button'
                                    tabIndex={0}
                                    margin={'2px'}
                                    marginLeft={'28px !important'}
                                    display={'flex'}
                                    flexShrink={0}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAnchorEl(e.currentTarget);
                                        // the reason we need to reverse the array is display
                                        // the reviews in latest to oldest review order
                                        // also we do not need to display the most recent review because it is
                                        // explicitly displayed in UI
                                        const reviews = [...s.reviews!]
                                          ?.reverse()
                                          .slice(1);
                                        setReviews(reviews ?? []);
                                      }
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setAnchorEl(e.currentTarget);
                                      // the reason we need to reverse the array is display
                                      // the reviews in latest to oldest review order
                                      // also we do not need to display the most recent review because it is
                                      // explicitly displayed in UI
                                      const reviews = [...s.reviews!]
                                        ?.reverse()
                                        .slice(1);
                                      setReviews(reviews ?? []);
                                    }}
                                    sx={{
                                      backgroundColor: '#F8F9FC',
                                      borderRadius: '100%',
                                    }}
                                    color={'#363F72'}
                                    fontWeight={500}
                                    width={'36px'}
                                    height={'36px'}
                                    fontSize={'14px'}
                                  >{`+${s.reviews.length - 1}`}</Box>
                                )}
                              </Box>
                            )}
                          </Box>
                          {renderDeleteIcon(s)}
                        </CustomTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <ReviewerMenu
                handleClose={() => {
                  setAnchorEl(null);
                  setReviews([]);
                }}
                reviews={reviews}
                anchorEl={anchorEl}
              />
              {!pagedTasks.length && (
                <CustomTypography
                  sx={{
                    textAlign: 'center',
                    textJustify: 'center',
                    color: '#475467',
                    marginTop: '40px',
                  }}
                >
                  There are no completed tasks available
                </CustomTypography>
              )}
            </TableContainer>
            <CustomPagination
              rowsPerPage={pageSize}
              totalSize={totalSize}
              page={page}
              onRowsPerPageChange={(rows) => {
                setPage(0);
                setSelectedTasks([]);
                // Refresh is needed when rows per page is changes to fetch fresh data
                listTasks(1, rows, true);
                setPageSize(rows);
              }}
              isShowSelectFilter={totalSize > pageSize}
              onPageChange={(p) => {
                setPage(p);
                setSelectedTasks([]);
                if (p >= page && tasks!.length <= p * pageSize) {
                  listTasks(p + 1, pageSize, false); // do not refresh the list
                }
              }}
            />
          </>
        )}
      </Box>
      <TaskDeletionModal
        selectedTasks={
          deleteType === ActionType.BATCH
            ? selectedTasks
            : selectedTask
              ? [selectedTask]
              : []
        }
        open={!!deleteType}
        handleClose={handleCloseDeletionModal}
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default CompletedTasks;
