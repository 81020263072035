import { CreateWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  Workflow,
  WorkflowActionsForReview,
} from 'protos/pb/v1alpha1/orbot_workflow';
import { Action, CreateTaskAction } from 'protos/pb/v1alpha1/orbot_action';
import ObjectId from 'bson-objectid';
import { orbotService } from '../../../services/OrbotService';

export async function copyTemplate(
  template: Workflow,
  orgId: string,
  userId: string,
) {
  const templateIdToWorkflowId = new Map<string, string>();
  const templateIds = await getReferencedTemplateIdsInTopologicalOrder(
    template.id!,
    orgId,
  );
  for (const templateId of templateIds) {
    const workflowId = await createWorkflowFromTemplate(
      templateId,
      templateIdToWorkflowId,
      orgId,
      userId,
    );
    templateIdToWorkflowId.set(templateId, workflowId!);
  }

  return templateIdToWorkflowId.get(template.id!);
}

async function getReferencedTemplateIdsInTopologicalOrder(
  rootTemplateId: string,
  orgId: string,
) {
  const visitedTemplateId = new Set<string>();
  const templateIdsInTopologicalOrder: Array<string> = [];

  const visit = async (templateId: string) => {
    const targetTemplate = await getTemplate(templateId, orgId);
    if (!targetTemplate) {
      throw new Error(
        `Invalid template definition, template ${templateId} does not exist`,
      );
    }

    if (visitedTemplateId.has(templateId)) {
      throw new Error(
        `Invalid template definition, cycle detected in ${JSON.stringify(templateId)}`,
      );
    }

    visitedTemplateId.add(templateId);

    for (const templateId of getReferencedTemplateIdsInCreateTask(
      targetTemplate,
    )) {
      await visit(templateId);
    }

    templateIdsInTopologicalOrder.push(templateId);
  };

  await visit(rootTemplateId);

  return templateIdsInTopologicalOrder;
}

async function createWorkflowFromTemplate(
  templateId: string,
  templateIdToWorkflowId: Map<string, string>,
  orgId: string,
  userId: string,
) {
  // It's possible for a workflow to reference itself with a different process id
  // So we need to create workflow id on the client and update any references first
  // before passing to the create API
  const workflowId = new ObjectId(Math.floor(Date.now() / 1000)).toHexString();
  const workflowObject = Workflow.fromJSON(
    await getTemplate(templateId, orgId)!,
  );
  templateIdToWorkflowId.set(templateId, workflowId);
  updateReferencedTemplateIdsInCreateTask(
    workflowObject,
    templateIdToWorkflowId,
  );

  const createReq: CreateWorkflowRequest = {
    workflow: {
      ...workflowObject,
      orgId,
      reviewerIds: [userId],
      id: workflowId,
      // Default to Assisted review mode with high confidence threshold
      actionsForReview: [WorkflowActionsForReview.LOW_CONFIDENCE_ACTIONS],
      lowConfidenceThreshold: 0.8,
    },
  };

  const workflow = await orbotService.createWorkflow(createReq);
  return workflow.id;
}

function getReferencedTemplateIdsInCreateTask(template: Workflow) {
  const templateAndProcessIndex: Map<string, Array<string>> = new Map();
  template.processes?.forEach((process) =>
    process.actions?.forEach((action) =>
      processAction(action, (createTaskAction) => {
        const processId = createTaskAction.processId ?? 'default';
        if (templateAndProcessIndex.has(createTaskAction.workflowId!)) {
          // Template can reference itself with different process id
          const processIndices = templateAndProcessIndex.get(
            createTaskAction.workflowId!,
          )!;
          if (processIndices.includes(processId)) {
            throw new Error(
              `Invalid template definition, cycle detected in ${JSON.stringify(template.id)}`,
            );
          } else {
            processIndices.push(processId);
          }
        } else {
          templateAndProcessIndex.set(createTaskAction.workflowId!, [
            processId,
          ]);
        }
      }),
    ),
  );
  return Array.from(templateAndProcessIndex.keys()).filter(
    (id) => id !== template.id,
  );
}

function updateReferencedTemplateIdsInCreateTask(
  workflow: Workflow,
  templateIdToWorkflowId: Map<string, string>,
) {
  workflow.processes?.forEach((process) =>
    process.actions?.forEach((action) =>
      processAction(action, (createTaskAction) => {
        if (!templateIdToWorkflowId.has(createTaskAction.workflowId!)) {
          throw new Error(
            `Template ${createTaskAction.workflowId} is expected to be created before ${workflow.id}`,
          );
        }
        createTaskAction.workflowId = templateIdToWorkflowId.get(
          createTaskAction.workflowId!,
        );
      }),
    ),
  );
}

function processAction(
  action: Action,
  handler: (createTaskAction: CreateTaskAction) => void,
) {
  const { createTask, foreach, condition, block } = action;
  if (createTask) {
    handler(createTask);
  }

  if (foreach) {
    foreach.loopActions?.forEach((subAction) =>
      processAction(subAction, handler),
    );
  }

  if (condition) {
    condition.thenActions?.forEach((subAction) =>
      processAction(subAction, handler),
    );
    condition.elseActions?.forEach((subAction) =>
      processAction(subAction, handler),
    );
  }

  if (block) {
    block.actions?.forEach((subAction) => processAction(subAction, handler));
  }
}

async function getTemplate(
  templateId: string,
  orgId: string,
): Promise<Workflow | undefined> {
  const { response } = await orbotService.getWorkflowTemplate({
    templateId,
    orgId,
  });
  return response;
}
