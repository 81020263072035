import { Box } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import React from 'react';
import { useSelector } from 'react-redux';
import { getConfidenceScoreSelector } from '../../../../../redux/selectors/review_task.selectors';

const ConfidenceScore: React.FC = () => {
  const confidenceScore = useSelector(getConfidenceScoreSelector);

  return (
    <Box
      borderBottom={1}
      paddingBottom={'12px'}
      display={'flex'}
      borderColor={'rgba(209, 209, 209, 1)'}
    >
      <CustomTypography
        sx={{ marginTop: '20px', marginLeft: '30px' }}
        typographyType={TypographyType.Bolder}
      >
        {/* 
          TODO: This will be updated once state management is implemented
        */}
        {confidenceScore}%
      </CustomTypography>
      <Box marginTop={'33px'} marginLeft={3} letterSpacing={'4%'}>
        <CustomTypography typographyType={TypographyType.Header4}>
          Orby’s confidence score
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default React.memo(ConfidenceScore);
