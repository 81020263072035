import * as React from 'react';

import { ItemDescription, WidgetContainer } from './Styled';
import { ActionObject } from 'workflow-utils/src/types';
import { EditableText } from './EditableText';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { useDispatch } from 'react-redux';
import { ProcessContext } from './process-context';
import { getActionById } from 'workflow-utils/src/v2/workflow';

export interface ClickableWidgetProps {
  action: ActionObject;
  isEditable: boolean;
  processId?: string;
}

const SetValueActionDescription: React.FC<ClickableWidgetProps> = ({
  action,
  isEditable,
  processId,
}) => {
  const dispatch = useDispatch();

  if (!action.setValue) {
    return null;
  }

  const referencedId = action.setValue.fieldValue?.referenceValue;

  const actions = React.useContext(ProcessContext);

  let fieldValue: string | null = null;
  let isSmartText = false;
  let smartTextAction: ActionObject | null = null;

  if (referencedId) {
    const referencedAction = getActionById(referencedId, actions);
    isSmartText = !!referencedAction?.jsFunction;
    if (isSmartText) {
      smartTextAction = referencedAction!;
    }
    fieldValue = isSmartText ? referencedAction!.description! : null;
  } else {
    fieldValue = action.setValue.fieldValue?.jsonValue
      ? JSON.parse(action.setValue.fieldValue.jsonValue)
      : null;
  }

  const onTextEditFinish = React.useCallback(
    (newFieldValue: string) => {
      // TODO: Regenerate and update JS function for smart text
      if (isSmartText) {
        dispatch(
          updateAction(
            smartTextAction!,
            {
              action: { ...smartTextAction },
              description: newFieldValue,
            },
            processId,
          ),
        );
        dispatch(
          updateAction(
            action,
            {
              action: { ...action },
              description: `Fill in ${newFieldValue}`,
            },
            processId,
          ),
        );
      } else {
        const updated = {
          description: action!.description!.replace(fieldValue!, newFieldValue),
          action: {
            ...action,
            setValue: {
              ...action.setValue,
              fieldValue: {
                jsonValue: JSON.stringify(newFieldValue),
              },
            },
          },
        };
        dispatch(updateAction(action, updated, processId));
      }
    },
    [action],
  );

  return (
    <WidgetContainer sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {fieldValue ? (
        <>
          <ItemDescription>Fill in field with value:</ItemDescription>
          <EditableText
            initialText={fieldValue}
            disabled={!isEditable}
            variant='inline'
            fontSize={18}
            onTextEditFinish={onTextEditFinish}
          />
        </>
      ) : (
        <ItemDescription>{action.description}</ItemDescription>
      )}
    </WidgetContainer>
  );
};

export default SetValueActionDescription;
