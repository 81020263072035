import { Box, Button, Stack, SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import CustomTypography from '../../components/CustomTypography';
import { OrbyButton } from 'orby-ui/src';
import { retryTasksLoadingSelector } from '../../redux/selectors/taskV2.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { retryTasksAction } from '../../redux/actions/taskV2.action';
import { RetryTasksRequest } from 'protos/pb/v1alpha2/tasks_service';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';

interface TaskHeaderProps {
  pageTitle: string;
  selectedItems: Array<any>;
  onCancel: () => void;
  primaryLabel: string;
  secondaryLabel: string;
  tertiaryLabel?: string;
  onPrimaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSecondaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTertiaryClick?: React.MouseEventHandler<HTMLButtonElement>;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  primaryButtonSx?: SxProps<Theme>;
  secondaryButtonSx?: SxProps<Theme>;
  isDeclined?: boolean;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({
  selectedItems,
  pageTitle,
  onCancel,
  onPrimaryClick,
  onSecondaryClick,
  onTertiaryClick,
  primaryLabel,
  primaryButtonSx,
  secondaryLabel,
  tertiaryLabel,
  primaryDisabled,
  secondaryButtonSx,
  secondaryDisabled,
  isDeclined = false,
}) => {
  const dispatch = useDispatch();
  const isItemsSelected = useMemo(
    () => selectedItems.length > 0,
    [selectedItems],
  );
  const retryLoading = useSelector(retryTasksLoadingSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const handleRetryTasks = () => {
    const req = RetryTasksRequest.fromJSON({
      orgResourceName: selectedOrgInfo?.orgResourceName,
      names: selectedItems.map((t) => t.name),
    });
    dispatch(retryTasksAction(req));
  };

  return (
    <Box
      height={'56px'}
      marginTop={'12px'}
      display={'flex'}
      bgcolor={isItemsSelected ? '#F5FAFF' : 'transparent'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderRadius={'4px'}
      boxShadow={
        isItemsSelected ? '0px 1px 4px 0px rgba(5, 0, 255, 0.16)' : 'none'
      }
    >
      <Stack direction='row' alignItems={'center'} marginLeft={'12px'}>
        <CustomTypography size='18px' weight={600} color={'black'}>
          {pageTitle}
        </CustomTypography>

        {isItemsSelected && (
          <>
            <CustomTypography
              sx={{ marginLeft: '48px' }}
              size='16px'
              weight={600}
              color={'#101828'}
            >
              {selectedItems.length}
            </CustomTypography>
            <CustomTypography
              sx={{ marginLeft: '8px' }}
              size='16px'
              weight={500}
              color={'#101828'}
            >
              selected on this page
            </CustomTypography>
            <Button
              variant='text'
              sx={{ marginLeft: '16px' }}
              onClick={onCancel}
            >
              <CustomTypography size='16px' weight={600} color={'#0500FF'}>
                Cancel
              </CustomTypography>
            </Button>
          </>
        )}
      </Stack>

      {isItemsSelected && (
        <Stack spacing={2} direction='row' marginRight={'24px'}>
          {tertiaryLabel && (
            <Button
              onClick={onTertiaryClick}
              sx={{
                borderRadius: '40px',
                borderColor: '#0500FF',
              }}
              variant='outlined'
            >
              <CustomTypography size='14px' weight={600} color={'#0500FF'}>
                {tertiaryLabel}
              </CustomTypography>
            </Button>
          )}

          {secondaryLabel && (
            <Button
              onClick={onSecondaryClick}
              disabled={secondaryDisabled}
              sx={{
                borderRadius: '40px',
                borderColor: '#0500FF',
                ...secondaryButtonSx,
              }}
              variant='outlined'
            >
              <CustomTypography size='14px' weight={600} color={'#0500FF'}>
                {secondaryLabel}
              </CustomTypography>
            </Button>
          )}

          {primaryLabel && (
            <Button
              onClick={onPrimaryClick}
              disabled={primaryDisabled}
              sx={{
                borderRadius: '40px',
                backgroundColor: '#0500FF',
                ...primaryButtonSx,
              }}
              variant='contained'
            >
              <CustomTypography size='14px' weight={600} color={'white'}>
                {primaryLabel}
              </CustomTypography>
            </Button>
          )}
          {isDeclined && (
            <OrbyButton
              onClick={handleRetryTasks}
              ariaLabel='Retry'
              variant='primary-contained'
              label='Retry'
              loading={retryLoading}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default React.memo(TaskHeader);
