import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
} from '@mui/material';
import CustomComboButtons from '../../components/CustomComboButtons';
import CustomTextField from '../../components/CustomTextField';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import { User, UserOrgRole } from 'protos/pb/v1alpha1/user';
import {
  AddUserToOrganizationRequest,
  UpdateRequest,
} from 'protos/pb/v1alpha1/users_service';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserToOrganizationAction,
  addUserToOrganizationErrorAction,
  setAddedUserAction,
  setUpdatedUserAction,
  updateUserAction,
  updateUserErrorAction,
} from '../../redux/actions/user.action';
import {
  addUserErrorSelector,
  addedUserSelector,
  createUserLoadingSelector,
  selectedOrgInfoSelector,
  updateUserErrorSelector,
  updateUserLoadingSelector,
  updatedUserSelector,
} from '../../redux/selectors/user.selectors';
import * as Yup from 'yup';
import { IS_PROD } from '../../utils/constants';
import { Close } from '@mui/icons-material';
import { shiftFocus } from '../../utils/FocusUtils';

interface Props {
  edit?: boolean;
  user?: User;
  open: boolean;
  setOpen: (open: boolean) => void;
  openError: (error: Error) => void;
  onSuccess: (message: string) => void;
  isRbacEnabled?: boolean;
}

const AddUserModal: React.FC<Props> = ({
  open,
  setOpen,
  edit = false,
  user,
  openError,
  onSuccess: openSuccess,
  isRbacEnabled = false,
}) => {
  const dispatch = useDispatch();
  const [creationTriggered, setCreationTriggered] = useState(false);
  const addUserError = useSelector(addUserErrorSelector);
  const addedUser = useSelector(addedUserSelector);
  const updateUserError = useSelector(updateUserErrorSelector);
  const updatedUser = useSelector(updatedUserSelector);
  const updateLoading = useSelector(updateUserLoadingSelector);
  const createLoading = useSelector(createUserLoadingSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const loading = updateLoading || createLoading;
  const [menuOpen, setMenuOpen] = useState(false);
  const [userObject, setUserObject] = useState({ email: '', role: '' });
  const boxRef = useRef<HTMLDivElement>(null);

  const getUserRoleForSelectedOrg = (user: User) => {
    return user?.orgInfos?.find(
      (i) => i.orgResourceName === selectedOrgInfo?.orgResourceName,
    )?.role;
  };

  const getRole = (role: UserOrgRole | undefined) => {
    if (role === UserOrgRole.ROLE_ADMIN) {
      return 'ROLE_ADMIN';
    } else if (role === UserOrgRole.ROLE_CREATOR) {
      return 'ROLE_CREATOR';
    } else if (role === UserOrgRole.ROLE_USER) {
      return 'ROLE_USER';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (user && user?.email !== '') {
      const userObject = {
        email: user.email as string,
        role: getRole(getUserRoleForSelectedOrg(user)),
      };
      setUserObject(userObject);
    }
  }, [user]);

  useEffect(() => {
    if (addUserError && !edit) {
      openError(addUserError);
      dispatch(addUserToOrganizationErrorAction(undefined));
    }
  }, [addUserError]);

  useEffect(() => {
    if (updateUserError && edit) {
      openError(updateUserError);
      dispatch(updateUserErrorAction(undefined));
    }
  }, [updateUserError]);

  useEffect(() => {
    if (creationTriggered && addedUser) {
      openSuccess('User ' + addedUser.email + ' is added successfully');
      setTimeout(() => {
        dispatch(setAddedUserAction(undefined));
        setCreationTriggered(false);
        setOpen(false);
        formik.resetForm();
      }, 1000);
    }
  }, [creationTriggered, addedUser]);

  useEffect(() => {
    if (creationTriggered && updatedUser) {
      openSuccess('User ' + updatedUser.email + ' is updated successfully');
      setTimeout(() => {
        dispatch(setUpdatedUserAction(undefined));
        setCreationTriggered(false);
        setOpen(false);
      }, 1000);
    }
  }, [creationTriggered, updatedUser]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: userObject?.email,
      role: userObject?.role,
      sendEmail: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required.'),
      role: Yup.string().required('Role is required.'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setCreationTriggered(true);
      if (edit) {
        const req: UpdateRequest = {};
        req.orgResourceName = selectedOrgInfo?.orgResourceName as string;
        const u = { ...(user as User) };
        const index: number = u?.orgInfos?.findIndex(
          (o) =>
            o.orgResourceName === (selectedOrgInfo?.orgResourceName as string),
        ) as number;
        if (u.orgInfos?.[index]) {
          const updatedOrgInfos = [...u.orgInfos]; // Shallow copy of orgInfos array
          updatedOrgInfos[index] = {
            ...updatedOrgInfos[index], // Shallow copy of the object at the given index
            role: UserOrgRole[values.role as keyof typeof UserOrgRole], // Update the role property
          };
          // u.orgInfos[index].role = UserOrgRole[values.role as keyof typeof UserOrgRole]
          const changedOrgInfo = updatedOrgInfos[index];
          u.orgInfos = [changedOrgInfo];
        }
        u.email = values.email.toLowerCase().trim();
        req.user = u;
        req.fieldMask = ['org_infos'];
        dispatch(updateUserAction(req));
      } else {
        const req: AddUserToOrganizationRequest = {};
        req.email = values.email.toLowerCase().trim();
        req.role = UserOrgRole[values.role as keyof typeof UserOrgRole];
        req.orgResourceName = selectedOrgInfo?.orgResourceName as string;
        req.sendEmail = values.sendEmail;
        dispatch(addUserToOrganizationAction(req));
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  // To Trap Focus within the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const boxContentElement = boxRef.current;
        if (boxContentElement) {
          const focusableElements = boxContentElement.querySelectorAll(
            'button, input:not([aria-hidden="true"]), [tabindex]:not([tabindex="-1"])',
          );
          if (focusableElements.length > 0) {
            shiftFocus(focusableElements, event);
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (menuOpen || open) {
      const elm = document.getElementById('root');
      elm?.removeAttribute('aria-hidden');
    }
  }, [menuOpen || open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      ref={boxRef}
      PaperProps={{ style: { width: '590px' } }}
      open={open}
      onClose={() => {
        formik.resetForm();
        setOpen(false);
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: '16px',
          py: '8px',
          pl: '24px',
        }}
      >
        <CustomTypography size={'16px'} weight={500}>
          {edit ? 'Edit' : 'Add User'}
        </CustomTypography>
        <IconButton
          aria-label='close'
          onClick={() => {
            formik.resetForm();
            setOpen(false);
          }}
        >
          <Close fontSize={'small'} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogContent>
        <FormikProvider value={formik}>
          <Form
            id='form1'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Box width={'100%'} marginBottom={'15px'}>
              <CustomTextField
                ariaHidden={edit}
                label='Email'
                name='email'
                size='full'
                value={formik.initialValues.email}
                extraProps={getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                disabled={edit}
                color={edit ? '#EBEBE4' : ''}
              />
              {!(IS_PROD || edit) && (
                <FormControlLabel
                  checked={formik.values.sendEmail}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        formik.setFieldValue('sendEmail', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <CustomTypography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                      }}
                    >
                      Send Email
                    </CustomTypography>
                  }
                />
              )}
              <Box marginTop={'11px'}>
                <FormControl
                  size='small'
                  sx={{ width: '100%' }}
                  error={Boolean(touched.role && errors.role)}
                >
                  <Box id={'role-label'} display={'none'} tabIndex={-1}>
                    {values.role ? values.role + 'selected' : 'Select Role'}
                  </Box>
                  <CustomTypography
                    id='Select Role'
                    component={'label'}
                    typographyType={TypographyType.Label}
                    color='#545454'
                  >
                    Role
                  </CustomTypography>
                  <Select
                    aria-hidden={false}
                    placeholder='Role'
                    labelId='role-label'
                    fullWidth
                    {...getFieldProps('role')}
                    error={Boolean(touched.role && errors.role)}
                    onOpen={() => setMenuOpen(true)}
                    sx={{ marginTop: '5px' }}
                    onClose={() => setMenuOpen(false)}
                  >
                    <MenuItem key={'admin'} value={'ROLE_ADMIN'}>
                      Admin
                    </MenuItem>
                    {isRbacEnabled && (
                      <MenuItem key={'creator'} value={'ROLE_CREATOR'}>
                        Creator
                      </MenuItem>
                    )}
                    <MenuItem key={'user'} value={'ROLE_USER'}>
                      User
                    </MenuItem>
                  </Select>
                  <FormHelperText>{touched.role && errors.role}</FormHelperText>
                </FormControl>
              </Box>
              <Box height={'100px'} />
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogActions>
        <CustomComboButtons
          primaryLabel='Cancel'
          secondaryLabel={edit ? 'Save Changes' : 'Submit'}
          onPrimaryClick={() => {
            formik.resetForm();
            setOpen(false);
          }}
          onSecondaryClick={() => {
            formik.handleSubmit();
          }}
          secondaryDisabled={false}
          secondaryLoading={loading}
          primarySx={{ marginRight: '15px' }}
          secondarySx={{ paddingX: '24px', paddingY: '4px' }}
          size={'medium'}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddUserModal);
