import { createSelector } from 'reselect';
import { reviewTaskSelector } from './app.selectors';
import { Activity } from 'protos/automation_mining/automation_mining';
import { getSelectedTaskDocument } from '../../utils/helpers';
import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { InfoToDetectChanges } from '../../utils/UnsavedChangesUtils';
import { checkIfMultipleCellsSelected } from '../../utils/ReviewTaskUtils';
import _ from 'lodash';

export const taskForReviewSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.task,
);

export const taskForReviewLoadingSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.taskLoading,
);

export const taskForReviewLoadedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.taskLoaded,
);

export const taskForReviewLoadingErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.taskLoadingError,
);

export const selectedTaskEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTaskEntityInfo,
);

export const originalTaskEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.originalTaskEntityInfo,
);

export const selectedEntityIdSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityId,
);

export const selectedEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityInfo,
);

export const selectedTextSegmentInfoSelector = createSelector(
  [reviewTaskSelector, selectedEntityInfoSelector],
  (reviewTaskState, entityInfo) => {
    const id = reviewTaskState.selectedTextSegmentId;
    if (id) {
      return entityInfo?.textSegments[id];
    }
    return undefined;
  },
);

export const selectedTextSegmentIdSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTextSegmentId,
);

export const tokensInDocumentSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.tokensInDocument,
);

export const getConfidenceScoreSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => {
    // Ideally this should be done in the backend.
    // For now handling it here to avoid backend changes.
    // Created a ticket for BE - REF - https://orby-ai.atlassian.net/browse/OA-2113
    if (reviewTaskState.task?.status === TaskSTATUS.COMPLETED) {
      return 100;
    }
    if (reviewTaskState.documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return Math.round(
        (reviewTaskState.predictionResult?.confidence ?? 0) * 100,
      );
    } else {
      let totalConfidenceScore = 0;
      let count = 0;
      const entityInfoMap = reviewTaskState.selectedTaskEntityInfo;
      for (const entityId in entityInfoMap) {
        // Notes entity is not predicted and thus doesn't contribute to average confidence score.
        if (!entityInfoMap[entityId].isExtra) {
          totalConfidenceScore += entityInfoMap?.[entityId]
            .confidenceScore as number;
          count++;
        }
      }
      return count ? Math.round((totalConfidenceScore / count) * 100) : 0;
    }
  },
);

export const getDocumentActivityTypeSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.documentActivityType,
);

export const getClassificationLabelsListSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.classificationLabelsList,
);

export const getPredictedResultSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.predictionResult,
);

export const getOriginalPredictedResultSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => {
    const documentStep = getSelectedTaskDocument(reviewTaskState.task);
    return documentStep?.predictionResult;
  },
);

export const getStartedReviewTime = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.reviewTime,
);

export const getAutomationProgressSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.automationProgressStatus,
);

export const getAutomationCompletedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.isAutomationCompleted,
);

export const getAutomationCompletedErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.automationError,
);

export const getSelectedReviewFilterSectionSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedReviewFilterSection,
);

export const searchTextSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchText,
);

export const searchedTokensInDocumentSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchedTokensInDocument,
);

export const searchedTokensStartIndicesSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchedTokensStartIndices,
);

export const selectedTokenIndexSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.selectedTokenIndex,
);

export const suggestionAnnotationTaskEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.suggestionAnnotationTaskEntityInfo,
);

export const tokenForHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenForHighlight,
);

export const selectedParentEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.selectedParentEntityInfo,
);

export const selectedTableEntitiesInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTableEntitiesInfo,
);

// TODO: Refactor this code base to use Map version for selectedTableEntitiesInfo instead of array version
export const selectedTableEntitiesInfoMapSelector = createSelector(
  [selectedTableEntitiesInfoSelector],
  (selectedTableEntitiesInfo) => _.keyBy(selectedTableEntitiesInfo, 'id'),
);

export const suggestedTableEntitiesInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.suggestedTableEntitiesInfo,
);

export const selectedEntityIdsForAnnotationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityIdsForAnnotation,
);

export const isMultipleCellsSelectedSelector = createSelector(
  [
    selectedEntityIdsForAnnotationSelector,
    selectedTableEntitiesInfoMapSelector,
  ],
  (selectedEntityIdsForAnnotation, selectedTableEntitiesInfoMap) =>
    checkIfMultipleCellsSelected(
      selectedEntityIdsForAnnotation,
      selectedTableEntitiesInfoMap,
    ),
);

export const addLocationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.addLocation,
);

export const lastLocatedEntityTypeSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.lastLocatedEntityType,
);

export const columnOrderInfoForTableEntitiesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.columnOrderInfoForTableEntities,
);

export const hiddenEntityTypesForTableAnnotationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.hiddenEntityTypesForTableAnnotation,
);

// this state contains all info necessary to detect unsaved changes
export const infoToDetectChangesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState): InfoToDetectChanges => {
    return {
      modifiedEntityInfoMap: reviewTaskState.selectedTaskEntityInfo,
      originalEntityInfoMap: reviewTaskState.originalTaskEntityInfo,
      selectedEntityInfo: reviewTaskState.selectedEntityInfo,
      selectedTableChildEntities: reviewTaskState.selectedTableEntitiesInfo,
      selectedParentEntityInfo: reviewTaskState.selectedParentEntityInfo,
    };
  },
);

export const tokenListToHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenListToHighlight,
);

export const tokenListPageToHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenListPageToHighlight,
);

export const rowOrderInfoForTableEntitiesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.rowOrderInfoForTableEntities,
);

export const rowOrderInfoForSelectedParentEntitySelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.rowOrderInfoForTableEntities[
      reviewTaskState.selectedParentEntityInfo?.type || ''
    ] || [],
);

export const entitySidePanelCollapsedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.isEntitySidePanelCollapsed,
);

export const openAddRowModalSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.openAddRowModal,
);

export const suggestionDataSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.suggestionData,
);

export const allowEditingTaskSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.allowEditingTask,
);

export const HitlDataSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.hitlData,
);

export const HitlLoadingSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.hitlDataLoading,
);

export const HitlErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.hitlDataLoadingError,
);
