import React from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { formatDateTime } from '../../../../utils/helpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  WorkflowExecution,
  WorkflowExecutionStatus,
} from 'protos/pb/v1alpha2/workflow_executions_service';
import { TASK_DECLINED_REASON } from '../../../../utils/constants';

const ErrorReasonTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    placement='top-start'
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #999',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 220,
  },
}));

export const EXECUTION_MENU_ACTION_TYPE = {
  RETRY: 'RETRY',
  DELETE: 'DELETE',
};

export const getExecutionCell = (
  execution: WorkflowExecution,
  displayNamePrefix: string,
): JSX.Element => {
  const executionName = execution.displayName || '';

  const startIndex = executionName
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={executionName}
      key={executionName}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {executionName.substring(startIndex, endIndex)}
          </span>
          {executionName.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getExecutionWorkflowDisplayNameCell = (
  execution: WorkflowExecution,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={
        execution.workflowDisplayName ||
        execution.organizationResourceName ||
        'NA'
      }
      key={execution.name}
      label={
        execution.workflowDisplayName ||
        execution.organizationResourceName ||
        'NA'
      }
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

type WorkflowExecutionStatusDetails = {
  color: string;
  label: string;
  backgroundColor: string;
  dotColor: string;
  value: string;
};

export const WorkflowExecutionStatusEnum: Record<
  number,
  WorkflowExecutionStatusDetails
> = {
  [WorkflowExecutionStatus.SCHEDULED]: {
    color: OrbyColorPalette['blueGrey-700'],
    label: 'Waiting for execution',
    value: 'SCHEDULED',
    backgroundColor: OrbyColorPalette['blueGrey-50'],
    dotColor: OrbyColorPalette['blueGrey-500'],
  },
  [WorkflowExecutionStatus.BLOCKED]: {
    color: OrbyColorPalette['warning-700'],
    label: 'Needs Attention',
    value: 'BLOCKED',
    backgroundColor: OrbyColorPalette['warning-50'],
    dotColor: OrbyColorPalette['warning-500'],
  },
  [WorkflowExecutionStatus.EXECUTING]: {
    color: OrbyColorPalette['blue-800'],
    label: 'Execution in process',
    value: 'EXECUTING',
    backgroundColor: OrbyColorPalette['blue-50'],
    dotColor: OrbyColorPalette['blue-600'],
  },
  [WorkflowExecutionStatus.PENDING_REVIEW]: {
    color: OrbyColorPalette['blue-600'],
    label: 'Pending Review',
    value: 'PENDING_REVIEW',
    backgroundColor: OrbyColorPalette['blue-50'],
    dotColor: OrbyColorPalette['blue-400'],
  },
  [WorkflowExecutionStatus.COMPLETED]: {
    color: OrbyColorPalette['green-700'],
    label: 'Completed',
    value: 'COMPLETED',
    backgroundColor: OrbyColorPalette['green-50'],
    dotColor: OrbyColorPalette['green-500'],
  },
  [WorkflowExecutionStatus.CANCELLED]: {
    color: OrbyColorPalette['pink-700'],
    label: 'Cancelled',
    value: 'CANCELLED',
    backgroundColor: OrbyColorPalette['pink-50'],
    dotColor: OrbyColorPalette['pink-500'],
  },
  [WorkflowExecutionStatus.FAILED]: {
    color: OrbyColorPalette['orange-700'],
    label: 'Error',
    value: 'FAILED',
    backgroundColor: OrbyColorPalette['orange-50'],
    dotColor: OrbyColorPalette['orange-500'],
  },
};

export const getExecutionStatusCell = (
  execution: WorkflowExecution,
): JSX.Element | JSX.Element[] => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
      <Box
        key={execution.name}
        title={WorkflowExecutionStatusEnum[execution.status!].label}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'6px'}
        sx={{
          paddingX: '8px',
          paddingY: '2px',
          backgroundColor:
            WorkflowExecutionStatusEnum[execution.status!]?.backgroundColor ||
            OrbyColorPalette['orange-50'],
          borderRadius: '16px',
          width: 'fit-content',
        }}
      >
        <CircleIcon
          sx={{
            width: '6px',
            height: '6px',
            color:
              WorkflowExecutionStatusEnum[execution.status!]?.dotColor ||
              OrbyColorPalette['orange-500'],
          }}
        />
        <OrbyTypography
          size='xs'
          weight='medium'
          color={
            WorkflowExecutionStatusEnum[execution.status!]?.color ||
            OrbyColorPalette['orange-700']
          }
        >
          {WorkflowExecutionStatusEnum[execution.status!]?.label ||
            'UNSPECIFIED'}
        </OrbyTypography>
        {execution.status === WorkflowExecutionStatus.FAILED &&
          execution.obsoleteReason !== undefined &&
          execution.obsoleteReason in TASK_DECLINED_REASON && (
            <ErrorReasonTooltip
              title={
                TASK_DECLINED_REASON[
                  execution.obsoleteReason as keyof typeof TASK_DECLINED_REASON
                ] + '. Please try again'
              }
            >
              <InfoOutlinedIcon
                sx={{
                  fontSize: 'small',
                  color: OrbyColorPalette['orange-700'],
                }}
              />
            </ErrorReasonTooltip>
          )}
      </Box>
    </Box>
  );
};

export const getExecutionTimeCell = (
  execution: WorkflowExecution,
  executionTime?: Date,
): JSX.Element => {
  if (executionTime) {
    const { date, time } = formatDateTime(executionTime);
    return (
      <Box key={execution.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={execution.name}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getExecutionActionMenuCell = (
  execution: WorkflowExecution,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    execution: WorkflowExecution,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={execution.name}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, execution);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const getExecutionActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
  isAdmin: boolean,
  execution: WorkflowExecution | null,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isAdmin && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, EXECUTION_MENU_ACTION_TYPE.DELETE);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Delete
          </OrbyTypography>
        </MenuItem>
      )}
      {execution?.status === WorkflowExecutionStatus.FAILED && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, EXECUTION_MENU_ACTION_TYPE.RETRY);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Retry
          </OrbyTypography>
        </MenuItem>
      )}
    </Menu>
  );
};

export const getExecutionNameColumnWidth = (tableWidth: number) => {
  return (tableWidth - getExecutionTimeWidth(tableWidth) - 178 - 69) / 2;
};

export const getExecutionTimeWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};

/**
 * Handle API Execution Page Change
 */
export const handleApiExecutionPageChange = ({
  executions,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listExecutions,
  setSelectedExecutions,
}: {
  executions: WorkflowExecution[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listExecutions: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedExecutions: React.Dispatch<
    React.SetStateAction<WorkflowExecution[]>
  >;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && executions.length <= pageNumber * rowsPerPage) {
    listExecutions(pageNumber + 1, rowsPerPage, false);
  }
  setSelectedExecutions([]);
};
