import { Box } from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import driveIcon from '../../../../static/icons/driveIcon.svg';
import docsIcon from '../../../../static/icons/Docs.svg';
import folderIcon from '../../../../static/icons/folder.svg';
import useDrivePicker from 'react-google-drive-picker';
import { CheckCircleRounded, WarningRounded } from '@mui/icons-material';
import { ReactComponent as SettingsIcon } from '../../../../static/icons/settings-rounded.svg';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import * as Yup from 'yup';
import { storageService } from '../../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  updateGoogleTokenCompletedSelector,
  updateGoogleTokenErrorSelector,
} from '../../../../redux/selectors/user.selectors';
import {
  getCustomFoldersListView,
  getTriggerIndex,
  withGoogleLoginScopeCheck,
} from '../../../../utils/helpers';
import { ApplicationName } from '../../../../utils/protos/enums';
import { GOOGLE_DRIVE_SCOPE } from '../../../../utils/constants';
import { toastService } from '../../../../services/ToastService';
import {
  clearGoogleTokenUpdateStatusAction,
  updateGoogleTokenErrorAction,
} from '../../../../redux/actions/user.action';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';
import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';

interface TriggerConditionContentProps {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  edit?: boolean;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

const TriggerConditionContent: FC<TriggerConditionContentProps> = ({
  workflow,
  onSubmit,
  edit = false,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  const [openPicker] = useDrivePicker();
  const user = useSelector(loggedInUserSelector);
  const updateGoogleTokenCompleted = useSelector(
    updateGoogleTokenCompletedSelector,
  );

  const updateGoogleTokenError = useSelector(updateGoogleTokenErrorSelector);

  const gDriveIndex = getTriggerIndex(workflow, ApplicationName.GoogleDrive);
  const dispatch = useDispatch();

  const folderUrl =
    (workflow?.steps?.length &&
      workflow.steps[gDriveIndex.stepIndex as number]?.triggers?.length &&
      workflow.steps[gDriveIndex.stepIndex as number]?.triggers?.[
        gDriveIndex.triggerIndex as number
      ]?.gdrive?.trigger?.file?.path) ||
    '';
  const fileId =
    (workflow?.steps?.length &&
      workflow.steps[gDriveIndex.stepIndex as number].triggers?.length &&
      workflow.steps[gDriveIndex.stepIndex as number].triggers?.[
        gDriveIndex.triggerIndex as number
      ]?.gdrive?.trigger?.file?.id) ||
    '';
  const [connected, setConnected] = useState(!!folderUrl);
  const [pickerCloseClicked, setPickerCloseClicked] = useState(false);

  const formik = useFormik({
    initialValues: {
      folder_url: folderUrl,
      file_id: fileId,
    },
    validationSchema: Yup.object({
      folder_url: Yup.string().required('Folder URL is required'),
    }),
    onSubmit: (values) => onSubmit(values, true),
  });

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
  const {
    errors,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setTouched,
    values,
  } = formik;

  const handleOpenPicker = async () => {
    if (!edit) {
      withGoogleLoginScopeCheck(dispatch, GOOGLE_DRIVE_SCOPE, openDrivePicker);
    }
  };

  const openDrivePicker = async () => {
    const googleToken = await storageService.getStoredGoogleToken(
      user?.email as string,
    );
    // GET CUSTOM FOLDERS LIST VIEW
    const customFoldersListView = getCustomFoldersListView();
    openPicker({
      clientId: CLIENT_ID,
      developerKey: '',
      disableDefaultView: true,
      token: googleToken.accessToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      customViews: [customFoldersListView],
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          setPickerCloseClicked(true);
        }
        if (data.docs && data.docs.length) {
          setFieldValue('folder_url', data.docs[0].url);
          setFieldValue('file_id', data.docs[0].id);
          setTouched({ folder_url: false });
          setConnected(true);
          setPickerCloseClicked(true);
        }
      },
    });
  };

  useEffect(() => {
    if (updateGoogleTokenCompleted) {
      openDrivePicker();
      // clear the status
      dispatch(clearGoogleTokenUpdateStatusAction());
    }
  }, [updateGoogleTokenCompleted]);

  useEffect(() => {
    if (updateGoogleTokenError) {
      toastService.showError(updateGoogleTokenError.message, {
        position: 'top-right',
      });
      // clear the error
      dispatch(updateGoogleTokenErrorAction());
    }
  }, [updateGoogleTokenError]);

  useEffect(() => {
    if (pickerCloseClicked) {
      setTimeout(() => {
        setPickerCloseClicked(false);
      }, 100);
    }
  }, [pickerCloseClicked]);

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  return (
    <main>
      <FormikProvider value={formik}>
        <Form id={formId} autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box
            display={'flex'}
            marginY={'32px'}
            paddingLeft={'19px'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
              {/* APPLICATION */}
              <Box>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Application
                </OrbyTypography>
                <OrbyTextField
                  placeholder='Application'
                  value={'Google Drive'}
                  name='application'
                  width='448px'
                  disabled
                  startAdornment={
                    <img
                      alt='Google Drive.'
                      style={{ marginRight: '6px' }}
                      src={driveIcon}
                    />
                  }
                  endAdornment={
                    !connected ? (
                      <WarningRounded
                        sx={{ marginRight: '8px', color: '#fe8b01' }}
                      />
                    ) : (
                      <CheckCircleRounded
                        sx={{ marginRight: '8px', color: '#006644' }}
                      />
                    )
                  }
                />
              </Box>

              {/* FOLDER URL */}
              <Box>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Select Folder
                </OrbyTypography>
                <OrbyTextField
                  placeholder='Folder Url'
                  onClick={handleOpenPicker}
                  inputSx={{
                    '& .MuiInputBase-input': { cursor: 'pointer' },
                  }}
                  onKeyDown={(event) => {
                    if (
                      !values.folder_url &&
                      !pickerCloseClicked &&
                      event.key === 'Enter'
                    ) {
                      handleOpenPicker();
                    }
                  }}
                  {...getFieldProps('folder_url')}
                  width='448px'
                  startAdornment={
                    <img
                      alt='Folder.'
                      style={{ marginRight: '6px' }}
                      src={folderIcon}
                    />
                  }
                  readOnly
                  disabled={edit}
                  error={errors.folder_url ? errors.folder_url : ''}
                />
              </Box>

              <Box>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Trigger
                </OrbyTypography>
                <OrbyTextField
                  name='trigger'
                  placeholder='Trigger'
                  width='448px'
                  disabled
                  startAdornment={
                    <img
                      alt='Document.'
                      style={{ marginRight: '2px' }}
                      src={docsIcon}
                    />
                  }
                  value={'Add new file'}
                />
              </Box>
            </Box>

            {/* INFO BOX */}
            <Box
              bgcolor={OrbyColorPalette['green-50']}
              borderRadius={'10px'}
              height={'fit-content'}
              padding={'16px'}
              display={'flex'}
              gap={'16px'}
              width={'315px'}
            >
              <Box>
                <SettingsIcon
                  style={{ color: OrbyColorPalette['green-500'] }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                paddingRight={'10px'}
              >
                <span id='folder_url'>
                  <OrbyTypography color={OrbyColorPalette['grey-700']}>
                    When new files are added to this folder, they will trigger
                    the workflow executions for all PDF files in this folder
                    (excluding files in the subfolders).
                  </OrbyTypography>
                  <OrbyTypography
                    color={OrbyColorPalette['grey-700']}
                    sx={{ marginTop: '10px' }}
                  >
                    A few new subfolders {edit ? 'has been' : 'will be'} created
                    in this folder. Processed files will be moved to subfolders
                    that reflect their associated task statuses.
                  </OrbyTypography>

                  {!edit && (
                    <OrbyTypography
                      color={OrbyColorPalette['grey-700']}
                      sx={{ marginTop: '10px' }}
                    >
                      <span
                        style={{
                          color: OrbyColorPalette['blue-600'],
                          fontSize: getFontSize('sm'),
                        }}
                      >
                        <Box
                          tabIndex={0}
                          sx={{
                            '&:focus': {
                              textDecoration: 'underline',
                              borderRadius: '4px',
                            },
                          }}
                          component={'a'}
                          color={'#006FD6'}
                          href='https://support.google.com/a/users/answer/7212025?hl=en'
                          target='_blank'
                        >
                          Google Shared Drives{' '}
                        </Box>
                      </span>
                      (previously called Team Drives) are not supported yet.
                    </OrbyTypography>
                  )}
                  {!edit && (
                    <OrbyTypography
                      color={OrbyColorPalette['grey-700']}
                      sx={{ marginTop: '10px' }}
                    >
                      This field cannot be changed after workflow creation.
                    </OrbyTypography>
                  )}
                </span>
              </Box>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </main>
  );
};

export default memo(TriggerConditionContent);
