import { Box, FormControl, MenuItem, Select, TextField } from '@mui/material';
import React, { ChangeEvent, FC, memo, useMemo } from 'react';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { CURRENCY_CODES } from '../../../../../../../utils/normalization/normalization.constants';
import { Money } from 'protos/google/type/money';
import {
  INT64_MAX,
  isValidNumber,
  moneyToText,
  NEGATIVE_INT64_MAX,
  processCurrency,
} from '../../../../../../../utils/normalization';
import {
  updateEntityInfoForTableAnnotationAction,
  updateEntityInfoNormalizedValueAction,
} from '../../../../../../../redux/actions/review_task.action';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardArrowDown } from '@mui/icons-material';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { detectUndoRedoKeyPress } from '../../../../../../../utils/helpers';
import { DocumentEntityNormalizedValue } from 'protos/google/cloud/documentai/v1/document';

interface NormalizedMoneyFieldProps {
  units: number;
  nanos: number;
  currencyCode: string;
  selectedEntity: EntityInfo;
  task: Task;
  label: string;
  insideTableLayout?: boolean;
}

const NormalizedMoneyField: FC<NormalizedMoneyFieldProps> = ({
  units,
  nanos,
  currencyCode,
  selectedEntity,
  label,
  insideTableLayout,
}) => {
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const error = useMemo(
    () => selectedEntity.error,
    [selectedEntity, selectedEntity.error],
  );
  const dispatch = useDispatch();
  const handleCurrencyChange = (event: { target: { value: string } }) => {
    if (allowEditingTask) {
      const newEntityInfo = { ...selectedEntity };
      newEntityInfo.normalizedValue = DocumentEntityNormalizedValue.create({
        ...selectedEntity.normalizedValue,
        moneyValue: Money.create({
          units,
          nanos,
          currencyCode: event.target.value,
        }),
      });
      updateEntityInfo(newEntityInfo);
    }
  };

  const updateMoneyTextValue = (
    value: string,
    error: string | null,
    excludeFromHistory = false,
  ) => {
    const newEntityInfo = { ...selectedEntity, error };
    newEntityInfo.normalizedInputValue = value;
    updateEntityInfo(newEntityInfo, excludeFromHistory);
  };

  const updateEntityInfo = (
    entityInfo: EntityInfo,
    excludeFromHistory = false,
  ) => {
    if (insideTableLayout) {
      dispatch(
        updateEntityInfoForTableAnnotationAction(
          entityInfo,
          false,
          false,
          excludeFromHistory,
        ),
      );
    } else {
      dispatch(
        updateEntityInfoNormalizedValueAction(entityInfo, excludeFromHistory),
      );
    }
  };

  const handleMoneyValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (allowEditingTask) {
      const inputValue = e.target.value;
      // Regular expression to allow numbers with optional decimal point
      const regex = /^-?\d*\.?\d*$/;
      if (regex.test(inputValue) || inputValue === '') {
        const integerPart = parseInt(inputValue.split('.')[0]); // eg '6054.53' -> '6054'
        let errorToUpdate: string | null = null;
        if (inputValue === '') {
          errorToUpdate = null;
        } else if (!isValidNumber(inputValue)) {
          errorToUpdate = 'Entered number is invalid';
        } else if (
          integerPart < NEGATIVE_INT64_MAX ||
          integerPart > INT64_MAX
        ) {
          errorToUpdate = 'Entered number out of range!';
        } else {
          errorToUpdate = null;
        }
        updateMoneyTextValue(inputValue, errorToUpdate);
      }
    }
  };

  const handleBlur = () => {
    if (!error && allowEditingTask) {
      const newEntityInfo = { ...selectedEntity };
      const strucMoney = {} as Money;
      const parsedMoney = processCurrency(
        `${selectedEntity.normalizedInputValue} ${currencyCode}`.trim(),
      );
      if (parsedMoney) {
        strucMoney.units = parsedMoney.moneyValue.units;
        strucMoney.nanos = parsedMoney.moneyValue.nanos;
        strucMoney.currencyCode = parsedMoney.moneyValue.currencyCode;
        newEntityInfo.normalizedValue = {};
        newEntityInfo.normalizedValue.text = parsedMoney.text;
        newEntityInfo.normalizedValue.moneyValue = strucMoney;
        newEntityInfo.isNormalizedValueModified = true;
        newEntityInfo.normalizedInputValue = moneyToText(
          parsedMoney.moneyValue.units,
          parsedMoney.moneyValue.nanos,
        );
        updateEntityInfo(newEntityInfo);
      }
    }
  };

  const getBorderProperty = (isFocused = false) => {
    if (label) return 'none';
    return error
      ? '1px solid #B42318'
      : `1px solid ${isFocused ? '#1669F7' : '#D0D5DD'}`;
  };

  return (
    <Box display={'flex'} width={'100%'} flexDirection={'column'}>
      <TextField
        label={label}
        placeholder='0.000000000'
        value={selectedEntity.normalizedInputValue ?? ''}
        type={'text'}
        onChange={handleMoneyValueChange}
        inputProps={{
          style: { padding: '110px !important' },
        }}
        InputLabelProps={{
          shrink: true,
          sx: { fontSize: '12px', top: '2px' },
        }}
        helperText={error}
        error={!!error}
        FormHelperTextProps={{
          sx: {
            color: '#B42318 !important',
            ml: '0px !important',
            fontSize: insideTableLayout ? '10px' : '12px',
            lineHeight: insideTableLayout ? '12px' : 'auto',
          },
        }}
        onKeyDown={(e) => {
          if (detectUndoRedoKeyPress(e)) {
            // prevents built-in undo/redo for this text field
            e.preventDefault();
          }
        }}
        sx={{
          width: '100%',
          padding: '0px !important',
          '& .MuiFilledInput-input': {
            padding: insideTableLayout ? '4px 12px' : '10px 0px 10px 14px',
          },
          '& .MuiOutlinedInput-input': {
            padding: insideTableLayout ? '4px 12px' : '8.5px 0px 8.5px 14px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD',
          },
          '& legend': {
            width: '82px',
          },
        }}
        InputProps={{
          ...{
            readOnly: !allowEditingTask,
            sx: {
              border: getBorderProperty(),
              backgroundColor: 'white',
              borderRadius: insideTableLayout ? '4px' : '8px',
              paddingRight: '4px',
              '&.Mui-focused': {
                border: getBorderProperty(true),
                backgroundColor: 'white',
              },
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              fontSize: insideTableLayout ? '14px' : '16px',
            },
            endAdornment: (
              <FormControl
                variant='standard'
                sx={{
                  marginLeft: '8px',
                  paddingLeft: '2px',
                  ':focus-within': {
                    border: '1px solid #1669F7',
                    borderRadius: '8px',
                  },
                }}
              >
                <Select
                  readOnly={!allowEditingTask}
                  value={currencyCode}
                  onChange={handleCurrencyChange}
                  disableUnderline
                  IconComponent={KeyboardArrowDown}
                  sx={{
                    border: 'none',
                    fontSize: insideTableLayout ? '14px' : '16px',
                    backgroundColor: 'transparent !important',
                    '& .MuiInputBase-root': {
                      p: 0,
                      border: 'none !important',
                      backgroundColor: 'transparent !important',
                    },
                    '& .MuiSelect-select': {
                      py: '0px',
                      backgroundColor: 'transparent !important',
                      paddingRight: label
                        ? '60px !important'
                        : '40px !important',
                      minWidth: '40px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        marginTop: '10px',
                        maxHeight: '400px',
                        scrollbarColor: 'gray transparent', // does not work for safari https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color#browser_compatibility
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }}
                >
                  <MenuItem key={'None'} value={''}>
                    None
                  </MenuItem>
                  {CURRENCY_CODES.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
          },
          ...(!label ? { disableUnderline: true } : {}),
        }}
        onBlur={handleBlur}
        variant={label ? 'outlined' : 'filled'}
        size={'small'}
      />
    </Box>
  );
};

export default memo(NormalizedMoneyField);
