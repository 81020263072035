import {
  Box,
  Divider,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import WorkflowCreationStep from '../../pages/workflow-creation/WorkflowCreationStep';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import WorkflowGeneralContent from './step-contents/WorkflowGeneralContent';
import TriggerConditionContent from './step-contents/TriggerConditionContent';
import CreateSystemActionContent from './step-contents/CreateSystemActionContent';
import {
  ManualAssignment,
  ReviewerList,
  ReviewTriggerCondition,
  Workflow,
  WorkflowAssignmentOption,
  WorkflowLearningSettings,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';
import AddUserStepContent from './step-contents/AddUserStepContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWorkflowAction,
  createWorkflowErrorAction,
} from '../../redux/actions/workflow.action';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createdWorkflowSelector,
  processingWorkflowSelector,
  processWorkflowErrorSelector,
} from '../../redux/selectors/workflow.selectors';
import { notification } from 'antd';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import ExtractSchemaDefinition, {
  SchemaEntity,
} from './step-contents/ExtractSchemaDefinition';
import EmailTriggerContent from './step-contents/EmailTriggerContent';
import {
  addUsersToReviewList,
  findSelectedTrigger,
  getActionIndex,
  getApplicationNames,
  getGroupConditionExtractedFields,
  getStepConstants,
  getTriggerIndex,
} from '../../utils/helpers';
import { ApplicationName } from '../../utils/protos/enums';
import SftpTriggerContent from './step-contents/SftpTriggerContent';
import { SFTPParamTriggerTriggerType } from 'protos/application/application_params';
import ClassificationSchemaDefinition from './step-contents/ClassificationSchemaDefinition';
import {
  AssignmentMode,
  GDRIVE_SFTP_MAPPING_COLUMNS,
  GMAIL_MAPPING_COLUMNS,
  STEP_CONTENT_LAST_STEP_WIDTH,
  STEP_CONTENT_WIDTH,
} from '../../utils/constants';
import {
  EntityDataType,
  EntityDetails,
  GenerateOutputParamActionType,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  CompositeGroupCondition,
  ConditionOptions,
  ConditionType,
} from 'protos/pb/v1alpha2/connector';
import GenerateOutput from './step-contents/GenerateOutput';
import { FormikValues } from 'formik';
import {
  getWorkflowAssignmentOptions,
  handleSubmitEmailTriggerForm,
  handleSubmitSheetActionForm,
  updateStepsTriggerOutlookLabels,
} from '../../utils/WorkflowUtils';

const getWorkflowObject = (workflowObject: Workflow) => {
  if (workflowObject) {
    return Workflow.fromJSON(workflowObject);
  }
  return Workflow.create({});
};

const WorkflowCreationPage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  // This variable is added to submit the form when previous button is clicked. This has been
  // passed as a prop to all the steps and when it is set true, the values that has been filled till
  // the click gets saved and user is navigated to previous step. After the form is saved, this gets reset
  // to false, so it won't affect the default save functionality when Next button is pressed
  const [previousClicked, setPreviousClicked] = useState(false);
  const location = useLocation();
  const [creationTriggered, setCreationTriggered] = useState(false);
  const templateWorkflow = getWorkflowObject(location.state.workflow);
  const temporalWorkFlowName = templateWorkflow?.displayName ?? '';
  const temporalWorkFlowDescription = templateWorkflow?.description ?? '';
  /**
   * If it is not created via yaml from the workflow template
   * then we need to clear the name and description
   */
  if (!location.state?.isYamlWorkflow) {
    templateWorkflow.displayName = '';
    templateWorkflow.description = '';
  }
  const [workflow, setWorkflow] = useState<Workflow>(templateWorkflow);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workflowError = useSelector(processWorkflowErrorSelector);
  const addingWorkflow = useSelector(processingWorkflowSelector);
  const createdWorkflow = useSelector(createdWorkflowSelector);
  const user = useSelector(loggedInUserSelector)!;
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const CustomConnector = styled(StepConnector)(() => ({
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#263244',
      borderLeftWidth: '2.6px',
      minHeight: '40px',
      marginLeft: '10px',
    },
  }));
  const selectedTrigger = findSelectedTrigger(templateWorkflow);
  const STEPS = getStepConstants(workflow);
  const isLastStep = activeStep === STEPS.length - 1;

  const hasEmailTrigger = useMemo(() => {
    const applications = getApplicationNames(workflow);
    return (
      applications.includes(ApplicationName.Gmail) ||
      applications.includes(ApplicationName.Outlook)
    );
  }, [workflow]);

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (workflowError && creationTriggered) {
      // revert back the name since its not saved successfully
      workflow.name = workflow.templateResourceName;
      setWorkflow(workflow);
      openError(workflowError);
      dispatch(createWorkflowErrorAction(undefined));
    }
  }, [workflowError]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (createdWorkflow && creationTriggered) {
      openSuccess(
        'workflow ' + createdWorkflow.name + ' is created successfully',
      );
      workflow.name = workflow.templateResourceName;
      setWorkflow(workflow);
      setTimeout(() => {
        setActiveStep(0);
        navigate(-1);
      }, 1000);
    }
  }, [createdWorkflow]);

  const handleSubmitGeneralContent = (values: FormikValues) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    w.displayName = values.workflow_name;
    w.description = values.description;
    w.manualTimeCostInMinutes = parseInt(values.time_spent);
    setWorkflow(w);
    setActiveStep(activeStep + 1);
  };

  const handleSubmitEmailTriggerContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow;
    handleSubmitEmailTriggerForm(w, values);
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitTriggerConditionContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const gDriveIndex = getTriggerIndex(w, ApplicationName.GoogleDrive);
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.id = values['file_id'];
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.file!.path = values['folder_url'];
    w.steps![gDriveIndex.stepIndex!].triggers![
      gDriveIndex.triggerIndex!
    ].gdrive!.trigger!.ownerEmail = user.email;
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitSftpTriggerContent = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.type = SFTPParamTriggerTriggerType.NEW_OBJECT;
    w.steps![sftpIndex.stepIndex!].triggers![
      sftpIndex.triggerIndex!
    ].sftp!.trigger!.folderPath = values['folder_path'];
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitExtractSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const entityExtractionIndex = getActionIndex(
      w,
      ApplicationName.EntityExtraction,
    );
    const entitiesDetails: EntityDetails[] = [];
    const simpleSchemaEntities = values.schemaEntities.filter(
      (e: SchemaEntity) => !e.parentEntityId,
    );
    simpleSchemaEntities.forEach((simpleSchemaEntity: SchemaEntity) => {
      const properties: EntityDetails[] = [];
      if (
        simpleSchemaEntity.normalizationType ===
        EntityDataType.ENTITY_TYPE_NESTED
      ) {
        values.schemaEntities
          .filter(
            (e: SchemaEntity) => e.parentEntityId === simpleSchemaEntity.id,
          )
          .forEach((childEntity: SchemaEntity) => {
            properties.push(
              EntityDetails.create({
                entityType: childEntity.entityName,
                normalizationType: childEntity.normalizationType,
                properties: [],
              }),
            );
          });
      }
      entitiesDetails.push(
        EntityDetails.create({
          entityType: simpleSchemaEntity.entityName,
          normalizationType: simpleSchemaEntity.normalizationType,
          properties,
        }),
      );
    });
    w.steps![entityExtractionIndex.stepIndex!].actions![
      entityExtractionIndex.actionIndex!
    ].entityExtraction!.entitiesDetails = entitiesDetails;
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitGenerateOutputContent = (isNextClicked: boolean) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const sftpIndex = getTriggerIndex(w, ApplicationName.SftpServer);
    const generateOutputApplicationIndex = getActionIndex(
      w,
      ApplicationName.GenerateOutput,
    );

    if (generateOutputApplicationIndex.actionIndex !== -1) {
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.encryptionRequired = sftpIndex.triggerIndex !== -1;
      w.steps![generateOutputApplicationIndex.stepIndex!].actions![
        generateOutputApplicationIndex.actionIndex!
      ].generateOutputParam!.type =
        GenerateOutputParamActionType.ACTION_CREATE_JSON_FILE;
    }

    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitClassificationSchemaDefinition = (
    values: FormikValues,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    const classificationLabelsIndex = getActionIndex(
      w,
      ApplicationName.DocumentClassification,
    );
    const gsheetsIndex = getActionIndex(w, ApplicationName.GoogleSheets);
    w.steps![classificationLabelsIndex.stepIndex!].actions![
      classificationLabelsIndex.actionIndex!
    ].classification!.classificationLabels = values.schemaClassificationLabels;
    let defaultEntities: string[] = [];
    if (gsheetsIndex.actionIndex !== -1) {
      if (
        [ApplicationName.GoogleDrive, ApplicationName.SftpServer].includes(
          selectedTrigger!,
        )
      ) {
        defaultEntities = GDRIVE_SFTP_MAPPING_COLUMNS;
      }
      if (hasEmailTrigger) {
        defaultEntities = GMAIL_MAPPING_COLUMNS;
      }
      w.steps![gsheetsIndex.stepIndex!].actions![
        gsheetsIndex.actionIndex!
      ].gsheets!.action!.addRowOption!.mappingColumns = defaultEntities;
    }
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitCreateSystemActionContent = (
    values: any,
    isNextClicked: boolean,
  ) => {
    const w: Workflow = workflow;
    handleSubmitSheetActionForm(w, values);
    setWorkflow(w);
    setActiveStep(isNextClicked ? activeStep + 1 : activeStep - 1);
    setPreviousClicked(false);
  };

  const handleSubmitAddUserContent = (
    values: FormikValues,
    isSubmitClicked: boolean,
  ) => {
    const w: Workflow = Workflow.fromJSON(workflow);
    let roundOneUsers = ReviewerList.create({});
    roundOneUsers = addUsersToReviewList(values.users);
    if (values.assignmentMode === AssignmentMode.UNASSIGNED) {
      roundOneUsers.assignmentOption = WorkflowAssignmentOption.create({
        manualAssignment: ManualAssignment.create({
          users: roundOneUsers.users,
        }),
      });
      // empty the users list for round one since we have already included them in manual assignment option
      roundOneUsers.users = [];
    } else if (values.showAdvanced) {
      roundOneUsers.assignmentOption = getWorkflowAssignmentOptions(values);
    }
    w.learningSettings = WorkflowLearningSettings.create({
      reviewers: Object.values(values.learningSettings),
    });
    roundOneUsers.roundNumber = 1;
    w.reviewerLists = [];
    w.reviewerLists.push(roundOneUsers);
    if (values.roundTwoUsers.length > 0) {
      let roundTwoUsers = ReviewerList.create({});
      roundTwoUsers = addUsersToReviewList(values.roundTwoUsers);
      roundTwoUsers.roundNumber = 2;
      roundTwoUsers.triggerCondition = ReviewTriggerCondition.create({});
      roundTwoUsers.triggerCondition.conditionType =
        ConditionType.RANDOM_SAMPLE_PERCENT;
      roundTwoUsers.triggerCondition.conditionOptions = ConditionOptions.create(
        {},
      );
      roundTwoUsers.triggerCondition.conditionOptions.percentOfRandomSample =
        values.samplePercentage;
      w.reviewerLists.push(roundTwoUsers);
    }
    w.reviewerLists[0].triggerCondition = ReviewTriggerCondition.create({});
    if (values.selectedMode === WorkflowMode.ASSISTED) {
      w.reviewerLists[0].triggerCondition.conditionType = values.conditionType;
      w.reviewerLists[0].triggerCondition.conditionOptions =
        ConditionOptions.create({});
      if (values.conditionType !== ConditionType.ANY_EMPTY_PREDICTIONS) {
        if (values.conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD) {
          w.reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            CompositeGroupCondition.create({});
          w.reviewerLists[0].triggerCondition.conditionOptions.groupCondition =
            getGroupConditionExtractedFields(values);
        } else {
          w.reviewerLists[0].triggerCondition.conditionOptions.confidenceScore =
            Number(values.confidenceScore);
        }
      }
    } else if (values.selectedMode === WorkflowMode.DEFAULT) {
      w.needAttentionThresholdDefaultMode =
        Number(values.needAttentionThresholdDefaultMode) / 100;
    } else {
      w.reviewerLists[0].triggerCondition.conditionType =
        ConditionType.UNSPECIFIED;
    }

    /**
     * IF WORKFLOW TYPE IS OUTLOOK
     * LOOK FOR outlook-all-emails label and remove it
     * The reason for doing this is because Outlook doesn’t have a label for ‘Inbox'.
     * However by sending no labels for an outlook workflow while creating workflow, we can achieve the same behaviour.
     */
    updateStepsTriggerOutlookLabels(w);

    w.mode = values.selectedMode;
    w.templateResourceName = w.name;
    w.organizationResourceName = selectedOrgInfo?.orgResourceName;
    setWorkflow(w);
    if (isSubmitClicked) {
      w.name = undefined;
      dispatch(createWorkflowAction(w));
      setCreationTriggered(true);
    } else {
      setActiveStep(activeStep - 1);
      setPreviousClicked(false);
    }

    /**
     * Add workflow admins
     */
    w.admins = values.workflowAdmins;
    w.adminEmailMessage = values.adminEmailMessage;
    w.sendAdminEmail = true;
  };

  const getStepForApplication = (applicationName: string, index: number) => {
    const formId = `form${index + 1}`;
    switch (applicationName) {
      case ApplicationName.Gmail:
      case ApplicationName.Outlook:
        return (
          <EmailTriggerContent
            formId={formId}
            onSubmit={handleSubmitEmailTriggerContent}
            workflow={workflow}
            edit={false}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.SftpServer:
        return (
          <SftpTriggerContent
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitSftpTriggerContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.GoogleDrive:
        return (
          <TriggerConditionContent
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitTriggerConditionContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.DocumentClassification:
        return (
          <ClassificationSchemaDefinition
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitClassificationSchemaDefinition}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.MSExcel:
      case ApplicationName.GoogleSheets:
        return (
          <CreateSystemActionContent
            formId={formId}
            workflow={workflow}
            onSubmit={handleSubmitCreateSystemActionContent}
            previousClicked={previousClicked}
          />
        );
      case ApplicationName.GenerateOutput:
        return (
          <GenerateOutput
            formId={formId}
            onSubmit={handleSubmitGenerateOutputContent}
            previousClicked={previousClicked}
          />
        );
      // THIS CASE WILL BE REPLACED BY EXTRACT ENTITY APPLICATION WHEN ADDED FROM BE
      default:
        return (
          <ExtractSchemaDefinition
            formId={formId}
            workflow={workflow}
            isEmailExtraction={hasEmailTrigger}
            onSubmit={handleSubmitExtractSchemaDefinition}
            previousClicked={previousClicked}
          />
        );
    }
  };

  const getStepContent = (index: number) => {
    if (index === 0) {
      return (
        <WorkflowGeneralContent
          workflow={workflow}
          onSubmit={handleSubmitGeneralContent}
        />
      );
    }
    const applications = getApplicationNames(workflow);
    if (index > applications.length) {
      return (
        <AddUserStepContent
          formId={`form${index + 1}`}
          workflow={workflow}
          onSubmit={handleSubmitAddUserContent}
          isEmailExtraction={hasEmailTrigger}
          previousClicked={previousClicked}
        />
      );
    }
    return getStepForApplication(applications[index - 1], index);
  };

  return (
    <Box
      bgcolor={'#F6F8FC'}
      paddingX={'50px'}
      paddingTop={'50px'}
      width={'100%'}
    >
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Workflow Creation from {temporalWorkFlowName}
      </CustomTypography>
      <CustomTypography
        sx={{ marginBottom: '40px' }}
        typographyType={TypographyType.MediumPara}
      >
        {temporalWorkFlowDescription}
      </CustomTypography>
      <Stepper
        sx={{
          overflow: 'none',
          marginBottom: '80px',
          '& .MuiStepConnector-root .MuiStepConnector-line': {
            minHeight: '48px',
            borderColor: '#031026',
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {STEPS.map((step, index) => {
          const isLastStep = index === STEPS.length - 1;
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabel
                StepIconComponent={(props: StepIconProps) => (
                  <>
                    <WorkflowCreationStep
                      key={index}
                      {...props}
                      {...step}
                      sx={{}}
                      ariaLabel={`Create Workflow Step ${index + 1} ${
                        step.title
                      }`}
                    />
                  </>
                )}
              ></StepLabel>
              <StepContent
                sx={
                  !isLastStep
                    ? {
                        borderLeft: 'none !important',
                        paddingLeft: '0px !important',
                        marginLeft: '0px  !important',
                      }
                    : {
                        borderLeft: 'none !important',
                        marginTop: '30px',
                        paddingLeft: '0px',
                        marginLeft: '50px !important',
                      }
                }
              >
                <Box
                  display={'flex'}
                  width={
                    isLastStep
                      ? STEP_CONTENT_LAST_STEP_WIDTH
                      : STEP_CONTENT_WIDTH
                  }
                  flexWrap={'wrap'}
                  bgcolor={'#FFFFFF'}
                  position={'relative'}
                >
                  {getStepContent(index)}
                  {isLastStep && (
                    <>
                      <Divider
                        orientation='vertical'
                        variant='fullWidth'
                        sx={{
                          borderLeft: '2.6px solid #031026',
                          height: '48px',
                          position: 'absolute',
                          left: '-27.3px',
                          top: '-30px',
                        }}
                      />
                      <Divider
                        sx={{
                          borderTop: '2.6px solid #031026',
                          width: '27.3px',
                          position: 'absolute',
                          left: '-27.3px',
                          top: '18px',
                        }}
                      />
                    </>
                  )}
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Box
        paddingBottom={'20px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '25px',
        }}
      >
        <CustomButton
          variant='outlined'
          onClick={() => navigate(-1)}
          disabled={addingWorkflow}
        >
          Cancel
        </CustomButton>
        {activeStep > 0 && (
          <CustomButton
            onClick={() => setPreviousClicked(true)}
            variant='outlined'
            disabled={addingWorkflow}
          >
            Previous
          </CustomButton>
        )}
        {activeStep < STEPS.length - 1 && (
          <CustomButton form={`form${activeStep + 1}`} type='submit'>
            Next
          </CustomButton>
        )}
        <CustomButton
          form={`form${activeStep + 1}`}
          type='submit'
          invisible={!isLastStep}
          disabled={addingWorkflow}
          loading={addingWorkflow}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default memo(WorkflowCreationPage);
