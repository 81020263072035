import { storageService } from './StorageService';
import {
  DeleteInvoiceRequest,
  Invoice,
  InvoicesClientImpl,
  ListInvoicesResponse,
} from 'protos/pb/demo/invoice_service';
import {
  ListInvoicesRequest,
  CreateInvoiceRequest,
  UpdateInvoiceRequest,
} from 'protos/pb/demo/invoice_service';
import { Empty } from 'protos/google/protobuf/empty';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

class InvoiceService {
  private static instance: InvoiceService;
  private static client = new InvoicesClientImpl(rpcWithErrorHandling);

  public static getInstance(): InvoiceService {
    if (!this.instance) {
      this.instance = new InvoiceService();
    }
    return this.instance;
  }

  async listInvoices(req: ListInvoicesRequest): Promise<ListInvoicesResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return InvoiceService.client.ListInvoices(
      req,
      getMetaData({ authorization }),
    );
  }

  async createInvoice(req: CreateInvoiceRequest): Promise<Invoice> {
    const authorization = await storageService.getAuthorizationHeader();
    return InvoiceService.client.CreateInvoice(
      req,
      getMetaData({ authorization }),
    );
  }

  async updateInvoice(req: UpdateInvoiceRequest): Promise<Invoice> {
    const authorization = await storageService.getAuthorizationHeader();
    return InvoiceService.client.UpdateInvoice(
      req,
      getMetaData({ authorization }),
    );
  }

  async deleteInvoice(req: DeleteInvoiceRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return InvoiceService.client.DeleteInvoice(
      req,
      getMetaData({ authorization }),
    );
  }
}

export const invoiceService = InvoiceService.getInstance();
