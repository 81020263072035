import { Box } from '@mui/material';
import React, { FC, memo, useMemo, useState } from 'react';
import NestedArrow from '../../../../../static/icons/nestedArrow.svg';
import { SchemaEntity, SchemaEntityError } from '../ExtractSchemaDefinition';
import ExtractSelectSchemaField from '../ExtractSelectSchemaField';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { ReactComponent as AddBlackIcon } from '../../../../../static/icons/add-black.svg';
import WarningIcon from '../../../../../static/icons/warning_icon.svg';
import {
  FileName,
  invalidCharactersInEntityNameMsg,
  invalidNotesEntityNameMsg,
  MAX_NESTED_ENTITIES,
} from '../../../../../utils/constants';
import {
  checkIfNotesEntityType,
  hasInvalidCharacters,
  isInvalidNotesEntity,
} from '../../../../../utils/entities';
import {
  allowParentNamePrefixForGoogleOrgs,
  getAllNestedEntitiesWithoutNotes,
  isChildPrefixedWithParentName,
  trimParentNamePrefix,
} from '../../../../../utils/helpers';
import { selectedOrgInfoSelector } from '../../../../../redux/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { OrgInfo } from 'protos/pb/v1alpha1/user';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';
import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';

interface AddChildEntityFieldProps {
  parentEntity: SchemaEntity;
  schemaEntities: SchemaEntity[];
  handleAddEntity: (
    entityString: string,
    entityTypeString?: string,
    parentString?: string,
    parentEntityId?: string,
  ) => void;
  handleDeleteEntity: (schemaEntity: SchemaEntity) => void;
  handleUpdateEntityDataType: (
    schemaEntity: SchemaEntity,
    type: EntityDataType,
  ) => void;
}

const AddChildEntityField: FC<AddChildEntityFieldProps> = ({
  parentEntity,
  schemaEntities,
  handleAddEntity,
  handleDeleteEntity,
  handleUpdateEntityDataType,
}) => {
  const selectedOrg =
    useSelector(selectedOrgInfoSelector) ?? OrgInfo.create({});
  const [value, setValue] = useState<string>('');
  const [childEntitiesErrors, setChildEntitiesErrors] = useState<
    SchemaEntityError[]
  >([]);
  const ALLOW_PARENT_NAME_PREFIX = useMemo(
    () => allowParentNamePrefixForGoogleOrgs(selectedOrg.orgResourceName!),
    [selectedOrg.orgResourceName],
  );
  const childEntities = useMemo(() => {
    return schemaEntities.filter((e) => e.parentEntityId === parentEntity.id);
  }, [schemaEntities, parentEntity.id]);

  const alreadyAddedChildEntitiesPrefixedWithParentName = useMemo(
    () =>
      childEntities.some((child) =>
        isChildPrefixedWithParentName(
          child.entityName,
          parentEntity.entityName,
        ),
      ),
    [childEntities, parentEntity.entityName],
  );

  const shouldAllowParentNamePrefix =
    ALLOW_PARENT_NAME_PREFIX &&
    (childEntities.length === 0 ||
      alreadyAddedChildEntitiesPrefixedWithParentName);

  const alreadyHasChildEntity = (child: string) => {
    const sameChild: SchemaEntity | undefined = childEntities.find(
      (e) => e.entityName.trim().toLowerCase() === child.trim().toLowerCase(),
    );
    if (sameChild) {
      return true;
    }
    return false;
  };

  const hasDuplicatesInString = (
    child: string,
    childString: string[],
    childIndex: number,
  ) => {
    return childString.some(
      (c, i) =>
        childIndex < i && c.trim().toLowerCase() === child.trim().toLowerCase(),
    );
  };

  const isMaxNestedEntitiesReached = (entity: string, index: number) => {
    return (
      !checkIfNotesEntityType(entity) &&
      getAllNestedEntitiesWithoutNotes(schemaEntities).length + (index + 1) >
        MAX_NESTED_ENTITIES
    );
  };

  const getValidatedCommaSeparatedChildString = (childString: string) => {
    setChildEntitiesErrors([]);
    let completeString = '';
    let errorString = '';

    let isPrefixedEntityExpected =
      alreadyAddedChildEntitiesPrefixedWithParentName;

    childString.split(',').map((child, i) => {
      const isEntityPrefixed = isChildPrefixedWithParentName(
        child,
        parentEntity.entityName,
      );
      if (i === 0 && childEntities.length === 0 && isEntityPrefixed) {
        isPrefixedEntityExpected = true;
      }
      let actualNameToValidate = child.toString();

      if (isEntityPrefixed && shouldAllowParentNamePrefix) {
        actualNameToValidate = trimParentNamePrefix(child);
      }
      if (actualNameToValidate.toLowerCase() === FileName) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          { entityName: child, errorMessage: 'Entity cannot be added.' },
        ]);
        errorString += `${child},`;
      } else if (
        ALLOW_PARENT_NAME_PREFIX &&
        isPrefixedEntityExpected &&
        !isEntityPrefixed
      ) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          {
            entityName: child,
            errorMessage: `Either Prefix all entities with "${parentEntity.entityName}/" or remove the prefix "${parentEntity.entityName}/" from all entities `,
          },
        ]);
        errorString += `${child},`;
      } else if (
        ALLOW_PARENT_NAME_PREFIX &&
        !isPrefixedEntityExpected &&
        isEntityPrefixed
      ) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          {
            entityName: child,
            errorMessage: `Either Prefix all entities with "${parentEntity.entityName}/" or remove the prefix "${parentEntity.entityName}/" from all entities `,
          },
        ]);
        errorString += `${child},`;
      } else if (
        alreadyHasChildEntity(child) ||
        hasDuplicatesInString(child, childString.split(','), i)
      ) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          { entityName: child, errorMessage: 'Already Exists' },
        ]);
        errorString += `${child},`;
      } else if (isInvalidNotesEntity(actualNameToValidate)) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          { entityName: child, errorMessage: invalidNotesEntityNameMsg },
        ]);
        errorString += `${child},`;
      } else if (hasInvalidCharacters(actualNameToValidate)) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          {
            entityName: child,
            errorMessage: invalidCharactersInEntityNameMsg,
          },
        ]);
        errorString += `${child},`;
      } else if (isMaxNestedEntitiesReached(actualNameToValidate, i)) {
        setChildEntitiesErrors((prev) => [
          ...prev,
          {
            entityName: child,
            errorMessage: `You can add a maximum of ${MAX_NESTED_ENTITIES} nested entities`,
          },
        ]);
        errorString += `${child},`;
      } else {
        completeString += `${child},`;
      }
    });
    return {
      completeString: completeString.slice(0, -1),
      errorString: errorString.slice(0, -1),
    };
  };

  return (
    <Box display={'flex'} marginLeft={'15px'}>
      <Box
        alignSelf={'self-start'}
        alt=''
        component={'img'}
        src={NestedArrow}
      />
      <Box
        padding={'16px'}
        marginTop={'6px'}
        borderRadius={'8px'}
        width={'97%'}
        minHeight={'60px'}
        bgcolor={OrbyColorPalette['blue-50']}
      >
        <OrbyTextField
          value={value}
          onChange={(e) => setValue(e.target.value.toLowerCase())}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const { completeString, errorString } =
                getValidatedCommaSeparatedChildString(value);
              handleAddEntity(
                completeString,
                undefined,
                undefined,
                parentEntity.id,
              );
              setValue(errorString);
            }
          }}
          width='100%'
          endAdornment={<AddBlackIcon aria-label='Add entity.' />}
          placeholder='Add child entity'
        />
        {childEntitiesErrors.length > 0 && (
          <Box>
            <Box padding={'4px 0'} display={'flex'}>
              <img
                alt='Warning Icon'
                style={{ height: '18px', paddingRight: '4px' }}
                src={WarningIcon}
              />
              <OrbyTypography
                sx={{
                  color: OrbyColorPalette['error-500'],
                  paddingLeft: '4px',
                }}
              >
                There {childEntitiesErrors.length === 1 ? 'is' : 'are'}{' '}
                {childEntitiesErrors.length}{' '}
                {childEntitiesErrors.length === 1 ? 'warning' : 'warnings'}:
              </OrbyTypography>
            </Box>
            <ul
              style={{
                fontSize: getFontSize('sm'),
                paddingLeft: '25px',
                color: OrbyColorPalette['error-500'],
              }}
            >
              {childEntitiesErrors.map((entity, index) => {
                return (
                  <li key={index}>
                    {entity.entityName}: {entity.errorMessage}{' '}
                  </li>
                );
              })}
            </ul>
          </Box>
        )}
        <Box width={'100%'} paddingTop={'6px'}>
          {childEntities.map((entity, index) => {
            return (
              <ExtractSelectSchemaField
                schemaEntities={schemaEntities}
                isDefaultEntity={false}
                handleDeleteEntity={handleDeleteEntity}
                handleAddEntity={handleAddEntity}
                handleUpdateEntityDataType={handleUpdateEntityDataType}
                entity={entity}
                key={index}
                isChildEntity={true}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AddChildEntityField);
