import {
  FormControl,
  FormControlLabel,
  CircularProgress,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Button,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import { useDispatch } from 'react-redux';
import {
  removeSavedDocumentAction,
  saveUploadedDocumentAction,
} from '../../redux/actions/taskV2.action';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Header1 } from '../../components/Typography/Typography';
import { HandymanOutlined } from '@mui/icons-material';
import { gcsService } from '../../services/GcsService';
import { clearReviewState } from '../../redux/actions/review_task.action';
import { utilityService } from '../../services/UtilityService';
import { GetSignedGCSUriRequest } from 'protos/pb/v1alpha2/utility_service';

const DebuggerReview: React.FC = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const openError = (error: Error) => {
    try {
      api.error({
        message: 'Notification',
        description: error.message,
        placement: 'topRight',
        duration: null,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const [value, setValue] = useState('');
  const [gcsURI, setGcsURI] = useState('');
  const [instruction, setInstruction] = useState('local');
  const [loading, setLoading] = useState(false);

  const downloadAndNavigateDocumentForReview = async () => {
    if (gcsURI === '') return;
    const getSignedGCSUriRequest: GetSignedGCSUriRequest = { uri: gcsURI };
    setLoading(true);
    const getSignedGCSUriResponse = await utilityService.getSignedGCSUri(
      getSignedGCSUriRequest,
    );
    if (getSignedGCSUriResponse.error) {
      openError(getSignedGCSUriResponse.error);
      setLoading(false);
      return;
    }
    const gcsUri = getSignedGCSUriResponse.response?.signedUri ?? '';

    gcsService.getUriContent(gcsUri).then(({ response, error }) => {
      if (error) {
        openError(error);
        setLoading(false);
      } else {
        try {
          const textDoc = Document.decode(response as Uint8Array);
          dispatch(saveUploadedDocumentAction(textDoc));
          if ((textDoc?.entities?.length ?? 0) > 0) {
            const file = gcsUri.replaceAll('/', '-');
            setTimeout(() => {
              setLoading(false);
              navigate(`document/${file.slice(0, 30)}`);
            }, 500);
          } else {
            setGcsURI('');
            setLoading(false);
            openError(Error('No entities found in GCS URI Document proto'));
          }
        } catch (err) {
          setGcsURI('');
          openError(err as Error);
          setLoading(false);
        }
      }
    });
  };
  const getDocumentProtoFromGCSURI = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (e.code === 'Enter') {
      downloadAndNavigateDocumentForReview();
    }
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target?.files?.[0] as File;
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const fileData = reader.result;
        if (fileData instanceof ArrayBuffer) {
          const document = Document.decode(new Uint8Array(fileData));
          dispatch(saveUploadedDocumentAction(document));
          navigate(`document/${file.name.slice(0, 30)}`);
          setValue('');
        } else {
          setValue('');
          openError(Error('Unsupported file'));
        }
      } catch (error) {
        setValue('');
        openError(Error('Unsupported file'));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    dispatch(removeSavedDocumentAction());
    dispatch(clearReviewState());
  }, []);

  return (
    <>
      {contextHolder}
      <Box
        gap={2}
        flexDirection={'column'}
        alignItems={'center'}
        height={'100vh'}
        display={'flex'}
        justifyContent={'center'}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Header1>Debugging</Header1>
              <HandymanOutlined sx={{ fontSize: 40 }} />
            </Box>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>
                Choose one
              </FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='gcs'
                name='radio-buttons-group'
                row
                value={instruction}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              >
                <FormControlLabel
                  value='local'
                  control={<Radio />}
                  label='Local document proto'
                />
                <FormControlLabel
                  value='gcs'
                  control={<Radio />}
                  label={`Public / (${process.env.REACT_APP_ENV} bucket) GCS URI`}
                />
              </RadioGroup>
            </FormControl>

            <Box
              width={'25%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              {instruction === 'local' && (
                <Input
                  sx={{ width: '100%' }}
                  value={value}
                  placeholder='Select proto file'
                  type='file'
                  onChange={handleFileInputChange}
                />
              )}
              {instruction === 'gcs' && (
                <Input
                  value={gcsURI}
                  onKeyDown={getDocumentProtoFromGCSURI}
                  onChange={(e) => {
                    setGcsURI(e.target.value);
                  }}
                  sx={{ width: '100%' }}
                  placeholder='Enter GCS URI'
                ></Input>
              )}
              {instruction === 'gcs' && (
                <Button
                  sx={{ marginTop: '10px' }}
                  onClick={downloadAndNavigateDocumentForReview}
                >
                  Review Document
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </>
  );
};
export default DebuggerReview;
