import { Box } from '@mui/material';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  tokenListPageToHighlightSelector,
  tokenListToHighlightSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import { boxPositionValuesUtilV2 } from '../../../../../utils/BoxPositionValuesUtilV2';

interface Props {
  scale: number;
  currentPage: number;
}

const DocumentTokensListHighlight: FC<Props> = ({ scale, currentPage }) => {
  const tokenListToHighlight = useSelector(tokenListToHighlightSelector);
  const tokenListPageToHighlight = useSelector(
    tokenListPageToHighlightSelector,
  );

  if (tokenListPageToHighlight !== currentPage) {
    return <Box />;
  }

  return (
    <>
      {tokenListToHighlight?.map((token, index) => {
        const boxPositionValues =
          boxPositionValuesUtilV2.addPaddingToBoxPositionValues(
            2, // This is the padding we are adding in the position
            boxPositionValuesUtilV2.getBoxPositionValues(
              token?.layout?.boundingPoly?.vertices,
              scale,
            ),
          );
        return (
          <Box
            key={index}
            position={'absolute'}
            sx={{ bgcolor: '#0500FF33', borderRadius: '2px' }}
            top={boxPositionValues.top}
            left={boxPositionValues.left}
            width={boxPositionValues.width}
            height={boxPositionValues.height}
          />
        );
      })}
    </>
  );
};

export default memo(DocumentTokensListHighlight);
