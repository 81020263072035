import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { addAlpha, formatDate } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { deletingTaskSelector } from '../../redux/selectors/taskV2.selectors';
import { notification } from 'antd';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';

import { ELLIPSIS_STYLE } from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import CustomTableLabel from '../../components/CustomTableLabel';
import '../../styles/table.css';
import {
  GetScheduleTasksRequest,
  ScheduleTask,
} from 'protos/pb/v1alpha2/utility_service';
import {
  getScheduledTasksAction,
  getScheduledTasksErrorAction,
} from '../../redux/actions/utility.action';
import {
  getScheduledTasksSelector,
  loadingScheduledTasksSelector,
  scheduledTasksErrorSelector,
} from '../../redux/selectors/utility.selectors';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));

const ScheduledTasks: React.FC = () => {
  const dispatch = useDispatch();
  const tasks: ScheduleTask[] = useSelector(getScheduledTasksSelector);
  const loading = useSelector(loadingScheduledTasksSelector);
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const pagedTasks = tasks.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const scheduledTaskError = useSelector(scheduledTasksErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const workflowColors = useSelector(workflowColorsSelector);
  const deletingTask = useSelector(deletingTaskSelector);

  const openError = (error: Error) => {
    api.error({
      message: 'Error',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  useEffect(() => {
    if (scheduledTaskError) {
      openError(scheduledTaskError);
      dispatch(getScheduledTasksErrorAction(undefined));
    }
  }, [scheduledTaskError]);

  useEffect(() => {
    setPage(0);
    const req = buildRequest();
    dispatch(getScheduledTasksAction(req));
  }, [selectedOrgInfo]);

  const buildRequest = () => {
    const req: GetScheduleTasksRequest = {};
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    return req;
  };

  useEffect(() => {
    if (tasks) {
      const workflowNames = tasks.map((t) => t.workflowName);
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [tasks]);

  return (
    <>
      <Box paddingX={'60px'} paddingY={'60px'}>
        {contextHolder}
        <Box display={'flex'} alignItems={'center'}></Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          paddingTop={'30px'}
        >
          <CustomTypography typographyType={TypographyType.Header2}>
            Scheduled Tasks
          </CustomTypography>
        </Box>
        <Box>
          {loading || deletingTask ? (
            <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer
                sx={{ marginTop: '30px' }}
                className='table-header-style'
              >
                <Table id={'pending-tasks-table'} sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                      <CustomTableCell>
                        <CustomTableLabel label='Task Name' />
                      </CustomTableCell>
                      <CustomTableCell title='Workflow'>
                        <CustomTableLabel label='Workflow' />
                      </CustomTableCell>
                      <CustomTableCell title='Upload Time'>
                        <CustomTableLabel label='Upload Time' />
                      </CustomTableCell>
                      <CustomTableCell title='Scheduled Time'>
                        <CustomTableLabel label='Schedule Time' />
                      </CustomTableCell>
                      <CustomTableCell title='Priority'>
                        <CustomTableLabel label='Priority' />
                      </CustomTableCell>
                      <CustomTableCell title='Status'>
                        <CustomTableLabel label='Status' />
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pagedTasks.map((t, index) => (
                      <StyledTableRow
                        key={index}
                        tabIndex={0}
                        role='row'
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 !== 0 ? '#F6F6F6' : 'white',
                          ':hover': { backgroundColor: '#1669F74D' },
                          ':focus': { backgroundColor: '#1669F74D' },
                        }}
                      >
                        <CustomTableCell>
                          <span title={t.name || 'NO DATA'}>
                            {t.name || 'NO DATA'}
                          </span>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Box
                            title={t.workflowName}
                            sx={{
                              paddingX: '10px',
                              paddingY: '5px',
                              backgroundColor: addAlpha(
                                workflowColors[t.workflowName as string],
                                0.4,
                              ),
                              width: 'fit-content',
                              maxWidth: '100%',
                              borderRadius: '4px',
                            }}
                          >
                            <CustomTypography
                              typographyType={TypographyType.Label}
                              sx={{
                                ...ELLIPSIS_STYLE,
                              }}
                            >
                              {t.workflowName}
                            </CustomTypography>
                          </Box>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.uploadTime ? formatDate(t.uploadTime) : '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.orbyScheduledTime
                              ? formatDate(t.orbyScheduledTime)
                              : '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>
                            {t.priority || '-'}
                          </CustomTypography>
                        </CustomTableCell>
                        <CustomTableCell ellipsis={false}>
                          <CustomTypography>{t.status || '-'}</CustomTypography>
                        </CustomTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {!pagedTasks.length && (
                  <CustomTypography
                    sx={{
                      textAlign: 'center',
                      textJustify: 'center',
                      color: '#475467',
                      marginTop: '40px',
                    }}
                  >
                    There are no scheduled tasks available
                  </CustomTypography>
                )}
              </TableContainer>
              <CustomPagination
                isShowSelectFilter={false}
                rowsPerPage={20}
                totalSize={200} // For 10 Pages
                page={page}
                onPageChange={(p) => {
                  setPage(p);
                  const req = buildRequest();
                  req.pageNo = p;
                  dispatch(getScheduledTasksAction(req));
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(ScheduledTasks);
