import React, { FC, memo } from 'react';
import { Box, StepLabel } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface StepLabelComponentProps {
  title: string;
  step: number;
  isActiveStep: boolean;
  isStepCompleted: boolean;
  updateStep: (step: number) => void;
}

const StepLabelComponent: FC<StepLabelComponentProps> = (props) => {
  const { title, step, isActiveStep, isStepCompleted, updateStep } = props;

  const backgroundColor = isActiveStep
    ? OrbyColorPalette['blue-50']
    : OrbyColorPalette['grey-50'];

  const iconBackgroundColor = isActiveStep
    ? OrbyColorPalette['blue-700']
    : 'transparent';
  const iconTextBorderColor = isActiveStep
    ? OrbyColorPalette['white-0']
    : OrbyColorPalette['grey-500'];

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <StepLabel
      onClick={() => {
        if (!isActiveStep) {
          updateStep(step - 1);
        }
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      sx={{
        '& .MuiStepLabel-iconContainer': {
          paddingRight: '0px',
        },
      }}
      StepIconComponent={() => (
        <Box
          sx={{
            borderRadius: '24px 0px 0px 24px',
            background:
              isHovered && !isActiveStep
                ? OrbyColorPalette['blue-25']
                : backgroundColor,
            height: '48px',
            width: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99,
          }}
        >
          {isStepCompleted ? (
            <Box
              sx={{
                background: OrbyColorPalette['green-600'],
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'content-box',
                border: `2px solid ${OrbyColorPalette['green-600']}`,
              }}
            >
              <CheckRoundedIcon sx={{ color: OrbyColorPalette['white-0'] }} />
            </Box>
          ) : (
            <Box
              sx={{
                background:
                  isHovered && !isActiveStep
                    ? OrbyColorPalette['blue-25']
                    : iconBackgroundColor,
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'content-box',
                border: `2px solid ${iconTextBorderColor}`,
              }}
            >
              <OrbyTypography
                size='md'
                weight='semibold'
                color={iconTextBorderColor}
              >
                {step}
              </OrbyTypography>
            </Box>
          )}
        </Box>
      )}
    >
      <Box
        id='step-label'
        bgcolor={
          isHovered && !isActiveStep
            ? OrbyColorPalette['blue-25']
            : backgroundColor
        }
        height={'48px'}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        maxWidth={'100%'}
        borderRadius={'0px 24px 24px 0px'}
        sx={{
          cursor: isActiveStep ? 'default' : 'pointer',
        }}
      >
        <OrbyTypography
          color={OrbyColorPalette['black-0']}
          size='md'
          weight='medium'
          sx={{
            marginLeft: '10px',
          }}
        >
          {title}
        </OrbyTypography>
      </Box>
    </StepLabel>
  );
};

export default memo(StepLabelComponent);
