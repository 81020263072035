import React from 'react';
import {
  OrbySelect,
  OrbyMenuItem,
  OrbyTypography,
  OrbyColorPalette,
} from 'orby-ui/src';
import { ELLIPSIS_STYLE } from '../../../utils/constants';

export interface Props {
  selectedRole: string;
  setSelectedRole: (data: string) => void;
  width?: string;
  menuWidth?: string;
  isRbacEnabled?: boolean;
}

const RolesFilter: React.FC<Props> = ({
  isRbacEnabled = false,
  selectedRole,
  setSelectedRole,
}) => {
  const DEFAULT_ROLE = {
    label: 'All roles',
    value: 'all-roles',
  };

  const ROLES = [
    {
      label: 'Admin',
      value: 'admin',
    },
    ...(isRbacEnabled
      ? [
          {
            label: 'Creator',
            value: 'creator',
          },
        ]
      : []),
    {
      label: 'User',
      value: 'user',
    },
  ];

  return (
    <>
      <OrbySelect
        value={selectedRole}
        renderValue={(value) => {
          return (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
              sx={{
                ...ELLIPSIS_STYLE,
              }}
            >
              {value
                ? ROLES.find((r) => r.value === value)?.label
                : DEFAULT_ROLE.label}
            </OrbyTypography>
          );
        }}
        width={'280px'}
        onChange={(event) => {
          const value = event.target.value as string;
          if (value) {
            if (value === DEFAULT_ROLE.value) {
              setSelectedRole('');
            } else {
              setSelectedRole(value);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={'280px'}
          key={DEFAULT_ROLE.value}
          title={DEFAULT_ROLE.label}
          value={DEFAULT_ROLE.value}
          isSelected={selectedRole === ''}
        />
        {ROLES.map((role) => {
          return (
            <OrbyMenuItem
              width={'280px'}
              key={role.value}
              title={role.label}
              value={role.value}
              isSelected={selectedRole === role.value}
            />
          );
        })}
      </OrbySelect>
    </>
  );
};

export default React.memo(RolesFilter);
