import { Box } from '@mui/material';
import {
  Document,
  DocumentPageTable,
  DocumentPageTableTableRow,
} from 'protos/google/cloud/documentai/v1/document';
import React from 'react';
import { boxPositionValuesUtilV2 } from '../../../../../utils/BoxPositionValuesUtilV2';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  document: Document;
  currentPage: number;
  scale: number;
}

// This file is purely for debugging purpose to display header and body rows of all the
// tables present in the document in the form of bounding box
const DocumentTableDebugLayout: React.FC<Props> = ({
  document,
  currentPage,
  scale,
}) => {
  return (
    <Box>
      {document?.pages?.[currentPage]?.tables?.map(
        (table: DocumentPageTable) => {
          return (
            <Box key={uuidv4()}>
              {table?.headerRows?.map((rows: DocumentPageTableTableRow) => {
                return (
                  <Box key={uuidv4()}>
                    {rows?.cells?.map((cell) => {
                      const boxPositionValues =
                        boxPositionValuesUtilV2.getBoxPositionValues(
                          cell?.layout?.boundingPoly?.vertices,
                          scale,
                        );
                      return (
                        <Box
                          key={uuidv4()}
                          position={'absolute'}
                          display={'flex'}
                          top={boxPositionValues.top}
                          left={boxPositionValues.left}
                          width={boxPositionValues.width}
                          height={boxPositionValues.height}
                          bgcolor={'#ff97975c'}
                          sx={{ opacity: '80%' }}
                          border={'2px solid black'}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          );
        },
      )}
      {document?.pages?.[currentPage]?.tables?.map(
        (table: DocumentPageTable) => {
          return (
            <Box key={uuidv4()}>
              {table?.bodyRows?.map((rows: DocumentPageTableTableRow) => {
                return (
                  <Box key={uuidv4()}>
                    {rows?.cells?.map((cell) => {
                      const boxPositionValues =
                        boxPositionValuesUtilV2.getBoxPositionValues(
                          cell?.layout?.boundingPoly?.vertices,
                          scale,
                        );
                      return (
                        <Box
                          key={uuidv4()}
                          position={'absolute'}
                          display={'flex'}
                          top={boxPositionValues.top}
                          left={boxPositionValues.left}
                          width={boxPositionValues.width}
                          height={boxPositionValues.height}
                          bgcolor={'#6dcb6d7d'}
                          sx={{ opacity: '80%' }}
                          border={'2px solid black'}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          );
        },
      )}
    </Box>
  );
};

export default React.memo(DocumentTableDebugLayout);
