import {
  AddCircleOutlined,
  ArrowBack,
  DeleteForever,
  Menu,
} from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import {
  CreateInvoiceRequest,
  Invoice,
  InvoiceSTATUS,
  LineItem,
  UpdateInvoiceRequest,
} from 'protos/pb/demo/invoice_service';
import React, { useState } from 'react';
import {
  createInvoiceAction,
  updateInvoiceAction,
} from '../../redux/actions/erp.action';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import {
  processingInvoiceSelector,
  selectedInvoiceSelector,
} from '../../redux/selectors/erp.selectors';
import { loggedInUserSelector } from '../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  children: React.ReactNode;
}

const InvoiceField: React.FC<Props> = ({ title, children }) => {
  return (
    <Box
      display={'flex'}
      sx={{ width: '33%' }}
      alignItems={'center'}
      justifyContent={'end'}
    >
      <Box display={'flex'} justifyContent={'start'}>
        <Typography>{title}</Typography>
      </Box>
      <Box sx={{ width: '15px' }} />
      <Box sx={{ width: '65%' }}>{children}</Box>
    </Box>
  );
};

const validationSchema = Yup.object().shape({
  vendorName: Yup.string(),
  vendorContact: Yup.string(),
  billingAddress: Yup.string(),
  number: Yup.string(),
  invoiceDate: Yup.string(),
  invoiceDueDate: Yup.string(),
  purchaseOrderNumber: Yup.string(),
  lineItems: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      description: Yup.string(),
      quantity: Yup.string(),
      price: Yup.string(),
    }),
  ),
  total: Yup.string().required('Total is required.'),
});

const CreateInvoice: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(processingInvoiceSelector);
  const user = useSelector(loggedInUserSelector);
  const selectedInvoice = useSelector(selectedInvoiceSelector)!;
  const [isDraft, setIsDraft] = useState(false);

  const formik = useFormik({
    initialValues: selectedInvoice
      ? selectedInvoice
      : {
          vendorName: '',
          vendorContact: '',
          billingAddress: '',
          number: '',
          invoiceDate: '',
          invoiceDueDate: '',
          purchaseOrderNumber: '',
          lineItems: [
            {
              id: '',
              description: '',
              quantity: '',
              price: '',
            },
          ],
          total: '',
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const invoice: Invoice = Invoice.fromJSON(values);
      invoice.total = String(invoice.total);
      for (const lineItem of invoice.lineItems as LineItem[]) {
        lineItem.quantity = String(lineItem.quantity);
        lineItem.price = String(lineItem.price);
      }
      invoice.status = isDraft ? InvoiceSTATUS.DRAFT : InvoiceSTATUS.SUBMITTED;
      if (selectedInvoice) {
        const updateRequest = UpdateInvoiceRequest.create({});
        updateRequest.invoice = invoice;
        dispatch(updateInvoiceAction(updateRequest));
      } else {
        const createRequest = CreateInvoiceRequest.create();
        createRequest.invoice = invoice;
        createRequest.parent = `users/${user?.email}`;
        dispatch(createInvoiceAction(createRequest));
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box sx={{ paddingX: '45px', paddingY: '15px' }}>
          <Box display={'flex'}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack
                sx={{ color: 'black', fontSize: '32px', paddingRight: '12px' }}
              />
            </IconButton>
            <Typography fontSize={'48px'} fontWeight={700}>
              My ERP
            </Typography>
          </Box>
          <Box
            sx={{
              border: '1px solid #0000003B',
              paddingX: '40px',
              paddingY: '35px',
              borderRadius: '20px',
              marginTop: '20px',
            }}
          >
            <Box display={'flex'} marginBottom={'30px'} alignItems={'start'}>
              <InvoiceField title='Vendor Name'>
                <TextField
                  fullWidth
                  autoComplete='vendorName'
                  {...getFieldProps('vendorName')}
                  error={Boolean(touched.vendorName && errors.vendorName)}
                  helperText={touched.vendorName && errors.vendorName}
                />
              </InvoiceField>
              <InvoiceField title='Vendor Contact'>
                <TextField
                  fullWidth
                  autoComplete='vendorContact'
                  {...getFieldProps('vendorContact')}
                  error={Boolean(touched.vendorContact && errors.vendorContact)}
                  helperText={touched.vendorContact && errors.vendorContact}
                />
              </InvoiceField>
              <InvoiceField title='Billing Address'>
                <TextField
                  fullWidth
                  autoComplete='billingAddress'
                  {...getFieldProps('billingAddress')}
                  error={Boolean(
                    touched.billingAddress && errors.billingAddress,
                  )}
                  helperText={touched.billingAddress && errors.billingAddress}
                />
              </InvoiceField>
            </Box>
            <Box display={'flex'} marginBottom={'30px'} alignItems={'start'}>
              <InvoiceField title='Invoice #'>
                <TextField
                  fullWidth
                  autoComplete='number'
                  {...getFieldProps('number')}
                  error={Boolean(touched.number && errors.number)}
                  helperText={touched.number && errors.number}
                />
              </InvoiceField>
              <InvoiceField title='Invoice date'>
                <TextField
                  fullWidth
                  autoComplete='invoiceDate'
                  // type="date" /* this will be undone in future */
                  {...getFieldProps('invoiceDate')}
                  error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                  helperText={touched.invoiceDate && errors.invoiceDate}
                />
              </InvoiceField>
              <InvoiceField title='Due date'>
                <TextField
                  fullWidth
                  autoComplete='invoiceDueDate'
                  // type="date" /* this will be undone in future */
                  {...getFieldProps('invoiceDueDate')}
                  error={Boolean(
                    touched.invoiceDueDate && errors.invoiceDueDate,
                  )}
                  helperText={touched.invoiceDueDate && errors.invoiceDueDate}
                />
              </InvoiceField>
            </Box>
            <Box display={'flex'} marginBottom={'30px'}>
              <InvoiceField title='PO #'>
                <TextField
                  fullWidth
                  autoComplete='purchaseOrderNumber'
                  {...getFieldProps('purchaseOrderNumber')}
                  error={Boolean(
                    touched.purchaseOrderNumber && errors.purchaseOrderNumber,
                  )}
                  helperText={
                    touched.purchaseOrderNumber && errors.purchaseOrderNumber
                  }
                />
              </InvoiceField>
            </Box>
            <Box
              display={'flex'}
              bgcolor={'#D9D9D980'}
              paddingY={'10px'}
              paddingX={'20px'}
            >
              <Box width={'5%'} />
              <Typography width={'15%'} marginRight={'20px'}>
                Item ID
              </Typography>
              <Typography width={'25%'} marginRight={'20px'}>
                Item Description
              </Typography>
              <Typography width={'10%'} marginRight={'20px'}>
                Quantity
              </Typography>
              <Typography width={'10%'} marginRight={'20px'}>
                Price
              </Typography>
              {/* <Typography width={'10%'}>Tax</Typography> */}
            </Box>
            <FieldArray
              name='lineItems'
              render={(arrayHelpers) => {
                const items = formik.values.lineItems;
                return (
                  <Box>
                    {items && items.length > 0
                      ? items.map((_, index) => (
                          <Box
                            key={`lineItems${index}`}
                            display={'flex'}
                            paddingY={'10px'}
                            paddingX={'20px'}
                            alignItems={'center'}
                          >
                            <Box width={'5%'}>
                              <Menu />
                            </Box>
                            <Box width={'15%'} marginRight={'20px'}>
                              <TextField
                                fullWidth
                                autoComplete={`lineItems[${index}].id`}
                                {...getFieldProps(`lineItems[${index}].id`)}
                              />
                            </Box>
                            <Box width={'25%'} marginRight={'20px'}>
                              <TextField
                                fullWidth
                                autoComplete={`lineItems[${index}].description`}
                                {...getFieldProps(
                                  `lineItems[${index}].description`,
                                )}
                              />
                            </Box>
                            <Box width={'10%'} marginRight={'20px'}>
                              <TextField
                                fullWidth
                                type='number'
                                autoComplete={`lineItems[${index}].quantity`}
                                {...getFieldProps(
                                  `lineItems[${index}].quantity`,
                                )}
                              />
                            </Box>
                            <Box width={'10%'} marginRight={'20px'}>
                              <TextField
                                fullWidth
                                type='number'
                                autoComplete={`lineItems[${index}].price`}
                                {...getFieldProps(`lineItems[${index}].price`)}
                              />
                            </Box>
                            {/* <Box width={'10%'}>
                            <TextField
                              fullWidth
                              type="number"
                              autoComplete={`lineItems[${index}].tax`}
                              {...getFieldProps(`lineItems[${index}].tax`)}
                            />
                          </Box> */}
                            <Box sx={{ marginLeft: '15px' }}>
                              <IconButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              >
                                <DeleteForever sx={{ color: '#1669F7' }} />
                              </IconButton>
                            </Box>
                          </Box>
                        ))
                      : null}
                    <Box
                      sx={{ cursor: 'pointer' }}
                      display={'flex'}
                      marginLeft={'20px'}
                      marginY={'20px'}
                      onClick={() => {
                        arrayHelpers.push(LineItem.create());
                      }}
                    >
                      <AddCircleOutlined
                        color='primary'
                        sx={{ marginRight: '12px' }}
                      />
                      <Typography>Add an item</Typography>
                    </Box>
                  </Box>
                );
              }}
            />
            <Box display={'flex'} justifyContent={'end'}>
              <InvoiceField title='Total'>
                <TextField
                  fullWidth
                  autoComplete='total'
                  type='number'
                  {...getFieldProps('total')}
                  error={Boolean(touched.total && errors.total)}
                  helperText={touched.total && errors.total}
                />
              </InvoiceField>
            </Box>
            <Box display={'flex'} justifyContent={'end'} marginTop={'25px'}>
              <Button
                variant='outlined'
                type='submit'
                color='primary'
                onClick={() => {
                  setIsDraft(true);
                }}
              >
                SAVE
              </Button>
              <Button
                variant='contained'
                sx={{ backgroundColor: '#1669F7', marginLeft: '25px' }}
                type='submit'
                onClick={() => {
                  setIsDraft(false);
                }}
              >
                {loading ? 'LOADING' : 'SUBMIT'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default React.memo(CreateInvoice);
