import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../../components/CustomTypography';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionRedo,
  actionUndo,
  addSuggestedRowsAction,
  clearActionHistory,
  openAddRowModalAction,
  setAddLocationAction,
  setSelectedParentEntityTypeAction,
} from '../../../../../../redux/actions/review_task.action';
import {
  allowEditingTaskSelector,
  entitySidePanelCollapsedSelector,
  openAddRowModalSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoMapSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import TableModalFooter from './components/TableModalFooter';
import {
  makeDraggableCard,
  makeResizableDiv,
} from '../../../../../../utils/BoundingBoxUtils';
import {
  DEFAULT_TABLE_MODAL_WIDTH,
  ELLIPSIS_STYLE,
  TABLE_MODAL_MIN_HEIGHT,
  TABLE_MODAL_MIN_WIDTH,
} from '../../../../../../utils/constants';
import { usePreventOverflow } from '../../../../../../hooks/usePreventOverflow';
import { getTitleForTableModal } from '../../../../../../utils/ReviewTaskUtils';
import FocusTrap from '@mui/material/Unstable_TrapFocus';
import CustomModal from '../../../../../../components/CustomModal';
import TableModalBodyV2 from './components/TableModalBodyV2';

interface Props {
  pdfRef: React.RefObject<HTMLElement>;
  left: string;
  documentWidth: number;
  availableWidth: number;
}

const ReviewPageTableModal: React.FC<Props> = ({
  pdfRef,
  left,
  documentWidth,
  availableWidth,
}) => {
  const dispatch = useDispatch();
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const selectedTableEntitiesInfoMap = useSelector(
    selectedTableEntitiesInfoMapSelector,
  );
  const isSidePanelCollapsed = useSelector(entitySidePanelCollapsedSelector);

  const isOverlapping = () => {
    if (pdfRef.current) {
      const width = pdfRef.current.clientWidth;
      return !(width > documentWidth + DEFAULT_TABLE_MODAL_WIDTH);
    }
    return false;
  };

  const [showNormalizedValues, setShowNormalizedValues] = useState(false);
  const [isInitialHeightSet, setIsInitialHeightSet] = useState(false);
  const tableModalRef = useRef<HTMLElement>(null);
  const [isMinWidthReached, setIsMinWidthReached] = useState(false);
  const openAddRowModal = useSelector(openAddRowModalSelector);

  // FUNCTION TO GET THE MAXIMUM AMOUNT OF LEFT POSITION THAT CAN BE ASSIGNED
  // WITHOUT LETTING THE COMPONENT GO OUT OF THE SCREEN
  const getMaxLeftPosition = (): number => {
    if (pdfRef.current && tableModalRef.current) {
      const width = pdfRef.current.clientWidth;
      const modalWidth = tableModalRef.current?.clientWidth;
      return width - modalWidth;
    } else {
      return 0;
    }
  };

  // FUNCTION TO GET THE MAXIMUM AMOUNT OF BOTTOM POSITION THAT CAN BE ASSIGNED
  // WITHOUT LETTING THE COMPONENT GO OUT OF THE SCREEN
  const getMaxBottomPosition = (): number => {
    if (pdfRef.current) {
      return pdfRef.current.clientHeight;
    } else {
      return 0;
    }
  };

  const tableHeaderText = useMemo(() => {
    if (!selectedParentEntityInfo) {
      return '';
    }
    return getTitleForTableModal(
      selectedParentEntityInfo,
      selectedEntityIdsForAnnotation,
      selectedTableEntitiesInfoMap,
    );
  }, [
    selectedParentEntityInfo?.id,
    selectedEntityIdsForAnnotation,
    selectedTableEntitiesInfoMap,
  ]);

  useEffect(() => {
    if (selectedParentEntityInfo && tableModalRef.current) {
      const draggableElem = document.getElementById(
        'floating-table-modal-header',
      );
      const maxHeight = pdfRef.current?.clientHeight;

      if (maxHeight) {
        tableModalRef.current.style.maxHeight = `${maxHeight}px`;
      }
      makeDraggableCard(
        tableModalRef.current,
        getMaxLeftPosition,
        getMaxBottomPosition,
        draggableElem,
      );
      makeResizableDiv(
        tableModalRef.current,
        () => null,
        TABLE_MODAL_MIN_WIDTH,
        TABLE_MODAL_MIN_HEIGHT,
        setIsMinWidthReached,
      );
    }
  }, [selectedParentEntityInfo, tableModalRef.current]);

  useEffect(() => {
    function updateTablePosition() {
      if (tableModalRef.current && pdfRef.current) {
        const tableWidth = tableModalRef.current.clientWidth;
        const pdfRight = pdfRef.current.getBoundingClientRect().right;
        const tableRight = tableModalRef.current.getBoundingClientRect().right;
        if (availableWidth < tableWidth) {
          // Adjust the table modal's width and position it to the far left
          tableModalRef.current.style.width = availableWidth + 'px';
          tableModalRef.current.style.left = '0px';
        } else if (tableRight > pdfRight) {
          const tablePreviousLeft = parseInt(
            getComputedStyle(tableModalRef.current).left,
            10,
          );
          tableModalRef.current.style.left =
            tablePreviousLeft - Math.abs(pdfRight - tableRight) + 'px';
        }
      }
    }
    // the reason we have added a delay is because due to side nav hiding and showing animation
    // it takes dom some time to reflect those changes correctly
    const cancel = setTimeout(() => {
      updateTablePosition();
    }, 300);
    return () => {
      clearTimeout(cancel);
    };
  }, [availableWidth, isSidePanelCollapsed]);

  // adjust table height initially
  useEffect(() => {
    if (
      // once initial height is set we do not set it again so as to maintain the user preferred size
      // ref: https://orby-ai.atlassian.net/browse/OA-2983?focusedCommentId=14725
      !isInitialHeightSet &&
      selectedParentEntityInfo?.id &&
      tableModalRef.current &&
      pdfRef.current
    ) {
      if (isOverlapping()) {
        const thirtyVhHeight = window.innerHeight * 0.3;

        // Determine the maximum height
        const maxHeight = Math.max(TABLE_MODAL_MIN_HEIGHT + 5, thirtyVhHeight);

        tableModalRef.current.style.height = maxHeight + 'px';
      } else {
        tableModalRef.current.style.height = '100%';
      }
      setIsInitialHeightSet(true);
    }
  }, [selectedParentEntityInfo, tableModalRef.current]);

  useEffect(() => {
    if (selectedEntityIdsForAnnotation.length > 1) {
      dispatch(setAddLocationAction(false));
    }
  }, [selectedEntityIdsForAnnotation]);

  usePreventOverflow(tableModalRef, pdfRef, [
    selectedParentEntityInfo,
    tableModalRef.current,
  ]);

  useEffect(() => {
    // Add an event listener for the ESC key
    // upon pressing `Esc` key, close the modal
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dispatch(setSelectedParentEntityTypeAction(undefined));
      }
    };
    if (selectedParentEntityInfo?.id) {
      document.addEventListener('keydown', handleEscKey);
      return () => {
        document.removeEventListener('keydown', handleEscKey);
      };
    }
  }, [selectedParentEntityInfo?.id]);
  const handleUndoRedo = (e: KeyboardEvent) => {
    // undo
    if ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ') {
      dispatch(actionUndo());
    }
    // redo
    else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyY') {
      // prevent browser from opening history tab
      e.preventDefault();

      dispatch(actionRedo());
    }
  };

  useEffect(() => {
    if (selectedParentEntityInfo?.type) {
      dispatch(clearActionHistory());
      document.addEventListener('keydown', handleUndoRedo);
      return () => {
        dispatch(clearActionHistory());
        document.removeEventListener('keydown', handleUndoRedo);
      };
    }
  }, [selectedParentEntityInfo?.type]);

  if (!selectedParentEntityInfo?.id) return <Box display={'none'} />;

  return (
    <Box
      ref={tableModalRef}
      left={left}
      width={`${DEFAULT_TABLE_MODAL_WIDTH}px`}
      id={'review-page-floating-table-modal'}
      maxWidth={'100% !important'}
      position={'absolute'}
      top={isOverlapping() ? undefined : getMaxBottomPosition() / 2 - 205}
      bottom={isOverlapping() ? 0 : undefined}
      display={'flex'}
      flexDirection={'column'}
      zIndex={100}
      borderRadius={'8px'}
      sx={{
        userSelect: 'none',
        boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.25)',
      }}
      minWidth={`${TABLE_MODAL_MIN_WIDTH}px !important`}
      minHeight={`${TABLE_MODAL_MIN_HEIGHT}px`}
    >
      <FocusTrap disableRestoreFocus open={!!selectedParentEntityInfo?.id}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          width={'100%'}
        >
          <Box
            sx={{
              cursor: 'ns-resize',
              top: '0px',
              position: 'absolute',
            }}
            className={'resizer top'}
            bgcolor={'transparent'}
            width={'100%'}
            height={'5px'}
          />
          <Box
            sx={{
              cursor: 'nesw-resize',
              top: '0px',
              right: '0px',
              position: 'absolute',
            }}
            className={'resizer top-right'}
            bgcolor={'transparent'}
            width={'10px'}
            height={'10px'}
          />
          <Box
            sx={{
              cursor: 'nwse-resize',
              top: '0px',
              left: '0px',
              position: 'absolute',
            }}
            className={'resizer top-left'}
            bgcolor={'transparent'}
            width={'10px'}
            height={'10px'}
          />
          <Box
            id={'floating-table-modal-header'}
            display={'flex'}
            maxHeight={'400px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            bgcolor={'#EAECF0'}
            padding={'0px 24px'}
            borderRadius={'8px 8px 0px 0px'}
            sx={{
              cursor: 'grab',
              ':active': { cursor: 'grabbing' },
            }}
          >
            <Box
              flexDirection={'column'}
              display={'flex'}
              py={'8px'}
              width={'calc(100% - 40px)'}
            >
              <CustomTypography
                sx={{ ...ELLIPSIS_STYLE }}
                typographyType={TypographyType.MediumPara}
              >
                {tableHeaderText}
              </CustomTypography>
              <CustomTypography
                id={'floating-table-modal-header-warning-text'}
                sx={{
                  overflowWrap: 'break-word',
                  display: 'none', // this would be dynamically set
                  mt: '4px',
                  color: '#DC6803',
                }}
                size={'14px'}
              >
                Please confirm to save your changes
              </CustomTypography>
            </Box>
            <IconButton
              aria-label='Close and discard all changes'
              title='Close and discard all changes'
              onClick={() => {
                dispatch(setSelectedParentEntityTypeAction(undefined));
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            bgcolor={'white'}
            borderRadius={'0px 0px 8px 8px'}
            padding={'0px'}
            position={'relative'}
            overflow={'auto'}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            sx={{
              boxShadow:
                '0px 4px 4px -4px #ececec,4px 0px 4px -4px #ececec,-4px 0px 4px -4px #ececec',
            }}
          >
            <Box
              sx={{
                cursor: 'ew-resize',
                left: '0px',
                position: 'absolute',
              }}
              className={'resizer left'}
              marginTop={'-16px'}
              bgcolor={'transparent'}
              width={'5px'}
              height={'100%'}
            />
            <Box
              sx={{
                cursor: 'ew-resize',
                right: '0px',
                position: 'absolute',
              }}
              className={'resizer right'}
              marginTop={'-16px'}
              bgcolor={'transparent'}
              width={'5px'}
              height={'100%'}
            />
            <Box
              sx={{
                cursor: 'ns-resize',
                bottom: '0px',
                left: '0px',
                position: 'absolute',
              }}
              className={'resizer bottom'}
              bgcolor={'transparent'}
              width={'100%'}
              height={'5px'}
            />
            <Box
              sx={{
                cursor: 'nesw-resize',
                bottom: '0px',
                left: '0px',
                position: 'absolute',
              }}
              className={'resizer bottom-left'}
              marginTop={'-16px'}
              bgcolor={'transparent'}
              width={'10px'}
              height={'10px'}
            />
            <Box
              sx={{
                cursor: 'nwse-resize',
                bottom: '0px',
                right: '0px',
                position: 'absolute',
              }}
              className={'resizer bottom-right'}
              marginTop={'-16px'}
              bgcolor={'transparent'}
              width={'10px'}
              height={'10px'}
            />
            <TableModalBodyV2 showNormalizedValues={showNormalizedValues} />
            {allowEditingTask && (
              <TableModalFooter
                isMinWidthReached={isMinWidthReached}
                showNormalizedValues={showNormalizedValues}
                setShowNormalizedValues={setShowNormalizedValues}
              />
            )}
            {/* This modal suggest to add the extra rows to the table, when extra rows annotated*/}
            <CustomModal
              open={openAddRowModal}
              handleClose={() => dispatch(openAddRowModalAction(false))}
              onPrimaryClick={() => {
                dispatch(addSuggestedRowsAction());
                dispatch(openAddRowModalAction(false, true));
              }}
              heading={'Additional rows'}
              content={
                <Box>
                  <CustomTypography lineHeight='18px'>
                    Your selection includes more rows than the current table.
                    Would you like to add the extra rows to the table?
                  </CustomTypography>
                </Box>
              }
              primaryLabel={'Add'}
              secondaryLabel={'Do not add extra'}
              containerSx={{ width: '400px', height: '110px' }}
              primaryButtonSx={{
                width: '168px',
                height: '44px',
              }}
              secondaryButtonSx={{ height: '44px' }}
              hideBackdrop={true}
            />
          </Box>
        </Box>
      </FocusTrap>
    </Box>
  );
};

export default React.memo(ReviewPageTableModal);
