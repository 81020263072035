import * as React from 'react';
import {
  MenuItem,
  Menu,
  Button,
  styled,
  buttonClasses,
  svgIconClasses,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { WidgetContainer } from './Styled';
import { grey } from '@mui/material/colors';
import { OrbyColorPalette } from 'orby-ui/src';
import { ActionObject } from 'workflow-utils/src/types';
import { WidgetType } from '../../../redux/reducers/workflow_detail.reducer';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { ReactComponent as IfElseIcon } from '../../../static/icons/if-else.svg';

const StyledButton = styled(Button)<{ open: boolean }>(({ theme, open }) => ({
  border: 'none',
  borderRadius: 0,
  padding: 0,
  width: '100%',
  justifyContent: 'space-between',
  fontSize: 18,
  lineHeight: '36px',
  borderBottom: open ? `2px solid ${OrbyColorPalette['blue-700']}` : 'none',
  color: grey[500],
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`& .${svgIconClasses.root}`]: {
    marginRight: theme.spacing(3),
  },
  textWrap: 'wrap',
}));

interface IProps {
  action: ActionObject;
  processId?: string;
}

const DropdownWidget: React.FC<IProps> = ({ action, processId }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type: WidgetType) => {
    switch (type) {
      case WidgetType.Click:
        dispatch(
          updateAction(
            action,
            {
              description: 'Click <start>Select<end>',
              action: {
                widget: undefined, // Adding this line to display the click action in the UI
                click: {
                  locator: {},
                  elementLocator: {},
                },
              } as ActionObject,
            },
            processId,
          ),
        );
        break;
      case WidgetType.Extract:
        throw Error('Not implemented yet');
      default:
        break;
    }
    setAnchorEl(null);
  };

  const menuWidth = anchorEl ? getComputedStyle(anchorEl).width : 'auto';
  return (
    <WidgetContainer>
      <StyledButton
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        open={open}
      >
        Do this
      </StyledButton>
      <Menu
        sx={{ mt: 1 }}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            sx: {
              width: menuWidth,
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem
          value='ifElse'
          onClick={() => handleSelect(WidgetType.Click)}
        >
          <IfElseIcon /> Click
        </StyledMenuItem>
      </Menu>
    </WidgetContainer>
  );
};

export default DropdownWidget;
