import {
  DeleteWorkflowRequest,
  ListWorkflowsRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { OrbotWorkflowActionType } from '../actions/actions.constants';
import {
  deleteWorkflowCompletedAction,
  deleteWorkflowErrorAction,
  listWorkflowCompletedAction,
  listWorkflowErrorAction,
} from '../actions/orbot.action';
import { orbotService } from '../../services/OrbotService';

export function* listWorkflowsSaga(data: {
  type: OrbotWorkflowActionType.LIST_WORKFLOWS;
  req: ListWorkflowsRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      orbotService.listWorkflows,
      data.req,
    );
    if (response) {
      yield put(
        listWorkflowCompletedAction(
          response.workflows,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listWorkflowErrorAction(error));
    }
  } catch (error) {
    yield put(listWorkflowErrorAction(error as Error));
  }
}

export function* deleteWorkflowSaga(data: {
  type: OrbotWorkflowActionType.DELETE_WORKFLOW;
  payload: DeleteWorkflowRequest;
}): any {
  try {
    yield call(orbotService.deleteWorkflow, data.payload);
    yield put(deleteWorkflowCompletedAction(data.payload.workflowId as string));
  } catch (e: any) {
    yield put(
      deleteWorkflowErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* orbotSaga() {
  yield all([
    takeLatest(OrbotWorkflowActionType.LIST_WORKFLOWS, listWorkflowsSaga),
    takeLatest(OrbotWorkflowActionType.DELETE_WORKFLOW, deleteWorkflowSaga),
  ]);
}

export default orbotSaga;
