import { Box } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { SelectedExtractedField } from '../../../../../utils/constants';

interface SearchFieldProps {
  selectedValues: SelectedExtractedField[];
  dropdownValues: SelectedExtractedField[];
  handleAdd: (e: SelectedExtractedField[]) => void;
  isChildDropdown?: boolean;
  ariaDescribeBy: string;
  ariaLabel: string;
  placeholder: string;
  disabled?: boolean;
  dropdownWidth: string;
}

const SearchNestedEntities: FC<SearchFieldProps> = (props) => {
  const {
    selectedValues,
    dropdownValues,
    handleAdd,
    ariaLabel,
    ariaDescribeBy,
    placeholder,
    disabled,
    dropdownWidth,
    isChildDropdown,
  } = props;
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchListState, setSearchListState] =
    useState<SelectedExtractedField[]>(dropdownValues);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        setSearchValue('');
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  /**
   * The following code is to prevent the enter key from
   * submitting the form and also prevent default enter key behavior
   * when the user is typing in the search field and hits enter.
   */
  useEffect(() => {
    function handleEnterKey(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        if (showSuggestions) {
          event.preventDefault();
        }
      }
    }
    document.addEventListener('keydown', handleEnterKey);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleEnterKey);
    };
  });

  useEffect(() => {
    setSearchListState(
      dropdownValues.filter((p) => !selectedValues.includes(p)),
    );
  }, [selectedValues]);

  const getAllParentExtractedFields = () => {
    const parents: SelectedExtractedField[] = [];
    for (const entity of dropdownValues) {
      const parent = dropdownValues.find((p) => p.parent === entity.entityName);
      if (parent && !entity.parent && !parents.includes(parent)) {
        parents.push(entity);
      }
    }
    return parents;
  };

  const getAllNormalExtractedFields = () => {
    const normalFields: SelectedExtractedField[] = [];
    for (const entity of searchListState) {
      const isParent = searchListState.find(
        (p) => p.parent === entity.entityName,
      );
      if (!isParent && !entity.parent) {
        normalFields.push(entity);
      }
    }
    return normalFields;
  };

  return (
    <Box>
      <Box position={'relative'} width={'97%'} maxWidth={'97%'}>
        <OrbyTextField
          aria-label={ariaLabel}
          aria-describedby={ariaDescribeBy}
          value={searchValue}
          width={dropdownWidth}
          placeholder={placeholder}
          disabled={disabled}
          onClick={() => {
            setShowSuggestions(true);
          }}
          onChange={(event) => {
            setSearchValue(event.target.value);
            setSearchListState(
              dropdownValues.filter(
                (val: SelectedExtractedField) =>
                  val?.entityName
                    .toLowerCase()
                    .includes(event.target.value?.toLowerCase()) &&
                  !selectedValues.includes(val),
              ),
            );
          }}
        />
        {((searchValue != '' && searchListState.length > 0) ||
          showSuggestions) && (
          <Box
            ref={searchFieldRef}
            position={'absolute'}
            top={'46px'}
            width={dropdownWidth}
            zIndex={1}
            display={'block'}
            sx={{
              scrollbarColor: `${OrbyColorPalette['grey-200']} transparent`, // does not work for safari https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color#browser_compatibility
              maxHeight: '200px',
              overflowY: 'auto',
              borderRadius: '8px',
              background: OrbyColorPalette['white-0'],
              border: `1px solid ${OrbyColorPalette['grey-100']}`,
              boxShadow:
                '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            }}
          >
            {searchListState.length > 0 ? (
              <>
                {isChildDropdown &&
                  searchListState.map((child) => (
                    <OrbyMenuItem
                      onClick={() => {
                        handleAdd([child]);
                        setSearchValue('');
                        setShowSuggestions(false);
                      }}
                      key={child.entityName}
                      title={child.entityName}
                      value={child.entityName}
                      width={dropdownWidth}
                    />
                  ))}
                {getAllParentExtractedFields().map((parent) => {
                  const childEntities = dropdownValues.filter(
                    (e) => e.parent === parent.entityName,
                  );
                  const filteredEntities = childEntities.filter((c) =>
                    searchListState.includes(c),
                  );

                  if (filteredEntities.length === 0)
                    return <Box key={parent.entityName} />;

                  return (
                    <>
                      <OrbyMenuItem
                        onClick={() => {
                          // Adding all child entities with parent
                          const entitiesToAdd = [...filteredEntities];
                          // Add parent only when it is not present in selectedValues
                          if (!selectedValues.includes(parent)) {
                            entitiesToAdd.push(parent);
                          }
                          handleAdd(entitiesToAdd);
                          setSearchValue('');
                          setShowSuggestions(false);
                        }}
                        width={dropdownWidth}
                        title={parent.entityName}
                        key={parent.entityName}
                        value={parent.entityName}
                      />
                      {filteredEntities.map((child) => (
                        <OrbyMenuItem
                          onClick={() => {
                            const entitiesToAdd = [child];
                            // Add parent with the child if it is not already selected and present in search list.
                            if (
                              !selectedValues.includes(parent) &&
                              dropdownValues.includes(parent)
                            ) {
                              entitiesToAdd.push(parent);
                            }
                            handleAdd(entitiesToAdd);
                            setSearchValue('');
                            setShowSuggestions(false);
                          }}
                          width={dropdownWidth}
                          key={child.entityName}
                          title={child.entityName}
                          value={child.entityName}
                        />
                      ))}
                    </>
                  );
                })}
                {getAllNormalExtractedFields().map((entity) => {
                  return (
                    <OrbyMenuItem
                      onClick={() => {
                        handleAdd([entity]);
                        setSearchValue('');
                        setShowSuggestions(false);
                      }}
                      width={dropdownWidth}
                      key={entity.entityName}
                      title={entity.entityName}
                      value={entity.entityName}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign={'center'}
                sx={{
                  cursor: 'not-allowed',
                  padding: '10px 20px',
                }}
              >
                <OrbyTypography>No results found</OrbyTypography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchNestedEntities);
