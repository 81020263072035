import { Box, TextField } from '@mui/material';
import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import {
  updateEntityInfoForTableAnnotationAction,
  updateEntityInfoNormalizedValueAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  INT32_MAX,
  NEGATIVE_INT32_MAX,
  isValidNumber,
  processNumber,
  processText,
} from '../../../../../../../utils/normalization';
import { detectUndoRedoKeyPress } from '../../../../../../../utils/helpers';

interface NormalizedTextFieldProps {
  name: string;
  error?: boolean;
  color?: string;
  label: string;
  size: 'small' | 'medium' | 'large' | 'full';
  disabled?: boolean;
  extraProps?: any;
  placeholder?: string;
  multiline?: boolean;
  ariaLabel?: string;
  required?: boolean;
  hasDescription?: boolean;
  autoFocus?: boolean;
  readonly?: boolean;
  entity: EntityInfo;
  structuredValue?: any;
  task: Task;
  insideTableLayout?: boolean;
}

const NormalizedTextField: FC<NormalizedTextFieldProps> = (props) => {
  const {
    size,
    disabled = false,
    label,
    color,
    extraProps,
    placeholder,
    multiline,
    ariaLabel,
    required = false,
    name,
    hasDescription,
    autoFocus,
    readonly = false,
    entity,
    insideTableLayout,
  } = props;
  let width = '';

  const dispatch = useDispatch();

  const updateEntityInfo = (
    entityInfo: EntityInfo,
    excludeFromHistory = false,
  ) => {
    if (insideTableLayout) {
      dispatch(
        updateEntityInfoForTableAnnotationAction(
          entityInfo,
          false,
          false,
          excludeFromHistory,
        ),
      );
    } else {
      dispatch(
        updateEntityInfoNormalizedValueAction(entityInfo, excludeFromHistory),
      );
    }
  };

  const handleBlur = () => {
    if (!entity.error && !readonly) {
      switch (entity.normalizedEntityType) {
        case EntityDataType.ENTITY_TYPE_FLOAT: {
          handleFloatBlur();
          return;
        }
        case EntityDataType.ENTITY_TYPE_INTEGER: {
          handleIntegerBlur();
          return;
        }
        case EntityDataType.ENTITY_TYPE_TEXT: {
          const newEntityInfo = { ...entity };
          const text = processText(entity.normalizedInputValue ?? '');
          newEntityInfo.normalizedValue = {};
          newEntityInfo.normalizedValue.text = text;
          newEntityInfo.isNormalizedValueModified = true;
          newEntityInfo.normalizedInputValue = text;
          updateEntityInfo(
            newEntityInfo,
            entity.normalizedInputValue === newEntityInfo.normalizedInputValue, // exclude the action if both values are equal
          );
        }
      }
    }
  };

  const handleIntegerBlur = () => {
    const newEntityInfo = { ...entity };
    const normalizedIntegerValue = processNumber(
      entity.normalizedInputValue ?? '',
    );
    if (normalizedIntegerValue && 'integerValue' in normalizedIntegerValue) {
      newEntityInfo.normalizedValue = {};
      newEntityInfo.normalizedValue.integerValue =
        normalizedIntegerValue.integerValue;
      newEntityInfo.isNormalizedValueModified = true;
      newEntityInfo.normalizedInputValue =
        normalizedIntegerValue.integerValue?.toString();
      updateEntityInfo(
        newEntityInfo,
        entity.normalizedInputValue === newEntityInfo.normalizedInputValue, // exclude the action if both values are equal
      );
    }
  };

  const handleFloatBlur = () => {
    const newEntityInfo = { ...entity };
    const normalizedIntegerValue = processNumber(
      entity.normalizedInputValue ?? '',
    );
    if (normalizedIntegerValue && 'floatValue' in normalizedIntegerValue) {
      newEntityInfo.normalizedValue = {};
      newEntityInfo.normalizedValue.floatValue =
        normalizedIntegerValue.floatValue;
      newEntityInfo.isNormalizedValueModified = true;
      newEntityInfo.normalizedInputValue =
        normalizedIntegerValue.floatValue?.toString();
      updateEntityInfo(
        newEntityInfo,
        entity.normalizedInputValue === newEntityInfo.normalizedInputValue, // exclude the action if both values are equal
      );
    }
  };

  const handleIntegerChange = (inputValue: string) => {
    const allowRegex = /^[+-]?\d*$/; // allow only digit along with sign (+,-)
    if (allowRegex.test(inputValue) || inputValue === '') {
      if (inputValue === '') {
        updateEntityInfo({
          ...entity,
          normalizedInputValue: inputValue,
          error: null,
        });
        return;
      }

      const integerPart = parseInt(inputValue);

      // for handing cases with only signs
      if (isNaN(integerPart)) {
        updateEntityInfo({
          ...entity,
          normalizedInputValue: inputValue,
          error: 'Entered number is invalid',
        });
      } else {
        updateEntityInfo({
          ...entity,
          normalizedInputValue: inputValue,
          error:
            integerPart < NEGATIVE_INT32_MAX || integerPart > INT32_MAX
              ? 'The accepted range is between -2,147,483,648 and 2,147,483,647'
              : null,
        });
      }
    }
  };

  const handleFloatChange = (inputValue: string) => {
    const allowRegex = /^[+-]?\d*\.?\d*$/; // allows digit and decimals along with sign (-,+)
    if (allowRegex.test(inputValue) || inputValue === '') {
      if (inputValue === '' || isValidNumber(inputValue)) {
        updateEntityInfo({
          ...entity,
          normalizedInputValue: inputValue,
          error: null,
        });
        return;
      }
      // TODO: Fix float number range
      updateEntityInfo({
        ...entity,
        normalizedInputValue: inputValue,
        error: 'Entered number is invalid',
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readonly) {
      const inputValue = event.target.value;
      switch (entity.normalizedEntityType) {
        case EntityDataType.ENTITY_TYPE_FLOAT: {
          handleFloatChange(inputValue);
          return;
        }
        case EntityDataType.ENTITY_TYPE_INTEGER: {
          handleIntegerChange(inputValue);
          return;
        }
        case EntityDataType.ENTITY_TYPE_TEXT: {
          updateEntityInfo({ ...entity, normalizedInputValue: inputValue });
        }
      }
    }
  };

  const inputProps = {
    'aria-label': ariaLabel ?? label,
    'aria-required': required,
    'aria-describedby': hasDescription ? name : undefined,
    disableUnderline: true,
    readOnly: readonly,
  };
  switch (size) {
    case 'small':
      width = '359px';
      break;
    case 'medium':
      width = '446px';
      break;
    case 'large':
      width = '485px';
      break;
    case 'full':
      width = '100%';
  }
  const getBorderProperty = (isFocused = false) => {
    if (label) return 'none';
    return entity.error
      ? '1px solid #B42318'
      : `1px solid ${isFocused ? '#1669F7' : '#D0D5DD'}`;
  };

  return (
    <Box display={'flex'} width={'100%'} flexDirection={'column'}>
      <TextField
        label={label}
        InputProps={{
          ...inputProps,
          sx: {
            padding: '0px',
            '& .MuiFilledInput-input': {
              padding: insideTableLayout ? '4px 12px' : '10px 14px',
            },
            '& .MuiOutlinedInput-input': {
              padding: insideTableLayout ? '4px 12px' : '8.5px 14px',
            },
            border: getBorderProperty(),
            backgroundColor: 'white',
            borderRadius: insideTableLayout ? '4px' : '8px',
            '&.Mui-focused': {
              border: getBorderProperty(true),
              backgroundColor: 'white',
            },
            fontSize: insideTableLayout ? '14px' : '16px',
            '& legend': {
              width: '82px',
            },
          },
        }}
        onKeyDown={(e) => {
          if (detectUndoRedoKeyPress(e)) {
            e.preventDefault();
          }
        }}
        InputLabelProps={{ shrink: true, sx: { fontSize: '12px', top: '2px' } }}
        multiline={multiline}
        autoFocus={autoFocus}
        placeholder={placeholder}
        type={'text'}
        variant={label ? 'outlined' : 'filled'}
        value={entity.normalizedInputValue ?? ''}
        size={'small'}
        error={!!entity.error}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={entity.error}
        FormHelperTextProps={{
          error: !!entity.error,
          sx: {
            color: '#B42318 !important',
            ml: '0px !important',
            textWrap: 'balance',
            fontSize: insideTableLayout ? '10px' : '12px',
            lineHeight: insideTableLayout ? '12px' : 'auto',
          },
        }}
        {...extraProps}
        sx={{
          width,
          bgcolor: color,
        }}
      />
    </Box>
  );
};

export default memo(NormalizedTextField);
