import { Box } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { ReactComponent as JsonIcon } from '../../../../static/icons/json.svg';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface GenerateOutputContentProps {
  onSubmit: (isNextClicked: boolean) => void;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

const GenerateOutputContent: FC<GenerateOutputContentProps> = ({
  onSubmit,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      onSubmit(true);
    }
  }, [moveToStep]);

  return (
    <main>
      <form
        id={formId}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(true);
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'12px'}
          marginY={'32px'}
          paddingLeft={'19px'}
          justifyContent={'space-between'}
        >
          <Box>
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
              sx={{
                marginBottom: '6px',
              }}
            >
              Output format
            </OrbyTypography>
            <OrbyTextField
              placeholder='Output format'
              value='JSON file'
              name='jsonfile'
              width='448px'
              disabled
              startAdornment={<JsonIcon height={'20px'} />}
            />
          </Box>
          <OrbyTypography size='xs'>
            Prediction results in JSON format will be stored in the
            “OrbyAI_System_Predictions” folder
          </OrbyTypography>
        </Box>
      </form>
    </main>
  );
};

export default memo(GenerateOutputContent);
