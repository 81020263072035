import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Divider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Check, Circle } from '@mui/icons-material';
import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridSearchIcon } from '@mui/x-data-grid';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { Filter, ELLIPSIS_STYLE } from '../../utils/constants';
import _ from 'lodash';
import { useFetchWorkflowFilters } from '../../hooks/useFetchWorkflowFilters';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { addAlpha } from '../../utils/helpers';

interface Props {
  selectedWorkflows: string[];
  setSelectedWorkflows: (x: string[]) => void;
}

const headerTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: '8px',
            '&::placeholder': {
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 400,
              color: 'B6B6B6',
            },
          },
        },
      },
    },
  },
});

export const defaultSelectedOption = 'All workflows';

const WorkflowFilterSelect: FC<Props> = ({
  selectedWorkflows,
  setSelectedWorkflows,
}) => {
  const dispatch = useDispatch();
  const workflowColors = useSelector(workflowColorsSelector);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  const [workflowOptions, setWorkflowOptions] = useState<Filter[]>([]);
  const [options, setOptions] = useState<Filter[]>([]);

  useEffect(() => {
    return () => setSelectedWorkflows([defaultSelectedOption]);
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (workflowFilters) {
      const workflowNames = workflowFilters.map((w) => w.label);
      dispatch(setWorkflowColorAction(workflowNames));

      const workflowOptions = workflowFilters.map((w) => ({
        value: w.value,
        label: w.label,
      }));

      setWorkflowOptions(workflowOptions);
      setOptions(workflowOptions);
    }
  }, [workflowFilters]);

  // Set selected option to default option if selectedOptions are empty
  useEffect(() => {
    if (!selectedWorkflows.length) {
      setSelectedWorkflows([defaultSelectedOption]);
    }
  }, [selectedWorkflows]);

  const handleWorkflowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = workflowOptions.filter(
      (opt) => opt.label.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  const handleSelectChange = (value: string) => {
    if (value) {
      const filteredSelectedOptions = selectedWorkflows.filter(
        (o) => ![value, defaultSelectedOption].includes(o),
      );
      if (selectedWorkflows.includes(value)) {
        setSelectedWorkflows(filteredSelectedOptions);
      } else {
        setSelectedWorkflows([...filteredSelectedOptions, value]);
      }
    }
  };

  // Handle "all workflows" default option selection and deselection
  const handleDefaultClick = () => {
    const filteredSelectedOptions = selectedWorkflows.filter(
      (o) =>
        o !== defaultSelectedOption &&
        !workflowOptions.some((filter) => filter.value === o),
    );
    setSelectedWorkflows(filteredSelectedOptions);
  };

  const handleRenderValue = () => {
    if (selectedWorkflows[0] === defaultSelectedOption) {
      return (
        <Box title={defaultSelectedOption}>
          {renderLabel(defaultSelectedOption)}
        </Box>
      );
    } else if (selectedWorkflows.length === 1) {
      const value = workflowFilters.find(
        (w) => w.value === selectedWorkflows[0],
      )?.label;
      if (value) {
        return <Box title={value}>{renderLabel(value)}</Box>;
      }
    } else {
      const value = `${selectedWorkflows.length} workflows selected`;
      return <Box title={value}>{renderLabel(value)}</Box>;
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <CustomTypography
        typographyType={TypographyType.Header4}
        color={isMenu ? '#101828' : '#101828'}
        weight={isMenu ? 600 : 500}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </CustomTypography>
    );
  };

  const renderMenuItem = ({
    key,
    label,
    value,
    colorCode = undefined,
    onClick,
  }: {
    key: number | string;
    label: string;
    value: string;
    colorCode?: string;
    onClick: (value: string) => void;
  }) => {
    return (
      <MenuItem
        tabIndex={0}
        key={key}
        value={value}
        onClick={() => onClick(value)}
        sx={{
          paddingY: '10px',
          paddingX: '14px',
          marginBottom: '4px',
          height: '44px',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box
            width={'80%'}
            display={'flex'}
            gap={1}
            alignItems={'center'}
            title={label}
          >
            {colorCode && (
              <Circle sx={{ fontSize: '10px', color: colorCode }} />
            )}
            {renderLabel(label, true)}
          </Box>

          {selectedWorkflows.includes(value) ? (
            <Check sx={{ color: '#344054' }} />
          ) : (
            <Box width={'24px'}></Box>
          )}
        </Box>
      </MenuItem>
    );
  };

  return (
    <Select
      multiple
      autoComplete='search'
      renderValue={handleRenderValue}
      className='override-padding'
      MenuProps={{
        autoFocus: false,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        sx: {
          '& .MuiMenu-paper': {
            maxHeight: '350px',
            width: '445px',
            overflowY: 'auto',
          },
        },
      }}
      value={selectedWorkflows}
      onClose={() => setOptions(workflowOptions)}
      sx={{ width: '225px', height: '44px', paddingTop: '4px' }}
    >
      {renderMenuItem({
        key: 'default',
        value: defaultSelectedOption,
        label: defaultSelectedOption,
        onClick: handleDefaultClick,
      })}

      <Divider />
      <Box tabIndex={-1} paddingX={'14px'} sx={{ marginY: 1, marginTop: 2 }}>
        <ThemeProvider theme={headerTheme}>
          <TextField
            autoFocus={true}
            variant='outlined'
            fullWidth
            autoComplete='search'
            type='search'
            placeholder={'Search workflow name'}
            onChange={_.debounce((e) => handleWorkflowSearch(e), 300)}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            InputProps={{
              startAdornment: (
                <Box>
                  <InputAdornment position='start'>
                    <IconButton tabIndex={-1} sx={{ width: '10px' }}>
                      <GridSearchIcon fontSize='medium' />
                    </IconButton>
                  </InputAdornment>
                </Box>
              ),
            }}
          />
        </ThemeProvider>
      </Box>
      {workflowFiltersLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={'30px'} />
        </Box>
      ) : (
        options.length > 0 &&
        options.map((opt, index) => {
          const colorCode = addAlpha(
            workflowColors[opt.label || selectedOrgInfo?.orgResourceName || ''],
            0.4,
          );
          return renderMenuItem({
            ...opt,
            key: index,
            colorCode,
            onClick: handleSelectChange,
          });
        })
      )}
    </Select>
  );
};

export default memo(WorkflowFilterSelect);
