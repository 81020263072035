import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  inputBaseClasses,
  svgIconClasses,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Element, ControlType } from 'protos/pb/v1alpha1/element';
import { ActionObject } from 'workflow-utils/src/types';
import { ReactComponent as SearchOrAddIcon } from '../../../static/icons/search-or-add.svg';
import ElementsGroup from './ElementsGroup';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';

export interface ElementsSelectorProps {
  types: ControlType[];
  actionId: string;
  text: string;
  action: ActionObject;
  open: boolean;
  processId?: string;
  traceIndices?: number[];

  onClose(): void;

  hasRecording: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '760px',
  gap: theme.spacing(1),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 'calc(100% - 16px)',
  margin: theme.spacing(0, 2),
  [`& .${inputBaseClasses.root}`]: {
    borderRadius: '24px',
    borderColor: '#0000001F',
    '&:hover': {
      borderColor: '#0000001F',
    },
  },
  [`& .${svgIconClasses.root}`]: {
    marginRight: 6,
  },
}));

const ElementsSelector: React.FC<ElementsSelectorProps> = ({
  open,
  onClose,
  actionId,
  types,
  text,
  action,
  hasRecording,
  processId,
}: ElementsSelectorProps) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  useEffect(() => {
    // TODO: Create this whole functionality
    // if (open && !workflowStore.actionableElementsDict[actionId]) {
    //   workflowStore.fetchActionableElements(actionId, types, processId);
    // }
  }, [actionId, types, open]);

  useEffect(() => {
    if (open) {
      setSearch('');
    }
  }, [open]);

  const onElementSelect = (element: Element) => {
    const updated: ActionObject = {
      description: action!.description!.replace(text, element.label!),
      action: {
        click: {
          locator: { jsonValue: JSON.stringify(element.locator) },
        },
      },
    };
    dispatch(updateAction(action, updated, processId));
    onClose();
  };

  const onAddCustomElementByText = (newText: string) => {
    const updated: ActionObject = {
      description: action!.description!.replace(text, newText),
      action: {
        click: {
          locator: { jsonValue: JSON.stringify({ text: newText }) },
        },
      },
    };
    dispatch(updateAction(action, updated, processId));
    onClose();
  };

  const onKeyDown = (key: string, newText: string) => {
    if (key === 'Enter' && newText !== '') {
      onAddCustomElementByText(newText);
    }
  };

  // TODO: Create this whole functionality
  //   const elements = workflowStore.actionableElementsDict[actionId] || [];
  const elements: any[] = [];
  return (
    <Container>
      <StyledTextField
        size='small'
        placeholder='Search or add items'
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => onKeyDown(e.key, search)}
        InputProps={{
          startAdornment: <SearchOrAddIcon />,
          // TODO: Temporary hack to allow adding custom clickable elements
          // This component should be redesigned to better support this feature
          endAdornment: search && (
            <Button
              size='small'
              onClick={() => onAddCustomElementByText(search)}
              sx={{ textTransform: 'none' }}
            >
              {' '}
              Add{' '}
            </Button>
          ),
        }}
      />
      {hasRecording && (
        <>
          <Typography mt={5} mb={4} ml={3}>
            Here are elements that are clickable
          </Typography>
          {types.map((type, index) => (
            <ElementsGroup
              key={index}
              type={type}
              elements={elements.filter(
                (e) =>
                  e.controlType === type &&
                  e.label?.toLowerCase().includes(search.toLowerCase()),
              )}
              onSelect={onElementSelect}
            />
          ))}
        </>
      )}
    </Container>
  );
};

export default ElementsSelector;
