import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { useEffect, useState } from 'react';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { ControlPoint } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectorListLoadingSelector,
  connectorListSelector,
  connectorTotalSizeSelector,
  processConnectorErrorSelector,
  updatedConnectorSelector,
} from '../../redux/selectors/connector.selectors';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import {
  deleteConnectorAction,
  listConnectorAction,
} from '../../redux/actions/connector.action';
import {
  DeleteConnectorRequest,
  ListConnectorRequest,
} from 'protos/pb/v1alpha2/connector_service';
import WorkflowContextMenu from './WorkflowContextMenu';
import { notification } from 'antd';
import { ELLIPSIS_STYLE, ROWS_PER_PAGE } from '../../utils/constants';
import CustomTableCell from '../../components/CustomTableCell';
import ConnectorDeletionModal from '../../pages/WorkflowConnector/ConnectorDeletionModal';
import {
  getCreationDetails,
  getIdFromResourceName,
} from '../../utils/WorkflowUtils';
import '../../styles/table.css';
import { Connector } from 'protos/pb/v1alpha2/connector';

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:nth-of-type(even)': {
    backgroundColor: '#FAFAFA',
  },
  '& td, & th': {
    border: 0,
  },
}));

const ConnectorsTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deletingConnector, setDeletingConnector] = useState(false);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(
    null,
  );
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const totalSize = useSelector(connectorTotalSizeSelector) ?? 0;
  const connectors = useSelector(connectorListSelector) ?? [];
  const loading = useSelector(connectorListLoadingSelector);
  const updatedConnector = useSelector(updatedConnectorSelector);
  const connectorError = useSelector(processConnectorErrorSelector);
  const pagedConnectors = connectors.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  const buildRequest = () => {
    const req: ListConnectorRequest = {};
    req.pageSize = rowsPerPage;
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    return req;
  };

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };
  const handleDelete = (deleteReason: string) => {
    const req: DeleteConnectorRequest = {
      name: selectedConnector?.name,
      deletedReason: deleteReason,
    };
    dispatch(deleteConnectorAction(req));
    setDeletingConnector(true);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
    setSelectedConnector(null);
  };

  const handleOpenModal = (c: Connector) => {
    setIsDeletionModalOpen(true);
    setSelectedConnector(c);
  };

  useEffect(() => {
    if (updatedConnector && deletingConnector) {
      openSuccess(
        'Connector ' +
          updatedConnector.displayName +
          ' is deleted successfully',
      );
      /*
        After deleting a connector, fetch an additional connector (only when complete data is not loaded) to ensure complete page filling 
        and maintain data consistency. For eg, if totalConnector = 30 and fetchedConnector = 20 
        (4 pages with 5 rows each), after deletion fetchedConnector = 19 (20 - 1), fetching an additional connector (21st before deletion, 20th after deletion)
        ensures consistent pagination (i.e 4 pages with 5 rows each even after deletion), preventing incomplete data display for subsequent pages.
      */
      // TODO: change this logic when batch deletion is supported
      if (totalSize > connectors.length) {
        const req = buildRequest();
        req.pageNumber = connectors.length + 1;
        req.pageSize = 1;
        dispatch(listConnectorAction(req));
      }
    }
  }, [updatedConnector]);

  useEffect(() => {
    if (connectorError && deletingConnector) {
      openError(connectorError);
    }
  }, [connectorError]);

  useEffect(() => {
    setPage(0);
    const req = buildRequest();
    // ADDING TRUE TO REFRESH THE LIST INSTEAD OF APPENDING WORKFLOWS
    dispatch(listConnectorAction(req, true));
    setDeletingConnector(false);
  }, [selectedOrgInfo]);

  return (
    <Box paddingY={'75px'}>
      {contextHolder}
      <Box display={'flex'} justifyContent={'space-between'}>
        <CustomTypography
          component={'h1'}
          typographyType={TypographyType.Header2}
        >
          Connectors
        </CustomTypography>
        <IconButton
          sx={{ color: '#1669F7', borderRadius: '6px' }}
          aria-label={'Create Connector'}
          onClick={() => {
            navigate('connector/create');
          }}
        >
          <ControlPoint fontSize='small' sx={{ marginRight: '5px' }} />
          <CustomTypography color='span' weight={500}>
            Add Connector
          </CustomTypography>
        </IconButton>
      </Box>

      <CustomTypography
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        typographyType={TypographyType.MediumPara}
      >
        You and other admins can configure the connectors
      </CustomTypography>
      {loading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          height={'20%'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          sx={{ marginTop: '30px' }}
          className={'table-header-style'}
        >
          <Table id={'connectors-table'} sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                <CustomTableCell title='Connectors'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Connectors
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Description'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Description
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Created by'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Created by
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Source workflow'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Source workflow
                  </CustomTypography>
                </CustomTableCell>
                <CustomTableCell title='Destination'>
                  <CustomTypography
                    component={'span'}
                    typographyType={TypographyType.Header4}
                    color='#6B6B6B'
                  >
                    Destination
                  </CustomTypography>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedConnectors.map((c, i) => (
                <StyledTableRow
                  key={c.name}
                  role='row'
                  tabIndex={0}
                  sx={{
                    cursor: 'pointer',
                    ':hover': { backgroundColor: '#1669F74D' },
                    ':focus': { backgroundColor: '#1669F74D' },
                  }}
                >
                  <CustomTableCell title={c.displayName}>
                    <CustomTypography
                      component={'span'}
                      lineHeight='18px'
                      color='#000000'
                      typographyType={TypographyType.Header5}
                    >
                      {c.displayName}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell title={c.description}>
                    <CustomTypography component={'span'} color='#6B6B6B'>
                      {c.description}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell
                    title={c.creatorEmail ? getCreationDetails(c) : undefined}
                  >
                    <CustomTypography component={'span'} color='#6B6B6B'>
                      {c.creatorEmail}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell title={c.sourceWorkflow?.workflowName}>
                    <CustomTypography
                      typographyType={TypographyType.Label}
                      component={'span'}
                    >
                      {c.sourceWorkflow?.workflowName}
                    </CustomTypography>
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{ overflow: 'hidden' }}
                      title={c.destinationWorkflow?.workflowName}
                    >
                      <CustomTypography
                        typographyType={TypographyType.Label}
                        sx={{ ...ELLIPSIS_STYLE, maxWidth: '100%' }}
                      >
                        {c.destinationWorkflow?.workflowName}
                      </CustomTypography>
                    </Box>
                    <WorkflowContextMenu
                      ariaLabel='Show actions for connector'
                      id={(c.name as string) + i}
                      onUpdate={() => {
                        const id = getIdFromResourceName(c.name as string);
                        if (id) {
                          navigate(`/workflow/connector/${id}/update`);
                        } else {
                          openError(Error('Something went wrong'));
                        }
                      }}
                      onDelete={() => handleOpenModal(c)}
                    />
                  </CustomTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {!pagedConnectors.length && (
            <CustomTypography
              sx={{
                textAlign: 'center',
                textJustify: 'center',
                color: '#475467',
                marginTop: '40px',
              }}
            >
              There are no connectors available
            </CustomTypography>
          )}
        </TableContainer>
      )}
      <CustomPagination
        ariaLabel={'Please select template rows per page'}
        rowsPerPage={rowsPerPage}
        totalSize={totalSize}
        page={page}
        rows={ROWS_PER_PAGE}
        justifyContent={'end'}
        onRowsPerPageChange={(rows) => {
          setPage(0);
          setRowsPerPage(rows);
          const req = buildRequest();
          req.pageSize = rows;
          // Refresh is needed when rows per page is changes to fetch fresh data
          dispatch(listConnectorAction(req, true /* Refresh */));
        }}
        isShowSelectFilter={totalSize > rowsPerPage}
        onPageChange={(p) => {
          setPage(p);
          if (p >= page && connectors.length <= p * rowsPerPage) {
            const req = buildRequest();
            req.pageNumber = p + 1;
            req.pageSize = rowsPerPage;
            dispatch(listConnectorAction(req));
          }
        }}
      />
      <ConnectorDeletionModal
        open={isDeletionModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default React.memo(ConnectorsTable);
