import { MoreVert } from '@mui/icons-material';
import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import CustomTypography from '../../../../../../../components/CustomTypography';
import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedEntityIdsForAnnotationAction,
  updateEntityInfoForTableAnnotationAction,
  setAddLocationAction,
} from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';
import { getDefaultTextSegment } from '../../../../../../../utils/ReviewTaskUtils';

interface Props {
  cell: EntityInfo;
  onCopy: () => void;
  onPaste: () => void;
  enablePaste: boolean;
  openTextPopover?: (entity: EntityInfo) => void;
  closeTextPopover?: () => void;
  showSmall?: boolean;
}

const TableModalChildMenu: FC<Props> = ({
  cell,
  onCopy,
  onPaste,
  enablePaste,
  openTextPopover,
  closeTextPopover,
  showSmall = true,
}) => {
  const dispatch = useDispatch();
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.getElementById(`fade-menu-icon-${cell.id}`)?.focus();
  };

  const updateEntityToNotInDoc = (entityInfo: EntityInfo) => {
    const newEntityInfo: EntityInfo = {
      ...entityInfo,
      textSegments: getDefaultTextSegment(entityInfo.id),
      entityText: '',
      normalizedInputValue: '',
      isInDoc: false,
    };
    dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo, false));
    handleClose();
  };

  return (
    <>
      {allowEditingTask && (
        <IconButton
          id={`fade-menu-icon-${cell.id}`}
          title=''
          onMouseDown={handleClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              handleClick(e as any);
            }
          }}
          sx={{
            width: showSmall ? '20px' : '2rem',
            height: showSmall ? '20px' : '2rem',
            padding: '0px',
            zIndex: 2,
          }}
        >
          <MoreVert
            sx={{ width: showSmall ? '18px' : '1em', color: '#000000' }}
          />
        </IconButton>
      )}
      {open && (
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          onFocus={(e) => {
            e.stopPropagation();
            e.currentTarget.focus();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          open={open}
          onClose={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            handleClose();
          }}
          TransitionComponent={Fade}
          sx={{
            '& .MuiPopover-paper': {
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <MenuItem
            id='fade-menu-item'
            onFocus={(e) => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onClick={(e) => {
              e.stopPropagation();
              updateEntityToNotInDoc(cell);
              handleClose();
            }}
          >
            <CustomTypography sx={{ color: '#000000' }}>
              Clear value
            </CustomTypography>
          </MenuItem>
          <MenuItem
            id='fade-menu-item'
            onFocus={(e) => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSelectedEntityIdsForAnnotationAction([cell.id]));
              closeTextPopover?.();
              dispatch(setAddLocationAction(true));
              handleClose();
            }}
          >
            <CustomTypography sx={{ color: '#000000' }}>
              Highlight in doc
            </CustomTypography>
          </MenuItem>
          <MenuItem
            id='fade-menu-item'
            onFocus={(e) => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onClick={(e) => {
              e.stopPropagation();
              onCopy();
              handleClose();
            }}
          >
            <CustomTypography sx={{ color: '#000000' }}>Copy</CustomTypography>
          </MenuItem>
          {enablePaste && (
            <MenuItem
              id='fade-menu-item'
              onFocus={(e) => {
                e.stopPropagation();
                e.currentTarget.focus();
              }}
              onClick={(e) => {
                e.stopPropagation();
                onPaste();
                handleClose();
              }}
            >
              <CustomTypography sx={{ color: '#000000' }}>
                Paste
              </CustomTypography>
            </MenuItem>
          )}
          {openTextPopover && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                openTextPopover(cell);
                handleClose();
              }}
            >
              <CustomTypography sx={{ color: '#000000' }}>
                Edit value
              </CustomTypography>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

export default memo(TableModalChildMenu);
