import React from 'react';
import CustomModal from '../../../../components/CustomModal';
import CustomTypography from '../../../../components/CustomTypography';

interface Props {
  open: boolean;
  onContinue: () => void;
  onDiscard: () => void;
}

const UnsavedChangesConfirmModal: React.FC<Props> = ({
  open,
  onContinue,
  onDiscard,
}) => {
  return (
    <CustomModal
      open={open}
      heading={'Unsaved changes'}
      containerSx={{
        width: '400px',
        pt: '8px',
      }}
      content={
        <CustomTypography
          sx={{
            color: '#101828',
            fontFamily: 'Inter',
          }}
        >
          {`You have unsaved changes on the annotation.\nThey will be discarded if
          you leave.`}
        </CustomTypography>
      }
      handleClose={onContinue}
      primaryLabel={'Continue editing'}
      secondaryLabel={'Discard and leave'}
      onPrimaryClick={onContinue}
      onSecondaryClick={onDiscard}
    />
  );
};

export default React.memo(UnsavedChangesConfirmModal);
