/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import {
  ClassifyClassifyPreset,
  classifyClassifyPresetFromJSON,
  classifyClassifyPresetToJSON,
  ProcessSmartActionsRequest,
  ProcessSmartActionsResponse,
} from "./actionprocessing";
import { ElementLocator, LowLevelActionType, lowLevelActionTypeFromJSON, lowLevelActionTypeToJSON } from "./element";

export const protobufPackage = "pb.v1alpha1";

export enum ActionBlockKind {
  ACTION_KIND_UNSPECIFIED = 0,
  PRESET = 1,
  USER_CREATED = 2,
  UNRECOGNIZED = -1,
}

export function actionBlockKindFromJSON(object: any): ActionBlockKind {
  switch (object) {
    case 0:
    case "ACTION_KIND_UNSPECIFIED":
      return ActionBlockKind.ACTION_KIND_UNSPECIFIED;
    case 1:
    case "PRESET":
      return ActionBlockKind.PRESET;
    case 2:
    case "USER_CREATED":
      return ActionBlockKind.USER_CREATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionBlockKind.UNRECOGNIZED;
  }
}

export function actionBlockKindToJSON(object: ActionBlockKind): string {
  switch (object) {
    case ActionBlockKind.ACTION_KIND_UNSPECIFIED:
      return "ACTION_KIND_UNSPECIFIED";
    case ActionBlockKind.PRESET:
      return "PRESET";
    case ActionBlockKind.USER_CREATED:
      return "USER_CREATED";
    case ActionBlockKind.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ContextValue {
  UNSPECIFIED = 0,
  LOOP_INDEX = 1,
  UNRECOGNIZED = -1,
}

export function contextValueFromJSON(object: any): ContextValue {
  switch (object) {
    case 0:
    case "CONTEXT_VALUE_UNSPECIFIED":
      return ContextValue.UNSPECIFIED;
    case 1:
    case "LOOP_INDEX":
      return ContextValue.LOOP_INDEX;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ContextValue.UNRECOGNIZED;
  }
}

export function contextValueToJSON(object: ContextValue): string {
  switch (object) {
    case ContextValue.UNSPECIFIED:
      return "CONTEXT_VALUE_UNSPECIFIED";
    case ContextValue.LOOP_INDEX:
      return "LOOP_INDEX";
    case ContextValue.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ClickType {
  /** UNSPECIFIED - For single click on the left mouse button, use the default UNSPECIFIED type. */
  UNSPECIFIED = 0,
  DOUBLE_CLICK = 1,
  RIGHT_CLICK = 2,
  UNRECOGNIZED = -1,
}

export function clickTypeFromJSON(object: any): ClickType {
  switch (object) {
    case 0:
    case "CLICK_TYPE_UNSPECIFIED":
      return ClickType.UNSPECIFIED;
    case 1:
    case "DOUBLE_CLICK":
      return ClickType.DOUBLE_CLICK;
    case 2:
    case "RIGHT_CLICK":
      return ClickType.RIGHT_CLICK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClickType.UNRECOGNIZED;
  }
}

export function clickTypeToJSON(object: ClickType): string {
  switch (object) {
    case ClickType.UNSPECIFIED:
      return "CLICK_TYPE_UNSPECIFIED";
    case ClickType.DOUBLE_CLICK:
      return "DOUBLE_CLICK";
    case ClickType.RIGHT_CLICK:
      return "RIGHT_CLICK";
    case ClickType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Simplified representation of a DOM / web page.
 * For example, <p>Hello <a>world</a></p> will be represented as
 * elements {
 *   tag {
 *     name: "p"
 *     children { text: "Hello " }
 *     children { tag { name:"a" children { text:"world" } } }
 *   }
 * }
 */
export interface PageContent {
  elements?: PageContentElement[] | undefined;
}

export interface PageContentElement {
  /** Element with a tag. */
  tag?:
    | PageContentElementTag
    | undefined;
  /** Content of a text element. */
  text?: string | undefined;
}

export interface PageContentElementTag {
  /** Id assigned by Orbot for identifying elements. */
  orbyId?:
    | string
    | undefined;
  /** Type of the element. For example, a, button, etc. */
  name?: string | undefined;
  children?:
    | PageContentElement[]
    | undefined;
  /** HTML attributes selected by the FE. For example, "aria-label" and "placeholder". */
  attributes?: { [key: string]: string } | undefined;
}

export interface PageContentElementTagAttributesEntry {
  key: string;
  value: string;
}

/**
 * A snapshot of the UI.
 * Next ID: 6
 */
export interface UiState {
  /** Screenshot image of the viewport. */
  viewportScreenshot?:
    | RecordedFile
    | undefined;
  /**
   * Pixel width of the viewport. Both viewport_width and viewport_height
   * should correspond to the bounding box in element. However, they
   * might not be consistent with the screenshot due to device pixel ratio.
   */
  viewportWidth?:
    | number
    | undefined;
  /** Pixel height of the viewport. See comment above for more info. */
  viewportHeight?:
    | number
    | undefined;
  /** Binary proto of the root element of the UI. */
  rootElement?:
    | RecordedFile
    | undefined;
  /** URL of the page. */
  url?: string | undefined;
}

/** A group of actions that perform some task collectively. */
export interface ActionBlock {
  actions?: Action[] | undefined;
  kind?: ActionBlockKind | undefined;
}

export interface PrerequisiteAction {
  action?: Action | undefined;
  type?: PrerequisiteActionPrerequisiteType | undefined;
}

export enum PrerequisiteActionPrerequisiteType {
  UNSPECIFIED = 0,
  /** SMART_TEXT - A JsFunctionAction for generating text from user's NL instructions. */
  SMART_TEXT = 1,
  /**
   * SMART_BOOLEAN - An action for generating boolean-like output from user's NL instructions,
   * which is mostly used for ConditionAction. It can be a JsFunctionAction
   * that generate a boolean value, or a GetElementAction which can be used
   * directly by the ConditionAction.
   */
  SMART_BOOLEAN = 3,
  /** DYNAMIC_LOCATOR - A JsFunctionAction for generating text from user's NL instructions. */
  DYNAMIC_LOCATOR = 2,
  UNRECOGNIZED = -1,
}

export function prerequisiteActionPrerequisiteTypeFromJSON(object: any): PrerequisiteActionPrerequisiteType {
  switch (object) {
    case 0:
    case "PREREQUISITE_TYPE_UNSPECIFIED":
      return PrerequisiteActionPrerequisiteType.UNSPECIFIED;
    case 1:
    case "SMART_TEXT":
      return PrerequisiteActionPrerequisiteType.SMART_TEXT;
    case 3:
    case "SMART_BOOLEAN":
      return PrerequisiteActionPrerequisiteType.SMART_BOOLEAN;
    case 2:
    case "DYNAMIC_LOCATOR":
      return PrerequisiteActionPrerequisiteType.DYNAMIC_LOCATOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PrerequisiteActionPrerequisiteType.UNRECOGNIZED;
  }
}

export function prerequisiteActionPrerequisiteTypeToJSON(object: PrerequisiteActionPrerequisiteType): string {
  switch (object) {
    case PrerequisiteActionPrerequisiteType.UNSPECIFIED:
      return "PREREQUISITE_TYPE_UNSPECIFIED";
    case PrerequisiteActionPrerequisiteType.SMART_TEXT:
      return "SMART_TEXT";
    case PrerequisiteActionPrerequisiteType.SMART_BOOLEAN:
      return "SMART_BOOLEAN";
    case PrerequisiteActionPrerequisiteType.DYNAMIC_LOCATOR:
      return "DYNAMIC_LOCATOR";
    case PrerequisiteActionPrerequisiteType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Basic action in Orbot workflow. Each action can be:
 * 1. a leaf action that can be executed as the smallest unit, such as click.
 * 2. a control flow action such as loop/condition that can contain children action.
 * 3. a group of actions, either prepackaged by Orby or user configured.
 * Next ID: 39
 */
export interface Action {
  /** action id used to reference between steps for parameter passing. */
  id?:
    | string
    | undefined;
  /** Generated description that is shown to the user. */
  description?:
    | string
    | undefined;
  /**
   * Prerequisite actions that need to be performed before the current action.
   * This is usually for JsFunctionAction that generate locators or perform data
   * manipulation. Prerequisite actions are hidden from the user for better
   * readability.
   */
  prerequisites?: PrerequisiteAction[] | undefined;
  block?: ActionBlock | undefined;
  goto?:
    | GotoAction
    | undefined;
  /** (FM) synonmous with CLICK */
  click?: ClickAction | undefined;
  getForm?: GetFormAction | undefined;
  fillForm?: FillFormAction | undefined;
  extractFields?: ExtractFieldsAction | undefined;
  jsFunction?: JsFunctionAction | undefined;
  validate?: ValidateAction | undefined;
  condition?: ConditionAction | undefined;
  foreach?: ForeachAction | undefined;
  getList?: GetListAction | undefined;
  getElement?: GetElementAction | undefined;
  flagKeywords?: FlagKeywordsAction | undefined;
  detectDuplicateLineItems?: DetectDuplicateLineItemsAction | undefined;
  createTask?: CreateTaskAction | undefined;
  reconcileItems?:
    | ReconcileItemsAction
    | undefined;
  /** (FM) synonmous with HOVER */
  hover?: HoverAction | undefined;
  exit?:
    | ExitAction
    | undefined;
  /** (FM) synonmous with TYPE and SELECT */
  setValue?: SetValueAction | undefined;
  customSmartAction?: CustomSmartAction | undefined;
  getDocument?:
    | GetDocumentAction
    | undefined;
  /** (FM) synonmous with SCROLL */
  scrollAction?: ScrollAction | undefined;
  generateText?: GenerateTextAction | undefined;
  classify?:
    | ClassifyAction
    | undefined;
  /**
   * which tab shall we perform the action. tabIndex is generated and assigned
   * during recording and we map them to the dynamic tabId during execution.
   */
  tabIndex?:
    | number
    | undefined;
  /**
   * The page snapshot/screenshot that are picked during the inference.
   * Deprecated: use before_state.root_element instead.
   *
   * @deprecated
   */
  snapshot?:
    | RecordedFile
    | undefined;
  /**
   * Screenshot captured before this action takes place.
   * Deprecated: use before_state.viewport_screenshot instead.
   *
   * @deprecated
   */
  screenshot?:
    | RecordedFile
    | undefined;
  /** Snapshot of the UI taken before the action. */
  beforeState?:
    | UiState
    | undefined;
  /**
   * Verify that the execution of the action is successful.
   * Currently this is only used in tests in the Replay format.
   */
  verification?:
    | ActionVerification
    | undefined;
  /** (FM) low-level action type; to be compatible with the FM crawler definition */
  lowLevelActionType?:
    | LowLevelActionType
    | undefined;
  /**
   * These are the IDs for the elements this action operates on.
   * Each ID is unique and corresponds to one dom element found in snapshot.
   */
  elementIds?: string[] | undefined;
}

export interface ActionVerification {
  outgoingRequests?: ActionVerificationOutgoingRequest[] | undefined;
  elementAssertions?:
    | ActionVerificationElementAssertion[]
    | undefined;
  /**
   * JavaScript code to be evaluated in any one of the EvaluateScriptContext(s) to check if the execution
   * is successful. Only the return value true would be treated as success.
   */
  evaluateScript?: ActionVerificationEvaluateScript | undefined;
}

export enum ActionVerificationOutgoingRequestMethod {
  GET = 0,
  POST = 1,
  PUT = 2,
  DELETE = 3,
  UNRECOGNIZED = -1,
}

export function actionVerificationOutgoingRequestMethodFromJSON(object: any): ActionVerificationOutgoingRequestMethod {
  switch (object) {
    case 0:
    case "GET":
      return ActionVerificationOutgoingRequestMethod.GET;
    case 1:
    case "POST":
      return ActionVerificationOutgoingRequestMethod.POST;
    case 2:
    case "PUT":
      return ActionVerificationOutgoingRequestMethod.PUT;
    case 3:
    case "DELETE":
      return ActionVerificationOutgoingRequestMethod.DELETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionVerificationOutgoingRequestMethod.UNRECOGNIZED;
  }
}

export function actionVerificationOutgoingRequestMethodToJSON(object: ActionVerificationOutgoingRequestMethod): string {
  switch (object) {
    case ActionVerificationOutgoingRequestMethod.GET:
      return "GET";
    case ActionVerificationOutgoingRequestMethod.POST:
      return "POST";
    case ActionVerificationOutgoingRequestMethod.PUT:
      return "PUT";
    case ActionVerificationOutgoingRequestMethod.DELETE:
      return "DELETE";
    case ActionVerificationOutgoingRequestMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ActionVerificationEvaluateScriptContext {
  UNSPECIFIED = 0,
  MAIN_WORLD = 1,
  CONTENT_SCRIPT = 2,
  SERVICE_WORKER = 3,
  UNRECOGNIZED = -1,
}

export function actionVerificationEvaluateScriptContextFromJSON(object: any): ActionVerificationEvaluateScriptContext {
  switch (object) {
    case 0:
    case "EVALUATE_SCRIPT_CONTEXT_UNSPECIFIED":
      return ActionVerificationEvaluateScriptContext.UNSPECIFIED;
    case 1:
    case "MAIN_WORLD":
      return ActionVerificationEvaluateScriptContext.MAIN_WORLD;
    case 2:
    case "CONTENT_SCRIPT":
      return ActionVerificationEvaluateScriptContext.CONTENT_SCRIPT;
    case 3:
    case "SERVICE_WORKER":
      return ActionVerificationEvaluateScriptContext.SERVICE_WORKER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionVerificationEvaluateScriptContext.UNRECOGNIZED;
  }
}

export function actionVerificationEvaluateScriptContextToJSON(object: ActionVerificationEvaluateScriptContext): string {
  switch (object) {
    case ActionVerificationEvaluateScriptContext.UNSPECIFIED:
      return "EVALUATE_SCRIPT_CONTEXT_UNSPECIFIED";
    case ActionVerificationEvaluateScriptContext.MAIN_WORLD:
      return "MAIN_WORLD";
    case ActionVerificationEvaluateScriptContext.CONTENT_SCRIPT:
      return "CONTENT_SCRIPT";
    case ActionVerificationEvaluateScriptContext.SERVICE_WORKER:
      return "SERVICE_WORKER";
    case ActionVerificationEvaluateScriptContext.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionVerificationOutgoingRequestBody {
  json?: string | undefined;
  raw?: Uint8Array | undefined;
}

export interface ActionVerificationOutgoingRequest {
  url?: string | undefined;
  method?: ActionVerificationOutgoingRequestMethod | undefined;
  body?: ActionVerificationOutgoingRequestBody | undefined;
}

export interface ActionVerificationElementAssertion {
  locator?: ElementLocator | undefined;
  exists?: boolean | undefined;
  visible?: boolean | undefined;
}

export interface ActionVerificationEvaluateScript {
  script?:
    | string
    | undefined;
  /** If no context is specified, the script would be run in the MAIN_WORLD */
  context?: ActionVerificationEvaluateScriptContext | undefined;
}

/**
 * ActionGroup is a group of actions that are executed together.
 * In Dashboard, we also visualize the action group as a single step.
 * One ActionGroup can have multiple PreparedActions, but 2 control flow actions (foreach, condition) cannot be in the same level and same group.
 * The reason is that it's hard to visualize foreach and condition in the same step.
 * But they can be nested. e.g foreach -> condition, or condition -> foreach, or foreach -> foreach (our executor doesn't support yet), or condition -> condition, etc.
 * Deprecated: use Action instead
 */
export interface ActionGroup {
  /** Generated description that is shown to the user. */
  description?: string | undefined;
  preparedActions?:
    | PreparedAction[]
    | undefined;
  /** The page snapshot/screenshot that are picked during the inference. */
  snapshot?: RecordedFile | undefined;
  screenshot?:
    | RecordedFile
    | undefined;
  /** uuid for the action group, used to reference between high-level steps. */
  uuid?: string | undefined;
}

export interface PartialReferenceValue {
  referenceValue?: string | undefined;
  referenceValueKey?: string | undefined;
}

/**
 * An action parameter value can be defined in two ways:
 * 1. static param that is defined during workflow definition, such as ExtractField
 *    with firstName and lastName.
 * 2. dynamic param from prior actions. For example, a FillFormAction relies
 *    on data from a prior ExtractFieldAction execution.
 */
export interface ActionParamValue {
  /**
   * json formatted parameter value that will be passed into high-level-actions.
   * the value is fixed when we define the workflow.
   */
  jsonValue?:
    | string
    | undefined;
  /**
   * parameter value that refers to the output from another action.
   * it should always be an ID of a prior action in the same workflow.
   */
  referenceValue?:
    | string
    | undefined;
  /**
   * refers to the environment variable set by the task discovery system.
   * the value is available when we start the workflow.
   */
  envValue?:
    | string
    | undefined;
  /** refers to runtime variable, such as the loop index */
  contextValue?:
    | ContextValue
    | undefined;
  /** refers to a secret ID with value stored in go/secret-manager. */
  secretValue?:
    | string
    | undefined;
  /** reference value with a key path, used to extract a specific field */
  partialReferenceValue?: PartialReferenceValue | undefined;
}

/**
 * PreparedActions are generated after recording.
 *
 * The naming is similar to SQL prepared statement, which is a feature used to
 * execute the same (or similar) SQL statements repeatedly with high efficiency.
 * Here we preprocess the recorded actions into high-level actions in order for
 * it to be executed more efficiently and reliably.
 * (FM) this is a superset of the FM atomic action
 * Deprecated: use Action instead
 */
export interface PreparedAction {
  /** uuid for the action, used to reference between steps for parameter passing. */
  uuid?:
    | string
    | undefined;
  /**
   * The parameters that is used to perform the action.
   * Deprecated: set parameter in each action instead
   *
   * @deprecated
   */
  params?:
    | ActionParamValue[]
    | undefined;
  /**
   * User might want to stop and review certain actions before proceeding, e.g.
   * clicking the Submit button for a form. This usually happens for operations
   * that has side effects such as create/update/delete.
   */
  requiresReview?:
    | boolean
    | undefined;
  /**
   * which tab shall we perform the action. tabIndex is generated and assigned
   * during recording and we map them to the dynamic tabId during execution.
   */
  tabIndex?: number | undefined;
  gotoAction?: GotoAction | undefined;
  clickAction?: ClickAction | undefined;
  getFormAction?: GetFormAction | undefined;
  fillFormAction?: FillFormAction | undefined;
  extractFieldsAction?: ExtractFieldsAction | undefined;
  jsFunctionAction?: JsFunctionAction | undefined;
  validateAction?: ValidateAction | undefined;
  conditionAction?: ConditionAction | undefined;
  foreachAction?: ForeachAction | undefined;
  getListAction?: GetListAction | undefined;
  getElementAction?: GetElementAction | undefined;
  flagKeywordsAction?: FlagKeywordsAction | undefined;
  detectDuplicateLineItemsAction?: DetectDuplicateLineItemsAction | undefined;
  createTaskAction?: CreateTaskAction | undefined;
  reconcileItemsAction?: ReconcileItemsAction | undefined;
  hoverAction?: HoverAction | undefined;
  exitAction?: ExitAction | undefined;
  setValueAction?: SetValueAction | undefined;
  customSmartAction?: CustomSmartAction | undefined;
  getDocumentAction?: GetDocumentAction | undefined;
  generateTextAction?: GenerateTextAction | undefined;
  classifyAction?: ClassifyAction | undefined;
}

/** File generated during action recording */
export interface RecordedFile {
  id?:
    | string
    | undefined;
  /** short-lived URL (~15 minute) to access the file */
  url?: string | undefined;
}

/**
 * Actions generated when we execute the workflow.
 * Compared to RecordedAction, this would be initiated by Orbot with a PreparedAction.
 * which might be mapped to multiple DOM action, and has execution context like input/output.
 */
export interface ExecutedAction {
  /** the PreparedAction that was executed. */
  preparedActionUuid?:
    | string
    | undefined;
  /** input/output of the action, where each value is serialized in JSON format. */
  paramValues?:
    | string[]
    | undefined;
  /**
   * the actual output of an action, there is no necessary bond between output_value and predicted_output_value.
   * e.g reconcile action, output_value is a boolean indicating whether there is fallout or not.
   */
  outputValue?:
    | string
    | undefined;
  /**
   * the original predicted value for SmartAction requests in serialized JSON format.
   * duplicated from smart_action_response
   *
   * @deprecated
   */
  predictedOutputValue?:
    | string
    | undefined;
  /** Whether we have triggered human review for this action during execution. */
  humanReviewTriggered?:
    | boolean
    | undefined;
  /** human review time in milliseconds computed as the total time that the task tab is active for human review. */
  humanReviewTimeInMs?:
    | number
    | undefined;
  /** for billing purpose */
  numProcessedPages?:
    | number
    | undefined;
  /** Screenshot of the page before the action is executed. */
  screenshot?:
    | RecordedFile
    | undefined;
  /** HTML representation of the page before the action takes place. */
  snapshot?:
    | RecordedFile
    | undefined;
  /** Persist the request for auditing and debugging. */
  smartActionRequest?: ProcessSmartActionsRequest | undefined;
  smartActionResponse?:
    | ProcessSmartActionsResponse
    | undefined;
  /**
   * if human overrides a value during review, corrected_smart_action_response would store the
   * overridden value. If the value is not overridden, corrected_smart_action_response would be empty.
   */
  correctedSmartActionResponse?: ProcessSmartActionsResponse | undefined;
  startTime?: Date | undefined;
  endTime?: Date | undefined;
}

export interface GotoAction {
  url?: ActionParamValue | undefined;
}

export interface ClickAction {
  /**
   * Deprecated: use locator instead.
   *
   * @deprecated
   */
  elementLocator?:
    | ElementLocator
    | undefined;
  /** deprecated: use ClickType.RIGHT_CLICK instead. */
  isDoubleClick?: boolean | undefined;
  type?: ClickType | undefined;
  locator?: ActionParamValue | undefined;
}

export interface HoverAction {
  /**
   * Deprecated: use locator instead.
   *
   * @deprecated
   */
  elementLocator?: ElementLocator | undefined;
  locator?: ActionParamValue | undefined;
}

/**
 * Get an element on the HTML page, which can be used:
 * 1. check if an element (such as a button) exists;
 * 2. get the content of the element, such as page/modal titles.
 *
 * The output can be used to perform different branches of actions. For example,
 * * perform receipt validation only if the Receipt Image tab is present in Concur
 * * route receipt to additional approvals, if the modal title is "Approval Workflow for Report"
 *
 * There are a few read/extract actions, each with its own focs:
 * * ExtractEntity extract semantic entities from the page, which usually requires LLM understanding.
 * * GetForm reads structured field usually from HTML forms.
 * * GetElement reads a single HTML elements that are relatively stable, such as buttons.
 * * GetList reads list of similar elements.
 */
export interface GetElementAction {
  /**
   * Deprecated: use element_locator instead.
   *
   * @deprecated
   */
  locator?: ElementLocator | undefined;
  elementLocator?: ActionParamValue | undefined;
}

/**
 * Get form schema as well as current form value.
 *
 * Form schema is based on JSON schema, such as:
 * {
 *   "type": "object"
 *   "properties": {
 *     "givenName": {
 *       "type": "string"
 *     }
 *   }
 * }
 *
 * From value is a JSON object, such as:
 * {
 *   "givenName": "John"
 * }
 */
export interface GetFormAction {
  formLocator?: ElementLocator | undefined;
}

/**
 * Fill form with the given values.
 * Deprecated: use SetValueAction instead
 */
export interface FillFormAction {
  formLocator?:
    | ElementLocator
    | undefined;
  /** Default to false. If true, press Enter key after filling the form. */
  pressEnter?: boolean | undefined;
}

/**
 * Compared to FillFormAction that can handle multiple form fields, SetValueAction
 * only set value for a single form field
 * (FM) synonmous with TYPE and SELECT
 */
export interface SetValueAction {
  fieldLocator?: ActionParamValue | undefined;
  fieldValue?:
    | ActionParamValue
    | undefined;
  /** Default to false. If true, press Enter key after filling the form. */
  pressEnter?: boolean | undefined;
}

/**
 * Extracts fields from one or more documents (HTML, PDF, images etc) with the given schema.
 * This action usually requires API call to the Orby server for the document extraction.
 */
export interface ExtractFieldsAction {
  document?:
    | ActionParamValue
    | undefined;
  /** list of fields to be extracted */
  fields?: string[] | undefined;
}

/** Each validate rule can output an string message if validation fails. */
export interface ValidateAction {
  /** Source representing the ground truth, it can be a form or a document. */
  source?:
    | ActionParamValue
    | undefined;
  /** Target representing the data to be validated, it's usually document but can also be a form. */
  target?:
    | ActionParamValue
    | undefined;
  /** NL description of the validation rule, which is sent to validation API. */
  rule?:
    | string
    | undefined;
  /** list of fields to be validated */
  fields?:
    | string[]
    | undefined;
  /** Additional metadata for the validate action, e.g. report header */
  metadata?: { [key: string]: ActionParamValue } | undefined;
}

export interface ValidateActionMetadataEntry {
  key: string;
  value?: ActionParamValue | undefined;
}

/**
 * Javascript function can be used to generate output in any format from
 * 1. zero or more output from prior actions.
 * 2. global context, such as current date etc.
 */
export interface JsFunctionAction {
  /** NL description of what the function does. */
  description?:
    | string
    | undefined;
  /** function parameter names, which are referred to in the function body */
  paramNames?: string[] | undefined;
  params?:
    | ActionParamValue[]
    | undefined;
  /** generated function body */
  body?: string | undefined;
}

/**
 * Condition actions take a single boolean/string param, and execute different branches.
 * For string param, the empty string evaluates to false and non-empty to true.
 */
export interface ConditionAction {
  condition?:
    | ActionParamValue
    | undefined;
  /**
   * Next action if condition value is true.
   *
   * @deprecated
   */
  trueActions?:
    | ActionGroup[]
    | undefined;
  /**
   * Next action if condition value is false.
   *
   * @deprecated
   */
  falseActions?: ActionGroup[] | undefined;
  thenActions?: Action[] | undefined;
  elseActions?: Action[] | undefined;
}

export interface GetListAction {
  listLocator?: ElementLocator | undefined;
}

export interface ForeachAction {
  /** items to be iterated through */
  items?:
    | ActionParamValue
    | undefined;
  /** @deprecated */
  actions?: ActionGroup[] | undefined;
  loopActions?: Action[] | undefined;
}

export interface FlagKeywordsAction {
  keywords?:
    | string[]
    | undefined;
  /** list of fields to include in flagging keywords */
  fields?: string[] | undefined;
  source?: ActionParamValue | undefined;
}

export interface DetectDuplicateLineItemsAction {
  duplicates?: string[] | undefined;
  source?: ActionParamValue | undefined;
}

/** Variables that are passed for execution as global env_value */
export interface WorkflowVariable {
  key?:
    | string
    | undefined;
  /** Only allow string values for simplicity, similar to OS environment variables. */
  value?: string | undefined;
}

/** Create a workflow task */
export interface CreateTaskAction {
  workflowId?: string | undefined;
  workflowVariables?:
    | ActionParamValue[]
    | undefined;
  /** the process within a workflow to start the task */
  processId?: string | undefined;
}

export interface ReconcileItemsAction {
  items?: ReconcileItemsActionItemLocator[] | undefined;
}

export interface ReconcileItemsActionItemLocator {
  tabIndex?: number | undefined;
  fieldGroups?: ElementLocator | undefined;
  documents?: ElementLocator[] | undefined;
}

/** Update the data table on the HTML page. */
export interface UpdateDataTableAction {
  tableLocator?: ElementLocator | undefined;
}

export interface ExitAction {
  status?: ExitActionExitStatus | undefined;
}

export enum ExitActionExitStatus {
  EXIST_STATUS_UNSPECIFIED = 0,
  SUCCESS = 1,
  ERROR = 2,
  UNRECOGNIZED = -1,
}

export function exitActionExitStatusFromJSON(object: any): ExitActionExitStatus {
  switch (object) {
    case 0:
    case "EXIST_STATUS_UNSPECIFIED":
      return ExitActionExitStatus.EXIST_STATUS_UNSPECIFIED;
    case 1:
    case "SUCCESS":
      return ExitActionExitStatus.SUCCESS;
    case 2:
    case "ERROR":
      return ExitActionExitStatus.ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExitActionExitStatus.UNRECOGNIZED;
  }
}

export function exitActionExitStatusToJSON(object: ExitActionExitStatus): string {
  switch (object) {
    case ExitActionExitStatus.EXIST_STATUS_UNSPECIFIED:
      return "EXIST_STATUS_UNSPECIFIED";
    case ExitActionExitStatus.SUCCESS:
      return "SUCCESS";
    case ExitActionExitStatus.ERROR:
      return "ERROR";
    case ExitActionExitStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Allow user to define custom actions that are not supported by default. */
export interface CustomSmartAction {
  /** key value pairs as input to the action */
  inputs?:
    | { [key: string]: ActionParamValue }
    | undefined;
  /** NL description of what the action does */
  rule?: string | undefined;
}

export interface CustomSmartActionInputsEntry {
  key: string;
  value?: ActionParamValue | undefined;
}

export interface GetDocumentAction {
  documentLocator?: ElementLocator | undefined;
}

/**
 * A generic action to generate text based on user prompted rule.
 * Example: Generate a summary based on the extracted fields from a document
 */
export interface GenerateTextAction {
  /** Expected to be reference value of any previous action output */
  inputs?:
    | ActionParamValue[]
    | undefined;
  /** User defined prompt for text generation */
  prompt?: string | undefined;
}

/**
 * A generic action to classify any input into predefined categories with a
 * confidence score and explanation. A few example use cases:
 * - Receipt validation (check a document is a valid receipt according to the policy)
 * - Risk analysis (use the confidence score as a measure of risk)
 * - Ranking (use the confidence score as measure of relevance)
 * - Sentiment analysis, such as is this review positive or negative
 */
export interface ClassifyAction {
  /** Expected to be reference value of any previous action output */
  inputs?:
    | ActionParamValue[]
    | undefined;
  /** Either user defined prompt or preset should be provided for classification */
  prompt?: string | undefined;
  preset?: ClassifyClassifyPreset | undefined;
}

export interface ScrollAction {
  scrollBy?: ScrollActionScrollBy | undefined;
  scrollTo?: ScrollActionScrollTo | undefined;
}

/**
 * Scroll the document by given pixels.
 * Similar to https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollBy
 */
export interface ScrollActionScrollBy {
  x?: number | undefined;
  y?: number | undefined;
}

/**
 * Scroll the window to a particular place in the document.
 * Similar to https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll
 */
export interface ScrollActionScrollTo {
  position?: ScrollActionScrollToPosition | undefined;
  preset?: ScrollActionScrollToPresetPosition | undefined;
}

export enum ScrollActionScrollToPresetPosition {
  UNSPECIFIED = 0,
  TOP = 1,
  BOTTOM = 2,
  LEFTMOST = 3,
  RIGHTMOST = 4,
  UNRECOGNIZED = -1,
}

export function scrollActionScrollToPresetPositionFromJSON(object: any): ScrollActionScrollToPresetPosition {
  switch (object) {
    case 0:
    case "PRESET_POSITION_UNSPECIFIED":
      return ScrollActionScrollToPresetPosition.UNSPECIFIED;
    case 1:
    case "TOP":
      return ScrollActionScrollToPresetPosition.TOP;
    case 2:
    case "BOTTOM":
      return ScrollActionScrollToPresetPosition.BOTTOM;
    case 3:
    case "LEFTMOST":
      return ScrollActionScrollToPresetPosition.LEFTMOST;
    case 4:
    case "RIGHTMOST":
      return ScrollActionScrollToPresetPosition.RIGHTMOST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScrollActionScrollToPresetPosition.UNRECOGNIZED;
  }
}

export function scrollActionScrollToPresetPositionToJSON(object: ScrollActionScrollToPresetPosition): string {
  switch (object) {
    case ScrollActionScrollToPresetPosition.UNSPECIFIED:
      return "PRESET_POSITION_UNSPECIFIED";
    case ScrollActionScrollToPresetPosition.TOP:
      return "TOP";
    case ScrollActionScrollToPresetPosition.BOTTOM:
      return "BOTTOM";
    case ScrollActionScrollToPresetPosition.LEFTMOST:
      return "LEFTMOST";
    case ScrollActionScrollToPresetPosition.RIGHTMOST:
      return "RIGHTMOST";
    case ScrollActionScrollToPresetPosition.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ScrollActionScrollToPosition {
  x?: number | undefined;
  y?: number | undefined;
}

function createBasePageContent(): PageContent {
  return { elements: [] };
}

export const PageContent = {
  encode(message: PageContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elements !== undefined && message.elements.length !== 0) {
      for (const v of message.elements) {
        PageContentElement.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PageContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elements!.push(PageContentElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageContent {
    return {
      elements: globalThis.Array.isArray(object?.elements)
        ? object.elements.map((e: any) => PageContentElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PageContent): unknown {
    const obj: any = {};
    if (message.elements?.length) {
      obj.elements = message.elements.map((e) => PageContentElement.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageContent>, I>>(base?: I): PageContent {
    return PageContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageContent>, I>>(object: I): PageContent {
    const message = createBasePageContent();
    message.elements = object.elements?.map((e) => PageContentElement.fromPartial(e)) || [];
    return message;
  },
};

function createBasePageContentElement(): PageContentElement {
  return { tag: undefined, text: undefined };
}

export const PageContentElement = {
  encode(message: PageContentElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== undefined) {
      PageContentElementTag.encode(message.tag, writer.uint32(18).fork()).ldelim();
    }
    if (message.text !== undefined) {
      writer.uint32(26).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PageContentElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageContentElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = PageContentElementTag.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageContentElement {
    return {
      tag: isSet(object.tag) ? PageContentElementTag.fromJSON(object.tag) : undefined,
      text: isSet(object.text) ? globalThis.String(object.text) : undefined,
    };
  },

  toJSON(message: PageContentElement): unknown {
    const obj: any = {};
    if (message.tag !== undefined) {
      obj.tag = PageContentElementTag.toJSON(message.tag);
    }
    if (message.text !== undefined) {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageContentElement>, I>>(base?: I): PageContentElement {
    return PageContentElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageContentElement>, I>>(object: I): PageContentElement {
    const message = createBasePageContentElement();
    message.tag = (object.tag !== undefined && object.tag !== null)
      ? PageContentElementTag.fromPartial(object.tag)
      : undefined;
    message.text = object.text ?? undefined;
    return message;
  },
};

function createBasePageContentElementTag(): PageContentElementTag {
  return { orbyId: "", name: "", children: [], attributes: {} };
}

export const PageContentElementTag = {
  encode(message: PageContentElementTag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orbyId !== undefined && message.orbyId !== "") {
      writer.uint32(34).string(message.orbyId);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.children !== undefined && message.children.length !== 0) {
      for (const v of message.children) {
        PageContentElement.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    Object.entries(message.attributes || {}).forEach(([key, value]) => {
      PageContentElementTagAttributesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PageContentElementTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageContentElementTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orbyId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.children!.push(PageContentElement.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = PageContentElementTagAttributesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.attributes![entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageContentElementTag {
    return {
      orbyId: isSet(object.orbyId) ? globalThis.String(object.orbyId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => PageContentElement.fromJSON(e))
        : [],
      attributes: isObject(object.attributes)
        ? Object.entries(object.attributes).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: PageContentElementTag): unknown {
    const obj: any = {};
    if (message.orbyId !== undefined && message.orbyId !== "") {
      obj.orbyId = message.orbyId;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => PageContentElement.toJSON(e));
    }
    if (message.attributes) {
      const entries = Object.entries(message.attributes);
      if (entries.length > 0) {
        obj.attributes = {};
        entries.forEach(([k, v]) => {
          obj.attributes[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageContentElementTag>, I>>(base?: I): PageContentElementTag {
    return PageContentElementTag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageContentElementTag>, I>>(object: I): PageContentElementTag {
    const message = createBasePageContentElementTag();
    message.orbyId = object.orbyId ?? "";
    message.name = object.name ?? "";
    message.children = object.children?.map((e) => PageContentElement.fromPartial(e)) || [];
    message.attributes = Object.entries(object.attributes ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBasePageContentElementTagAttributesEntry(): PageContentElementTagAttributesEntry {
  return { key: "", value: "" };
}

export const PageContentElementTagAttributesEntry = {
  encode(message: PageContentElementTagAttributesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PageContentElementTagAttributesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageContentElementTagAttributesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageContentElementTagAttributesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: PageContentElementTagAttributesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageContentElementTagAttributesEntry>, I>>(
    base?: I,
  ): PageContentElementTagAttributesEntry {
    return PageContentElementTagAttributesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageContentElementTagAttributesEntry>, I>>(
    object: I,
  ): PageContentElementTagAttributesEntry {
    const message = createBasePageContentElementTagAttributesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUiState(): UiState {
  return { viewportScreenshot: undefined, viewportWidth: 0, viewportHeight: 0, rootElement: undefined, url: "" };
}

export const UiState = {
  encode(message: UiState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.viewportScreenshot !== undefined) {
      RecordedFile.encode(message.viewportScreenshot, writer.uint32(10).fork()).ldelim();
    }
    if (message.viewportWidth !== undefined && message.viewportWidth !== 0) {
      writer.uint32(16).int32(message.viewportWidth);
    }
    if (message.viewportHeight !== undefined && message.viewportHeight !== 0) {
      writer.uint32(24).int32(message.viewportHeight);
    }
    if (message.rootElement !== undefined) {
      RecordedFile.encode(message.rootElement, writer.uint32(34).fork()).ldelim();
    }
    if (message.url !== undefined && message.url !== "") {
      writer.uint32(42).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UiState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUiState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.viewportScreenshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewportWidth = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.viewportHeight = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rootElement = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UiState {
    return {
      viewportScreenshot: isSet(object.viewportScreenshot)
        ? RecordedFile.fromJSON(object.viewportScreenshot)
        : undefined,
      viewportWidth: isSet(object.viewportWidth) ? globalThis.Number(object.viewportWidth) : 0,
      viewportHeight: isSet(object.viewportHeight) ? globalThis.Number(object.viewportHeight) : 0,
      rootElement: isSet(object.rootElement) ? RecordedFile.fromJSON(object.rootElement) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: UiState): unknown {
    const obj: any = {};
    if (message.viewportScreenshot !== undefined) {
      obj.viewportScreenshot = RecordedFile.toJSON(message.viewportScreenshot);
    }
    if (message.viewportWidth !== undefined && message.viewportWidth !== 0) {
      obj.viewportWidth = Math.round(message.viewportWidth);
    }
    if (message.viewportHeight !== undefined && message.viewportHeight !== 0) {
      obj.viewportHeight = Math.round(message.viewportHeight);
    }
    if (message.rootElement !== undefined) {
      obj.rootElement = RecordedFile.toJSON(message.rootElement);
    }
    if (message.url !== undefined && message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UiState>, I>>(base?: I): UiState {
    return UiState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UiState>, I>>(object: I): UiState {
    const message = createBaseUiState();
    message.viewportScreenshot = (object.viewportScreenshot !== undefined && object.viewportScreenshot !== null)
      ? RecordedFile.fromPartial(object.viewportScreenshot)
      : undefined;
    message.viewportWidth = object.viewportWidth ?? 0;
    message.viewportHeight = object.viewportHeight ?? 0;
    message.rootElement = (object.rootElement !== undefined && object.rootElement !== null)
      ? RecordedFile.fromPartial(object.rootElement)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseActionBlock(): ActionBlock {
  return { actions: [], kind: 0 };
}

export const ActionBlock = {
  encode(message: ActionBlock, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actions !== undefined && message.actions.length !== 0) {
      for (const v of message.actions) {
        Action.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.kind !== undefined && message.kind !== 0) {
      writer.uint32(16).int32(message.kind);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionBlock {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionBlock();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions!.push(Action.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.kind = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionBlock {
    return {
      actions: globalThis.Array.isArray(object?.actions) ? object.actions.map((e: any) => Action.fromJSON(e)) : [],
      kind: isSet(object.kind) ? actionBlockKindFromJSON(object.kind) : 0,
    };
  },

  toJSON(message: ActionBlock): unknown {
    const obj: any = {};
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => Action.toJSON(e));
    }
    if (message.kind !== undefined && message.kind !== 0) {
      obj.kind = actionBlockKindToJSON(message.kind);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionBlock>, I>>(base?: I): ActionBlock {
    return ActionBlock.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionBlock>, I>>(object: I): ActionBlock {
    const message = createBaseActionBlock();
    message.actions = object.actions?.map((e) => Action.fromPartial(e)) || [];
    message.kind = object.kind ?? 0;
    return message;
  },
};

function createBasePrerequisiteAction(): PrerequisiteAction {
  return { action: undefined, type: 0 };
}

export const PrerequisiteAction = {
  encode(message: PrerequisiteAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.action !== undefined) {
      Action.encode(message.action, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrerequisiteAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrerequisiteAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.action = Action.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrerequisiteAction {
    return {
      action: isSet(object.action) ? Action.fromJSON(object.action) : undefined,
      type: isSet(object.type) ? prerequisiteActionPrerequisiteTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: PrerequisiteAction): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = Action.toJSON(message.action);
    }
    if (message.type !== undefined && message.type !== 0) {
      obj.type = prerequisiteActionPrerequisiteTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrerequisiteAction>, I>>(base?: I): PrerequisiteAction {
    return PrerequisiteAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrerequisiteAction>, I>>(object: I): PrerequisiteAction {
    const message = createBasePrerequisiteAction();
    message.action = (object.action !== undefined && object.action !== null)
      ? Action.fromPartial(object.action)
      : undefined;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseAction(): Action {
  return {
    id: "",
    description: "",
    prerequisites: [],
    block: undefined,
    goto: undefined,
    click: undefined,
    getForm: undefined,
    fillForm: undefined,
    extractFields: undefined,
    jsFunction: undefined,
    validate: undefined,
    condition: undefined,
    foreach: undefined,
    getList: undefined,
    getElement: undefined,
    flagKeywords: undefined,
    detectDuplicateLineItems: undefined,
    createTask: undefined,
    reconcileItems: undefined,
    hover: undefined,
    exit: undefined,
    setValue: undefined,
    customSmartAction: undefined,
    getDocument: undefined,
    scrollAction: undefined,
    generateText: undefined,
    classify: undefined,
    tabIndex: 0,
    snapshot: undefined,
    screenshot: undefined,
    beforeState: undefined,
    verification: undefined,
    lowLevelActionType: 0,
    elementIds: [],
  };
}

export const Action = {
  encode(message: Action, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.prerequisites !== undefined && message.prerequisites.length !== 0) {
      for (const v of message.prerequisites) {
        PrerequisiteAction.encode(v!, writer.uint32(258).fork()).ldelim();
      }
    }
    if (message.block !== undefined) {
      ActionBlock.encode(message.block, writer.uint32(26).fork()).ldelim();
    }
    if (message.goto !== undefined) {
      GotoAction.encode(message.goto, writer.uint32(34).fork()).ldelim();
    }
    if (message.click !== undefined) {
      ClickAction.encode(message.click, writer.uint32(42).fork()).ldelim();
    }
    if (message.getForm !== undefined) {
      GetFormAction.encode(message.getForm, writer.uint32(50).fork()).ldelim();
    }
    if (message.fillForm !== undefined) {
      FillFormAction.encode(message.fillForm, writer.uint32(58).fork()).ldelim();
    }
    if (message.extractFields !== undefined) {
      ExtractFieldsAction.encode(message.extractFields, writer.uint32(66).fork()).ldelim();
    }
    if (message.jsFunction !== undefined) {
      JsFunctionAction.encode(message.jsFunction, writer.uint32(74).fork()).ldelim();
    }
    if (message.validate !== undefined) {
      ValidateAction.encode(message.validate, writer.uint32(82).fork()).ldelim();
    }
    if (message.condition !== undefined) {
      ConditionAction.encode(message.condition, writer.uint32(90).fork()).ldelim();
    }
    if (message.foreach !== undefined) {
      ForeachAction.encode(message.foreach, writer.uint32(98).fork()).ldelim();
    }
    if (message.getList !== undefined) {
      GetListAction.encode(message.getList, writer.uint32(106).fork()).ldelim();
    }
    if (message.getElement !== undefined) {
      GetElementAction.encode(message.getElement, writer.uint32(130).fork()).ldelim();
    }
    if (message.flagKeywords !== undefined) {
      FlagKeywordsAction.encode(message.flagKeywords, writer.uint32(138).fork()).ldelim();
    }
    if (message.detectDuplicateLineItems !== undefined) {
      DetectDuplicateLineItemsAction.encode(message.detectDuplicateLineItems, writer.uint32(146).fork()).ldelim();
    }
    if (message.createTask !== undefined) {
      CreateTaskAction.encode(message.createTask, writer.uint32(154).fork()).ldelim();
    }
    if (message.reconcileItems !== undefined) {
      ReconcileItemsAction.encode(message.reconcileItems, writer.uint32(162).fork()).ldelim();
    }
    if (message.hover !== undefined) {
      HoverAction.encode(message.hover, writer.uint32(178).fork()).ldelim();
    }
    if (message.exit !== undefined) {
      ExitAction.encode(message.exit, writer.uint32(186).fork()).ldelim();
    }
    if (message.setValue !== undefined) {
      SetValueAction.encode(message.setValue, writer.uint32(194).fork()).ldelim();
    }
    if (message.customSmartAction !== undefined) {
      CustomSmartAction.encode(message.customSmartAction, writer.uint32(202).fork()).ldelim();
    }
    if (message.getDocument !== undefined) {
      GetDocumentAction.encode(message.getDocument, writer.uint32(210).fork()).ldelim();
    }
    if (message.scrollAction !== undefined) {
      ScrollAction.encode(message.scrollAction, writer.uint32(250).fork()).ldelim();
    }
    if (message.generateText !== undefined) {
      GenerateTextAction.encode(message.generateText, writer.uint32(274).fork()).ldelim();
    }
    if (message.classify !== undefined) {
      ClassifyAction.encode(message.classify, writer.uint32(282).fork()).ldelim();
    }
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      writer.uint32(168).int32(message.tabIndex);
    }
    if (message.snapshot !== undefined) {
      RecordedFile.encode(message.snapshot, writer.uint32(218).fork()).ldelim();
    }
    if (message.screenshot !== undefined) {
      RecordedFile.encode(message.screenshot, writer.uint32(226).fork()).ldelim();
    }
    if (message.beforeState !== undefined) {
      UiState.encode(message.beforeState, writer.uint32(306).fork()).ldelim();
    }
    if (message.verification !== undefined) {
      ActionVerification.encode(message.verification, writer.uint32(234).fork()).ldelim();
    }
    if (message.lowLevelActionType !== undefined && message.lowLevelActionType !== 0) {
      writer.uint32(240).int32(message.lowLevelActionType);
    }
    if (message.elementIds !== undefined && message.elementIds.length !== 0) {
      for (const v of message.elementIds) {
        writer.uint32(266).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Action {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.prerequisites!.push(PrerequisiteAction.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.block = ActionBlock.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.goto = GotoAction.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.click = ClickAction.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.getForm = GetFormAction.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fillForm = FillFormAction.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extractFields = ExtractFieldsAction.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.jsFunction = JsFunctionAction.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.validate = ValidateAction.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.condition = ConditionAction.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.foreach = ForeachAction.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.getList = GetListAction.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.getElement = GetElementAction.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.flagKeywords = FlagKeywordsAction.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.detectDuplicateLineItems = DetectDuplicateLineItemsAction.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.createTask = CreateTaskAction.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.reconcileItems = ReconcileItemsAction.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.hover = HoverAction.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.exit = ExitAction.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.setValue = SetValueAction.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.customSmartAction = CustomSmartAction.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.getDocument = GetDocumentAction.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.scrollAction = ScrollAction.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.generateText = GenerateTextAction.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.classify = ClassifyAction.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.tabIndex = reader.int32();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.snapshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.screenshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.beforeState = UiState.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.verification = ActionVerification.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.lowLevelActionType = reader.int32() as any;
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.elementIds!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Action {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      prerequisites: globalThis.Array.isArray(object?.prerequisites)
        ? object.prerequisites.map((e: any) => PrerequisiteAction.fromJSON(e))
        : [],
      block: isSet(object.block) ? ActionBlock.fromJSON(object.block) : undefined,
      goto: isSet(object.goto) ? GotoAction.fromJSON(object.goto) : undefined,
      click: isSet(object.click) ? ClickAction.fromJSON(object.click) : undefined,
      getForm: isSet(object.getForm) ? GetFormAction.fromJSON(object.getForm) : undefined,
      fillForm: isSet(object.fillForm) ? FillFormAction.fromJSON(object.fillForm) : undefined,
      extractFields: isSet(object.extractFields) ? ExtractFieldsAction.fromJSON(object.extractFields) : undefined,
      jsFunction: isSet(object.jsFunction) ? JsFunctionAction.fromJSON(object.jsFunction) : undefined,
      validate: isSet(object.validate) ? ValidateAction.fromJSON(object.validate) : undefined,
      condition: isSet(object.condition) ? ConditionAction.fromJSON(object.condition) : undefined,
      foreach: isSet(object.foreach) ? ForeachAction.fromJSON(object.foreach) : undefined,
      getList: isSet(object.getList) ? GetListAction.fromJSON(object.getList) : undefined,
      getElement: isSet(object.getElement) ? GetElementAction.fromJSON(object.getElement) : undefined,
      flagKeywords: isSet(object.flagKeywords) ? FlagKeywordsAction.fromJSON(object.flagKeywords) : undefined,
      detectDuplicateLineItems: isSet(object.detectDuplicateLineItems)
        ? DetectDuplicateLineItemsAction.fromJSON(object.detectDuplicateLineItems)
        : undefined,
      createTask: isSet(object.createTask) ? CreateTaskAction.fromJSON(object.createTask) : undefined,
      reconcileItems: isSet(object.reconcileItems) ? ReconcileItemsAction.fromJSON(object.reconcileItems) : undefined,
      hover: isSet(object.hover) ? HoverAction.fromJSON(object.hover) : undefined,
      exit: isSet(object.exit) ? ExitAction.fromJSON(object.exit) : undefined,
      setValue: isSet(object.setValue) ? SetValueAction.fromJSON(object.setValue) : undefined,
      customSmartAction: isSet(object.customSmartAction)
        ? CustomSmartAction.fromJSON(object.customSmartAction)
        : undefined,
      getDocument: isSet(object.getDocument) ? GetDocumentAction.fromJSON(object.getDocument) : undefined,
      scrollAction: isSet(object.scrollAction) ? ScrollAction.fromJSON(object.scrollAction) : undefined,
      generateText: isSet(object.generateText) ? GenerateTextAction.fromJSON(object.generateText) : undefined,
      classify: isSet(object.classify) ? ClassifyAction.fromJSON(object.classify) : undefined,
      tabIndex: isSet(object.tabIndex) ? globalThis.Number(object.tabIndex) : 0,
      snapshot: isSet(object.snapshot) ? RecordedFile.fromJSON(object.snapshot) : undefined,
      screenshot: isSet(object.screenshot) ? RecordedFile.fromJSON(object.screenshot) : undefined,
      beforeState: isSet(object.beforeState) ? UiState.fromJSON(object.beforeState) : undefined,
      verification: isSet(object.verification) ? ActionVerification.fromJSON(object.verification) : undefined,
      lowLevelActionType: isSet(object.lowLevelActionType) ? lowLevelActionTypeFromJSON(object.lowLevelActionType) : 0,
      elementIds: globalThis.Array.isArray(object?.elementIds)
        ? object.elementIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Action): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.prerequisites?.length) {
      obj.prerequisites = message.prerequisites.map((e) => PrerequisiteAction.toJSON(e));
    }
    if (message.block !== undefined) {
      obj.block = ActionBlock.toJSON(message.block);
    }
    if (message.goto !== undefined) {
      obj.goto = GotoAction.toJSON(message.goto);
    }
    if (message.click !== undefined) {
      obj.click = ClickAction.toJSON(message.click);
    }
    if (message.getForm !== undefined) {
      obj.getForm = GetFormAction.toJSON(message.getForm);
    }
    if (message.fillForm !== undefined) {
      obj.fillForm = FillFormAction.toJSON(message.fillForm);
    }
    if (message.extractFields !== undefined) {
      obj.extractFields = ExtractFieldsAction.toJSON(message.extractFields);
    }
    if (message.jsFunction !== undefined) {
      obj.jsFunction = JsFunctionAction.toJSON(message.jsFunction);
    }
    if (message.validate !== undefined) {
      obj.validate = ValidateAction.toJSON(message.validate);
    }
    if (message.condition !== undefined) {
      obj.condition = ConditionAction.toJSON(message.condition);
    }
    if (message.foreach !== undefined) {
      obj.foreach = ForeachAction.toJSON(message.foreach);
    }
    if (message.getList !== undefined) {
      obj.getList = GetListAction.toJSON(message.getList);
    }
    if (message.getElement !== undefined) {
      obj.getElement = GetElementAction.toJSON(message.getElement);
    }
    if (message.flagKeywords !== undefined) {
      obj.flagKeywords = FlagKeywordsAction.toJSON(message.flagKeywords);
    }
    if (message.detectDuplicateLineItems !== undefined) {
      obj.detectDuplicateLineItems = DetectDuplicateLineItemsAction.toJSON(message.detectDuplicateLineItems);
    }
    if (message.createTask !== undefined) {
      obj.createTask = CreateTaskAction.toJSON(message.createTask);
    }
    if (message.reconcileItems !== undefined) {
      obj.reconcileItems = ReconcileItemsAction.toJSON(message.reconcileItems);
    }
    if (message.hover !== undefined) {
      obj.hover = HoverAction.toJSON(message.hover);
    }
    if (message.exit !== undefined) {
      obj.exit = ExitAction.toJSON(message.exit);
    }
    if (message.setValue !== undefined) {
      obj.setValue = SetValueAction.toJSON(message.setValue);
    }
    if (message.customSmartAction !== undefined) {
      obj.customSmartAction = CustomSmartAction.toJSON(message.customSmartAction);
    }
    if (message.getDocument !== undefined) {
      obj.getDocument = GetDocumentAction.toJSON(message.getDocument);
    }
    if (message.scrollAction !== undefined) {
      obj.scrollAction = ScrollAction.toJSON(message.scrollAction);
    }
    if (message.generateText !== undefined) {
      obj.generateText = GenerateTextAction.toJSON(message.generateText);
    }
    if (message.classify !== undefined) {
      obj.classify = ClassifyAction.toJSON(message.classify);
    }
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      obj.tabIndex = Math.round(message.tabIndex);
    }
    if (message.snapshot !== undefined) {
      obj.snapshot = RecordedFile.toJSON(message.snapshot);
    }
    if (message.screenshot !== undefined) {
      obj.screenshot = RecordedFile.toJSON(message.screenshot);
    }
    if (message.beforeState !== undefined) {
      obj.beforeState = UiState.toJSON(message.beforeState);
    }
    if (message.verification !== undefined) {
      obj.verification = ActionVerification.toJSON(message.verification);
    }
    if (message.lowLevelActionType !== undefined && message.lowLevelActionType !== 0) {
      obj.lowLevelActionType = lowLevelActionTypeToJSON(message.lowLevelActionType);
    }
    if (message.elementIds?.length) {
      obj.elementIds = message.elementIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Action>, I>>(base?: I): Action {
    return Action.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Action>, I>>(object: I): Action {
    const message = createBaseAction();
    message.id = object.id ?? "";
    message.description = object.description ?? "";
    message.prerequisites = object.prerequisites?.map((e) => PrerequisiteAction.fromPartial(e)) || [];
    message.block = (object.block !== undefined && object.block !== null)
      ? ActionBlock.fromPartial(object.block)
      : undefined;
    message.goto = (object.goto !== undefined && object.goto !== null)
      ? GotoAction.fromPartial(object.goto)
      : undefined;
    message.click = (object.click !== undefined && object.click !== null)
      ? ClickAction.fromPartial(object.click)
      : undefined;
    message.getForm = (object.getForm !== undefined && object.getForm !== null)
      ? GetFormAction.fromPartial(object.getForm)
      : undefined;
    message.fillForm = (object.fillForm !== undefined && object.fillForm !== null)
      ? FillFormAction.fromPartial(object.fillForm)
      : undefined;
    message.extractFields = (object.extractFields !== undefined && object.extractFields !== null)
      ? ExtractFieldsAction.fromPartial(object.extractFields)
      : undefined;
    message.jsFunction = (object.jsFunction !== undefined && object.jsFunction !== null)
      ? JsFunctionAction.fromPartial(object.jsFunction)
      : undefined;
    message.validate = (object.validate !== undefined && object.validate !== null)
      ? ValidateAction.fromPartial(object.validate)
      : undefined;
    message.condition = (object.condition !== undefined && object.condition !== null)
      ? ConditionAction.fromPartial(object.condition)
      : undefined;
    message.foreach = (object.foreach !== undefined && object.foreach !== null)
      ? ForeachAction.fromPartial(object.foreach)
      : undefined;
    message.getList = (object.getList !== undefined && object.getList !== null)
      ? GetListAction.fromPartial(object.getList)
      : undefined;
    message.getElement = (object.getElement !== undefined && object.getElement !== null)
      ? GetElementAction.fromPartial(object.getElement)
      : undefined;
    message.flagKeywords = (object.flagKeywords !== undefined && object.flagKeywords !== null)
      ? FlagKeywordsAction.fromPartial(object.flagKeywords)
      : undefined;
    message.detectDuplicateLineItems =
      (object.detectDuplicateLineItems !== undefined && object.detectDuplicateLineItems !== null)
        ? DetectDuplicateLineItemsAction.fromPartial(object.detectDuplicateLineItems)
        : undefined;
    message.createTask = (object.createTask !== undefined && object.createTask !== null)
      ? CreateTaskAction.fromPartial(object.createTask)
      : undefined;
    message.reconcileItems = (object.reconcileItems !== undefined && object.reconcileItems !== null)
      ? ReconcileItemsAction.fromPartial(object.reconcileItems)
      : undefined;
    message.hover = (object.hover !== undefined && object.hover !== null)
      ? HoverAction.fromPartial(object.hover)
      : undefined;
    message.exit = (object.exit !== undefined && object.exit !== null)
      ? ExitAction.fromPartial(object.exit)
      : undefined;
    message.setValue = (object.setValue !== undefined && object.setValue !== null)
      ? SetValueAction.fromPartial(object.setValue)
      : undefined;
    message.customSmartAction = (object.customSmartAction !== undefined && object.customSmartAction !== null)
      ? CustomSmartAction.fromPartial(object.customSmartAction)
      : undefined;
    message.getDocument = (object.getDocument !== undefined && object.getDocument !== null)
      ? GetDocumentAction.fromPartial(object.getDocument)
      : undefined;
    message.scrollAction = (object.scrollAction !== undefined && object.scrollAction !== null)
      ? ScrollAction.fromPartial(object.scrollAction)
      : undefined;
    message.generateText = (object.generateText !== undefined && object.generateText !== null)
      ? GenerateTextAction.fromPartial(object.generateText)
      : undefined;
    message.classify = (object.classify !== undefined && object.classify !== null)
      ? ClassifyAction.fromPartial(object.classify)
      : undefined;
    message.tabIndex = object.tabIndex ?? 0;
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? RecordedFile.fromPartial(object.snapshot)
      : undefined;
    message.screenshot = (object.screenshot !== undefined && object.screenshot !== null)
      ? RecordedFile.fromPartial(object.screenshot)
      : undefined;
    message.beforeState = (object.beforeState !== undefined && object.beforeState !== null)
      ? UiState.fromPartial(object.beforeState)
      : undefined;
    message.verification = (object.verification !== undefined && object.verification !== null)
      ? ActionVerification.fromPartial(object.verification)
      : undefined;
    message.lowLevelActionType = object.lowLevelActionType ?? 0;
    message.elementIds = object.elementIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseActionVerification(): ActionVerification {
  return { outgoingRequests: [], elementAssertions: [], evaluateScript: undefined };
}

export const ActionVerification = {
  encode(message: ActionVerification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.outgoingRequests !== undefined && message.outgoingRequests.length !== 0) {
      for (const v of message.outgoingRequests) {
        ActionVerificationOutgoingRequest.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.elementAssertions !== undefined && message.elementAssertions.length !== 0) {
      for (const v of message.elementAssertions) {
        ActionVerificationElementAssertion.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.evaluateScript !== undefined) {
      ActionVerificationEvaluateScript.encode(message.evaluateScript, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionVerification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionVerification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.outgoingRequests!.push(ActionVerificationOutgoingRequest.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.elementAssertions!.push(ActionVerificationElementAssertion.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.evaluateScript = ActionVerificationEvaluateScript.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionVerification {
    return {
      outgoingRequests: globalThis.Array.isArray(object?.outgoingRequests)
        ? object.outgoingRequests.map((e: any) => ActionVerificationOutgoingRequest.fromJSON(e))
        : [],
      elementAssertions: globalThis.Array.isArray(object?.elementAssertions)
        ? object.elementAssertions.map((e: any) => ActionVerificationElementAssertion.fromJSON(e))
        : [],
      evaluateScript: isSet(object.evaluateScript)
        ? ActionVerificationEvaluateScript.fromJSON(object.evaluateScript)
        : undefined,
    };
  },

  toJSON(message: ActionVerification): unknown {
    const obj: any = {};
    if (message.outgoingRequests?.length) {
      obj.outgoingRequests = message.outgoingRequests.map((e) => ActionVerificationOutgoingRequest.toJSON(e));
    }
    if (message.elementAssertions?.length) {
      obj.elementAssertions = message.elementAssertions.map((e) => ActionVerificationElementAssertion.toJSON(e));
    }
    if (message.evaluateScript !== undefined) {
      obj.evaluateScript = ActionVerificationEvaluateScript.toJSON(message.evaluateScript);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionVerification>, I>>(base?: I): ActionVerification {
    return ActionVerification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionVerification>, I>>(object: I): ActionVerification {
    const message = createBaseActionVerification();
    message.outgoingRequests = object.outgoingRequests?.map((e) => ActionVerificationOutgoingRequest.fromPartial(e)) ||
      [];
    message.elementAssertions =
      object.elementAssertions?.map((e) => ActionVerificationElementAssertion.fromPartial(e)) || [];
    message.evaluateScript = (object.evaluateScript !== undefined && object.evaluateScript !== null)
      ? ActionVerificationEvaluateScript.fromPartial(object.evaluateScript)
      : undefined;
    return message;
  },
};

function createBaseActionVerificationOutgoingRequestBody(): ActionVerificationOutgoingRequestBody {
  return { json: undefined, raw: undefined };
}

export const ActionVerificationOutgoingRequestBody = {
  encode(message: ActionVerificationOutgoingRequestBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.json !== undefined) {
      writer.uint32(10).string(message.json);
    }
    if (message.raw !== undefined) {
      writer.uint32(18).bytes(message.raw);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionVerificationOutgoingRequestBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionVerificationOutgoingRequestBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.json = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.raw = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionVerificationOutgoingRequestBody {
    return {
      json: isSet(object.json) ? globalThis.String(object.json) : undefined,
      raw: isSet(object.raw) ? bytesFromBase64(object.raw) : undefined,
    };
  },

  toJSON(message: ActionVerificationOutgoingRequestBody): unknown {
    const obj: any = {};
    if (message.json !== undefined) {
      obj.json = message.json;
    }
    if (message.raw !== undefined) {
      obj.raw = base64FromBytes(message.raw);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionVerificationOutgoingRequestBody>, I>>(
    base?: I,
  ): ActionVerificationOutgoingRequestBody {
    return ActionVerificationOutgoingRequestBody.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionVerificationOutgoingRequestBody>, I>>(
    object: I,
  ): ActionVerificationOutgoingRequestBody {
    const message = createBaseActionVerificationOutgoingRequestBody();
    message.json = object.json ?? undefined;
    message.raw = object.raw ?? undefined;
    return message;
  },
};

function createBaseActionVerificationOutgoingRequest(): ActionVerificationOutgoingRequest {
  return { url: "", method: 0, body: undefined };
}

export const ActionVerificationOutgoingRequest = {
  encode(message: ActionVerificationOutgoingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== undefined && message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.method !== undefined && message.method !== 0) {
      writer.uint32(16).int32(message.method);
    }
    if (message.body !== undefined) {
      ActionVerificationOutgoingRequestBody.encode(message.body, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionVerificationOutgoingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionVerificationOutgoingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.method = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.body = ActionVerificationOutgoingRequestBody.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionVerificationOutgoingRequest {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      method: isSet(object.method) ? actionVerificationOutgoingRequestMethodFromJSON(object.method) : 0,
      body: isSet(object.body) ? ActionVerificationOutgoingRequestBody.fromJSON(object.body) : undefined,
    };
  },

  toJSON(message: ActionVerificationOutgoingRequest): unknown {
    const obj: any = {};
    if (message.url !== undefined && message.url !== "") {
      obj.url = message.url;
    }
    if (message.method !== undefined && message.method !== 0) {
      obj.method = actionVerificationOutgoingRequestMethodToJSON(message.method);
    }
    if (message.body !== undefined) {
      obj.body = ActionVerificationOutgoingRequestBody.toJSON(message.body);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionVerificationOutgoingRequest>, I>>(
    base?: I,
  ): ActionVerificationOutgoingRequest {
    return ActionVerificationOutgoingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionVerificationOutgoingRequest>, I>>(
    object: I,
  ): ActionVerificationOutgoingRequest {
    const message = createBaseActionVerificationOutgoingRequest();
    message.url = object.url ?? "";
    message.method = object.method ?? 0;
    message.body = (object.body !== undefined && object.body !== null)
      ? ActionVerificationOutgoingRequestBody.fromPartial(object.body)
      : undefined;
    return message;
  },
};

function createBaseActionVerificationElementAssertion(): ActionVerificationElementAssertion {
  return { locator: undefined, exists: false, visible: false };
}

export const ActionVerificationElementAssertion = {
  encode(message: ActionVerificationElementAssertion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locator !== undefined) {
      ElementLocator.encode(message.locator, writer.uint32(10).fork()).ldelim();
    }
    if (message.exists !== undefined && message.exists !== false) {
      writer.uint32(16).bool(message.exists);
    }
    if (message.visible !== undefined && message.visible !== false) {
      writer.uint32(24).bool(message.visible);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionVerificationElementAssertion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionVerificationElementAssertion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.exists = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.visible = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionVerificationElementAssertion {
    return {
      locator: isSet(object.locator) ? ElementLocator.fromJSON(object.locator) : undefined,
      exists: isSet(object.exists) ? globalThis.Boolean(object.exists) : false,
      visible: isSet(object.visible) ? globalThis.Boolean(object.visible) : false,
    };
  },

  toJSON(message: ActionVerificationElementAssertion): unknown {
    const obj: any = {};
    if (message.locator !== undefined) {
      obj.locator = ElementLocator.toJSON(message.locator);
    }
    if (message.exists !== undefined && message.exists !== false) {
      obj.exists = message.exists;
    }
    if (message.visible !== undefined && message.visible !== false) {
      obj.visible = message.visible;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionVerificationElementAssertion>, I>>(
    base?: I,
  ): ActionVerificationElementAssertion {
    return ActionVerificationElementAssertion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionVerificationElementAssertion>, I>>(
    object: I,
  ): ActionVerificationElementAssertion {
    const message = createBaseActionVerificationElementAssertion();
    message.locator = (object.locator !== undefined && object.locator !== null)
      ? ElementLocator.fromPartial(object.locator)
      : undefined;
    message.exists = object.exists ?? false;
    message.visible = object.visible ?? false;
    return message;
  },
};

function createBaseActionVerificationEvaluateScript(): ActionVerificationEvaluateScript {
  return { script: "", context: 0 };
}

export const ActionVerificationEvaluateScript = {
  encode(message: ActionVerificationEvaluateScript, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.script !== undefined && message.script !== "") {
      writer.uint32(10).string(message.script);
    }
    if (message.context !== undefined && message.context !== 0) {
      writer.uint32(16).int32(message.context);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionVerificationEvaluateScript {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionVerificationEvaluateScript();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.script = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.context = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionVerificationEvaluateScript {
    return {
      script: isSet(object.script) ? globalThis.String(object.script) : "",
      context: isSet(object.context) ? actionVerificationEvaluateScriptContextFromJSON(object.context) : 0,
    };
  },

  toJSON(message: ActionVerificationEvaluateScript): unknown {
    const obj: any = {};
    if (message.script !== undefined && message.script !== "") {
      obj.script = message.script;
    }
    if (message.context !== undefined && message.context !== 0) {
      obj.context = actionVerificationEvaluateScriptContextToJSON(message.context);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionVerificationEvaluateScript>, I>>(
    base?: I,
  ): ActionVerificationEvaluateScript {
    return ActionVerificationEvaluateScript.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionVerificationEvaluateScript>, I>>(
    object: I,
  ): ActionVerificationEvaluateScript {
    const message = createBaseActionVerificationEvaluateScript();
    message.script = object.script ?? "";
    message.context = object.context ?? 0;
    return message;
  },
};

function createBaseActionGroup(): ActionGroup {
  return { description: "", preparedActions: [], snapshot: undefined, screenshot: undefined, uuid: "" };
}

export const ActionGroup = {
  encode(message: ActionGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.preparedActions !== undefined && message.preparedActions.length !== 0) {
      for (const v of message.preparedActions) {
        PreparedAction.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.snapshot !== undefined) {
      RecordedFile.encode(message.snapshot, writer.uint32(26).fork()).ldelim();
    }
    if (message.screenshot !== undefined) {
      RecordedFile.encode(message.screenshot, writer.uint32(34).fork()).ldelim();
    }
    if (message.uuid !== undefined && message.uuid !== "") {
      writer.uint32(42).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.preparedActions!.push(PreparedAction.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.snapshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.screenshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionGroup {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      preparedActions: globalThis.Array.isArray(object?.preparedActions)
        ? object.preparedActions.map((e: any) => PreparedAction.fromJSON(e))
        : [],
      snapshot: isSet(object.snapshot) ? RecordedFile.fromJSON(object.snapshot) : undefined,
      screenshot: isSet(object.screenshot) ? RecordedFile.fromJSON(object.screenshot) : undefined,
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
    };
  },

  toJSON(message: ActionGroup): unknown {
    const obj: any = {};
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.preparedActions?.length) {
      obj.preparedActions = message.preparedActions.map((e) => PreparedAction.toJSON(e));
    }
    if (message.snapshot !== undefined) {
      obj.snapshot = RecordedFile.toJSON(message.snapshot);
    }
    if (message.screenshot !== undefined) {
      obj.screenshot = RecordedFile.toJSON(message.screenshot);
    }
    if (message.uuid !== undefined && message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionGroup>, I>>(base?: I): ActionGroup {
    return ActionGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionGroup>, I>>(object: I): ActionGroup {
    const message = createBaseActionGroup();
    message.description = object.description ?? "";
    message.preparedActions = object.preparedActions?.map((e) => PreparedAction.fromPartial(e)) || [];
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? RecordedFile.fromPartial(object.snapshot)
      : undefined;
    message.screenshot = (object.screenshot !== undefined && object.screenshot !== null)
      ? RecordedFile.fromPartial(object.screenshot)
      : undefined;
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBasePartialReferenceValue(): PartialReferenceValue {
  return { referenceValue: "", referenceValueKey: "" };
}

export const PartialReferenceValue = {
  encode(message: PartialReferenceValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.referenceValue !== undefined && message.referenceValue !== "") {
      writer.uint32(10).string(message.referenceValue);
    }
    if (message.referenceValueKey !== undefined && message.referenceValueKey !== "") {
      writer.uint32(18).string(message.referenceValueKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PartialReferenceValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePartialReferenceValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.referenceValue = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.referenceValueKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PartialReferenceValue {
    return {
      referenceValue: isSet(object.referenceValue) ? globalThis.String(object.referenceValue) : "",
      referenceValueKey: isSet(object.referenceValueKey) ? globalThis.String(object.referenceValueKey) : "",
    };
  },

  toJSON(message: PartialReferenceValue): unknown {
    const obj: any = {};
    if (message.referenceValue !== undefined && message.referenceValue !== "") {
      obj.referenceValue = message.referenceValue;
    }
    if (message.referenceValueKey !== undefined && message.referenceValueKey !== "") {
      obj.referenceValueKey = message.referenceValueKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PartialReferenceValue>, I>>(base?: I): PartialReferenceValue {
    return PartialReferenceValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PartialReferenceValue>, I>>(object: I): PartialReferenceValue {
    const message = createBasePartialReferenceValue();
    message.referenceValue = object.referenceValue ?? "";
    message.referenceValueKey = object.referenceValueKey ?? "";
    return message;
  },
};

function createBaseActionParamValue(): ActionParamValue {
  return {
    jsonValue: undefined,
    referenceValue: undefined,
    envValue: undefined,
    contextValue: undefined,
    secretValue: undefined,
    partialReferenceValue: undefined,
  };
}

export const ActionParamValue = {
  encode(message: ActionParamValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jsonValue !== undefined) {
      writer.uint32(10).string(message.jsonValue);
    }
    if (message.referenceValue !== undefined) {
      writer.uint32(18).string(message.referenceValue);
    }
    if (message.envValue !== undefined) {
      writer.uint32(26).string(message.envValue);
    }
    if (message.contextValue !== undefined) {
      writer.uint32(32).int32(message.contextValue);
    }
    if (message.secretValue !== undefined) {
      writer.uint32(42).string(message.secretValue);
    }
    if (message.partialReferenceValue !== undefined) {
      PartialReferenceValue.encode(message.partialReferenceValue, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActionParamValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActionParamValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jsonValue = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.referenceValue = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.envValue = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.contextValue = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.secretValue = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.partialReferenceValue = PartialReferenceValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActionParamValue {
    return {
      jsonValue: isSet(object.jsonValue) ? globalThis.String(object.jsonValue) : undefined,
      referenceValue: isSet(object.referenceValue) ? globalThis.String(object.referenceValue) : undefined,
      envValue: isSet(object.envValue) ? globalThis.String(object.envValue) : undefined,
      contextValue: isSet(object.contextValue) ? contextValueFromJSON(object.contextValue) : undefined,
      secretValue: isSet(object.secretValue) ? globalThis.String(object.secretValue) : undefined,
      partialReferenceValue: isSet(object.partialReferenceValue)
        ? PartialReferenceValue.fromJSON(object.partialReferenceValue)
        : undefined,
    };
  },

  toJSON(message: ActionParamValue): unknown {
    const obj: any = {};
    if (message.jsonValue !== undefined) {
      obj.jsonValue = message.jsonValue;
    }
    if (message.referenceValue !== undefined) {
      obj.referenceValue = message.referenceValue;
    }
    if (message.envValue !== undefined) {
      obj.envValue = message.envValue;
    }
    if (message.contextValue !== undefined) {
      obj.contextValue = contextValueToJSON(message.contextValue);
    }
    if (message.secretValue !== undefined) {
      obj.secretValue = message.secretValue;
    }
    if (message.partialReferenceValue !== undefined) {
      obj.partialReferenceValue = PartialReferenceValue.toJSON(message.partialReferenceValue);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionParamValue>, I>>(base?: I): ActionParamValue {
    return ActionParamValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActionParamValue>, I>>(object: I): ActionParamValue {
    const message = createBaseActionParamValue();
    message.jsonValue = object.jsonValue ?? undefined;
    message.referenceValue = object.referenceValue ?? undefined;
    message.envValue = object.envValue ?? undefined;
    message.contextValue = object.contextValue ?? undefined;
    message.secretValue = object.secretValue ?? undefined;
    message.partialReferenceValue =
      (object.partialReferenceValue !== undefined && object.partialReferenceValue !== null)
        ? PartialReferenceValue.fromPartial(object.partialReferenceValue)
        : undefined;
    return message;
  },
};

function createBasePreparedAction(): PreparedAction {
  return {
    uuid: "",
    params: [],
    requiresReview: false,
    tabIndex: 0,
    gotoAction: undefined,
    clickAction: undefined,
    getFormAction: undefined,
    fillFormAction: undefined,
    extractFieldsAction: undefined,
    jsFunctionAction: undefined,
    validateAction: undefined,
    conditionAction: undefined,
    foreachAction: undefined,
    getListAction: undefined,
    getElementAction: undefined,
    flagKeywordsAction: undefined,
    detectDuplicateLineItemsAction: undefined,
    createTaskAction: undefined,
    reconcileItemsAction: undefined,
    hoverAction: undefined,
    exitAction: undefined,
    setValueAction: undefined,
    customSmartAction: undefined,
    getDocumentAction: undefined,
    generateTextAction: undefined,
    classifyAction: undefined,
  };
}

export const PreparedAction = {
  encode(message: PreparedAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== undefined && message.uuid !== "") {
      writer.uint32(18).string(message.uuid);
    }
    if (message.params !== undefined && message.params.length !== 0) {
      for (const v of message.params) {
        ActionParamValue.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.requiresReview !== undefined && message.requiresReview !== false) {
      writer.uint32(120).bool(message.requiresReview);
    }
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      writer.uint32(168).int32(message.tabIndex);
    }
    if (message.gotoAction !== undefined) {
      GotoAction.encode(message.gotoAction, writer.uint32(34).fork()).ldelim();
    }
    if (message.clickAction !== undefined) {
      ClickAction.encode(message.clickAction, writer.uint32(42).fork()).ldelim();
    }
    if (message.getFormAction !== undefined) {
      GetFormAction.encode(message.getFormAction, writer.uint32(50).fork()).ldelim();
    }
    if (message.fillFormAction !== undefined) {
      FillFormAction.encode(message.fillFormAction, writer.uint32(58).fork()).ldelim();
    }
    if (message.extractFieldsAction !== undefined) {
      ExtractFieldsAction.encode(message.extractFieldsAction, writer.uint32(66).fork()).ldelim();
    }
    if (message.jsFunctionAction !== undefined) {
      JsFunctionAction.encode(message.jsFunctionAction, writer.uint32(74).fork()).ldelim();
    }
    if (message.validateAction !== undefined) {
      ValidateAction.encode(message.validateAction, writer.uint32(82).fork()).ldelim();
    }
    if (message.conditionAction !== undefined) {
      ConditionAction.encode(message.conditionAction, writer.uint32(90).fork()).ldelim();
    }
    if (message.foreachAction !== undefined) {
      ForeachAction.encode(message.foreachAction, writer.uint32(98).fork()).ldelim();
    }
    if (message.getListAction !== undefined) {
      GetListAction.encode(message.getListAction, writer.uint32(106).fork()).ldelim();
    }
    if (message.getElementAction !== undefined) {
      GetElementAction.encode(message.getElementAction, writer.uint32(130).fork()).ldelim();
    }
    if (message.flagKeywordsAction !== undefined) {
      FlagKeywordsAction.encode(message.flagKeywordsAction, writer.uint32(138).fork()).ldelim();
    }
    if (message.detectDuplicateLineItemsAction !== undefined) {
      DetectDuplicateLineItemsAction.encode(message.detectDuplicateLineItemsAction, writer.uint32(146).fork()).ldelim();
    }
    if (message.createTaskAction !== undefined) {
      CreateTaskAction.encode(message.createTaskAction, writer.uint32(154).fork()).ldelim();
    }
    if (message.reconcileItemsAction !== undefined) {
      ReconcileItemsAction.encode(message.reconcileItemsAction, writer.uint32(162).fork()).ldelim();
    }
    if (message.hoverAction !== undefined) {
      HoverAction.encode(message.hoverAction, writer.uint32(178).fork()).ldelim();
    }
    if (message.exitAction !== undefined) {
      ExitAction.encode(message.exitAction, writer.uint32(186).fork()).ldelim();
    }
    if (message.setValueAction !== undefined) {
      SetValueAction.encode(message.setValueAction, writer.uint32(194).fork()).ldelim();
    }
    if (message.customSmartAction !== undefined) {
      CustomSmartAction.encode(message.customSmartAction, writer.uint32(202).fork()).ldelim();
    }
    if (message.getDocumentAction !== undefined) {
      GetDocumentAction.encode(message.getDocumentAction, writer.uint32(210).fork()).ldelim();
    }
    if (message.generateTextAction !== undefined) {
      GenerateTextAction.encode(message.generateTextAction, writer.uint32(218).fork()).ldelim();
    }
    if (message.classifyAction !== undefined) {
      ClassifyAction.encode(message.classifyAction, writer.uint32(226).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreparedAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreparedAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.params!.push(ActionParamValue.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.requiresReview = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.tabIndex = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.gotoAction = GotoAction.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clickAction = ClickAction.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.getFormAction = GetFormAction.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fillFormAction = FillFormAction.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extractFieldsAction = ExtractFieldsAction.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.jsFunctionAction = JsFunctionAction.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.validateAction = ValidateAction.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.conditionAction = ConditionAction.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.foreachAction = ForeachAction.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.getListAction = GetListAction.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.getElementAction = GetElementAction.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.flagKeywordsAction = FlagKeywordsAction.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.detectDuplicateLineItemsAction = DetectDuplicateLineItemsAction.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.createTaskAction = CreateTaskAction.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.reconcileItemsAction = ReconcileItemsAction.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.hoverAction = HoverAction.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.exitAction = ExitAction.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.setValueAction = SetValueAction.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.customSmartAction = CustomSmartAction.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.getDocumentAction = GetDocumentAction.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.generateTextAction = GenerateTextAction.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.classifyAction = ClassifyAction.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreparedAction {
    return {
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      params: globalThis.Array.isArray(object?.params)
        ? object.params.map((e: any) => ActionParamValue.fromJSON(e))
        : [],
      requiresReview: isSet(object.requiresReview) ? globalThis.Boolean(object.requiresReview) : false,
      tabIndex: isSet(object.tabIndex) ? globalThis.Number(object.tabIndex) : 0,
      gotoAction: isSet(object.gotoAction) ? GotoAction.fromJSON(object.gotoAction) : undefined,
      clickAction: isSet(object.clickAction) ? ClickAction.fromJSON(object.clickAction) : undefined,
      getFormAction: isSet(object.getFormAction) ? GetFormAction.fromJSON(object.getFormAction) : undefined,
      fillFormAction: isSet(object.fillFormAction) ? FillFormAction.fromJSON(object.fillFormAction) : undefined,
      extractFieldsAction: isSet(object.extractFieldsAction)
        ? ExtractFieldsAction.fromJSON(object.extractFieldsAction)
        : undefined,
      jsFunctionAction: isSet(object.jsFunctionAction) ? JsFunctionAction.fromJSON(object.jsFunctionAction) : undefined,
      validateAction: isSet(object.validateAction) ? ValidateAction.fromJSON(object.validateAction) : undefined,
      conditionAction: isSet(object.conditionAction) ? ConditionAction.fromJSON(object.conditionAction) : undefined,
      foreachAction: isSet(object.foreachAction) ? ForeachAction.fromJSON(object.foreachAction) : undefined,
      getListAction: isSet(object.getListAction) ? GetListAction.fromJSON(object.getListAction) : undefined,
      getElementAction: isSet(object.getElementAction) ? GetElementAction.fromJSON(object.getElementAction) : undefined,
      flagKeywordsAction: isSet(object.flagKeywordsAction)
        ? FlagKeywordsAction.fromJSON(object.flagKeywordsAction)
        : undefined,
      detectDuplicateLineItemsAction: isSet(object.detectDuplicateLineItemsAction)
        ? DetectDuplicateLineItemsAction.fromJSON(object.detectDuplicateLineItemsAction)
        : undefined,
      createTaskAction: isSet(object.createTaskAction) ? CreateTaskAction.fromJSON(object.createTaskAction) : undefined,
      reconcileItemsAction: isSet(object.reconcileItemsAction)
        ? ReconcileItemsAction.fromJSON(object.reconcileItemsAction)
        : undefined,
      hoverAction: isSet(object.hoverAction) ? HoverAction.fromJSON(object.hoverAction) : undefined,
      exitAction: isSet(object.exitAction) ? ExitAction.fromJSON(object.exitAction) : undefined,
      setValueAction: isSet(object.setValueAction) ? SetValueAction.fromJSON(object.setValueAction) : undefined,
      customSmartAction: isSet(object.customSmartAction)
        ? CustomSmartAction.fromJSON(object.customSmartAction)
        : undefined,
      getDocumentAction: isSet(object.getDocumentAction)
        ? GetDocumentAction.fromJSON(object.getDocumentAction)
        : undefined,
      generateTextAction: isSet(object.generateTextAction)
        ? GenerateTextAction.fromJSON(object.generateTextAction)
        : undefined,
      classifyAction: isSet(object.classifyAction) ? ClassifyAction.fromJSON(object.classifyAction) : undefined,
    };
  },

  toJSON(message: PreparedAction): unknown {
    const obj: any = {};
    if (message.uuid !== undefined && message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.params?.length) {
      obj.params = message.params.map((e) => ActionParamValue.toJSON(e));
    }
    if (message.requiresReview !== undefined && message.requiresReview !== false) {
      obj.requiresReview = message.requiresReview;
    }
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      obj.tabIndex = Math.round(message.tabIndex);
    }
    if (message.gotoAction !== undefined) {
      obj.gotoAction = GotoAction.toJSON(message.gotoAction);
    }
    if (message.clickAction !== undefined) {
      obj.clickAction = ClickAction.toJSON(message.clickAction);
    }
    if (message.getFormAction !== undefined) {
      obj.getFormAction = GetFormAction.toJSON(message.getFormAction);
    }
    if (message.fillFormAction !== undefined) {
      obj.fillFormAction = FillFormAction.toJSON(message.fillFormAction);
    }
    if (message.extractFieldsAction !== undefined) {
      obj.extractFieldsAction = ExtractFieldsAction.toJSON(message.extractFieldsAction);
    }
    if (message.jsFunctionAction !== undefined) {
      obj.jsFunctionAction = JsFunctionAction.toJSON(message.jsFunctionAction);
    }
    if (message.validateAction !== undefined) {
      obj.validateAction = ValidateAction.toJSON(message.validateAction);
    }
    if (message.conditionAction !== undefined) {
      obj.conditionAction = ConditionAction.toJSON(message.conditionAction);
    }
    if (message.foreachAction !== undefined) {
      obj.foreachAction = ForeachAction.toJSON(message.foreachAction);
    }
    if (message.getListAction !== undefined) {
      obj.getListAction = GetListAction.toJSON(message.getListAction);
    }
    if (message.getElementAction !== undefined) {
      obj.getElementAction = GetElementAction.toJSON(message.getElementAction);
    }
    if (message.flagKeywordsAction !== undefined) {
      obj.flagKeywordsAction = FlagKeywordsAction.toJSON(message.flagKeywordsAction);
    }
    if (message.detectDuplicateLineItemsAction !== undefined) {
      obj.detectDuplicateLineItemsAction = DetectDuplicateLineItemsAction.toJSON(
        message.detectDuplicateLineItemsAction,
      );
    }
    if (message.createTaskAction !== undefined) {
      obj.createTaskAction = CreateTaskAction.toJSON(message.createTaskAction);
    }
    if (message.reconcileItemsAction !== undefined) {
      obj.reconcileItemsAction = ReconcileItemsAction.toJSON(message.reconcileItemsAction);
    }
    if (message.hoverAction !== undefined) {
      obj.hoverAction = HoverAction.toJSON(message.hoverAction);
    }
    if (message.exitAction !== undefined) {
      obj.exitAction = ExitAction.toJSON(message.exitAction);
    }
    if (message.setValueAction !== undefined) {
      obj.setValueAction = SetValueAction.toJSON(message.setValueAction);
    }
    if (message.customSmartAction !== undefined) {
      obj.customSmartAction = CustomSmartAction.toJSON(message.customSmartAction);
    }
    if (message.getDocumentAction !== undefined) {
      obj.getDocumentAction = GetDocumentAction.toJSON(message.getDocumentAction);
    }
    if (message.generateTextAction !== undefined) {
      obj.generateTextAction = GenerateTextAction.toJSON(message.generateTextAction);
    }
    if (message.classifyAction !== undefined) {
      obj.classifyAction = ClassifyAction.toJSON(message.classifyAction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreparedAction>, I>>(base?: I): PreparedAction {
    return PreparedAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreparedAction>, I>>(object: I): PreparedAction {
    const message = createBasePreparedAction();
    message.uuid = object.uuid ?? "";
    message.params = object.params?.map((e) => ActionParamValue.fromPartial(e)) || [];
    message.requiresReview = object.requiresReview ?? false;
    message.tabIndex = object.tabIndex ?? 0;
    message.gotoAction = (object.gotoAction !== undefined && object.gotoAction !== null)
      ? GotoAction.fromPartial(object.gotoAction)
      : undefined;
    message.clickAction = (object.clickAction !== undefined && object.clickAction !== null)
      ? ClickAction.fromPartial(object.clickAction)
      : undefined;
    message.getFormAction = (object.getFormAction !== undefined && object.getFormAction !== null)
      ? GetFormAction.fromPartial(object.getFormAction)
      : undefined;
    message.fillFormAction = (object.fillFormAction !== undefined && object.fillFormAction !== null)
      ? FillFormAction.fromPartial(object.fillFormAction)
      : undefined;
    message.extractFieldsAction = (object.extractFieldsAction !== undefined && object.extractFieldsAction !== null)
      ? ExtractFieldsAction.fromPartial(object.extractFieldsAction)
      : undefined;
    message.jsFunctionAction = (object.jsFunctionAction !== undefined && object.jsFunctionAction !== null)
      ? JsFunctionAction.fromPartial(object.jsFunctionAction)
      : undefined;
    message.validateAction = (object.validateAction !== undefined && object.validateAction !== null)
      ? ValidateAction.fromPartial(object.validateAction)
      : undefined;
    message.conditionAction = (object.conditionAction !== undefined && object.conditionAction !== null)
      ? ConditionAction.fromPartial(object.conditionAction)
      : undefined;
    message.foreachAction = (object.foreachAction !== undefined && object.foreachAction !== null)
      ? ForeachAction.fromPartial(object.foreachAction)
      : undefined;
    message.getListAction = (object.getListAction !== undefined && object.getListAction !== null)
      ? GetListAction.fromPartial(object.getListAction)
      : undefined;
    message.getElementAction = (object.getElementAction !== undefined && object.getElementAction !== null)
      ? GetElementAction.fromPartial(object.getElementAction)
      : undefined;
    message.flagKeywordsAction = (object.flagKeywordsAction !== undefined && object.flagKeywordsAction !== null)
      ? FlagKeywordsAction.fromPartial(object.flagKeywordsAction)
      : undefined;
    message.detectDuplicateLineItemsAction =
      (object.detectDuplicateLineItemsAction !== undefined && object.detectDuplicateLineItemsAction !== null)
        ? DetectDuplicateLineItemsAction.fromPartial(object.detectDuplicateLineItemsAction)
        : undefined;
    message.createTaskAction = (object.createTaskAction !== undefined && object.createTaskAction !== null)
      ? CreateTaskAction.fromPartial(object.createTaskAction)
      : undefined;
    message.reconcileItemsAction = (object.reconcileItemsAction !== undefined && object.reconcileItemsAction !== null)
      ? ReconcileItemsAction.fromPartial(object.reconcileItemsAction)
      : undefined;
    message.hoverAction = (object.hoverAction !== undefined && object.hoverAction !== null)
      ? HoverAction.fromPartial(object.hoverAction)
      : undefined;
    message.exitAction = (object.exitAction !== undefined && object.exitAction !== null)
      ? ExitAction.fromPartial(object.exitAction)
      : undefined;
    message.setValueAction = (object.setValueAction !== undefined && object.setValueAction !== null)
      ? SetValueAction.fromPartial(object.setValueAction)
      : undefined;
    message.customSmartAction = (object.customSmartAction !== undefined && object.customSmartAction !== null)
      ? CustomSmartAction.fromPartial(object.customSmartAction)
      : undefined;
    message.getDocumentAction = (object.getDocumentAction !== undefined && object.getDocumentAction !== null)
      ? GetDocumentAction.fromPartial(object.getDocumentAction)
      : undefined;
    message.generateTextAction = (object.generateTextAction !== undefined && object.generateTextAction !== null)
      ? GenerateTextAction.fromPartial(object.generateTextAction)
      : undefined;
    message.classifyAction = (object.classifyAction !== undefined && object.classifyAction !== null)
      ? ClassifyAction.fromPartial(object.classifyAction)
      : undefined;
    return message;
  },
};

function createBaseRecordedFile(): RecordedFile {
  return { id: "", url: "" };
}

export const RecordedFile = {
  encode(message: RecordedFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.url !== undefined && message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordedFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordedFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordedFile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: RecordedFile): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.url !== undefined && message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordedFile>, I>>(base?: I): RecordedFile {
    return RecordedFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordedFile>, I>>(object: I): RecordedFile {
    const message = createBaseRecordedFile();
    message.id = object.id ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseExecutedAction(): ExecutedAction {
  return {
    preparedActionUuid: "",
    paramValues: [],
    outputValue: "",
    predictedOutputValue: "",
    humanReviewTriggered: false,
    humanReviewTimeInMs: 0,
    numProcessedPages: 0,
    screenshot: undefined,
    snapshot: undefined,
    smartActionRequest: undefined,
    smartActionResponse: undefined,
    correctedSmartActionResponse: undefined,
    startTime: undefined,
    endTime: undefined,
  };
}

export const ExecutedAction = {
  encode(message: ExecutedAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preparedActionUuid !== undefined && message.preparedActionUuid !== "") {
      writer.uint32(10).string(message.preparedActionUuid);
    }
    if (message.paramValues !== undefined && message.paramValues.length !== 0) {
      for (const v of message.paramValues) {
        writer.uint32(26).string(v!);
      }
    }
    if (message.outputValue !== undefined && message.outputValue !== "") {
      writer.uint32(34).string(message.outputValue);
    }
    if (message.predictedOutputValue !== undefined && message.predictedOutputValue !== "") {
      writer.uint32(58).string(message.predictedOutputValue);
    }
    if (message.humanReviewTriggered !== undefined && message.humanReviewTriggered !== false) {
      writer.uint32(64).bool(message.humanReviewTriggered);
    }
    if (message.humanReviewTimeInMs !== undefined && message.humanReviewTimeInMs !== 0) {
      writer.uint32(72).int32(message.humanReviewTimeInMs);
    }
    if (message.numProcessedPages !== undefined && message.numProcessedPages !== 0) {
      writer.uint32(80).int32(message.numProcessedPages);
    }
    if (message.screenshot !== undefined) {
      RecordedFile.encode(message.screenshot, writer.uint32(42).fork()).ldelim();
    }
    if (message.snapshot !== undefined) {
      RecordedFile.encode(message.snapshot, writer.uint32(50).fork()).ldelim();
    }
    if (message.smartActionRequest !== undefined) {
      ProcessSmartActionsRequest.encode(message.smartActionRequest, writer.uint32(90).fork()).ldelim();
    }
    if (message.smartActionResponse !== undefined) {
      ProcessSmartActionsResponse.encode(message.smartActionResponse, writer.uint32(114).fork()).ldelim();
    }
    if (message.correctedSmartActionResponse !== undefined) {
      ProcessSmartActionsResponse.encode(message.correctedSmartActionResponse, writer.uint32(122).fork()).ldelim();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutedAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutedAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preparedActionUuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.paramValues!.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.outputValue = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.predictedOutputValue = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.humanReviewTriggered = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.humanReviewTimeInMs = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.numProcessedPages = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.screenshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.snapshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.smartActionRequest = ProcessSmartActionsRequest.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.smartActionResponse = ProcessSmartActionsResponse.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.correctedSmartActionResponse = ProcessSmartActionsResponse.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutedAction {
    return {
      preparedActionUuid: isSet(object.preparedActionUuid) ? globalThis.String(object.preparedActionUuid) : "",
      paramValues: globalThis.Array.isArray(object?.paramValues)
        ? object.paramValues.map((e: any) => globalThis.String(e))
        : [],
      outputValue: isSet(object.outputValue) ? globalThis.String(object.outputValue) : "",
      predictedOutputValue: isSet(object.predictedOutputValue) ? globalThis.String(object.predictedOutputValue) : "",
      humanReviewTriggered: isSet(object.humanReviewTriggered)
        ? globalThis.Boolean(object.humanReviewTriggered)
        : false,
      humanReviewTimeInMs: isSet(object.humanReviewTimeInMs) ? globalThis.Number(object.humanReviewTimeInMs) : 0,
      numProcessedPages: isSet(object.numProcessedPages) ? globalThis.Number(object.numProcessedPages) : 0,
      screenshot: isSet(object.screenshot) ? RecordedFile.fromJSON(object.screenshot) : undefined,
      snapshot: isSet(object.snapshot) ? RecordedFile.fromJSON(object.snapshot) : undefined,
      smartActionRequest: isSet(object.smartActionRequest)
        ? ProcessSmartActionsRequest.fromJSON(object.smartActionRequest)
        : undefined,
      smartActionResponse: isSet(object.smartActionResponse)
        ? ProcessSmartActionsResponse.fromJSON(object.smartActionResponse)
        : undefined,
      correctedSmartActionResponse: isSet(object.correctedSmartActionResponse)
        ? ProcessSmartActionsResponse.fromJSON(object.correctedSmartActionResponse)
        : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
    };
  },

  toJSON(message: ExecutedAction): unknown {
    const obj: any = {};
    if (message.preparedActionUuid !== undefined && message.preparedActionUuid !== "") {
      obj.preparedActionUuid = message.preparedActionUuid;
    }
    if (message.paramValues?.length) {
      obj.paramValues = message.paramValues;
    }
    if (message.outputValue !== undefined && message.outputValue !== "") {
      obj.outputValue = message.outputValue;
    }
    if (message.predictedOutputValue !== undefined && message.predictedOutputValue !== "") {
      obj.predictedOutputValue = message.predictedOutputValue;
    }
    if (message.humanReviewTriggered !== undefined && message.humanReviewTriggered !== false) {
      obj.humanReviewTriggered = message.humanReviewTriggered;
    }
    if (message.humanReviewTimeInMs !== undefined && message.humanReviewTimeInMs !== 0) {
      obj.humanReviewTimeInMs = Math.round(message.humanReviewTimeInMs);
    }
    if (message.numProcessedPages !== undefined && message.numProcessedPages !== 0) {
      obj.numProcessedPages = Math.round(message.numProcessedPages);
    }
    if (message.screenshot !== undefined) {
      obj.screenshot = RecordedFile.toJSON(message.screenshot);
    }
    if (message.snapshot !== undefined) {
      obj.snapshot = RecordedFile.toJSON(message.snapshot);
    }
    if (message.smartActionRequest !== undefined) {
      obj.smartActionRequest = ProcessSmartActionsRequest.toJSON(message.smartActionRequest);
    }
    if (message.smartActionResponse !== undefined) {
      obj.smartActionResponse = ProcessSmartActionsResponse.toJSON(message.smartActionResponse);
    }
    if (message.correctedSmartActionResponse !== undefined) {
      obj.correctedSmartActionResponse = ProcessSmartActionsResponse.toJSON(message.correctedSmartActionResponse);
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutedAction>, I>>(base?: I): ExecutedAction {
    return ExecutedAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExecutedAction>, I>>(object: I): ExecutedAction {
    const message = createBaseExecutedAction();
    message.preparedActionUuid = object.preparedActionUuid ?? "";
    message.paramValues = object.paramValues?.map((e) => e) || [];
    message.outputValue = object.outputValue ?? "";
    message.predictedOutputValue = object.predictedOutputValue ?? "";
    message.humanReviewTriggered = object.humanReviewTriggered ?? false;
    message.humanReviewTimeInMs = object.humanReviewTimeInMs ?? 0;
    message.numProcessedPages = object.numProcessedPages ?? 0;
    message.screenshot = (object.screenshot !== undefined && object.screenshot !== null)
      ? RecordedFile.fromPartial(object.screenshot)
      : undefined;
    message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
      ? RecordedFile.fromPartial(object.snapshot)
      : undefined;
    message.smartActionRequest = (object.smartActionRequest !== undefined && object.smartActionRequest !== null)
      ? ProcessSmartActionsRequest.fromPartial(object.smartActionRequest)
      : undefined;
    message.smartActionResponse = (object.smartActionResponse !== undefined && object.smartActionResponse !== null)
      ? ProcessSmartActionsResponse.fromPartial(object.smartActionResponse)
      : undefined;
    message.correctedSmartActionResponse =
      (object.correctedSmartActionResponse !== undefined && object.correctedSmartActionResponse !== null)
        ? ProcessSmartActionsResponse.fromPartial(object.correctedSmartActionResponse)
        : undefined;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    return message;
  },
};

function createBaseGotoAction(): GotoAction {
  return { url: undefined };
}

export const GotoAction = {
  encode(message: GotoAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== undefined) {
      ActionParamValue.encode(message.url, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GotoAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGotoAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GotoAction {
    return { url: isSet(object.url) ? ActionParamValue.fromJSON(object.url) : undefined };
  },

  toJSON(message: GotoAction): unknown {
    const obj: any = {};
    if (message.url !== undefined) {
      obj.url = ActionParamValue.toJSON(message.url);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GotoAction>, I>>(base?: I): GotoAction {
    return GotoAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GotoAction>, I>>(object: I): GotoAction {
    const message = createBaseGotoAction();
    message.url = (object.url !== undefined && object.url !== null)
      ? ActionParamValue.fromPartial(object.url)
      : undefined;
    return message;
  },
};

function createBaseClickAction(): ClickAction {
  return { elementLocator: undefined, isDoubleClick: false, type: 0, locator: undefined };
}

export const ClickAction = {
  encode(message: ClickAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementLocator !== undefined) {
      ElementLocator.encode(message.elementLocator, writer.uint32(10).fork()).ldelim();
    }
    if (message.isDoubleClick !== undefined && message.isDoubleClick !== false) {
      writer.uint32(16).bool(message.isDoubleClick);
    }
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.locator !== undefined) {
      ActionParamValue.encode(message.locator, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClickAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClickAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elementLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isDoubleClick = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.locator = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClickAction {
    return {
      elementLocator: isSet(object.elementLocator) ? ElementLocator.fromJSON(object.elementLocator) : undefined,
      isDoubleClick: isSet(object.isDoubleClick) ? globalThis.Boolean(object.isDoubleClick) : false,
      type: isSet(object.type) ? clickTypeFromJSON(object.type) : 0,
      locator: isSet(object.locator) ? ActionParamValue.fromJSON(object.locator) : undefined,
    };
  },

  toJSON(message: ClickAction): unknown {
    const obj: any = {};
    if (message.elementLocator !== undefined) {
      obj.elementLocator = ElementLocator.toJSON(message.elementLocator);
    }
    if (message.isDoubleClick !== undefined && message.isDoubleClick !== false) {
      obj.isDoubleClick = message.isDoubleClick;
    }
    if (message.type !== undefined && message.type !== 0) {
      obj.type = clickTypeToJSON(message.type);
    }
    if (message.locator !== undefined) {
      obj.locator = ActionParamValue.toJSON(message.locator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClickAction>, I>>(base?: I): ClickAction {
    return ClickAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClickAction>, I>>(object: I): ClickAction {
    const message = createBaseClickAction();
    message.elementLocator = (object.elementLocator !== undefined && object.elementLocator !== null)
      ? ElementLocator.fromPartial(object.elementLocator)
      : undefined;
    message.isDoubleClick = object.isDoubleClick ?? false;
    message.type = object.type ?? 0;
    message.locator = (object.locator !== undefined && object.locator !== null)
      ? ActionParamValue.fromPartial(object.locator)
      : undefined;
    return message;
  },
};

function createBaseHoverAction(): HoverAction {
  return { elementLocator: undefined, locator: undefined };
}

export const HoverAction = {
  encode(message: HoverAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementLocator !== undefined) {
      ElementLocator.encode(message.elementLocator, writer.uint32(10).fork()).ldelim();
    }
    if (message.locator !== undefined) {
      ActionParamValue.encode(message.locator, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HoverAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHoverAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elementLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locator = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HoverAction {
    return {
      elementLocator: isSet(object.elementLocator) ? ElementLocator.fromJSON(object.elementLocator) : undefined,
      locator: isSet(object.locator) ? ActionParamValue.fromJSON(object.locator) : undefined,
    };
  },

  toJSON(message: HoverAction): unknown {
    const obj: any = {};
    if (message.elementLocator !== undefined) {
      obj.elementLocator = ElementLocator.toJSON(message.elementLocator);
    }
    if (message.locator !== undefined) {
      obj.locator = ActionParamValue.toJSON(message.locator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HoverAction>, I>>(base?: I): HoverAction {
    return HoverAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HoverAction>, I>>(object: I): HoverAction {
    const message = createBaseHoverAction();
    message.elementLocator = (object.elementLocator !== undefined && object.elementLocator !== null)
      ? ElementLocator.fromPartial(object.elementLocator)
      : undefined;
    message.locator = (object.locator !== undefined && object.locator !== null)
      ? ActionParamValue.fromPartial(object.locator)
      : undefined;
    return message;
  },
};

function createBaseGetElementAction(): GetElementAction {
  return { locator: undefined, elementLocator: undefined };
}

export const GetElementAction = {
  encode(message: GetElementAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locator !== undefined) {
      ElementLocator.encode(message.locator, writer.uint32(10).fork()).ldelim();
    }
    if (message.elementLocator !== undefined) {
      ActionParamValue.encode(message.elementLocator, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetElementAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetElementAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.elementLocator = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetElementAction {
    return {
      locator: isSet(object.locator) ? ElementLocator.fromJSON(object.locator) : undefined,
      elementLocator: isSet(object.elementLocator) ? ActionParamValue.fromJSON(object.elementLocator) : undefined,
    };
  },

  toJSON(message: GetElementAction): unknown {
    const obj: any = {};
    if (message.locator !== undefined) {
      obj.locator = ElementLocator.toJSON(message.locator);
    }
    if (message.elementLocator !== undefined) {
      obj.elementLocator = ActionParamValue.toJSON(message.elementLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetElementAction>, I>>(base?: I): GetElementAction {
    return GetElementAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetElementAction>, I>>(object: I): GetElementAction {
    const message = createBaseGetElementAction();
    message.locator = (object.locator !== undefined && object.locator !== null)
      ? ElementLocator.fromPartial(object.locator)
      : undefined;
    message.elementLocator = (object.elementLocator !== undefined && object.elementLocator !== null)
      ? ActionParamValue.fromPartial(object.elementLocator)
      : undefined;
    return message;
  },
};

function createBaseGetFormAction(): GetFormAction {
  return { formLocator: undefined };
}

export const GetFormAction = {
  encode(message: GetFormAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.formLocator !== undefined) {
      ElementLocator.encode(message.formLocator, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFormAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFormAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.formLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFormAction {
    return { formLocator: isSet(object.formLocator) ? ElementLocator.fromJSON(object.formLocator) : undefined };
  },

  toJSON(message: GetFormAction): unknown {
    const obj: any = {};
    if (message.formLocator !== undefined) {
      obj.formLocator = ElementLocator.toJSON(message.formLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFormAction>, I>>(base?: I): GetFormAction {
    return GetFormAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFormAction>, I>>(object: I): GetFormAction {
    const message = createBaseGetFormAction();
    message.formLocator = (object.formLocator !== undefined && object.formLocator !== null)
      ? ElementLocator.fromPartial(object.formLocator)
      : undefined;
    return message;
  },
};

function createBaseFillFormAction(): FillFormAction {
  return { formLocator: undefined, pressEnter: false };
}

export const FillFormAction = {
  encode(message: FillFormAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.formLocator !== undefined) {
      ElementLocator.encode(message.formLocator, writer.uint32(10).fork()).ldelim();
    }
    if (message.pressEnter !== undefined && message.pressEnter !== false) {
      writer.uint32(16).bool(message.pressEnter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillFormAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillFormAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.formLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pressEnter = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FillFormAction {
    return {
      formLocator: isSet(object.formLocator) ? ElementLocator.fromJSON(object.formLocator) : undefined,
      pressEnter: isSet(object.pressEnter) ? globalThis.Boolean(object.pressEnter) : false,
    };
  },

  toJSON(message: FillFormAction): unknown {
    const obj: any = {};
    if (message.formLocator !== undefined) {
      obj.formLocator = ElementLocator.toJSON(message.formLocator);
    }
    if (message.pressEnter !== undefined && message.pressEnter !== false) {
      obj.pressEnter = message.pressEnter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FillFormAction>, I>>(base?: I): FillFormAction {
    return FillFormAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FillFormAction>, I>>(object: I): FillFormAction {
    const message = createBaseFillFormAction();
    message.formLocator = (object.formLocator !== undefined && object.formLocator !== null)
      ? ElementLocator.fromPartial(object.formLocator)
      : undefined;
    message.pressEnter = object.pressEnter ?? false;
    return message;
  },
};

function createBaseSetValueAction(): SetValueAction {
  return { fieldLocator: undefined, fieldValue: undefined, pressEnter: false };
}

export const SetValueAction = {
  encode(message: SetValueAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldLocator !== undefined) {
      ActionParamValue.encode(message.fieldLocator, writer.uint32(10).fork()).ldelim();
    }
    if (message.fieldValue !== undefined) {
      ActionParamValue.encode(message.fieldValue, writer.uint32(18).fork()).ldelim();
    }
    if (message.pressEnter !== undefined && message.pressEnter !== false) {
      writer.uint32(24).bool(message.pressEnter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetValueAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetValueAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldLocator = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldValue = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pressEnter = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetValueAction {
    return {
      fieldLocator: isSet(object.fieldLocator) ? ActionParamValue.fromJSON(object.fieldLocator) : undefined,
      fieldValue: isSet(object.fieldValue) ? ActionParamValue.fromJSON(object.fieldValue) : undefined,
      pressEnter: isSet(object.pressEnter) ? globalThis.Boolean(object.pressEnter) : false,
    };
  },

  toJSON(message: SetValueAction): unknown {
    const obj: any = {};
    if (message.fieldLocator !== undefined) {
      obj.fieldLocator = ActionParamValue.toJSON(message.fieldLocator);
    }
    if (message.fieldValue !== undefined) {
      obj.fieldValue = ActionParamValue.toJSON(message.fieldValue);
    }
    if (message.pressEnter !== undefined && message.pressEnter !== false) {
      obj.pressEnter = message.pressEnter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetValueAction>, I>>(base?: I): SetValueAction {
    return SetValueAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetValueAction>, I>>(object: I): SetValueAction {
    const message = createBaseSetValueAction();
    message.fieldLocator = (object.fieldLocator !== undefined && object.fieldLocator !== null)
      ? ActionParamValue.fromPartial(object.fieldLocator)
      : undefined;
    message.fieldValue = (object.fieldValue !== undefined && object.fieldValue !== null)
      ? ActionParamValue.fromPartial(object.fieldValue)
      : undefined;
    message.pressEnter = object.pressEnter ?? false;
    return message;
  },
};

function createBaseExtractFieldsAction(): ExtractFieldsAction {
  return { document: undefined, fields: [] };
}

export const ExtractFieldsAction = {
  encode(message: ExtractFieldsAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.document !== undefined) {
      ActionParamValue.encode(message.document, writer.uint32(26).fork()).ldelim();
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        writer.uint32(34).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFieldsAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFieldsAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.document = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fields!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFieldsAction {
    return {
      document: isSet(object.document) ? ActionParamValue.fromJSON(object.document) : undefined,
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ExtractFieldsAction): unknown {
    const obj: any = {};
    if (message.document !== undefined) {
      obj.document = ActionParamValue.toJSON(message.document);
    }
    if (message.fields?.length) {
      obj.fields = message.fields;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFieldsAction>, I>>(base?: I): ExtractFieldsAction {
    return ExtractFieldsAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFieldsAction>, I>>(object: I): ExtractFieldsAction {
    const message = createBaseExtractFieldsAction();
    message.document = (object.document !== undefined && object.document !== null)
      ? ActionParamValue.fromPartial(object.document)
      : undefined;
    message.fields = object.fields?.map((e) => e) || [];
    return message;
  },
};

function createBaseValidateAction(): ValidateAction {
  return { source: undefined, target: undefined, rule: "", fields: [], metadata: {} };
}

export const ValidateAction = {
  encode(message: ValidateAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.source !== undefined) {
      ActionParamValue.encode(message.source, writer.uint32(50).fork()).ldelim();
    }
    if (message.target !== undefined) {
      ActionParamValue.encode(message.target, writer.uint32(58).fork()).ldelim();
    }
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(34).string(message.rule);
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        writer.uint32(42).string(v!);
      }
    }
    Object.entries(message.metadata || {}).forEach(([key, value]) => {
      ValidateActionMetadataEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          message.source = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.target = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rule = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fields!.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = ValidateActionMetadataEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.metadata![entry8.key] = entry8.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateAction {
    return {
      source: isSet(object.source) ? ActionParamValue.fromJSON(object.source) : undefined,
      target: isSet(object.target) ? ActionParamValue.fromJSON(object.target) : undefined,
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => globalThis.String(e)) : [],
      metadata: isObject(object.metadata)
        ? Object.entries(object.metadata).reduce<{ [key: string]: ActionParamValue }>((acc, [key, value]) => {
          acc[key] = ActionParamValue.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ValidateAction): unknown {
    const obj: any = {};
    if (message.source !== undefined) {
      obj.source = ActionParamValue.toJSON(message.source);
    }
    if (message.target !== undefined) {
      obj.target = ActionParamValue.toJSON(message.target);
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    if (message.fields?.length) {
      obj.fields = message.fields;
    }
    if (message.metadata) {
      const entries = Object.entries(message.metadata);
      if (entries.length > 0) {
        obj.metadata = {};
        entries.forEach(([k, v]) => {
          obj.metadata[k] = ActionParamValue.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateAction>, I>>(base?: I): ValidateAction {
    return ValidateAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateAction>, I>>(object: I): ValidateAction {
    const message = createBaseValidateAction();
    message.source = (object.source !== undefined && object.source !== null)
      ? ActionParamValue.fromPartial(object.source)
      : undefined;
    message.target = (object.target !== undefined && object.target !== null)
      ? ActionParamValue.fromPartial(object.target)
      : undefined;
    message.rule = object.rule ?? "";
    message.fields = object.fields?.map((e) => e) || [];
    message.metadata = Object.entries(object.metadata ?? {}).reduce<{ [key: string]: ActionParamValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ActionParamValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseValidateActionMetadataEntry(): ValidateActionMetadataEntry {
  return { key: "", value: undefined };
}

export const ValidateActionMetadataEntry = {
  encode(message: ValidateActionMetadataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ActionParamValue.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateActionMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateActionMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateActionMetadataEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ActionParamValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ValidateActionMetadataEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ActionParamValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateActionMetadataEntry>, I>>(base?: I): ValidateActionMetadataEntry {
    return ValidateActionMetadataEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateActionMetadataEntry>, I>>(object: I): ValidateActionMetadataEntry {
    const message = createBaseValidateActionMetadataEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ActionParamValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseJsFunctionAction(): JsFunctionAction {
  return { description: "", paramNames: [], params: [], body: "" };
}

export const JsFunctionAction = {
  encode(message: JsFunctionAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.paramNames !== undefined && message.paramNames.length !== 0) {
      for (const v of message.paramNames) {
        writer.uint32(26).string(v!);
      }
    }
    if (message.params !== undefined && message.params.length !== 0) {
      for (const v of message.params) {
        ActionParamValue.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.body !== undefined && message.body !== "") {
      writer.uint32(18).string(message.body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JsFunctionAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJsFunctionAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.paramNames!.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.params!.push(ActionParamValue.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.body = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JsFunctionAction {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      paramNames: globalThis.Array.isArray(object?.paramNames)
        ? object.paramNames.map((e: any) => globalThis.String(e))
        : [],
      params: globalThis.Array.isArray(object?.params)
        ? object.params.map((e: any) => ActionParamValue.fromJSON(e))
        : [],
      body: isSet(object.body) ? globalThis.String(object.body) : "",
    };
  },

  toJSON(message: JsFunctionAction): unknown {
    const obj: any = {};
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.paramNames?.length) {
      obj.paramNames = message.paramNames;
    }
    if (message.params?.length) {
      obj.params = message.params.map((e) => ActionParamValue.toJSON(e));
    }
    if (message.body !== undefined && message.body !== "") {
      obj.body = message.body;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JsFunctionAction>, I>>(base?: I): JsFunctionAction {
    return JsFunctionAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JsFunctionAction>, I>>(object: I): JsFunctionAction {
    const message = createBaseJsFunctionAction();
    message.description = object.description ?? "";
    message.paramNames = object.paramNames?.map((e) => e) || [];
    message.params = object.params?.map((e) => ActionParamValue.fromPartial(e)) || [];
    message.body = object.body ?? "";
    return message;
  },
};

function createBaseConditionAction(): ConditionAction {
  return { condition: undefined, trueActions: [], falseActions: [], thenActions: [], elseActions: [] };
}

export const ConditionAction = {
  encode(message: ConditionAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.condition !== undefined) {
      ActionParamValue.encode(message.condition, writer.uint32(34).fork()).ldelim();
    }
    if (message.trueActions !== undefined && message.trueActions.length !== 0) {
      for (const v of message.trueActions) {
        ActionGroup.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.falseActions !== undefined && message.falseActions.length !== 0) {
      for (const v of message.falseActions) {
        ActionGroup.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.thenActions !== undefined && message.thenActions.length !== 0) {
      for (const v of message.thenActions) {
        Action.encode(v!, writer.uint32(42).fork()).ldelim();
      }
    }
    if (message.elseActions !== undefined && message.elseActions.length !== 0) {
      for (const v of message.elseActions) {
        Action.encode(v!, writer.uint32(50).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConditionAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConditionAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.condition = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trueActions!.push(ActionGroup.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.falseActions!.push(ActionGroup.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.thenActions!.push(Action.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.elseActions!.push(Action.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConditionAction {
    return {
      condition: isSet(object.condition) ? ActionParamValue.fromJSON(object.condition) : undefined,
      trueActions: globalThis.Array.isArray(object?.trueActions)
        ? object.trueActions.map((e: any) => ActionGroup.fromJSON(e))
        : [],
      falseActions: globalThis.Array.isArray(object?.falseActions)
        ? object.falseActions.map((e: any) => ActionGroup.fromJSON(e))
        : [],
      thenActions: globalThis.Array.isArray(object?.thenActions)
        ? object.thenActions.map((e: any) => Action.fromJSON(e))
        : [],
      elseActions: globalThis.Array.isArray(object?.elseActions)
        ? object.elseActions.map((e: any) => Action.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ConditionAction): unknown {
    const obj: any = {};
    if (message.condition !== undefined) {
      obj.condition = ActionParamValue.toJSON(message.condition);
    }
    if (message.trueActions?.length) {
      obj.trueActions = message.trueActions.map((e) => ActionGroup.toJSON(e));
    }
    if (message.falseActions?.length) {
      obj.falseActions = message.falseActions.map((e) => ActionGroup.toJSON(e));
    }
    if (message.thenActions?.length) {
      obj.thenActions = message.thenActions.map((e) => Action.toJSON(e));
    }
    if (message.elseActions?.length) {
      obj.elseActions = message.elseActions.map((e) => Action.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConditionAction>, I>>(base?: I): ConditionAction {
    return ConditionAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConditionAction>, I>>(object: I): ConditionAction {
    const message = createBaseConditionAction();
    message.condition = (object.condition !== undefined && object.condition !== null)
      ? ActionParamValue.fromPartial(object.condition)
      : undefined;
    message.trueActions = object.trueActions?.map((e) => ActionGroup.fromPartial(e)) || [];
    message.falseActions = object.falseActions?.map((e) => ActionGroup.fromPartial(e)) || [];
    message.thenActions = object.thenActions?.map((e) => Action.fromPartial(e)) || [];
    message.elseActions = object.elseActions?.map((e) => Action.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetListAction(): GetListAction {
  return { listLocator: undefined };
}

export const GetListAction = {
  encode(message: GetListAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.listLocator !== undefined) {
      ElementLocator.encode(message.listLocator, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetListAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetListAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.listLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetListAction {
    return { listLocator: isSet(object.listLocator) ? ElementLocator.fromJSON(object.listLocator) : undefined };
  },

  toJSON(message: GetListAction): unknown {
    const obj: any = {};
    if (message.listLocator !== undefined) {
      obj.listLocator = ElementLocator.toJSON(message.listLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetListAction>, I>>(base?: I): GetListAction {
    return GetListAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetListAction>, I>>(object: I): GetListAction {
    const message = createBaseGetListAction();
    message.listLocator = (object.listLocator !== undefined && object.listLocator !== null)
      ? ElementLocator.fromPartial(object.listLocator)
      : undefined;
    return message;
  },
};

function createBaseForeachAction(): ForeachAction {
  return { items: undefined, actions: [], loopActions: [] };
}

export const ForeachAction = {
  encode(message: ForeachAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.items !== undefined) {
      ActionParamValue.encode(message.items, writer.uint32(34).fork()).ldelim();
    }
    if (message.actions !== undefined && message.actions.length !== 0) {
      for (const v of message.actions) {
        ActionGroup.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.loopActions !== undefined && message.loopActions.length !== 0) {
      for (const v of message.loopActions) {
        Action.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ForeachAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseForeachAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items = ActionParamValue.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions!.push(ActionGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.loopActions!.push(Action.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ForeachAction {
    return {
      items: isSet(object.items) ? ActionParamValue.fromJSON(object.items) : undefined,
      actions: globalThis.Array.isArray(object?.actions) ? object.actions.map((e: any) => ActionGroup.fromJSON(e)) : [],
      loopActions: globalThis.Array.isArray(object?.loopActions)
        ? object.loopActions.map((e: any) => Action.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ForeachAction): unknown {
    const obj: any = {};
    if (message.items !== undefined) {
      obj.items = ActionParamValue.toJSON(message.items);
    }
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => ActionGroup.toJSON(e));
    }
    if (message.loopActions?.length) {
      obj.loopActions = message.loopActions.map((e) => Action.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ForeachAction>, I>>(base?: I): ForeachAction {
    return ForeachAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ForeachAction>, I>>(object: I): ForeachAction {
    const message = createBaseForeachAction();
    message.items = (object.items !== undefined && object.items !== null)
      ? ActionParamValue.fromPartial(object.items)
      : undefined;
    message.actions = object.actions?.map((e) => ActionGroup.fromPartial(e)) || [];
    message.loopActions = object.loopActions?.map((e) => Action.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFlagKeywordsAction(): FlagKeywordsAction {
  return { keywords: [], fields: [], source: undefined };
}

export const FlagKeywordsAction = {
  encode(message: FlagKeywordsAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keywords !== undefined && message.keywords.length !== 0) {
      for (const v of message.keywords) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.source !== undefined) {
      ActionParamValue.encode(message.source, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywordsAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywordsAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keywords!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fields!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.source = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywordsAction {
    return {
      keywords: globalThis.Array.isArray(object?.keywords) ? object.keywords.map((e: any) => globalThis.String(e)) : [],
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => globalThis.String(e)) : [],
      source: isSet(object.source) ? ActionParamValue.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: FlagKeywordsAction): unknown {
    const obj: any = {};
    if (message.keywords?.length) {
      obj.keywords = message.keywords;
    }
    if (message.fields?.length) {
      obj.fields = message.fields;
    }
    if (message.source !== undefined) {
      obj.source = ActionParamValue.toJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywordsAction>, I>>(base?: I): FlagKeywordsAction {
    return FlagKeywordsAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywordsAction>, I>>(object: I): FlagKeywordsAction {
    const message = createBaseFlagKeywordsAction();
    message.keywords = object.keywords?.map((e) => e) || [];
    message.fields = object.fields?.map((e) => e) || [];
    message.source = (object.source !== undefined && object.source !== null)
      ? ActionParamValue.fromPartial(object.source)
      : undefined;
    return message;
  },
};

function createBaseDetectDuplicateLineItemsAction(): DetectDuplicateLineItemsAction {
  return { duplicates: [], source: undefined };
}

export const DetectDuplicateLineItemsAction = {
  encode(message: DetectDuplicateLineItemsAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.duplicates !== undefined && message.duplicates.length !== 0) {
      for (const v of message.duplicates) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.source !== undefined) {
      ActionParamValue.encode(message.source, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectDuplicateLineItemsAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectDuplicateLineItemsAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.duplicates!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.source = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectDuplicateLineItemsAction {
    return {
      duplicates: globalThis.Array.isArray(object?.duplicates)
        ? object.duplicates.map((e: any) => globalThis.String(e))
        : [],
      source: isSet(object.source) ? ActionParamValue.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: DetectDuplicateLineItemsAction): unknown {
    const obj: any = {};
    if (message.duplicates?.length) {
      obj.duplicates = message.duplicates;
    }
    if (message.source !== undefined) {
      obj.source = ActionParamValue.toJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectDuplicateLineItemsAction>, I>>(base?: I): DetectDuplicateLineItemsAction {
    return DetectDuplicateLineItemsAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectDuplicateLineItemsAction>, I>>(
    object: I,
  ): DetectDuplicateLineItemsAction {
    const message = createBaseDetectDuplicateLineItemsAction();
    message.duplicates = object.duplicates?.map((e) => e) || [];
    message.source = (object.source !== undefined && object.source !== null)
      ? ActionParamValue.fromPartial(object.source)
      : undefined;
    return message;
  },
};

function createBaseWorkflowVariable(): WorkflowVariable {
  return { key: "", value: "" };
}

export const WorkflowVariable = {
  encode(message: WorkflowVariable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined && message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowVariable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowVariable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowVariable {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: WorkflowVariable): unknown {
    const obj: any = {};
    if (message.key !== undefined && message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowVariable>, I>>(base?: I): WorkflowVariable {
    return WorkflowVariable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowVariable>, I>>(object: I): WorkflowVariable {
    const message = createBaseWorkflowVariable();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCreateTaskAction(): CreateTaskAction {
  return { workflowId: "", workflowVariables: [], processId: "" };
}

export const CreateTaskAction = {
  encode(message: CreateTaskAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.workflowId !== undefined && message.workflowId !== "") {
      writer.uint32(10).string(message.workflowId);
    }
    if (message.workflowVariables !== undefined && message.workflowVariables.length !== 0) {
      for (const v of message.workflowVariables) {
        ActionParamValue.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(26).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTaskAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTaskAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workflowId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workflowVariables!.push(ActionParamValue.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTaskAction {
    return {
      workflowId: isSet(object.workflowId) ? globalThis.String(object.workflowId) : "",
      workflowVariables: globalThis.Array.isArray(object?.workflowVariables)
        ? object.workflowVariables.map((e: any) => ActionParamValue.fromJSON(e))
        : [],
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: CreateTaskAction): unknown {
    const obj: any = {};
    if (message.workflowId !== undefined && message.workflowId !== "") {
      obj.workflowId = message.workflowId;
    }
    if (message.workflowVariables?.length) {
      obj.workflowVariables = message.workflowVariables.map((e) => ActionParamValue.toJSON(e));
    }
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTaskAction>, I>>(base?: I): CreateTaskAction {
    return CreateTaskAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTaskAction>, I>>(object: I): CreateTaskAction {
    const message = createBaseCreateTaskAction();
    message.workflowId = object.workflowId ?? "";
    message.workflowVariables = object.workflowVariables?.map((e) => ActionParamValue.fromPartial(e)) || [];
    message.processId = object.processId ?? "";
    return message;
  },
};

function createBaseReconcileItemsAction(): ReconcileItemsAction {
  return { items: [] };
}

export const ReconcileItemsAction = {
  encode(message: ReconcileItemsAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.items !== undefined && message.items.length !== 0) {
      for (const v of message.items) {
        ReconcileItemsActionItemLocator.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItemsAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItemsAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items!.push(ReconcileItemsActionItemLocator.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItemsAction {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ReconcileItemsActionItemLocator.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReconcileItemsAction): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ReconcileItemsActionItemLocator.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItemsAction>, I>>(base?: I): ReconcileItemsAction {
    return ReconcileItemsAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItemsAction>, I>>(object: I): ReconcileItemsAction {
    const message = createBaseReconcileItemsAction();
    message.items = object.items?.map((e) => ReconcileItemsActionItemLocator.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReconcileItemsActionItemLocator(): ReconcileItemsActionItemLocator {
  return { tabIndex: 0, fieldGroups: undefined, documents: [] };
}

export const ReconcileItemsActionItemLocator = {
  encode(message: ReconcileItemsActionItemLocator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      writer.uint32(8).int32(message.tabIndex);
    }
    if (message.fieldGroups !== undefined) {
      ElementLocator.encode(message.fieldGroups, writer.uint32(18).fork()).ldelim();
    }
    if (message.documents !== undefined && message.documents.length !== 0) {
      for (const v of message.documents) {
        ElementLocator.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItemsActionItemLocator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItemsActionItemLocator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tabIndex = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldGroups = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.documents!.push(ElementLocator.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItemsActionItemLocator {
    return {
      tabIndex: isSet(object.tabIndex) ? globalThis.Number(object.tabIndex) : 0,
      fieldGroups: isSet(object.fieldGroups) ? ElementLocator.fromJSON(object.fieldGroups) : undefined,
      documents: globalThis.Array.isArray(object?.documents)
        ? object.documents.map((e: any) => ElementLocator.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReconcileItemsActionItemLocator): unknown {
    const obj: any = {};
    if (message.tabIndex !== undefined && message.tabIndex !== 0) {
      obj.tabIndex = Math.round(message.tabIndex);
    }
    if (message.fieldGroups !== undefined) {
      obj.fieldGroups = ElementLocator.toJSON(message.fieldGroups);
    }
    if (message.documents?.length) {
      obj.documents = message.documents.map((e) => ElementLocator.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItemsActionItemLocator>, I>>(base?: I): ReconcileItemsActionItemLocator {
    return ReconcileItemsActionItemLocator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItemsActionItemLocator>, I>>(
    object: I,
  ): ReconcileItemsActionItemLocator {
    const message = createBaseReconcileItemsActionItemLocator();
    message.tabIndex = object.tabIndex ?? 0;
    message.fieldGroups = (object.fieldGroups !== undefined && object.fieldGroups !== null)
      ? ElementLocator.fromPartial(object.fieldGroups)
      : undefined;
    message.documents = object.documents?.map((e) => ElementLocator.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateDataTableAction(): UpdateDataTableAction {
  return { tableLocator: undefined };
}

export const UpdateDataTableAction = {
  encode(message: UpdateDataTableAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tableLocator !== undefined) {
      ElementLocator.encode(message.tableLocator, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDataTableAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDataTableAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tableLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateDataTableAction {
    return { tableLocator: isSet(object.tableLocator) ? ElementLocator.fromJSON(object.tableLocator) : undefined };
  },

  toJSON(message: UpdateDataTableAction): unknown {
    const obj: any = {};
    if (message.tableLocator !== undefined) {
      obj.tableLocator = ElementLocator.toJSON(message.tableLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateDataTableAction>, I>>(base?: I): UpdateDataTableAction {
    return UpdateDataTableAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDataTableAction>, I>>(object: I): UpdateDataTableAction {
    const message = createBaseUpdateDataTableAction();
    message.tableLocator = (object.tableLocator !== undefined && object.tableLocator !== null)
      ? ElementLocator.fromPartial(object.tableLocator)
      : undefined;
    return message;
  },
};

function createBaseExitAction(): ExitAction {
  return { status: 0 };
}

export const ExitAction = {
  encode(message: ExitAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExitAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExitAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExitAction {
    return { status: isSet(object.status) ? exitActionExitStatusFromJSON(object.status) : 0 };
  },

  toJSON(message: ExitAction): unknown {
    const obj: any = {};
    if (message.status !== undefined && message.status !== 0) {
      obj.status = exitActionExitStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExitAction>, I>>(base?: I): ExitAction {
    return ExitAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExitAction>, I>>(object: I): ExitAction {
    const message = createBaseExitAction();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseCustomSmartAction(): CustomSmartAction {
  return { inputs: {}, rule: "" };
}

export const CustomSmartAction = {
  encode(message: CustomSmartAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.inputs || {}).forEach(([key, value]) => {
      CustomSmartActionInputsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(18).string(message.rule);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomSmartAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomSmartAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = CustomSmartActionInputsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.inputs![entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rule = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomSmartAction {
    return {
      inputs: isObject(object.inputs)
        ? Object.entries(object.inputs).reduce<{ [key: string]: ActionParamValue }>((acc, [key, value]) => {
          acc[key] = ActionParamValue.fromJSON(value);
          return acc;
        }, {})
        : {},
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
    };
  },

  toJSON(message: CustomSmartAction): unknown {
    const obj: any = {};
    if (message.inputs) {
      const entries = Object.entries(message.inputs);
      if (entries.length > 0) {
        obj.inputs = {};
        entries.forEach(([k, v]) => {
          obj.inputs[k] = ActionParamValue.toJSON(v);
        });
      }
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomSmartAction>, I>>(base?: I): CustomSmartAction {
    return CustomSmartAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomSmartAction>, I>>(object: I): CustomSmartAction {
    const message = createBaseCustomSmartAction();
    message.inputs = Object.entries(object.inputs ?? {}).reduce<{ [key: string]: ActionParamValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ActionParamValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.rule = object.rule ?? "";
    return message;
  },
};

function createBaseCustomSmartActionInputsEntry(): CustomSmartActionInputsEntry {
  return { key: "", value: undefined };
}

export const CustomSmartActionInputsEntry = {
  encode(message: CustomSmartActionInputsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ActionParamValue.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomSmartActionInputsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomSmartActionInputsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ActionParamValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomSmartActionInputsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ActionParamValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CustomSmartActionInputsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ActionParamValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomSmartActionInputsEntry>, I>>(base?: I): CustomSmartActionInputsEntry {
    return CustomSmartActionInputsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomSmartActionInputsEntry>, I>>(object: I): CustomSmartActionInputsEntry {
    const message = createBaseCustomSmartActionInputsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ActionParamValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetDocumentAction(): GetDocumentAction {
  return { documentLocator: undefined };
}

export const GetDocumentAction = {
  encode(message: GetDocumentAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.documentLocator !== undefined) {
      ElementLocator.encode(message.documentLocator, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDocumentAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDocumentAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.documentLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDocumentAction {
    return {
      documentLocator: isSet(object.documentLocator) ? ElementLocator.fromJSON(object.documentLocator) : undefined,
    };
  },

  toJSON(message: GetDocumentAction): unknown {
    const obj: any = {};
    if (message.documentLocator !== undefined) {
      obj.documentLocator = ElementLocator.toJSON(message.documentLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDocumentAction>, I>>(base?: I): GetDocumentAction {
    return GetDocumentAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDocumentAction>, I>>(object: I): GetDocumentAction {
    const message = createBaseGetDocumentAction();
    message.documentLocator = (object.documentLocator !== undefined && object.documentLocator !== null)
      ? ElementLocator.fromPartial(object.documentLocator)
      : undefined;
    return message;
  },
};

function createBaseGenerateTextAction(): GenerateTextAction {
  return { inputs: [], prompt: "" };
}

export const GenerateTextAction = {
  encode(message: GenerateTextAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inputs !== undefined && message.inputs.length !== 0) {
      for (const v of message.inputs) {
        ActionParamValue.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateTextAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateTextAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inputs!.push(ActionParamValue.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateTextAction {
    return {
      inputs: globalThis.Array.isArray(object?.inputs)
        ? object.inputs.map((e: any) => ActionParamValue.fromJSON(e))
        : [],
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
    };
  },

  toJSON(message: GenerateTextAction): unknown {
    const obj: any = {};
    if (message.inputs?.length) {
      obj.inputs = message.inputs.map((e) => ActionParamValue.toJSON(e));
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateTextAction>, I>>(base?: I): GenerateTextAction {
    return GenerateTextAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateTextAction>, I>>(object: I): GenerateTextAction {
    const message = createBaseGenerateTextAction();
    message.inputs = object.inputs?.map((e) => ActionParamValue.fromPartial(e)) || [];
    message.prompt = object.prompt ?? "";
    return message;
  },
};

function createBaseClassifyAction(): ClassifyAction {
  return { inputs: [], prompt: "", preset: 0 };
}

export const ClassifyAction = {
  encode(message: ClassifyAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inputs !== undefined && message.inputs.length !== 0) {
      for (const v of message.inputs) {
        ActionParamValue.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    if (message.preset !== undefined && message.preset !== 0) {
      writer.uint32(24).int32(message.preset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClassifyAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClassifyAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inputs!.push(ActionParamValue.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.preset = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClassifyAction {
    return {
      inputs: globalThis.Array.isArray(object?.inputs)
        ? object.inputs.map((e: any) => ActionParamValue.fromJSON(e))
        : [],
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
      preset: isSet(object.preset) ? classifyClassifyPresetFromJSON(object.preset) : 0,
    };
  },

  toJSON(message: ClassifyAction): unknown {
    const obj: any = {};
    if (message.inputs?.length) {
      obj.inputs = message.inputs.map((e) => ActionParamValue.toJSON(e));
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    if (message.preset !== undefined && message.preset !== 0) {
      obj.preset = classifyClassifyPresetToJSON(message.preset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClassifyAction>, I>>(base?: I): ClassifyAction {
    return ClassifyAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClassifyAction>, I>>(object: I): ClassifyAction {
    const message = createBaseClassifyAction();
    message.inputs = object.inputs?.map((e) => ActionParamValue.fromPartial(e)) || [];
    message.prompt = object.prompt ?? "";
    message.preset = object.preset ?? 0;
    return message;
  },
};

function createBaseScrollAction(): ScrollAction {
  return { scrollBy: undefined, scrollTo: undefined };
}

export const ScrollAction = {
  encode(message: ScrollAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scrollBy !== undefined) {
      ScrollActionScrollBy.encode(message.scrollBy, writer.uint32(10).fork()).ldelim();
    }
    if (message.scrollTo !== undefined) {
      ScrollActionScrollTo.encode(message.scrollTo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScrollAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScrollAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scrollBy = ScrollActionScrollBy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scrollTo = ScrollActionScrollTo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScrollAction {
    return {
      scrollBy: isSet(object.scrollBy) ? ScrollActionScrollBy.fromJSON(object.scrollBy) : undefined,
      scrollTo: isSet(object.scrollTo) ? ScrollActionScrollTo.fromJSON(object.scrollTo) : undefined,
    };
  },

  toJSON(message: ScrollAction): unknown {
    const obj: any = {};
    if (message.scrollBy !== undefined) {
      obj.scrollBy = ScrollActionScrollBy.toJSON(message.scrollBy);
    }
    if (message.scrollTo !== undefined) {
      obj.scrollTo = ScrollActionScrollTo.toJSON(message.scrollTo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScrollAction>, I>>(base?: I): ScrollAction {
    return ScrollAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScrollAction>, I>>(object: I): ScrollAction {
    const message = createBaseScrollAction();
    message.scrollBy = (object.scrollBy !== undefined && object.scrollBy !== null)
      ? ScrollActionScrollBy.fromPartial(object.scrollBy)
      : undefined;
    message.scrollTo = (object.scrollTo !== undefined && object.scrollTo !== null)
      ? ScrollActionScrollTo.fromPartial(object.scrollTo)
      : undefined;
    return message;
  },
};

function createBaseScrollActionScrollBy(): ScrollActionScrollBy {
  return { x: 0, y: 0 };
}

export const ScrollActionScrollBy = {
  encode(message: ScrollActionScrollBy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== undefined && message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== undefined && message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScrollActionScrollBy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScrollActionScrollBy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScrollActionScrollBy {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: ScrollActionScrollBy): unknown {
    const obj: any = {};
    if (message.x !== undefined && message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== undefined && message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScrollActionScrollBy>, I>>(base?: I): ScrollActionScrollBy {
    return ScrollActionScrollBy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScrollActionScrollBy>, I>>(object: I): ScrollActionScrollBy {
    const message = createBaseScrollActionScrollBy();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseScrollActionScrollTo(): ScrollActionScrollTo {
  return { position: undefined, preset: undefined };
}

export const ScrollActionScrollTo = {
  encode(message: ScrollActionScrollTo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== undefined) {
      ScrollActionScrollToPosition.encode(message.position, writer.uint32(10).fork()).ldelim();
    }
    if (message.preset !== undefined) {
      writer.uint32(16).int32(message.preset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScrollActionScrollTo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScrollActionScrollTo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.position = ScrollActionScrollToPosition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.preset = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScrollActionScrollTo {
    return {
      position: isSet(object.position) ? ScrollActionScrollToPosition.fromJSON(object.position) : undefined,
      preset: isSet(object.preset) ? scrollActionScrollToPresetPositionFromJSON(object.preset) : undefined,
    };
  },

  toJSON(message: ScrollActionScrollTo): unknown {
    const obj: any = {};
    if (message.position !== undefined) {
      obj.position = ScrollActionScrollToPosition.toJSON(message.position);
    }
    if (message.preset !== undefined) {
      obj.preset = scrollActionScrollToPresetPositionToJSON(message.preset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScrollActionScrollTo>, I>>(base?: I): ScrollActionScrollTo {
    return ScrollActionScrollTo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScrollActionScrollTo>, I>>(object: I): ScrollActionScrollTo {
    const message = createBaseScrollActionScrollTo();
    message.position = (object.position !== undefined && object.position !== null)
      ? ScrollActionScrollToPosition.fromPartial(object.position)
      : undefined;
    message.preset = object.preset ?? undefined;
    return message;
  },
};

function createBaseScrollActionScrollToPosition(): ScrollActionScrollToPosition {
  return { x: 0, y: 0 };
}

export const ScrollActionScrollToPosition = {
  encode(message: ScrollActionScrollToPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== undefined && message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== undefined && message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScrollActionScrollToPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScrollActionScrollToPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScrollActionScrollToPosition {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: ScrollActionScrollToPosition): unknown {
    const obj: any = {};
    if (message.x !== undefined && message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== undefined && message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScrollActionScrollToPosition>, I>>(base?: I): ScrollActionScrollToPosition {
    return ScrollActionScrollToPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScrollActionScrollToPosition>, I>>(object: I): ScrollActionScrollToPosition {
    const message = createBaseScrollActionScrollToPosition();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
