import React from 'react';
import ActionItem from './ActionItem';
import { getNumStepsForAction } from 'workflow-utils/src/v2/helper';
import { ActionsContext } from './ActionsContext';
import { ExecutedActionObject } from 'workflow-utils/src/types';
import { cumulativeSum } from 'workflow-utils/src/helper';
import { ActionObject } from 'workflow-utils/src/types';

interface IProps {
  actions: ActionObject[];
  executedActions: ExecutedActionObject[];
}

const TaskDetails: React.FC<IProps> = ({
  actions,
  executedActions,
}: IProps) => {
  const executionStartIndices = cumulativeSum(
    actions.map(getNumStepsForAction),
  );

  return (
    <>
      <ActionsContext.Provider value={{ actions, executedActions }}>
        {actions.map((action, index) => {
          return (
            <ActionItem
              key={index}
              action={action}
              startIndex={executionStartIndices[index]}
              executedActions={executedActions}
            />
          );
        })}
      </ActionsContext.Provider>
    </>
  );
};

export default TaskDetails;
