import { Box } from '@mui/material';
import OrbyTypography from '../../../typography/OrbyTypography';
import React from 'react';
import OrbyColorPalette from '../../../colors/ColorPalette';

interface OrbyPaginationProps {
  rowsPerPage: number;
  totalSize?: number;
  page: number;
  rows?: number[];
  justifyContent?: 'center' | 'end';
  onRowsPerPageChange?: (rows: number) => void;
  onPageChange: (page: number) => void;
  ariaLabel?: string;
  isShowSelectFilter?: boolean;
}

interface OrbyPageBoxProps {
  title: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  role?: React.AriaRole;
  ariaLabel?: string;
  component?: React.ElementType;
  bgColor: string;
  isIcon: boolean;
}

const OrbyPageBox: React.FC<OrbyPageBoxProps> = ({
  title,
  selected = false,
  onClick,
  disabled,
  role,
  ariaLabel,
  component,
  bgColor,
  isIcon,
}) => {
  return (
    <Box
      height={'32px'}
      bgcolor={bgColor}
      component={component}
      role={role}
      aria-label={ariaLabel}
      width={'32px'}
      border={`1.5px solid ${selected ? OrbyColorPalette['blue-700'] : OrbyColorPalette['grey-300']}`}
      borderRadius={'4px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      marginX={'4px'}
      // The reason for doing e.preventDefault is to disable the default behavior of the button
      // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e);
      }}
    >
      <Box display={'flex'} aria-hidden={true}>
        {!isIcon ? (
          <OrbyTypography
            color={
              selected
                ? OrbyColorPalette['blue-700']
                : disabled
                  ? OrbyColorPalette['grey-700']
                  : OrbyColorPalette['white-0']
            }
          >
            {title}
          </OrbyTypography>
        ) : (
          title
        )}
      </Box>
    </Box>
  );
};

export default React.memo(OrbyPageBox);
