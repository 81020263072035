import React, { FC, Suspense, memo, useEffect, useState } from 'react';
import CompletedTasks from './CompletedTasks/CompletedTasks';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  matchPath,
  useNavigate,
} from 'react-router-dom';
import { Box } from '@mui/material';
import GeneratedGraph from './GeneratedGraph/GeneratedGraph';
import Dashboard from './Dashboard/Dashboard';
import ReviewPage from './AutomationReviewPage/AutomationReviewPage';
import WebDrawerWrapper from '../components/web-drawer/WebDrawerWrapper';
import PendingTasks from './PendingTasks/PendingTasks';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../redux/selectors/user.selectors';
import { isAdmin } from '../utils/helpers';
import DeclinedTasks from './DeclinedTasks/DeclinedTasks';
import DebuggerReview from './DebuggerReview/DebuggerReview';
import IntegrationTest from './IntegrationTest/IntegrationTest';
import ListInvoicePage from './ERP/ListInvoicePage';
import CreateInvoice from './ERP/CreateInvoice';
import { useIsOrgChanged } from '../hooks/useIsOrgChanged';
import ScheduledTasks from './ScheduledTasks/ScheduledTasks';
import FeatureFlags from './FeatureFlags/FeatureFlags';
import FeatureFlagCreationPage from './FeatureFlags/FeatureFlagCreationPage';
import FeatureFlagUpdatePage from './FeatureFlags/FeatureFlagUpdatePage';
import { getFeatureFlagsForOrgAndUserSelector } from '../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from './FeatureFlags/FeatureFlagUtils';
import {
  FEATURE_FLAGS,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
} from '../utils/constants';
import Tasks from './Tasks/Tasks';
import ExecutionHistory from './ExecutionHistory/ExecutionHistory';
import Settings from './Settings/Settings';
import ConfigureSSO from './Settings/ConfigureSSO/ConfigureSSO';
import UiAutomationsDetail from './ExecutionHistory/tabs/ui-automations/details/UiAutomationsDetail';
import WorkflowDetails from './MyWorkflows/details/WorkflowDetails';
import Template from './MyWorkflows/details/Template';
import CreateWorkflowTemplate from './MyWorkflows/CreateWorkflow/CreateWorkflowTemplate';
import CreateConnector from './MyWorkflows/CreateConnector/CreateConnector';
import UpdateConnector from './MyWorkflows/CreateConnector/UpdateConnector';
import UpdateWorkflow from './MyWorkflows/CreateWorkflow/UpdateWorkflow';
import WorkflowHome from './MyWorkflows';
import CreateWorkflow from './MyWorkflows/CreateWorkflow/CreateWorkflow';
import TeamsHome from './Teams';
import TrashCan from './TrashCan';
import HITLDetails from './TasksPendingReview/HITLDetails';
import AnnouncementPopup from './Announcement/AnnouncementPopup';

const COLLAPSED_NAVIGATION_PATHNAMES = [
  'automation-review',
  'dev-testing/document',
];

// Define regular expressions for dynamic patterns
const COLLAPSE_NAVIGATION_DYNAMIC_PATHNAMES = [
  /^\/executions\/.*$/,
  /^\/workflow\/.*$/,
  /^\/workflows\/.*$/,
  /^\/dev-testing\/document\/.*$/,
];

interface Props {
  orbotWorkflowLength: number;
}

const OptionsConfig: FC<Props> = ({ orbotWorkflowLength }) => {
  const findIfCollapsedPath = () => {
    // Check if the pathname is in the static pathnames
    if (COLLAPSED_NAVIGATION_PATHNAMES.includes(location.pathname)) {
      return true;
    }

    // Check if the pathname matches any dynamic pattern using regular expressions
    return COLLAPSE_NAVIGATION_DYNAMIC_PATHNAMES.some((pattern) =>
      pattern.test(location.pathname),
    );
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(findIfCollapsedPath());

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  const user = useSelector(loggedInUserSelector);
  const isOrbyAIUser = (user?.email ?? '').endsWith('@orby.ai');
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const orgChangeInfo = useIsOrgChanged(selectedOrgInfo?.orgResourceName ?? '');
  const announcements = user?.announcements ?? [];
  let isTestUser = false;

  if (user?.email === process.env.REACT_APP_TEST_USER) {
    isTestUser = true;
  }

  const isOrbyAdmin =
    selectedOrgInfo?.orgDisplayName === 'Orby AI' &&
    isAdmin(selectedOrgInfo?.role);

  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);
  const userPermissions = useSelector(userPermissionsSelector);

  const canCreateWorkflow =
    isAdmin(selectedOrgInfo?.role) ||
    (isRbacEnabled && userPermissions.createWorkflow);

  const canListArchivedResources =
    isAdmin(selectedOrgInfo?.role) ||
    (isRbacEnabled && userPermissions.listArchivedResources);

  const canAccessConnectors =
    isAdmin(selectedOrgInfo?.role) ||
    (isRbacEnabled && userPermissions.listConnectors);

  const canAccessWorkflowPage =
    canCreateWorkflow ||
    orbotWorkflowLength ||
    (isRbacEnabled &&
      (userPermissions.listWorkflows || userPermissions.listConnectors));

  const canAccessExecutionPage =
    isAdmin(selectedOrgInfo?.role) ||
    orbotWorkflowLength ||
    (isRbacEnabled && userPermissions.listWorkflows);

  const canAccessTeamPage =
    isAdmin(selectedOrgInfo?.role) ||
    (isRbacEnabled && userPermissions.updateUser);

  useEffect(() => {
    setCollapse(findIfCollapsedPath());
  }, [location.pathname]);

  useEffect(() => {
    // Upon org change if user is on these paths redirect
    // them to their respective redirect url
    const pathRedirectMap = [
      {
        paths: [
          '/workflow/create',
          '/workflow/:workflow_id/update',
          '/workflow/connector/create',
          '/workflow/connector/:connector_id/update',
        ],
        redirect: '/workflow',
      },
      {
        paths: ['/settings/configure-sso/:organization_id'],
        redirect: '/settings',
      },
    ];

    // Function to check if the current location matches any of the given paths
    const shouldNavigate = (paths: string[]) =>
      paths.some((path) => matchPath(path, location.pathname) !== null);

    // Effect to handle organization change
    if (orgChangeInfo.isOrgChanged) {
      // Iterate over the pathRedirectMap to find the matching path and navigate to the respective URL if org changes
      pathRedirectMap.forEach(({ paths, redirect }) => {
        if (shouldNavigate(paths)) {
          navigate(redirect);
        }
      });
    }
  }, [orgChangeInfo]);

  return (
    <Box height={'100%'}>
      <title>`&quot;Login | Orby-UI&quot;</title>
      <Box height={'100%'} display={'flex'}>
        <WebDrawerWrapper
          collapse={collapse}
          setCollapse={setCollapse}
          orbotWorkflowLength={orbotWorkflowLength}
        />
        <AnnouncementPopup announcements={announcements}></AnnouncementPopup>
        <Box
          id={'main-content'}
          sx={{
            width: `calc(100% - ${collapse ? `${DRAWER_WIDTH_COLLAPSED}px` : `${DRAWER_WIDTH_EXPANDED}px`})`,
            marginLeft: collapse
              ? `${DRAWER_WIDTH_COLLAPSED}px`
              : `${DRAWER_WIDTH_EXPANDED}px`,
            transition: 'width 0.3s ease, margin-left 0.3s ease',
          }}
        >
          <Suspense fallback={<span>Loading</span>}>
            <Routes>
              <Route path='/' element={<Navigate to={'dashboard'} />} />
              <Route path='/erp/list' element={<ListInvoicePage />} />
              <Route path='/erp/create-invoice' element={<CreateInvoice />} />
              {/* --- UPDATE ON src/components/web-drawer/WebDrawerWrapper.tsx too --- */}
              <Route path='/pending-tasks/*' element={<PendingTasks />} />
              {isWebAppIntegrationEnabled && (
                <>
                  <Route
                    path='/tasks/*'
                    element={<Tasks sideDrawerCollapse={collapse} />}
                  />

                  {canAccessExecutionPage && (
                    <>
                      <Route
                        path='/executions'
                        element={
                          <ExecutionHistory sideDrawerCollapse={collapse} />
                        }
                      />

                      <Route
                        path='/executions/:workflow_task_id'
                        element={<UiAutomationsDetail />}
                      />
                    </>
                  )}

                  {canCreateWorkflow && (
                    <Route
                      path='/workflow/template'
                      element={<CreateWorkflowTemplate />}
                    />
                  )}
                </>
              )}
              <Route path='/completed-tasks/*' element={<CompletedTasks />} />
              <Route path='/dashboard/*' element={<Dashboard />} />
              <Route path='/generated-graph/*' element={<GeneratedGraph />} />
              <Route
                path='/workflows/:workflow_id/tasks/:task_id/automation-review/*'
                element={<ReviewPage sideDrawerCollapse={collapse} />}
              />
              <Route path='/declined-tasks' element={<DeclinedTasks />} />

              {/* JLL DETAILS PAGE */}
              <Route
                path='/hitl-details'
                element={<HITLDetails sideDrawerCollapse={collapse} />}
              />

              {isOrbyAIUser && (
                <Route path='/dev-testing' element={<DebuggerReview />} />
              )}
              {isOrbyAIUser && (
                <Route
                  path='/dev-testing/document/:file_name'
                  element={
                    <ReviewPage debug={true} sideDrawerCollapse={collapse} />
                  }
                />
              )}
              {isTestUser && (
                <Route path='/integration-test' element={<IntegrationTest />} />
              )}
              {canAccessWorkflowPage && (
                <>
                  <Route
                    path='/workflow'
                    element={<WorkflowHome sideDrawerCollapse={collapse} />}
                  />
                  <Route
                    path='/workflow/:workflow_id/definition'
                    element={<WorkflowDetails />}
                  />
                  <Route
                    path='/workflow/:workflow_id/update'
                    element={<UpdateWorkflow />}
                  />
                  <Route
                    path='/workflow/:workflow_id/settings'
                    element={<WorkflowDetails />}
                  />
                </>
              )}
              {canListArchivedResources && (
                <Route
                  path='/deleted-items'
                  element={<TrashCan sideDrawerCollapse={collapse} />}
                />
              )}
              {canCreateWorkflow && (
                <>
                  <Route path='/workflow/create' element={<CreateWorkflow />} />
                  <Route
                    path='/template/:template_id/definition'
                    element={<Template />}
                  />
                </>
              )}

              {canAccessConnectors && (
                <>
                  <Route
                    path='/workflow/connector/create'
                    element={<CreateConnector />}
                  />
                  <Route
                    path='/workflow/connector/:connector_id/update'
                    element={<UpdateConnector />}
                  />
                </>
              )}
              {isAdmin(selectedOrgInfo?.role) && (
                <>
                  <Route path='/settings' element={<Settings />} />
                  <Route
                    path='/settings/configure-sso/:organization_id'
                    element={<ConfigureSSO />}
                  />
                  {(process.env.REACT_APP_ENV === 'development' ||
                    process.env.REACT_APP_ENV === 'local' ||
                    process.env.REACT_APP_ENV === 'staging' ||
                    process.env.REACT_APP_ENV === 'preproduction') && (
                    <Route
                      path='/scheduled-tasks'
                      element={<ScheduledTasks />}
                    />
                  )}
                </>
              )}
              {canAccessTeamPage && (
                <Route
                  path='/teams/*'
                  element={<TeamsHome sideDrawerCollapse={collapse} />}
                />
              )}
              {isOrbyAdmin && (
                <>
                  <Route path='/feature-flags' element={<FeatureFlags />} />
                  <Route
                    path='/feature-flags/create'
                    element={<FeatureFlagCreationPage />}
                  />
                  <Route
                    path='/feature-flags/:feature_flag_id/update'
                    element={<FeatureFlagUpdatePage />}
                  />
                </>
              )}
              <Route path='*' element={<Navigate to={'dashboard'} />} />
            </Routes>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(OptionsConfig);
