import { Box, Tooltip } from '@mui/material';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DRAWER_TABS } from './tabs';
import { INTEGRATION_DRAWER_TABS } from './integrationTabs';
import orbyLogo from '../../static/orby-white.svg';
import orbyIcon from '../../static/o-white.svg';
import DrawerTab from './DrawerTab';
import DrawerFooter from './DrawerFooter';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import { isAdmin } from '../../utils/helpers';
import SkipLink from './SkipLink';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import {
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  FEATURE_FLAGS,
} from '../../utils/constants';
import { OrbyColorPalette } from 'orby-ui/src';
import IntegratedDrawerFooter from './IntegratedDrawerFooter';
import { Link } from 'react-router-dom';
import {
  getFontSize,
  getFontWeight,
} from 'orby-ui/src/components/typography/typography-utils';

interface Props {
  collapse: boolean;
  setCollapse: (val: boolean) => void;
  orbotWorkflowLength: number;
}

const WebDrawerWrapper: FC<Props> = ({
  collapse,
  setCollapse,
  orbotWorkflowLength,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);

  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  const user = useSelector(loggedInUserSelector);
  const isOrbyAIUser = (user?.email ?? '').endsWith('@orby.ai');
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const isOrbyAdmin =
    selectedOrgInfo?.orgDisplayName === 'Orby AI' &&
    isAdmin(selectedOrgInfo?.role);
  const selectedPath = useMemo(() => {
    const arr = location.pathname.split('/');
    return arr[arr.length - 1];
  }, [location.pathname]);

  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);
  const userPermissions = useSelector(userPermissionsSelector);
  const canAccessWorkflowPage =
    isAdmin(selectedOrgInfo?.role) ||
    orbotWorkflowLength ||
    (isRbacEnabled &&
      (userPermissions.createWorkflow ||
        userPermissions.listWorkflows ||
        userPermissions.listConnectors));

  const canAccessExecutionPage =
    isAdmin(selectedOrgInfo?.role) ||
    orbotWorkflowLength ||
    (isRbacEnabled && userPermissions.listWorkflows);

  const handleDrawerItemClick = useCallback(
    (path: string) => navigate(path),
    [],
  );

  const renderHeader = useMemo(() => {
    return (
      <Box display='flex' justifyContent={'center'}>
        <Link to={'/dashboard'}>
          <img
            style={{
              marginTop: '32px',
              marginBottom: '44px',
            }}
            src={collapse ? orbyIcon : orbyLogo}
            alt='Orby Logo'
          />
        </Link>
      </Box>
    );
  }, [collapse]);

  const checkForTabPermissions = (title: string) => {
    if (!isRbacEnabled) {
      if (['Teams', 'Deleted Items', 'Settings', 'Trash Can'].includes(title)) {
        // UPDATE ON src/pages/OptionsConfig.tsx too
        return isAdmin(selectedOrgInfo?.role);
      } else if (['Workflows', 'Workflow'].includes(title)) {
        return canAccessWorkflowPage;
      } else if (['Executions'].includes(title)) {
        return isWebAppIntegrationEnabled && canAccessExecutionPage;
      } else if (title === 'ML Testing') {
        return isOrbyAIUser;
      } else if (title === 'Integration Test') {
        return user?.email === process.env.REACT_APP_TEST_USER;
      } else if (title === 'Feature Flags') {
        return isOrbyAdmin;
      } else if (
        title === 'Tasks' ||
        title === 'Teams New' ||
        title === 'My Workflows'
      ) {
        return isWebAppIntegrationEnabled;
      }
      return true;
    } else {
      if (title === 'Teams') {
        return isAdmin(selectedOrgInfo?.role) || userPermissions.updateUser;
      } else if (title === 'Settings') {
        return isAdmin(selectedOrgInfo?.role);
      } else if (['Deleted Items', 'Trash Can'].includes(title)) {
        return (
          isAdmin(selectedOrgInfo?.role) ||
          userPermissions.listArchivedResources
        );
      } else if (['Workflows', 'Workflow'].includes(title)) {
        return canAccessWorkflowPage;
      } else if (title === 'ML Testing') {
        return isOrbyAIUser;
      } else if (title === 'Integration Test') {
        return user?.email === process.env.REACT_APP_TEST_USER;
      } else if (title === 'Feature Flags') {
        return isOrbyAdmin;
      } else if (title === 'Tasks') {
        return isWebAppIntegrationEnabled;
      } else if (title === 'Executions') {
        return isWebAppIntegrationEnabled && canAccessExecutionPage;
      } else if (title === 'Teams New') {
        return (
          isWebAppIntegrationEnabled &&
          (isAdmin(selectedOrgInfo?.role) || userPermissions.updateUser)
        );
      } else if (title === 'My Workflows') {
        return isWebAppIntegrationEnabled && canAccessWorkflowPage;
      }
      return true;
    }
  };

  return (
    <Box
      bgcolor={OrbyColorPalette['grey-900']}
      width={
        collapse ? `${DRAWER_WIDTH_COLLAPSED}px` : `${DRAWER_WIDTH_EXPANDED}px`
      }
      height='100%'
      position={'fixed'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      zIndex={40}
      sx={{
        transition: 'all 0.1s ease',
      }}
    >
      <>
        {renderHeader}
        <SkipLink />
        <Box
          display={'flex'}
          component={'nav'}
          flexDirection={'column'}
          alignItems={'center'}
          height={'60%'}
          maxHeight={'60%'}
          style={{ overflowY: 'auto' }}
          overflow={'hidden'}
          className='drawer-content'
          paddingX={'16px'}
        >
          <Box
            role='tablist'
            aria-label='Tabs navigation'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {(isWebAppIntegrationEnabled
              ? INTEGRATION_DRAWER_TABS
              : DRAWER_TABS
            ).map((tab, index) => {
              if (checkForTabPermissions(tab.title))
                return (
                  <DrawerTab
                    key={index}
                    data={tab}
                    index={index}
                    collapse={collapse}
                    isSelected={tab.route === selectedPath}
                    onClick={handleDrawerItemClick}
                  />
                );
            })}
          </Box>
        </Box>
        <Tooltip
          title={collapse ? 'Click to expand' : 'Click to collapse'}
          arrow
          placement='right-start'
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [52, 0],
                },
              },
            ],
            sx: {
              '& .MuiTooltip-arrow': { color: OrbyColorPalette['white-0'] },
              '& .MuiTooltip-tooltip': {
                backgroundColor: OrbyColorPalette['white-0'],
                borderRadius: '8px',
                padding: '8px 12px',
                color: OrbyColorPalette['grey-700'],
                fontSize: getFontSize('xs'),
                fontWeight: getFontWeight('semibold'),
                boxShadow:
                  '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
              },
            },
          }}
        >
          <Box
            sx={{
              height: '15%',
              cursor: 'pointer',
            }}
            role='button'
            aria-label={collapse ? 'Click to collapse' : 'Click to expand'}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                setCollapse(!collapse);
              }
            }}
            tabIndex={0}
            onClick={() => setCollapse(!collapse)}
          ></Box>
        </Tooltip>
      </>
      {isWebAppIntegrationEnabled ? (
        <IntegratedDrawerFooter collapse={collapse} />
      ) : (
        <DrawerFooter collapse={collapse} setCollapse={setCollapse} />
      )}
    </Box>
  );
};

export default memo(WebDrawerWrapper);
