import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { GetTaskRequest, Task } from 'protos/pb/v1alpha2/tasks_service';
import {
  getUploadedDebugDocument,
  taskFormatErrorSelector,
} from '../../redux/selectors/taskV2.selectors';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { notification } from 'antd';
import {
  createTaskObjectWithDocument,
  getSelectedTaskDocument,
} from '../../utils/helpers';
import ReviewPageSideBar from './components/ReviewPageSideBar/ReviewPageSideBar';
import ReviewPageTopBar from './components/ReviewPageTopBar/ReviewPageTopBar';
import ReviewPageDocumentLayout from './components/ReviewPageDocumentLayout/ReviewPageDocumentLayout';
import ReviewPageLoader from './components/ReviewPageStateComponents/ReviewPageLoader';
import ReviewPageErrorView from './components/ReviewPageStateComponents/ReviewPageErrorView';
import {
  clearTaskAnnotationInfo,
  collapseEntitySidePanelAction,
  getTaskForReviewAction,
} from '../../redux/actions/review_task.action';
import {
  allowEditingTaskSelector,
  entitySidePanelCollapsedSelector,
  getAutomationCompletedErrorSelector,
  getAutomationCompletedSelector,
  getAutomationProgressSelector,
  getDocumentActivityTypeSelector,
  getOriginalPredictedResultSelector,
  getPredictedResultSelector,
  infoToDetectChangesSelector,
  taskForReviewLoadedSelector,
  taskForReviewLoadingErrorSelector,
  taskForReviewLoadingSelector,
  taskForReviewSelector,
} from '../../redux/selectors/review_task.selectors';
import ReviewPageProcessToast from './components/ReviewPageProcessToast/ReviewPageProcessToast';
import {
  AutomationProgressStatus,
  FEATURE_FLAGS,
  REVIEW_PAGE_TOP_BAR_HEIGHT,
  TASKS_TAB_INDEX,
} from '../../utils/constants';
import UnsavedChangesConfirmModal from './components/UnsavedChangesConfirmModal/UnsavedChangesConfirmModal';
import { Activity } from 'protos/automation_mining/automation_mining';
import { isAnyEntityModified } from '../../utils/UnsavedChangesUtils';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';

interface Props {
  sideDrawerCollapse: boolean;
  debug?: boolean;
}

const AutomationReviewPage: React.FC<Props> = ({
  sideDrawerCollapse,
  debug,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workflow_id, task_id, file_name } = useParams();
  const taskName = `workflows/${workflow_id}/tasks/${task_id}`;
  const documentActivityType = useSelector(getDocumentActivityTypeSelector);
  const predictedResult = useSelector(getPredictedResultSelector);
  const originalPredictedResult = useSelector(
    getOriginalPredictedResultSelector,
  );
  // this state contains all info necessary to detect unsaved changes
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);
  const task: Task | undefined = useSelector(taskForReviewSelector);
  const loading: boolean = useSelector(taskForReviewLoadingSelector);
  const loaded: boolean = useSelector(taskForReviewLoadedSelector);
  const taskLoadingError = useSelector(taskForReviewLoadingErrorSelector);
  const taskFormatError = useSelector(taskFormatErrorSelector);
  const taskAutomated = useSelector(getAutomationCompletedSelector);
  const taskAutomatedError = useSelector(getAutomationCompletedErrorSelector);
  const automationProgressStatus = useSelector(getAutomationProgressSelector);
  const debugDocument = useSelector(getUploadedDebugDocument);
  const isSidePanelCollapsed = useSelector(entitySidePanelCollapsedSelector);
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);

  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  const areUnsavedChangesPresent = () => {
    // classification
    if (documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return (
        predictedResult?.classificationLabel !==
        originalPredictedResult?.classificationLabel
      );
    }
    // extraction
    return isAnyEntityModified(infoToDetectChanges);
  };
  const blocker = useBlocker(({ nextLocation }) => {
    // do not block if task is non editable
    if (!allowEditingTask) {
      return false;
    }
    // do not block navigation if route contains 'preventBlocking' as true
    // eg: navigate('/declined-tasks', { state: { preventBlocking: true } })
    if (nextLocation.state?.preventBlocking) {
      return false;
    }
    // check for unsaved changes, if present block navigation and show custom modal
    return areUnsavedChangesPresent();
  });

  const [api, contextHolder] = notification.useNotification();

  const openError = (error: Error) => {
    api.info({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
    });
  };

  // Once the task automation is complete
  // navigate to the completed tasks page
  useEffect(() => {
    if (taskAutomated) {
      if (
        automationProgressStatus === AutomationProgressStatus.DECLINING_TASK
      ) {
        const url = isWebAppIntegrationEnabled
          ? `/tasks?tab=${TASKS_TAB_INDEX.DECLINED_TAB}`
          : '/declined-tasks';
        navigate(url, { state: { preventBlocking: true } }); // using this state we would know not to block this navigation
      } else if (
        automationProgressStatus === AutomationProgressStatus.ACCEPTING_TASK
      ) {
        const url = isWebAppIntegrationEnabled
          ? `/tasks?tab=${TASKS_TAB_INDEX.COMPLETED_TAB}`
          : '/completed-tasks';
        navigate(url, { state: { preventBlocking: true } }); // using this state we would know not to block this navigation
      }
    }
  }, [taskAutomated]);

  useEffect(() => {
    if (debug && file_name) {
      if (!debugDocument) {
        openError(Error('No Document found to render'));
        return;
      }
      const task = createTaskObjectWithDocument(file_name, debugDocument);
      dispatch(getTaskForReviewAction(undefined, task));
    } else {
      const req: GetTaskRequest = GetTaskRequest.fromJSON({ name: taskName });
      dispatch(getTaskForReviewAction(req));
    }
    return () => {
      // Clear suggestions state on unmount.
      dispatch(clearTaskAnnotationInfo());
    };
  }, []);

  useEffect(() => {
    if (taskAutomatedError) {
      openError(taskAutomatedError);
    }
  }, [taskAutomatedError]);

  useEffect(() => {
    // only attach beforeunload if task is editable
    if (allowEditingTask) {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (areUnsavedChangesPresent()) {
          e.preventDefault();
        }
      };
      // this event listener fires when user attempts to either refresh close browser/tab
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [areUnsavedChangesPresent, task, allowEditingTask]);

  // WHEN TASK IS LOADED AND THERE IS SOME ERROR or the Task DNE
  if (taskLoadingError || (loaded && !task)) {
    console.error('taskLoadingError', taskLoadingError);
    // Added this log statement to debug the issue on the dev environment
    if (process.env.REACT_APP_ENV === 'development') {
      console.error('taskLoadingError', taskLoadingError);
    }
    return (
      <ReviewPageErrorView
        contextHolder={contextHolder}
        message={'The task you are looking for does not exist'}
      />
    );
  }

  // IF THE TASK HAS A FORMAT ERROR
  if (taskFormatError) {
    // Added this log statement to debug the issue on the dev environment
    if (process.env.REACT_APP_ENV === 'development') {
      console.error('taskLoadingError', taskLoadingError);
    }
    return (
      <ReviewPageErrorView
        contextHolder={contextHolder}
        message={'The task format is incorrect'}
      />
    );
  }

  // IF THE TASK IS LOADING
  if (
    !task &&
    (loading || !loaded) &&
    !taskAutomated &&
    automationProgressStatus === AutomationProgressStatus.DEFAULT
  ) {
    return <ReviewPageLoader contextHolder={contextHolder} />;
  }

  const documentStep: ExecutionStep | undefined = getSelectedTaskDocument(task);
  const hasPagesInDocument = documentStep
    ? (documentStep?.documents?.[0]?.pages?.length as number) > 0
    : 0;
  const notAutomatingTask =
    !taskAutomated &&
    automationProgressStatus === AutomationProgressStatus.DEFAULT;

  // IF THE TASK IS LOADED BUT THERE IS NO DOCUMENT AND THERE IS NO ERROR IN TASK AUTOMATION
  if (!hasPagesInDocument && !taskAutomatedError && notAutomatingTask) {
    return <ReviewPageLoader contextHolder={contextHolder} />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      bgcolor={'#E0E0E054'}
      height={'100vh'}
    >
      {contextHolder}
      <ReviewPageProcessToast
        open={
          taskAutomated ||
          automationProgressStatus !== AutomationProgressStatus.DEFAULT
        }
        automationProgressStatus={automationProgressStatus}
        taskAutomated={taskAutomated}
      />
      <ReviewPageTopBar task={task || ({} as Task)} debug={debug} />
      <Box
        display={'flex'}
        // Removing the top bar height and a padding of 4px to get the remaining height
        height={`calc(100% - ${REVIEW_PAGE_TOP_BAR_HEIGHT}px - 4px)`}
        marginTop={'4px'}
      >
        <Box
          sx={{
            minWidth: !isSidePanelCollapsed ? '27vw' : '0px',
            width: !isSidePanelCollapsed ? '27vw' : '0px',
            transition:
              'min-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s, width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
          }}
        >
          <ReviewPageSideBar debug={debug} />
        </Box>
        <Box
          sx={{
            ':hover': {
              bgcolor: isSidePanelCollapsed ? 'unset' : '#212121',
            },
            '&:hover .child': {
              bgcolor: isSidePanelCollapsed ? '#212121' : 'white',
              boxShadow:
                'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
            },
            '&:hover .child-icon': {
              color: 'black',
            },
            transition: 'background-color 0.1s linear',
          }}
          height={'calc(100% + 10px)'}
          width={'5px'}
          display={'flex'}
          marginTop={'-10px'}
          justifyContent={'center'}
          alignItems={'center'}
          paddingLeft={isSidePanelCollapsed ? '0px' : '4px'}
          marginLeft={isSidePanelCollapsed ? '15px' : '0px'}
          position={'relative'}
        >
          <Tooltip
            placement='right'
            arrow={false}
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#000',
                  borderRadius: '8px',
                },
              },
            }}
            title={isSidePanelCollapsed ? 'Expand' : 'Collapse'}
          >
            <IconButton
              onClick={() =>
                dispatch(collapseEntitySidePanelAction(!isSidePanelCollapsed))
              }
              className={'child'}
              sx={{
                position: 'absolute',
                ':hover': {
                  bgcolor: '#212121 !important',
                },
                '&:hover .child-icon': {
                  color: 'white !important',
                },
                bgcolor: isSidePanelCollapsed ? '#212121' : 'auto',
                zIndex: 1000,
                height: '32px !important',
                width: '32px !important',
              }}
            >
              {isSidePanelCollapsed ? (
                <KeyboardArrowRightRoundedIcon
                  sx={{
                    color: 'white',
                  }}
                />
              ) : (
                <KeyboardArrowLeftRoundedIcon
                  className='child-icon'
                  sx={{
                    color: 'transparent',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <ReviewPageDocumentLayout
          task={task || ({} as Task)}
          documentStep={documentStep || ({} as ExecutionStep)}
          sideDrawerCollapse={sideDrawerCollapse}
        />
      </Box>
      {blocker.state === 'blocked' && (
        <UnsavedChangesConfirmModal
          open
          onContinue={() => blocker.reset?.()}
          onDiscard={() => blocker.proceed?.()}
        />
      )}
    </Box>
  );
};

export default React.memo(AutomationReviewPage);
