import React, { memo } from 'react';
import { isFeatureFlagEnabled } from '../../../pages/FeatureFlags/FeatureFlagUtils';
import { useSelector } from 'react-redux';
import {
  getFeatureFlagsForOrgAndUserLoadingSelector,
  getFeatureFlagsForOrgAndUserSelector,
} from '../../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../../utils/constants';
import NewWorkflowUpdatePage from './WorkflowUpdatePage';
import { Box, CircularProgress } from '@mui/material';
import WorkflowUpdatePage from '../../../pages/workflow-creation/WorkflowUpdatePage';

const UpdateWorkflow = () => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const featureFlagsLoading = useSelector(
    getFeatureFlagsForOrgAndUserLoadingSelector,
  );
  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  if (featureFlagsLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vH'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isWebAppIntegrationEnabled ? (
    <NewWorkflowUpdatePage />
  ) : (
    <WorkflowUpdatePage />
  );
};

export default memo(UpdateWorkflow);
