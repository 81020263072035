import jsYaml from 'js-yaml';
import {
  CompositeGroupCondition,
  Condition,
  ConditionType,
} from 'protos/pb/v1alpha2/connector';
import {
  EntityDataType,
  EntityDetails,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { getActionIndex } from './helpers';
import { ApplicationName } from './protos/enums';
import {
  ReviewTriggerCondition,
  ReviewerList,
  Workflow,
  WorkflowAssignmentOption,
  WorkflowLearningSettings,
  WorkflowLearningSettingsReviewer,
  WorkflowMode,
  WorkflowUser,
} from 'protos/pb/v1alpha2/workflows_service';
import {
  getEntityDetailsFromSchemaMapping,
  transformNotesEntityType,
} from './yamlToJsonParser';
import { UserProfileInfo } from 'protos/common/user_profile';
import { User } from 'protos/pb/v1alpha1/user';

/**
 * Reverse maps an enum value to its corresponding enum key.
 */
const reverseMapEnum = (enumValue: any, enumMap: any) => {
  for (const key in enumMap) {
    if (enumMap[key] === enumValue) {
      return key; // Return the enum key if a matching enum value is found
    }
  }
  return undefined; // Return undefined if no matching enum is found
};

/**
 * Function to parse the trigger condition
 * and remove the fields which are not required
 */
const parseTriggerCondition = (triggerCondition: ReviewTriggerCondition) => {
  const updatedTriggerCondition: Record<string, any> = {};
  updatedTriggerCondition['conditionType'] = reverseMapEnum(
    triggerCondition.conditionType,
    ConditionType,
  );
  if (triggerCondition.conditionType === ConditionType.RANDOM_SAMPLE_PERCENT) {
    updatedTriggerCondition['conditionOptions'] = {};
    updatedTriggerCondition['conditionOptions']['percentOfRandomSample'] =
      triggerCondition.conditionOptions!.percentOfRandomSample;
  } else if (
    triggerCondition.conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD
  ) {
    updatedTriggerCondition['conditionOptions'] = {
      groupCondition: {
        conditions:
          triggerCondition.conditionOptions?.groupCondition?.conditions?.map(
            (condition) => {
              return {
                attribute: condition.attribute,
                attributeType: {
                  name: condition?.attributeType?.name,
                  parent: condition?.attributeType?.parent,
                },
                value: condition.value,
              };
            },
          ),
      },
    };
  } else if (
    [
      ConditionType.AVERAGE_CONFIDENCE_SCORE,
      ConditionType.ANY_EXTRACTED_FIELD,
    ].includes(triggerCondition.conditionType!)
  ) {
    updatedTriggerCondition['conditionOptions'] = {};
    updatedTriggerCondition['conditionOptions']['confidenceScore'] =
      triggerCondition.conditionOptions!.confidenceScore;
  }
  return updatedTriggerCondition;
};

export const parseUsers = (users: WorkflowUser[]) => {
  return users.map((user) => {
    return { user: user.user };
  });
};

export const parseAssignmentOption = (
  assignmentOption?: WorkflowAssignmentOption,
) => {
  if (
    !assignmentOption?.conditionalAssignment &&
    !assignmentOption?.manualAssignment
  )
    return undefined;
  const assignmentOptionObject = WorkflowAssignmentOption.create({});
  if (assignmentOption.conditionalAssignment) {
    assignmentOptionObject.conditionalAssignment =
      assignmentOption.conditionalAssignment.map((conditionalAssignment) => {
        return {
          groupCondition: CompositeGroupCondition.create({
            conditions: (
              conditionalAssignment.groupCondition?.conditions || []
            ).map((condition) => {
              return Condition.create({
                attribute: condition.attribute,
                attributeType: {
                  parent: condition.attributeType?.parent,
                  name: condition.attributeType?.name,
                },
                operator: condition.operator,
                value: condition.value,
              });
            }),
          }),
          users: parseUsers(conditionalAssignment?.users || []),
        };
      });
  }
  if (assignmentOption.manualAssignment) {
    assignmentOptionObject.manualAssignment = {
      users: parseUsers(assignmentOption.manualAssignment?.users || []),
    };
  }
  return assignmentOptionObject;
};

/**
 * Function to parse the reviewer list and
 * parse the trigger condition if the workflow is not a classification workflow
 */
const parseReviewerListEnumStrings = (reviewerLists: ReviewerList[]) => {
  return reviewerLists.map((reviewerList) => {
    const data: Record<string, any> = {
      users: parseUsers(reviewerList.users || []),
      roundNumber: reviewerList.roundNumber,
      assignmentOption: parseAssignmentOption(reviewerList.assignmentOption),
    };
    const triggerCondition = parseTriggerCondition(
      reviewerList.triggerCondition!,
    );
    if (Object.keys(triggerCondition).length > 0) {
      data['triggerCondition'] = triggerCondition;
    }
    return data;
  });
};

/**
 * Function to parse the entity type schema mapping and convert the enum values to strings
 * This function is called from getParsedWorkflowStep
 */
const parseEntityTypeSchemaMappingEnumStrings = (
  entityTypeSchemaMapping: Record<string, any>,
) => {
  const updatedEntityTypeSchemaMapping = entityTypeSchemaMapping;
  for (const key in updatedEntityTypeSchemaMapping) {
    const mapping = updatedEntityTypeSchemaMapping[key];
    const reverseMapped = reverseMapEnum(
      mapping.normalizationType,
      EntityDataType,
    );
    if (reverseMapped) {
      mapping.normalizationType = reverseMapped;
    }
  }
  return updatedEntityTypeSchemaMapping;
};

export const parseEntitiesDetails = (details: EntityDetails[] = []) => {
  return details.map((e: EntityDetails): EntityDetails => {
    return {
      entityType: transformNotesEntityType(
        EntityDataType[e.normalizationType!],
        e.entityType!,
      ),
      normalizationType: EntityDataType[
        e.normalizationType!
      ] as unknown as EntityDataType,
      properties: e.properties!.length
        ? parseEntitiesDetails(e.properties)
        : [],
    };
  });
};

/**
 * Get the workflow step action based on the action
 */
const getParsedWorkflowStep = (workflow: Workflow) => {
  const classificationLabelsIndex = getActionIndex(
    workflow,
    ApplicationName.DocumentClassification,
  );
  // If the workflow is a classification workflow then return the classification action
  if (classificationLabelsIndex.actionIndex !== -1) {
    return {
      actions: [
        workflow.steps![classificationLabelsIndex.stepIndex!].actions![
          classificationLabelsIndex.actionIndex!
        ],
      ],
    };
  } else {
    // If the workflow is an extraction workflow then return the extraction action with the entity type schema mapping and convert the enum values to strings
    const extractionIndex = getActionIndex(
      workflow,
      ApplicationName.EntityExtraction,
    );
    const extractionAction =
      workflow.steps![extractionIndex.stepIndex!].actions![
        extractionIndex.actionIndex!
      ];
    const entities: string[] = [];
    const entityTypeSchemaMapping: Record<string, EntityTypeSchema> = {};
    for (const entity in parseEntityTypeSchemaMappingEnumStrings(
      extractionAction.entityExtraction!.entityTypeSchemaMapping!,
    )) {
      entities.push(entity);
      entityTypeSchemaMapping[entity] = {
        normalizationType: EntityDataType[
          extractionAction.entityExtraction!.entityTypeSchemaMapping![entity]
            .normalizationType!
        ] as unknown as EntityDataType,
      };
    }
    const entitiesDetails = !extractionAction.entityExtraction?.entitiesDetails
      ?.length
      ? getEntityDetailsFromSchemaMapping(
          { entities, entityTypeSchemaMapping },
          true,
        )
      : parseEntitiesDetails(
          extractionAction?.entityExtraction?.entitiesDetails,
        );
    return {
      actions: [
        {
          application: extractionAction.application,
          entityExtraction: {
            entitiesDetails,
            entityTypeSchemaMapping: {},
          },
        },
      ],
    };
  }
};

// This is to parse learning settings
export const parseLearningSettings = (
  learningSettings?: WorkflowLearningSettings,
  workflow?: Workflow,
  user?: User,
) => {
  if (workflow?.mode === WorkflowMode.AUTOPILOT) {
    return WorkflowLearningSettings.create({
      reviewers: [
        WorkflowLearningSettingsReviewer.create({
          existsInReviewerList: true,
          usedForLearning: true,
          reviewer: UserProfileInfo.create({
            username: user?.email,
          }),
        }),
      ],
    });
  }

  if (!learningSettings) {
    return undefined;
  }
  return WorkflowLearningSettings.create({
    reviewers: learningSettings?.reviewers?.map((info) => {
      return WorkflowLearningSettingsReviewer.create({
        existsInReviewerList: info.existsInReviewerList,
        usedForLearning: info.usedForLearning,
        reviewer: UserProfileInfo.create({
          username: info.reviewer?.username,
        }),
      });
    }),
  });
};

/**
 * This method is used to parse the workflow object to json with enums as strings
 * Used for the yaml export and is configured to work only for sftp workflows without google sheets
 */
export const parseWorkflowProtoToJson = (workflow: Workflow) => {
  const parsedWorkflowObject: Workflow = {
    templateResourceName: workflow.templateResourceName,
    displayName: workflow.displayName,
    description: workflow.description,
    learningSettings:
      workflow.mode === WorkflowMode.AUTOPILOT
        ? undefined
        : parseLearningSettings(workflow?.learningSettings),
    manualTimeCostInMinutes: workflow.manualTimeCostInMinutes,
    needAttentionThresholdDefaultMode:
      workflow.needAttentionThresholdDefaultMode,
    steps: [getParsedWorkflowStep(workflow)],
    mode: reverseMapEnum(
      workflow.mode,
      WorkflowMode,
    ) as unknown as WorkflowMode,
  };
  if (workflow.mode !== WorkflowMode.AUTOPILOT) {
    parsedWorkflowObject.reviewerLists = parseReviewerListEnumStrings(
      workflow.reviewerLists!,
    );
  }
  return jsYaml.dump(parsedWorkflowObject);
};
