import {
  CreateInvoiceRequest,
  DeleteInvoiceRequest,
  Invoice,
  ListInvoicesRequest,
  UpdateInvoiceRequest,
} from 'protos/pb/demo/invoice_service';
import { ERPActionType } from './actions.constants';

export const listInvoicesAction = (req: ListInvoicesRequest) => ({
  type: ERPActionType.LIST_INVOICES,
  payload: req,
});

export const listInvoicesCompletedAction = (invoices: Invoice[]) => ({
  type: ERPActionType.LIST_INVOICES_COMPLETED,
  payload: invoices,
});

export const listInvoicesErrorAction = (error: string | undefined) => ({
  type: ERPActionType.LIST_INVOICES_ERROR,
  payload: error,
});

export const createInvoiceAction = (req: CreateInvoiceRequest) => ({
  type: ERPActionType.CREATE_INVOICE,
  payload: req,
});

export const createInvoiceCompletedAction = (invoice: Invoice) => ({
  type: ERPActionType.CREATE_INVOICE_COMPLETED,
  payload: invoice,
});

export const createInvoiceErrorAction = (error: string) => ({
  type: ERPActionType.CREATE_INVOICE_ERROR,
  payload: error,
});

export const updateInvoiceAction = (req: UpdateInvoiceRequest) => ({
  type: ERPActionType.UPDATE_INVOICE,
  payload: req,
});

export const updateInvoiceCompletedAction = (invoice: Invoice) => ({
  type: ERPActionType.UPDATE_INVOICE_COMPLETED,
  payload: invoice,
});

export const updateInvoiceErrorAction = (error: string) => ({
  type: ERPActionType.UPDATE_INVOICE_ERROR,
  payload: error,
});

export const setSelectedInvoiceAction = (invoice?: Invoice) => ({
  type: ERPActionType.SET_SELECTED_INVOICE,
  payload: invoice,
});

export const deleteInvoiceAction = (req: DeleteInvoiceRequest) => ({
  type: ERPActionType.DELETE_INVOICE,
  payload: req,
});

export const deleteInvoiceCompletedAction = (invoiceName: string) => ({
  type: ERPActionType.DELETE_INVOICE_COMPLETED,
  payload: invoiceName,
});

export const deleteInvoiceErrorAction = (error: string | undefined) => ({
  type: ERPActionType.DELETE_INVOICE_ERROR,
  payload: error,
});
