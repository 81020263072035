import { createSelector } from 'reselect';
import { archivedResourcesSelector } from './app.selectors';

export const archivedResourcesListSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.list,
);

export const archivedResourcesTotalSizeSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.totalSize,
);

export const archivedResourcesListLoadingSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.listLoading,
);

export const archivedResourcesListLoadedSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.listLoaded,
);

export const archivedResourcesListingErrorSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.listingError,
);

export const archivedResourcesRestoringSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.restoring,
);

export const archivedResourcesRestoredSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.restored,
);

export const archivedResourcesRestoringErrorSelector = createSelector(
  [archivedResourcesSelector],
  (archivedResourcesState) => archivedResourcesState.restoringError,
);
