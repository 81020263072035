import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';

import {
  Review,
  Task,
  TaskSTATUS,
  UpdateBatchTasksRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  deletingTaskSelector,
  pendingTasksListSelector,
  pendingTasksLoadingSelector,
  pendingTasksTotalSizeSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToDeleteSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  areAllTasksSelected,
  buildRequest,
  getTaskNameColumnWidthCompletedTab,
  getTimeModifiedWidth,
  handleDeleteTask,
  handleTaskPageChange,
  handleTasksRowSelectionChange,
} from '../../TaskHelpers';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  clearDeleteTaskAction,
  listPendingTasksAction,
  updateBatchTasksAction,
} from '../../../../redux/actions/taskV2.action';
import {
  TASK_MENU_ACTION_TYPE,
  getDisplayNameCell,
  getPendingTaskActions,
  getReviewersToggle,
  getTaskActionGroupErrorContent,
  getTaskActionItem,
  getTaskCompletedTimeCell,
  getTaskPendingReviewerCell,
  getTaskTagCell,
  getWorkflowDisplayNameCell,
} from '../TaskTableBodyHelpers';
import TaskDeletionModal from '../../../../pages/PendingTasks/TaskDeletionModal';
import { toastService } from '../../../../services/ToastService';
import TaskSelectionHeader from '../TaskSelectionHeader';
import { clearReviewState } from '../../../../redux/actions/review_task.action';
import { groupTasksByError } from '../../../../utils/helpers';
import { DEFAULT_ROWS_PER_PAGE } from 'orby-ui/src/components/table/table-utils';
import { isTaskViewable, isTaskDeletable } from '../../../../utils/RbacUtils';
import { selectedTasksPermissionErrorMsg } from '../../../../utils/constants';

export interface Props {
  displayNamePrefix: string;
  selectedWorkflows: Array<string>;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const CompletedTasksTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  selectedUsers,
  isAdminView,
  tableWidth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tasks: Task[] = useSelector(pendingTasksListSelector);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);
  const totalSize = useSelector(pendingTasksTotalSizeSelector) ?? 0;

  const tasksLoading = useSelector(pendingTasksLoadingSelector);
  const deletingTask = useSelector(deletingTaskSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );

  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [selectedTasksToDelete, setSelectedTasksToDelete] = useState<Task[]>(
    [],
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const pagedTasks = tasks.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [reviewerToggleAnchorEl, setReviewerToggleAnchorEl] =
    useState<null | HTMLElement>(null);

  const [reviews, setReviews] = useState<Review[]>([]);

  /**
   * REVIEWS CLICK
   */
  const handleReviewsToggleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    task: Task,
  ) => {
    event.stopPropagation();
    const reviews = [...task.reviews!]?.reverse().slice(1);
    setReviews(reviews ?? []);
    setReviewerToggleAnchorEl(event.currentTarget);
  };

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    task: Task,
  ) => {
    setSelectedTask(task);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedTask) {
      if (type === TASK_MENU_ACTION_TYPE.DELETE) {
        if (selectedTask.status !== TaskSTATUS.CREATED) {
          setSelectedTasksToDelete([selectedTask]);
        }
      } else if (type === TASK_MENU_ACTION_TYPE.UNASSIGN) {
        assignUnAssignTask([selectedTask], '');
      }
    }
    setActionMenuAnchorEl(null);
  };

  /**
   * FETCH TASKS
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listTasks = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildRequest({
      status: 'status=COMPLETED',
      user: user!,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      selectedUsers,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listPendingTasksAction(req, refresh));
  };

  /**
   * ASSIGN / UNASSIGN TASKS
   */
  const assignUnAssignTask = (taskList: Task[], user: string) => {
    const tasks = taskList.map((t: Task) => {
      return {
        name: t.name,
        displayName: t.displayName,
        username: user,
        organizationResourceName: t.organizationResourceName,
      } as Task;
    });
    dispatch(updateBatchTasksAction(UpdateBatchTasksRequest.create({ tasks })));
  };

  /**
   * DELETE TASKS
   */
  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    handleDeleteTask(deleteReason, itemsToDelete, dispatch);
    setSelectedTasks([]);
    setSelectedTasksToDelete([]);
  };

  // CHECK IF SPECIFIC TASKS IS SELECTED
  const isTaskSelected = (task: Task) => {
    return selectedTasks.some(
      (selectedTask) => selectedTask.name === task.name,
    );
  };

  // HANDLE SELECTION/DESELECTION OF THE TASK
  const handleTaskSelection = (task: Task, isSelected: boolean) => {
    if (isSelected) {
      setSelectedTasks((prevSelectedTasks) => [...prevSelectedTasks, task]);
    } else {
      setSelectedTasks((prevSelectedTasks) =>
        prevSelectedTasks.filter((t) => t.name !== task.name),
      );
    }
  };

  // HANDLE SELECTION/DESELECTION OF ALL TASKS ON THE CURRENT PAGE
  const handleAllTasksSelection = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedTasks(pagedTasks);
    } else {
      setSelectedTasks([]);
    }
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(0);
    setSelectedTasks([]);
    listTasks(1, rowsPerPage, true);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix, selectedWorkflows, selectedUsers, isAdminView]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      toastService.showSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
        {
          position: 'top-right',
        },
      );

      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksDeletedSuccessfully]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      toastService.showError(
        getTaskActionGroupErrorContent(
          groupTasksByError(tasksFailedToDelete),
          false,
        ),
        {
          position: 'top-right',
        },
      );
      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksFailedToDelete]);

  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        onTableRowClick={(data) => {
          const task = data as Task;
          if (!isTaskViewable(task)) {
            toastService.showInfo(
              "You don't have permissions to view task details",
              {
                position: 'top-center',
                autoClose: 1000,
              },
            );
            return;
          }
          if (task.status !== TaskSTATUS.CREATED) {
            dispatch(clearReviewState());
            navigate(`/${task.name}/automation-review`);
          }
        }}
        isDataLoading={tasksLoading || deletingTask}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no completed tasks available'
        }
        isCheckBoxDisabled={false}
        colSx={[
          {
            width: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
            maxWidth: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
            minWidth: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
          },
          {
            width: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
            maxWidth: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
            minWidth: `${getTaskNameColumnWidthCompletedTab(tableWidth)}px`,
          },
          {
            width: '109px',
            maxWidth: '109px',
            minWidth: '109px',
          },
          {
            width: `${getTimeModifiedWidth(tableWidth)}px`,
            maxWidth: `${getTimeModifiedWidth(tableWidth)}px`,
            minWidth: `${getTimeModifiedWidth(tableWidth)}px`,
          },
          {
            width: '270px',
            minWidth: '270px',
            maxWidth: '270px',
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        rowSx={{
          '.visibility-off-icon': {
            display: 'none',
          },
          '&:hover .visibility-off-icon': {
            display: 'block',
          },
        }}
        tableId='completed-task-table'
        showCheckBox={true}
        areAllRowsSelected={() =>
          areAllTasksSelected(selectedTasks, tasks, page, rowsPerPage)
        }
        isRowSelected={(row) => {
          const task = row as Task;
          return isTaskSelected(task);
        }}
        selectedRows={selectedTasks}
        handleRowSelection={(row, isSelected) => {
          const task = row as Task;
          handleTaskSelection(task, isSelected);
        }}
        handleAllRowsSelection={handleAllTasksSelection}
        checkBoxSelectionHeader={
          <TaskSelectionHeader
            selectedItems={selectedTasks}
            onCancel={() => {
              setSelectedTasks([]);
              setSelectedTasksToDelete([]);
            }}
            tertiaryLabel={''}
            secondaryLabel={'Delete'}
            onSecondaryClick={() => {
              setSelectedTasksToDelete(selectedTasks);
            }}
            secondaryLabelDisabled={selectedTasks.some(
              (t) => !isTaskDeletable(t),
            )}
            secondaryLabelDisabledTooltip={selectedTasksPermissionErrorMsg}
            primaryLabel={''}
          />
        }
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleTaskPageChange({
              tasks,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listTasks,
              setSelectedTasks,
            }),
          setRowsPerPage: (rows: number) =>
            handleTasksRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listTasks,
              setSelectedTasks,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'task-header-display-name'}
            label='Task Name'
          />,
          <OrbyHeaderLabel key={'task-header-workflow'} label='Workflow' />,
          <OrbyHeaderLabel key={'task-header-tag'} label='Tag' />,
          <OrbyHeaderLabel
            key={'task-header-time-completed'}
            label='Time Completed'
          />,
          <OrbyHeaderLabel key={'task-header-users'} label='Users' />,
          <OrbyHeaderLabel key={'task-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedTasks.map((task) => {
          return {
            title: task.displayName!,
            id: task.name!,
            row: task,
            cells: [
              getDisplayNameCell(task, displayNamePrefix),
              getWorkflowDisplayNameCell(task),
              getTaskTagCell(task),
              getTaskCompletedTimeCell(task),
              getTaskPendingReviewerCell(task, handleReviewsToggleClick),
              getPendingTaskActions(Task.create(task), handleActionMenuClick),
            ],
            isClickable: isTaskViewable(task),
          };
        })}
      />

      {/* TASK ACTION MENU */}
      {getTaskActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        (selectedTask && isTaskDeletable(selectedTask)) || false,
        false /* cannot assign to others as task is completed */,
        'completed-task',
      )}

      {/* REVIEWER TOGGLE MENU */}
      {getReviewersToggle(reviewerToggleAnchorEl, reviews, () =>
        setReviewerToggleAnchorEl(null),
      )}

      {/* DELETE TASK MODEL */}
      <TaskDeletionModal
        selectedTasks={selectedTasksToDelete}
        open={selectedTasksToDelete.length > 0}
        handleClose={() => setSelectedTasksToDelete([])}
        onSubmit={(deletionReason, task) => {
          handleDelete(deletionReason, task as Task[]);
        }}
      />
    </>
  );
};

export default React.memo(CompletedTasksTab);
