import { createSelector } from 'reselect';
import { erpSelector } from './app.selectors';

export const invoiceListSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.invoices,
);

export const invoiceLoadingSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.loadingInvoices,
);

export const processingInvoiceSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.processingInvoice,
);

export const deletingInvoiceSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.deletingInvoice,
);

export const selectedInvoiceSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.selectedInvoice,
);

export const listInvoiceErrorSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.listInvoiceError,
);

export const deleteInvoiceErrorSelector = createSelector(
  [erpSelector],
  (erpState) => erpState.deleteInvoiceError,
);
