import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { notification } from 'antd';
import CustomButton from '../../components/CustomButton';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import CustomTextField from '../../components/CustomTextField';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createdFeatureFlagSelector,
  processFeatureFlagErrorSelector,
  processingFeatureFlagSelector,
} from '../../redux/selectors/feature_flags.selectors';
import {
  CreateFeatureFlagRequest,
  Rule,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { createFeatureFlagAction } from '../../redux/actions/feature_flags.action';
import { FeatureFlagFormValues } from './FeatureFlagUtils';
import { InfoRounded } from '@mui/icons-material';

const FeatureFlagCreationPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const processingFeatureFlag = useSelector(processingFeatureFlagSelector);
  const [creationTriggered, setCreationTriggered] = useState(false);
  const createdFeatureFlag = useSelector(createdFeatureFlagSelector);
  const processFeatureFlagError = useSelector(processFeatureFlagErrorSelector);

  useEffect(() => {
    if (processFeatureFlagError && creationTriggered) {
      api.error({
        message: 'Notification',
        description: processFeatureFlagError.message,
        placement: 'topRight',
        duration: null,
      });
    }
  }, [processFeatureFlagError]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (createdFeatureFlag && creationTriggered) {
      api.success({
        message: 'Success',
        description:
          'Feature flag ' +
          createdFeatureFlag.name +
          ' is created successfully',
        placement: 'topRight',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [createdFeatureFlag]);

  const onSubmit = (values: FeatureFlagFormValues) => {
    const req = CreateFeatureFlagRequest.create({
      name: values.name,
      description: values.description,
      rule: Rule.create({
        enabled: values.enabled,
        enabledUsers: values.enabledUsers,
        blockedUsers: values.blockedUsers,
        enabledOrgs: values.enabledOrgs,
        blockedOrgs: values.blockedOrgs,
        enabledWorkflows: values.enabledWorkflows,
        blockedWorkflows: values.blockedWorkflows,
      }),
    });
    dispatch(createFeatureFlagAction(req));
    setCreationTriggered(true);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      enabled: false,
      enabledUsers: [],
      blockedUsers: [],
      enabledOrgs: [],
      blockedOrgs: [],
      enabledWorkflows: [],
      blockedWorkflows: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Feature flag name is required.')
        .max(100, 'Feature flag name must be at most 100 characters.'),
    }),
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <Box
      bgcolor={'#F6F8FC'}
      paddingX={'50px'}
      paddingTop={'50px'}
      width={'100%'}
    >
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Create Feature Flag
        <Tooltip
          title={
            <span>
              More info refer to feature flag{' '}
              <Link to='https://go/feature-flag'>guidance</Link>{' '}
            </span>
          }
        >
          <IconButton>
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </CustomTypography>
      <FormikProvider value={formik}>
        <Form
          id='feature-flag-create-form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Box display={'flex'} gap={'50px'} justifyContent={'space-between'}>
            <Box width={'70%'} marginBottom={'15px'}>
              <Box marginLeft={'14px'} marginTop={'21px'}>
                <CustomTextField
                  label='Feature Flag Name'
                  size='small'
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  hasDescription={true}
                  extraProps={getFieldProps('name')}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <CustomTextField
                  label='Description'
                  size='small'
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  extraProps={getFieldProps('description')}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography>
                    <Tooltip
                      title={
                        <div>
                          <b>Default feature flag status</b>. if default status
                          is true, the feature flag is enabled for all users
                          unless the user or org is in the blocked list; if
                          default status is false, the feature flag is disabled
                          for all users unless the user or org is in the enabled
                          list
                        </div>
                      }
                      arrow
                    >
                      <span>Disabled</span>
                    </Tooltip>
                  </Typography>
                  <Switch
                    color='primary'
                    checked={values.enabled}
                    {...getFieldProps('enabled')}
                  />
                  <Typography>Enabled</Typography>
                </Stack>
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='enabledUsers'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) => setFieldValue('enabledUsers', value)}
                  freeSolo
                  disabled={values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of usernames (like email, xxx@orbby.ai) to enable. Add username by typing and press enter after finishing each username.'
                          arrow
                          placement='top'
                        >
                          <span>Enabled Users</span>
                        </Tooltip>
                      }
                      placeholder='Add enabled usernames'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='enabledOrgs'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) => setFieldValue('enabledOrgs', value)}
                  freeSolo
                  disabled={values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of organization ids (pure object ids, like 6150ccc742d39feae9fc640g) to enable. Add organization id by typing and press enter after finishing each organization id.'
                          arrow
                          placement='top'
                        >
                          <span>Enabled Organizations</span>
                        </Tooltip>
                      }
                      placeholder='Add enabled organization ids'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='enabledWorkflows'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) =>
                    setFieldValue('enabledWorkflows', value)
                  }
                  freeSolo
                  disabled={values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of workflow ids (pure object ids, like 6150ccc742d39feae9fc640g) to enable. Add workflow id by typing and press enter after finishing each workflow id.'
                          arrow
                          placement='top'
                        >
                          <span>Enabled Workflows</span>
                        </Tooltip>
                      }
                      placeholder='Add enabled workflow ids'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='blockedUsers'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) => setFieldValue('blockedUsers', value)}
                  freeSolo
                  disabled={!values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of usernames (like email, xxx@orbby.ai) to block. Add username by typing and press enter after finishing each username.'
                          arrow
                          placement='top'
                        >
                          <span>Blocked Users</span>
                        </Tooltip>
                      }
                      placeholder='Add blocked usernames'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='blockedOrgs'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) => setFieldValue('blockedOrgs', value)}
                  freeSolo
                  disabled={!values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of organization ids (pure object ids, like 6150ccc742d39feae9fc640g) to block. Add organization id by typing and press enter after finishing each organization id.'
                          arrow
                          placement='top'
                        >
                          <span>Blocked Organizations</span>
                        </Tooltip>
                      }
                      placeholder='Add blocked organization ids'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
              <Box marginLeft={'14px'} marginTop={'11px'}>
                <Autocomplete
                  multiple
                  id='blockedWorkflows'
                  options={[]}
                  defaultValue={[]}
                  onChange={(e, value) =>
                    setFieldValue('blockedWorkflows', value)
                  }
                  freeSolo
                  disabled={!values.enabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={
                        <Tooltip
                          title='Add exception list of workflow ids (pure object ids, like 6150ccc742d39feae9fc640g) to block. Add workflow id by typing and press enter after finishing each workflow id.'
                          arrow
                          placement='top'
                        >
                          <span>Blocked Workflows</span>
                        </Tooltip>
                      }
                      placeholder='Add blocked workflow ids'
                      sx={{ width: '359px' }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
      <Box
        paddingBottom={'20px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '25px',
        }}
      >
        <CustomButton
          variant='outlined'
          onClick={() => navigate(-1)}
          disabled={processingFeatureFlag}
        >
          Cancel
        </CustomButton>
        <CustomButton
          form={'feature-flag-create-form'}
          type='submit'
          invisible={false}
          disabled={processingFeatureFlag}
          loading={processingFeatureFlag}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default React.memo(FeatureFlagCreationPage);
