import {
  ItemDescription,
  WidgetContainer,
} from '../../ExecutionHistory/tabs/ui-automations/details/component/Styled';
import React from 'react';
import { EditableText } from './EditableText';
import { ActionObject } from 'workflow-utils/src/types';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { ProcessContext } from './process-context';
import { useContext } from 'react';
import { getActionById } from 'workflow-utils/src/v2/workflow';

interface IProps {
  action: ActionObject;
  isEditable?: boolean;
  processId?: string;
}

const IfCondition: React.FC<IProps> = ({ action, isEditable, processId }) => {
  const dispatch = useDispatch();

  const actions = useContext(ProcessContext);
  const referencedActionId = action?.condition?.condition?.referenceValue;
  const referencedAction = getActionById(referencedActionId!, actions)!;

  // Currently condition could also be other action output, e.g. output of GetElementAction
  const isConditionSmartText = !!referencedAction?.jsFunction;

  const initialText = isConditionSmartText
    ? referencedAction.description
    : action.description;

  const onUpdate = (v: string) => {
    const updates = isConditionSmartText
      ? [
          // TODO: Regenerate and update JsFunction
          updateAction(
            referencedAction,
            {
              action: { ...referencedAction },
              description: v,
            },
            processId,
          ),
          updateAction(
            action,
            {
              action: { ...action },
              description: `If ${v}`,
            },
            processId,
          ),
        ]
      : [
          updateAction(
            action,
            {
              action: { ...action },
              description: `If ${v}`,
            },
            processId,
          ),
        ];

    updates.forEach((update) => dispatch(update));
  };

  return (
    <WidgetContainer>
      <ItemDescription>If</ItemDescription>
      <EditableText
        variant='chip'
        fontSize={16}
        fontWeight={400}
        initialText={initialText ?? ''}
        emptyErrorMessage={'condition cannot be empty'}
        placeholder={'type in the condition'}
        initialEditing={false}
        disabled={!isEditable}
        onTextEditFinish={onUpdate}
      />
    </WidgetContainer>
  );
};

export default IfCondition;
