import { Box } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo } from 'react';
import * as Yup from 'yup';
import CustomTypography from '../../../components/CustomTypography';
import { SettingsOutlined } from '@mui/icons-material';

interface Props {
  workflow: Workflow;
  onSubmit: (values: FormikValues) => void;
  edit?: boolean;
  hideNote?: boolean;
}

const WorkflowGeneralContent: FC<Props> = ({
  onSubmit,
  workflow,
  edit,
  hideNote,
}) => {
  const formik = useFormik({
    initialValues: {
      workflow_name: workflow.displayName || '',
      description: workflow.description || '',
      time_spent: workflow.manualTimeCostInMinutes || '',
    },
    validationSchema: Yup.object({
      workflow_name: Yup.string()
        .required('Workflow name is required.')
        .matches(/^[^/]*$/, 'Workflow name cannot contain "/".')
        .max(100, 'Workflow name must be at most 100 characters.'),
      time_spent: Yup.number().test(
        'positive',
        'Please provide a positive number for Time spent on the process manually (minutes)',
        (value) => (value as number) > 0,
      ),
    }),
    onSubmit: onSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id='form1' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box display={'flex'} gap={'50px'} justifyContent={'space-between'}>
          <Box width={'70%'} marginBottom={'15px'}>
            <Box marginLeft={'14px'} marginTop={'21px'}>
              <CustomTextField
                label='Workflow name'
                name='workflow_name'
                size='small'
                value={''}
                extraProps={getFieldProps('workflow_name')}
                error={Boolean(touched.workflow_name && errors.workflow_name)}
                helperText={touched.workflow_name && errors.workflow_name}
                hasDescription={true}
                autoFocus
                color={edit ? '#DDDBDA' : ''}
                disabled={edit}
              />
            </Box>
            <Box marginLeft={'14px'} marginTop={'11px'}>
              <CustomTextField
                label='Description'
                name='description'
                size='small'
                value={''}
                extraProps={getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
            <Box marginLeft={'14px'} marginTop={'11px'}>
              <CustomTextField
                name='time_spent'
                type='number'
                label='Time spent on the process manually (minutes)'
                size='small'
                value={''}
                extraProps={getFieldProps('time_spent')}
                error={Boolean(touched.time_spent && errors.time_spent)}
                helperText={touched.time_spent && errors.time_spent}
                required={true}
                hasDescription={true}
              />
            </Box>
          </Box>
          {!hideNote && (
            <Box
              bgcolor={'#E3FCEF'}
              borderRadius={'10px'}
              height={'fit-content'}
              padding={'16px'}
              display={'flex'}
              gap={'10px'}
              marginTop={'30px'}
              marginRight={5}
              width={'360px'}
            >
              <SettingsOutlined sx={{ color: '#3BA755' }} />
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                paddingRight={'10px'}
              >
                <span id='workflow_name'>
                  <CustomTypography color='#545454' size='14px'>
                    &quot;Workflow name&quot; needs to be unique in your
                    organization.
                  </CustomTypography>
                </span>
                <span id='time_spent'>
                  <CustomTypography
                    sx={{ marginTop: '10px' }}
                    color='#545454'
                    size='14px'
                  >
                    &quot;Time spent on the process manually&quot; is used to
                    calculate time that Orby saves for you through this
                    workflow.
                  </CustomTypography>
                </span>
                {!edit && (
                  <CustomTypography
                    sx={{ marginTop: '10px' }}
                    color='#545454'
                    size='14px'
                  >
                    Name cannot be changed after workflow creation.
                  </CustomTypography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(WorkflowGeneralContent);
