import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateActivityGraphAction } from '../../redux/actions/automation_mining.action';
import { activityGraphSelector } from '../../redux/selectors/automation_mining.selectors';
import './GeneratedGraph.css';
// TODO:
// Removed the below package since it was causing error with yarn
// and we are not using it right now.
// will work on it later when required
// import DatetimeRangePicker from '@wojtekmaj/react-datetimerange-picker'

const GeneratedGraph: React.FC = () => {
  const [range] = useState([new Date(Date.now() - 86400000), new Date()] as [
    Date,
    Date,
  ]);
  const dispatch = useDispatch();
  const activityGraph = useSelector(activityGraphSelector);
  const svgXML = activityGraph ? atob(activityGraph) : '';
  const fetchGraph = () => {
    dispatch(generateActivityGraphAction(range[0], range[1]));
  };

  return (
    <Box marginX={4} marginTop={4}>
      <Box
        display={'flex'}
        marginTop={6}
        marginBottom={8}
        alignItems={'center'}
      >
        {/* <DatetimeRangePicker
          className={'date-range'}
          onChange={setRange}
          value={range}
        /> */}
        <Box sx={{ mx: 2 }}> </Box>
        <Button
          variant='contained'
          size='medium'
          color='primary'
          type='submit'
          onClick={fetchGraph}
        >
          Fetch Graphic
        </Button>
      </Box>
      <Box
        display={'flex'}
        marginTop={6}
        marginBottom={8}
        alignItems={'center'}
        dangerouslySetInnerHTML={{ __html: svgXML }}
      ></Box>
    </Box>
  );
};

export default GeneratedGraph;
