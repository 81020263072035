import {
  Close,
  DoneOutlined,
  FiberManualRecordRounded,
  ModeEditOutlineOutlined,
} from '@mui/icons-material';
import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import React, { FC, memo } from 'react';

interface TopBarTaskStepsProps {
  activeStep: number;
  steps: string[];
}

const TopBarTaskSteps: FC<TopBarTaskStepsProps> = ({ activeStep, steps }) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        marginRight: '7px',
        '.css-1i4nyer-MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          margin: '4px 4px 0px 0px',
        },
      }}
      connector={<ColorlibConnector />}
    >
      {steps.map((label: string) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => (
              <ColorlibStepIcon
                stepProps={props}
                rejected={label === 'Rejected'}
              />
            )}
          >
            <CustomTypography typographyType={TypographyType.Label}>
              {label}
            </CustomTypography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default memo(TopBarTaskSteps);

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(() => ({
  backgroundColor: '#B6B6B6',
  zIndex: 1,
  color: '#fff',
  width: 16,
  height: 16.5,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: ' #3BA755',
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: ' #3BA755',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#B6B6B6',
    borderRadius: 1,
    top: '-4px',
    left: 'calc(-50% - 0px)',
    right: 'calc(-50% - 0px)',
    position: 'absolute',
  },
}));

interface IconProps {
  stepProps: StepIconProps;
  rejected?: boolean;
}

function ColorlibStepIcon(props: IconProps) {
  const defaultIcon = () => {
    return (
      <FiberManualRecordRounded
        sx={{
          position: 'absolute',
          color: '#B6B6B6',
          bgcolor: '#FFFFFF',
          fontSize: '12.5px',
          borderRadius: '50%',
        }}
      />
    );
  };

  const finalIcon = (rejected?: boolean) => {
    if (rejected)
      return (
        <Close
          sx={{
            bgcolor: '#E8684A',
            borderRadius: '50%',
            color: '#FFFFFF',
            fontSize: '16px',
            padding: '1px',
          }}
        />
      );
    return (
      <DoneOutlined
        sx={{
          bgcolor: '#3BA755',
          borderRadius: '50%',
          color: '#FFFFFF',
          fontSize: '16px',
          padding: '1px',
        }}
      />
    );
  };
  const { stepProps, rejected } = props;
  const { active, completed, className } = stepProps;

  const icons: { [index: string]: React.ReactElement } = {
    1: completed ? finalIcon() : defaultIcon(),
    2: active ? (
      <ModeEditOutlineOutlined
        sx={{
          marginTop: '1px',
          borderRadius: '50%',
          bgcolor: '#0176D3',
          color: '#FFFFFF',
          padding: '3px',
          fontSize: '20px',
        }}
      />
    ) : completed ? (
      finalIcon()
    ) : (
      defaultIcon()
    ),
    3: completed ? finalIcon(rejected) : defaultIcon(),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(stepProps.icon)]}
    </ColorlibStepIconRoot>
  );
}
