import React, { FC, memo, useEffect } from 'react';
import PdfIcon from '../static/icons/pdf-icon.svg';
import { File } from 'protos/automation_mining/ontology/data_models';
import CustomFilePicker from './CustomFilePicker';
import ImageIcon from '@mui/icons-material/Image';
import { SvgIconComponent } from '@mui/icons-material';

interface Props {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  workflowName: string;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
}

const CustomMultiTypeFilePicker: FC<Props> = ({
  files,
  setFiles,
  workflowName,
  error,
  setError,
  isUploading,
}) => {
  useEffect(() => {
    // Reset error state when workflow name changes
    setError(false);
  }, [workflowName]);

  const fileIconMap = new Map<string, string | SvgIconComponent>([
    ['application/pdf', PdfIcon],
    ['image/png', ImageIcon],
    ['image/jpeg', ImageIcon],
    ['image/gif', ImageIcon],
    ['image/tiff', ImageIcon],
  ]);

  const acceptedMIMEFormat = Array.from(fileIconMap.keys());

  return (
    <CustomFilePicker
      files={files}
      setFiles={setFiles}
      error={error}
      setError={setError}
      acceptedFormat={'.pdf, .png, .jpeg, .jpg, .gif, .tiff, .tif'}
      acceptedMIMEFormat={acceptedMIMEFormat}
      acceptedFormatMessage={
        'Supported file formats: .pdf, .png, .jpeg, .jpg, .gif, .tiff, .tif'
      }
      fileIcon={fileIconMap}
      allowMultipleFiles={true}
      isUploading={isUploading}
    />
  );
};

export default memo(CustomMultiTypeFilePicker);
