/**
 * This file is used to show the side bar on the left side
 * on the Review Page to show the detail of entities which are
 * present in the document and the confidence score of the document
 */
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import ConfidenceScore from './components/ConfidenceScore';
import ReviewFilterSection from './components/ReviewFilterSection/ReviewFilterSection';
import { useSelector } from 'react-redux';
import {
  getDocumentActivityTypeSelector,
  getSelectedReviewFilterSectionSelector,
  taskForReviewSelector,
} from '../../../../redux/selectors/review_task.selectors';
import { Activity } from 'protos/automation_mining/automation_mining';
import ClassificationOptions from './components/classificationOptions';
import ReviewPageFooter from './components/ReviewPageFooter';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import SideBarEntitiesList from './components/EntitiesInfo/SideBarEntitiesList';

interface Props {
  debug?: boolean;
}

const ReviewPageSideBar: React.FC<Props> = ({ debug }) => {
  const task = useSelector(taskForReviewSelector);
  /**
   * We will select the initial review filter by selecting the first non empty filter
   */
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const documentActivityType = useSelector(getDocumentActivityTypeSelector);

  const showFooter = useMemo(
    () =>
      task?.status === TaskSTATUS.READY ||
      task?.status === TaskSTATUS.COMPLETED,
    [task?.status],
  );

  return (
    <>
      <Box
        position={'relative'}
        bgcolor={'#fefeff'}
        boxShadow='4px 0px 4px #eef0f4'
        overflow={'hidden'}
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
      >
        <ConfidenceScore />

        {documentActivityType === Activity.CLASSIFY_DOCUMENT ? (
          <ClassificationOptions />
        ) : (
          <>
            <ReviewFilterSection
              selectedReviewFilterSection={selectedReviewFilterSection}
            />
            <SideBarEntitiesList
              task={task as Task}
              selectedReviewFilterSection={selectedReviewFilterSection}
            />
          </>
        )}
        {showFooter && <ReviewPageFooter debug={debug} />}
      </Box>
    </>
  );
};

export default React.memo(ReviewPageSideBar);
