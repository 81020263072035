import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import './step.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ApplicationName } from '../../../../utils/protos/enums';
import { getActionIndex } from '../../../../utils/helpers';
import WarningIcon from '../../../../static/icons/warning_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../../static/icons/settings-rounded.svg';
import { ReactComponent as AddBlackIcon } from '../../../../static/icons/add-black.svg';
import {
  ORBYAI_UNKNOWN,
  invalidCharactersClassificationMsg,
} from '../../../../utils/constants';
import { hasInvalidClassificationCharacters } from '../../../../utils/entities';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface Props {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  edit?: boolean;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

interface ClassificationError {
  label: string;
  error: string;
}

const defaultClassificationLabels = [ORBYAI_UNKNOWN];
const maxLabels = 8;

const ClassificationSchemaDefinition: FC<Props> = ({
  onSubmit,
  workflow,
  edit = false,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  const defaultClassifications = defaultClassificationLabels;
  const classificationLabelsIndex = getActionIndex(
    workflow,
    ApplicationName.DocumentClassification,
  );
  const mappingColumns =
    workflow?.steps?.[classificationLabelsIndex.stepIndex!]?.actions?.[
      classificationLabelsIndex.actionIndex!
    ]?.classification?.classificationLabels ?? [];
  const [schemaClassificationLabels, setSchemaClassificationLabels] =
    useState(mappingColumns);
  const [value, setValue] = useState('');

  const showErrorMessage = () => {
    const findNormalClassificationLabel = schemaClassificationLabels.find(
      (e) => e.toLowerCase() !== defaultClassificationLabels[0].toLowerCase(),
    );
    return !findNormalClassificationLabel;
  };

  const formik = useFormik({
    initialValues: {
      schemaClassificationLabels: mappingColumns,
    },
    validationSchema: Yup.object({
      schemaClassificationLabels: Yup.array()
        .min(1, 'Please add at least one document type')
        .required('Please add at least one document type')
        .test('test-contain-all-types', function () {
          if (showErrorMessage()) {
            return this.createError({
              path: this.path,
              message: 'Please add at least one document type',
            });
          } else {
            return true;
          }
        }),
    }),
    onSubmit: (values) => {
      onSubmit(values, true);
    },
  });

  const [classificationErrors, setClassificationErrors] = useState<
    ClassificationError[]
  >([]);

  const handleAddClassificationLabel = (classificationLabels: string) => {
    setClassificationErrors([]);
    const classificationLabelErrorList: string[] = [];
    const classificationLabelInvalidList: string[] = [];
    const finalClassificationLabelList: string[] = [];
    const classificationLabelList = classificationLabels.split(',');
    let newValue = '';
    classificationLabelList.forEach((classificationLabel: string) => {
      if (classificationLabel.trim() === '') {
        return;
      }
      let classificationLabelName = classificationLabel
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim();

      classificationLabelName = classificationLabelName.replace('/ ', '/');
      classificationLabelName = classificationLabelName.replace(' /', '/');

      if (classificationLabelName == defaultClassifications[0].toLowerCase()) {
        classificationLabelErrorList.push(classificationLabelName);
        return;
      }

      const index = schemaClassificationLabels.findIndex(
        (en) => en == classificationLabelName,
      );
      if (
        index != -1 ||
        finalClassificationLabelList.includes(classificationLabelName)
      ) {
        classificationLabelErrorList.push(classificationLabelName);
        return;
      } else if (hasInvalidClassificationCharacters(classificationLabelName)) {
        classificationLabelInvalidList.push(classificationLabelName);
        return;
      }
      finalClassificationLabelList.push(classificationLabelName);
    });

    const newSchemaClassificationLabels = [
      ...schemaClassificationLabels,
      ...finalClassificationLabelList,
    ];

    if (
      newSchemaClassificationLabels.length - defaultClassifications.length >=
      maxLabels
    ) {
      setClassificationErrors([
        {
          label: 'Limit reached',
          error: `You can add a maximum of ${maxLabels} entities`,
        },
      ]);
      return;
    }

    if (classificationLabelErrorList.length > 0) {
      const errors = classificationLabelErrorList.map((label) => ({
        label,
        error: 'Already Exists',
      }));
      setClassificationErrors((prev) => [...prev, ...errors]);
      newValue = classificationLabelErrorList.join(',');
    }

    if (classificationLabelInvalidList.length > 0) {
      const errors = classificationLabelInvalidList.map((label) => ({
        label,
        error: invalidCharactersClassificationMsg,
      }));
      setClassificationErrors((prev) => [...prev, ...errors]);
      newValue = newValue
        ? `${newValue}, ${classificationLabelInvalidList.join(',')}`
        : classificationLabelInvalidList.join(',');
    }

    setSchemaClassificationLabels(newSchemaClassificationLabels);
    setFieldValue('schemaClassificationLabels', newSchemaClassificationLabels);
    setValue(newValue);
  };

  const handleDelete = (classificationLabel: string) => {
    const filteredClassificationLabels = schemaClassificationLabels.filter(
      (u) => classificationLabel !== u,
    );
    if (filteredClassificationLabels.length < maxLabels) {
      setClassificationErrors([]);
    }
    setSchemaClassificationLabels(filteredClassificationLabels);
    setFieldValue('schemaClassificationLabels', filteredClassificationLabels);
  };

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  const { handleSubmit, setFieldValue, touched, errors } = formik;
  return (
    <FormikProvider value={formik}>
      <Form
        id={formId}
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
        style={{ width: '100%' }}
      >
        <Box
          display={'flex'}
          marginY={'32px'}
          gap={'51px'}
          paddingLeft={'19px'}
          justifyContent={'space-between'}
        >
          <Box flex={1}>
            <OrbyTypography
              weight='medium'
              color={OrbyColorPalette['grey-900']}
              sx={{
                paddingBottom: '6px',
              }}
            >
              Define document types to classify
            </OrbyTypography>
            <Box sx={{ width: '400px' }}>
              <OrbyTextField
                aria-label='Define document types to classify.'
                aria-describedby='classification_schema'
                width='400px'
                disabled={edit}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleAddClassificationLabel(
                      (event.target as HTMLInputElement).value,
                    );
                  }
                }}
                placeholder='Enter document type'
                value={value}
                onChange={(event) => setValue(event.target.value)}
                endAdornment={
                  <IconButton
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={() => handleAddClassificationLabel(value)}
                  >
                    <AddBlackIcon aria-label='Add entity.' />
                  </IconButton>
                }
              />
            </Box>
            {Boolean(
              touched.schemaClassificationLabels &&
                errors.schemaClassificationLabels,
            ) && (
              <Box>
                <OrbyTypography color={OrbyColorPalette['error-500']}>
                  {
                    (touched.schemaClassificationLabels &&
                      errors.schemaClassificationLabels) as string
                  }
                </OrbyTypography>
              </Box>
            )}
            {classificationErrors.length > 0 && (
              <Box>
                <Box padding={'4px 0'} display={'flex'}>
                  <img
                    alt='Warning Icon'
                    style={{ height: '18px', paddingRight: '4px' }}
                    src={WarningIcon}
                  />
                  <OrbyTypography
                    color={OrbyColorPalette['error-500']}
                    sx={{ paddingLeft: '4px' }}
                  >
                    There {classificationErrors.length === 1 ? 'is' : 'are'}{' '}
                    {classificationErrors.length}{' '}
                    {classificationErrors.length === 1 ? 'warning' : 'warnings'}
                    :
                  </OrbyTypography>
                </Box>
                <ul
                  style={{
                    fontSize: '10px',
                    paddingLeft: '25px',
                    color: OrbyColorPalette['error-500'],
                  }}
                >
                  {classificationErrors.map((entity, index) => {
                    return (
                      <li key={index}>
                        {entity.label}: {entity.error}
                      </li>
                    );
                  })}
                </ul>
              </Box>
            )}
            <Box width={'100%'}>
              <Tooltip
                placement='right'
                title='The OrbyAI_Unknown type will cover various miscellaneous types'
              >
                <Chip
                  className='custom-chip-icon-right'
                  sx={{
                    marginRight: '10px',
                    marginTop: '15px',
                    textTransform: 'capitalize',
                  }}
                  icon={<HelpOutlineIcon />}
                  label={defaultClassifications[0]}
                />
              </Tooltip>
              {schemaClassificationLabels.map((classificationLabel) => {
                return (
                  <Chip
                    sx={{ marginRight: '10px', marginTop: '15px' }}
                    key={classificationLabel}
                    label={classificationLabel}
                    onDelete={() => handleDelete(classificationLabel)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleDelete(classificationLabel);
                      }
                    }}
                  />
                );
              })}
            </Box>
          </Box>

          {/* INFO BOX */}
          <Box
            bgcolor={OrbyColorPalette['green-50']}
            borderRadius={'10px'}
            height={'fit-content'}
            padding={'16px'}
            display={'flex'}
            gap={'16px'}
            width={'315px'}
          >
            <Box>
              <SettingsIcon style={{ color: OrbyColorPalette['green-500'] }} />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'8px'}
              paddingRight={'10px'}
            >
              <span id={'classification_schema'}>
                <OrbyTypography
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  Please provide the types of documents you wish to{' '}
                  <strong>classify</strong>, such as Proposals, Statements of
                  Work (SOW), Quotes, Business contracts, and Invoices as
                  examples.{' '}
                  <strong>
                    Each document will be predicted with one type only so please
                    avoid overlapped definitions among the types.
                  </strong>
                </OrbyTypography>
                <OrbyTypography
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  You can create types in two different ways:
                </OrbyTypography>
                <OrbyTypography
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  1. Enter the type individually and press the enter key
                </OrbyTypography>
                <OrbyTypography
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  2. Enter type one by one, separated by commas, and then press
                  the enter key to create them in bulk
                </OrbyTypography>
              </span>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(ClassificationSchemaDefinition);
