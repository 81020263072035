import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { FC, memo, useEffect, useState, ChangeEvent } from 'react';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import { Check, Search } from '@mui/icons-material';
import {
  ADMIN_TASK_FILTER,
  ELLIPSIS_STYLE,
  Filter,
  USER_TASK_FILTER,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  userFiltersLoadingSelector,
  userFiltersSelector,
} from '../../redux/selectors/filter_options.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { getUserFiltersAction } from '../../redux/actions/filter_options.action';
import { isAdmin } from '../../utils/helpers';
import { debounce } from 'lodash';

interface CustomMenuItemProps {
  key: string;
  value: string;
  onClick: () => void;
  label?: string;
  isSelected?: boolean;
}

interface Props {
  selectedUsers: Set<string>;
  setSelectedUsers: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedOption: string;
  setSelectedOption: (x: string) => void;
}

const TaskFilterSelect: FC<Props> = ({
  selectedOption,
  selectedUsers,
  setSelectedOption,
  setSelectedUsers,
}) => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const dispatch = useDispatch();
  const [userOptions, setUserOptions] = useState<Filter[]>([]);
  const userFilters = useSelector(userFiltersSelector);
  const userFiltersLoading = useSelector(userFiltersLoadingSelector);
  const loggedInUser = useSelector(loggedInUserSelector) ?? {};

  const renderLabel = (label: string, selected = false) => {
    return (
      <CustomTypography
        typographyType={TypographyType.Header4}
        color={selected ? '#475467' : '#101828'}
        weight={500}
        sx={{
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </CustomTypography>
    );
  };

  useEffect(() => {
    if (selectedOrgInfo && isAdminView) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
  }, [selectedOrgInfo]);

  const handleRenderValue = () => {
    let title = selectedOption;
    if (isAdminView && selectedOption === ADMIN_TASK_FILTER.REVIEWERS_ONLY) {
      const noOfUsers = selectedUsers.size;
      const [firstElem] = selectedUsers;
      title = noOfUsers === 1 ? firstElem || '' : noOfUsers + ' ' + 'users';
    }
    if (title === loggedInUser.email) {
      title = 'Assigned to me';
    }
    return <Box title={title}>{renderLabel(title)}</Box>;
  };

  const handleSetUserOptions = (options: Filter[]) => {
    // before setting the values filter out the logged in user's email
    // since it is shown separately in the list as 'Assigned to me'
    setUserOptions(options.filter((user) => user.label !== loggedInUser.email));
  };

  // Initialize user options state
  useEffect(() => {
    if (userFilters.length) {
      handleSetUserOptions(userFilters);
    }
  }, [userFilters]);

  const handleUserSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const newOptions = userFilters.filter((o) =>
      o.label.toLowerCase().includes(value),
    );
    handleSetUserOptions(newOptions);
  };

  const handleUserClick = (user: Filter) => {
    if (selectedOption === ADMIN_TASK_FILTER.ALL_TASKS) {
      setSelectedOption(ADMIN_TASK_FILTER.REVIEWERS_ONLY);
    } else if (selectedOption === ADMIN_TASK_FILTER.UNASSIGNED_ONLY) {
      setSelectedOption(ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS);
    } else if (
      selectedOption === ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS &&
      selectedUsers.has(user.label) &&
      selectedUsers.size === 1
    ) {
      setSelectedOption(ADMIN_TASK_FILTER.UNASSIGNED_ONLY);
    } else if (
      selectedOption === ADMIN_TASK_FILTER.REVIEWERS_ONLY &&
      selectedUsers.has(user.label) &&
      selectedUsers.size === 1
    ) {
      setSelectedOption(ADMIN_TASK_FILTER.ALL_TASKS);
    }
    setSelectedUsers((prev) => {
      if (prev.has(user.label)) {
        prev.delete(user.label);
        return new Set(prev);
      }
      return new Set(prev.add(user.label));
    });
  };

  const getSelectedOptions = () => {
    const selectedOptions = [...selectedUsers.values()];
    if (selectedOption === ADMIN_TASK_FILTER.ALL_TASKS) {
      return [ADMIN_TASK_FILTER.ALL_TASKS];
    } else if (
      [
        ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
        ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
      ].includes(selectedOption as ADMIN_TASK_FILTER)
    ) {
      return [...selectedOptions, ADMIN_TASK_FILTER.UNASSIGNED_ONLY];
    }
    return selectedOptions;
  };

  const renderMenuItem = ({
    key,
    value,
    onClick,
    label = value,
    isSelected = false,
  }: CustomMenuItemProps) => {
    return (
      <MenuItem onClick={onClick} key={key} value={value}>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box
            width={'80%'}
            display={'flex'}
            gap={1}
            alignItems={'center'}
            title={label}
          >
            <CustomTypography
              weight={500}
              size='16px'
              lineHeight='24px'
              color='#101828'
            >
              {label}
            </CustomTypography>
          </Box>

          {isSelected ? (
            <Check sx={{ color: '#7F56D9' }} />
          ) : (
            <Box width={'24px'} />
          )}
        </Box>
      </MenuItem>
    );
  };

  return isAdminView ? (
    <Select
      multiple
      onClose={() => handleSetUserOptions(userFilters)} // reset search on close
      renderValue={() => handleRenderValue()}
      sx={{
        paddingBottom: '8px',
        paddingRight: '14px',
        height: '44px',
        width: '225px',
        paddingTop: '12px',
      }}
      value={getSelectedOptions()}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        sx: {
          '& .MuiMenu-paper': {
            maxHeight: '350px',
            width: isAdminView ? '445px' : 'max-content',
            overflowY: 'auto',
          },
        },
      }}
    >
      {renderMenuItem({
        onClick: () => {
          setSelectedUsers(new Set()); // Empty the selected users
          setSelectedOption(ADMIN_TASK_FILTER.ALL_TASKS);
        },
        key: ADMIN_TASK_FILTER.ALL_TASKS,
        value: ADMIN_TASK_FILTER.ALL_TASKS,
        isSelected: selectedOption === ADMIN_TASK_FILTER.ALL_TASKS,
      })}

      {renderMenuItem({
        onClick: () => {
          if (selectedUsers.size) {
            if (
              [
                ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
                ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
              ].includes(selectedOption as ADMIN_TASK_FILTER)
            ) {
              setSelectedOption(ADMIN_TASK_FILTER.REVIEWERS_ONLY);
              return;
            }
            setSelectedOption(ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS);
            return;
          }
          setSelectedOption(ADMIN_TASK_FILTER.UNASSIGNED_ONLY);
        },
        key: ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
        value: ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
        isSelected: [
          ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
          ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
        ].includes(selectedOption as ADMIN_TASK_FILTER),
      })}
      {renderMenuItem({
        onClick: () =>
          handleUserClick({
            value: loggedInUser.email as string,
            label: loggedInUser.email as string,
          }),
        key: loggedInUser.email as string,
        value: loggedInUser.email as string,
        label: 'Assigned to me',
        isSelected: selectedUsers.has(loggedInUser.email as string),
      })}
      <TextField
        variant='outlined'
        fullWidth
        autoComplete='search'
        type='search'
        placeholder={'Search email'}
        onChange={debounce((e) => handleUserSearch(e), 300)}
        sx={{
          marginBottom: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '10px 16px',
            paddingLeft: '4px',
            '::placeholder': { color: '#667085', opacity: 1 },
          },
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        InputProps={{
          startAdornment: (
            <Box>
              <InputAdornment position='start'>
                <Search sx={{ color: '#667085' }} fontSize='medium' />
              </InputAdornment>
            </Box>
          ),
        }}
      />
      {userFiltersLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={'30px'} />
        </Box>
      ) : (
        userOptions.map((option) =>
          renderMenuItem({
            onClick: () => handleUserClick(option),
            key: option.value,
            value: option.label,
            label: option.label,
            isSelected: selectedUsers.has(option.label),
          }),
        )
      )}
    </Select>
  ) : (
    <Select
      multiple={false}
      renderValue={() => handleRenderValue()}
      sx={{
        paddingY: '8px',
        paddingRight: '14px',
        marginBottom: '4px',
        height: '40px',
        width: '230px',
      }}
      value={selectedOption}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        sx: {
          '& .MuiMenu-paper': {
            maxHeight: '350px',
            width: isAdminView ? '445px' : 'max-content',
            overflowY: 'auto',
          },
        },
      }}
    >
      {Object.values(USER_TASK_FILTER).map((e) =>
        renderMenuItem({
          onClick: () => {
            setSelectedOption(e);
          },
          value: e,
          key: e,
          isSelected: selectedOption === e,
        }),
      )}
    </Select>
  );
};

export default memo(TaskFilterSelect);
