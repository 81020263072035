import React, { ReactElement } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { OrbyColorPalette } from 'orby-ui/src';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['grey-500'],
    maxWidth: 328,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 0px 16px -4px #10182826',
  },
}));

interface CustomTooltipProps {
  title: ReactElement;
  children: ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  return <HtmlTooltip title={title}>{children}</HtmlTooltip>;
};

export default CustomTooltip;
