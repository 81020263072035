import React from 'react';
import {
  OrbySelect,
  OrbyMenuItem,
  OrbyTypography,
  OrbyColorPalette,
} from 'orby-ui/src';
import { ELLIPSIS_STYLE } from '../../../utils/constants';

export interface Props {
  selectedWorkflowType: string;
  setSelectedWorkflowType: (data: string) => void;
  width?: string;
  menuWidth?: string;
}

const WorkflowTypesFilter: React.FC<Props> = ({
  selectedWorkflowType,
  setSelectedWorkflowType,
  width = '200px',
  menuWidth = '200px',
}) => {
  const DEFAULT_WORKFLOW_TYPE = {
    label: 'All workflow types',
    value: 'all-workflow-types',
  };

  const WORKFLOW_TYPES = [
    {
      label: 'Classification',
      value: 'classification',
    },
    {
      label: 'Extraction',
      value: 'extraction',
    },
    {
      label: 'Others',
      value: 'others',
    },
  ];

  return (
    <>
      <OrbySelect
        value={selectedWorkflowType}
        renderValue={(value) => {
          return (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
              sx={{
                ...ELLIPSIS_STYLE,
              }}
            >
              {value
                ? WORKFLOW_TYPES.find((r) => r.value === value)?.label
                : DEFAULT_WORKFLOW_TYPE.label}
            </OrbyTypography>
          );
        }}
        width={width}
        onChange={(event) => {
          const value = event.target.value as string;
          if (value) {
            if (value === DEFAULT_WORKFLOW_TYPE.value) {
              setSelectedWorkflowType('');
            } else {
              setSelectedWorkflowType(value);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={menuWidth}
          key={DEFAULT_WORKFLOW_TYPE.value}
          title={DEFAULT_WORKFLOW_TYPE.label}
          value={DEFAULT_WORKFLOW_TYPE.value}
          isSelected={selectedWorkflowType === ''}
        />
        {WORKFLOW_TYPES.map((role) => {
          return (
            <OrbyMenuItem
              width={menuWidth}
              key={role.value}
              title={role.label}
              value={role.value}
              isSelected={selectedWorkflowType === role.value}
            />
          );
        })}
      </OrbySelect>
    </>
  );
};

export default React.memo(WorkflowTypesFilter);
