import produce from 'immer';
import { Reducer } from 'redux';
import { SuggestionActionType } from '../actions/actions.constants';
import { Activity } from 'protos/automation_mining/automation_mining';
import { Suggestion, SuggestionSTATUS } from 'protos/pb/v1alpha1/suggestion';
import {
  SuggestionStep,
  SuggestionStepOption,
} from 'protos/pb/v1alpha1/suggestion_step';
import {
  Document,
  DocumentEntity,
} from 'protos/google/cloud/documentai/v1/document';
import { SummarizeSuggestionsResponse } from 'protos/pb/v1alpha1/suggestions_service';
import { cloneDeep } from 'lodash';

export interface SuggestionState {
  loadingSuggestionByName: boolean;
  loadedSuggestionByName: boolean;
  loadingSuggestions: boolean;
  loadingSummary: boolean;
  updatingSuggestions: boolean;
  readySuggestionsLoaded: boolean;
  completedSuggestionsLoaded: boolean;
  readySuggestions: Suggestion[];
  completedSuggestions: Suggestion[];
  nextPageToken?: string;
  totalSize?: number;
  summary?: SummarizeSuggestionsResponse;
  selectedSuggestionName?: string;
  selectedStatus?: number;
  nextStepError?: string;
}

const initialState: SuggestionState = {
  loadingSuggestionByName: false,
  loadedSuggestionByName: false,
  loadingSuggestions: false,
  loadingSummary: false,
  updatingSuggestions: false,
  readySuggestionsLoaded: false,
  completedSuggestionsLoaded: false,
  readySuggestions: [],
  completedSuggestions: [],
};

export const suggestionReducer: Reducer<SuggestionState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: SuggestionState = initialState,
  action,
) =>
  produce(state, (draft: SuggestionState) => {
    switch (action.type) {
      case SuggestionActionType.LIST_READY_SUGGESTIONS:
        draft.loadingSuggestions = !draft.readySuggestionsLoaded;
        draft.readySuggestionsLoaded = true;
        break;
      case SuggestionActionType.LIST_COMPLETED_SUGGESTIONS: {
        draft.loadingSuggestions = !draft.completedSuggestionsLoaded;
        draft.completedSuggestionsLoaded = true;
        break;
      }
      case SuggestionActionType.LIST_READY_SUGGESTIONS_COMPLETED: {
        draft.readySuggestions = [...action.payload];
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingSuggestions = false;
        break;
      }
      case SuggestionActionType.LIST_READY_SUGGESTIONS_ERROR:
        draft.readySuggestions = [];
        draft.loadingSuggestions = false;
        break;
      case SuggestionActionType.LIST_COMPLETED_SUGGESTIONS_COMPLETED: {
        const suggestions = action.payload;
        suggestions.forEach((suggestion: Suggestion) => {
          const index: number = draft.completedSuggestions.findIndex(
            (s) => s.name === suggestion.name,
          );
          if (index < 0) draft.completedSuggestions.push(suggestion);
        });
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingSuggestions = false;
        break;
      }
      case SuggestionActionType.LIST_COMPLETED_SUGGESTIONS_ERROR:
        draft.completedSuggestions = [];
        draft.loadingSuggestions = false;
        break;
      case SuggestionActionType.SET_SELECTED_SUGGESTION:
        draft.selectedSuggestionName = action.payload.name;
        draft.selectedStatus = action.payload.status;
        break;
      case SuggestionActionType.UPDATE_SUGGESTION:
      case SuggestionActionType.EXECUTE_SUGGESTION_STEP:
        draft.updatingSuggestions = true;
        break;
      case SuggestionActionType.UPDATE_SUGGESTION_COMPLETED: {
        const suggestion: Suggestion = action.payload;
        const suggestions: Suggestion[] = draft.readySuggestions;
        const index: number = suggestions.findIndex(
          (s) => s.name === suggestion.name,
        );
        if (suggestion.status !== SuggestionSTATUS.READY) {
          suggestions.splice(index, 1);
          if (suggestion.status === SuggestionSTATUS.ACCEPTED)
            draft.completedSuggestions.push(suggestion);
        } else {
          suggestions.splice(index, 1, suggestion);
        }
        draft.readySuggestions = suggestions;
        draft.updatingSuggestions = false;
        break;
      }
      case SuggestionActionType.UPDATE_SUGGESTION_ERROR:
        draft.updatingSuggestions = false;
        break;
      case SuggestionActionType.BATCH_UPDATE_SUGGESTION:
        draft.updatingSuggestions = true;
        break;
      case SuggestionActionType.BATCH_UPDATE_SUGGESTION_COMPLETED: {
        const suggestions: Suggestion[] = action.payload;
        const currentList = draft.readySuggestions;
        suggestions.forEach((s) => {
          const index: number = currentList.findIndex(
            (newS) => newS.name === s.name,
          );
          if (index >= 0 && s.status != SuggestionSTATUS.READY) {
            currentList.splice(index, 1);
          }
          if (s.status == SuggestionSTATUS.ACCEPTED) {
            draft.completedSuggestions.push(s);
          }
        });
        draft.updatingSuggestions = false;
        break;
      }
      case SuggestionActionType.BATCH_UPDATE_SUGGESTION_ERROR:
        draft.updatingSuggestions = false;
        break;
      case SuggestionActionType.GET_SUGGESTION_SUMMARY:
        draft.loadingSummary = true;
        break;
      case SuggestionActionType.GET_SUGGESTION_SUMMARY_COMPLETED: {
        draft.summary = action.payload;
        draft.loadingSummary = false;
        break;
      }
      case SuggestionActionType.GET_SUGGESTION_SUMMARY_ERROR:
        draft.loadingSummary = false;
        break;
      case SuggestionActionType.GET_SUGGESTION_BY_NAME:
        draft.loadedSuggestionByName = false;
        draft.loadingSuggestionByName = true;
        break;
      case SuggestionActionType.GET_SUGGESTION_BY_NAME_COMPLETED: {
        const suggestion: Suggestion = action.payload;
        draft.selectedSuggestionName = action.payload.name;
        draft.selectedStatus = action.payload.status;
        const suggestions: Suggestion[] =
          suggestion.status === SuggestionSTATUS.ACCEPTED
            ? draft.completedSuggestions
            : draft.readySuggestions;
        const index: number = suggestions.findIndex(
          (s) => s.name === suggestion.name,
        );
        if (index >= 0) {
          suggestions.splice(index, 1, suggestion);
        } else {
          suggestions.push(suggestion);
        }
        if (suggestion.status === SuggestionSTATUS.ACCEPTED) {
          draft.completedSuggestions = suggestions;
        } else {
          draft.readySuggestions = suggestions;
        }
        draft.loadingSuggestionByName = false;
        draft.loadedSuggestionByName = true;
        break;
      }
      case SuggestionActionType.GET_SUGGESTION_BY_NAME_ERROR:
        draft.loadingSuggestionByName = false;
        draft.loadedSuggestionByName = true;
        break;
      case SuggestionActionType.LOCAL_SET_SUGGESTION_BY_NAME: {
        const i = draft.completedSuggestions.findIndex(
          (s) => s.name === draft.selectedSuggestionName,
        );
        if (i >= 0) {
          draft.completedSuggestions.splice(i, 1, action.payload);
        } else {
          const index = draft.readySuggestions.findIndex(
            (s) => s.name === draft.selectedSuggestionName,
          );
          if (index >= 0) {
            draft.readySuggestions.splice(index, 1, action.payload);
          }
        }
        break;
      }
      case SuggestionActionType.EDIT_DOCUMENT_ENTITIES: {
        const entity: DocumentEntity = action.payload;
        const suggestion: Suggestion = draft.readySuggestions.find(
          (s) => s.name === action.suggestionName,
        ) as Suggestion;
        const step: SuggestionStep = suggestion?.steps?.find(
          (step) => step.activity === Activity.COMPREHEND_DOCUMENT,
        ) as SuggestionStep;
        if (step?.originalDocuments?.length == 0) {
          step.originalDocuments = step?.documents?.map((doc) =>
            cloneDeep(doc),
          );
        }
        const entities = step?.documents?.[0].entities;
        const index: number =
          entities?.findIndex((e) => e.id === entity.id) ?? -1;
        if (index >= 0) {
          entities?.splice(index, 1, entity);
        } else {
          entities?.forEach((entity) => {
            const index: number =
              entity?.properties?.findIndex((e) => e.id === entity.id) ?? -1;
            if (index >= 0) {
              entity?.properties?.splice(index, 1, entity);
            }
          });
        }
        break;
      }
      case SuggestionActionType.DELETE_DOCUMENT_ENTITIES: {
        const payloadEntity: DocumentEntity = action.payload;
        const suggestion: Suggestion = draft.readySuggestions.find(
          (s) => s.name === action.suggestionName,
        ) as Suggestion;
        const step: SuggestionStep = suggestion.steps?.find(
          (step) => step.activity === Activity.COMPREHEND_DOCUMENT,
        ) as SuggestionStep;
        if (step.originalDocuments?.length == 0) {
          step.originalDocuments = step.documents?.map((doc) => cloneDeep(doc));
        }
        for (const document of step.documents as Document[]) {
          const entities = document.entities as DocumentEntity[];
          const index: number =
            entities?.findIndex((e) => e.id === payloadEntity.id) ?? -1;
          if (index >= 0) {
            entities?.splice(index, 1);
          } else {
            for (const entity of entities) {
              const index: number =
                entity.properties?.findIndex(
                  (e) => e.id === payloadEntity.id,
                ) ?? -1;
              if (index >= 0) {
                entity.properties?.splice(index, 1);
                document.entities = [
                  ...(document?.entities?.filter((e) => e.id !== entity.id) ??
                    []),
                  cloneDeep(entity),
                ];
              }
            }
          }
        }
        draft.readySuggestions = [
          ...draft.readySuggestions.filter((s) => s.name !== suggestion.name),
          cloneDeep(suggestion),
        ];
        break;
      }
      case SuggestionActionType.COPY_DOCUMENT_ENTITIES: {
        const payloadEntity: DocumentEntity = action.payload;
        const suggestion: Suggestion = draft.readySuggestions.find(
          (s) => s.name === action.suggestionName,
        ) as Suggestion;
        const step: SuggestionStep = suggestion.steps?.find(
          (step) => step.activity === Activity.COMPREHEND_DOCUMENT,
        ) as SuggestionStep;
        if (step.originalDocuments?.length == 0) {
          step.originalDocuments = step.documents?.map((doc) => cloneDeep(doc));
        }
        let maxIndex = 0;
        for (const document of step.documents as Document[]) {
          const entities = document.entities as DocumentEntity[];
          for (const entity of entities) {
            const id = parseInt(entity.id as string, 10);
            if (id > maxIndex) {
              maxIndex = id;
            }
            for (const property of entity.properties as DocumentEntity[]) {
              const id = parseInt(property.id as string, 10);
              if (id > maxIndex) {
                maxIndex = id;
              }
            }
          }
        }

        for (const document of step.documents as Document[]) {
          const entities = document.entities;
          const index: number =
            entities?.findIndex((e) => e.id === payloadEntity.id) ?? -1;
          if (index >= 0) {
            const copiedEntity = cloneDeep(payloadEntity);
            copiedEntity.id = (maxIndex + 1).toString();
            entities?.splice(index, 0, copiedEntity);
          }
          for (const entity of entities as DocumentEntity[]) {
            const index1: number =
              entity.properties?.findIndex((e) => e.id === payloadEntity.id) ??
              -1;
            if (index1 >= 0) {
              const copiedEntity = cloneDeep(payloadEntity);
              copiedEntity.id = (maxIndex + 1).toString();
              entity.properties?.splice(index1, 0, copiedEntity);
              document.entities = [
                ...(document?.entities?.filter((e) => e.id !== entity.id) ??
                  []),
                cloneDeep(entity),
              ];
            }
          }
        }
        draft.readySuggestions = [
          ...draft.readySuggestions.filter((s) => s.name !== suggestion.name),
          cloneDeep(suggestion),
        ];
        break;
      }
      case SuggestionActionType.EDIT_SHEET_DATA: {
        const stepOption: SuggestionStepOption = action.payload;
        const suggestion: Suggestion = draft.readySuggestions.find(
          (s) => s.name === action.suggestionName,
        ) as Suggestion;
        const steps: SuggestionStep[] = suggestion.steps as SuggestionStep[];

        const step: SuggestionStep = steps.find(
          (step) => step.activity === Activity.ADD_SHEET_ROW,
        ) as SuggestionStep;
        if (!step.originalOption) {
          step.originalOption = step.options?.[0];
        }
        step.options = [stepOption];
        break;
      }
      case SuggestionActionType.SET_SELECTED_STATUS: {
        draft.selectedStatus = action.payload;
        break;
      }
      case SuggestionActionType.GET_NEXT_STEP: {
        draft.nextStepError = undefined;
        break;
      }
      case SuggestionActionType.GET_NEXT_STEP_ERROR: {
        draft.nextStepError = action.payload;
        draft.updatingSuggestions = false;
        break;
      }
      case SuggestionActionType.GET_NEXT_STEP_COMPLETED: {
        const payloadSuggestion: Suggestion = action.suggestion;
        const suggestion: Suggestion = draft.readySuggestions.find(
          (s) => s.name === payloadSuggestion.name,
        ) as Suggestion;
        suggestion.steps?.push(...(payloadSuggestion.steps ?? []));
        suggestion.taskName = payloadSuggestion.taskName;
        draft.nextStepError = undefined;
        break;
      }
      default:
        break;
    }
  });
