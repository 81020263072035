import React, { useContext } from 'react';

import { Box, Typography, styled } from '@mui/material';
import { RowContainer } from './Styled';

import FlagKeywordsIdentified from './FlagKeywordsIdentified';
import { OrbyColorPalette } from 'orby-ui/src';
import { ActionsContext } from './ActionsContext';
import { ActionObject } from 'workflow-utils/src/types';

const Container = styled(RowContainer)(() => ({
  display: 'block',
  marginBottom: '10px',
  justifyContent: 'flex-start',
  borderRadius: '8px',
  backgroundColor: OrbyColorPalette['indigo-50'],
  padding: '5px 15px',
  wordWrap: 'break-word',
  '& .keywords': {
    color: OrbyColorPalette['grey-900'],
    fontWeight: 700,
    fontStyle: 'italic',
  },
  '& .MuiTypography-root': {
    lineHeight: '25px',
    fontSize: '18px',
  },
}));

interface Props {
  action: ActionObject;
}

const FlagKeywords: React.FC<Props> = ({ action }) => {
  const { executedActions } = useContext(ActionsContext)!;

  const flagKeywords = action.flagKeywords!.keywords || [];
  const targetExecutedActions = executedActions.filter(
    (eAction) => eAction.preparedActionUuid === action.id,
  );
  const targetExecutedAction = targetExecutedActions[0];
  let identifiedKeywords: string[] = [];
  let outputValue: { [index: string]: any } = {};

  if (targetExecutedAction.outputValue !== '') {
    try {
      outputValue = JSON.parse(targetExecutedAction.outputValue!);
      if (
        typeof outputValue === 'object' &&
        Array.isArray(outputValue.keywords)
      ) {
        identifiedKeywords = outputValue.keywords;
      }
    } catch (error) {
      console.error('Error parsing output value:', error);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Container>
        <Typography component={'span'}>Flag the following keywords:</Typography>
        <Typography className='keywords' component={'span'}>
          {flagKeywords.join(', ')}
        </Typography>
      </Container>

      <FlagKeywordsIdentified keywords={identifiedKeywords} />
    </Box>
  );
};

export default FlagKeywords;
