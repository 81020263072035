import { authService } from './AuthService';
import store from '../redux/store';
import { logoutCompletedAction } from '../redux/actions/auth.action';
import { eraseCookie, setCookie } from '../utils/cookie';
import {
  GetNewGoogleTokenRequest,
  GetNewGoogleTokenResponse,
} from 'protos/pb/v1alpha1/tokens_service';
import { LoginResponse } from 'protos/pb/v1alpha1/users_service';
import { grpc } from '@improbable-eng/grpc-web';
import { SAML_REDIRECT_STATUS_KEY } from '../utils/constants';
import { MicrosoftUserInfo } from 'protos/pb/v1alpha1/user';

export interface Token {
  sessionId: string | undefined;
  accessToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresAt: number | undefined;
  platform?: string;
}

export interface GoogleToken {
  accessToken: string;
  accessTokenExpiresAt: Date;
}

export const convertToken = (pbToken: LoginResponse): Token => {
  return {
    sessionId: pbToken.sessionId,
    accessToken: pbToken.accessToken,
    accessTokenExpiresAt:
      pbToken.accessTokenExpiresAt &&
      new Date(pbToken.accessTokenExpiresAt)?.getTime(),
    refreshToken: pbToken.refreshToken,
    refreshTokenExpiresAt:
      pbToken.refreshTokenExpiresAt &&
      new Date(pbToken.refreshTokenExpiresAt)?.getTime(),
  };
};

export class StorageService {
  private static instance: StorageService;

  public static getInstance(): StorageService {
    if (!this.instance) {
      this.instance = new StorageService();
    }
    return this.instance;
  }

  setStoredToken(token: Token): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('token', tokenSting);
      setCookie('token', tokenSting, 1);
      resolve();
    });
  }

  setStoredGoogleToken(token: GoogleToken): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('google-token', tokenSting);
      resolve();
    });
  }

  getStoredGoogleToken(email: string): Promise<GoogleToken> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('google-token');
      const itemToken: GoogleToken = item && JSON.parse(item);
      // 10*1000 is ten seconds, so if the access token will expire in 10 seconds , we deem it expired
      if (
        new Date(itemToken.accessTokenExpiresAt) <
        new Date(new Date().getTime() + 10 * 1000)
      ) {
        authService
          .registerGoogleToken({ email } as GetNewGoogleTokenRequest)
          .then((res: GetNewGoogleTokenResponse) => {
            resolve({
              accessToken: res.accessToken as string,
              accessTokenExpiresAt: res.accessTokenExpiresAt!,
            });
          })
          .catch(() => resolve(itemToken));
      } else {
        resolve(itemToken);
      }
    });
  }

  setStoredOrgResourceName(orgResourceName: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('org-resource-name', orgResourceName);
      resolve();
    });
  }

  getStoredOrgResourceName(): Promise<string | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('org-resource-name');
      resolve(item);
    });
  }

  getStoredToken(refreshToken = true): Promise<Token | undefined> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('token');
      const itemToken: Token = item && JSON.parse(item);
      if (item) {
        if (refreshToken) {
          authService
            .refreshTokenIfNeeded(itemToken)
            .then((token: Token) => {
              resolve(token);
            })
            .catch(() => {
              resolve(undefined);
            });
        } else {
          resolve(itemToken);
        }
      } else {
        resolve(undefined);
      }
    });
  }

  deleteStoredValues(): Promise<void> {
    return new Promise((resolve) => {
      localStorage.clear();
      eraseCookie('token');
      resolve();
    });
  }

  setStoredEnableEventUpload(enable_event_upload: boolean): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(
        'enable_event_upload',
        enable_event_upload.toString(),
      );
      resolve();
    });
  }

  getStoredEnabledEventUpload(): Promise<boolean> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('enable_event_upload');
      resolve(item === 'true');
    });
  }

  getAuthorizationHeader(): Promise<string> {
    return new Promise((resolve, reject) => {
      StorageService.instance
        .getStoredToken()
        .then((token: Token | undefined) => {
          if (token) {
            resolve(`Bearer ${token?.accessToken}`);
          } else {
            StorageService.instance.deleteStoredValues();
            store.dispatch(logoutCompletedAction());
            reject();
          }
        });
    });
  }

  async getMetadata(): Promise<grpc.Metadata> {
    const authHeader = await this.getAuthorizationHeader();
    if (authHeader) {
      return new grpc.Metadata({ authorization: authHeader });
    } else {
      return new grpc.Metadata();
    }
  }

  setStoredGoogleScope(scopes: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('google-scopes', scopes);
      resolve();
    });
  }

  getStoredGoogleScope(): Promise<string | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('google-scopes');
      resolve(item);
    });
  }

  setStoredMicrosoftInfo(data: MicrosoftUserInfo): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('microsoft-info', JSON.stringify(data));
      resolve();
    });
  }

  getStoredMicrosoftInfo(): Promise<MicrosoftUserInfo | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('microsoft-info');
      resolve(item ? JSON.parse(item) : null);
    });
  }

  // We are using session storage instead of local storage because we only
  // need to persist the SAML redirect status for the duration of the current
  // tab session. If the SAML redirect status is not cleared properly,
  // it could cause issues with the front-end code. Session storage ensures
  // that the data is cleared when the tab is closed.

  private getSamlRedirect() {
    return sessionStorage.getItem(SAML_REDIRECT_STATUS_KEY);
  }

  private clearSamlRedirect() {
    return sessionStorage.removeItem(SAML_REDIRECT_STATUS_KEY);
  }

  setSamlRedirect() {
    return sessionStorage.setItem(SAML_REDIRECT_STATUS_KEY, 'true');
  }

  isSamlRedirect() {
    if (this.getSamlRedirect() === 'true') {
      this.clearSamlRedirect();
      return true;
    }
    return false;
  }
}

export const storageService = StorageService.getInstance();
