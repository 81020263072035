import { Box, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import gmailIcon from '../../../../static/icons/gmail-logo.svg';
import outlookIcon from '../../../../static/icons/outlook.svg';
import docsIcon from '../../../../static/icons/Docs.svg';
import { CheckCircleRounded } from '@mui/icons-material';
import ConnectionMessage from '../ConnectionMessage';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { loggedInUserSelector } from '../../../../redux/selectors/user.selectors';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { GetEmailLabelsRequest } from 'protos/pb/v1alpha2/utility_service';
import { getGmailLabelsAction } from '../../../../redux/actions/utility.action';
import {
  EmailLabel,
  GmailLabel,
} from 'protos/automation_mining/ontology/data_models';
import {
  getGmailLabelsErrorSelector,
  getGmailLabelsSelector,
  loadingGmailLabelsSelector,
} from '../../../../redux/selectors/utility.selectors';
import { ApplicationName } from '../../../../utils/protos/enums';
import {
  getApplicationNames,
  getTriggerIndex,
  withGoogleLoginScopeCheck,
} from '../../../../utils/helpers';
import {
  GOOGLE_GMAIL_SCOPE,
  colorMap,
  customOutlookInbox,
  hexToRgba,
} from '../../../../utils/constants';
import {
  OrbyBadge,
  OrbyCheckbox,
  OrbyColorPalette,
  OrbySelect,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';

interface EmailTriggerContentProps {
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  workflow: Workflow;
  edit?: boolean;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

// We exclude all default labels in https://developers.google.com/gmail/api/guides/labels except Inbox.
const hideLabels = {
  SPAM: true,
  TRASH: true,
  UNREAD: true,
  STARRED: true,
  IMPORTANT: true,
  SENT: true,
  DRAFT: true,
  CATEGORY_PERSONAL: true,
  CATEGORY_SOCIAL: true,
  CATEGORY_PROMOTIONS: true,
  CATEGORY_UPDATES: true,
  CATEGORY_FORUMS: true,
  CHAT: true,
};

const EmailTriggerContent: FC<EmailTriggerContentProps> = ({
  onSubmit,
  workflow,
  edit,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  const user = useSelector(loggedInUserSelector);
  const dispatch = useDispatch();
  const gmailLabels: GmailLabel[] = useSelector(getGmailLabelsSelector);
  const loadingGmailLabels = useSelector(loadingGmailLabelsSelector);
  const gmailLabelsError = useSelector(getGmailLabelsErrorSelector);
  const selectRef = useRef<HTMLInputElement>(null);
  const selectDisabled =
    edit || loadingGmailLabels || gmailLabelsError != undefined;

  const handleFormSubmit = (values: FormikValues) => {
    if (values.email && values.labels.length > 0) {
      onSubmit(values, true);
    }
  };
  const gmailIndex = getTriggerIndex(workflow, ApplicationName.Gmail);
  const outlookIndex = getTriggerIndex(workflow, ApplicationName.Outlook);

  const isGmailApplication = useMemo(() => {
    return getApplicationNames(workflow).includes(ApplicationName.Gmail);
  }, [workflow]);

  let selectedLabels: GmailLabel[] | EmailLabel[] = [];
  if (
    gmailIndex.stepIndex !== undefined &&
    gmailIndex.triggerIndex !== undefined
  ) {
    selectedLabels =
      workflow.steps?.[gmailIndex.stepIndex!]?.triggers?.[
        gmailIndex.triggerIndex
      ]?.gmail?.trigger?.labels ?? [];
  } else if (
    outlookIndex.stepIndex !== undefined &&
    outlookIndex.triggerIndex !== undefined
  ) {
    selectedLabels =
      workflow?.steps?.[outlookIndex.stepIndex]?.triggers?.[
        outlookIndex.triggerIndex
      ]?.gmail?.trigger?.labels ?? [];
    if (selectedLabels.length === 0 && edit) {
      selectedLabels = [customOutlookInbox];
    }
  }

  const getGmailLabels = () => {
    if (selectedLabels.length === 0 && !edit) {
      if (isGmailApplication) {
        withGoogleLoginScopeCheck(dispatch, GOOGLE_GMAIL_SCOPE, getLabels);
      } else {
        getLabels();
      }
    }
  };

  const getLabelBackgroundColor = (label: EmailLabel | GmailLabel) => {
    return isGmailApplication
      ? label?.color?.backgroundColor ?? '#DDDDDD'
      : hexToRgba(
          colorMap[label.color?.textColor as keyof typeof colorMap],
          0.3,
        );
  };

  const getLabels = () => {
    const req = GetEmailLabelsRequest.create({});
    dispatch(getGmailLabelsAction(req));
  };

  const formik = useFormik({
    initialValues: {
      email: workflow?.creatorEmail || user?.email,
      labels: selectedLabels,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      labels: Yup.array().required('Labels are required.').min(1),
    }),
    onSubmit: handleFormSubmit,
  });

  const checkIfValueChecked = (label: GmailLabel | EmailLabel) => {
    return formik.values.labels.find((l) => l?.id === label.id);
  };

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (!selectDisabled && selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectDisabled]);

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  return (
    <FormikProvider value={formik}>
      <Form id={formId} autoComplete='off' onSubmit={handleSubmit}>
        <Box
          display={'flex'}
          marginY={'32px'}
          paddingLeft={'19px'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
            {/* APPLICATION */}
            <Box>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                Application
              </OrbyTypography>
              <OrbyTextField
                placeholder='Application'
                value={`${
                  isGmailApplication
                    ? ApplicationName.Gmail
                    : ApplicationName.Outlook
                } (${formik.values.email})`}
                name='application'
                width='448px'
                disabled
                startAdornment={
                  <img
                    alt={isGmailApplication ? 'Gmail' : 'Outlook'}
                    style={{
                      marginRight: '6px',
                      height: '19px',
                      width: '19px',
                    }}
                    src={isGmailApplication ? gmailIcon : outlookIcon}
                  />
                }
                endAdornment={
                  <CheckCircleRounded
                    sx={{ marginRight: '8px', color: '#006644' }}
                  />
                }
              />
            </Box>

            {/* TRIGGER */}
            <Box>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                Trigger
              </OrbyTypography>
              <OrbyTextField
                name='trigger'
                placeholder='Trigger'
                width='448px'
                disabled
                startAdornment={
                  <img
                    alt='Document.'
                    style={{ marginRight: '2px' }}
                    src={docsIcon}
                  />
                }
                value={'Receive new email with the following Labels'}
              />
            </Box>

            {/* EMAIL LABELS */}
            <Box width={'100%'}>
              <OrbyTypography
                size='sm'
                weight='medium'
                color={OrbyColorPalette['grey-700']}
                sx={{
                  marginBottom: '6px',
                }}
              >
                Choose Labels
              </OrbyTypography>
              <OrbySelect
                width='448px'
                onOpen={getGmailLabels}
                inputProps={{ ref: selectRef }}
                MenuProps={{
                  MenuListProps: { 'aria-label': 'Choose labels.' },
                  sx: {
                    padding: 0,
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                multiple
                disabled={selectDisabled}
                value={formik.values.labels}
                onChange={(e) => {
                  const gmailSelectedLabels: GmailLabel[] = [];
                  const removeLabels: string[] = [];
                  const labels = e.target.value as any[];
                  labels.forEach((v: any) => {
                    if (typeof v == 'string') {
                      const label = formik.values.labels.find((l) => {
                        return v === l.id;
                      });
                      if (!label) {
                        const gmailLabel = gmailLabels.find(
                          (label) => label.id === v,
                        );
                        if (gmailLabel) gmailSelectedLabels.push(gmailLabel);
                      } else {
                        removeLabels.push(v);
                      }
                    } else {
                      const gmailLabel = gmailLabels.find(
                        (label) => label.id === v.id,
                      );
                      if (gmailLabel) gmailSelectedLabels.push(gmailLabel);
                    }
                  });
                  const selectedLabels: GmailLabel[] = [];

                  if (labels.includes(customOutlookInbox.id)) {
                    let isCustomOutlookInboxAlreadyPresent = false;

                    // Look if the customOutlookInbox is already present in the selected labels
                    // If so we don't need to push it to selected labels as we have to uncheck it
                    labels.forEach((label) => {
                      if (
                        typeof label !== 'string' &&
                        label.id === customOutlookInbox.id
                      ) {
                        isCustomOutlookInboxAlreadyPresent = true;
                      }
                    });
                    if (!isCustomOutlookInboxAlreadyPresent) {
                      selectedLabels.push(customOutlookInbox);
                    }
                  } else {
                    gmailSelectedLabels.forEach((l) => {
                      if (!removeLabels.includes(l.id!)) {
                        selectedLabels.push(l);
                      }
                    });
                  }
                  setFieldValue('labels', selectedLabels);
                }}
                renderValue={(selected) => {
                  return (selected as Array<{ id: string; name: string }>)
                    ?.length ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxWidth: '435px',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      {(selected as Array<{ id: string; name: string }>).map(
                        (label) => {
                          return (
                            <OrbyBadge
                              key={label.id}
                              badgeName={label.name}
                              size='small'
                              backgroundColor={getLabelBackgroundColor(label)}
                              textColor='#344054'
                            />
                          );
                        },
                      )}
                    </Box>
                  ) : (
                    <OrbyTypography
                      size='sm'
                      weight='medium'
                      color={OrbyColorPalette['grey-700']}
                    >
                      Select labels
                    </OrbyTypography>
                  );
                }}
              >
                {!isGmailApplication && (
                  <MenuItem
                    key={customOutlookInbox.id}
                    value={customOutlookInbox.id}
                  >
                    <Box display={'flex'} gap={'10px'}>
                      <OrbyCheckbox
                        size='sm'
                        checked={
                          checkIfValueChecked(customOutlookInbox)?.id ===
                          customOutlookInbox.id
                        }
                        title={customOutlookInbox.name ?? 'No label'}
                      />
                      <OrbyBadge
                        key={customOutlookInbox.id}
                        badgeName={customOutlookInbox.name}
                        size='small'
                        backgroundColor={getLabelBackgroundColor(
                          customOutlookInbox,
                        )}
                        textColor='#344054'
                      />
                    </Box>
                  </MenuItem>
                )}

                {gmailLabels.map((label) => {
                  if (!(label.id! in hideLabels)) {
                    const checkedLabel = checkIfValueChecked(label);
                    return (
                      <MenuItem
                        disabled={
                          checkIfValueChecked(customOutlookInbox) ? true : false
                        }
                        key={label.id}
                        value={label.id}
                      >
                        <Box display={'flex'} gap={'10px'}>
                          <OrbyCheckbox
                            size='sm'
                            checked={checkedLabel?.id === label.id}
                            title={label.name ?? 'No label'}
                          />

                          <OrbyBadge
                            key={label.id}
                            badgeName={label.name}
                            size='small'
                            backgroundColor={getLabelBackgroundColor(label)}
                            textColor='#344054'
                          />
                        </Box>
                      </MenuItem>
                    );
                  }
                })}
              </OrbySelect>
              {Boolean(errors.labels) && (
                <OrbyTypography
                  color={OrbyColorPalette['error-500']}
                  sx={{ marginTop: '3px' }}
                >
                  {(touched.labels && errors.labels) as string}
                </OrbyTypography>
              )}
              {gmailLabelsError && (
                <OrbyTypography
                  color={OrbyColorPalette['error-500']}
                  sx={{ marginTop: '3px' }}
                >
                  {gmailLabelsError.message as string}
                </OrbyTypography>
              )}
            </Box>
          </Box>

          <ConnectionMessage
            connected={true}
            application={
              isGmailApplication
                ? ApplicationName.Gmail
                : ApplicationName.Outlook
            }
            edit={edit}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(EmailTriggerContent);
