import { Box, MenuItem, Select } from '@mui/material';
import React, { FC, memo } from 'react';
import NestedArrow from '../../../../../static/icons/nestedArrow.svg';
import CloseIcon from '@mui/icons-material/Close';
import {
  ELLIPSIS_STYLE,
  SelectedExtractedField,
} from '../../../../../utils/constants';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import SearchNestedEntities from './SearchNestedEntities';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';

const getEntityDataTypeLabel = (dataType: EntityDataType): string => {
  switch (dataType) {
    case EntityDataType.ENTITY_TYPE_NESTED:
      return 'Parent Entity';
    case EntityDataType.ENTITY_TYPE_TEXT:
      return 'Text';
    case EntityDataType.ENTITY_TYPE_DATE:
      return 'Date';
    case EntityDataType.ENTITY_TYPE_MONEY:
      return 'Money';
    case EntityDataType.ENTITY_TYPE_FLOAT:
      return 'Number-float';
    case EntityDataType.ENTITY_TYPE_INTEGER:
      return 'Number-integer';
    case EntityDataType.ENTITY_TYPE_ANNOTATION:
      return 'Notes';
    default:
      return '';
  }
};

interface ParentEntityChipProps {
  entity: SelectedExtractedField;
  getEntityDataType: (e: SelectedExtractedField) => EntityDataType | undefined;
  handleDelete: (e: SelectedExtractedField[]) => void;
  handleAddEntity?: (e: SelectedExtractedField[]) => void;
  selectedEntities?: SelectedExtractedField[];
  childEntities?: SelectedExtractedField[];
  hasError?: boolean;
  isParentEntity?: boolean;
}

const ParentEntityChip: FC<ParentEntityChipProps> = ({
  entity,
  getEntityDataType,
  handleDelete,
  handleAddEntity,
  selectedEntities = [],
  childEntities = [],
  hasError,
  isParentEntity = true,
}) => {
  const selectedChildEntities = selectedEntities.filter(
    (e) => e.parent === entity.entityName,
  );

  const type = getEntityDataType(entity)!;

  return (
    <Box
      sx={{
        paddingY: '10px',
        paddingX: '2px',
      }}
      display={'inline-block'}
    >
      <Box
        width={'fit-content'}
        sx={{
          alignItems: 'center',
          display: 'flex',
          background: OrbyColorPalette['blue-50'],
          borderRadius: '50px',
          border: hasError
            ? `1px solid ${OrbyColorPalette['error-500']}`
            : `1px solid ${OrbyColorPalette['grey-300']}`,
        }}
      >
        <Select
          labelId='entity-type'
          disabled
          sx={{
            borderRadius: '25px 0px 0px 25px',
            border: '0px solid transparent',
            fontSize: '12px',
            '& .MuiSelect-select': {
              paddingLeft: '16px',
              paddingTop: '8px',
              paddingBottom: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeft: 'none',
              borderTop: 'none',
              borderColor: '#E2E2E2 !important',
              borderBottom: 'none',
            },
          }}
          value={type}
        >
          <MenuItem value={type}>{getEntityDataTypeLabel(type)}</MenuItem>
        </Select>
        <Box title={entity.entityName}>
          <OrbyTypography
            color={OrbyColorPalette['grey-900']}
            size='xs'
            sx={{
              padding: '0px 16px 0px 16px',
              ...ELLIPSIS_STYLE,
              // Set the width to '248px' when it's a parent entity; otherwise, set 'auto' with a maximum of '200px'.
              maxWidth: isParentEntity ? 'auto' : '200px',
              width: 'auto',
            }}
            aria-live='assertive'
            aria-label={entity.entityName}
          >
            {entity.entityName}
          </OrbyTypography>
        </Box>
        <Box display={'flex'} sx={{ paddingRight: '16px' }}>
          <CloseIcon
            // Delete all the child entities with parent
            onClick={() => handleDelete([...selectedChildEntities, entity])}
            sx={{
              cursor: 'pointer',
              fontSize: getFontSize('sm'),
              color: OrbyColorPalette['purple-700'],
            }}
          />
        </Box>
      </Box>
      {isParentEntity && (
        <Box display={'flex'} marginLeft={'15px'}>
          <Box
            alignSelf={'self-start'}
            alt=''
            component={'img'}
            src={NestedArrow}
          />
          <Box
            padding={'16px'}
            marginTop={'6px'}
            borderRadius={'8px'}
            width={'368px'}
            minHeight={'60px'}
            bgcolor={OrbyColorPalette['blue-50']}
          >
            <SearchNestedEntities
              dropdownValues={childEntities}
              selectedValues={selectedChildEntities}
              isChildDropdown
              handleAdd={handleAddEntity!}
              placeholder='Search extracted field'
              ariaLabel='Search extracted field'
              ariaDescribeBy='search_extracted_field'
              dropdownWidth={'100%'}
            />
            <Box width={'100%'} paddingTop={'6px'}>
              {selectedChildEntities?.map((child) => {
                return (
                  <ParentEntityChip
                    key={child.entityName}
                    entity={child}
                    getEntityDataType={getEntityDataType}
                    handleDelete={handleDelete}
                    isParentEntity={false}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(ParentEntityChip);
