import { createSelector } from 'reselect';
import { organizationSelector } from './app.selectors';

export const organizationDetailsSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organization,
);

export const organizationDetailsLoadingSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organizationLoading,
);

export const organizationDetailsLoadingErrorSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organizationLoadingError,
);

export const organizationUpdatedSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organizationUpdated,
);

export const organizationUpdatingSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organizationUpdating,
);

export const organizationUpdatingErrorSelector = createSelector(
  [organizationSelector],
  (organizationState) => organizationState.organizationUpdatingError,
);
