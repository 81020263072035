import React, { FC } from 'react';
import { Box, Divider } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { LogicalOperator } from 'protos/pb/v1alpha2/connector';

interface Props {
  onClick: (operator: LogicalOperator) => void;
  value: LogicalOperator;
}

export const LogicalOperatorGroupButton: FC<Props> = ({ onClick, value }) => {
  const getLogicalOperatorBackgroundColor = (operator: LogicalOperator) => {
    if (value === operator) {
      return OrbyColorPalette['blue-100'];
    } else {
      return OrbyColorPalette['white-0'];
    }
  };

  const getLogicalOperatorColor = (operator: LogicalOperator) => {
    if (value === operator) {
      return OrbyColorPalette['blue-700'];
    } else {
      return OrbyColorPalette['grey-600'];
    }
  };

  return (
    <Box
      display={'inline-flex'}
      flexDirection={'row'}
      width={'120px'}
      marginY={'12px'}
      sx={{
        boxShadow: '0px 1px 2px 0px #1018280D',
        border: `0.5px solid ${OrbyColorPalette['blueGrey-100']}`,
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      {/* DIVIDER */}
      <Divider
        orientation='vertical'
        sx={{
          position: 'absolute',
          borderColor: OrbyColorPalette['grey-700'],
          borderWidth: '1px',
          left: '12px',
          top: '-12px',
          height: '56px',
        }}
      />

      {/* AND OPERATOR */}
      <Box
        width={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingY={'7px'}
        sx={{
          backgroundColor: getLogicalOperatorBackgroundColor(
            LogicalOperator.AND,
          ),
          borderBottomLeftRadius: '8px',
          borderTopLeftRadius: '8px',
          cursor: 'pointer',
          zIndex: 10,
          '&:hover': {
            backgroundColor: OrbyColorPalette['blue-25'],
          },
        }}
        role='button'
        tabIndex={0}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onClick(LogicalOperator.AND);
          }
        }}
        onClick={() => onClick(LogicalOperator.AND)}
      >
        <OrbyTypography
          color={getLogicalOperatorColor(LogicalOperator.AND)}
          size='xs'
          weight='medium'
        >
          And
        </OrbyTypography>
      </Box>

      {/* OR OPERATOR */}
      <Box
        width={'40px'}
        paddingY={'7px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          backgroundColor: getLogicalOperatorBackgroundColor(
            LogicalOperator.OR,
          ),
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: OrbyColorPalette['blue-25'],
          },
        }}
        role='button'
        tabIndex={0}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onClick(LogicalOperator.OR);
          }
        }}
        onClick={() => onClick(LogicalOperator.OR)}
      >
        <OrbyTypography
          color={getLogicalOperatorColor(LogicalOperator.OR)}
          size='xs'
          weight='medium'
        >
          Or
        </OrbyTypography>
      </Box>
      {/* NOT OPERATOR */}
      <Box
        width={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingY={'7px'}
        sx={{
          backgroundColor: getLogicalOperatorBackgroundColor(
            LogicalOperator.NOT,
          ),
          borderBottomRightRadius: '8px',
          borderTopRightRadius: '8px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: OrbyColorPalette['blue-25'],
          },
        }}
        role='button'
        tabIndex={0}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onClick(LogicalOperator.NOT);
          }
        }}
        onClick={() => onClick(LogicalOperator.NOT)}
      >
        <OrbyTypography
          color={getLogicalOperatorColor(LogicalOperator.NOT)}
          size='xs'
          weight='medium'
        >
          Not
        </OrbyTypography>
      </Box>
    </Box>
  );
};
