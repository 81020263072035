/**
 * This file is used to show the top bar
 * on the Review Page to show the detail of
 * task name and progress bar for the status of the task
 */
import { ArrowBackIos, ContentCopy } from '@mui/icons-material';
import { Box, Button, IconButton, Switch, Typography } from '@mui/material';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTypography, {
  TypographyType,
} from '../../../../components/CustomTypography';
import TopBarTaskSteps from './components/TopBarTaskSteps';
import { format } from 'date-fns';
import {
  REVIEW_PAGE_TOP_BAR_HEIGHT,
  ELLIPSIS_STYLE,
  FEATURE_FLAGS,
} from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { debugLayoutSelector } from '../../../../redux/selectors/taskV2.selectors';
import { updateDebugLayout } from '../../../../redux/actions/taskV2.action';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../../../FeatureFlags/FeatureFlagUtils';
import ReviewerMenu from '../../../../components/ReviewerMenu';
import { CloseRounded } from '@mui/icons-material';
import {
  getGcsProtoUriFromDocumentUri,
  getSelectedTaskDocument,
} from '../../../../utils/helpers';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { loggedInUserSelector } from '../../../../redux/selectors/user.selectors';

const steps = ['Process', 'Validate', 'Automate'];
const rejectedSteps = ['Process', 'Validate', 'Rejected'];

interface Props {
  task: Task;
  debug?: boolean;
}

const ReviewPageTopBar: React.FC<Props> = ({ task, debug }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(loggedInUserSelector);
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isTableDebugLayoutEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.TABLE_DEBUG_LAYOUT,
    featureFlags,
  );
  const isOrbyAIUser = (user?.email ?? '').endsWith('@orby.ai');
  const enableDebugLayout = useSelector(debugLayoutSelector);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const isRejectedTask = () => {
    return (
      task.status === TaskSTATUS.REJECTED_INCORRECT ||
      task.status === TaskSTATUS.REJECTED_ALREADY_COMPLETED
    );
  };

  const getTime = (date: Date) => {
    if (date) {
      return format(date, 'MM/dd/yyyy HH:mm:ss');
    }
    return 'Not available';
  };

  // Now we are using same tab to open review page, so just navigate to previous page
  const handleGoBack = () => {
    navigate(-1);
  };

  const getTitle = () => {
    if (isRejectedTask()) {
      return 'Declined Tasks';
    } else if (task.status === TaskSTATUS.COMPLETED) {
      return 'Completed Tasks';
    } else return 'Pending Tasks';
  };

  const handleCopyClick = () => {
    const documentStep: ExecutionStep | undefined =
      getSelectedTaskDocument(task);
    const gcsUri = getGcsProtoUriFromDocumentUri(
      documentStep?.documentUris?.[0] ?? '',
    );
    navigator.clipboard.writeText(gcsUri);
  };

  return (
    <Box
      bgcolor={'#FFFFFF'}
      width={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      gap={'8px'}
      zIndex={100}
      height={REVIEW_PAGE_TOP_BAR_HEIGHT + 'px'}
      minHeight={REVIEW_PAGE_TOP_BAR_HEIGHT + 'px'}
      alignItems={'center'}
    >
      <Box
        height={'100%'}
        overflow='hidden'
        marginLeft={'35px'}
        display={'flex'}
      >
        <IconButton
          aria-label={getTitle()}
          title={getTitle()}
          sx={{
            marginTop: '24px',
            width: '40px',
            height: '40px',
            marginRight: '8px',
          }}
          onClick={handleGoBack}
        >
          <ArrowBackIos sx={{ color: '#000000', marginLeft: '8px' }} />
        </IconButton>
        <Box marginTop={'23px'} overflow={'hidden'}>
          <Box display={'flex'} flexDirection='column'>
            <Box title={task.displayName} overflow='hidden'>
              <CustomTypography
                sx={ELLIPSIS_STYLE}
                component={'h1'}
                typographyType={TypographyType.Header4}
              >
                {task.displayName}
              </CustomTypography>
            </Box>
            <Box display={'flex'} marginTop='4px'>
              <Box title={task.workflowDisplayName} overflow='hidden'>
                <CustomTypography
                  sx={{
                    borderRadius: '4px',
                    bgcolor: '#D9E7FF',
                    paddingX: '12px',
                    paddingY: '4px',
                    marginRight: '8px',
                    ...ELLIPSIS_STYLE,
                  }}
                  typographyType={TypographyType.Label}
                >
                  {task.workflowDisplayName}
                </CustomTypography>
              </Box>
              {!debug && (
                <>
                  <Box flexShrink={0} display={'flex'} fontSize={'14px'}>
                    {task.status === TaskSTATUS.READY
                      ? 'Time Modified: ' + getTime(task.readyTime as Date)
                      : task.status === TaskSTATUS.COMPLETED
                        ? 'Completed on: ' + getTime(task.completeTime as Date)
                        : 'Declined on: ' + getTime(task.completeTime as Date)}
                  </Box>
                  {/* We will only display review record for completed tasks only */}
                  {task.status === TaskSTATUS.COMPLETED && (
                    <Button
                      variant='text'
                      sx={{
                        marginTop: '-4px',
                        marginLeft: '4px',
                        flexShrink: 0,
                        display: 'flex',
                      }}
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <CustomTypography
                        color='#0500FF'
                        size={'14px'}
                        weight={600}
                      >
                        Review Record
                      </CustomTypography>
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <ReviewerMenu
        // the reason we need to reverse the array is display
        // the reviews in latest to oldest review order
        reviews={task.reviews ? [...task.reviews].reverse() : []}
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
        }}
        header={
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            tabIndex={-1}
          >
            <CustomTypography
              weight={500}
              color='#344054'
              sx={{ paddingLeft: '16px' }}
            >
              Review Record
            </CustomTypography>
            <IconButton disableRipple onClick={() => setAnchorEl(null)}>
              <CloseRounded
                sx={{ height: '22px', width: '22px', color: '#98A2B3' }}
              />
            </IconButton>
          </Box>
        }
      />
      {/* TOGGLE TO ENABLE / DISABLE DEBUG LAYOUT */}
      <Box
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        gap={5}
      >
        {isTableDebugLayoutEnabled && (
          <Box textAlign={'center'}>
            <Switch
              size='small'
              color='primary'
              checked={enableDebugLayout}
              onChange={(event) => {
                dispatch(updateDebugLayout(event.target.checked));
              }}
            />
            <Typography variant='subtitle2'>
              {enableDebugLayout ? 'Disable' : 'Enable'} Table Debug Layout
            </Typography>
          </Box>
        )}
        {isOrbyAIUser && !debug && (
          <IconButton title='Copy GCS proto URI' onClick={handleCopyClick}>
            <ContentCopy fontSize='small' />
          </IconButton>
        )}
        <TopBarTaskSteps
          activeStep={task.status === TaskSTATUS.READY ? 1 : 3}
          steps={isRejectedTask() ? rejectedSteps : steps}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ReviewPageTopBar);
