import { Box } from '@mui/material';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import React, { FC, memo } from 'react';
import { CLASSIFICATION_LABEL_SELECT } from '../../../utils/constants';
import { CURRENCY_CODES } from '../../../utils/normalization/normalization.constants';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import { FormikValues, getIn } from 'formik';

interface AttributeContentProps {
  attributeType: EntityDataType | typeof CLASSIFICATION_LABEL_SELECT;
  name: string;
  getFieldProps: any;
  setFieldValue: any;
  getClassificationLabelsList: () => { value: string; label: string }[];
  width: string;
  disabled?: boolean;
  formik: FormikValues;
}

const AttributeContent: FC<AttributeContentProps> = ({
  attributeType,
  name,
  getFieldProps,
  setFieldValue,
  getClassificationLabelsList,
  width,
  disabled = false,
  formik,
}) => {
  switch (attributeType) {
    case EntityDataType.ENTITY_TYPE_MONEY: {
      const values = getIn(formik.values, name)?.split(' ');
      const currencyCode = values[1] ?? CURRENCY_CODES[0];

      return (
        <Box display={'flex'} width={width} gap={1} alignItems={'center'}>
          <Box width={'70%'}>
            <OrbyTextField
              width='100%'
              value={values[0]}
              type='number'
              placeholder='Enter value'
              onChange={(e) =>
                setFieldValue(name, e.target.value + ' ' + currencyCode)
              }
              showErrorText={false}
              disabled={disabled}
            />
          </Box>

          <Box width={'30%'}>
            <OrbySelect
              width={'100%'}
              renderValue={() => {
                return (
                  <OrbyTypography
                    size={currencyCode ? 'md' : 'sm'}
                    color={
                      currencyCode
                        ? OrbyColorPalette['grey-900']
                        : OrbyColorPalette['grey-500']
                    }
                    weight={currencyCode ? 'medium' : 'regular'}
                    sx={{
                      textTransform: 'uppercase',
                    }}
                  >
                    {currencyCode ?? ''}
                  </OrbyTypography>
                );
              }}
              onChange={(e) =>
                setFieldValue(name, values[0] + ' ' + e.target.value)
              }
              value={currencyCode?.toUpperCase()}
              disabled={disabled}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {CURRENCY_CODES.map((currencyCode) => {
                return (
                  <OrbyMenuItem
                    key={currencyCode}
                    value={currencyCode}
                    title={currencyCode}
                    width={width}
                  />
                );
              })}
            </OrbySelect>
          </Box>
        </Box>
      );
    }
    case EntityDataType.ENTITY_TYPE_DATE:
      return (
        <OrbyTextField
          width={width}
          name={name}
          value={''}
          type='date'
          placeholder='Select Date'
          {...getFieldProps(name)}
          disabled={disabled}
          inputSx={{
            'input::-webkit-datetime-edit-day-field:focus': {
              color: OrbyColorPalette['grey-900'],
              backgroundColor: OrbyColorPalette['blue-50'],
              borderBottom: `1px solid ${OrbyColorPalette['blue-700']}`,
            },
            'input::-webkit-datetime-edit-month-field:focus': {
              color: OrbyColorPalette['grey-900'],
              backgroundColor: OrbyColorPalette['blue-50'],
              borderBottom: `1px solid ${OrbyColorPalette['blue-700']}`,
            },
            'input::-webkit-datetime-edit-year-field:focus': {
              color: OrbyColorPalette['grey-900'],
              backgroundColor: OrbyColorPalette['blue-50'],
              borderBottom: `1px solid ${OrbyColorPalette['blue-700']}`,
            },
          }}
          showErrorText={false}
          error={
            getIn(formik.touched, name) && getIn(formik.errors, name)
              ? 'Please select date'
              : ''
          }
        />
      );
    case EntityDataType.ENTITY_TYPE_FLOAT:
    case EntityDataType.ENTITY_TYPE_INTEGER:
      return (
        <OrbyTextField
          width={width}
          name={name}
          value={''}
          type='number'
          placeholder='Enter value'
          {...getFieldProps(name)}
          disabled={disabled}
          showErrorText={false}
          error={
            getIn(formik.touched, name) && getIn(formik.errors, name)
              ? 'Please enter value'
              : ''
          }
        />
      );
    case CLASSIFICATION_LABEL_SELECT:
      return (
        <OrbySelect
          width={width}
          value={''}
          onChange={(e) => setFieldValue(name, e.target.value)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          renderValue={() => {
            const data = getIn(formik.values, name);
            return (
              <OrbyTypography
                size={data ? 'md' : 'sm'}
                color={
                  data
                    ? OrbyColorPalette['grey-900']
                    : OrbyColorPalette['grey-500']
                }
                weight={data ? 'medium' : 'regular'}
              >
                {data || 'Select label'}
              </OrbyTypography>
            );
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {getClassificationLabelsList().map((classificationLabel) => {
            return (
              <OrbyMenuItem
                key={classificationLabel.value}
                value={classificationLabel.value}
                title={classificationLabel.label}
                width={width}
              />
            );
          })}
        </OrbySelect>
      );
    default:
      return (
        <OrbyTextField
          width={width}
          name={name}
          value={''}
          placeholder='Enter text or value'
          {...getFieldProps(name)}
          disabled={disabled}
          showErrorText={false}
          error={
            getIn(formik.touched, name) && getIn(formik.errors, name)
              ? 'Please enter text or value'
              : ''
          }
        />
      );
  }
};

export default memo(AttributeContent);
