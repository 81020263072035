import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { MoreVert } from '@mui/icons-material';

interface Props {
  onUpdate: () => void;
  onDelete: () => void;
  id: string;
  isShowCopyTo?: boolean;
  openCopyModel?: () => void;
  isShowYamlConfig?: boolean;
  downloadYaml?: () => void;
  openYamlModal?: () => void;
  ariaLabel?: string;
  isShowShareWorkflow?: boolean;
  openShareWorkflow?: () => void;
}
export default function WorkflowContextMenu({
  onUpdate,
  onDelete,
  id,
  isShowCopyTo,
  openCopyModel,
  isShowYamlConfig,
  downloadYaml,
  openYamlModal,
  ariaLabel,
  isShowShareWorkflow,
  openShareWorkflow,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-label={ariaLabel ?? 'Show actions for workflow'}
        id={id}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert fontSize='small' sx={{ cursor: 'pointer' }} />
      </Button>
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onUpdate}>Edit</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onDelete();
          }}
        >
          Delete{' '}
        </MenuItem>
        {isShowCopyTo && (
          <MenuItem
            onClick={() => {
              handleClose();
              openCopyModel?.();
            }}
          >
            Copy task
          </MenuItem>
        )}
        {isShowYamlConfig && (
          <MenuItem
            onClick={() => {
              handleClose();
              downloadYaml?.();
            }}
          >
            Download configuration
          </MenuItem>
        )}
        {isShowYamlConfig && (
          <MenuItem
            onClick={() => {
              handleClose();
              openYamlModal?.();
            }}
          >
            Upload
          </MenuItem>
        )}
        {isShowShareWorkflow && (
          <MenuItem
            onClick={() => {
              handleClose();
              openShareWorkflow?.();
            }}
          >
            Share
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
