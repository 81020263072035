// THIS FUNCTION IS NEEDED TO MANUALLY SHIFT THE FOCUS TO PREVIOUS FOCUSABLE ELEMENT
// USING SHIFT + TAB KEY
export const shiftFocus = (
  focusableElements: NodeListOf<Element>,
  event: KeyboardEvent,
) => {
  if (document?.activeElement) {
    event.preventDefault();
    const currentIndex = Array.from(focusableElements).indexOf(
      document.activeElement,
    );
    const nextIndex = event.shiftKey ? currentIndex - 1 : currentIndex + 1;
    const lastTabIndex = focusableElements.length - 1;
    const newIndex =
      nextIndex < 0 ? lastTabIndex : nextIndex > lastTabIndex ? 0 : nextIndex;
    (focusableElements[newIndex] as HTMLElement)?.focus();
  }
};

export const checkFocusOnInputField = () => {
  const activeElement = document.activeElement;

  if (
    activeElement &&
    (activeElement.tagName.toLowerCase() === 'input' ||
      activeElement.tagName.toLowerCase() === 'textarea' ||
      activeElement.tagName.toLowerCase() === 'select' ||
      activeElement.tagName.toLowerCase() === 'button')
  ) {
    return true;
  }
  return false;
};

/**
 * Focuses on the next focusable element in the DOM
 * @param {NodeListOf<Element>} focusableElements List of focusable elements
 * @param {Element} currentElement The currently focused element
 */
export const focusNextElement = (
  focusableElements: NodeListOf<Element>,
  currentElement: Element,
) => {
  // Convert NodeList to Array for easier manipulation
  const elementsArray = Array.from(focusableElements);

  // Find the index of the current element
  const currentIndex = elementsArray.indexOf(currentElement);

  // Calculate the index of the next element, wrapping around if necessary
  const nextIndex = (currentIndex + 1) % elementsArray.length;

  // Focus on the next element
  setTimeout(() => {
    (elementsArray[nextIndex] as HTMLElement)?.focus();
  });
};
