import {
  Box,
  CircularProgress,
  Step,
  StepContent,
  Stepper,
} from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AssignmentConfig,
  Connector,
  WorkflowInfo,
} from 'protos/pb/v1alpha2/connector';
import ConnectorGeneralContent from './Steps/ConnectorGeneralContent';
import {
  createConnectorErrorAction,
  getConnectorAction,
  getConnectorErrorAction,
  setSelectedConnectorAction,
  updateConnectorAction,
} from '../../../redux/actions/connector.action';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import {
  processConnectorErrorSelector,
  processingConnectorSelector,
  selectedConnectorErrorSelector,
  selectedConnectorLoadingSelector,
  selectedConnectorSelector,
  updatedConnectorSelector,
} from '../../../redux/selectors/connector.selectors';
import { notification } from 'antd';
import { UpdateConnectorRequest } from 'protos/pb/v1alpha2/connector_service';
import { getCompleteCompositeGroupCondition } from '../../../utils/helpers';
import { useFetchWorkflowFilters } from '../../../hooks/useFetchWorkflowFilters';
import { FormikValues } from 'formik';
import CreateConnectorCondition from './Steps/CreateConnectorCondition';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import StepLabelComponent from '../components/StepLabelComponent';
import StepperActionComponent from '../components/StepperActionComponent';

enum CreationStep {
  StepOne,
  StepTwo,
}

const STEPS = [
  {
    title: 'Workflow connector',
    background: '#E8F4E3',
    iconColor: '#3BA755',
  },
  {
    title: 'Create condition',
    background: '#E8E8FC',
    iconColor: '#4F52B2',
  },
];

const ConnectorUpdatePage: FC = () => {
  const { connector_id } = useParams();

  const selectedConnector = useSelector(selectedConnectorSelector);
  const selectedConnectorLoading = useSelector(
    selectedConnectorLoadingSelector,
  );
  const selectedConnectorError = useSelector(selectedConnectorErrorSelector);

  const [updationTriggered, setUpdationTriggered] = useState(false);
  const [activeStep, setActiveStep] = useState<CreationStep>(
    CreationStep.StepOne,
  );
  const [moveToStep, setMoveToStep] = useState<CreationStep | null>(null);
  const [isStepOneClicked, setIsStepOneClicked] = useState(false);

  const [connector, setConnector] = useState<Connector | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const connectorError = useSelector(processConnectorErrorSelector);
  const updatingConnector = useSelector(processingConnectorSelector);
  const updatedConnector = useSelector(updatedConnectorSelector);
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  const [api, contextHolder] = notification.useNotification();

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openWarning = (msg: string) => {
    api.info({
      message: 'Warning',
      description: msg,
      placement: 'topRight',
    });
  };

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  // Set connector in state when it is loaded
  useEffect(() => {
    if (!connector && selectedConnector) {
      if (
        selectedConnector.orgResourceName === selectedOrgInfo?.orgResourceName
      ) {
        setConnector(selectedConnector);
      } else {
        openWarning('Connector does not belong to this organization');
        setTimeout(() => {
          navigate('/workflow');
        }, 1500);
      }
    }
  }, [selectedConnector, connector]);

  // Load connector using id from url params when page is opened
  useEffect(() => {
    dispatch(getConnectorAction(`connectors/${connector_id}`));
    return () => {
      setUpdationTriggered(false);
      dispatch(setSelectedConnectorAction(undefined));
    };
  }, []);

  useEffect(() => {
    if (selectedConnectorError) {
      openError(selectedConnectorError);
      dispatch(getConnectorErrorAction());
    }
  }, [selectedConnectorError]);

  useEffect(() => {
    if (connectorError && updationTriggered) {
      setConnector(connector);
      openError(connectorError);
      dispatch(createConnectorErrorAction(undefined));
    }
  }, [connectorError]);

  useEffect(() => {
    if (updatedConnector && updationTriggered) {
      openSuccess(
        'Connector ' + updatedConnector.name + ' is updated successfully',
      );
      setConnector(connector);
      setTimeout(() => {
        setActiveStep(0);
        navigate(-1);
      }, 1000);
    }
  }, [updatedConnector]);

  const handleSubmitGeneralContent = (values: FormikValues) => {
    const c: Connector = Connector.create(connector);
    c.displayName = values.connector_name;
    c.description = values.description;
    setConnector(c);
    setActiveStep(activeStep + 1);
  };

  const handleSubmitConnectorCondition = (values: FormikValues) => {
    const c: Connector = Connector.create(connector);

    c.sourceWorkflow = WorkflowInfo.create({
      workflowResourceName: values.source_workflow,
    });
    c.destinationWorkflow = WorkflowInfo.create({
      workflowResourceName: values.destination_workflow,
    });
    c.groupCondition = getCompleteCompositeGroupCondition(
      values.conditionGroups,
    );

    c.orgResourceName = selectedOrgInfo?.orgResourceName;
    c.assignmentConfig = AssignmentConfig.create({
      preserveAssignee: values.preserve_assignee,
    });
    const req: UpdateConnectorRequest = {};
    req.connector = c;
    dispatch(updateConnectorAction(req));
    setUpdationTriggered(true);
  };

  const getStepContent = (index: number) => {
    if (index === CreationStep.StepOne) {
      return (
        <ConnectorGeneralContent
          connector={connector as Connector}
          onSubmit={handleSubmitGeneralContent}
          isStepOneClicked={isStepOneClicked}
          setIsStepOneClicked={setIsStepOneClicked}
        />
      );
    } else if (index === CreationStep.StepTwo) {
      return (
        <CreateConnectorCondition
          workflowFiltersLoading={workflowFiltersLoading}
          workflowFilters={workflowFilters}
          connector={connector}
          onSubmit={handleSubmitConnectorCondition}
        />
      );
    }
  };

  const handleStepUpdate = (step: number) => {
    setMoveToStep(step);
  };

  useEffect(() => {
    if (moveToStep !== null) {
      if (activeStep === CreationStep.StepOne) {
        setIsStepOneClicked(true);
      } else if (activeStep === CreationStep.StepTwo) {
        setActiveStep(moveToStep);
      }
    }
    setTimeout(() => {
      setMoveToStep(null);
    }, 100);
  }, [moveToStep]);

  if (selectedConnectorLoading || !connector) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        height={'100vh'}
        alignItems={'center'}
      >
        {contextHolder}
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      bgcolor={OrbyColorPalette['white-0']}
      paddingX={'48px'}
      paddingTop={'64px'}
      width={'100%'}
    >
      {contextHolder}
      <OrbyTypography
        size='display-xs'
        weight='semibold'
        sx={{
          marginBottom: '24px',
        }}
      >
        Edit Connector: {connector.displayName}
      </OrbyTypography>
      <OrbyTypography
        size='md'
        color={OrbyColorPalette['grey-600']}
        sx={{
          marginBottom: '24px',
        }}
      >
        Imagine connectors as bridges that connect various workflows, based on
        specific conditions.
      </OrbyTypography>

      {/* STEPPER */}
      <Stepper
        sx={{
          '& .MuiStepContent-root': {
            borderColor: OrbyColorPalette['blue-700'],
            borderLeftWidth: '2px',
            marginLeft: '24px',
          },
          '& .MuiStepConnector-root': {
            maxHeight: '12px',
          },
          '& .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['blue-700'],
            borderLeftWidth: '2px',
            marginLeft: '12px',
          },
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: OrbyColorPalette['grey-300'],
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
      >
        {STEPS.map((step, index) => {
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
            >
              <StepLabelComponent
                title={step.title}
                isActiveStep={activeStep === index}
                step={index + 1}
                isStepCompleted={activeStep > index}
                updateStep={handleStepUpdate}
              />
              <StepContent>
                <Box>{getStepContent(index)}</Box>

                {/* ACTIONS */}
                <StepperActionComponent
                  loading={updatingConnector}
                  step={activeStep}
                  setActiveStep={setActiveStep}
                  totalSteps={STEPS.length - 1}
                  activeStep={activeStep}
                  setMoveToStep={setMoveToStep}
                />
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default memo(ConnectorUpdatePage);
