import React from 'react';
import DocumentBoundingBoxLayout from './DocumentBoundingBoxLayout/DocumentBoundingBoxLayout';
import { Activity } from 'protos/automation_mining/automation_mining';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import DocumentTokensLayout from './DocumentTokensLayout';
import TableAnnotationSuggestionLayout from './TableAnnotationSuggestionLayout';
import DocumentBoundingBoxHighlight from './DocumentBoundingBoxHighlight';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import DocumentCursorBox from './DocumentCursorBox';
import DocumentTableDebugLayout from './DocumentTableDebugLayout';
import { useSelector } from 'react-redux';
import { debugLayoutSelector } from '../../../../../redux/selectors/taskV2.selectors';
import { FEATURE_FLAGS } from '../../../../../utils/constants';
import { isFeatureFlagEnabled } from '../../../../FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../../redux/selectors/feature_flags.selectors';
import DocumentTokensListHighlight from './DocumentTokensListHighlight';

interface Props {
  scale: number;
  currentPage: number;
  documentActivityType: Activity;
  documentStep: ExecutionStep;
}

const DocumentLayoutOverlay: React.FC<Props> = ({
  scale,
  currentPage,
  documentActivityType,
  documentStep,
}) => {
  const enableDebugLayout = useSelector(debugLayoutSelector);
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isTableDebugLayoutEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.TABLE_DEBUG_LAYOUT,
    featureFlags,
  );

  return (
    <>
      {documentActivityType !== Activity.CLASSIFY_DOCUMENT && (
        <DocumentBoundingBoxLayout currentPage={currentPage} scale={scale} />
      )}
      {/* This component is used to display the tokens on the document and hence used for text search */}
      <DocumentTokensLayout
        document={documentStep.documents?.[0] as Document}
        currentPage={currentPage}
        scale={scale}
      />
      <TableAnnotationSuggestionLayout
        scale={scale}
        currentPage={currentPage}
      />
      <DocumentBoundingBoxHighlight scale={scale} currentPage={currentPage} />
      {enableDebugLayout && isTableDebugLayoutEnabled && (
        <DocumentTableDebugLayout
          document={documentStep.documents![0]}
          currentPage={currentPage}
          scale={scale}
        />
      )}
      <DocumentCursorBox
        documentStep={documentStep}
        currentPage={currentPage}
        scale={scale}
      />
      {/* This component displays the highlight for tokens which are covered in click and drag
          in real time when user moves the mouse
       */}
      <DocumentTokensListHighlight scale={scale} currentPage={currentPage} />
    </>
  );
};

export default React.memo(DocumentLayoutOverlay);
