import { Box, IconButton, TextField } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import CustomTypography from '../../../../../components/CustomTypography';
import {
  setSearchTextAction,
  toggleSearchResultAction,
} from '../../../../../redux/actions/review_task.action';
import {
  searchedTokensStartIndicesSelector,
  selectedTokenIndexSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import { goToVisibleElement } from '../../../../../utils/ReviewTaskUtils';

const ReviewPageSearchField = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const searchedTokensStartIndices =
    useSelector(searchedTokensStartIndicesSelector) ?? [];
  const selectedTokenIndex = useSelector(selectedTokenIndexSelector);

  // EVENT LISTENER TO OPEN SEARCH FIELD ON CTRL + F CLICK.
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'f') {
        e.preventDefault();
        setOpen(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (searchedTokensStartIndices.length) {
      dispatch(toggleSearchResultAction(searchedTokensStartIndices[0]));
    } else {
      dispatch(toggleSearchResultAction(undefined));
    }
  }, [searchedTokensStartIndices]);

  useEffect(() => {
    if (selectedTokenIndex) {
      goToVisibleElement(`token-index-${selectedTokenIndex}`);
    }
  }, [selectedTokenIndex]);

  const focusedTokenIndex = searchedTokensStartIndices.findIndex(
    (v) => v === selectedTokenIndex,
  );

  const handleGoToPrevResult = () => {
    if (focusedTokenIndex === 0) {
      // If it is the first one, focus on the last search result
      dispatch(
        toggleSearchResultAction(
          searchedTokensStartIndices[searchedTokensStartIndices.length - 1],
        ),
      );
    } else {
      // Focus on the previous search result
      dispatch(
        toggleSearchResultAction(
          searchedTokensStartIndices[focusedTokenIndex - 1],
        ),
      );
    }
  };

  const handleGoToNextResult = () => {
    if (focusedTokenIndex === searchedTokensStartIndices.length - 1) {
      // If it is the last one, wrap around and focus on the first search result
      dispatch(toggleSearchResultAction(searchedTokensStartIndices[0]));
    } else {
      // Focus on the next search result
      dispatch(
        toggleSearchResultAction(
          searchedTokensStartIndices[focusedTokenIndex + 1],
        ),
      );
    }
  };

  return (
    <>
      {open ? (
        <TextField
          id='document-search-field'
          autoFocus
          placeholder='Search'
          onChange={_.debounce((event) => {
            dispatch(setSearchTextAction(event.target.value.toLowerCase()));
          }, 300)}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              document?.activeElement?.id === 'document-search-field'
            ) {
              handleGoToNextResult();
            }
          }}
          InputProps={{
            sx: {
              bgcolor: '#FFFFFF',
              borderRadius: '50px',
              height: '32px',
              width: '315px',
              zIndex: 2,
            },
            endAdornment: (
              <Box display={'flex'} gap={'2px'}>
                <CustomTypography size='12px' lineHeight='24px' color='#344054'>
                  {/* Selected token index */}
                  {searchedTokensStartIndices.length
                    ? focusedTokenIndex + 1
                    : 0}
                  /{/* Total tokens count */}
                  {searchedTokensStartIndices.length
                    ? searchedTokensStartIndices.length
                    : 0}
                </CustomTypography>
                <IconButton
                  aria-label='Go to previous result'
                  sx={{ height: '24px', width: '24px', zIndex: 3 }}
                  onClick={handleGoToPrevResult}
                >
                  <ArrowUpward sx={{ color: '#000000', fontSize: '22px' }} />
                </IconButton>
                <IconButton
                  aria-label='Go to next result'
                  sx={{ height: '24px', width: '24px', zIndex: 3 }}
                  onClick={handleGoToNextResult}
                >
                  <ArrowDownward sx={{ color: '#000000', fontSize: '22px' }} />
                </IconButton>
                <IconButton
                  aria-label={'Clear Search'}
                  sx={{ height: '24px', width: '24px', zIndex: 3 }}
                  onClick={() => {
                    // Clear the search text and close the textField
                    dispatch(setSearchTextAction(''));
                    setOpen(false);
                  }}
                >
                  <Close sx={{ color: '#000000', fontSize: '22px' }} />
                </IconButton>
              </Box>
            ),
          }}
        />
      ) : (
        <IconButton
          aria-label={'Search text in PDF'}
          onClick={() => setOpen(true)}
          sx={{ padding: '4px' }}
        >
          <Search sx={{ color: '#000000' }} />
        </IconButton>
      )}
    </>
  );
};

export default memo(ReviewPageSearchField);
