import React, { memo } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { Review, ReviewType } from 'protos/pb/v1alpha2/tasks_service';
import UserCard from './UserCard';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  reviews: Review[];
  anchorEl: Element | null;
  handleClose: () => void;
  header?: JSX.Element;
}

const ReviewerMenu: React.FC<Props> = ({
  reviews,
  anchorEl,
  handleClose,
  header,
}) => {
  return (
    <Menu
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '360px',
          boxShadow:
            '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);',
          borderRadius: '8px',
        },
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
    >
      {header}
      {reviews.map(({ reviewer, reviewType }, index) => (
        <MenuItem
          disabled
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', // TODO: revert this to `start` after adding Modified Timestamp
            opacity: '1.0 !important',
            '&.Mui-disabled': {
              pointerEvents: 'all !important',
            },
          }}
          key={reviewer!.username!}
        >
          <Box sx={{ width: 'calc(100% - 80px)' }}>
            <UserCard
              email={reviewer?.username ?? ''}
              fullName={reviewer?.fullName}
              imageUrl={reviewer?.imageUrl}
            />
          </Box>
          {/* TODO: Add Modified Timestamp when BE is finished */}
          <OrbyTypography
            size='sm'
            weight='regular'
            color={OrbyColorPalette['grey-600']}
          >
            {reviewType === ReviewType.MODIFICATION_REVIEW
              ? 'Modified'
              : index === reviews.length - 1
                ? '1st-round'
                : '2nd-round'}
          </OrbyTypography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default memo(ReviewerMenu);
