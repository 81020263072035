import { Box, Stack, styled } from '@mui/material';
import { OrbyButton, OrbyTabs } from 'orby-ui/src';
import { WorkflowVariable } from 'protos/pb/v1alpha1/orbot_action';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import { canEditWorkflow } from '../../../utils/rbac';
import { getWorkflowVariables } from 'workflow-utils/src/workflow';
import Frame from './Frame';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { GetWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  fetchWorkflow,
  saveWorkflow,
  setHasUnsavedChanges,
  updateWorkflowTitle,
} from '../../../redux/actions/workflow_details.constants';
import { workflowAllDetailsSelector } from '../../../redux/selectors/workflow_details.selectors';
import { Status } from '../../../redux/reducers/workflow_detail.reducer';
import Settings from './Settings';
import Workflow from './Workflow';
import FullPageLoader from '../../../components/FullPageLoader';
import { EXTENSION_ID } from 'extension/src/constants';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
}));

const WorkflowDetails: React.FC = () => {
  const { workflow_id: workflowId } = useParams<{
    workflow_id: string;
  }>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Information about the current logged in user
  const user = useSelector(loggedInUserSelector);

  // All the information of workflow details
  const workflowStore = useSelector(workflowAllDetailsSelector);

  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;

  // Local states
  const [workflowVariables, setWorkflowVariables] = useState<
    WorkflowVariable[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<string>('0');

  // Request const for fetching the workflow
  const fetchWorkflowRequest: GetWorkflowRequest = useMemo(() => {
    return {
      workflowId: workflowId,
      orgId,
    };
  }, [workflowId]);

  const tabOptions = [
    {
      label: 'Definition',
      id: '0',
    },
  ];

  // TODO: Remove the 1 from the below code because right now my account is not allowed to edit anything
  const userCanEditWorkflow = canEditWorkflow(1 || user?.role);
  if (userCanEditWorkflow) {
    tabOptions.push({
      label: 'Settings',
      id: '1',
    });
  }

  useEffect(() => {
    // Setting the hasUnsavedChanges to false when the workflow is loaded
    dispatch(setHasUnsavedChanges(false));
  }, []);

  // TODO: chrome.runtime would be defined if any extension allow us to connect,
  //       we need better ways to check if Orby extension is installed.
  const canRunWorkflow = !!chrome.runtime;

  useEffect(() => {
    if (!workflowId) return;
    dispatch(fetchWorkflow(fetchWorkflowRequest));
  }, [workflowId]);

  useEffect(() => {
    if (!workflowStore?.workflow?.id) return;
    setWorkflowVariables(getWorkflowVariables(workflowStore.workflow!));
  }, [workflowStore?.workflow?.id]);

  // The below is for setting the tabs according to the user's role
  useEffect(() => {
    if (selectedTab !== undefined) {
      switch (selectedTab) {
        case '0': {
          navigate(
            {
              pathname: `/workflow/${workflowId}/definition`,
            },
            { replace: true },
          );
          break;
        }
        case '1': {
          navigate(
            {
              pathname: `/workflow/${workflowId}/settings`,
            },
            { replace: true },
          );
          break;
        }
        default:
          break;
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (window.location.pathname.includes('workflow/definition/')) {
      setSelectedTab('0');
    } else if (window.location.pathname.includes('workflow/settings/')) {
      setSelectedTab('1');
    }
  }, [window.location.pathname]);

  const updateWorkflowVariable = (key: string, value: string) => {
    setWorkflowVariables((prev) =>
      prev.filter(({ key: k }) => k !== key).concat({ key, value }),
    );
  };

  const runWorkflow = () => {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(EXTENSION_ID, {
        type: 'runWorkflow',
        workflowId,
      });
    }
  };

  if (workflowStore.status === Status.Loading || workflowStore.loading) {
    return <FullPageLoader />;
  }

  return (
    <Frame
      title={workflowStore?.workflow?.displayName || ''}
      enableLogoBack
      onTitleChange={
        userCanEditWorkflow
          ? (text: string) => dispatch(updateWorkflowTitle(text))
          : undefined
      }
      description={
        workflowStore?.workflow?.lastUpdateTime &&
        `Saved ${formatDate(workflowStore?.workflow?.lastUpdateTime)}`
      }
      action={
        <Stack direction='row' spacing={2}>
          {userCanEditWorkflow && (
            <OrbyButton
              sx={{ height: '36px', borderColor: '#0000001F' }}
              variant='monochrome-outline'
              disabled={
                !workflowStore.hasUnsavedChanges ||
                workflowStore.invalidateChanges.size > 0
              }
              label='Save'
              onClick={() =>
                dispatch(saveWorkflow(workflowStore.workflow!, orgId))
              }
            />
          )}

          {canRunWorkflow && (
            <OrbyButton
              sx={{ height: '36px', borderColor: '#0000001F' }}
              variant='monochrome-outline'
              onClick={runWorkflow}
              disabled={workflowStore.hasUnsavedChanges}
              label={'Run'}
            />
          )}
        </Stack>
      }
      hasUnsavedChanges={workflowStore.hasUnsavedChanges}
      onSave={() => dispatch(saveWorkflow(workflowStore.workflow!, orgId))}
      onDiscard={() => dispatch(fetchWorkflow(fetchWorkflowRequest))}
    >
      <Container>
        <OrbyTabs
          tabs={tabOptions}
          selectedTab={+selectedTab}
          setSelectedTab={(tabNumber) => setSelectedTab(`${tabNumber}`)}
        />

        {selectedTab === '1' ? (
          <Settings workflow={workflowStore.workflow} />
        ) : (
          <Workflow
            workflow={workflowStore.workflow}
            isEditable={userCanEditWorkflow}
            workflowVariables={workflowVariables}
            updateWorkflowVariable={updateWorkflowVariable}
          />
        )}
      </Container>
    </Frame>
  );
};

export default WorkflowDetails;
