import React, { memo } from 'react';
import { isFeatureFlagEnabled } from '../../../pages/FeatureFlags/FeatureFlagUtils';
import { useSelector } from 'react-redux';
import {
  getFeatureFlagsForOrgAndUserLoadingSelector,
  getFeatureFlagsForOrgAndUserSelector,
} from '../../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../../utils/constants';
import NewWorkflowCreationPage from './WorkflowCreationPage';
import { Box, CircularProgress } from '@mui/material';
import WorkflowCreationPage from '../../../pages/workflow-creation/WorkflowCreationPage';

const CreateWorkflow = () => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const featureFlagsLoading = useSelector(
    getFeatureFlagsForOrgAndUserLoadingSelector,
  );
  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  if (featureFlagsLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vH'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isWebAppIntegrationEnabled ? (
    <NewWorkflowCreationPage />
  ) : (
    <WorkflowCreationPage />
  );
};

export default memo(CreateWorkflow);
