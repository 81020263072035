import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkflowFiltersAction } from '../redux/actions/filter_options.action';
import {
  workflowFiltersErrorSelector,
  workflowFiltersLoadingSelector,
  workflowFiltersSelector,
} from '../redux/selectors/filter_options.selectors';

export const useFetchWorkflowFilters = (orgResourceName?: string) => {
  const dispatch = useDispatch();
  const workflowFilters = useSelector(workflowFiltersSelector);
  const workflowFiltersLoading = useSelector(workflowFiltersLoadingSelector);
  const workflowFiltersError = useSelector(workflowFiltersErrorSelector);

  useEffect(() => {
    if (orgResourceName) {
      dispatch(getWorkflowFiltersAction(orgResourceName));
    }
  }, [orgResourceName]);

  return { workflowFilters, workflowFiltersLoading, workflowFiltersError };
};
