import { Box, Chip, IconButton } from '@mui/material';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ReactComponent as SettingsIcon } from '../../../../static/icons/settings-rounded.svg';
import { ReactComponent as AddBlackIcon } from '../../../../static/icons/add-black.svg';
import './step.css';
import {
  checkIfNotesEntityType,
  checkIfNestedEntityType,
  isInvalidNotesEntity,
  hasInvalidCharacters,
} from '../../../../utils/entities';
import ExtractSelectSchemaField from './ExtractSelectSchemaField';
import {
  allowParentNamePrefixForGoogleOrgs,
  getActionIndex,
  getEnteredEntityType,
  getEntitiesOtherThanNotesAndNestedType,
  getEntitySchemaListWithString,
  isChildPrefixedWithParentName,
  trimParentNamePrefix,
  validateAllCsvEntities,
  validateCsvEntity,
} from '../../../../utils/helpers';
import { ApplicationName } from '../../../../utils/protos/enums';
import {
  EntityDataType,
  EntityDetails,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import TextFilePickerComponent from './TextFilePicker';
import Papa from 'papaparse';
import WarningIcon from '../../../../static/icons/warning_icon.svg';
import { v4 as uuidv4 } from 'uuid';
import {
  EMAIL_DEFAULT_ENTITIES,
  FileName,
  defaultEntityIdPrefix,
  emailDefaultEntities,
  fileNameEntity,
  getDefaultEntityId,
  invalidCharactersInEntityNameMsg,
  invalidNotesEntityNameMsg,
  MAX_SIMPLE_ENTITIES,
} from '../../../../utils/constants';
import { getEntityDetailsFromSchemaMapping } from '../../../../utils/yamlToJsonParser';
import { useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { OrgInfo } from 'protos/pb/v1alpha1/user';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface Props {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  edit?: boolean;
  isEmailExtraction?: boolean;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

export interface SchemaEntity {
  id: string;
  entityName: string;
  normalizationType: EntityDataType;
  parentEntityId?: string;
}

export interface CSVEntityDetails {
  entity_name: string;
  entity_type: string;
  parent_entity_name: string;
}

export interface SchemaEntityError {
  entityName: string;
  errorMessage: string;
}

const parseMappingColumns = (
  entitiesDetails: EntityDetails[],
  parentEntityId?: string,
): SchemaEntity[] => {
  const schemaEntities: SchemaEntity[] = [];
  for (const entityDetails of entitiesDetails) {
    if (
      Object.values(EMAIL_DEFAULT_ENTITIES).includes(
        entityDetails.entityType as EMAIL_DEFAULT_ENTITIES,
      ) ||
      entityDetails.entityType === FileName
    ) {
      schemaEntities.push({
        id: getDefaultEntityId(),
        entityName: entityDetails.entityType as string,
        normalizationType: entityDetails.normalizationType as EntityDataType,
      });
    } else {
      const entityId = uuidv4();
      schemaEntities.push({
        id: entityId,
        entityName: entityDetails.entityType as string,
        normalizationType: entityDetails.normalizationType as EntityDataType,
        parentEntityId: parentEntityId,
      });
      schemaEntities.push(
        ...parseMappingColumns(
          entityDetails.properties as EntityDetails[],
          entityId,
        ),
      );
    }
  }
  return schemaEntities;
};

const ExtractSchemaDefinition: FC<Props> = ({
  onSubmit,
  workflow,
  edit = false,
  isEmailExtraction = false,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  const defaultEntities = isEmailExtraction
    ? emailDefaultEntities
    : fileNameEntity;
  const entityExtractionIndex = getActionIndex(
    workflow,
    ApplicationName.EntityExtraction,
  ) as { stepIndex: number; actionIndex: number };

  const selectedOrgInfo =
    useSelector(selectedOrgInfoSelector) ?? OrgInfo.create({});

  const entityExtraction =
    workflow?.steps?.[entityExtractionIndex.stepIndex].actions?.[
      entityExtractionIndex.actionIndex
    ].entityExtraction;
  const mappingColumns = parseMappingColumns(
    entityExtraction?.entitiesDetails?.length
      ? entityExtraction.entitiesDetails
      : getEntityDetailsFromSchemaMapping(entityExtraction!),
  );
  const [schemaEntities, setSchemaEntities] = useState<SchemaEntity[]>(
    mappingColumns.length === 0 ? defaultEntities : mappingColumns,
  );
  const [value, setValue] = useState('');
  const [schemaEntitiesErrors, setSchemaEntitiesErrors] = useState<
    SchemaEntityError[]
  >([]);
  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const [csvFileError, setCsvFileError] = useState<string | undefined>();

  const showErrorMessage = () => {
    const findNestedEntity = schemaEntities.find((e) =>
      checkIfNestedEntityType(e.entityName),
    );
    const findNormalEntity = schemaEntities.find(
      (e) =>
        !checkIfNotesEntityType(e.entityName) &&
        !checkIfNestedEntityType(e.entityName) &&
        e.entityName !== FileName,
    );
    return !findNestedEntity && !findNormalEntity;
  };
  // Determines whether to show an error message for a parent entity.
  const showParentErrorMessage = (
    entity: SchemaEntity | undefined = undefined,
  ) => {
    // Check if the entity is provided
    if (entity) {
      // Check if form is submitted and show error if the entity is a parent and has no child entities
      if (
        isSubmitClicked &&
        entity.normalizationType === EntityDataType.ENTITY_TYPE_NESTED
      ) {
        return !schemaEntities.some((e) => e.parentEntityId === entity.id);
      }
      return false;
    }
    // If no specific entity is provided, check all parent entities
    const parentEntities = getEntitiesWithoutChild().filter(
      (e) => e.normalizationType === EntityDataType.ENTITY_TYPE_NESTED,
    );
    // Show error if any parent entity has no child entities
    const isEmptyParent = parentEntities.some(
      (p) => !schemaEntities.some((e) => e.parentEntityId === p.id),
    );
    return isEmptyParent;
  };

  const formik = useFormik({
    initialValues: {
      schemaEntities: schemaEntities,
    },
    validationSchema: Yup.object({
      schemaEntities: Yup.array()
        .min(defaultEntities.length + 1, 'Please add at least one entity field')
        .required('Please add at least one entity field')
        .test('test-contain-all-types', function () {
          if (showParentErrorMessage()) {
            return this.createError({
              path: this.path,
              message:
                'Please ensure each parent entity has at least one child entity.',
            });
          }
          if (showErrorMessage()) {
            return this.createError({
              path: this.path,
              message:
                'Please add at least one entity directly extracted from files',
            });
          }
          return true;
        }),
    }),
    onSubmit: (values) => {
      onSubmit(values, true);
    },
  });

  // Handle file selection
  const handleFileChange = (
    file: File,
    setOpen: (x: boolean | undefined) => void,
    setFile: (x: File | null) => void,
  ) => {
    setCsvFileError(undefined);
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          try {
            const entityList = results.data as CSVEntityDetails[];
            let entityString = '';
            let entityTypeString = '';
            let parentString = '';
            validateAllCsvEntities(entityList, defaultEntities, schemaEntities);
            entityList.forEach((entityDetail) => {
              const entityName = entityDetail.entity_name?.toLowerCase();
              const entityType = entityDetail.entity_type;
              const parent = entityDetail.parent_entity_name?.toLowerCase();
              // Validate Entity
              validateCsvEntity(entityName, entityType);
              // Add '*' to annotation entities if missing
              const annotationPrefix =
                getEnteredEntityType(entityType) ===
                  EntityDataType.ENTITY_TYPE_ANNOTATION &&
                !entityName.startsWith('*')
                  ? '*'
                  : '';
              entityString += `${annotationPrefix}${entityName.trim()}` + ',';
              entityTypeString += `${entityType.trim()}` + ',';
              parentString += (parent || '').trim() + ',';
            });
            entityString = entityString.slice(0, -1);
            entityTypeString = entityTypeString.slice(0, -1);
            parentString = parentString.slice(0, -1);
            handleAddEntityWithString(
              entityString,
              entityTypeString,
              parentString,
              undefined,
              true,
            );
            setOpen(false);
            setFile(null);
          } catch (e: any) {
            // Handle any errors that occur during CSV upload
            setCsvFileError(e.message || 'Error uploading CSV file.');
            return;
          }
        },
      });
    } else {
      setCsvFileError('File is required.');
      return;
    }
  };

  // Function that adds entities based on input entered
  // This is helpful when entities are added in bulk separated by commas
  const handleAddEntityWithString = (
    entityString: string,
    entityTypeString?: string,
    parentString?: string,
    parentEntityId?: string,
    isCsv = false,
  ) => {
    const entitySchemaList = getEntitySchemaListWithString(
      entityString,
      entityTypeString,
      parentString,
      parentEntityId,
    );
    handleAddEntity(entitySchemaList, isCsv);
  };

  // If newly added entity is not Notes entity and the length exceeds MAX_SIMPLE_ENTITIES length
  // block adding entity and show error
  const showErrorInAddEntity = (
    entity: SchemaEntity,
    entitiesList: SchemaEntity[],
  ) => {
    return (
      !entity.parentEntityId &&
      !checkIfNotesEntityType(entity.entityName) &&
      getEntitiesOtherThanNotesAndNestedType(entitiesList).length -
        defaultEntities.length >=
        MAX_SIMPLE_ENTITIES
    );
  };

  const checkForError = (
    entity: SchemaEntity,
    entitiesList: SchemaEntity[],
    isCsv = false,
  ) => {
    let entityName = entity.entityName.toString();
    let error: string | undefined;

    if (
      allowParentNamePrefixForGoogleOrgs(selectedOrgInfo.orgResourceName!) &&
      entity.parentEntityId
    ) {
      const parentEntityName = entitiesList.find(
        (s) => s.id === entity.parentEntityId,
      )?.entityName;
      const childEntities = entitiesList.filter(
        (e) => e.parentEntityId === entity.parentEntityId,
      );
      const isEntityPrefixed = isChildPrefixedWithParentName(
        entityName,
        parentEntityName!,
      );

      if (isCsv && parentEntityName && childEntities.length > 0) {
        const isPrefixedEntityExpected = isChildPrefixedWithParentName(
          childEntities[0].entityName,
          parentEntityName,
        );
        const showError = isPrefixedEntityExpected !== isEntityPrefixed;
        const errorMessage = `Either Prefix all child entities with "${parentEntityName}/" or remove the prefix "${parentEntityName}/" from all entities`;
        if (showError) {
          setSchemaEntitiesErrors((prev) => [
            ...prev,
            { entityName: entityName, errorMessage: errorMessage },
          ]);
          return true;
        }
      }

      if (parentEntityName && isEntityPrefixed) {
        entityName = trimParentNamePrefix(entityName);
      }
    }
    entitiesList = entitiesList.filter(
      (e) => e.normalizationType !== EntityDataType.ENTITY_TYPE_NESTED,
    );
    if (entityName === '') {
      return true;
    } else if (showErrorInAddEntity(entity, entitiesList)) {
      error = `You can add a maximum of ${MAX_SIMPLE_ENTITIES} normal entities`;
    } else if (
      !entity.parentEntityId &&
      entitiesList.find((e) => !e.parentEntityId && e.entityName === entityName)
    ) {
      error = 'Already Exists';
    } else if (isInvalidNotesEntity(entityName)) {
      error = invalidNotesEntityNameMsg;
    } else if (hasInvalidCharacters(entityName)) {
      error = invalidCharactersInEntityNameMsg;
    }
    if (error !== undefined) {
      setSchemaEntitiesErrors((prev) => [
        ...prev,
        { entityName: entityName, errorMessage: error },
      ]);
    }
    return error !== undefined;
  };

  const handleAddEntity = (
    schemaEntitiesList: SchemaEntity[],
    isCsv = false,
  ) => {
    setSchemaEntitiesErrors([]);
    const toAddSchemaEntities = [];
    let errorEntityString = '';
    for (const schemaEntity of schemaEntitiesList) {
      const simpleAndChildEntities = [
        ...toAddSchemaEntities,
        ...schemaEntities,
      ];
      if (checkForError(schemaEntity, simpleAndChildEntities, isCsv)) {
        if (!errorEntityString) {
          errorEntityString = schemaEntity.entityName;
        } else {
          errorEntityString += `,${schemaEntity.entityName}`;
        }
        continue;
      }
      toAddSchemaEntities.push(schemaEntity);
    }
    setSchemaEntities([...schemaEntities, ...toAddSchemaEntities]);
    setFieldValue('schemaEntities', [
      ...schemaEntities,
      ...toAddSchemaEntities,
    ]);
    setValue(errorEntityString);
  };

  const handleDelete = (entity: SchemaEntity) => {
    let entities = schemaEntities.filter((e) => e.id !== entity.id);
    if (entity.normalizationType === EntityDataType.ENTITY_TYPE_NESTED) {
      entities = entities.filter((e) => e.parentEntityId !== entity.id);
    }
    setSchemaEntities(entities);
    setFieldValue('schemaEntities', entities);
  };

  const handleUpdateEntityDataType = (entity: SchemaEntity, type: number) => {
    const schemaEntity = entity;
    schemaEntity.normalizationType = type;
    const isNotesEntity = type === EntityDataType.ENTITY_TYPE_ANNOTATION;
    if (isNotesEntity) {
      // Add '*' when entity type is ENTITY_TYPE_ANNOTATION and and first character of entityName is not '*'
      if (!schemaEntity.entityName.startsWith('*')) {
        schemaEntity.entityName = '*' + schemaEntity.entityName;
      }
    } else {
      // Remove '*' when entity type is not ENTITY_TYPE_ANNOTATION and first character of entityName is '*'
      if (schemaEntity.entityName.startsWith('*')) {
        schemaEntity.entityName = schemaEntity.entityName.slice(1);
      }
    }
    const index = schemaEntities.findIndex((e) => e.id === schemaEntity.id);
    schemaEntities.splice(index, 1, schemaEntity);
    setSchemaEntities(schemaEntities);
    setFieldValue('schemaEntities', schemaEntities);
  };

  const getEntitiesWithoutChild = () => {
    return schemaEntities.filter((e) => e.parentEntityId === undefined);
  };

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  const { handleSubmit, setFieldValue, touched, errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        id={formId}
        autoComplete='off'
        noValidate
        onSubmit={(v) => {
          setSubmitClicked(true);
          handleSubmit(v);
        }}
        style={{ width: '100%' }}
      >
        <Box
          display={'flex'}
          marginY={'32px'}
          gap={'51px'}
          paddingLeft={'19px'}
          justifyContent={'space-between'}
        >
          <Box flex={1}>
            <Box
              paddingBottom={'6px'}
              width={'420px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <OrbyTypography
                weight='medium'
                color={OrbyColorPalette['grey-900']}
              >
                Extract entity from file
              </OrbyTypography>

              <TextFilePickerComponent
                error={csvFileError}
                setError={setCsvFileError}
                handleFileChange={handleFileChange}
                style={{
                  textAlign: 'right',
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: '#1570EF',
                }}
              />
            </Box>
            <Box sx={{ width: '400px' }}>
              <OrbyTextField
                aria-label='Enter entity name.'
                aria-describedby='extract_schema'
                width='400px'
                disabled={edit}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleAddEntityWithString(
                      (event.target as HTMLInputElement).value,
                    );
                  }
                }}
                placeholder='Enter entity name'
                value={value}
                onChange={(event) => setValue(event.target.value)}
                endAdornment={
                  <IconButton
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={() => handleAddEntityWithString(value)}
                  >
                    <AddBlackIcon aria-label='Add entity.' />
                  </IconButton>
                }
              />
            </Box>
            {Boolean(touched.schemaEntities && errors.schemaEntities) && (
              <Box>
                <OrbyTypography
                  sx={{ marginTop: '3px' }}
                  color={OrbyColorPalette['error-500']}
                >
                  {(touched.schemaEntities && errors.schemaEntities) as string}
                </OrbyTypography>
              </Box>
            )}
            {schemaEntitiesErrors.length > 0 && (
              <Box>
                <Box padding={'4px 0'} display={'flex'}>
                  <img
                    alt='Warning Icon'
                    style={{ height: '18px', paddingRight: '4px' }}
                    src={WarningIcon}
                  />
                  <OrbyTypography
                    color={OrbyColorPalette['error-500']}
                    sx={{
                      paddingLeft: '4px',
                    }}
                  >
                    There {schemaEntitiesErrors.length === 1 ? 'is' : 'are'}{' '}
                    {schemaEntitiesErrors.length}{' '}
                    {schemaEntitiesErrors.length === 1 ? 'warning' : 'warnings'}
                    :
                  </OrbyTypography>
                </Box>
                <ul
                  style={{
                    fontSize: '10px',
                    paddingLeft: '25px',
                    color: OrbyColorPalette['error-500'],
                  }}
                >
                  {schemaEntitiesErrors.map((entity, index) => {
                    return (
                      <li key={index}>
                        {entity.entityName}: {entity.errorMessage}{' '}
                      </li>
                    );
                  })}
                </ul>
              </Box>
            )}
            <Box width={'100%'}>
              {getEntitiesWithoutChild().map((entity) => {
                if (entity.parentEntityId) {
                  return;
                }
                // CHECKING WHETHER DEFAULT ENTITIES EXISTS SO THAT THEY CAN'T BE REMOVED
                if (
                  defaultEntities.find(
                    (e) =>
                      e.entityName === entity.entityName &&
                      entity.id.startsWith(defaultEntityIdPrefix),
                  ) ||
                  entity.normalizationType ===
                    EntityDataType.ENTITY_TYPE_UNSPECIFIED
                ) {
                  return (
                    <Box
                      border={`1px solid ${OrbyColorPalette['grey-300']}`}
                      key={entity.id}
                      width={'fit-content'}
                      display={'inline-block'}
                      sx={{ margin: '10px', borderRadius: '50px' }}
                    >
                      <Chip
                        className='custom-chip-icon-right'
                        sx={{
                          background: OrbyColorPalette['blueGrey-50'],
                          color: OrbyColorPalette['grey-900'],
                        }}
                        label={entity.entityName}
                      />
                    </Box>
                  );
                }
                return (
                  <ExtractSelectSchemaField
                    schemaEntities={schemaEntities}
                    handleAddEntity={handleAddEntityWithString}
                    handleDeleteEntity={handleDelete}
                    handleUpdateEntityDataType={handleUpdateEntityDataType}
                    isDefaultEntity={false}
                    entity={entity}
                    key={entity.id}
                    hasError={showParentErrorMessage(entity)}
                    isEmailExtraction={isEmailExtraction}
                  />
                );
              })}
            </Box>
          </Box>

          {/* INFO BOX */}
          <Box
            bgcolor={OrbyColorPalette['green-50']}
            borderRadius={'10px'}
            height={'fit-content'}
            padding={'16px'}
            display={'flex'}
            gap={'16px'}
            width={'315px'}
          >
            <Box>
              <SettingsIcon style={{ color: OrbyColorPalette['green-500'] }} />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'8px'}
              paddingRight={'10px'}
              id={'extract_schema'}
            >
              <OrbyTypography color={OrbyColorPalette['grey-700']}>
                You can create entities in three different ways:
              </OrbyTypography>
              <OrbyTypography color={OrbyColorPalette['grey-700']}>
                1. Enter the entity name individually and press the Enter key
              </OrbyTypography>
              <OrbyTypography color={OrbyColorPalette['grey-700']}>
                2. Enter entity names one by one, separated by commas, and then
                press the Enter key to create them in bulk
              </OrbyTypography>
              <OrbyTypography color={OrbyColorPalette['grey-700']}>
                3. Upload a CSV file to create entities in bulk
              </OrbyTypography>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(ExtractSchemaDefinition);
