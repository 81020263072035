import React, { useMemo, useRef } from 'react';
import CustomModal from '../../components/CustomModal';
import ItemDeletionForm, {
  FormikFormRef,
} from './ItemDeletionForm/ItemDeletionForm';
import { Box } from '@mui/material';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface TaskDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (deletionReason: string, selectedTasks: Task[]) => void;
  selectedTasks: Task[];
}

const TaskDeletionModal: React.FC<TaskDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
  selectedTasks,
}) => {
  const formRef = useRef<FormikFormRef>(null);
  const isMultiple = useMemo(() => selectedTasks.length > 1, [selectedTasks]);
  const getModalText = () => {
    return `By deleting ${isMultiple ? `these ${selectedTasks.length} tasks` : 'the task'}, you will also delete the ${isMultiple ? 'files' : 'file'} that created ${isMultiple ? 'these tasks' : 'this task'}.`;
  };
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => formRef.current?.submitForm()}
      heading={isMultiple ? 'Delete Items?' : 'Delete Item?'}
      content={
        <>
          <Box sx={{ maxWidth: '480px' }}>
            <OrbyTypography>{getModalText()}</OrbyTypography>
            <OrbyTypography
              color={OrbyColorPalette['grey-900']}
              sx={{ marginTop: '10px', marginBottom: '10px' }}
              weight='medium'
            >
              {`You can restore deleted ${
                isMultiple ? 'tasks' : 'task'
              } within 30 days post deletion.`}
            </OrbyTypography>
          </Box>
          <ItemDeletionForm
            ref={formRef}
            onSubmit={(reason) => onSubmit(reason, selectedTasks)}
          />
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(TaskDeletionModal);
