import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import CustomFilePicker from '../../../components/CustomFilePicker';
import { Organization } from 'protos/pb/v1alpha1/organization';
import {
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from 'protos/pb/v1alpha1/organization_service';
import {
  SAMLConfig,
  IdpMetadata,
  IdpMetadataXML,
  IdpMetadataNamedFile,
} from 'protos/pb/v1alpha1/saml';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { File } from 'protos/automation_mining/ontology/data_models';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearOrganizationUpdateStatusAction,
  getOrganizationAction,
  getOrganizationErrorAction,
  updateOrganizationAction,
  updateOrganizationErrorAction,
} from '../../../redux/actions/organization.actions';
import {
  organizationDetailsLoadingErrorSelector,
  organizationDetailsLoadingSelector,
  organizationDetailsSelector,
  organizationUpdatedSelector,
  organizationUpdatingErrorSelector,
  organizationUpdatingSelector,
} from '../../../redux/selectors/organization.selectors';
import { ArrowBack } from '@mui/icons-material';
import {
  OrbyTypography,
  OrbyColorPalette,
  OrbyTextField,
  OrbyButton,
  OrbyTabs,
} from 'orby-ui/src';
import UploadIcon from '../../../static/icons/uploadV2.svg';
import XMLIcon from '../../../static/icons/xml-icon.svg';
import * as Yup from 'yup';
import { samlService } from '../../../services/SAMLService';
import { toastService } from '../../../services/ToastService';
import { storageService } from '../../../services/StorageService';
import OktaLogo from '../../../static/icons/okta.svg';
import RadioCheck from '../../../static/icons/radioCheck.svg';
import {
  copyTextToClipBoard,
  getExtensionFromFileName,
} from '../../../utils/helpers';
import { getIdFromResourceName } from '../../../utils/WorkflowUtils';

enum AttributeConfigType {
  MANUAL = 'manual',
  METADATA = 'metadata',
}

interface SSOConfig {
  domainName: string;
  issuer: string;
  ssoUrl: string;
  certificate?: File;
  signOutUrl: string;
  xmlFileUrl?: string;
  xmlFile?: File;
}

const ConfigureSSO: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization_id } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const organizationDetails = useSelector(organizationDetailsSelector);
  const user = useSelector(loggedInUserSelector);
  const loading = useSelector(organizationDetailsLoadingSelector);
  const error = useSelector(organizationDetailsLoadingErrorSelector);
  const orgUpdated = useSelector(organizationUpdatedSelector);
  const orgUpdatingError = useSelector(organizationUpdatingErrorSelector);
  const samlConfig = organizationDetails?.samlConfig;
  const [configType, setConfigType] = useState<AttributeConfigType>(
    AttributeConfigType.MANUAL,
  );
  const [testConnection, setTestConnection] = useState(false);
  const orgUpdating = useSelector(organizationUpdatingSelector);
  const initialValues: SSOConfig = {
    domainName: samlConfig?.domain
      ? samlConfig?.domain
      : user?.email?.split('@')[1] ?? '',
    issuer: samlConfig?.idpMetadata?.entityId ?? '',
    ssoUrl: samlConfig?.idpMetadata?.ssoUrl ?? '',
    signOutUrl: samlConfig?.idpMetadata?.logoutUrl ?? '',
    xmlFileUrl: samlConfig?.idpMetadataXml?.uri ?? '',
    certificate:
      samlConfig?.idpMetadata !== undefined
        ? File.create({
            name:
              samlConfig.idpMetadata.signingCertificates?.[0].name ??
              'Certificate Already Uploaded',
            id: 'Generated File', // this will help us identify if file is generated
          })
        : undefined,
    xmlFile: samlConfig?.idpMetadataXml?.xml
      ? File.create({
          name: samlConfig?.idpMetadataXml?.xml.name ?? 'File Already Uploaded',
          id: 'Generated File', // this will help us identify if file is generated
        })
      : undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema:
      configType === AttributeConfigType.MANUAL
        ? Yup.object({
            // validation for Manual Config
            issuer: Yup.string()
              .url('Issuer must be a valid URL')
              .required('This is a required field'),
            ssoUrl: Yup.string()
              .url('SSO url must be a valid URL')
              .required('This is a required field'),
            signOutUrl: Yup.string().url('Sign Out URL must be a valid URL'),
            certificate: Yup.object().required('This is a required field'),
          })
        : Yup.object({
            // Validation for XML Config
            // THIS FIELD alone will validate if one of xmlFileURL and xmlFile exist or not
            xmlFileUrl: Yup.string()
              .test(
                'at-least-one',
                'At least one field must be filled either url or xml file',
                function (value) {
                  const { xmlFile } = this.parent;
                  return value || xmlFile;
                },
              )
              .test(
                'only-one',
                'Please remove the uploaded file to use the URL instead',
                function (value) {
                  const { xmlFile } = this.parent;
                  return !(value && xmlFile);
                },
              )
              .url('XML File Url must be a valid URL'),
          }),
    onSubmit: async (values) => {
      if (
        (samlConfig?.idpMetadataXml &&
          configType === AttributeConfigType.METADATA) ||
        (samlConfig?.idpMetadata && configType === AttributeConfigType.MANUAL)
      ) {
        const req = UpdateOrganizationRequest.create({
          organization: Organization.create({
            name: orgInfo.orgResourceName,
            samlConfig: SAMLConfig.create({ domain: values.domainName }),
          }),
        });

        if (configType === AttributeConfigType.MANUAL) {
          const prevIdpMetadata = samlConfig?.idpMetadata;
          const idpMetaData = IdpMetadata.create({});
          const fieldMask = [];
          if (values.issuer !== prevIdpMetadata?.entityId) {
            idpMetaData.entityId = values.issuer;
            fieldMask.push('saml_config.idp_metadata.entity_id');
          }

          if (values.ssoUrl !== prevIdpMetadata?.ssoUrl) {
            idpMetaData.ssoUrl = values.ssoUrl;
            fieldMask.push('saml_config.idp_metadata.sso_url');
          }

          if (
            values.certificate?.name &&
            values.certificate.id !== 'Generated File'
          ) {
            idpMetaData.signingCertificates = [
              IdpMetadataNamedFile.create({
                content: values.certificate?.byteContent as Uint8Array,
                name: values.certificate.name,
              }),
            ];
            fieldMask.push('saml_config.idp_metadata.signing_certificates');
          }

          if (values.signOutUrl) {
            idpMetaData.logoutUrl = values.signOutUrl;
            fieldMask.push('saml_config.idp_metadata.logout_url');
          }
          req.organization!.samlConfig!.idpMetadata = idpMetaData;
          req.fieldMask = fieldMask;
        } else {
          req.organization!.samlConfig!.idpMetadataXml = IdpMetadataXML.create({
            uri: values.xmlFileUrl,
            xml: values.xmlFile
              ? IdpMetadataNamedFile.create({
                  content: values.xmlFile?.byteContent,
                  name: values.xmlFile?.name,
                })
              : undefined,
          });
          req.fieldMask = ['saml_config'];
          req.organization!.samlConfig!.signRequest = true;
          req.organization!.samlConfig!.isActivated = true;
        }
        dispatch(updateOrganizationAction(req));
        return;
      }
      const req = UpdateOrganizationRequest.create({
        fieldMask: ['saml_config'],
        organization: Organization.create({
          name: orgInfo.orgResourceName,
          samlConfig: SAMLConfig.create({
            domain: values.domainName,
            isActivated: true,
            signRequest: true,
          }),
        }),
      });
      if (configType === AttributeConfigType.MANUAL) {
        req.organization!.samlConfig!.idpMetadata = IdpMetadata.create({
          entityId: values.issuer,
          logoutUrl: values.signOutUrl,
          signingCertificates: [
            IdpMetadataNamedFile.create({
              content: values.certificate?.byteContent as Uint8Array,
              name: values.certificate?.name,
            }),
          ],
          ssoUrl: values.ssoUrl,
        });
      } else {
        req.organization!.samlConfig!.idpMetadataXml = IdpMetadataXML.create({
          uri: values.xmlFileUrl,
          xml: values.xmlFile
            ? IdpMetadataNamedFile.create({
                content: values.xmlFile?.byteContent,
                name: values.xmlFile?.name,
              })
            : undefined,
        });
      }
      dispatch(updateOrganizationAction(req));
    },
  });
  const {
    values,
    handleChange,
    handleBlur,
    setFieldError,
    setFieldValue,
    errors,
    touched,
    initialValues: previousValues,
    setFieldTouched,
    resetForm,
  } = formik;
  const validateAndSetFile = (files: FileList | null) => {
    setFieldError('certificate', '');
    if (files && files?.length === 1) {
      const localFile = files[0];
      const file = File.create({});
      file.mimeType = localFile.type;
      const fileExtension = getExtensionFromFileName(localFile.name);

      // We validate the file extension instead of the MIME type due to issues with certificate files.
      // These files often lack a proper MIME type but are valid certificates.
      const supportedExtensions = ['crt', 'pem', 'cer', 'der', 'cert'];
      // Check if the file is of valid format
      if (fileExtension && supportedExtensions.includes(fileExtension)) {
        file.path = localFile.name;
        file.name = localFile.name;
        const reader = new FileReader();
        reader.readAsArrayBuffer(localFile);
        reader.onload = function () {
          file.byteContent = new Uint8Array(reader.result as ArrayBuffer);
          setFieldValue('certificate', file);
        };
      } else {
        setFieldTouched('certificate', true);
        setTimeout(() => {
          setFieldError('certificate', 'Invalid File Format');
        }, 10);
      }
    }
  };

  useEffect(() => {
    if (error) {
      toastService.showError(error.message, { position: 'top-right' });
      dispatch(getOrganizationErrorAction());
      // redirect to setting page if there is any loading error
      setTimeout(() => {
        navigate('/settings');
      }, 500);
    }
  }, [error]);

  useEffect(() => {
    if (orgUpdatingError) {
      toastService.showError(orgUpdatingError.message, {
        position: 'top-right',
      });
      dispatch(updateOrganizationErrorAction());
    }
  }, [orgUpdatingError]);

  useEffect(() => {
    if (organization_id && !loading) {
      dispatch(
        getOrganizationAction(
          GetOrganizationRequest.create({
            name: `organizations/${organization_id}`,
          }),
        ),
      );
    }
  }, [organization_id]);

  useEffect(() => {
    if (orgUpdated) {
      toastService.showSuccess('Configuration updated', {
        position: 'top-right',
      });
      resetForm();
      dispatch(clearOrganizationUpdateStatusAction());
    }
  }, [orgUpdated]);

  useLayoutEffect(() => {
    if (organizationDetails) {
      setConfigType(
        samlConfig?.idpMetadata
          ? AttributeConfigType.MANUAL
          : AttributeConfigType.METADATA,
      );
    }
  }, [organizationDetails]);

  const isFormDirty = () => {
    if (configType === AttributeConfigType.MANUAL) {
      if (previousValues?.issuer !== values.issuer) {
        return true;
      }
      if (previousValues?.ssoUrl !== values.ssoUrl) {
        return true;
      }
      if (previousValues?.signOutUrl !== values.signOutUrl) {
        return true;
      }
      if (previousValues.certificate?.id !== values.certificate?.id) {
        return true;
      }
      return false;
    } else {
      if (previousValues?.xmlFileUrl !== values.xmlFileUrl) {
        return true;
      }
      if (previousValues.xmlFile?.id !== values.xmlFile?.id) {
        return true;
      }
      return false;
    }
  };

  const handleVerifyConfig = () => {
    if (orgInfo.orgResourceName) {
      setTestConnection(true);
      samlService
        .verifyConfiguration(orgInfo.orgResourceName)
        .then(() => {
          storageService.setSamlRedirect();
        })
        .catch((e) => {
          toastService.showError(e.message, { position: 'top-right' });
          setTestConnection(false);
        });
    }
  };

  const enableTestConfiguration = () => {
    return (
      samlConfig?.isActivated &&
      (samlConfig.idpMetadata || samlConfig.idpMetadataXml) &&
      !isFormDirty()
    );
  };

  const getToolTipForTestConfig = useMemo(() => {
    if (!enableTestConfiguration()) {
      if (!(samlConfig?.idpMetadata || samlConfig?.idpMetadataXml)) {
        return 'Please add configuration before testing';
      } else if (samlConfig?.isActivated === false) {
        return 'Please activate configuration before testing';
      } else if (isFormDirty()) {
        return 'You have unsaved changes please submit form before testing';
      }
    }
  }, [enableTestConfiguration, isFormDirty, samlConfig]);

  const handleCopy = async () => {
    const status = await copyTextToClipBoard(
      getIdFromResourceName(orgInfo.orgResourceName!)!,
    );
    if (status) {
      toastService.showSuccess('Copied to clipboard');
    }
  };

  return (
    <Box
      height={'100vh'}
      overflow={'hidden'}
      bgcolor={'white'}
      padding={'48px'}
      sx={{ overflow: 'auto' }}
    >
      <Box display={'flex'} alignItems={'center'} gap={2} marginLeft={'-8px'}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack sx={{ color: OrbyColorPalette['grey-700'] }} />
        </IconButton>
        <OrbyTypography size='display-xs' weight='medium'>
          Settings
        </OrbyTypography>
      </Box>
      {loading ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <FormikProvider value={formik}>
          <Form style={{ marginLeft: '48px' }}>
            <Box mt='51px'>
              <OrbyTypography size='lg' weight='medium'>
                1. Select provider
              </OrbyTypography>
              <FormControl component='fieldset' sx={{ pl: '20px', pt: '14px' }}>
                <RadioGroup
                  name='provider'
                  value={'okta'}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value='okta'
                    control={
                      <Radio
                        checkedIcon={<img src={RadioCheck} alt='radio-check' />}
                      />
                    }
                    label={
                      <Box display={'flex'} gap={1} alignItems={'center'}>
                        <img src={OktaLogo} alt='okta-logo' />
                        <OrbyTypography weight='medium'>Okta</OrbyTypography>
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box my={'48px'} maxWidth='500px'>
              <OrbyTypography size='lg' weight='medium'>
                2. Add domain name
              </OrbyTypography>
              <OrbyTextField
                width='100%'
                disabled
                name='domainName'
                label='Add the domain name for SSO'
                containerSx={{ ml: '16px', mt: '24px' }}
                value={values.domainName}
              />
            </Box>

            <Box mb={2} maxWidth='500px'>
              <OrbyTypography size='lg' weight='medium'>
                3. Configure attributes
              </OrbyTypography>
              <Box
                mt={'16px'}
                pl='16px'
                flexDirection={'column'}
                display='flex'
                gap={2}
              >
                <OrbyTabs
                  tabs={[
                    { label: 'Provide Metadata', id: '0' },
                    { label: 'Manually', id: '1' },
                  ]}
                  selectedTab={
                    configType === AttributeConfigType.METADATA ? 0 : 1
                  }
                  setSelectedTab={(tabIndex) => {
                    setConfigType(
                      tabIndex === 0
                        ? AttributeConfigType.METADATA
                        : AttributeConfigType.MANUAL,
                    );
                  }}
                />
                {configType === AttributeConfigType.MANUAL ? (
                  <>
                    <input
                      type='file'
                      accept={'.crt,.pem,.cer,.der,.cert'}
                      ref={inputRef}
                      tabIndex={-1} // disables focus for this element since this element is off screen
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        validateAndSetFile(event.target.files);
                        if (inputRef.current) {
                          inputRef.current.value = '';
                        }
                      }}
                    />
                    <OrbyTextField
                      name='issuer'
                      label='Issuer'
                      width='100%'
                      value={values.issuer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.issuer && errors.issuer ? errors.issuer : ''
                      }
                    />
                    <OrbyTextField
                      width='100%'
                      name='ssoUrl'
                      label='Single Sign-on URL'
                      value={values.ssoUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.ssoUrl && errors.ssoUrl ? errors.ssoUrl : ''
                      }
                    />

                    <OrbyTextField
                      width='100%'
                      error={
                        touched.certificate && errors.certificate
                          ? errors.certificate
                          : ''
                      }
                      onBlur={handleBlur}
                      name='certificate'
                      label='Public Certificate'
                      value={values.certificate?.name ?? ''}
                      readOnly
                      endAdornment={
                        <Button
                          endIcon={
                            <Icon
                              component={'img'}
                              src={UploadIcon}
                              alt='upload'
                            />
                          }
                          variant='text'
                          onClick={() => {
                            if (inputRef.current) {
                              inputRef.current.click();
                            }
                          }}
                          sx={{ color: 'black', py: '2px', px: '4px' }}
                        >
                          {values.certificate?.name ? 'Replace' : 'Upload'}
                        </Button>
                      }
                    />
                    <OrbyTextField
                      error={
                        touched.signOutUrl && errors.signOutUrl
                          ? errors.signOutUrl
                          : ''
                      }
                      width='100%'
                      name='signOutUrl'
                      label='Sign Out URL'
                      value={values.signOutUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </>
                ) : (
                  <Box display={'flex'} flexDirection='column'>
                    <CustomFilePicker
                      files={values.xmlFile ? [values.xmlFile] : []}
                      setFile={(file) => {
                        setFieldValue('xmlFile', file);
                      }}
                      error={!!errors.xmlFile}
                      setError={(val) =>
                        setFieldError('xmlFile', val ? 'Error' : '')
                      }
                      acceptedFormat={'.xml'}
                      acceptedMIMEFormat={['application/xml', 'text/xml']}
                      acceptedFormatMessage={'Only .xml files are supported.'}
                      fileIcon={XMLIcon}
                      allowMultipleFiles={false}
                      isUploading={false}
                    />
                    <Divider sx={{ marginTop: '16px', marginBottom: '16px' }}>
                      <OrbyTypography sx={{ py: '12px' }}>OR</OrbyTypography>
                    </Divider>
                    <OrbyTextField
                      name='xmlFileUrl'
                      width='100%'
                      label='XML File URL'
                      value={values.xmlFileUrl}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.xmlFileUrl && errors.xmlFileUrl
                          ? errors.xmlFileUrl
                          : ''
                      }
                    />
                  </Box>
                )}
                {(organizationDetails?.samlConfig?.idpMetadata ||
                  organizationDetails?.samlConfig?.idpMetadataXml) && (
                  <Box mt='16px'>
                    <OrbyTypography weight='medium'>
                      Copy the default relay state and paste it into the
                      appropriate field in your identity provider to set up
                      IDP-initiated SSO.
                    </OrbyTypography>
                    <Box
                      display={'flex'}
                      gap={1}
                      mt={'8px'}
                      alignItems={'center'}
                    >
                      <OrbyButton
                        onClick={handleCopy}
                        size='small'
                        variant='primary-contained'
                        label='Copy default relay state'
                        ariaLabel={'Copy default relay state'}
                      />
                      <OrbyTypography
                        weight='medium'
                        color={OrbyColorPalette['grey-500']}
                      >
                        {getIdFromResourceName(orgInfo.orgResourceName!)}
                      </OrbyTypography>
                    </Box>
                  </Box>
                )}
                <Box display='flex' gap={1} pt={'32px'}>
                  <OrbyButton
                    sx={{ width: '100%' }}
                    label='Cancel'
                    ariaLabel='Cancel'
                    size='large'
                    variant='primary-outline'
                    onClick={() => navigate(-1)}
                  />
                  <Tooltip
                    title={getToolTipForTestConfig}
                    placement='top'
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-arrow': { color: '#000' },
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: '#000',
                          borderRadius: '8px',
                        },
                      },
                    }}
                  >
                    <Box width={'100%'}>
                      <OrbyButton
                        disabled={!enableTestConfiguration()}
                        sx={{ width: '100%' }}
                        label='Test'
                        ariaLabel='Test'
                        size='large'
                        variant='primary-outline'
                        onClick={handleVerifyConfig}
                        loading={testConnection}
                      />
                    </Box>
                  </Tooltip>
                  <OrbyButton
                    loading={orgUpdating}
                    sx={{ width: '100%' }}
                    disabled={!isFormDirty()}
                    size='large'
                    variant='primary-contained'
                    type='submit'
                    label='Save'
                    ariaLabel={'Save'}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      )}
    </Box>
  );
};
export default ConfigureSSO;
