import React from 'react';
import {
  OrbySelect,
  OrbyMenuItem,
  OrbyTypography,
  OrbyColorPalette,
} from 'orby-ui/src';
import { ELLIPSIS_STYLE } from '../../../utils/constants';
import { Box } from '@mui/material';

export interface Props {
  selectedApps: string[];
  setSelectedApps: (data: string[]) => void;
  width?: string;
  menuWidth?: string;
}

export const APP_TYPE_OTHER = 'Other';

const AppsFilter: React.FC<Props> = ({
  selectedApps,
  setSelectedApps,
  width = '200px',
  menuWidth = '200px',
}) => {
  const DEFAULT_APPS = {
    label: 'All apps',
    value: 'all-apps',
  };

  const APPS = [
    {
      label: 'Gmail',
      value: 'Gmail',
    },
    {
      label: 'Google Drive',
      value: 'Google Drive',
    },
    {
      label: 'Google Sheet',
      value: 'Google Sheets',
    },
    {
      label: 'Outlook',
      value: 'Outlook',
    },
    {
      label: 'MS Excel',
      value: 'MS Excel',
    },
    {
      label: 'SFTP',
      value: 'SFTP Server',
    },
    {
      label: APP_TYPE_OTHER,
      value: APP_TYPE_OTHER,
    },
  ];

  /**
   * HANDLE RENDER VALUE
   */
  const handleRenderValue = () => {
    if (selectedApps.length === 0) {
      return (
        <Box overflow={'hidden'} title={DEFAULT_APPS.label}>
          {renderLabel(DEFAULT_APPS.label)}
        </Box>
      );
    } else if (selectedApps.length === 1) {
      const value = APPS.find((app) => app.value === selectedApps[0])?.label;
      if (value) {
        return (
          <Box overflow={'hidden'} title={value}>
            {renderLabel(value)}
          </Box>
        );
      }
    } else {
      const value = (
        <Box
          overflow={'hidden'}
          display={'flex'}
          gap={'4px'}
          alignItems={'center'}
        >
          <OrbyTypography
            size='sm'
            weight='semibold'
            color={OrbyColorPalette['blue-700']}
          >
            {selectedApps.length}
          </OrbyTypography>
          <OrbyTypography
            size='sm'
            weight='medium'
            color={OrbyColorPalette['grey-700']}
            sx={{
              paddingRight: '10px',
              ...ELLIPSIS_STYLE,
            }}
          >
            selected apps
          </OrbyTypography>
        </Box>
      );
      return (
        <Box overflow={'hidden'} title={`${selectedApps.length} apps selected`}>
          {value}
        </Box>
      );
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <OrbyTypography
        size='sm'
        color={OrbyColorPalette['grey-900']}
        weight={'regular'}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </OrbyTypography>
    );
  };

  return (
    <>
      <OrbySelect
        value={selectedApps}
        multiple={true}
        renderValue={handleRenderValue}
        width={width}
        onChange={(event) => {
          const value = event.target.value as Array<string>;
          if (value) {
            if (
              value[value.length - 1] === DEFAULT_APPS.value ||
              value.length === 0
            ) {
              setSelectedApps([]);
            } else {
              // REMOVE THE DEFAULT VALUE
              const filteredSelectedApps = value.filter(
                (o) => o !== DEFAULT_APPS.value,
              );
              setSelectedApps(filteredSelectedApps);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={menuWidth}
          key={DEFAULT_APPS.value}
          title={DEFAULT_APPS.label}
          value={DEFAULT_APPS.value}
          isSelected={selectedApps.length === 0}
        />
        {APPS.map((app) => {
          return (
            <OrbyMenuItem
              width={menuWidth}
              key={app.value}
              title={app.label}
              value={app.value}
              isSelected={selectedApps.includes(app.value)}
            />
          );
        })}
      </OrbySelect>
    </>
  );
};

export default React.memo(AppsFilter);
