import React from 'react';
import { Box, Card, SxProps, Theme } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  title: string;
  value: string | number;
  sx?: SxProps<Theme> | undefined;
}

export const StatsCard: React.FC<Props> = ({ title, value }) => {
  const isNum = (char?: string) => {
    // @ts-ignore
    return !isNaN(char);
  };
  const replaceText = (value: string | number) => {
    value = value.toString();
    const result = [];
    let lastIsNum = false;
    let lastSubstring = '';
    for (let i = 0; i < value.length; i++) {
      if (isNum(value.at(i))) {
        if (i && !lastIsNum) {
          result.push(
            <OrbyTypography
              weight='bold'
              size='display-xs'
              sx={{
                marginRight: lastSubstring === 'h' ? '8px' : '0px',
              }}
              key={i}
            >
              {lastSubstring}
            </OrbyTypography>,
          );
          lastSubstring = '';
        }
        lastIsNum = true;
        lastSubstring += value.at(i);
      } else {
        if (lastIsNum) {
          result.push(
            <OrbyTypography weight='bold' size='display-md' key={i}>
              {lastSubstring}
            </OrbyTypography>,
          );
          lastSubstring = '';
        }
        lastIsNum = false;
        lastSubstring += value.at(i);
      }
    }
    if (lastIsNum) {
      result.push(
        <OrbyTypography weight='bold' size='display-md' key={lastSubstring}>
          {lastSubstring}
        </OrbyTypography>,
      );
    } else {
      result.push(
        <OrbyTypography weight='bold' size='display-xs' key={lastSubstring}>
          {lastSubstring}
        </OrbyTypography>,
      );
    }
    return result;
  };
  return (
    <Card
      sx={{
        width: '30%',
        height: '130px',
        boxShadow: 'none',
        position: 'relative',
        borderRadius: '12px',
        padding: '32px',
        border: `1px solid ${OrbyColorPalette['purple-100']}`,
      }}
    >
      <Box display={'flex'} height={'44px'} alignItems={'baseline'}>
        {replaceText(value)}
      </Box>
      <OrbyTypography
        weight='medium'
        color={OrbyColorPalette['grey-600']}
        sx={{
          paddingTop: '14px',
        }}
      >
        {title}
      </OrbyTypography>
    </Card>
  );
};
