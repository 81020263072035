import { Box } from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import {
  WORKFLOW_RESOURCE_NAMES,
  USER_RESOURCE_NAMES,
  START_DATE,
  END_DATE,
  PERIOD,
  DateOptionValues,
  DateRange,
  defaultDateRange,
} from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { getDashboardStatsAction } from '../../../redux/actions/dashboard.action';
import { GetDashboardStatsRequest } from 'protos/pb/v1alpha2/dashboard_service';
import {
  getDateRange,
  getValidDateFilter,
  isAdmin,
} from '../../../utils/helpers';
import {
  getWorkflowIdsFromParamString,
  getWorkflowIdsJoinedByDash,
} from '../../Utils/taskV2Utils';
import { useNavigate } from 'react-router-dom';
import { OrgInfo } from 'protos/pb/v1alpha1/user';
import WorkflowFilter from '../../../pages/Tasks/component/WorkflowFilter';
import {
  ALL_USER_VALUE,
  DEFAULT_WORKFLOW_VALUE,
} from '../../Tasks/TaskHelpers';
import UserFilter from '../../../pages/Tasks/component/UserFilter';
import { OrbyTypography } from 'orby-ui/src';
import {
  getUserFiltersAction,
  getUserFiltersErrorAction,
  getWorkflowFiltersAction,
  getWorkflowFiltersErrorAction,
} from '../../../redux/actions/filter_options.action';
import {
  userFiltersErrorSelector,
  userFiltersSelector,
  workflowFiltersErrorSelector,
  workflowFiltersSelector,
} from '../../../redux/selectors/filter_options.selectors';
import { notification } from 'antd';
import TimeFilter from '../../../components/TimeFilter/TimeFilter';
import _ from 'lodash';

const DashboardHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const selectedOrgInfo =
    useSelector(selectedOrgInfoSelector) ?? ({} as OrgInfo);
  const isAdminView = isAdmin(selectedOrgInfo.role);
  const urlSearchParams = new URLSearchParams(location.search);
  const user = useSelector(loggedInUserSelector);
  const workflowFiltersError = useSelector(workflowFiltersErrorSelector);
  const userFiltersError = useSelector(userFiltersErrorSelector);
  const workflowFilters = useSelector(workflowFiltersSelector);
  const userFilters = useSelector(userFiltersSelector);

  const [date, setDate] = useState(
    () => getValidDateFilter(urlSearchParams).period,
  );

  const [customRange, setCustomRange] = useState<DateRange>(
    () => getValidDateFilter(urlSearchParams).range,
  );

  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamString(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) || '',
      DEFAULT_WORKFLOW_VALUE.value,
    ),
  );

  const [selectedUsers, setSelectedUsers] = useState(
    getWorkflowIdsFromParamString(
      urlSearchParams.get(USER_RESOURCE_NAMES) || '',
      ALL_USER_VALUE.value,
    ),
  );

  // Validating selected workflows and users
  useEffect(() => {
    if (workflowFilters.length) {
      // Only proceed if selectedWorkflows does not have default value
      if (
        !(
          selectedWorkflows.length === 1 &&
          selectedWorkflows[0] === DEFAULT_WORKFLOW_VALUE.value
        )
      ) {
        const validWorkflows = new Set([
          ...workflowFilters.map((w) => w.value),
        ]);
        // filter out all the invalid workflows
        let filteredWorkflows = selectedWorkflows.filter((w) =>
          validWorkflows.has(w),
        );
        if (filteredWorkflows.length === 0) {
          filteredWorkflows = [DEFAULT_WORKFLOW_VALUE.value];
        }
        // if both filtered and prev workflow array are same
        // then do not re-set the same state as it will cause unnecessary re-renders
        if (
          filteredWorkflows.length !== selectedWorkflows.length ||
          !_.isEqual(filteredWorkflows, selectedWorkflows)
        ) {
          setSelectedWorkflows(filteredWorkflows);
        }
      }
    }

    if (userFilters.length) {
      // Only proceed if selectedUsers does not have default value
      if (
        !(
          selectedUsers.length === 1 &&
          selectedUsers[0] === ALL_USER_VALUE.value
        )
      ) {
        const validUsers = new Set(userFilters.map((u) => u.value));
        // filter out all the invalid users
        let filteredUsers = selectedUsers.filter((u) => validUsers.has(u));
        if (filteredUsers.length === 0) {
          filteredUsers = [ALL_USER_VALUE.value];
        }
        // if both filtered and prev users array are same
        // then do not re-set the same state as it will cause unnecessary re-renders
        if (
          filteredUsers.length !== selectedUsers.length ||
          !_.isEqual(filteredUsers, selectedUsers)
        ) {
          setSelectedUsers(filteredUsers);
        }
      }
    }
  }, [workflowFilters, userFilters]);

  const updateUrl = () => {
    const searchParams = new URLSearchParams();
    const workflowIds: string | undefined = getWorkflowIdsJoinedByDash(
      selectedWorkflows,
      DEFAULT_WORKFLOW_VALUE.value,
    );
    const userIds: string | undefined = getWorkflowIdsJoinedByDash(
      selectedUsers,
      ALL_USER_VALUE.value,
    );
    if (workflowIds) {
      searchParams.append(WORKFLOW_RESOURCE_NAMES, workflowIds);
    }
    if (userIds) {
      searchParams.append(USER_RESOURCE_NAMES, userIds);
    }
    if (date != DateOptionValues.LAST_WEEK) {
      searchParams.append(PERIOD, date);
      if (date === DateOptionValues.CUSTOM_RANGE) {
        searchParams.append(
          START_DATE,
          customRange.startDate.format('MM/DD/YYYY'),
        );
        searchParams.append(END_DATE, customRange.endDate.format('MM/DD/YYYY'));
      }
    }
    navigate({ search: `?${searchParams.toString()}` });
  };

  const openError = (error: Error) => {
    api.error({
      message: 'Error',
      description: error.message,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (workflowFiltersError) {
      openError(workflowFiltersError);
      dispatch(getWorkflowFiltersErrorAction());
    }
    if (userFiltersError) {
      openError(userFiltersError);
      dispatch(getUserFiltersErrorAction());
    }
  }, [workflowFiltersError, userFiltersError]);

  useEffect(() => {
    const req = getDashboardStatsRequest(
      selectedOrgInfo,
      selectedWorkflows,
      selectedUsers,
      date,
      customRange,
    );
    updateUrl();
    dispatch(getDashboardStatsAction(req));
  }, [selectedWorkflows, selectedUsers, date, customRange]);

  const getDashboardStatsRequest = (
    selectedOrgInfo: OrgInfo,
    selectedWorkflows: string[],
    selectedUsers: string[],
    date: string,
    customRange: DateRange,
  ) => {
    const filters = [];
    const req: GetDashboardStatsRequest = {};
    req.orgResourceName = selectedOrgInfo.orgResourceName;

    // Handle workflow resource names filter
    if (
      selectedWorkflows[0] !== DEFAULT_WORKFLOW_VALUE.value &&
      selectedWorkflows.length
    ) {
      filters.push(`${WORKFLOW_RESOURCE_NAMES}=${selectedWorkflows.join('-')}`);
    }
    // Handle user filter
    if (selectedUsers[0] !== ALL_USER_VALUE.value && selectedUsers.length) {
      filters.push(`${USER_RESOURCE_NAMES}=${selectedUsers.join('-')}`);
    }
    // Handle time range filter
    const dateRange = getDateRange(date, customRange);
    if (dateRange) {
      filters.push(`${START_DATE}=${dateRange?.startDate}`);
      filters.push(`${END_DATE}=${dateRange?.endDate}`);
    }
    req.filter = filters.length ? filters.join(',') : '';
    return req;
  };

  const resetFilters = () => {
    setSelectedWorkflows([DEFAULT_WORKFLOW_VALUE.value]);
    setSelectedUsers([ALL_USER_VALUE.value]);
    setCustomRange(defaultDateRange);
    setDate(DateOptionValues.LAST_WEEK);
  };

  useEffect(() => {
    if (selectedOrgInfo && isAdminView) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
    if (selectedOrgInfo) {
      dispatch(
        getWorkflowFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
    return () => {
      resetFilters();
    };
  }, [selectedOrgInfo]);

  return (
    <Box display={'flex'} alignItems={'center'} gap={3}>
      {contextHolder}
      <OrbyTypography
        size={'display-sm'}
        weight={'medium'}
        sx={{ marginRight: 'auto' }}
      >
        Dashboard
      </OrbyTypography>
      <WorkflowFilter
        selectedWorkflows={selectedWorkflows}
        setSelectedWorkflows={setSelectedWorkflows}
        width={'210px'}
      />
      {isAdminView && (
        <UserFilter
          selectedUsers={selectedUsers.filter(
            (value) => value !== ALL_USER_VALUE.value,
          )}
          setSelectedUsers={setSelectedUsers}
          user={user!}
          isAdminView={isAdminView}
          allValue={ALL_USER_VALUE}
          userFilterMappedValue={'value'}
          isShowAssignedToMe={false}
          isShowUnassignedOnly={false}
          width={'210px'}
        />
      )}
      <TimeFilter
        value={date}
        setValue={setDate}
        customRange={customRange}
        setCustomRange={setCustomRange}
        width={'210px'}
        menuWidth={'210px'}
      />
    </Box>
  );
};

export default memo(DashboardHeader);
