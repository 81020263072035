import { Box, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import noSuggestion from '../../../../static/icons/no-suggestion.png';

interface ReviewPageErrorViewProps {
  contextHolder: any;
  message: string;
}

const ReviewPageErrorView: FC<ReviewPageErrorViewProps> = ({
  contextHolder,
  message,
}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
    >
      {contextHolder}
      <img src={noSuggestion} alt={'no suggestion'} />
      <Typography marginRight={2}>{message}</Typography>
    </Box>
  );
};

export default memo(ReviewPageErrorView);
