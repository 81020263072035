import * as React from 'react';
import { ControlType } from 'protos/pb/v1alpha1/element';

import { ItemDescription, WidgetContainer } from './Styled';
import { ClickableWidgetInfo } from 'workflow-utils/src/types';
import { ActionObject } from 'workflow-utils/src/types';
import { ClickableTextWithPopover } from './ClickableTextWithPopover';
import ElementsSelector from './ElementsSelector';

export interface ClickableWidgetProps {
  action: ActionObject;
  clickableWidgetInfo: ClickableWidgetInfo;
  isEditable: boolean;
  hasRecording: boolean;
  processId?: string;
  traceIndices?: number[];
}

const ClickableWidget: React.FC<ClickableWidgetProps> = ({
  action,
  clickableWidgetInfo,
  isEditable,
  hasRecording,
  processId,
  traceIndices,
}) => {
  const text = clickableWidgetInfo.clickableText || '';

  const clickableElementPopover = (open: boolean, handleClose: () => void) => (
    <ElementsSelector
      open={open}
      onClose={handleClose}
      actionId={action.id!}
      action={action}
      text={text}
      types={[ControlType.BUTTON]}
      hasRecording={hasRecording}
      processId={processId}
      traceIndices={traceIndices}
    />
  );

  return (
    <WidgetContainer>
      <ItemDescription>{clickableWidgetInfo.prefix}</ItemDescription>
      <ClickableTextWithPopover
        isEditable={isEditable}
        text={text}
        renderPopover={clickableElementPopover}
      />
      <ItemDescription>{clickableWidgetInfo.suffix}</ItemDescription>
    </WidgetContainer>
  );
};

export default ClickableWidget;
