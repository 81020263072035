import React, { memo, useMemo, useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { colorValuesUtil } from '../utils/ColorValuesUtil';
import { addAlpha, getInitials } from '../utils/helpers';
import { ELLIPSIS_STYLE } from '../utils/constants';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  email: string;
  imageUrl?: string;
  fullName?: string;
}

const UserCard: React.FC<Props> = ({ email, imageUrl, fullName }) => {
  const [imgError, setImgError] = useState(false);
  const displayName = fullName?.trim() || email;

  const initials = useMemo(() => getInitials(displayName), [displayName]);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {imgError || !imageUrl ? (
        <Avatar
          alt='user-initials'
          sx={{
            width: 32,
            height: 32,
            fontSize: 20,
            bgcolor: addAlpha(colorValuesUtil.getColor(email), 0.4),
          }}
        >
          <OrbyTypography size='md' weight='medium'>
            {initials}
          </OrbyTypography>
        </Avatar>
      ) : (
        <Avatar
          alt='user-photo'
          sx={{
            width: 32,
            height: 32,
            fontSize: 20,
          }}
          slotProps={{
            img: { referrerPolicy: 'no-referrer' }, // this is added for security purpose
          }}
          src={imageUrl}
          onError={() => setImgError(true)}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '0px' }}>
        <Box title={displayName}>
          <OrbyTypography
            size='sm'
            weight='regular'
            color={OrbyColorPalette['grey-900']}
            sx={{ ...ELLIPSIS_STYLE }}
          >
            {displayName}
          </OrbyTypography>
        </Box>
        {fullName?.trim() && (
          <Box title={email}>
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-500']}
              sx={{ ...ELLIPSIS_STYLE }}
            >
              {email}
            </OrbyTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(UserCard);
