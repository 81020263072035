import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { workflowDetailsSelector } from './app.selectors';
import { WorkflowDetailsState } from '../reducers/workflow_detail.reducer';

export const loadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.loading,
);

export const workflowAllDetailsSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails,
);
