import { Checkbox, SxProps, Theme, Tooltip } from '@mui/material';
import React, { FC, memo } from 'react';
import checkedBox from '../../static/icons/task-box-checked.svg';
import uncheckedBox from '../../static/icons/task-box-unchecked.svg';

interface Props {
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const TaskCheckbox: FC<Props> = ({ checked, onClick, title, disabled, sx }) => {
  return (
    <>
      {title ? (
        <Tooltip
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-arrow': { color: '#000' },
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#000',
                borderRadius: '8px',
              },
            },
          }}
          title={title}
        >
          <Checkbox
            sx={{ ...sx, p: 0 }}
            checked={checked}
            onClick={(e) => {
              if (disabled) {
                e.stopPropagation();
                return;
              }
              onClick?.(e);
            }}
            icon={<img src={uncheckedBox} alt='Unchecked Box' />}
            checkedIcon={<img src={checkedBox} alt='Checked Box' />}
          />
        </Tooltip>
      ) : (
        <Checkbox
          disabled={disabled}
          sx={{ ...sx, p: 0 }}
          checked={checked}
          onClick={onClick}
          icon={<img src={uncheckedBox} alt='Unchecked Box' />}
          checkedIcon={<img src={checkedBox} alt='Checked Box' />}
        />
      )}
    </>
  );
};

export default memo(TaskCheckbox);
