import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { workflowTaskSelector } from './app.selectors';
import { WorkflowTaskState } from '../reducers/workflow_task.reducer';

export const workflowTaskLoadingSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) => workflowTaskState.loading,
);

export const workflowTaskDetailSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) => ({
    data: workflowTaskState.workflowTaskData,
    loading: workflowTaskState.loading,
    error: workflowTaskState.workflowTaskError,
  }),
);

export const workflowByIdSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) => ({
    data: workflowTaskState.workflowData,
    loading: workflowTaskState.loading,
    error: workflowTaskState.workflowError,
  }),
);

export const linkedTasksSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) =>
    workflowTaskState.workflowLinkedTasks,
);

export const listWorkflowTemplatesSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) =>
    workflowTaskState.listWorkflowTemplates,
);

export const listWorkflowTemplateErrorSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) =>
    workflowTaskState.listWorkflowTemplateError,
);

export const getWorkflowTemplateSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) => workflowTaskState.workflowTemplate,
);

export const getWorkflowTemplateErrorSelector = createSelector(
  [workflowTaskSelector],
  (workflowTaskState: WorkflowTaskState) =>
    workflowTaskState.workflowTemplateError,
);
