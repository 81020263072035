import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';

import {
  OrbyColorPalette,
  OrbyTabs,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';

import { isAdmin } from '../../utils/helpers';
import {
  API_EXECUTION_STATUS_FILTER,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  NAME_PREFIX,
  UI_EXECUTION_STATUS_FILTER,
  WORKFLOW_RESOURCE_NAMES,
  FEATURE_FLAGS,
} from '../../utils/constants';
import {
  getApiExecutionStatusesFromParamString,
  getUsersFromParamStringV2,
  getWorkflowIdsFromParamStringV2,
} from '../Utils/taskV2Utils';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';

import ApiAutomationsTab from './tabs/api-automations/ApiAutomationsTab';
import WorkflowFilter from '../../pages/Tasks/component/WorkflowFilter';
import {
  EXECUTION_ALL_STATUS,
  getExecutionStatusDropdown,
  getExecutionStatusFilterMenu,
} from './ExecutionHelpers';
import UiAutomationsTab from './tabs/ui-automations/UiAutomationsTab';
import ExecutionUserFilter from './component/ExecutionUserFilter';
import { DEFAULT_WORKFLOW_VALUE } from '../Tasks/TaskHelpers';
import OrbotWorkflowFilter from './component/OrbotWorkflowFilter';
import { useIsOrgChanged } from '../../hooks/useIsOrgChanged';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';

interface Props {
  sideDrawerCollapse: boolean;
}

const Executions: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const urlSearchParams = new URLSearchParams(location.search);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const isOrgChanged = useIsOrgChanged(selectedOrgInfo?.orgResourceName || '');
  const user = useSelector(loggedInUserSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);

  // rbac enabled
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);
  // user permissions
  const userPermissions = useSelector(userPermissionsSelector);

  const isOrgAdminOrWorkflowAdmin =
    isAdminView || (isRbacEnabled && userPermissions.listWorkflows);

  const [selectedTab, setSelectedTab] = useState(0);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  // USED FOR SEARCHING EXECUTION BY NAME
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(NAME_PREFIX) ?? '',
  );
  // USED FOR FILTERING API EXECUTION BY STATUS
  const [apiExecutionStatusFilters, setApiExecutionStatusFilters] = useState(
    getApiExecutionStatusesFromParamString(
      urlSearchParams.get(API_EXECUTION_STATUS_FILTER) ?? '',
    ),
  );
  // USED FOR FILTERING UI EXECUTION BY STATUS
  const [uiExecutionStatusFilters, setUiExecutionStatusFilters] = useState(
    getApiExecutionStatusesFromParamString(
      urlSearchParams.get(UI_EXECUTION_STATUS_FILTER) ?? '',
    ),
  );
  // WORKFLOW FILTER
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamStringV2(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );
  // EXECUTION FILTER BY USER
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>(
    getUsersFromParamStringV2(urlSearchParams),
  );

  /**
   * HANDLE API EXECUTION STATUS FILTER SELECT
   */
  const handleApiExecutionStatusSelect = (status: string) => {
    if (status === EXECUTION_ALL_STATUS.value) {
      setApiExecutionStatusFilters([]);
    } else {
      /**
       * CHECK IF THE STATUS ALREADY EXISTS IN THE FILTER
       */
      if (apiExecutionStatusFilters.includes(status)) {
        setApiExecutionStatusFilters(
          apiExecutionStatusFilters.filter((s) => s !== status),
        );
      } else {
        setApiExecutionStatusFilters([...apiExecutionStatusFilters, status]);
      }
    }
  };

  /**
   * HANDLE UI EXECUTION STATUS FILTER SELECT
   */
  const handleUiExecutionStatusSelect = (status: string) => {
    if (status === EXECUTION_ALL_STATUS.value) {
      setUiExecutionStatusFilters([]);
    } else {
      /**
       * CHECK IF THE STATUS ALREADY EXISTS IN THE FILTER
       */
      if (uiExecutionStatusFilters.includes(status)) {
        setUiExecutionStatusFilters(
          uiExecutionStatusFilters.filter((s) => s !== status),
        );
      } else {
        setUiExecutionStatusFilters([...uiExecutionStatusFilters, status]);
      }
    }
  };

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  const resetFilters = () => {
    setUiExecutionStatusFilters([]);
    setApiExecutionStatusFilters([]);
    setDisplayNamePrefix('');
    setSelectedWorkflows([DEFAULT_WORKFLOW_VALUE.value]);
    setSelectedUsers([]);
  };

  const tabs = [
    // COMMENTING THIS ONE OUT SINCE WE DON"T PLAN IT IN THE CURRENT RELEASE
    {
      id: 'ui-automations',
      label: 'UI Automations',
      children: (
        <UiAutomationsTab
          displayNamePrefix={displayNamePrefix}
          selectedWorkflows={selectedWorkflows}
          uiExecutionStatusFilters={uiExecutionStatusFilters}
          selectedUsers={selectedUsers}
          isAdminView={isAdminView}
          tableWidth={getTableWidth()}
        />
      ),
    },
    {
      id: 'api-automations',
      label: 'API Automations',
      children: (
        <ApiAutomationsTab
          selectedWorkflows={selectedWorkflows}
          displayNamePrefix={displayNamePrefix}
          apiExecutionStatusFilters={apiExecutionStatusFilters}
          isAdminView={isOrgAdminOrWorkflowAdmin}
          tableWidth={getTableWidth()}
        />
      ),
    },
  ];

  useEffect(() => {
    setSelectedWorkflows([DEFAULT_WORKFLOW_VALUE.value]);
  }, [selectedTab]);

  useEffect(() => {
    if (isOrgChanged.isOrgChanged) {
      resetFilters();
    }
  }, [isOrgChanged]);

  return (
    <>
      <Box paddingX={'48px'} paddingY={'48px'}>
        {/* PAGE HEADER */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box>
            <OrbyTypography size={'display-sm'} weight={'medium'}>
              Executions
            </OrbyTypography>
          </Box>
        </Box>

        {/* EXECUTION FILTER */}
        <Box
          position={'sticky'}
          top={0}
          bgcolor={OrbyColorPalette['white-0']}
          zIndex={1}
          paddingTop={'24px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-start'}
            width={'100%'}
            gap={'8px'}
            marginBottom={'24px'}
          >
            {/* SEARCH EXECUTION BY NAME */}
            <Box>
              <OrbyTextField
                width={'240px'}
                tabIndex={0}
                value={displayNamePrefix}
                name={'search-execution-name'}
                disabled={false}
                fontSize='14px'
                placeholder='Search by execution name'
                startAdornment={
                  <GridSearchIcon
                    sx={{ color: OrbyColorPalette['grey-600'] }}
                    fontSize='medium'
                  />
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDisplayNamePrefix(event.target.value)
                }
              />
            </Box>
            {/* FILTER WORKFLOWS */}
            <Box>
              {selectedTab === 0 ? (
                <OrbotWorkflowFilter
                  selectedWorkflows={selectedWorkflows}
                  setSelectedWorkflows={setSelectedWorkflows}
                />
              ) : (
                <WorkflowFilter
                  selectedWorkflows={selectedWorkflows}
                  setSelectedWorkflows={setSelectedWorkflows}
                />
              )}
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {/* EXECUTION HISTORY TABS */}
            <OrbyTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
            />

            <Box display={'flex'} gap={'12px'}>
              {/* STATUS DROPDOWN */}
              {getExecutionStatusDropdown({
                statusAnchorEl,
                setStatusAnchorEl,
                executionStatusFilters:
                  selectedTab === 1
                    ? apiExecutionStatusFilters
                    : uiExecutionStatusFilters,
              })}

              {selectedTab === 0 && (
                <ExecutionUserFilter
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  user={user!}
                  isAdminView={isAdminView}
                />
              )}
            </Box>
          </Box>
        </Box>

        {/* EXECUTION TAB PANELS */}
        <Box>
          {tabs.map((tab, index) => (
            <div
              key={tab.id}
              role='tabpanel'
              hidden={selectedTab !== index}
              id={`tabpanel-${tab.id}`}
              aria-labelledby={`tab-${tab.id}`}
            >
              {selectedTab === index && (
                <Box sx={{ paddingTop: '12px' }}>{tab.children}</Box>
              )}
            </div>
          ))}
        </Box>
      </Box>

      {/* EXECUTION STATUS MENU */}
      {getExecutionStatusFilterMenu({
        statusAnchorEl,
        setStatusAnchorEl,
        handleExecutionStatusSelect:
          selectedTab === 1
            ? handleApiExecutionStatusSelect
            : handleUiExecutionStatusSelect,
        executionStatusFilters:
          selectedTab === 1
            ? apiExecutionStatusFilters
            : uiExecutionStatusFilters,
        isApiExecution: selectedTab === 1, // (This means its API Execution Page)
      })}
    </>
  );
};

export default React.memo(Executions);
