import { createSelector } from 'reselect';
import { taskV2Selector } from './app.selectors';

export const tasksListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasks,
);

export const tasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksLoading,
);

export const deletingTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deletingTask,
);

export const deletedTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deletedTask,
);

export const tasksFailedToDeleteSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksFailedToDelete,
);

export const tasksRequestedForDeletionSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksRequestedForDeletion,
);

export const tasksDeletedSuccessfullySelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksDeletedSuccessfully,
);

export const tasksLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksLoaded,
);

export const tasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.nextPageToken,
);

export const tasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.totalSize,
);

export const pendingTasksListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasks,
);

export const pendingTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksLoading,
);

export const pendingTasksLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksLoaded,
);

export const pendingTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksNextPageToken,
);

export const pendingTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksTotalSize,
);

export const pendingTasksAdditionalRoundListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRound,
);

export const pendingTasksAdditionalRoundLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundLoading,
);

export const pendingTasksAdditionalRoundLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundLoaded,
);

export const pendingTasksAdditionalRoundNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundNextPageToken,
);

export const pendingTasksAdditionalRoundTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.pendingTasksAdditionalRoundTotalSize,
);

export const taskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.task,
);

export const taskLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskLoading,
);

export const taskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskLoaded,
);

export const taskEntityStatusesSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.entitiesInTask,
);

export const listPendingTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.listPendingTasksError,
);

export const listTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.listTasksError,
);

export const listPendingTasksAdditionalRoundErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.listPendingTasksError,
);

export const deleteTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.deletingTaskError,
);

export const getTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.getTaskError,
);

export const updateTaskErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.updatingTaskError,
);

export const taskCreationErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskCreationError,
);

export const processingTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.processingTask,
);

export const addedTaskSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.addedTask,
);

export const getConfidenceSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.confidence,
);
export const declinedTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTaskLoading,
);
export const listDeclinedTasksSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTasks,
);
export const declinedTaskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTaskLoaded,
);

export const declinedTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedNextPageToken,
);

export const declinedTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTotalSize,
);
export const listDeclinedTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.declinedTasksError,
);

export const systemDeclinedTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTaskLoading,
);
export const listSystemDeclinedTasksSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTasks,
);
export const systemDeclinedTaskLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTaskLoaded,
);

export const systemDeclinedTasksNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedNextPageToken,
);

export const systemDeclinedTasksTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTotalSize,
);
export const listSystemDeclinedTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.systemDeclinedTasksError,
);

export const getUploadedDebugDocument = createSelector(
  [taskV2Selector],
  (taskState) => taskState.debugDocument,
);

export const getStartedReviewTime = createSelector(
  [taskV2Selector],
  (taskState) => taskState.reviewTime,
);

export const taskDeclinedSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskDeclinedSuccess,
);

export const completedTaskSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.completedTaskSuccess,
);

export const taskFormatErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.taskFormatError,
);

export const updateBatchTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.updateBatchTasksLoading,
);

export const updateBatchTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.updateBatchTasksError,
);

export const updateBatchTasksSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.updateBatchTasksSuccess,
);

export const tasksFailedToReassignSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.tasksFailedToReassign,
);

export const debugLayoutSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.enableDebugLayout,
);

export const apiExecutionHistoryListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistory,
);

export const apiExecutionHistoryLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistoryLoading,
);

export const apiExecutionHistoryLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistoryLoaded,
);

export const apiExecutionHistoryTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.apiExecutionHistoryTotalSize,
);

export const listApiExecutionHistoryErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.listApiExecutionHistoryError,
);

export const uiExecutionHistoryListSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistory,
);

export const uiExecutionHistoryLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryLoading,
);

export const uiExecutionHistoryNextPageTokenSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryNextPageToken,
);

export const uiExecutionHistoryLoadedSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryLoaded,
);

export const uiExecutionHistoryTotalSizeSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.uiExecutionHistoryTotalSize,
);

export const listUiExecutionHistoryErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.listUiExecutionHistoryError,
);

export const retryTasksLoadingSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.retryTasksLoading,
);

export const retryTasksErrorSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.retryTasksError,
);

export const retryTasksSuccessSelector = createSelector(
  [taskV2Selector],
  (taskState) => taskState.retryTasksSuccess,
);
