import { Reorder } from 'framer-motion';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderOfTableRowsAction } from '../../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../../redux/reducers/review_task.reducer';
import { rowOrderInfoForSelectedParentEntitySelector } from '../../../../../../../../redux/selectors/review_task.selectors';
import { VirtualItem } from '@tanstack/react-virtual';
import ReorderTableRow from './components/ReorderTableRow';

export interface TableBodyProps {
  getChildEntitiesFromParentId: (id: string) => EntityInfo[];
  setDraggingElemId: (id: string) => void;
  handleEntityRowClick: (
    e: React.MouseEvent | React.KeyboardEvent,
    cell: EntityInfo,
  ) => void;
  openTextPopover: (cell: EntityInfo) => void;
  handleTableCellKeyDown: (e: React.KeyboardEvent, cell: EntityInfo) => void;
  setClipboardEntity: (cell: EntityInfo) => void;
  handlePaste: (cell: EntityInfo) => void;
  clipboardEntity: EntityInfo | undefined;
  getEntitiesWithSameType: (type: string) => EntityInfo[];
  showNormalizedValues: boolean;
  columnTypeToResize: string;
  showResizeLine: (type: string) => void;
  hideResizeLine: () => void;
  startResizingColumn: (e: React.MouseEvent, type: string) => void;
  virtualItems: VirtualItem[];
}

const TableBody: FC<TableBodyProps> = (props) => {
  const dispatch = useDispatch();
  const rowOrderInfoForTableEntities = useSelector(
    rowOrderInfoForSelectedParentEntitySelector,
  );

  const handleReorder = useCallback(
    (ids: string[]) => {
      // Handle reordering of table rows, accounting for virtualization

      // Due to virtualization, we only receive IDs for visible rows.
      // We need to reconstruct the full order by combining:
      // 1. Hidden rows above the visible area
      // 2. Reordered visible rows
      // 3. Hidden rows below the visible area

      // Get IDs of hidden rows above the visible area
      // Note: If the user is at the top of the list, this will be an empty array
      const startIds = rowOrderInfoForTableEntities.slice(
        0,
        props.virtualItems[0]?.index,
      );

      // Get IDs of hidden rows below the visible area
      // Note: If the user is at the bottom of the list, this will be an empty array
      const endIds = rowOrderInfoForTableEntities.slice(
        props.virtualItems[props.virtualItems.length - 1]?.index + 1,
      );

      // Dispatch action to update the order, combining all parts
      // The spread operator (...) ensures that even if startIds or endIds
      // are empty arrays, they won't affect the final order
      dispatch(updateOrderOfTableRowsAction([...startIds, ...ids, ...endIds]));
    },
    [props.virtualItems, rowOrderInfoForTableEntities],
  );

  return (
    <Reorder.Group
      as='tbody'
      axis='y'
      values={rowOrderInfoForTableEntities}
      onReorder={handleReorder}
    >
      {/* Spacer row to fill the gap above virtualized rows */}
      {/* This creates an empty space for non-visible rows at the top of the table */}
      <tr
        style={{
          height:
            props.virtualItems[0]?.index === 0
              ? 0 // No spacer needed if we're at the top of the list
              : `${props.virtualItems[0]?.index * (props.showNormalizedValues ? 75 : 30)}px`,
          // Height calculation:
          // - Number of hidden rows (virtualItems[0].index) multiplied by
          // - Row height (75px for normalized values, 30px otherwise)
        }}
      />
      {/* Render virtualized rows */}
      {props.virtualItems?.map((vid) => {
        const id = rowOrderInfoForTableEntities[vid.index];
        return (
          <ReorderTableRow id={id} {...props} key={id} rowIndex={vid.index} />
        );
      })}
      {/* Spacer row to fill the gap below virtualized rows */}
      {/* This creates an empty space for non-visible rows at the bottom of the table */}
      {/* It maintains the illusion of a complete dataset and ensures correct scrollbar behavior */}
      <tr
        style={{
          height: `${(rowOrderInfoForTableEntities.length - props.virtualItems[props.virtualItems.length - 1]?.index - 1) * (props.showNormalizedValues ? 75 : 30)}px`,
          // Height calculation:
          // - Number of hidden rows below (total rows minus last visible row index minus 1)
          // - Multiplied by row height (75px for normalized values, 30px otherwise)
        }}
      />
    </Reorder.Group>
  );
};

export default memo(TableBody);
