import React, { useMemo, useRef } from 'react';
import CustomTypography from '../../components/CustomTypography';
import CustomModal from '../../components/CustomModal';
import ItemDeletionForm, {
  FormikFormRef,
} from './ItemDeletionForm/ItemDeletionForm';
import { Box } from '@mui/material';
import { WorkflowExecution } from 'protos/pb/v1alpha2/workflow_executions_service';

interface ExecutionDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (
    deletionReason: string,
    selectedExecutions: WorkflowExecution[],
  ) => void;
  selectedExecutions: WorkflowExecution[];
}

const ExecutionDeletionModal: React.FC<ExecutionDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
  selectedExecutions,
}) => {
  const formRef = useRef<FormikFormRef>(null);
  const isMultiple = useMemo(
    () => selectedExecutions.length > 1,
    [selectedExecutions],
  );
  const getModalText = () => {
    // https://github.com/orby-ai-engineering/orby-web-app/pull/1017#discussion_r1593124093
    if (isMultiple) {
      return `Are you sure you want to delete these ${selectedExecutions.length} executions`;
    }
    return 'Are you sure you want to delete this execution';
  };
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => formRef.current?.submitForm()}
      heading={isMultiple ? 'Delete Items?' : 'Delete Item?'}
      content={
        <>
          <Box sx={{ maxWidth: '433px' }}>
            <CustomTypography lineHeight='18px'>
              {getModalText()}
            </CustomTypography>
            <CustomTypography sx={{ mt: '8px', mb: '24px', fontWeight: 600 }}>
              {`You can restore deleted ${
                isMultiple ? 'executions' : 'execution'
              } within 30 days post deletion.`}
            </CustomTypography>
          </Box>
          <ItemDeletionForm
            ref={formRef}
            onSubmit={(reason) => onSubmit(reason, selectedExecutions)}
          />
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(ExecutionDeletionModal);
