import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC, memo } from 'react';
import * as Yup from 'yup';
import './DeclineModal.css';
import CustomFilterSelect from '../../../../components/generic/CustomFilterSelect';
import CustomTypography, {
  TypographyType,
} from '../../../../components/CustomTypography';
import CustomTextField from '../../../../components/CustomTextField';
import { UserDeclinedTaskReasonTYPE } from 'protos/pb/v1alpha2/tasks_service';
import CustomModal from '../../../../components/CustomModal';

interface DeclineAllModalProps {
  open: boolean;
  description: string;
  label: string;
  setOpen: (x: boolean) => void;
  onSubmit: (rejectionReason: { type: string; description: string }) => void;
}

const DeclineAllModal: FC<DeclineAllModalProps> = ({
  open,
  setOpen,
  onSubmit,
  description,
  label,
}) => {
  const minCount = 100;

  const formik = useFormik({
    initialValues: {
      type: '',
      description: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Please select an option'),
      description: Yup.string()
        .required('Description is required')
        .max(100, 'Description cannot be more than 100 characters'),
    }),
    onSubmit: (values) => {
      onSubmit(values);
      setOpen(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <CustomModal
      containerSx={{
        width: '600px',
        zIndex: 11111,
        px: '40px !important',
      }}
      titleSx={{ fontSize: '20px' }}
      headerSx={{ p: '40px', pb: '0px' }}
      open={open}
      closable={false}
      content={
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <CustomTypography
              sx={{
                marginBottom: '28px',
                marginTop: '24px',
                fontSize: '14px',
              }}
              typographyType={TypographyType.MediumPara}
            >
              {description}
            </CustomTypography>
            <CustomFilterSelect
              name='type'
              width={'100%'}
              color={'#667085'}
              padding={'10px'}
              label='Problem type'
              height={'47px'}
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
              placeholder='Select a problem type'
              filters={[
                {
                  value: UserDeclinedTaskReasonTYPE.WRONG_DOCUMENT,
                  label: 'Wrong document',
                },
                {
                  value: UserDeclinedTaskReasonTYPE.CANNOT_EDIT_CONTENT,
                  label: 'Cannot edit content',
                },
                {
                  value: UserDeclinedTaskReasonTYPE.OTHER,
                  label: 'Other, specify below',
                },
              ]}
              value={UserDeclinedTaskReasonTYPE.WRONG_DOCUMENT}
              extraProps={getFieldProps('type')}
            />
            <Box paddingTop={'48px'}>
              <CustomTextField
                label='Description'
                placeholder='Did you have any issues? Please describe the problem you have encountered and share a little more about why to decline'
                fontSize='14px'
                multiline={true}
                minCount={minCount}
                extraProps={getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                value={''}
                name={'description'}
                size={'full'}
                labelStyle={{
                  color: '#344054',
                  fontWeight: 500,
                  fontSize: '14px',
                  fontFamily: 'unset',
                }}
              />
            </Box>
          </Form>
        </FormikProvider>
      }
      heading={label}
      handleClose={() => {
        setOpen(false);
        resetForm();
      }}
      primaryLabel={label}
      secondaryLabel={'Cancel'}
      onPrimaryClick={handleSubmit}
    />
  );
};

export default memo(DeclineAllModal);
