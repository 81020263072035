import { Box, CircularProgress } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import React from 'react';

const FullPageLoader: React.FC = () => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyItems={'center'}
      height={'100vh'}
      alignItems={'center'}
    >
      <CircularProgress
        sx={{
          margin: '0 auto',
          color: OrbyColorPalette['blue-700'],
        }}
      />
    </Box>
  );
};

export default FullPageLoader;
