import { Box } from '@mui/material';
import React, { FC, memo, useEffect, useRef } from 'react';
import { FLOATING_MODAL_WIDTH } from '../../../../../utils/constants';
import ReviewPageFloatingModal from '../../ReviewPageFloatingModal/ReviewPageFloatingModal';
import { makeDraggableCard } from '../../../../../utils/BoundingBoxUtils';
import { useSelector } from 'react-redux';
import {
  entitySidePanelCollapsedSelector,
  selectedEntityInfoSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import { usePreventOverflow } from '../../../../../hooks/usePreventOverflow';

interface Props {
  scale: number;
  left: string;
  pdfRef: React.RefObject<HTMLElement>;
  availableWidth: number;
}

const ReviewPageFloatingModalWrapper: FC<Props> = ({
  scale,
  left,
  pdfRef,
  availableWidth,
}) => {
  const floatingModalRef = useRef<HTMLElement>(null);
  const selectedEntity = useSelector(selectedEntityInfoSelector);
  const isSidePanelCollapsed = useSelector(entitySidePanelCollapsedSelector);

  // FUNCTION TO GET THE MAXIMUM AMOUNT OF LEFT POSITION THAT CAN BE ASSIGNED
  // WITHOUT LETTING THE COMPONENT GO OUT OF THE SCREEN
  const getMaxLeftPosition = (): number => {
    if (pdfRef.current) {
      const width = pdfRef.current.clientWidth;
      return width - FLOATING_MODAL_WIDTH;
    } else {
      return 0;
    }
  };

  // FUNCTION TO GET THE MAXIMUM AMOUNT OF BOTTOM POSITION THAT CAN BE ASSIGNED
  // WITHOUT LETTING THE COMPONENT GO OUT OF THE SCREEN
  const getMaxBottomPosition = (): number => {
    if (pdfRef.current) {
      const height = pdfRef.current.clientHeight;
      return height;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    // Function to update the modal position based on the available space and PDF container
    function updateModalPosition() {
      if (floatingModalRef.current && pdfRef.current) {
        const modalWidth = floatingModalRef.current.clientWidth;
        const pdfRight = pdfRef.current.getBoundingClientRect().right;
        const modalRight =
          floatingModalRef.current.getBoundingClientRect().right;
        // If the available width is less than the modal width,
        // adjust the modal width and align it to the left
        if (availableWidth < modalWidth) {
          floatingModalRef.current.style.width = availableWidth + 'px';
          floatingModalRef.current.style.left = '0px';
        } else if (modalRight > pdfRight) {
          // If the modal overflows beyond the PDF container, adjust its position to stay within bounds
          const modalPreviousLeft = parseInt(
            getComputedStyle(floatingModalRef.current).left,
            10,
          );
          floatingModalRef.current.style.left =
            modalPreviousLeft - Math.abs(pdfRight - modalRight) + 'px';
        }
      }
    }
    // the reason we have added a delay is because due to side nav hiding and showing animation
    // it takes dom some time to reflect those changes correctly
    const cancel = setTimeout(() => {
      updateModalPosition();
    }, 300);

    return () => {
      clearTimeout(cancel);
    };
  }, [availableWidth, isSidePanelCollapsed]);

  useEffect(() => {
    if (selectedEntity && floatingModalRef.current) {
      const draggableElem = document.getElementById('floating-modal-header');
      makeDraggableCard(
        floatingModalRef.current,
        getMaxLeftPosition,
        getMaxBottomPosition,
        draggableElem,
      );
    }
  }, [selectedEntity, floatingModalRef.current]);

  usePreventOverflow(floatingModalRef, pdfRef, [
    selectedEntity,
    floatingModalRef.current,
  ]);

  return (
    <Box id={'floating-modal'}>
      {selectedEntity && !selectedEntity.isExtra && (
        <Box
          ref={floatingModalRef}
          width={`${FLOATING_MODAL_WIDTH}px`}
          left={left}
          // KEEPING IT 30% FROM TOP OF THE SCREEN AS A DEFAULT VALUE.
          // USER CAN DRAG THE MODAL ANYWHERE FROM THIS POSITION
          top={'30%'}
          position={'absolute'}
          zIndex={100}
        >
          <ReviewPageFloatingModal
            scale={scale}
            maxHeight={pdfRef?.current?.clientHeight} // this will be the modal's max height
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(ReviewPageFloatingModalWrapper);
