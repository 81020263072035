import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import CustomDateRangePicker from './CustomDateRangePicker';
import React, { FC, memo, useMemo, useState } from 'react';
import {
  dateOptions,
  DateOptionValues,
  DateRange,
  ELLIPSIS_STYLE,
} from '../../utils/constants';
import { getDateRangeForDisplay } from '../../utils/helpers';
import { Box } from '@mui/material';

interface TimeFilterProps {
  value: string;
  setValue: (x: string) => void;
  width?: string;
  menuWidth?: string;
  customRange: DateRange;
  setCustomRange: React.Dispatch<React.SetStateAction<DateRange>>;
  isShowAllTime?: boolean;
}

const TimeFilter: FC<TimeFilterProps> = ({
  value,
  setValue,
  width = '160px',
  menuWidth = '320px',
  customRange,
  setCustomRange,
  isShowAllTime = false,
}) => {
  const [showRangePicker, setShowRangePicker] = useState(false);

  const handleRangeChange = (range: DateRange) => {
    setCustomRange(range);
    setValue(DateOptionValues.CUSTOM_RANGE);
    setShowRangePicker(false);
  };

  const ALL_TIME = {
    value: 'all-time',
    label: 'All Time',
  };

  const dateFilterLabel = useMemo(() => {
    const dateOption = dateOptions.find((o) => o.value === value);
    const isShowRange =
      dateOption?.value === DateOptionValues.CUSTOM_RANGE && !showRangePicker;
    const startDate = customRange.startDate.toString();
    const endDate = customRange.endDate.toString();
    return isShowRange
      ? getDateRangeForDisplay(startDate, endDate)
      : dateOption?.label || '';
  }, [value, customRange]);

  return (
    <Box position={'relative'}>
      <OrbySelect
        renderValue={(selected) => (
          <OrbyTypography
            size='sm'
            color={OrbyColorPalette['grey-700']}
            weight={'medium'}
            sx={{
              ...ELLIPSIS_STYLE,
            }}
          >
            {selected ? dateFilterLabel : ALL_TIME.label}
          </OrbyTypography>
        )}
        width={width}
        onChange={(e: any) => {
          if (e.target.value !== DateOptionValues.CUSTOM_RANGE) {
            if (e.target.value !== ALL_TIME.value) {
              setValue(e.target.value);
            } else {
              setValue('');
            }
          }
        }}
        value={value}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isShowAllTime && (
          <OrbyMenuItem
            width={menuWidth}
            key={ALL_TIME.value}
            title={ALL_TIME.label}
            value={ALL_TIME.value}
            isSelected={!value}
            onClick={() => setValue(ALL_TIME.value)}
          />
        )}
        {dateOptions.map((option) => (
          <OrbyMenuItem
            width={menuWidth}
            key={option.value}
            title={option.label}
            value={option.value}
            isSelected={value === option.value}
            onClick={() =>
              setShowRangePicker(
                option.value === DateOptionValues.CUSTOM_RANGE ? true : false,
              )
            }
          />
        ))}
      </OrbySelect>

      {showRangePicker && (
        <CustomDateRangePicker
          customRange={customRange}
          onClose={() => setShowRangePicker(false)}
          onRangeChange={handleRangeChange}
        />
      )}
    </Box>
  );
};

export default memo(TimeFilter);
