import React from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as ConnectorIcon } from '../../../../static/icons/connector.svg';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import UserCard from '../../../../components/UserCard';
import { formatDateTime, getDateRange } from '../../../../utils/helpers';
import { NavigateFunction } from 'react-router-dom';
import {
  ListConnectorRequest,
  ListConnectorRequestFilter,
} from 'protos/pb/v1alpha2/connector_service';
import { Connector } from 'protos/pb/v1alpha2/connector';
import {
  DISPLAY_NAME_PREFIX,
  DateOptionValues,
  DateRange,
  END_DATE,
  PERIOD,
  SELECTED_TAB,
  START_DATE,
  USERNAMES,
  WORKFLOW_RESOURCE_NAMES,
} from '../../../../utils/constants';
import { getWorkflowIdsJoinedByDashV2 } from '../../../Utils/taskV2Utils';

export const buildRequest = ({
  navigate,
  displayNamePrefix,
  orgResourceName,
  selectedTime,
  customRange,
  selectedWorkflows,
  selectedUsers,
}: {
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  selectedTime: string;
  customRange: DateRange;
  selectedWorkflows: Array<string>;
  selectedUsers: Array<string>;
}) => {
  const req: ListConnectorRequest = ListConnectorRequest.create({
    listFilter: getFilters(
      navigate,
      displayNamePrefix,
      selectedTime,
      customRange,
      selectedWorkflows,
      selectedUsers,
    ),
    orgResourceName: orgResourceName,
  });
  return req;
};

export const getFilters = (
  navigate: NavigateFunction,
  displayNamePrefix: string,
  selectedTime: string,
  customRange: DateRange,
  selectedWorkflows: Array<string>,
  selectedUsers: Array<string>,
) => {
  const filter: ListConnectorRequestFilter = {};
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // SEARCH FILTER
  if (displayNamePrefix) {
    filter.namePrefix = displayNamePrefix;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  // TIME FILTER
  if (selectedTime) {
    const dateRange = getDateRange(selectedTime, customRange);
    if (dateRange) {
      filter.createTimeLt = dateRange.endDate;
      filter.createTimeGt = dateRange.startDate;
    }
    searchParams.append(PERIOD, selectedTime);

    if (selectedTime === DateOptionValues.CUSTOM_RANGE) {
      searchParams.append(
        START_DATE,
        customRange.startDate.format('MM/DD/YYYY'),
      );
      searchParams.append(END_DATE, customRange.endDate.format('MM/DD/YYYY'));
    }
  }

  // WORKFLOW FILTER
  if (
    selectedWorkflows.length &&
    !selectedWorkflows.includes('all-workflows')
  ) {
    filter.workflowResourceNames = selectedWorkflows;
    searchParams.append(
      WORKFLOW_RESOURCE_NAMES,
      getWorkflowIdsJoinedByDashV2(selectedWorkflows) || '',
    );
  }

  // USERS FILTER
  if (selectedUsers.length) {
    filter.creatorUsername = selectedUsers;
    searchParams.append(
      USERNAMES,
      getWorkflowIdsJoinedByDashV2(selectedUsers) || '',
    );
  }

  // GET THE TAB
  const selectedTab = existingParams.get(SELECTED_TAB) ?? 0;
  navigate({
    search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
  });
  return filter;
};

export const getConnectorNameCell = (
  connector: Connector,
  displayNamePrefix: string,
): JSX.Element => {
  const name = connector.displayName || '';
  const startIndex = name
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={name}
      key={name}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {name.substring(startIndex, endIndex)}
          </span>
          {name.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getConnectorAppsCell = (): JSX.Element => {
  return (
    <Box display={'flex'} flexWrap='wrap'>
      <ConnectorIcon />
    </Box>
  );
};

export const getConnectorSourceWorkflowCell = (
  connector: Connector,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={connector.sourceWorkflow?.workflowName}
      key={connector.sourceWorkflow?.workflowName}
      label={connector.sourceWorkflow?.workflowName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getConnectorDestinationWorkflowCell = (
  connector: Connector,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={connector.destinationWorkflow?.workflowName}
      key={connector.destinationWorkflow?.workflowName}
      label={connector.destinationWorkflow?.workflowName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getConnectorCreatorCell = (connector: Connector): JSX.Element => {
  return (
    <UserCard
      email={connector?.creatorEmail ?? ''}
      fullName={connector.creator?.fullName}
      imageUrl={connector.creator?.imageUrl}
    />
  );
};

export const getConnectorTimeCell = (connector: Connector): JSX.Element => {
  if (connector.lastModifiedTime || connector.createTime) {
    const { date, time } = formatDateTime(
      connector.lastModifiedTime ?? connector.createTime,
    );
    return (
      <Box key={connector.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={connector.name}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getConnectorActionMenuCell = (
  connector: Connector,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    connector: Connector,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={connector.name}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, connector);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const CONNECTOR_ACTION_MENU_ITEM = {
  DELETE: 'Delete',
  EDIT: 'Edit',
};

export const getConnectorActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* EDIT */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, CONNECTOR_ACTION_MENU_ITEM.EDIT);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Edit
        </OrbyTypography>
      </MenuItem>
      {/* DELETE */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, CONNECTOR_ACTION_MENU_ITEM.DELETE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Delete
        </OrbyTypography>
      </MenuItem>
    </Menu>
  );
};

/**
 * Handle Connector Page Change
 */
export const handleConnectorPageChange = ({
  connectors,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listConnectors,
}: {
  connectors: Connector[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listConnectors: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && connectors.length <= pageNumber * rowsPerPage) {
    listConnectors(pageNumber + 1, rowsPerPage, false);
  }
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleConnectorsRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listConnectors,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listConnectors: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(0);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listConnectors(1, rowsNumber, true);
};

export const getConnectorNameColumnWidth = (tableWidth: number) => {
  // Table Width - Apps Cell Width - Creator Cell Width - Time Cell Width - Action Cell Width
  return (tableWidth - 74 - 217 - getConnectorTimeWidth(tableWidth) - 69) / 3;
};

export const getConnectorTimeWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};
