import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  styled,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAlpha,
  formatDate,
  groupTasksByError,
  handleSelectAllClick,
  handleTaskCheckboxClick,
  isAdmin,
  isSelectAllChecked,
  isTaskSelected,
} from '../../utils/helpers';
import {
  clearDeleteTaskAction,
  clearRetryTaskAction,
  listDeclinedTasksAction,
  listDeclinedTasksErrorAction,
  listSystemDeclinedTasksAction,
  listSystemDeclinedTasksErrorAction,
  setTaskDeclinedSuccessAction,
  updateTaskErrorAction,
} from '../../redux/actions/taskV2.action';
import {
  declinedTasksLoadingSelector,
  declinedTasksTotalSizeSelector,
  deleteTaskErrorSelector,
  deletingTaskSelector,
  listDeclinedTasksErrorSelector,
  listDeclinedTasksSelector,
  listSystemDeclinedTasksErrorSelector,
  listSystemDeclinedTasksSelector,
  systemDeclinedTasksLoadingSelector,
  systemDeclinedTasksTotalSizeSelector,
  taskDeclinedSuccessSelector,
  updateTaskErrorSelector,
  tasksFailedToDeleteSelector,
  tasksDeletedSuccessfullySelector,
  retryTasksLoadingSelector,
  retryTasksErrorSelector,
  retryTasksSuccessSelector,
} from '../../redux/selectors/taskV2.selectors';
import {
  ListTasksRequest,
  Task,
  UserDeclinedTaskReason,
  UserDeclinedTaskReasonTYPE,
} from 'protos/pb/v1alpha2/tasks_service';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import CustomPagination from '../../components/Pagination/CustomPagination';
import { notification } from 'antd';
import { setWorkflowColorAction } from '../../redux/actions/workflow.action';
import { workflowColorsSelector } from '../../redux/selectors/workflow.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import './DeclinedTask.css';
import {
  ActionType,
  DeclinedTaskTabs,
  DISPLAY_NAME_PREFIX,
  ELLIPSIS_STYLE,
  TASK_DECLINED_REASON,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearReviewState } from '../../redux/actions/review_task.action';
import WorkflowFilterSelect from '../../pages/PendingTasks/WorkflowFilterSelect';
import {
  composeTaskFiltersAndUpdateNetworkURL,
  getWorkflowIdsFromParamString,
} from '../Utils/taskV2Utils';
import CustomTableCell from '../../components/CustomTableCell';
import SearchTaskField from '../../pages/PendingTasks/SearchTaskField';
import CustomTableLabel from '../../components/CustomTableLabel';
import { ReactComponent as DeleteIcon } from '../../static/icons/deleteIcon.svg';
import TaskDeletionModal from '../PendingTasks/TaskDeletionModal';
import '../../styles/table.css';
import TaskCheckbox from '../PendingTasks/TaskCheckbox';
import TaskHeader from '../PendingTasks/TaskHeader';
import { handleDeleteTask } from '../Tasks/TaskHelpers';
import { toastService } from '../../services/ToastService';
import { getExecutionActionGroupErrorContent } from '../Tasks/tabs/TaskTableBodyHelpers';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
  },
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));
const tabStyles = {
  width: '127px',
  minHeight: '41px',
  padding: 0,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#074BC0',
};

const DeclinedTasks: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loading: boolean = useSelector(declinedTasksLoadingSelector);
  const systemDeclinedLoading: boolean = useSelector(
    systemDeclinedTasksLoadingSelector,
  );
  const tasks = useSelector(listDeclinedTasksSelector);
  const systemDeclinedTasks = useSelector(listSystemDeclinedTasksSelector);
  const totalSize = useSelector(declinedTasksTotalSizeSelector) ?? 0;
  const systemDeclinedTotalSize =
    useSelector(systemDeclinedTasksTotalSizeSelector) ?? 0;
  const listDeclinedTasksError = useSelector(listDeclinedTasksErrorSelector);
  const listSystemDeclinedTasksError = useSelector(
    listSystemDeclinedTasksErrorSelector,
  );
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const dispatch = useDispatch();
  const [userPage, setUserPage] = useState(0);
  const [userPageSize, setUserPageSize] = useState(10);
  const [systemPage, setSystemPage] = useState(0);
  const [systemPageSize, setSystemPageSize] = useState(10);
  const [api, contextHolder] = notification.useNotification();

  const workflowColors = useSelector(workflowColorsSelector);
  const user = useSelector(loggedInUserSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const [deleteType, setDeleteType] = useState<ActionType | null>(null);
  const [tab, setTab] = useState<DeclinedTaskTabs>(
    location.state?.tab ?? DeclinedTaskTabs.USER_DECLINED,
  );
  const isUserDeclined = tab === DeclinedTaskTabs.USER_DECLINED;
  const page = isUserDeclined ? userPage : systemPage;
  const pageSize = isUserDeclined ? userPageSize : systemPageSize;
  const setPage = isUserDeclined ? setUserPage : setSystemPage;
  const setPageSize = isUserDeclined ? setUserPageSize : setSystemPageSize;
  const pageTasks = isUserDeclined ? tasks : systemDeclinedTasks;
  const pagedTasks = pageTasks.slice(page * pageSize, (page + 1) * pageSize);
  const updateTaskError = useSelector(updateTaskErrorSelector);
  const taskDeclinedSuccess = useSelector(taskDeclinedSuccessSelector);
  const urlSearchParams = new URLSearchParams(location.search);
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamString(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]); // for batch delete
  const [selectedTask, setSelectedTask] = useState<Task | null>(null); // for single delete
  const deletingTask = useSelector(deletingTaskSelector);
  const deleteTaskError = useSelector(deleteTaskErrorSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );
  const retryLoading = useSelector(retryTasksLoadingSelector);
  const retryTasksError = useSelector(retryTasksErrorSelector);
  const retryTasksSuccessfully = useSelector(retryTasksSuccessSelector);

  const openError = (error: Error) => {
    api.error({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
    });
  };

  const openMessage = () => {
    api.success({
      message: 'Success',
      description: 'Task is declined successfully.',
      placement: 'topRight',
    });
  };

  const openSuccess = (message: string, autoClose = true) => {
    api.success({
      message: 'Success',
      description: message,
      placement: 'topRight',
      duration: autoClose ? 4.5 : null,
    });
  };

  const refreshPage = () => {
    // set page number to zero and refresh the whole list
    setPage(0);
    setSelectedTasks([]);
    if (isUserDeclined) {
      listDeclinedTasks(1, pageSize, true);
    } else {
      listSystemDeclinedTasks(1, pageSize, true);
    }
  };

  useEffect(() => {
    if (retryTasksSuccessfully) {
      toastService.showSuccess(
        'The tasks will be re-executed, and you will be notified by email upon successful execution.',
        {
          position: 'top-right',
        },
      );
    }
    dispatch(clearRetryTaskAction());
    refreshPage();
  }, [retryTasksSuccessfully]);

  useEffect(() => {
    if (typeof retryTasksError === 'string') {
      toastService.showError(retryTasksError, {
        position: 'top-right',
      });
    } else if (retryTasksError && retryTasksError.length > 0) {
      toastService.showError(
        getExecutionActionGroupErrorContent(groupTasksByError(retryTasksError)),
        {
          position: 'top-right',
        },
      );
    }
    dispatch(clearRetryTaskAction());
    refreshPage();
  }, [retryTasksError]);

  useEffect(() => {
    if (listDeclinedTasksError) {
      openError(listDeclinedTasksError);
      dispatch(listDeclinedTasksErrorAction(undefined));
    }
  }, [listDeclinedTasksError]);

  useEffect(() => {
    if (listSystemDeclinedTasksError) {
      openError(listSystemDeclinedTasksError);
      dispatch(listSystemDeclinedTasksErrorAction(undefined));
    }
  }, [listSystemDeclinedTasksError]);

  const listDeclinedTasks = (
    pageNumber: number,
    rowsPerPage: number,
    refresh: boolean,
  ) => {
    const req = buildRequest(true);
    req.pageNumber = pageNumber;
    req.pageSize = rowsPerPage;
    dispatch(listDeclinedTasksAction(req, refresh));
  };

  const listSystemDeclinedTasks = (
    pageNumber: number,
    rowsPerPage: number,
    refresh: boolean,
  ) => {
    const req = buildRequest();
    req.pageNumber = pageNumber;
    req.pageSize = rowsPerPage;
    dispatch(listSystemDeclinedTasksAction(req, refresh));
  };

  useEffect(() => {
    setUserPage(0);
    setSystemPage(0);
    listDeclinedTasks(1, userPage, true);
    listSystemDeclinedTasks(1, systemPage, true);
    setSelectedTasks([]); // empty the selection upon filter change
  }, [selectedOrgInfo, selectedWorkflows, displayNamePrefix]);

  const buildRequest = (isUserDeclined = false) => {
    const req: ListTasksRequest = {};
    req.filter = composeTaskFiltersAndUpdateNetworkURL(
      isUserDeclined
        ? 'status=REJECTED_INCORRECT-REJECTED_ALREADY_COMPLETED'
        : 'status=OBSOLETE',
      user?.email as string,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
    );
    req.parent = selectedOrgInfo?.orgResourceName;
    return req;
  };

  const openGroupError = (
    groupedErrorTasks: {
      errorMsg: string;
      taskNames: string[];
    }[],
    isDelete = false,
  ) => {
    api.error({
      message: 'Error',
      description: (
        <div>
          {groupedErrorTasks.map((e, index) => (
            <div key={index}>
              <p>
                {`${e.taskNames.length} ${
                  e.taskNames.length > 1 ? 'Tasks were' : 'Task was'
                } not ${isDelete ? 'deleted' : 'retried'} due to: `}
                <p style={{ fontWeight: 500, display: 'inline' }}>
                  {e.errorMsg}
                  {isDelete ? ':' : ''}
                </p>{' '}
              </p>
              {isDelete && (
                <ul>
                  {e.taskNames.map((n) => (
                    <li key={n}>{n}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      ),
      placement: 'topRight',
      duration: null,
    });
  };

  useEffect(() => {
    if (updateTaskError) {
      setTab(DeclinedTaskTabs.SYSTEM_DECLINED);
      openError(updateTaskError);
      dispatch(updateTaskErrorAction(undefined));
    }
  }, [updateTaskError]);

  useEffect(() => {
    if (taskDeclinedSuccess) {
      setTab(DeclinedTaskTabs.USER_DECLINED);
      openMessage();
      dispatch(setTaskDeclinedSuccessAction(undefined));
    }
  }, [taskDeclinedSuccess]);

  useEffect(() => {
    if (pageTasks) {
      const workflowNames = pageTasks.map(
        (t) => t.workflowDisplayName || t.organizationResourceName,
      );
      dispatch(setWorkflowColorAction(workflowNames as string[]));
    }
  }, [pageTasks]);

  useEffect(() => {
    return () => setDisplayNamePrefix('');
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (deleteTaskError) {
      openError(deleteTaskError);
      dispatch(clearDeleteTaskAction());
    }
  }, [deleteTaskError]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      openGroupError(groupTasksByError(tasksFailedToDelete), true);
      dispatch(clearDeleteTaskAction());
    }
  }, [tasksFailedToDelete]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      openSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
      );
      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksDeletedSuccessfully]);

  const getDeclineReason = (reason: UserDeclinedTaskReason) => {
    if (reason)
      return `${
        UserDeclinedTaskReasonTYPE[reason.type as UserDeclinedTaskReasonTYPE]
      } : ${reason.description}`;
    return 'Unspecified';
  };

  const getLastReviewUser = (task: Task) => {
    if (task.reviews && task.reviews.length > 0) {
      return task.reviews[task.reviews.length - 1].user;
    }
    return '';
  };

  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    handleDeleteTask(deleteReason, itemsToDelete, dispatch);
    setSelectedTasks([]);
    handleCloseDeletionModal();
  };

  const handleCloseDeletionModal = () => {
    setDeleteType(null);
    setSelectedTask(null);
  };

  const renderDeleteIcon = (t: Task) => {
    return (
      <IconButton
        sx={{ ml: '20px' }}
        aria-label='Delete Task'
        onClick={(e) => {
          e.stopPropagation();
          setSelectedTask(t);
          setDeleteType(ActionType.SINGLE);
        }}
      >
        <DeleteIcon color='#6B6B6B' />
      </IconButton>
    );
  };

  return (
    <Box paddingX={'60px'} paddingY={'60px'}>
      {contextHolder}
      <WorkflowFilterSelect
        selectedWorkflows={selectedWorkflows}
        setSelectedWorkflows={setSelectedWorkflows}
      />
      <SearchTaskField
        sx={{ '& .MuiOutlinedInput-input': { paddingY: '10.5px' } }}
        displayNamePrefix={displayNamePrefix}
        setDisplayNamePrefix={setDisplayNamePrefix}
      />
      <TaskHeader
        pageTitle={'Declined Tasks'}
        selectedItems={selectedTasks}
        onCancel={() => setSelectedTasks([])}
        secondaryLabel={'Delete'}
        onSecondaryClick={() => setDeleteType(ActionType.BATCH)}
        primaryLabel={''}
        isDeclined={!isUserDeclined}
      />
      {/* <Box
        display={'flex'}
        marginTop={'30px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={'20px'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <CustomSearchField />
          <CustomFilterSelect
            filters={[
              { value: 'all', label: 'All Tasks' },
              { value: 'all2', label: 'All Tasks2' },
            ]}
            value={'all'}
          />
          <CustomFilterSelect
            filters={[
              { value: 'all', label: 'All Confidence' },
              { value: 'all2', label: 'All Confidence2' },
            ]}
            value={'all'}
          />
        </Box>
      </Box> */}
      <Tabs
        sx={{
          marginTop: '12px',
          minHeight: 0,
        }}
        classes={{ indicator: 'my-tabs-indicator', root: 'declined-tabs' }}
        value={tab}
        onChange={(e, tab) => {
          setSelectedTasks([]);
          setTab(tab);
        }}
      >
        <Tab
          sx={{ ...tabStyles, marginRight: '10px' }}
          value={DeclinedTaskTabs.USER_DECLINED}
          label='User Declined'
          tabIndex={0}
        />
        <Tab
          sx={{ ...tabStyles, width: '155px' }}
          value={DeclinedTaskTabs.SYSTEM_DECLINED}
          label='System Declined'
          tabIndex={0}
        />
      </Tabs>
      <Box>
        {loading || systemDeclinedLoading || deletingTask ? (
          <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer
              sx={{ marginTop: '30px' }}
              className={'table-header-style'}
            >
              <Table
                id={
                  isUserDeclined
                    ? 'user-declined-tasks-table'
                    : 'system-declined-tasks-table'
                }
                sx={{ tableLayout: 'fixed' }}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                    {isAdminView && pagedTasks.length > 0 && (
                      <CustomTableCell sx={{ width: '50px' }} ellipsis={false}>
                        <TaskCheckbox
                          disabled={retryLoading}
                          checked={isSelectAllChecked(
                            selectedTasks,
                            pagedTasks,
                          )}
                          title={`Select all ${
                            isUserDeclined ? 'User Declined' : 'System Declined'
                          } tasks`}
                          onClick={(e) =>
                            handleSelectAllClick(
                              e,
                              selectedTasks,
                              pagedTasks,
                              setSelectedTasks,
                            )
                          }
                        />
                      </CustomTableCell>
                    )}
                    <CustomTableCell title='Task Name'>
                      <CustomTableLabel label='Task Name' />
                    </CustomTableCell>
                    <CustomTableCell title='Workflow'>
                      <CustomTableLabel label='Workflow' />
                    </CustomTableCell>
                    <CustomTableCell title='Time Declined'>
                      <CustomTableLabel label='Time Declined' />
                    </CustomTableCell>
                    <CustomTableCell
                      title={isUserDeclined ? 'Declined reason' : 'Error'}
                    >
                      <CustomTableLabel
                        label={isUserDeclined ? 'Declined reason' : 'Error'}
                      />
                    </CustomTableCell>
                    {isAdminView && (
                      <CustomTableCell
                        title={isUserDeclined ? 'Declined by' : 'Assigned to'}
                      >
                        <CustomTableLabel
                          label={isUserDeclined ? 'Declined by' : 'Assigned to'}
                        />
                      </CustomTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedTasks.map((s: Task, index: number) => (
                    <StyledTableRow
                      key={index}
                      tabIndex={0}
                      role='row'
                      sx={{
                        cursor: 'pointer',
                        ':hover': { backgroundColor: '#1669F74D' },
                        ':focus': { backgroundColor: '#1669F74D' },
                      }}
                      onKeyUp={(event) => {
                        if (event.key === 'Enter' && isUserDeclined) {
                          dispatch(clearReviewState());
                          navigate(`/${s.name}/automation-review`);
                        }
                      }}
                      onClick={() => {
                        if (isUserDeclined) {
                          dispatch(clearReviewState());
                          navigate(`/${s.name}/automation-review`);
                        }
                      }}
                    >
                      {isAdminView && (
                        <CustomTableCell ellipsis={false}>
                          <TaskCheckbox
                            disabled={retryLoading}
                            checked={isTaskSelected(s, selectedTasks)}
                            onClick={(e) =>
                              handleTaskCheckboxClick(
                                e,
                                s,
                                selectedTasks,
                                setSelectedTasks,
                              )
                            }
                            sx={{ marginRight: '20px' }}
                          />
                        </CustomTableCell>
                      )}
                      <CustomTableCell title={s.displayName || 'NO DATA'}>
                        {s.displayName || 'NO DATA'}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Box
                          title={
                            s.workflowDisplayName || s.organizationResourceName
                          }
                          sx={{
                            paddingX: '10px',
                            paddingY: '5px',
                            width: 'fit-content',
                            maxWidth: '100%',
                            backgroundColor: addAlpha(
                              workflowColors[
                                (s.workflowDisplayName as string) ||
                                  (s.organizationResourceName as string)
                              ],
                              0.4,
                            ),
                            borderRadius: '4px',
                          }}
                        >
                          <CustomTypography
                            typographyType={TypographyType.Label}
                            sx={{
                              ...ELLIPSIS_STYLE,
                            }}
                          >
                            {s.workflowDisplayName ||
                              s.organizationResourceName}
                          </CustomTypography>
                        </Box>
                      </CustomTableCell>
                      <CustomTableCell ellipsis={false}>
                        {s.createTime && s.completeTime
                          ? formatDate(s.completeTime)
                          : '-'}
                      </CustomTableCell>
                      {isUserDeclined ? (
                        <CustomTableCell
                          title={getDeclineReason(
                            s?.declineReason as UserDeclinedTaskReason,
                          )}
                        >
                          {getDeclineReason(
                            s?.declineReason as UserDeclinedTaskReason,
                          )}
                        </CustomTableCell>
                      ) : (
                        <CustomTableCell
                          title={
                            TASK_DECLINED_REASON[
                              s?.obsoleteReason as keyof typeof TASK_DECLINED_REASON
                            ]
                          }
                        >
                          {
                            TASK_DECLINED_REASON[
                              s?.obsoleteReason as keyof typeof TASK_DECLINED_REASON
                            ]
                          }
                        </CustomTableCell>
                      )}
                      {isAdminView && (
                        <CustomTableCell
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{ ...ELLIPSIS_STYLE }}
                            title={getLastReviewUser(s)}
                          >
                            <CustomTypography
                              sx={{
                                ...ELLIPSIS_STYLE,
                                maxWidth: '100%',
                                marginTop: '5px',
                              }}
                            >
                              {getLastReviewUser(s)}
                            </CustomTypography>
                          </Box>
                          {renderDeleteIcon(s)}
                        </CustomTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {!pagedTasks.length && (
                <CustomTypography
                  sx={{
                    textAlign: 'center',
                    textJustify: 'center',
                    color: '#475467',
                    marginTop: '40px',
                  }}
                >
                  {`There are no ${
                    isUserDeclined ? 'user' : 'system'
                  } declined tasks available`}
                </CustomTypography>
              )}
            </TableContainer>
            <CustomPagination
              rowsPerPage={pageSize}
              totalSize={isUserDeclined ? totalSize : systemDeclinedTotalSize}
              page={page}
              onRowsPerPageChange={(rows) => {
                setPage(0);
                // Refresh is needed when rows per page is changes to fetch fresh data
                if (isUserDeclined) {
                  listDeclinedTasks(1, rows, true);
                } else {
                  listSystemDeclinedTasks(1, rows, true);
                }
                setPageSize(rows as number);
                setSelectedTasks([]);
              }}
              isShowSelectFilter={
                isUserDeclined ? totalSize !== 0 : systemDeclinedTotalSize !== 0
              }
              onPageChange={(p) => {
                setPage(p);
                setSelectedTasks([]);
                if (p >= page && pageTasks.length <= p * pageSize) {
                  if (isUserDeclined) {
                    listDeclinedTasks(p + 1, pageSize, false);
                  } else {
                    listSystemDeclinedTasks(p + 1, pageSize, false);
                  }
                }
              }}
            />
          </>
        )}
      </Box>
      <TaskDeletionModal
        selectedTasks={
          deleteType === ActionType.BATCH
            ? selectedTasks
            : selectedTask
              ? [selectedTask]
              : []
        }
        open={!!deleteType}
        handleClose={handleCloseDeletionModal}
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default DeclinedTasks;
