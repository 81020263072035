import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';

import {
  OrbyColorPalette,
  OrbyHeaderLabel,
  OrbyTable,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import {
  ArchivedResourceType,
  DISPLAY_NAME_PREFIX,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import {
  TRASH_CAN_ACTION_MENU_TYPE,
  allowSelectionForArchivedResources,
  areAllResourcesSelected,
  compareResourceName,
  getNotesColumnWidth,
  getResourceName,
  getTaskNameColumnWidth,
  getTimeModifiedWidth,
  getTrashCanActionMenu,
  getTrashCanActionMenuCell,
  getWorkflowNameColumnWidth,
  handleTrashPageChange,
  handleTrashPageRowSelectionChange,
} from './TrashCanHelper';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROWS_PER_PAGE } from 'orby-ui/src/components/table/table-utils';
import {
  buildTrashCanRequest,
  getDeletedByCell,
  getNotesCell,
  getResourceType,
  getTaskNameCell,
  getTimeDeletedCell,
  getWorkflowDisplayNameCell,
} from './TrashCanHelper';
import {
  listArchivedResourcesAction,
  restoreArchivedResourcesAction,
  restoreArchivedResourcesErrorAction,
} from '../../redux/actions/archived_resource.action';
import {
  archivedResourcesListLoadingSelector,
  archivedResourcesListSelector,
  archivedResourcesListingErrorSelector,
  archivedResourcesRestoredSelector,
  archivedResourcesRestoringErrorSelector,
  archivedResourcesRestoringSelector,
  archivedResourcesTotalSizeSelector,
} from '../../redux/selectors/archived_resources.selectors';
import {
  ArchivedResource,
  RestoreArchivedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import { toastService } from '../../services/ToastService';
import ResourceTypeFiler from './component/ResourceTypeFiler';
import TaskSelectionHeader from '../../pages/Tasks/tabs/TaskSelectionHeader';
import RestoreItemsSuccessModel from '../../pages/ArchivedResources/RestoreItemsSuccessModel';
import RestoreItemsConfirmationModel from '../../pages/ArchivedResources/RestoreItemsConfirmationModel';
import CustomToastContent from '../../components/CustomToastContent';
import { CallMadeRounded, RefreshRounded } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { getPathAccToResourceV2 } from '../Utils/archivedResourcesUtils';

interface Props {
  sideDrawerCollapse: boolean;
}

const TrashCanPage: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(location.search);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const archivedItems: ArchivedResource[] = useSelector(
    archivedResourcesListSelector,
  );
  const loading = useSelector(archivedResourcesListLoadingSelector);
  const listingError = useSelector(archivedResourcesListingErrorSelector);
  const totalSize = useSelector(archivedResourcesTotalSizeSelector) ?? 0;
  const restoring = useSelector(archivedResourcesRestoringSelector);
  const restored = useSelector(archivedResourcesRestoredSelector);
  const restoringError = useSelector(archivedResourcesRestoringErrorSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [isRestoreSuccessModalOpen, setIsRestoreSuccessModalOpen] =
    useState(false);
  const [selectedType, setSelectedType] = useState<ArchivedResourceType>(
    ArchivedResourceType.TASK,
  );
  const [selectedResources, setSelectedResources] = useState<
    ArchivedResource[]
  >([]);
  const [selectedResource, setSelectedResource] =
    useState<ArchivedResource | null>(null);

  const [itemsPendingForRestoration, setItemsPendingForRestoration] = useState<
    ArchivedResource[]
  >([]);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  // USED FOR SEARCHING EXECUTION BY NAME
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );

  const pagedData = archivedItems.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  /**
   * FETCH TRASH
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listTrash = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildTrashCanRequest({
      navigate,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName!,
      type: selectedType,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listArchivedResourcesAction(req, refresh));
  };

  /**
   * ACTION MENU
   */
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    resource: ArchivedResource,
  ) => {
    setSelectedResource(resource);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type) {
      if (type === TRASH_CAN_ACTION_MENU_TYPE.RESTORE && selectedResource) {
        const resource = selectedResource;
        handleRestore([resource]);
      }
    }
    setSelectedResource(null);
    setActionMenuAnchorEl(null);
  };

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  const getColSx = () => {
    const colSx = [
      {
        width: `${getTaskNameColumnWidth(getTableWidth(), selectedType)}px`,
        maxWidth: `${getTaskNameColumnWidth(getTableWidth(), selectedType)}px`,
        minWidth: `${getTaskNameColumnWidth(getTableWidth(), selectedType)}px`,
      },
      {
        width: `${getWorkflowNameColumnWidth(getTableWidth())}px`,
        maxWidth: `${getWorkflowNameColumnWidth(getTableWidth())}px`,
        minWidth: `${getWorkflowNameColumnWidth(getTableWidth())}px`,
      },
      {
        width: `${getNotesColumnWidth(getTableWidth(), selectedType)}px`,
        maxWidth: `${getNotesColumnWidth(getTableWidth(), selectedType)}px`,
        minWidth: `${getNotesColumnWidth(getTableWidth(), selectedType)}px`,
      },
      {
        width: `${getTimeModifiedWidth(getTableWidth())}px`,
        maxWidth: `${getTimeModifiedWidth(getTableWidth())}px`,
        minWidth: `${getTimeModifiedWidth(getTableWidth())}px`,
      },
      {
        width: '217px',
        maxWidth: '217px',
        minWidth: '217px',
      },
      {
        width: '69px',
        maxWidth: '69px',
        minWidth: '69px',
      },
    ];
    if (selectedType !== ArchivedResourceType.TASK) {
      // Remove the workflow cell
      colSx.splice(1, 1);
      colSx.splice(4, 1);
    }
    return colSx;
  };

  const getHeaderRows = () => {
    const headers = [
      <OrbyHeaderLabel key={'trash-can-task-name'} label='Task Name' />,
      <OrbyHeaderLabel key={'trash-can-workflow'} label='Workflow' />,
      <OrbyHeaderLabel key={'trash-can-notes'} label='Notes' />,
      <OrbyHeaderLabel key={'trash-can-time-deleted'} label='Time Deleted' />,
      <OrbyHeaderLabel key={'trash-can-deleted-by'} label='Deleted By' />,
      <OrbyHeaderLabel key={'trash-can-actions'} label='' />,
    ];
    if (selectedType !== ArchivedResourceType.TASK) {
      // Remove the workflow cell
      headers.splice(1, 1);
      headers.splice(4, 1);
    }
    return headers;
  };

  // CHECK IF SPECIFIC RESOURCE IS SELECTED
  const isRowSelected = (resource: ArchivedResource) => {
    return selectedResources.some((res) =>
      compareResourceName(selectedType, res, resource),
    );
  };

  // HANDLE SELECTION/DESELECTION OF THE RESOURCE
  const handleRowSelection = (
    resource: ArchivedResource,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setSelectedResources((prevSelectedResource) => [
        ...prevSelectedResource,
        resource,
      ]);
    } else {
      setSelectedResources((prevSelectedResource) =>
        prevSelectedResource.filter(
          (res) => !compareResourceName(selectedType, res, resource),
        ),
      );
    }
  };

  // HANDLE SELECTION/DESELECTION OF ALL RESOURCES ON THE CURRENT PAGE
  const handleAllRowsSelection = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedResources(
        pagedData.filter((res) =>
          allowSelectionForArchivedResources(selectedType, res),
        ),
      );
    } else {
      setSelectedResources([]);
    }
  };

  const handleCloseRestoreModal = () => {
    setIsRestoreModalOpen(false);
  };

  const handleCloseRestoreSuccessModal = () => {
    setIsRestoreSuccessModalOpen(false);
  };

  const handleRestore = (itemsToRestore: ArchivedResource[]) => {
    // whether its batch or single restore we always send the array of resource names
    const req: RestoreArchivedResourcesRequest = {};
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    req.name = itemsToRestore
      .map((item) => getResourceName(selectedType, item))
      .filter((res) => res) as string[];
    dispatch(restoreArchivedResourcesAction(req));
    setItemsPendingForRestoration(itemsToRestore);
    handleCloseRestoreModal();
    setSelectedResources([]);
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(0);
    setSelectedResources([]);
    listTrash(1, rowsPerPage, true);
  };

  /**
   * SHOW ERROR TOAST
   * WHEN RESTORE IN UNSUCCESSFUL
   */
  const showRestoreError = (msg: string, pendingItems: ArchivedResource[]) => {
    const isBatch = pendingItems.length > 1;
    toastService.showError(
      <CustomToastContent
        type='error'
        content={
          <OrbyTypography sx={{ mt: '8px' }}>
            {`${pendingItems.length} ${selectedType}${
              isBatch ? 's were' : ' was'
            } not restored because of ${msg}`}
          </OrbyTypography>
        }
        buttonTitle={'Try again'}
        icon={<RefreshRounded />}
        onClick={() => handleRestore(pendingItems)}
      />,
      { position: 'top-right' },
    );
  };

  const showListingError = (msg: string) => {
    toastService.showError(
      <CustomToastContent
        type='error'
        content={<OrbyTypography sx={{ mt: '8px' }}>{msg}</OrbyTypography>}
      />,
      { position: 'top-right' },
    );
  };

  const openSuccess = (msg: string) => {
    const toastId = `toast-${uuidv4()}`;
    toastService.showSuccess(
      <CustomToastContent
        type='success'
        content={<OrbyTypography sx={{ mt: '8px' }}>{msg}</OrbyTypography>}
        buttonTitle={'Go to page'}
        icon={<CallMadeRounded />}
        onClick={() => {
          handleNavigation(false);
          toastService.dismiss(toastId);
        }}
      />,
      {
        position: 'top-right',
        style: {
          borderWidth: '0px !important',
          backgroundColor: '#EBF8F2',
        },
        toastId,
      },
    );
  };

  const handleNavigation = (isBatch = true) => {
    /* We can only determine appropriate url in the following cases
       1. Restore is Single
       2. Restore is Batch but not of type `task` 
          because based on task status, tasks can belong to multiple tabs eg: 'completed-tasks', 'deleted-tasks' 
          so determination of url would not be possible since we can have multiple urls */
    if (selectedType !== ArchivedResourceType.TASK || !isBatch) {
      // Only defined in case of 'task' otherwise undefined
      const taskStatus = itemsPendingForRestoration[0].task?.status;
      const path = getPathAccToResourceV2(selectedType, taskStatus);
      navigate(`/${path}`);
      return;
    }
    handleCloseRestoreSuccessModal();
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    if (selectedOrgInfo) {
      refreshPage();
    }
  }, [selectedOrgInfo]);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix, selectedType]);

  useEffect(() => {
    if (listingError) {
      showListingError(listingError.message);
    }
  }, [listingError]);

  useEffect(() => {
    if (restoringError && itemsPendingForRestoration.length > 0) {
      showRestoreError(restoringError.message, [...itemsPendingForRestoration]);
      setItemsPendingForRestoration([]);
      dispatch(restoreArchivedResourcesErrorAction());
    }
  }, [restoringError]);

  // RESTORED SUCCESS
  useEffect(() => {
    if (restored && itemsPendingForRestoration.length > 0) {
      // if multiple resources are restored (> 1) then show modal otherwise show toast
      if (itemsPendingForRestoration.length > 1) {
        setIsRestoreSuccessModalOpen(true);
      } else {
        openSuccess(`1 ${selectedType} was successfully restored.`);
      }
      setSelectedResources([]);
      setItemsPendingForRestoration([]);
      dispatch(restoreArchivedResourcesErrorAction());
      setPage(0);
      listTrash(1, rowsPerPage, true);
    }
  }, [restored]);

  return (
    <>
      <Box paddingX={'48px'} paddingY={'48px'}>
        {/* PAGE HEADER */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          paddingBottom={'10px'}
        >
          <Box>
            <OrbyTypography size={'display-sm'} weight={'medium'}>
              Trash
            </OrbyTypography>
          </Box>
        </Box>

        {/* TRASH CAN FILTER */}
        <Box
          position={'sticky'}
          top={0}
          bgcolor={OrbyColorPalette['white-0']}
          zIndex={1}
          paddingTop={'14px'}
          paddingBottom={'24px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-start'}
            width={'100%'}
            gap={'8px'}
          >
            <Box>
              <ResourceTypeFiler
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                width='160px'
                menuWidth='160px'
              />
            </Box>
            {/* SEARCH BY NAME */}
            <Box>
              <OrbyTextField
                width={'240px'}
                tabIndex={0}
                value={displayNamePrefix}
                name={'search-by-name'}
                disabled={false}
                fontSize='14px'
                placeholder='Search by name'
                startAdornment={<GridSearchIcon fontSize='medium' />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDisplayNamePrefix(event.target.value)
                }
              />
            </Box>
          </Box>
        </Box>

        {/* TRASH TABLE */}
        <Box>
          <OrbyTable
            tableWidth={getTableWidth()}
            cursor={'unset'}
            isDataLoading={loading || restoring}
            noDataMessage={
              displayNamePrefix
                ? `No results for "${displayNamePrefix}"`
                : 'There are no data available'
            }
            colSx={getColSx()}
            tableId='ui-automation-table'
            showCheckBox={true}
            isCheckBoxDisabled={false}
            areAllRowsSelected={() =>
              areAllResourcesSelected(
                selectedResources,
                archivedItems,
                page,
                rowsPerPage,
                selectedType,
              )
            }
            isRowSelected={(row) => {
              const resource = row as ArchivedResource;
              return isRowSelected(resource);
            }}
            selectedRows={selectedResources}
            handleRowSelection={(row, isSelected) => {
              const resource = row as ArchivedResource;
              handleRowSelection(resource, isSelected);
            }}
            handleAllRowsSelection={handleAllRowsSelection}
            checkBoxSelectionHeader={
              <TaskSelectionHeader
                selectedItems={selectedResources}
                onCancel={() => {
                  setSelectedResources([]);
                }}
                secondaryLabel=''
                primaryLabel={'Restore'}
                onPrimaryClick={() => setIsRestoreModalOpen(true)}
              />
            }
            /**
             * PAGINATION
             */
            pagination={{
              rowsPerPage,
              currentPage: page,
              totalRows: totalSize,
              setCurrentPage: (pageNumber: number) =>
                handleTrashPageChange({
                  archivedItems,
                  pageNumber,
                  page,
                  rowsPerPage,
                  setPage,
                  listTrash,
                  setSelectedResources,
                }),
              setRowsPerPage: (rows: number) =>
                handleTrashPageRowSelectionChange({
                  rowsNumber: rows,
                  setPage,
                  setRowsPerPage,
                  listTrash,
                  setSelectedResources,
                }),
            }}
            /**
             * TABLE HEADER ROWS
             */
            headerRows={getHeaderRows()}
            /**
             * TABLE ROWS
             */
            dataRows={pagedData.map((archivedItem) => {
              const cells = [
                getTaskNameCell(archivedItem, displayNamePrefix),
                getWorkflowDisplayNameCell(archivedItem),
                getNotesCell(archivedItem),
                getTimeDeletedCell(archivedItem),
                getDeletedByCell(archivedItem),
                getTrashCanActionMenuCell(archivedItem, handleActionMenuClick),
              ];

              if (selectedType !== ArchivedResourceType.TASK) {
                // Remove the workflow display name cell
                cells.splice(1, 1);
              }
              const resource = getResourceType(archivedItem);
              return {
                title: resource.name!,
                id: resource.name!,
                row: archivedItem,
                cells: cells,
                isCheckBoxDisabled: !allowSelectionForArchivedResources(
                  selectedType,
                  archivedItem,
                ),
              };
            })}
          />
        </Box>
      </Box>

      {/* TRASH CAN ACTION MENU */}
      {getTrashCanActionMenu(actionMenuAnchorEl, handleActionMenuClose)}

      <RestoreItemsConfirmationModel
        open={isRestoreModalOpen}
        isMultiple={selectedResources.length > 1}
        handleClose={handleCloseRestoreModal}
        onSubmit={() => handleRestore(selectedResources)}
      />

      <RestoreItemsSuccessModel
        open={isRestoreSuccessModalOpen}
        handleClose={handleCloseRestoreSuccessModal}
        // Don't show links for multiple tasks restore
        // https://github.com/orby-ai-engineering/orby-web-app/pull/917#issuecomment-1995472950
        isShowLink={selectedType !== ArchivedResourceType.TASK}
        resourceType={selectedType}
        onSubmit={handleNavigation}
      />
    </>
  );
};

export default React.memo(TrashCanPage);
