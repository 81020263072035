import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DoneOutlined, CloseOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
  EditOutlined,
  MoreVert,
  PersonAddAlt,
  PersonRemoveAlt1Outlined,
} from '@mui/icons-material';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import CustomPagination from '../../components/Pagination/CustomPagination';
import AddUserModal from './AddUserModal';
import DeleteUserModal from './DeleteUserModal';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingUsersSelector,
  loggedInUserSelector,
  selectedOrgInfoSelector,
  usersListSelector,
} from '../../redux/selectors/user.selectors';
import {
  listUsersAction,
  listUsersErrorAction,
} from '../../redux/actions/user.action';
import { ListUsersRequest } from 'protos/pb/v1alpha1/users_service';
import { usersTotalSizeSelector } from '../../redux/selectors/user.selectors';
import { listUsersErrorSelector } from '../../redux/selectors/user.selectors';
import { notification } from 'antd';
import { getRole } from '../../utils/helpers';
import CustomTableCell from '../../components/CustomTableCell';
import {
  ELLIPSIS_STYLE,
  NAME_EMAIL_PREFIX,
  FEATURE_FLAGS,
} from '../../utils/constants';
import CustomTableLabel from '../../components/CustomTableLabel';
import SearchTaskField from '../PendingTasks/SearchTaskField';
import { composeUserFilters } from '../../utils/UserUtils';
import { useNavigate } from 'react-router-dom';
import UserCard from '../../components/UserCard';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FAFAFA',
  },
  '& td, & th': {
    border: 0,
  },
}));

const Account: React.FC = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useSelector(loggedInUserSelector);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const users: User[] = useSelector(usersListSelector);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const totalSize = useSelector(usersTotalSizeSelector) ?? 0;
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [nameEmailPrefix, setNameEmailPrefix] = useState(
    urlSearchParams.get(NAME_EMAIL_PREFIX) || '',
  );
  const listUsersError = useSelector(listUsersErrorSelector);
  const loadingUsers = useSelector(loadingUsersSelector);
  const [api, contextHolder] = notification.useNotification();
  const pagedUsers = users.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const open = Boolean(anchorEl);

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);

  const handleClick = (event: React.MouseEvent<HTMLElement>, d: User) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(d);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buildRequest = () => {
    const req: ListUsersRequest = {};
    req.orgResourceName = selectedOrgInfo?.orgResourceName;
    const filters = composeUserFilters(nameEmailPrefix, navigate);
    if (filters) {
      req.filter = filters;
    }
    return req;
  };

  const getUserRoleForSelectedOrg = (user: User) => {
    return user?.orgInfos?.find(
      (i: OrgInfo) => i.orgResourceName === selectedOrgInfo?.orgResourceName,
    )?.role;
  };

  const openError = (error: Error) => {
    api.info({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: 3,
    });
  };

  const openSuccess = (msg: string) => {
    api.info({
      message: 'Success',
      description: msg,
      placement: 'topRight',
      duration: 3,
    });
  };

  useEffect(() => {
    if (listUsersError) {
      openError(listUsersError);
      dispatch(listUsersErrorAction(undefined));
    }
  }, [listUsersError]);

  const listUsers = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildRequest();
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listUsersAction(req, refresh));
  };

  useEffect(() => {
    setPage(0);
    // Added true to refresh list
    listUsers(1, rowsPerPage, true);
  }, [selectedOrgInfo, nameEmailPrefix]);

  useEffect(() => {
    return () => setNameEmailPrefix(''); // reset search query only when org changes not when component mounts
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (open) {
      const removeTabIndex = () => {
        const element = document.querySelector('[data-testid="sentinelEnd"]');
        if (element) {
          element.removeAttribute('tabindex');
        }
      };

      removeTabIndex();
    }
  }, [open]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const sentinelStartElements = document.querySelectorAll(
        'div[data-testid="sentinelStart"]',
      );
      const sentinelEndElements = document.querySelectorAll(
        'div[data-testid="sentinelEnd"]',
      );

      sentinelStartElements.forEach((element) => {
        element.removeAttribute('tabindex');
      });

      sentinelEndElements.forEach((element) => {
        element.removeAttribute('tabindex');
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      const elm = document.getElementById('root');
      if (elm) elm.removeAttribute('aria-hidden');
    }
  }, [open]);

  return (
    <Box paddingX={'60px'} paddingY={'60px'}>
      {contextHolder}
      <SearchTaskField
        sx={{ marginLeft: '0px !important', marginBottom: '16px' }}
        displayNamePrefix={nameEmailPrefix ?? ''}
        setDisplayNamePrefix={setNameEmailPrefix}
        placeholder='Search by name or email'
      />
      <CustomTypography
        component={'h1'}
        size='18px'
        weight={600}
        color={'black'}
        sx={{ marginLeft: '12px', marginTop: '16px' }}
      >
        Users
      </CustomTypography>
      <Box display={'flex'} justifyContent={'end'}>
        <Button variant='contained' onClick={() => setAddUserOpen(true)}>
          <PersonAddAlt sx={{ marginRight: '4px', fontSize: '22px' }} />
          <CustomTypography component={'span'}>Add User</CustomTypography>
        </Button>
      </Box>
      {loadingUsers ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            sx={{ marginTop: '20px' }}
            className={'table-header-style'}
          >
            <Table id={'users-table'} sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                  <CustomTableCell title='User'>
                    <CustomTableLabel label='User' />
                  </CustomTableCell>
                  <CustomTableCell title='Role'>
                    <CustomTableLabel label='Role' />
                  </CustomTableCell>
                  <CustomTableCell title='Workflows'>
                    <CustomTableLabel label='Workflows' />
                  </CustomTableCell>
                  <CustomTableCell title='Activated'>
                    <CustomTableLabel label='Activated' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedUsers.map((d) => (
                  <StyledTableRow
                    key={d.email}
                    tabIndex={0}
                    role='row'
                    sx={{
                      cursor: 'pointer',
                      ':hover': { backgroundColor: '#1669F74D' },
                      ':focus': { backgroundColor: '#1669F74D' },
                    }}
                  >
                    <CustomTableCell>
                      <UserCard
                        email={d.email ?? ''}
                        fullName={d.fullName}
                        imageUrl={d.profileImageUrl}
                      />
                    </CustomTableCell>
                    <CustomTableCell
                      title={getRole(getUserRoleForSelectedOrg(d))}
                    >
                      <CustomTypography component={'span'}>
                        {getRole(getUserRoleForSelectedOrg(d))}
                      </CustomTypography>
                    </CustomTableCell>
                    <CustomTableCell>
                      {d.assignedWorkflows &&
                        d.assignedWorkflows.length > 0 && (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            gap={1}
                          >
                            {d.assignedWorkflows.map((workflow: string) => {
                              return (
                                <Box
                                  key={workflow}
                                  title={workflow}
                                  maxWidth={'100%'}
                                  sx={{
                                    paddingX: '10px',
                                    paddingY: '5px',
                                    backgroundColor: '#FFC04333',
                                    borderRadius: '4px',
                                  }}
                                >
                                  <CustomTypography
                                    sx={{ ...ELLIPSIS_STYLE }}
                                    typographyType={TypographyType.Label}
                                  >
                                    {workflow}
                                  </CustomTypography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        {d.activated && (
                          <DoneOutlined
                            aria-hidden={false}
                            role={'img'}
                            aria-label={'activated'}
                            sx={{ color: '#2E8442' }}
                          />
                        )}
                        {!d.activated && (
                          <CloseOutlined
                            aria-hidden={false}
                            role={'img'}
                            aria-label={'deactivated'}
                            sx={{ color: '#D30000' }}
                          />
                        )}
                      </Box>
                      {user && d.email !== user.email && (
                        <IconButton
                          aria-label='more'
                          id={'long-button' + d.email}
                          aria-controls={
                            open ? 'long-menu' + d.email : undefined
                          }
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup='true'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleClick(event, d);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      )}
                      <Menu
                        autoFocus={false}
                        disableAutoFocusItem={true}
                        aria-hidden={false}
                        id={'long-menu' + d.email}
                        MenuListProps={{
                          'aria-labelledby': 'long-button' + d.email,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: '87px',
                            width: '124px',
                            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
                            borderRadius: '4px',
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setEditUserOpen(true);
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            gap={'10px'}
                            alignItems={'center'}
                          >
                            <EditOutlined
                              fontSize='small'
                              sx={{ color: '#6B6B6B', marginRight: '10px' }}
                            />
                            <CustomTypography>Edit</CustomTypography>
                          </Box>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setDeleteUserOpen(true);
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            gap={'10px'}
                            alignItems={'center'}
                          >
                            <PersonRemoveAlt1Outlined
                              fontSize='small'
                              sx={{ color: '#E11900', marginRight: '10px' }}
                            />
                            <CustomTypography color='#E11900'>
                              Remove
                            </CustomTypography>
                          </Box>
                        </MenuItem>
                      </Menu>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!pagedUsers.length && (
              <CustomTypography
                sx={{
                  textAlign: 'center',
                  textJustify: 'center',
                  color: '#475467',
                  marginTop: '40px',
                }}
              >
                There are no users available
              </CustomTypography>
            )}
          </TableContainer>
          <CustomPagination
            rowsPerPage={rowsPerPage}
            totalSize={totalSize}
            page={page}
            justifyContent='end'
            onRowsPerPageChange={(rows) => {
              setPage(0);
              // Refresh is needed when rows per page is changes to fetch fresh data
              listUsers(1, rows, true);
              setRowsPerPage(rows as number);
            }}
            isShowSelectFilter={totalSize > rowsPerPage}
            onPageChange={(p) => {
              setPage(p);
              if (p >= page && users.length <= p * rowsPerPage) {
                listUsers(p + 1, rowsPerPage, false);
              }
            }}
          />
        </>
      )}
      <AddUserModal
        open={addUserOpen}
        setOpen={setAddUserOpen}
        openError={openError}
        onSuccess={(message) => {
          openSuccess(message);
          // refresh the list upon creation
          setPage(0);
          listUsers(1, rowsPerPage, true);
        }}
        isRbacEnabled={isRbacEnabled}
      />
      <AddUserModal
        open={editUserOpen}
        setOpen={setEditUserOpen}
        edit
        user={selectedUser}
        openError={openError}
        onSuccess={openSuccess}
        isRbacEnabled={isRbacEnabled}
      />
      {selectedUser && (
        <DeleteUserModal
          open={deleteUserOpen}
          setOpen={setDeleteUserOpen}
          user={selectedUser}
          openError={openError}
          onSuccess={(message) => {
            openSuccess(message);
            // refresh the list upon deletion
            setPage(0);
            listUsers(1, rowsPerPage, true);
          }}
        />
      )}
    </Box>
  );
};

export default Account;
