import { TextField } from '@mui/material';
import React, { FC, memo } from 'react';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import moment from 'moment';
import { DateMessage } from 'protos/google/type/date';
import {
  updateEntityInfoForTableAnnotationAction,
  updateEntityInfoNormalizedValueAction,
} from '../../../../../../../redux/actions/review_task.action';
import { useDispatch, useSelector } from 'react-redux';
import { ISO_8601_DATE_FORMAT } from '../../../../../../../utils/constants';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';
import { detectUndoRedoKeyPress } from '../../../../../../../utils/helpers';
import InputMask from 'react-input-mask';

interface NormalizedDateFieldProps {
  year?: number;
  month?: number;
  day?: number;
  selectedEntity: EntityInfo;
  task: Task;
  label: string;
  insideTableLayout?: boolean;
}

const NormalizedDateField: FC<NormalizedDateFieldProps> = ({
  selectedEntity,
  label,
  insideTableLayout,
}) => {
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const dispatch = useDispatch();

  const FORMAT_MARK = ISO_8601_DATE_FORMAT.replace(/[^-]/g, '9'); // eg:9999-99-99 nine here represent a digit

  const updateEntityInfo = (
    entityInfo: EntityInfo,
    excludeFromHistory = false,
  ) => {
    if (insideTableLayout) {
      dispatch(
        updateEntityInfoForTableAnnotationAction(
          entityInfo,
          false,
          false,
          excludeFromHistory,
        ),
      );
    } else {
      dispatch(
        updateEntityInfoNormalizedValueAction(entityInfo, excludeFromHistory),
      );
    }
  };

  const validateInput = (value: string) => {
    // Regular expression to match the full date format YYYY-MM-DD
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    // Check if the input is fully filled and matches the regex pattern
    if (!dateRegex.test(value)) {
      return `The expected date format is ${ISO_8601_DATE_FORMAT}`;
    }

    // Validate if the date is a valid calendar date
    const date = moment(value, ISO_8601_DATE_FORMAT, true);

    if (!date.isValid()) {
      return 'Entered date is not valid';
    }

    // If the input is valid, return an empty string
    return '';
  };

  return (
    <InputMask
      readOnly={!allowEditingTask}
      mask={FORMAT_MARK}
      maskChar=''
      value={selectedEntity.normalizedInputValue ?? ''}
      onChange={(e) => {
        if (allowEditingTask) {
          const inputValue = e.target.value;
          const error = validateInput(inputValue);
          updateEntityInfo({
            ...selectedEntity,
            normalizedInputValue: inputValue,
            error: inputValue && error ? error : null,
          });
        }
      }}
      onBlur={() => {
        if (allowEditingTask) {
          const parsedDate = moment(
            selectedEntity.normalizedInputValue,
            ISO_8601_DATE_FORMAT,
            true,
          );
          if (parsedDate.isValid()) {
            const textDate = parsedDate.format(ISO_8601_DATE_FORMAT);
            const date: DateMessage = {};
            date.day = parsedDate.date();
            date.month = parsedDate.month() + 1;
            date.year = parsedDate.year();
            const newEntityInfo = { ...selectedEntity };
            newEntityInfo.normalizedValue = {};
            newEntityInfo.normalizedValue.text = textDate;
            newEntityInfo.normalizedValue.dateValue = date;
            newEntityInfo.isNormalizedValueModified = true;
            newEntityInfo.error = null;
            // do not record this action in undo/redo history
            updateEntityInfo(newEntityInfo, true);
          }
        }
      }}
    >
      {
        ((props: any) => {
          return (
            <TextField
              {...props}
              label={label}
              error={!!selectedEntity.error ?? false}
              helperText={
                selectedEntity.error ? selectedEntity.error : undefined
              }
              onKeyDown={(e) => {
                if (detectUndoRedoKeyPress(e)) {
                  e.preventDefault();
                }
              }}
              InputProps={
                !label
                  ? {
                      disableUnderline: true,
                      sx: {
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      },
                    }
                  : {}
              }
              FormHelperTextProps={{
                sx: {
                  color: '#B42318 !important',
                  ml: '0px !important',
                  textWrap: 'balance',
                  fontSize: insideTableLayout ? '10px' : '12px',
                  lineHeight: insideTableLayout ? '12px' : 'auto',
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: { fontSize: '12px', top: '2px' },
              }}
              inputProps={{
                placeholder: ISO_8601_DATE_FORMAT,
                sx: {
                  fontSize: insideTableLayout ? '14px' : '16px',
                },
              }}
              sx={{
                width: '100%',
                padding: '0px',
                '& legend': {
                  width: '82px',
                },
                '& .MuiFilledInput-input': {
                  padding: insideTableLayout ? '4px 12px' : '10px 14px',
                },
                '& .MuiOutlinedInput-input': {
                  padding: insideTableLayout ? '4px 12px' : '8.5px 14px',
                },
                '& .MuiInputBase-input': {
                  border: !label
                    ? `1px solid ${selectedEntity.error ? '#B42318' : '#D0D5DD'}`
                    : 'none',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '&:focus': {
                    borderWidth: '1px',
                    borderColor: selectedEntity.error ? '#B42318' : '#1669F7',
                    backgroundColor: 'white !important',
                  },
                  '&:hover': {
                    backgroundColor: !label ? '#00000017' : 'white',
                  },
                },
              }}
              size='small'
              variant={label ? 'outlined' : 'filled'}
            />
          );
        }) as any
      }
    </InputMask>
  );
};

export default memo(NormalizedDateField);
