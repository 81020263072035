import { MoreVert } from '@mui/icons-material';
import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import CustomTypography from '../../../../../../../components/CustomTypography';
import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideColumnFromTableAnnotationAction } from '../../../../../../../redux/actions/review_task.action';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import { allowEditingTaskSelector } from '../../../../../../../redux/selectors/review_task.selectors';
import { focusNextElement } from '../../../../../../../utils/FocusUtils';

interface Props {
  row: EntityInfo;
  disabled: boolean;
  showSmall?: boolean;
}

const TableModalHeaderMenu: FC<Props> = ({
  row,
  disabled,
  showSmall = false,
}) => {
  const dispatch = useDispatch();
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const focusNextElem = () => {
    const table = document.getElementById('table-modal-body');
    if (table) {
      const focusableElements = table.querySelectorAll(
        '[tabindex]:not([tabindex="-1"])',
      );
      const currentElement = document.getElementById(
        `cell-${row.type}-menu-button`,
      );
      focusNextElement(focusableElements, currentElement as Element);
    }
  };

  return (
    <>
      {allowEditingTask && (
        <IconButton
          id={`cell-${row.type}-menu-button`}
          title=''
          onClick={handleClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          disabled={disabled}
          sx={{
            width: showSmall ? '20px' : '2rem',
            height: showSmall ? '20px' : '2rem',
            padding: '0px',
            zIndex: 2,
          }}
        >
          <MoreVert
            sx={{
              width: showSmall ? '18px' : '1em',
              color: disabled ? '#D0D5DD' : '#000000',
            }}
          />
        </IconButton>
      )}
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        open={open}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          '& .MuiPopover-paper': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();

            // focus next element before hiding the column to retain the focus
            focusNextElem();

            dispatch(hideColumnFromTableAnnotationAction(row.type));
            setAnchorEl(null);
          }}
        >
          <CustomTypography sx={{ color: '#000000' }}>Hide</CustomTypography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(TableModalHeaderMenu);
