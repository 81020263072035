/**
 * This file is used to show the entities list
 * on the sidebar of the review page
 */
import React, { useEffect, useRef } from 'react';
import EntitiesInfoBox from './components/EntitiesInfoBox';
import ParentEntitiesInfoBox from './components/ParentEntitiesInfoBox';
import { EntityInfo } from '../../../../../../redux/reducers/review_task.reducer';
import {
  getEntitiesList,
  getNeedAttentionEntitiesCount,
  getOldEntitiesList,
  getPredictedEntitiesCount,
  getReviewedEntitiesCount,
  isEntityNeedAttention,
  isEntityPredicted,
  isEntityReviewed,
} from '../../../../../../utils/ReviewTaskUtils';
import { EntityFilter, FEATURE_FLAGS } from '../../../../../../utils/constants';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import CustomTypography from '../../../../../../components/CustomTypography';
import { useSelector } from 'react-redux';
import {
  originalTaskEntityInfoSelector,
  selectedEntityIdSelector,
  selectedParentEntityInfoSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import NestedEntitiesInfoBox from './components/NestedEntitiesInfoBox';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../../../../../FeatureFlags/FeatureFlagUtils';

interface Props {
  selectedReviewFilterSection: EntityFilter | undefined;
  task: Task;
  entityInfoMap: { [id: string]: EntityInfo };
  isSuggestionEntityBox?: boolean;
}

const EntitiesInfo: React.FC<Props> = ({
  selectedReviewFilterSection,
  task,
  entityInfoMap,
  isSuggestionEntityBox,
}) => {
  const originalEntitiesInfo = useSelector(originalTaskEntityInfoSelector);
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );
  const entityList = showNewNestedUI
    ? getEntitiesList(entityInfoMap, originalEntitiesInfo)
    : getOldEntitiesList(entityInfoMap);
  const needAttentionEntitiesCount =
    getNeedAttentionEntitiesCount(entityInfoMap);
  const predictedEntitiesCount = getPredictedEntitiesCount(entityInfoMap);
  const reviewedEntitiesCount = getReviewedEntitiesCount(entityInfoMap);
  const selectedEntityId = useSelector(selectedEntityIdSelector);
  const lastSelectedEntityId = useRef<string | null>(null);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );

  // This function checks whether there are entities available for the selected
  // entity category that is displayed on the side bar so that necessary placeholder can be shown
  const hasEntitiesForSelectedTab = () => {
    // Is the entities displayed are suggestion entities, no need to check the count
    if (selectedReviewFilterSection && !isSuggestionEntityBox) {
      if (selectedReviewFilterSection === EntityFilter.REVIEWED) {
        return reviewedEntitiesCount > 0;
      } else if (selectedReviewFilterSection === EntityFilter.PREDICTED) {
        return predictedEntitiesCount > 0;
      } else if (selectedReviewFilterSection === EntityFilter.NEED_ATTENTION) {
        return needAttentionEntitiesCount > 0;
      }
      return false;
    }
    return true;
  };

  /**
   * This function is used to check if the entity should be shown or not
   * If the selectedReviewFilterSection is not null, then it will check if the entity
   * is reviewed or not based on the selectedReviewFilterSection
   *  If selectedReviewFilterSection is null, then it will show all the entities
   */
  const isShowEntity = (entity: EntityInfo) => {
    if (selectedReviewFilterSection) {
      if (selectedReviewFilterSection === EntityFilter.REVIEWED) {
        return isEntityReviewed(entity);
      } else if (selectedReviewFilterSection === EntityFilter.PREDICTED) {
        return isEntityPredicted(entity);
      } else if (selectedReviewFilterSection === EntityFilter.NEED_ATTENTION) {
        return isEntityNeedAttention(entity);
      }
      return false;
    }
    return true;
  };

  // Allow deletion of parent entities only if there is more than one parent entity of the same parentEntityType.
  const allowDeletionForParent = (
    entityDetail:
      | EntityInfo
      | {
          parentEntityId: string;
          parentEntityType: string;
          entities: EntityInfo[];
        },
  ) => {
    // Check whether the entity is parent entity or not
    if (
      'parentEntityId' in entityDetail &&
      'entities' in entityDetail &&
      'parentEntityType' in entityDetail
    ) {
      return entityList.some(
        (e) =>
          e.parentEntityId !== entityDetail.parentEntityId &&
          e.parentEntityType === entityDetail.parentEntityType,
      );
    }
    return false;
  };

  /**
   * This function is used to check if the nested entity should be shown or not
   * If the selectedReviewSection is not null, then it will check if the nested entities properties
   * are reviewed or not based on the selectedReviewSection
   * If selectedReviewSection is null, then it will show all the nested entities
   */
  const isShowNestedEntity = (entities: EntityInfo[]) => {
    if (selectedReviewFilterSection) {
      if (selectedReviewFilterSection === EntityFilter.REVIEWED) {
        return entities.some((entity) => isEntityReviewed(entity));
      } else if (selectedReviewFilterSection === EntityFilter.PREDICTED) {
        return entities.some((entity) => isEntityPredicted(entity));
      } else if (selectedReviewFilterSection === EntityFilter.NEED_ATTENTION) {
        return entities.some((entity) => isEntityNeedAttention(entity));
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selectedEntityId) {
      lastSelectedEntityId.current = selectedEntityId;
    } else if (
      !selectedParentEntityInfo?.type &&
      lastSelectedEntityId.current
    ) {
      document
        .getElementById(`entity-box-${lastSelectedEntityId.current}`)
        ?.focus();
    }
  }, [selectedEntityId]);

  useEffect(() => {
    if (selectedParentEntityInfo?.type) {
      lastSelectedEntityId.current = selectedParentEntityInfo?.type;
    } else if (!selectedEntityId && lastSelectedEntityId.current) {
      document
        .getElementById(`entity-box-${lastSelectedEntityId.current}`)
        ?.focus();
    }
  }, [selectedParentEntityInfo?.type]);

  return (
    <>
      {hasEntitiesForSelectedTab() ? (
        entityList.map((entityDetail: any) => {
          // First condition - To check if entity is parent for new nested HITL design
          // Group of second condition - To check if entity is parent for old nested HITL design
          if (
            entityDetail.isNestedEntity ||
            ('parentEntityId' in entityDetail &&
              'entities' in entityDetail &&
              'parentEntityType' in entityDetail)
          ) {
            const allChildEntities = Object.values(entityInfoMap).filter(
              (e) => e.parentEntityId === entityDetail.parentEntityId,
            );
            // Show old nested HITL design
            if (!showNewNestedUI) {
              if (isShowNestedEntity(entityDetail.entities || [])) {
                return (
                  <NestedEntitiesInfoBox
                    key={entityDetail.parentEntityId}
                    task={task}
                    parentEntityId={entityDetail.parentEntityId || ''}
                    nestedType={entityDetail.parentEntityType || ''}
                    entities={entityDetail.entities || []}
                    allowDeletion={allowDeletionForParent(entityDetail)}
                    disabled={isSuggestionEntityBox}
                  />
                );
              }
            } else {
              // Show new nested HITL design
              if (isShowNestedEntity(allChildEntities)) {
                return (
                  <ParentEntitiesInfoBox
                    key={entityDetail.parentEntityId}
                    entityType={entityDetail.parentEntityType || ''}
                    entityId={entityDetail.parentEntityId || ''}
                  />
                );
              }
            }
          } else {
            if (isShowEntity(entityDetail)) {
              return (
                <EntitiesInfoBox
                  task={task}
                  key={entityDetail.id}
                  entity={entityDetail}
                  disabled={isSuggestionEntityBox}
                />
              );
            }
          }
        })
      ) : (
        <CustomTypography
          sx={{ display: 'flex', justifyContent: 'center' }}
          color='667085'
        >
          There is no entity in this tab currently
        </CustomTypography>
      )}
    </>
  );
};

export default React.memo(EntitiesInfo);
