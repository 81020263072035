import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import CustomComboButtons from '../../components/CustomComboButtons';
import CustomTypography from '../../components/CustomTypography';
import { User } from 'protos/pb/v1alpha1/user';
import { UpdateRequest } from 'protos/pb/v1alpha1/users_service';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUpdatedUserAction,
  updateUserAction,
  updateUserErrorAction,
} from '../../redux/actions/user.action';
import {
  selectedOrgInfoSelector,
  updatedUserSelector,
  updateUserErrorSelector,
} from '../../redux/selectors/user.selectors';
import { shiftFocus } from '../../utils/FocusUtils';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: User;
  openError: (error: Error) => void;
  onSuccess: (message: string) => void;
}

const DeleteUserModal: React.FC<Props> = ({
  open,
  setOpen,
  user,
  openError,
  onSuccess: openSuccess,
}) => {
  const dispatch = useDispatch();
  const updatedUser = useSelector(updatedUserSelector);
  const updateUserError = useSelector(updateUserErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [creationTriggered, setCreationTriggered] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (updateUserError) {
      openError(updateUserError);
      dispatch(updateUserErrorAction(undefined));
    }
  }, [updateUserError]);

  useEffect(() => {
    if (creationTriggered && updatedUser) {
      openSuccess('User ' + updatedUser.email + ' removed successfully');
      dispatch(setUpdatedUserAction(undefined));
      setCreationTriggered(false);
      setOpen(false);
    }
  }, [creationTriggered, updatedUser]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  // To Trap Focus within the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const boxContentElement = boxRef.current;
        if (boxContentElement) {
          const focusableElements = boxContentElement.querySelectorAll(
            'button, [tabindex]:not([tabindex="-1"])',
          );
          if (focusableElements.length > 0) {
            shiftFocus(focusableElements, event);
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (open) {
      const elm = document.getElementById('root');
      if (elm) {
        elm.removeAttribute('aria-hidden');
      }
    }
  }, [open]);

  return (
    <Dialog
      ref={boxRef}
      PaperProps={{ style: { width: '590px' } }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: '16px',
          py: '8px',
          pl: '24px',
        }}
      >
        <CustomTypography size={'16px'} weight={500}>
          Delete user from organization
        </CustomTypography>
        <IconButton
          aria-label='close'
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close fontSize={'small'} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogContent>
        <CustomTypography>
          Are you sure you want to remove {user?.email ?? ''} from your
          organization?
        </CustomTypography>
      </DialogContent>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogActions>
        <CustomComboButtons
          primaryLabel='Cancel'
          secondaryLabel='Confirm'
          secondaryDisabled={false}
          primarySx={{ marginRight: '15px' }}
          secondarySx={{ paddingX: '24px', paddingY: '4px' }}
          size={'medium'}
          onPrimaryClick={() => {
            setOpen(false);
          }}
          onSecondaryClick={() => {
            if (selectedOrgInfo) {
              const req: UpdateRequest = {};
              req.orgResourceName = selectedOrgInfo.orgResourceName;
              const u = { ...user };
              // Backend expects an empty orgInfos array to remove the user from the selected org
              u.orgInfos = [];
              req.fieldMask = ['org_infos'];
              req.user = u;
              dispatch(updateUserAction(req));
              setCreationTriggered(true);
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DeleteUserModal);
