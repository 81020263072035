import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  uiExecutionHistoryListSelector,
  uiExecutionHistoryLoadingSelector,
  uiExecutionHistoryTotalSizeSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  buildUiRequest,
  handleExecutionsRowSelectionChange,
} from '../../ExecutionHelpers';
import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import { listUiExecutionHistoryAction } from '../../../../redux/actions/taskV2.action';
import {
  getExecutionCell,
  getExecutionNameColumnWidth,
  getExecutionRanByCell,
  getExecutionStatusCell,
  getExecutionTimeCell,
  getExecutionTimeWidth,
  getExecutionWorkflowDisplayNameCell,
  handleUiExecutionPageChange,
} from './ui-table-body-helpers';
import { DEFAULT_ROWS_PER_PAGE } from 'orby-ui/src/components/table/table-utils';
import { WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';

export interface Props {
  displayNamePrefix: string;
  selectedWorkflows: Array<string>;
  uiExecutionStatusFilters: Array<string>;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const UiAutomationsTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  uiExecutionStatusFilters,
  selectedUsers,
  tableWidth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const executions: WorkflowTask[] = useSelector(
    uiExecutionHistoryListSelector,
  );

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const totalSize = useSelector(uiExecutionHistoryTotalSizeSelector) ?? 0;
  const tasksLoading = useSelector(uiExecutionHistoryLoadingSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const pagedExecutions = executions.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  /**
   * FETCH UI Executions
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listExecutions = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildUiRequest({
      navigate,
      selectedWorkflows,
      selectedUsers,
      uiExecutionStatusFilters,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName!.replace(
        'organizations/',
        '',
      ) as string,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listUiExecutionHistoryAction(req, refresh));
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(0);
    listExecutions(1, rowsPerPage, true);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [
    displayNamePrefix,
    selectedWorkflows,
    selectedUsers,
    uiExecutionStatusFilters,
  ]);

  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        cursor={'pointer'}
        isDataLoading={tasksLoading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no executions available'
        }
        colSx={[
          {
            width: `${getExecutionNameColumnWidth(tableWidth)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth)}px`,
          },
          {
            width: `${getExecutionNameColumnWidth(tableWidth)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth)}px`,
          },
          {
            width: '188px',
            maxWidth: '188px',
            minWidth: '188px',
          },
          {
            width: `${getExecutionTimeWidth(tableWidth)}px`,
            maxWidth: `${getExecutionTimeWidth(tableWidth)}px`,
            minWidth: `${getExecutionTimeWidth(tableWidth)}px`,
          },
          {
            width: `${getExecutionTimeWidth(tableWidth)}px`,
            maxWidth: `${getExecutionTimeWidth(tableWidth)}px`,
            minWidth: `${getExecutionTimeWidth(tableWidth)}px`,
          },
          {
            width: '217px',
            maxWidth: '217px',
            minWidth: '217px',
          },
        ]}
        tableId='ui-automation-table'
        showCheckBox={false}
        isCheckBoxDisabled={false}
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleUiExecutionPageChange({
              executions,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listExecutions,
            }),
          setRowsPerPage: (rows: number) =>
            handleExecutionsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listExecutions,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'execution-header-execution'}
            label='Execution'
          />,
          <OrbyHeaderLabel
            key={'execution-header-workflow'}
            label='Workflow'
          />,
          <OrbyHeaderLabel key={'execution-header-status'} label='Status' />,
          <OrbyHeaderLabel
            key={'execution-header-start-time'}
            label='Start Time'
          />,
          <OrbyHeaderLabel
            key={'execution-header-last-updated'}
            label='Last Updated'
          />,
          <OrbyHeaderLabel key={'execution-header-ran-by'} label='Ran By' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedExecutions.map((execution) => {
          return {
            title: execution.id!,
            id: execution.id!,
            row: execution,
            cells: [
              getExecutionCell(execution, displayNamePrefix),
              getExecutionWorkflowDisplayNameCell(execution),
              getExecutionStatusCell(execution),
              getExecutionTimeCell(execution, execution.discoverTime),
              getExecutionTimeCell(execution, execution.endTime),
              getExecutionRanByCell(execution),
            ],
          };
        })}
        onTableRowClick={(data) => {
          const workflowTask = data as WorkflowTask;
          navigate(`/executions/${workflowTask.id}`);
        }}
      />
    </>
  );
};

export default React.memo(UiAutomationsTab);
