import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  listTemplateWorkflowErrorSelector,
  workflowTemplatesListSelector,
  workflowTemplatesLoadingSelector,
} from '../../../redux/selectors/workflow.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListWorkflowsRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import { Workflow as OrbotWorkflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { useNavigate } from 'react-router-dom';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import {
  listWorkflowTemplateAction,
  listWorkflowTemplateErrorAction,
} from '../../../redux/actions/workflow.action';
import { getWorkflowAppsCell } from '../tabs/ApiAutomation/workflow-api-automation-helper';
import { toastService } from '../../../services/ToastService';
import { ELLIPSIS_STYLE, FEATURE_FLAGS } from '../../../utils/constants';
import AppsFilter, { APP_TYPE_OTHER } from '../components/AppsFilter';
import WorkflowTypesFilter from '../components/WorkflowTypesFilter';
// import { ReactComponent as ForwardArrow } from 'static/icons/arrow-forward.svg';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { ReactComponent as DocumentIcon } from '../../../static/icons/document.svg';
import UploadYamlComponent from '../../../components/workflow-table/UploadYaml/UploadYaml';
import { validateYamlWorkflow } from '../../../utils/yamlParserValidation';
import jsYaml from 'js-yaml';
import { ApplicationName } from '../../../utils/protos/enums';
import {
  checkActions,
  checkTriggers,
  getApplicationNames,
} from '../../../utils/helpers';
import {
  createSftpClassificationWorkflowObject,
  createSftpExtractionWorkflowObject,
} from '../../../utils/yamlToJsonParser';
import { User } from 'protos/pb/v1alpha1/user';
import backgroundLogo from '../../../static/orby-card-content.svg';
import { ListWorkflowTemplatesRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  listOrbotWorkflowsTemplateAction,
  listWorkflowsTemplateErrorAction,
} from '../../../redux/actions/workflow_task.constants';
import {
  listWorkflowTemplateErrorSelector,
  listWorkflowTemplatesSelector,
} from '../../../redux/selectors/workflow_task.selectors';
import { EXTENSION_ID, EXTENSION_URL } from 'extension/src/constants';
import CustomModal from '../../../components/CustomModal';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../../FeatureFlags/FeatureFlagUtils';

const CreateWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const workflowTemplateWrapper: React.RefObject<HTMLElement | null> =
    useRef(null);
  const user = useSelector(loggedInUserSelector);
  const loading = useSelector(workflowTemplatesLoadingSelector);
  const workflowsTemplates: Workflow[] = useSelector(
    workflowTemplatesListSelector,
  );
  const listOrbotWorkflowTemplates = useSelector(listWorkflowTemplatesSelector);
  const listOrbotWorkflowTemplateError = useSelector(
    listWorkflowTemplateErrorSelector,
  );
  const listWorkflowError = useSelector(listTemplateWorkflowErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [showExtensionInstallModal, setShowExtensionInstallModal] =
    useState(false);

  // YAML
  const [isYamlModalOpen, setIsYamlModalOpen] = useState({
    open: false,
    isEdit: false,
  });
  const [yamlFileError, setYamlFileError] = useState<string | null>(null);

  // FILTER BY APPS
  const [selectedApps, setSelectedApps] = useState<Array<string>>([]);

  // FILTER BY WORKFLOW TYPES
  const [selectedWorkflowType, setSelectedWorkflowType] = useState<string>('');

  // BUILD FETCH WORKFLOW TEMPLATES REQUEST
  const buildRequest = () => {
    const req: ListWorkflowsRequest = {};
    req.filter = 'is_template=true';
    if (selectedOrgInfo) {
      req.orgResourceName = selectedOrgInfo.orgResourceName;
    }
    if (selectedApps.length) {
      const copyOfSelectedApps = [...selectedApps];
      const index = copyOfSelectedApps.indexOf(APP_TYPE_OTHER);

      if (index !== -1) {
        copyOfSelectedApps.splice(index, 1);
      }
      if (copyOfSelectedApps.length) {
        req.filter += `,application=${copyOfSelectedApps.join('-')}`;
      }
    }
    if (selectedWorkflowType && selectedWorkflowType !== 'others') {
      req.filter += `,type=${selectedWorkflowType}`;
    }
    return req;
  };

  // BUILD REQUEST TO FETCH LIST OF ORBOT WORKFLOW TEMPLATES
  const buildOrbotRequest = () => {
    const req: ListWorkflowTemplatesRequest =
      ListWorkflowTemplatesRequest.create({
        orgId: selectedOrgInfo?.orgResourceName?.replace('organizations/', ''),
      });

    return req;
  };

  /**
   * HANDLE YAML CREATE WORKFLOW
   */
  const handleYamlCreateWorkflow = (jsonData: any) => {
    const templateResourceName = jsonData.templateResourceName;
    // Find the workflow template that matches the template resource name
    // If the workflow template is not found, then the YAML file is invalid since user doesn't have access to the workflow template
    const workflowTemplate = workflowsTemplates.find(
      (workflowTemplate) => workflowTemplate.name === templateResourceName,
    );
    if (!workflowTemplate) {
      setYamlFileError('Workflow template not found');
      return;
    }

    let parsedWorkflow = Workflow.create(workflowTemplate);

    const isSftpTrigger = checkTriggers(workflowTemplate, [
      ApplicationName.SftpServer,
    ]);
    const isClassificationGenerateOutputAction = checkActions(
      workflowTemplate,
      [ApplicationName.DocumentClassification, ApplicationName.GenerateOutput],
    );
    const isExtractionGenerateOutputAction = checkActions(workflowTemplate, [
      ApplicationName.EntityExtraction,
      ApplicationName.GenerateOutput,
    ]);
    // Check if the workflow is a classification workflow or an extraction workflow
    if (
      isSftpTrigger &&
      (isClassificationGenerateOutputAction || isExtractionGenerateOutputAction)
    ) {
      if (isClassificationGenerateOutputAction) {
        parsedWorkflow = createSftpClassificationWorkflowObject(
          parsedWorkflow,
          jsonData,
          user as User,
          true,
        );
      } else {
        parsedWorkflow = createSftpExtractionWorkflowObject(
          parsedWorkflow,
          jsonData,
          user as User,
          true,
        );
      }
      // Navigate to the create workflow page with the parsed workflow
      navigate('/workflow/create', {
        state: { workflow: parsedWorkflow, isYamlWorkflow: true },
      });
    } else {
      // Handle the case where the YAML file is invalid
      setYamlFileError('Invalid YAML file');
      return;
    }
  };

  /**
   * HANDLE YAML FILE CHANGE
   */
  const handleYamlFileChange = (file: File | undefined) => {
    setYamlFileError(null);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const yamlContent = e.target?.result as string;
          // Convert the yaml content to JSON
          const jsonData = jsYaml.load(yamlContent);
          if (jsonData) {
            /**
             * VALIDATIONS FOR YAML FILE
             */
            validateYamlWorkflow(jsonData);
            handleYamlCreateWorkflow(jsonData);
          } else {
            // Handle the case where the YAML file is invalid
            setYamlFileError('Invalid YAML file');
            return;
          }
        } catch (error: any) {
          // Handle any errors that occur during parsing
          setYamlFileError(error.message || 'Error parsing YAML');
          return;
        }
      };
      reader.readAsText(file);
    } else {
      setYamlFileError('File is required.');
      return;
    }
  };

  const handleRecord = async () => {
    // when extension is not yet installed, chrome.runtime would be undefined
    if (!chrome.runtime) {
      setShowExtensionInstallModal(true);
      return;
    }
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      { type: 'startRecording' },
      (response) => {
        // even if chrome.runtime is defined, it might not be our extension.
        // in that case, we still trigger the modal to ask user to install extension.
        if (chrome.runtime.lastError || !response) {
          setShowExtensionInstallModal(true);
        } else {
          // the recording should have started in a new window.
        }
      },
    );
  };

  const getLoaderWrapperHeight = () => {
    const wrapper = workflowTemplateWrapper.current?.getBoundingClientRect();
    if (wrapper) {
      return `${window.innerHeight - wrapper.top - 80}px`;
    } else {
      return '100%';
    }
  };

  /**
   * USE EFFECT
   */
  useEffect(() => {
    const req = buildRequest();
    const isAppTypeOther = selectedApps.includes(APP_TYPE_OTHER);
    if (!(isAppTypeOther && selectedApps.length === 1)) {
      dispatch(listWorkflowTemplateAction(req, true /* Refresh */));
    }

    const requestForOrbotList = buildOrbotRequest();
    dispatch(listOrbotWorkflowsTemplateAction(requestForOrbotList, false));
  }, [selectedOrgInfo, selectedApps, selectedWorkflowType]);

  useEffect(() => {
    if (listWorkflowError) {
      toastService.showError(listWorkflowError.message, {
        position: 'top-right',
      });
      dispatch(listWorkflowTemplateErrorAction(undefined));
    }
  }, [listWorkflowError]);

  useEffect(() => {
    if (listOrbotWorkflowTemplateError) {
      toastService.showError(listOrbotWorkflowTemplateError.message, {
        position: 'top-right',
      });
      dispatch(listWorkflowsTemplateErrorAction(undefined));
    }
  }, [listOrbotWorkflowTemplateError]);

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRecordingWorkflowEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.RECORD_WORKFLOW,
    featureFlags,
  );

  return (
    <Box paddingY={'56px'} paddingX={'78px'}>
      {/* HEADER */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        gap={'24px'}
      >
        <IconButton
          title='Back to My Workflows'
          aria-label='Go back to my workflows'
          tabIndex={0}
          onClick={() => navigate(-1)}
          sx={{
            padding: 0,
          }}
        >
          <ArrowBackIcon
            sx={{
              color: OrbyColorPalette['grey-700'],
            }}
          />
        </IconButton>
        <OrbyTypography
          size={'display-sm'}
          weight={'medium'}
          color={OrbyColorPalette['grey-900']}
        >
          Choose how to create
        </OrbyTypography>
      </Box>

      {/* Banner */}
      {isRecordingWorkflowEnabled && (
        <Link
          component='button'
          sx={{
            marginTop: '48px',
          }}
          onClick={handleRecord}
        >
          <img
            src={backgroundLogo}
            style={{ width: '100%' }}
            alt='background'
          />
        </Link>
      )}

      {/* Upload Section */}
      <Box
        sx={{
          marginTop: '16px',
          background: OrbyColorPalette['blueGrey-50'],
          height: '48px',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          padding: '12px 16px',
        }}
        gap={'8px'}
      >
        <IconButton sx={{ padding: '0' }}>
          <DocumentIcon />
        </IconButton>

        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          I have a YAML file Upload to create a workflow
        </OrbyTypography>

        <OrbyButton
          ariaLabel='Upload to create'
          variant='primary-text-flat'
          size='large'
          endIcon={<ArrowForwardRoundedIcon />}
          label='Upload to create'
          onClick={() =>
            setIsYamlModalOpen({
              open: true,
              isEdit: false,
            })
          }
        />
      </Box>

      {/* Create an app based workflow from templates */}
      <Box
        sx={{
          paddingTop: '48px',
          paddingBottom: '16px',
        }}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <OrbyTypography
          size='md'
          weight='semibold'
          color={OrbyColorPalette['grey-900']}
        >
          Create an workflow from templates
        </OrbyTypography>
      </Box>

      {/* FILTERS */}
      <Box
        sx={{
          paddingBottom: '16px',
        }}
        display={'flex'}
        gap={'16px'}
      >
        {/* WORKFLOW TYPE FILTER */}
        <WorkflowTypesFilter
          selectedWorkflowType={selectedWorkflowType}
          setSelectedWorkflowType={setSelectedWorkflowType}
          width='193px'
          menuWidth='230px'
        />
        {/* APPS FILTER */}
        <AppsFilter
          selectedApps={selectedApps}
          setSelectedApps={setSelectedApps}
          width='160px'
          menuWidth='160px'
        />
      </Box>

      {/* BODY */}

      <Box
        ref={workflowTemplateWrapper}
        display={'flex'}
        flexWrap={'wrap'}
        gap={'16px'}
      >
        {loading ? (
          <Box
            height={getLoaderWrapperHeight()}
            justifyContent={'center'}
            display={'flex'}
            width={'100%'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {listOrbotWorkflowTemplates
              ?.filter(() => {
                return (
                  (selectedWorkflowType === '' ||
                    selectedWorkflowType === 'others') &&
                  (selectedApps.length === 0 ||
                    selectedApps.includes(APP_TYPE_OTHER))
                );
              })
              .map((workflow: OrbotWorkflow) => (
                <TemplateItem
                  key={workflow.id}
                  displayName={workflow.displayName}
                  description={workflow.description}
                  applicationNames={[ApplicationName.UiAutomation]}
                  onUse={() => {
                    navigate(`/template/${workflow.id}/definition`);
                  }}
                />
              ))}

            {selectedWorkflowType !== 'others' &&
              !(
                selectedApps.length === 1 &&
                selectedApps.includes(APP_TYPE_OTHER)
              ) &&
              workflowsTemplates.map((workflow) => (
                <TemplateItem
                  key={workflow.name}
                  displayName={workflow.displayName?.split(':')[1]}
                  description={workflow.description}
                  applicationNames={getApplicationNames(workflow)}
                  onUse={() => {
                    navigate('/workflow/create', {
                      state: { workflow: workflow },
                    });
                  }}
                />
              ))}
          </>
        )}
      </Box>

      <UploadYamlComponent
        yamlFileError={yamlFileError}
        handleFileChange={handleYamlFileChange}
        open={isYamlModalOpen.open}
        isEdit={isYamlModalOpen.isEdit}
        setYamlFileError={setYamlFileError}
        setOpen={(open) => {
          setIsYamlModalOpen({
            open: open,
            isEdit: false,
          });
          setYamlFileError(null);
        }}
      />

      {showExtensionInstallModal && (
        <ExtensionInstallModal
          onClose={() => setShowExtensionInstallModal(false)}
        />
      )}
    </Box>
  );
};

function TemplateItem(props: {
  displayName?: string;
  description?: string;
  applicationNames: string[];
  onUse: () => void;
}) {
  return (
    <Box
      tabIndex={0}
      sx={{
        border: `1px solid ${OrbyColorPalette['grey-200']}`,
        boxShadow: `0px 1px 2px 0px #0000000A`,
        borderRadius: '12px',
        padding: '24px 16px',
        width: 'calc(33.33% - 10.67px)', // Adjusted width to fit 3 in a row with gap
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: OrbyColorPalette['purple-25'],
          outline: OrbyColorPalette['purple-300'],
        },
        '&:focus': {
          backgroundColor: OrbyColorPalette['purple-50'],
          outline: OrbyColorPalette['purple-300'],
        },
      }}
      role='button'
      onClick={() => {
        props.onUse();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          props.onUse();
        }
      }}
    >
      <Box>{getWorkflowAppsCell(props.applicationNames, '40px', '28px')}</Box>

      <Box marginBottom={'12px'} marginTop={'24px'} title={props.displayName}>
        <OrbyTypography
          size='md'
          sx={{
            ...ELLIPSIS_STYLE,
            maxWidth: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1, // max no of lines
            wordBreak: 'break-word',
            textWrap: 'wrap',
          }}
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          {props.displayName}
        </OrbyTypography>
      </Box>

      <Box
        sx={{
          minHeight: '60px',
        }}
        title={props.description}
      >
        <OrbyTypography
          color={OrbyColorPalette['grey-500']}
          sx={{
            ...ELLIPSIS_STYLE,
            maxWidth: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3, // max no of lines
            wordBreak: 'break-word',
            textWrap: 'wrap',
          }}
        >
          {props.description}
        </OrbyTypography>
      </Box>

      {/* USE IT BUTTON */}
      <Box marginTop={'18px'} display={'flex'} justifyContent={'flex-end'}>
        <OrbyButton
          variant='primary-outline'
          ariaLabel='Use it'
          label='Use it'
        />
      </Box>
    </Box>
  );
}

function ExtensionInstallModal(props: { onClose: () => void }) {
  return (
    <CustomModal
      open={true}
      closable={false}
      heading={`Install "Orby AI" extension`}
      containerSx={{ maxWidth: '400px' }}
      primaryLabel={'Go to install'}
      secondaryLabel={'Cancel'}
      handleClose={props.onClose}
      onPrimaryClick={() => {
        // open extension page in a new tab
        window.open(EXTENSION_URL, '_blank')!.focus();
        props.onClose();
      }}
      primaryButtonSx={{ maxWidth: '50%' }}
      content={
        <Box>
          <OrbyTypography>
            Please install the &ldquo;Orby AI&rdquo; extension before starting.
          </OrbyTypography>
          <OrbyTypography sx={{ mt: 2 }}>
            Once activated, the extraction can automatically record your
            operations to generate a workflow.
          </OrbyTypography>
        </Box>
      }
    />
  );
}

export default React.memo(CreateWorkflow);
