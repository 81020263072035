import { Box, IconButton } from '@mui/material';
import { OrbyBadge, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrowIcon } from '../../../../../../static/icons/back-arrow.svg';
import { WorkflowTaskStatusEnum } from '../../ui-table-body-helpers';

interface Props {
  title: string;
  description: string;
  status?: number;
}

const Header: React.FC<Props> = ({ title, description, status = 0 }) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    // if there is history that can go -1, go -1, otherwise go to home
    if (!history.state || history.state.idx === 0) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      display={'flex'}
      paddingTop={'32px'}
      paddingLeft={'78px'}
      paddingBottom={'30px'}
      alignItems={'center'}
      height={116}
      gap={'55px'}
    >
      <IconButton onClick={navigateBack} aria-label='Go Back'>
        <BackArrowIcon />
      </IconButton>

      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'16px'}
        alignItems={'baseline'}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <OrbyTypography size={'display-xs'} weight={'semibold'}>
            {title}
          </OrbyTypography>

          <OrbyTypography color={OrbyColorPalette['grey-600']}>
            {description}
          </OrbyTypography>
        </Box>

        <OrbyBadge
          size='large'
          badgeName={WorkflowTaskStatusEnum?.[status!]?.label}
          backgroundColor={WorkflowTaskStatusEnum?.[status!]?.backgroundColor}
          textColor={WorkflowTaskStatusEnum?.[status!]?.color}
        />
      </Box>
    </Box>
  );
};

export default Header;
