import React from 'react';
import OrbyColorPalette from '../../colors/ColorPalette';
import OrbyTypography from '../../typography/OrbyTypography';
import { Box } from '@mui/material';

interface OrbyMenuItemContentProps {
  /**
   * Primary label to be displayed
   */
  title: string;
  /**
   * Icon/Image to be displayed as prefix
   */
  icon?: JSX.Element;
  /**
   * Secondary label to be displayed on menu item
   */
  description?: string;
  /**
   * If the menu item is selected
   */
  isSelected?: boolean;
}
const OrbyMenuItemContent: React.FC<OrbyMenuItemContentProps> = ({
  title,
  icon,
  description,
  isSelected = false,
}) => {
  return (
    <Box
      width={isSelected ? '80%' : '100%'}
      display={'flex'}
      gap={1}
      alignItems={'center'}
    >
      {icon && (
        <Box
          width={'24px'}
          height={'24px'}
          display={'flex'}
          alignItems={'center'}
        >
          {icon}
        </Box>
      )}
      <OrbyTypography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        color={OrbyColorPalette['grey-900']}
        size='md'
        weight='medium'
      >
        {title}
      </OrbyTypography>
      <OrbyTypography
        color={OrbyColorPalette['grey-500']}
        size='md'
        weight='regular'
      >
        {description}
      </OrbyTypography>
    </Box>
  );
};

export default React.memo(OrbyMenuItemContent);
