import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import React, { useEffect } from 'react';
import { EntityInfo } from '../../../../../redux/reducers/review_task.reducer';
import {
  closeFloatingModal,
  isEntityNeedAttention,
  isEntityPredicted,
  isEntityReviewed,
} from '../../../../../utils/ReviewTaskUtils';

interface Props {
  entity: EntityInfo;
}

const FloatingModalHeader: React.FC<Props> = ({ entity }) => {
  const getColorBasedOnEntityStatus = () => {
    if (isEntityReviewed(entity)) return '#0F8470';
    else if (isEntityPredicted(entity)) return '#B2DDFF';
    else if (isEntityNeedAttention(entity)) return '#DD6A6A';
  };
  useEffect(() => {
    // Add an event listener for the ESC key
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeFloatingModal(entity);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleEscKey);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  return (
    <>
      <Box
        id={'floating-modal-header'}
        bgcolor={'#EAECF0'}
        display={'flex'}
        flexDirection={'column'}
        sx={{
          borderRadius: '4px 4px 0 0',
          cursor: 'grab',
          ':active': { cursor: 'grabbing' },
        }}
        position={'relative'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          gap={'12px'}
          width={'100%'}
          p={'20px 16px 20px 16px'}
        >
          <Box
            display={'flex'}
            gap={'12px'}
            alignItems={'center'}
            width={'calc(100% - 50px)'}
          >
            <Box
              width={'6px'}
              flexShrink={0}
              height={'28px'}
              borderRadius={'50px'}
              bgcolor={getColorBasedOnEntityStatus()}
            />
            <CustomTypography
              typographyType={TypographyType.Header5}
              sx={{
                overflowWrap: 'break-word',
                width: '100%',
              }}
              size={'18px'}
            >
              {entity.type}
            </CustomTypography>
          </Box>
          <IconButton
            aria-label='Close and discard all changes'
            title='Close and discard all changes'
            sx={{
              alignSelf: 'end',
            }}
            onClick={() => closeFloatingModal(entity)}
          >
            <Close sx={{ fontSize: '18px', color: '#475467' }} />
          </IconButton>
        </Box>
        <CustomTypography
          id={'floating-modal-header-warning-text'}
          sx={{
            overflowWrap: 'break-word',
            display: 'none', // this would be dynamically set
            color: '#DC6803',
            px: '16px',
            marginTop: '-16px',
            pb: '20px',
          }}
          size={'14px'}
        >
          Please confirm to save your changes
        </CustomTypography>
      </Box>
    </>
  );
};

export default React.memo(FloatingModalHeader);
