/**
 * We are keeping the functions that are relevant to finding the actual action out of the action group in this file
 */
import { ActionGroupObject, ActionId, PreparedActionObject } from './types';

export const containClickAction = (
  actions: PreparedActionObject[] | undefined,
) => {
  return actions!.filter((a) => a?.clickAction).length > 0;
};

export const containFlagKeywordsAction = (
  actions: PreparedActionObject[] | undefined,
) => {
  return actions!.filter((a) => a?.flagKeywordsAction).length > 0;
};

export const containExtractFieldsAction = (
  actions: PreparedActionObject[] | undefined,
) => {
  return actions!.filter((a) => a?.extractFieldsAction).length > 0;
};

export const containSetValueAction = (actions: PreparedActionObject[]) => {
  return actions.filter((a) => a?.setValueAction).length > 0;
};

export const containGotoAction = (actions: PreparedActionObject[]) => {
  return actions.filter((a) => a?.gotoAction).length > 0;
};

export const containSetSecretValueAction = (
  actions: PreparedActionObject[],
) => {
  return (
    actions.filter((a) => a?.setValueAction?.fieldValue?.secretValue).length > 0
  );
};

export const containForeachAction = (
  actions: PreparedActionObject[] | undefined,
) => {
  return actions!.filter((a) => a?.foreachAction).length > 0;
};

export const containConditionAction = (
  actions: PreparedActionObject[] | undefined,
) => {
  return actions!.filter((a) => a?.conditionAction).length > 0;
};

export const containSmartAction = (
  actions: PreparedActionObject[],
): boolean => {
  let result = false;
  actions.forEach((a) => {
    if (
      a.flagKeywordsAction ||
      a.detectDuplicateLineItemsAction ||
      a.validateAction
    ) {
      result = true;
    }
  });
  return result;
};

export const containControlFlow = (
  actions: PreparedActionObject[],
): boolean => {
  return containForeachAction(actions) || containConditionAction(actions);
};

export const hasDeteDuplicateLineItems = (
  actions: PreparedActionObject[],
): boolean => {
  if (!actions?.length) return false;
  return actions.filter((a) => a.detectDuplicateLineItemsAction).length > 0;
};

export const hasFlagKeywords = (actions: PreparedActionObject[]): boolean => {
  return actions.filter((a) => a.flagKeywordsAction).length > 0;
};

export const containCreateTaskAction = (actions: PreparedActionObject[]) => {
  return actions.filter((a) => a?.createTaskAction).length > 0;
};

export const isAnyChildActionInSet = (
  actionGroup: ActionGroupObject,
  set: Set<ActionId>,
): boolean => {
  const actions = actionGroup.preparedActions;
  if (!actions) {
    return false;
  }
  for (const action of actions) {
    const uuid = action.uuid;
    if (uuid && set.has(uuid)) {
      return true;
    }
    if (action.conditionAction) {
      return !!action.conditionAction.trueActions?.some((ag) =>
        isAnyChildActionInSet(ag, set),
      );
    }
    if (action.foreachAction) {
      return !!action.foreachAction.actions?.some((ag) =>
        isAnyChildActionInSet(ag, set),
      );
    }
  }
  return false;
};
