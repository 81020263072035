import React from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import {
  ListWorkflowsRequest,
  Workflow,
  WorkflowMode,
} from 'protos/pb/v1alpha2/workflows_service';

import {
  OrbyBadge,
  OrbyBodyLabel,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import UserCard from '../../../../components/UserCard';
import {
  checkActions,
  checkTriggers,
  formatDateTime,
  getDateRange,
} from '../../../../utils/helpers';
import { NavigateFunction } from 'react-router-dom';
import {
  DISPLAY_NAME_PREFIX,
  DateOptionValues,
  DateRange,
  ELLIPSIS_STYLE,
  END_DATE,
  NAME_PREFIX,
  PERIOD,
  SELECTED_TAB,
  START_DATE,
  USERNAMES,
  WORKFLOW_APPS,
  WORKFLOW_MODES,
} from '../../../../utils/constants';
import { getLogoByName } from '../../../../components/workflow-table/helper';
import { ApplicationName } from '../../../../utils/protos/enums';
import { parseWorkflowProtoToJson } from '../../../../utils/jsonToYamlParser';
import {
  createSftpClassificationWorkflowObject,
  createSftpExtractionWorkflowObject,
} from '../../../../utils/yamlToJsonParser';
import { User } from 'protos/pb/v1alpha1/user';
import { setSelectedWorkflowAction } from '../../../../redux/actions/workflow.action';
import { getIdFromResourceName } from '../../../../utils/WorkflowUtils';
import { toastService } from '../../../../services/ToastService';
import {
  getUsersJoinedByColon,
  getWorkflowIdsJoinedByDashV2,
} from '../../../Utils/taskV2Utils';

export const buildRequest = ({
  status,
  navigate,
  displayNamePrefix,
  orgResourceName,
  selectedUsers,
  selectedApps,
  selectedModes,
  selectedTime,
  customRange,
  isWorkflowAdmin /* ONLY LIST WORKFLOWS THAT USER IS ADMIN OF */,
}: {
  status: string;
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  selectedApps: string[];
  selectedUsers: string[];
  selectedModes: string[];
  selectedTime: string;
  customRange: DateRange;
  isWorkflowAdmin: boolean;
}) => {
  const req: ListWorkflowsRequest = ListWorkflowsRequest.create({
    filter: getFilters(
      navigate,
      status,
      displayNamePrefix,
      selectedApps,
      selectedUsers,
      selectedModes,
      selectedTime,
      customRange,
      isWorkflowAdmin,
    ),
    orgResourceName: orgResourceName,
  });
  return req;
};

export const getFilters = (
  navigate: NavigateFunction,
  status: string,
  displayNamePrefix: string,
  selectedApps: string[],
  selectedUsers: string[],
  selectedModes: string[],
  selectedTime: string,
  customRange: DateRange,
  isWorkflowAdmin: boolean,
) => {
  let filter = status;
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // SEARCH FILTER
  if (displayNamePrefix) {
    filter += `,${NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  // APPS FILTER
  if (selectedApps.length) {
    filter += `,application=${selectedApps.join('-')}`;
    searchParams.append(WORKFLOW_APPS, selectedApps.join('-'));
  }

  // MODES FILTER
  if (selectedModes.length) {
    filter += `,mode=${selectedModes.join('-')}`;
    searchParams.append(WORKFLOW_MODES, selectedModes.join('-'));
  }

  // TIME FILTER
  if (selectedTime) {
    const dateRange = getDateRange(selectedTime, customRange);
    if (dateRange) {
      filter += `,last_update_time_lt=${dateRange.endDate},last_update_time_gt=${dateRange.startDate}`;
      searchParams.append(PERIOD, selectedTime);
    }

    if (selectedTime === DateOptionValues.CUSTOM_RANGE) {
      searchParams.append(
        START_DATE,
        customRange.startDate.format('MM/DD/YYYY'),
      );
      searchParams.append(END_DATE, customRange.endDate.format('MM/DD/YYYY'));
    }
  }

  // USERS FILTER
  if (selectedUsers.length) {
    filter += `,user=${getUsersJoinedByColon(selectedUsers)}`;
    searchParams.append(
      USERNAMES,
      getWorkflowIdsJoinedByDashV2(selectedUsers) || '',
    );
  }

  // ONLY LIST WORKFLOWS THAT USER IS ADMIN OF
  if (isWorkflowAdmin) {
    filter += `,is_workflow_admin=true`;
  }

  // GET THE TAB
  const selectedTab = existingParams.get(SELECTED_TAB) || 0;
  navigate({
    search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
  });
  return filter;
};

export const getWorkflowNameCell = (
  workflow: Workflow,
  displayNamePrefix: string,
): JSX.Element => {
  const name = workflow.displayName || '';
  const startIndex = name
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={name}
      key={name}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {name.substring(startIndex, endIndex)}
          </span>
          {name.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getWorkflowAppsCell = (
  applicationNames: string[],
  width = '24px',
  imageWidth = '12.8px',
): JSX.Element => {
  return (
    <Box display={'flex'} flexWrap='wrap'>
      {applicationNames.map((app: string, index) => {
        const logo = getLogoByName(app);
        if (logo) {
          return (
            <React.Fragment key={app}>
              {/* IF INDEX IS ODD (1,3,5 etc) THEN PRINT BELOW*/}
              {index % 2 === 0 && index > 1 && (
                <Box
                  marginLeft={'13px'}
                  marginRight={'15px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <ArrowForwardIosRoundedIcon
                    sx={{
                      color: OrbyColorPalette['grey-400'],
                      fontSize: '10px',
                    }}
                  />
                </Box>
              )}

              <Box
                width={width}
                height={width}
                key={logo}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  border: `1px solid ${OrbyColorPalette['grey-200']}`,
                  borderRadius: '100px',
                }}
              >
                <img
                  src={logo}
                  title={app}
                  alt={app}
                  width={imageWidth}
                  height={imageWidth}
                />
              </Box>
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

export const getWorkflowModeName = (mode: WorkflowMode) => {
  if (mode === WorkflowMode.DEFAULT) {
    return 'Active Review';
  } else if (mode === WorkflowMode.AUTOPILOT) {
    return 'Auto-pilot';
  } else if (mode === WorkflowMode.ASSISTED) {
    return 'Assisted';
  }
};

export const getWorkflowModeCell = (workflow: Workflow): JSX.Element => {
  return (
    <OrbyBadge
      key={workflow.name}
      backgroundColor={OrbyColorPalette['purple-50']}
      textColor={OrbyColorPalette['purple-700']}
      badgeName={getWorkflowModeName(workflow.mode!)}
      size='small'
      textSx={ELLIPSIS_STYLE}
    />
  );
};

export const getWorkflowCreatorCell = (workflow: Workflow): JSX.Element => {
  return (
    <UserCard
      email={workflow?.creatorEmail ?? ''}
      fullName={workflow.creator?.fullName}
      imageUrl={workflow.creator?.imageUrl}
    />
  );
};

export const getWorkflowTimeCell = (workflow: Workflow): JSX.Element => {
  if (workflow.lastModifiedTime || workflow.createTime) {
    const { date, time } = formatDateTime(
      workflow.lastModifiedTime ?? workflow.createTime,
    );
    return (
      <Box key={workflow.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={workflow.name}
        label={'--'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getWorkflowActionMenuCell = (
  workflow: Workflow,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    workflow: Workflow,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={workflow.name}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, workflow);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const WORKFLOW_ACTION_MENU_ITEM = {
  DELETE: 'Delete',
  EDIT: 'Edit',
  COPY: 'Copy',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  SHARE: 'Share',
};

export const isSFTPToJsonWorkflow = (workflow: Workflow) => {
  const sftpIndex = checkTriggers(workflow, [ApplicationName.SftpServer]);
  const generateOutputIndex = checkActions(workflow, [
    ApplicationName.GenerateOutput,
    ApplicationName.EntityExtraction,
    ApplicationName.DocumentClassification,
  ]);
  if (sftpIndex && generateOutputIndex) {
    return true;
  }
  return false;
};

export const getWorkflowActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  isShowYamlConfig: boolean,
  isShowShareWorkflow: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* EDIT */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.EDIT);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Edit
        </OrbyTypography>
      </MenuItem>
      {/* DELETE */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.DELETE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Delete
        </OrbyTypography>
      </MenuItem>
      {/* COPY */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.COPY);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Copy task
        </OrbyTypography>
      </MenuItem>

      {isShowYamlConfig && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.UPLOAD);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Upload
          </OrbyTypography>
        </MenuItem>
      )}

      {isShowYamlConfig && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.DOWNLOAD);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Download Configuration
          </OrbyTypography>
        </MenuItem>
      )}

      {isShowShareWorkflow && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, WORKFLOW_ACTION_MENU_ITEM.SHARE);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Share
          </OrbyTypography>
        </MenuItem>
      )}
    </Menu>
  );
};

/**
 * Handle Workflow Page Change
 */
export const handleWorkflowPageChange = ({
  workflows,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listWorkflows,
}: {
  workflows: Workflow[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && workflows.length <= pageNumber * rowsPerPage) {
    listWorkflows(pageNumber + 1, rowsPerPage, false);
  }
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleWorkflowsRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listWorkflows,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(0);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listWorkflows(1, rowsNumber, true);
};

export const getWorkflowNameColumnWidth = (tableWidth: number) => {
  // Table Width - Apps Cell Width - Workflow Mode Cell Width - Creator Cell Width - Workflow Mode Cell Width - Workflow Action Cell Width
  return tableWidth - 174 - 142 - 217 - getWorkflowTimeWidth(tableWidth) - 69;
};

export const getWorkflowTimeWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};

/**
 * Download the workflow as a YAML file
 */
export const downloadYamlFile = (workflow: Workflow) => {
  const yamlString = parseWorkflowProtoToJson(workflow);
  const element = document.createElement('a');
  const file = new Blob([yamlString], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = workflow.displayName + '.yaml';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
};

/**
 * HANDLE YAML EDIT
 */
export const handleYamlEditWorkflow = ({
  jsonData,
  selectedWorkflow,
  setYamlFileError,
  user,
  dispatch,
  navigate,
}: {
  jsonData: any;
  selectedWorkflow: Workflow;
  setYamlFileError: React.Dispatch<React.SetStateAction<string | null>>;
  user: User;
  dispatch: React.Dispatch<any>;
  navigate: NavigateFunction;
}) => {
  let parsedWorkflow = Workflow.create(selectedWorkflow);
  // Check if the workflow template name in the YAML file matches the selected workflow template name
  // If the template name doesn't match, then the user is trying to edit a different workflow
  if (jsonData.templateResourceName != selectedWorkflow.templateResourceName) {
    setYamlFileError("Workflow template doesn't match");
    return;
  }
  // Check if the workflow display name in the YAML file matches the selected workflow display name
  if (jsonData?.displayName != selectedWorkflow?.displayName) {
    setYamlFileError("Workflow display name doesn't match");
    return;
  }
  const isSftpTrigger = checkTriggers(selectedWorkflow, [
    ApplicationName.SftpServer,
  ]);
  const isClassificationGenerateOutputAction = checkActions(selectedWorkflow, [
    ApplicationName.DocumentClassification,
    ApplicationName.GenerateOutput,
  ]);
  const isExtractionGenerateOutputAction = checkActions(selectedWorkflow, [
    ApplicationName.EntityExtraction,
    ApplicationName.GenerateOutput,
  ]);
  // Check if the workflow is a classification workflow or an extraction workflow
  if (
    isSftpTrigger &&
    (isClassificationGenerateOutputAction || isExtractionGenerateOutputAction)
  ) {
    try {
      if (isClassificationGenerateOutputAction) {
        parsedWorkflow = createSftpClassificationWorkflowObject(
          parsedWorkflow,
          jsonData,
          user,
        );
      } else {
        parsedWorkflow = createSftpExtractionWorkflowObject(
          parsedWorkflow,
          jsonData,
          user,
        );
      }
    } catch (error: any) {
      setYamlFileError(`${error.message}`);
      return;
    }
    // Navigate to the update workflow page with the parsed workflow
    dispatch(setSelectedWorkflowAction(parsedWorkflow as Workflow));
    const id = getIdFromResourceName(parsedWorkflow?.name as string);
    if (id) {
      navigate(`/workflow/${id}/update`, {
        state: { isYamlWorkflow: true },
      });
    } else {
      toastService.showError('Something went wrong', {
        position: 'top-right',
      });
    }
  } else {
    // Handle the case where the YAML file is invalid
    setYamlFileError('Invalid YAML file');
    return;
  }
};
