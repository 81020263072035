import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { notification } from 'antd';
import CustomTypography, {
  TypographyType,
} from '../../components/CustomTypography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  processFeatureFlagErrorSelector,
  processingFeatureFlagSelector,
  selectedFeatureFlagErrorSelector,
  selectedFeatureFlagLoadingSelector,
  selectedFeatureFlagSelector,
  updatedFeatureFlagSelector,
} from '../../redux/selectors/feature_flags.selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../components/CustomTextField';
import {
  DeleteFeatureFlagRequest,
  FeatureFlag,
  Rule,
  UpdateFeatureFlagRequest,
  GetFeatureFlagRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import {
  deleteFeatureFlagAction,
  getFeatureFlagAction,
  updateFeatureFlagAction,
} from '../../redux/actions/feature_flags.action';
import { FeatureFlagFormValues } from './FeatureFlagUtils';
import CustomButton from '../../components/CustomButton';
import FeatureFlagDeletionModal from './FeatureFlagDeletionModal';
import { InfoRounded } from '@mui/icons-material';

const FeatureFlagUpdatePage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const processingFeatureFlag = useSelector(processingFeatureFlagSelector);
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const processFeatureFlagError = useSelector(processFeatureFlagErrorSelector);
  const updatedFeatureFlag = useSelector(updatedFeatureFlagSelector);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [deletionTriggered, setDeletionTriggered] = useState(false);
  const location = useLocation();
  const paths = location.pathname.split('/');
  const featureFlagId = paths[paths.length - 2];
  const featureFlag: FeatureFlag | undefined = useSelector(
    selectedFeatureFlagSelector,
  );
  const featureFlagLoading = useSelector(selectedFeatureFlagLoadingSelector);
  const featureFlagLoadingError = useSelector(selectedFeatureFlagErrorSelector);

  const openSuccess = (msg: string) => {
    api.success({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  const openError = (msg: string) => {
    api.error({
      message: 'Notification',
      description: msg,
      placement: 'topRight',
      duration: null,
    });
  };

  useEffect(() => {
    if (!featureFlag) {
      const req = GetFeatureFlagRequest.create({
        id: featureFlagId,
      });
      dispatch(getFeatureFlagAction(req));
    }
  }, []);

  useEffect(() => {
    if (featureFlagLoadingError) {
      openError(featureFlagLoadingError.message);
      navigate(-1);
    }
  }, [featureFlagLoadingError]);

  useEffect(() => {
    if (processFeatureFlagError && updateTriggered) {
      openError(processFeatureFlagError.message);
    }
  }, [processFeatureFlagError]);

  useEffect(() => {
    return () => {
      setUpdateTriggered(false);
      setDeletionTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (updatedFeatureFlag && updateTriggered) {
      openSuccess('Feature flag is updated successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [updatedFeatureFlag]);

  useEffect(() => {
    if (deletionTriggered && !processingFeatureFlag) {
      openSuccess('Feature flag is deleted successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [deletionTriggered, processingFeatureFlag]);

  const onSubmit = (values: FeatureFlagFormValues) => {
    const req = UpdateFeatureFlagRequest.create({
      id: featureFlag?.id,
      name: values.name,
      description: values.description,
      rule: Rule.create({
        enabled: values.enabled,
        enabledUsers: values.enabledUsers,
        blockedUsers: values.blockedUsers,
        enabledOrgs: values.enabledOrgs,
        blockedOrgs: values.blockedOrgs,
        enabledWorkflows: values.enabledWorkflows,
        blockedWorkflows: values.blockedWorkflows,
      }),
    });
    dispatch(updateFeatureFlagAction(req));
    setUpdateTriggered(true);
  };

  const openDeleteModal = () => {
    setIsDeletionModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
  };

  const handleDelete = () => {
    const req = DeleteFeatureFlagRequest.create({
      id: featureFlag?.id,
    });
    dispatch(deleteFeatureFlagAction(req));
    setDeletionTriggered(true);
  };

  const formik = useFormik({
    initialValues: {
      name: featureFlag?.name || '',
      description: featureFlag?.description || '',
      enabled: featureFlag?.rule?.enabled || false,
      enabledUsers: featureFlag?.rule?.enabledUsers || [],
      blockedUsers: featureFlag?.rule?.blockedUsers || [],
      enabledOrgs: featureFlag?.rule?.enabledOrgs || [],
      blockedOrgs: featureFlag?.rule?.blockedOrgs || [],
      enabledWorkflows: featureFlag?.rule?.enabledWorkflows || [],
      blockedWorkflows: featureFlag?.rule?.blockedWorkflows || [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Feature flag name is required.')
        .max(100, 'Feature flag name must be at most 100 characters.'),
    }),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Box
      bgcolor={'#F6F8FC'}
      paddingX={'50px'}
      paddingTop={'50px'}
      width={'100%'}
    >
      {contextHolder}
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Update Feature Flag
        <Tooltip
          title={
            <span>
              More info refer to feature flag{' '}
              <Link to='https://go/feature-flag'>guidance</Link>{' '}
            </span>
          }
        >
          <IconButton>
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </CustomTypography>
      {featureFlagLoading || processingFeatureFlag ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form
              id='feature-flag-create-form'
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
            >
              <Box
                display={'flex'}
                gap={'50px'}
                justifyContent={'space-between'}
              >
                <Box width={'70%'} marginBottom={'15px'}>
                  <Box marginLeft={'14px'} marginTop={'21px'}>
                    <CustomTextField
                      label='Feature Flag Name'
                      name='name'
                      size='small'
                      value={values.name}
                      extraProps={getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      hasDescription={true}
                      disabled
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <CustomTextField
                      label='Description'
                      name='description'
                      size='small'
                      value={values.description}
                      extraProps={getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Typography>
                        <Tooltip
                          title={
                            <div>
                              <b>Default feature flag status</b>. if default
                              status is true, the feature flag is enabled for
                              all users unless the user or org is in the blocked
                              list; if default status is false, the feature flag
                              is disabled for all users unless the user or org
                              is in the enabled list
                            </div>
                          }
                          arrow
                        >
                          <span>Disabled</span>
                        </Tooltip>
                      </Typography>
                      <Switch
                        color='primary'
                        checked={values.enabled}
                        {...getFieldProps('enabled')}
                      />
                      <Typography>Enabled</Typography>
                    </Stack>
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='enabledUsers'
                      options={[]}
                      value={values.enabledUsers}
                      onChange={(e, value) =>
                        setFieldValue('enabledUsers', value)
                      }
                      freeSolo
                      disabled={values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of usernames (like email, xxx@orbby.ai) to enable. Add username by typing and press enter after finishing each username.'
                              arrow
                              placement='top'
                            >
                              <span>Enabled Users</span>
                            </Tooltip>
                          }
                          placeholder='Add enabled usernames'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='enabledOrgs'
                      options={[]}
                      value={values.enabledOrgs}
                      onChange={(e, value) =>
                        setFieldValue('enabledOrgs', value)
                      }
                      freeSolo
                      disabled={values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of organization ids (pure object ids, like 6150ccc742d39feae9fc640g) to enable. Add organization id by typing and press enter after finishing each organization id.'
                              arrow
                              placement='top'
                            >
                              <span>Enabled Organizations</span>
                            </Tooltip>
                          }
                          placeholder='Add enabled organization ids'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='enabledWorkflows'
                      options={[]}
                      value={values.enabledWorkflows}
                      onChange={(e, value) =>
                        setFieldValue('enabledWorkflows', value)
                      }
                      freeSolo
                      disabled={values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of workflow ids (pure object ids, like 6150ccc742d39feae9fc640g) to enable. Add workflow id by typing and press enter after finishing each workflow id.'
                              arrow
                              placement='top'
                            >
                              <span>Enabled Workflows</span>
                            </Tooltip>
                          }
                          placeholder='Add enabled workflow ids'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='blockedUsers'
                      options={[]}
                      value={values.blockedUsers}
                      onChange={(e, value) =>
                        setFieldValue('blockedUsers', value)
                      }
                      freeSolo
                      disabled={!values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of usernames (like email, xxx@orbby.ai) to block. Add username by typing and press enter after finishing each username.'
                              arrow
                              placement='top'
                            >
                              <span>Blocked Users</span>
                            </Tooltip>
                          }
                          placeholder='Add blocked usernames'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='blockedOrgs'
                      options={[]}
                      value={values.blockedOrgs}
                      onChange={(e, value) =>
                        setFieldValue('blockedOrgs', value)
                      }
                      freeSolo
                      disabled={!values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of organization ids (pure object ids, like 6150ccc742d39feae9fc640g) to block. Add organization id by typing and press enter after finishing each organization id.'
                              arrow
                              placement='top'
                            >
                              <span>Blocked Organizations</span>
                            </Tooltip>
                          }
                          placeholder='Add blocked organization ids'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                  <Box marginLeft={'14px'} marginTop={'11px'}>
                    <Autocomplete
                      multiple
                      id='blockedWorkflows'
                      options={[]}
                      value={values.blockedWorkflows}
                      onChange={(e, value) =>
                        setFieldValue('blockedWorkflows', value)
                      }
                      freeSolo
                      disabled={!values.enabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={
                            <Tooltip
                              title='Add exception list of workflow ids (pure object ids, like 6150ccc742d39feae9fc640g) to block. Add workflow id by typing and press enter after finishing each workflow id.'
                              arrow
                              placement='top'
                            >
                              <span>Blocked Workflows</span>
                            </Tooltip>
                          }
                          placeholder='Add blocked workflow ids'
                          sx={{ width: '359px' }}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                          />
                        ));
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
          <Box
            paddingBottom={'20px'}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '25px',
            }}
          >
            <CustomButton
              variant='outlined'
              onClick={() => navigate(-1)}
              disabled={processingFeatureFlag}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant='outlined'
              onClick={openDeleteModal}
              disabled={processingFeatureFlag}
            >
              Delete
            </CustomButton>
            <CustomButton
              form={'feature-flag-create-form'}
              type='submit'
              disabled={processingFeatureFlag}
              loading={processingFeatureFlag}
            >
              Submit
            </CustomButton>
          </Box>
          <FeatureFlagDeletionModal
            open={isDeletionModalOpen}
            handleClose={handleCloseModal}
            onSubmit={handleDelete}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(FeatureFlagUpdatePage);
