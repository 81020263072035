import React, { ElementType } from 'react';
import { Box } from '@mui/material';
import '@fontsource/inter';
interface Props {
  children: any;
  sx?: any;
  component?: ElementType<any>;
}
export const Header1: React.FC<Props> = ({ children, sx, component }) => (
  <Box
    component={component}
    sx={{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
      display: 'inline-block',
      alignItems: 'center',
      letterSpacing: '0.04em',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const Header2: React.FC<Props> = ({ children, sx, component }) => (
  <Box
    component={component}
    sx={{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '28px',
      display: 'inline-block',
      alignItems: 'center',
      letterSpacing: '0.04em',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const Header4: React.FC<Props> = ({ children, sx, component }) => (
  <Box
    component={component}
    sx={{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      display: 'inline-block',
      alignItems: 'center',
      letterSpacing: '0.04em',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const Paragraph: React.FC<Props> = ({ children, sx }) => (
  <Box
    sx={{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'inline-block',
      alignItems: 'center',
      ...sx,
    }}
  >
    {children}
  </Box>
);
