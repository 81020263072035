import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@mui/material';
import {
  FieldArray,
  Form,
  FormikProvider,
  FormikValues,
  useFormik,
} from 'formik';
import {
  ReviewTriggerCondition,
  Workflow,
  WorkflowLearningSettingsReviewer,
  WorkflowMode,
  WorkflowUser,
} from 'protos/pb/v1alpha2/workflows_service';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import CustomTypography from '../../../components/CustomTypography';
import * as Yup from 'yup';
import './step.css';
import { DeleteOutlineRounded, SettingsOutlined } from '@mui/icons-material';
import AddUserReviewMode from './AddUserReviewMode';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import CloseIcon from '@mui/icons-material/Close';
import AssistedArrow from '../../../static/icons/assisted-arrow.svg';
import {
  getActionIndex,
  getAttributeDataType,
  getAttributeFilters,
  getClassificationLabels,
} from '../../../utils/helpers';
import { ApplicationName } from '../../../utils/protos/enums';
import { ConditionType, Operator } from 'protos/pb/v1alpha2/connector';
import {
  AssignmentMode,
  CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
  classificationOperatorValues,
  extractionOperators,
  FEATURE_FLAGS,
  GDRIVE_SFTP_MAPPING_COLUMNS,
  GMAIL_MAPPING_COLUMNS,
  numberDateOperatorValues,
  ORBYAI_UNKNOWN,
  SelectedExtractedField,
  STEP_CONTENT_LAST_STEP_WIDTH,
  stringOperatorValues,
} from '../../../utils/constants';
import { GridSearchIcon } from '@mui/x-data-grid';
import ParentEntityChip from './components/ParentEntityChip';
import {
  EntityDataType,
  EntityDetails,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { v4 as uuidv4 } from 'uuid';
import SearchNestedEntities from './components/SearchNestedEntities';
import TextButton from '../../../components/TextButton';
import AddUserField from '../../../components/AddUserField';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CustomFilterSelect from '../../../components/generic/CustomFilterSelect';
import EntityFilterSelect from './components/EntityFilterSelect';
import AttributeContent from '../../../components/AttributeContent';
import {
  checkForManualAssignment,
  getConditionsFromConditionalAssignment,
  getRoundOneReviewers,
  getRoundTwoReviewers,
} from '../../../utils/WorkflowUtils';
import { getUserFiltersAction } from '../../../redux/actions/filter_options.action';
import {
  userDetailsMapSelector,
  userFiltersSelector,
} from '../../../redux/selectors/filter_options.selectors';
import AssignmentFilterSelect from './components/AssignmentFilterSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserProfileInfo } from 'protos/common/user_profile';
import UserChip from '../../../components/UserChip';
import { OrbyColorPalette, OrbyTypography, OrbyTabs } from 'orby-ui/src';
import UserIcon from '../../../static/icons/user.svg';
import ShareWorkflowUserChip from '../../../components/workflow-table/ShareWorkflowModal/components/UserChip';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../../FeatureFlags/FeatureFlagUtils';

interface Props {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isSubmitClicked: boolean) => void;
  edit?: boolean;
  isEmailExtraction: boolean;
  formId: string;
  previousClicked: boolean;
  originalWorkflow?: Workflow;
}

const ConditionTypesList = [
  {
    label: 'Any extracted field',
    value: ConditionType.ANY_EXTRACTED_FIELD,
  },
  {
    label: 'Average confidence score',
    value: ConditionType.AVERAGE_CONFIDENCE_SCORE,
  },
  {
    label: 'Specific extracted fields',
    value: ConditionType.SPECIFIC_EXTRACTED_FIELD,
  },
  {
    label: 'Any empty predictions',
    value: ConditionType.ANY_EMPTY_PREDICTIONS,
  },
];

const getSelectedEntities = (
  workflow: Workflow,
  isEmailExtraction: boolean,
  classificationLabelsIndex: boolean,
  entityExtractionIndex: { stepIndex: number; actionIndex: number },
  customMode: WorkflowMode,
): SelectedExtractedField[] => {
  if (
    workflow.mode === WorkflowMode.ASSISTED ||
    (customMode && customMode === WorkflowMode.ASSISTED)
  ) {
    if (classificationLabelsIndex) {
      return [
        {
          id: uuidv4(),
          entityName: CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
          parent: undefined,
        },
      ];
    }
    // Transforming entitiesDetails into an array of strings in "parent", "normal" and "parent/child" format
    const entities = workflow.steps?.[
      entityExtractionIndex.stepIndex!
    ]?.actions?.[
      entityExtractionIndex.actionIndex!
    ]?.entityExtraction?.entitiesDetails!.reduce((acc: any[], entity) => {
      // We do not show default entities in the dropdown, so do not add them
      if (entity.normalizationType === EntityDataType.ENTITY_TYPE_UNSPECIFIED) {
        return acc;
      }
      acc.push({
        id: uuidv4() as string,
        entityName: entity.entityType as string,
        parent: undefined,
      });
      entity.properties!.map((e) =>
        acc.push({
          id: uuidv4(),
          entityName: e.entityType,
          parent: entity.entityType,
        }),
      );
      return acc;
    }, []);

    if (isEmailExtraction) {
      return entities!.filter(
        (entity) => !GMAIL_MAPPING_COLUMNS.includes(entity),
      );
    } else {
      return entities!.filter(
        (entity) => !GDRIVE_SFTP_MAPPING_COLUMNS.includes(entity),
      );
    }
  }
  return [];
};

const getSelectedExtractedFields = (
  workflow: Workflow,
  classificationLabelsIndex: boolean,
  selectedEntities: SelectedExtractedField[],
) => {
  if (workflow.mode === WorkflowMode.ASSISTED) {
    if (classificationLabelsIndex) {
      return [
        {
          id: uuidv4(),
          entityName: CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
          parent: undefined,
        },
      ];
    }

    const groupCondition =
      workflow.reviewerLists![0]?.triggerCondition?.conditionOptions
        ?.groupCondition;
    if (groupCondition) {
      const selectedExtractedFields: SelectedExtractedField[] = [];
      groupCondition.conditions?.map((condition) => {
        const entityName = condition.attribute
          ? condition.attribute
          : condition?.attributeType?.name;
        const parent = condition.attribute
          ? undefined
          : condition?.attributeType?.parent === ''
            ? undefined
            : condition?.attributeType?.parent;
        const entity = selectedEntities.find(
          (e) => e.entityName === entityName && e.parent === parent,
        );
        if (entity) {
          selectedExtractedFields.push(entity);
        } else {
          selectedExtractedFields.push({
            id: uuidv4(),
            entityName: entityName!,
            parent: parent,
          });
        }
      });
      return selectedExtractedFields;
    }
  }
  return [];
};

const getConfidenceScore = (triggerCondition?: ReviewTriggerCondition) => {
  if (
    triggerCondition?.conditionType === ConditionType.SPECIFIC_EXTRACTED_FIELD
  ) {
    return (
      triggerCondition?.conditionOptions?.groupCondition?.conditions?.[0]
        ?.value || 0
    );
  } else {
    return triggerCondition?.conditionOptions?.confidenceScore || 0;
  }
};

const getSelectedConditionType = (
  workflow: Workflow,
  classificationLabelsIndex: boolean,
) => {
  if (workflow.mode === WorkflowMode.ASSISTED) {
    if (classificationLabelsIndex) {
      return ConditionType.SPECIFIC_EXTRACTED_FIELD;
    }
    return (
      workflow.reviewerLists![0]?.triggerCondition?.conditionType ||
      ConditionType.UNSPECIFIED
    );
  }
  return ConditionType.UNSPECIFIED;
};

const isAdvancedConditionsAdded = (workflow: Workflow) => {
  const conditionalAssignment =
    workflow.reviewerLists![0]?.assignmentOption?.conditionalAssignment;
  return conditionalAssignment ? conditionalAssignment.length > 0 : false;
};

const AddUserStepContent: FC<Props> = ({
  onSubmit,
  workflow,
  edit,
  isEmailExtraction,
  formId,
  previousClicked,
  originalWorkflow,
}) => {
  const dispatch = useDispatch();
  const userFilters = useSelector(userFiltersSelector);
  const userDetailsMap = useSelector(userDetailsMapSelector);
  const entityExtractionIndex = getActionIndex(
    workflow,
    ApplicationName.EntityExtraction,
  );
  const classificationLabelsIndex =
    getActionIndex(workflow, ApplicationName.DocumentClassification)
      .actionIndex! > -1;
  const [selectedEntities, setSelectedEntities] = useState(
    getSelectedEntities(
      workflow,
      isEmailExtraction,
      classificationLabelsIndex,
      entityExtractionIndex as { stepIndex: number; actionIndex: number },
      WorkflowMode.UNSPECIFIED,
    ),
  );
  const [removedPerson, setRemovedPerson] = useState<string | null>(null);
  const [userEmailList, setUserEmailList] = useState<string[]>([]);
  const [users, setUsers] = useState(getRoundOneReviewers(workflow));
  const [roundTwoUsers, setRoundTwoUsers] = useState(
    getRoundTwoReviewers(workflow),
  );

  const [selectedExtractedField, setSelectedExtractedField] = useState<
    SelectedExtractedField[]
  >(
    getSelectedExtractedFields(
      workflow,
      classificationLabelsIndex,
      selectedEntities,
    ),
  );
  const [showSecondRoundReviewBox, setShowSecondRoundReviewBox] = useState(
    workflow.reviewerLists?.find((r) => r.roundNumber === 2) ? true : false,
  );
  const isManualAssignment = useMemo(
    () => checkForManualAssignment(workflow),
    [workflow],
  );
  const gsheetsIndex = getActionIndex(workflow, ApplicationName.GoogleSheets);
  const user = useSelector(loggedInUserSelector)!;
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [showAdvanced, setShowAdvanced] = useState(
    !isManualAssignment && isAdvancedConditionsAdded(workflow),
  );

  const getLearningSettings = () => {
    const learningSettings: {
      [email: string]: WorkflowLearningSettingsReviewer;
    } = {};
    workflow?.learningSettings?.reviewers?.forEach((reviewer) => {
      const email = reviewer.reviewer?.username;
      if (email) {
        learningSettings[email] = reviewer;
      }
    });
    return learningSettings;
  };

  const formik = useFormik({
    initialValues: {
      // These users will act as
      // 1. 'People who can view tasks and later on assign themselves to these tasks'
      // associated with this workflow in case of Manual-Assignment/UNASSIGNED
      // 2. 'Reviewers' in other cases
      users: getRoundOneReviewers(workflow),
      roundTwoUsers: getRoundTwoReviewers(workflow),
      samplePercentage:
        workflow.reviewerLists?.find((r) => r.roundNumber === 2)
          ?.triggerCondition?.conditionOptions?.percentOfRandomSample || 0,
      selectedMode: workflow.mode || WorkflowMode.DEFAULT,
      learningSettings: getLearningSettings(),
      assistedPercent: undefined,
      conditionType: getSelectedConditionType(
        workflow,
        classificationLabelsIndex,
      ),
      confidenceScore: getConfidenceScore(
        workflow?.reviewerLists?.[0]?.triggerCondition,
      ),
      selectedExtractedField,
      assignmentMode: isManualAssignment
        ? AssignmentMode.UNASSIGNED
        : AssignmentMode.ASSIGNED,
      needAttentionThresholdDefaultMode:
        workflow.needAttentionThresholdDefaultMode! * 100,
      conditions: getConditionsFromConditionalAssignment(workflow),
      workflowAdmins: workflow.admins || [],
      adminEmailMessage: '',
    },
    validationSchema: Yup.object({
      // @ts-ignore
      conditions: showAdvanced
        ? Yup.array().of(
            Yup.object().shape({
              assignees: Yup.array().test(
                'check-assignees',
                'Please add at least one reviewer',
                (value) => {
                  if (formik.values.selectedMode !== WorkflowMode.AUTOPILOT) {
                    return !!value && value.length >= 1;
                  }
                  return true; // No validation when selectedMode is AUTOPILOT
                },
              ),
            }),
          )
        : undefined,
      users: Yup.array()
        .when('assignmentMode', {
          is: AssignmentMode.ASSIGNED,
          then: Yup.array()
            .min(1, 'Please add at least one user')
            .required('Please add at least one user'),
          otherwise: Yup.array()
            .min(1, 'Please add at least one user who can view this task')
            .required('Please add at least one user who can view this task'),
        })
        .test('check-duplicate-users', 'Please add unique users', () => {
          if (users.length === 1 && roundTwoUsers.length === 1) {
            if (users[0].user === roundTwoUsers[0].user) {
              return false;
            }
          }
          return true;
        }),
      samplePercentage: Yup.number()
        .test(
          'check-sample-percentage-min',
          'Sample percentage cannot be negative',
          (value) => {
            if (
              showSecondRoundReviewBox &&
              formik.values.selectedMode !== WorkflowMode.AUTOPILOT
            ) {
              if (value! < 0) {
                return false;
              }
            }
            return true; // No validation when showSecondRoundReviewBox is false and selectedMode is AUTOPILOT
          },
        )
        .test(
          'check-sample-percentage-max',
          'Sample percentage cannot be greater than 100',
          (value) => {
            if (
              showSecondRoundReviewBox &&
              formik.values.selectedMode !== WorkflowMode.AUTOPILOT
            ) {
              if (value! > 100) {
                return false;
              }
            }
            return true; // No validation when showSecondRoundReviewBox is false and selectedMode is AUTOPILOT
          },
        )
        .test(
          'check-sample-percentage',
          'Sample percentage is required',
          (value) => {
            if (
              showSecondRoundReviewBox &&
              formik.values.selectedMode !== WorkflowMode.AUTOPILOT
            ) {
              return value !== undefined;
            }
            return true; // No validation when showSecondRoundReviewBox is false and selectedMode is AUTOPILOT
          },
        ),
      roundTwoUsers: Yup.array().test(
        'check-round-two-users',
        'Please add at least one second-round reviewer, or remove this section by clicking the X',
        (value) => {
          if (
            showSecondRoundReviewBox &&
            formik.values.selectedMode !== WorkflowMode.AUTOPILOT
          ) {
            return !!value && value.length >= 1;
          }
          return true; // No validation when showSecondRoundReviewBox is false and selectedMode is AUTOPILOT
        },
      ),
      selectedExtractedField: Yup.array()
        .test(
          'check-selected-extracted-field',
          'Please add at least one extracted field',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.ASSISTED &&
              formik.values.conditionType ===
                ConditionType.SPECIFIC_EXTRACTED_FIELD
            ) {
              return !!value && value.length >= 1;
            }
            return true;
          },
        )
        .test(
          'check-if-selected-extracted-field-exists',
          'Please add valid extracted fields',
          () => {
            if (
              !classificationLabelsIndex &&
              formik.values.selectedMode === WorkflowMode.ASSISTED &&
              formik.values.conditionType ===
                ConditionType.SPECIFIC_EXTRACTED_FIELD
            ) {
              return selectedExtractedField.every((entity) =>
                selectedEntities.includes(entity),
              );
            }
            return true;
          },
        ),
      conditionType: Yup.number().test(
        'check-condition-type',
        'Please add at least one extracted field',
        (value) => {
          if (formik.values.selectedMode === WorkflowMode.ASSISTED) {
            return (
              value !== undefined &&
              [
                ConditionType.ANY_EXTRACTED_FIELD,
                ConditionType.AVERAGE_CONFIDENCE_SCORE,
                ConditionType.SPECIFIC_EXTRACTED_FIELD,
                ConditionType.ANY_EMPTY_PREDICTIONS,
              ].includes(value)
            );
          }
          return true;
        },
      ),
      confidenceScore: Yup.number()
        .test(
          'check-confidence-score-min',
          'Confidence score cannot be negative',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.ASSISTED &&
              formik.values.conditionType !==
                ConditionType.ANY_EMPTY_PREDICTIONS
            ) {
              if (value! < 0) {
                return false;
              }
            }
            return true;
          },
        )
        .test(
          'check-confidence-score-max',
          'Confidence score cannot be greater than 100',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.ASSISTED &&
              formik.values.conditionType !==
                ConditionType.ANY_EMPTY_PREDICTIONS
            ) {
              if (value! > 100) {
                return false;
              }
            }
            return true;
          },
        )
        .test(
          'check-confidence-score',
          'Confidence score is required',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.ASSISTED &&
              formik.values.conditionType !==
                ConditionType.ANY_EMPTY_PREDICTIONS
            ) {
              return value !== undefined;
            }
            return true; // No validation when showSecondRoundReviewBox is false
          },
        ),
      needAttentionThresholdDefaultMode: Yup.number()
        .test(
          'check-confidence-score-min',
          'Confidence score cannot be negative',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.DEFAULT &&
              entityExtractionIndex.stepIndex! >= 0 &&
              value! < 0
            ) {
              return false;
            }
            return true;
          },
        )
        .test(
          'check-confidence-score-max',
          'Confidence score cannot be greater than 100',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.DEFAULT &&
              entityExtractionIndex.stepIndex! >= 0 &&
              value! > 100
            ) {
              return false;
            }
            return true;
          },
        )
        .test(
          'check-confidence-score',
          'Confidence score is required',
          (value) => {
            if (
              formik.values.selectedMode === WorkflowMode.DEFAULT &&
              entityExtractionIndex.stepIndex! >= 0
            ) {
              return value !== undefined;
            }
            return true;
          },
        ),
    }),
    onSubmit: (values) => {
      onSubmit(
        {
          ...values,
          showAdvanced:
            showAdvanced && values.selectedMode !== WorkflowMode.AUTOPILOT,
        },
        true,
      );
    },
  });
  const [value, setValue] = useState('');
  const [prevUsers, setPrevUsers] = useState(users);
  const [prevRoundTwoUsers, setPrevRoundTwoUsers] = useState(roundTwoUsers);
  const {
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    values,
    getFieldProps,
  } = formik;

  // function to get the past users
  const pastUsers = useMemo(() => {
    const pastUsers: UserProfileInfo[] = [];
    Object.values(values.learningSettings).forEach((setting) => {
      if (!setting.existsInReviewerList && setting.reviewer) {
        pastUsers.push(UserProfileInfo.create({ ...setting.reviewer }));
      }
    });
    return pastUsers;
  }, [values.learningSettings]);

  const currentReviewers = useMemo(() => {
    const currentReviewers = new Set<string>();
    if (showAdvanced) {
      values.conditions.forEach((condition) => {
        (condition.assignees as WorkflowUser[]).forEach((user) => {
          currentReviewers.add(user.user as string);
        });
      });
    }
    values.users.forEach((user) => {
      currentReviewers.add(user.user as string);
    });
    values.roundTwoUsers.forEach((user) => {
      currentReviewers.add(user.user as string);
    });
    return currentReviewers;
  }, [showAdvanced, values.users, values.roundTwoUsers, values.conditions]);

  const [error, setError] = useState('');
  const [errorRoundTwo, setErrorRoundTwo] = useState('');

  useEffect(() => {
    setError('');
  }, [value]);

  useEffect(() => {
    if (userFilters.length) {
      const emails = userFilters.map((f) => f.label);
      setUserEmailList(emails);
    }
  }, [userFilters]);

  useEffect(() => {
    dispatch(getUserFiltersAction(selectedOrgInfo!.orgResourceName!));
  }, [selectedOrgInfo]);

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit({ ...formik.values, showAdvanced }, false);
    }
  }, [previousClicked]);

  const toggleLearningSettings = (email: string) => {
    const updatedLearningSettings = {
      ...values.learningSettings,
    };
    const reviewer = updatedLearningSettings[email];
    if (reviewer) {
      reviewer.usedForLearning = !reviewer.usedForLearning;
      setFieldValue('learningSettings', updatedLearningSettings);
    }
  };

  //  This function handles the following cases
  //   1. Person do not exist in learning settings
  //       -> Simply add the person
  //   2. Person already exist in learning settings
  //       a. As Past Reviewer
  //          -> Mark the person as current Reviewer
  //       b. As Current Reviewer
  //          -> No action needed

  const updateLearningSettingOnAdd = (email: string) => {
    const updatedLearningSettings = { ...values.learningSettings };
    const reviewer = updatedLearningSettings[email] as
      | WorkflowLearningSettingsReviewer
      | undefined;
    if (!reviewer) {
      updatedLearningSettings[email] = WorkflowLearningSettingsReviewer.create({
        usedForLearning: true,
        existsInReviewerList: true,
        reviewer: UserProfileInfo.create({ username: email }),
      });
      setFieldValue('learningSettings', updatedLearningSettings);
    } else if (!reviewer.existsInReviewerList) {
      reviewer.existsInReviewerList = true;
      setFieldValue('learningSettings', updatedLearningSettings);
    }
  };

  // Workflow Creation
  //  1. If the person exists as a reviewer in other settings
  //       -> Do nothing
  //  2. If the person does not exist as a reviewer in any settings
  //       -> Remove them

  // Workflow Update
  //  1. If the person exists as a current reviewer in other settings
  //       -> Do nothing
  //  2. If the person does not exist as a current reviewer in other settings
  //       a. If they previously existed as a reviewer -> Mark them as a Past User
  //       b. If they never existed as a reviewer -> Delete them
  const updateLearningSettingOnDelete = (email: string) => {
    // workflow update
    if (originalWorkflow) {
      const updatedLearningSettings = { ...values.learningSettings };
      const reviewer = updatedLearningSettings[email];
      if (reviewer && !currentReviewers.has(email)) {
        const previousReviewer =
          originalWorkflow.learningSettings?.reviewers?.find(
            (r) => r.reviewer?.username === email,
          );
        if (previousReviewer) {
          reviewer.existsInReviewerList = false;
        } else {
          delete updatedLearningSettings[email];
        }
        setFieldValue('learningSettings', updatedLearningSettings);
      }
    }
    // workflow creation
    else {
      const updatedLearningSettings = { ...values.learningSettings };
      const reviewer = updatedLearningSettings[email];
      if (reviewer && !currentReviewers.has(email)) {
        delete updatedLearningSettings[email];
        setFieldValue('learningSettings', updatedLearningSettings);
      }
    }
  };

  const handleAddUser = (email: string) => {
    setError('');
    email = email.toLowerCase();
    email = email.replace(/\s+/g, ' ').trim();
    if (email.length == 0) {
      setError('Empty Field');
      return;
    }
    const index = users.findIndex((u) => u.user == email);
    if (index !== -1) {
      setError('User already added');
      return;
    }
    // Check if user is already added in second round (only in case if round first has 1 user and second round has 1 user)
    if (
      roundTwoUsers.length === 1 &&
      users.length <= 1 &&
      roundTwoUsers[0].user === email
    ) {
      setError('User already added in second round');
      return;
    }
    const newUsers = [
      ...users,
      WorkflowUser.create({ user: email, enabled: true }),
    ];
    setUsers(newUsers);
    setPrevUsers(newUsers);
    setFieldValue('users', newUsers);
    updateLearningSettingOnAdd(email);
    setValue('');
  };

  const handleAddSecondRoundUser = (email: string) => {
    setErrorRoundTwo('');
    email = email.toLowerCase();
    email = email.replace(/\s+/g, ' ').trim();
    if (email.length == 0) {
      setErrorRoundTwo('Empty Field');
      return;
    }
    const index = roundTwoUsers.findIndex((u) => u.user == email);
    if (index !== -1) {
      setErrorRoundTwo('User already added');
      return;
    }
    // Check if user is already added in first round (only in case if round first has 1 user)
    if (
      users.length === 1 &&
      roundTwoUsers.length < 1 &&
      users[0].user === email
    ) {
      setErrorRoundTwo('User already added in first round');
      return;
    }
    const newUsers = [
      ...roundTwoUsers,
      WorkflowUser.create({ user: email, enabled: true }),
    ];
    setRoundTwoUsers(newUsers);
    setPrevRoundTwoUsers(newUsers);
    setFieldValue('roundTwoUsers', newUsers);
    updateLearningSettingOnAdd(email);
  };

  const handleModeChange = (mode: WorkflowMode) => {
    setFieldValue('selectedMode', mode);
    setFieldValue(
      'assistedPercent',
      mode === WorkflowMode.ASSISTED ? 60 : undefined,
    );
    setFieldValue('confidenceScore', 0);
    setFieldValue('needAttentionThresholdDefaultMode', 0);
    setFieldValue('conditionType', ConditionType.UNSPECIFIED);
    setFieldValue('selectedExtractedField', []);
    setSelectedEntities([]);
    if (mode === WorkflowMode.AUTOPILOT) {
      setPrevUsers(users);
      setPrevRoundTwoUsers(roundTwoUsers);
      setValue('');
      const newUsers = [
        WorkflowUser.create({ user: user.email, enabled: true }),
      ];
      setUsers(newUsers);
      setRoundTwoUsers([]);
      setFieldValue('users', newUsers);
      setFieldValue('roundTwoUsers', []);
      formik.setFieldError('users', '');
      formik.setFieldTouched('users', false, false);
      formik.setFieldError('roundTwoUsers', '');
      formik.setFieldTouched('roundTwoUsers', false, false);
    } else {
      setValue('');
      setUsers(prevUsers);
      setRoundTwoUsers(prevRoundTwoUsers);
      setFieldValue('roundTwoUsers', prevRoundTwoUsers);
      setFieldValue('users', prevUsers);
      formik.setFieldError('users', '');
      formik.setFieldTouched('users', false, false);
      formik.setFieldError('roundTwoUsers', '');
      formik.setFieldTouched('roundTwoUsers', false, false);
      if (mode === WorkflowMode.ASSISTED) {
        setSelectedEntities(
          getSelectedEntities(
            workflow,
            isEmailExtraction,
            classificationLabelsIndex,
            entityExtractionIndex as { stepIndex: number; actionIndex: number },
            mode,
          ),
        );
        setFieldValue(
          'conditionType',
          classificationLabelsIndex
            ? ConditionType.SPECIFIC_EXTRACTED_FIELD
            : ConditionType.ANY_EXTRACTED_FIELD,
        );
        if (classificationLabelsIndex) {
          setFieldValue('selectedExtractedField', [
            CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
          ]);
        }
      }
    }
  };
  const handleDelete = (user: WorkflowUser) => {
    if (formik.values.selectedMode === WorkflowMode.AUTOPILOT) {
      return;
    }
    const filteredUsers = users.filter((u) => u.user !== user.user);
    setUsers(filteredUsers);
    setPrevUsers(filteredUsers);
    setFieldValue('users', filteredUsers);
    setRemovedPerson(user.user as string);
  };

  const handleDeleteSecondRoundUser = (user: WorkflowUser) => {
    const filteredUsers = roundTwoUsers.filter((u) => u.user !== user.user);
    setRoundTwoUsers(filteredUsers);
    setPrevRoundTwoUsers(filteredUsers);
    setFieldValue('roundTwoUsers', filteredUsers);
    setRemovedPerson(user.user as string);
  };

  /**
   * Handles the addition of specific extracted fields to the selectedEntities.
   * This function is designed to conveniently add entities in bulk.
   *
   * @param {string | string[]} selectedEntities - The entity or entities to be added.
   *   It can be either a single string or an array of strings.
   */
  const handleAddSpecificExtractedField = (
    selectedEntities: SelectedExtractedField[],
  ) => {
    // Convert the selectedEntities to an array for uniform processing
    const updatesEntities =
      typeof selectedEntities === 'string'
        ? [...selectedExtractedField, selectedEntities]
        : [...selectedExtractedField, ...selectedEntities];
    setSelectedExtractedField(updatesEntities);
    setFieldValue('selectedExtractedField', updatesEntities);
  };
  /**
   * Handles the deletion of specific extracted fields to the selectedEntities.
   * This function is designed to conveniently delete entities in bulk.
   *
   * @param {string | string[]} selectedEntities - The entity or entities to be added.
   *   It can be either a single string or an array of strings.
   */
  const handleDeleteSpecificExtractedField = (
    selectedEntities: SelectedExtractedField[],
  ) => {
    // Convert the selectedEntities to an array for uniform processing
    const filteredEntities = selectedExtractedField.filter(
      (entity) => !selectedEntities.some((e) => e.id === entity.id),
    );
    setSelectedExtractedField(filteredEntities);
    setFieldValue('selectedExtractedField', filteredEntities);
  };

  const getEntityDataType = (
    entity: SelectedExtractedField,
  ): EntityDataType | undefined => {
    const entityDetails: EntityDetails[] =
      workflow.steps![entityExtractionIndex.stepIndex!]!.actions![
        entityExtractionIndex.actionIndex!
      ].entityExtraction!.entitiesDetails || [];
    for (const entityDetail of entityDetails) {
      if (entity.parent) {
        for (const property of entityDetail.properties!) {
          if (
            property.entityType === entity.entityName &&
            entityDetail.entityType === entity.parent
          )
            return property.normalizationType!;
        }
      } else if (
        entityDetail.entityType === entity.entityName &&
        !entity.parent
      ) {
        return entityDetail.normalizationType!;
      }
    }
  };

  const getAllParentExtractedFields = () => {
    const parents: SelectedExtractedField[] = [];
    for (const entity of selectedExtractedField) {
      const parent = selectedExtractedField.find(
        (p) => p.parent === entity.entityName,
      );
      if (parent && !entity.parent && !parents.includes(parent)) {
        parents.push(entity);
      }
    }
    return parents;
  };

  const getAllNormalExtractedFields = () => {
    const normalFields: SelectedExtractedField[] = [];
    for (const entity of selectedExtractedField) {
      const isParent = selectedExtractedField.find(
        (p) => p.parent === entity.entityName,
      );
      if (!isParent && !entity.parent) {
        normalFields.push(entity);
      }
    }
    return normalFields;
  };

  const getOperatorOptions = (attribute: string) => {
    const classificationIndex = getActionIndex(
      workflow,
      ApplicationName.DocumentClassification,
    );
    const isClassification = !!classificationIndex.stepIndex;
    const attributeType = getAttributeDataType(attribute, workflow);
    switch (attributeType) {
      case EntityDataType.ENTITY_TYPE_MONEY:
      case EntityDataType.ENTITY_TYPE_DATE:
      case EntityDataType.ENTITY_TYPE_FLOAT:
      case EntityDataType.ENTITY_TYPE_INTEGER:
        return numberDateOperatorValues;
      default:
        return isClassification
          ? classificationOperatorValues
          : stringOperatorValues;
    }
  };

  const getOperatorBasedAttributeContent = (
    attribute: string,
    name: string,
    operator: Operator,
  ) => {
    // Disable fields for OPERATOR_EXISTS & OPERATOR_DOES_NOT_EXIST since these operators themselves
    // are sufficient for a condition and does not need any value
    const disabled = extractionOperators.includes(operator);
    // Clear field value if disabled
    if (disabled) {
      if (getFieldProps(name).value) {
        setFieldValue(name, '');
      }
    }
    return (
      <AttributeContent
        attributeType={getAttributeDataType(attribute, workflow)}
        name={name}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        getClassificationLabelsList={() => getClassificationLabels(workflow)}
        align='top'
        disabled={disabled}
      />
    );
  };

  const isUserForLearning = (email: string) => {
    return values.learningSettings[email]?.usedForLearning ?? false;
  };

  const isLearningSettingModified = () => {
    return !!(
      originalWorkflow &&
      originalWorkflow.learningSettings?.reviewers?.find((or) => {
        const reviewer = values.learningSettings[or.reviewer?.username ?? ''];
        return reviewer && reviewer.usedForLearning !== or.usedForLearning;
      })
    );
  };

  useEffect(() => {
    if (removedPerson) {
      updateLearningSettingOnDelete(removedPerson);
      setRemovedPerson(null);
    }
  }, [removedPerson]);

  const [selectedTab, setSelectedTab] = useState(0);
  /**
   * HANDLE TAB CHANGE
   */
  const handleTabChange = (tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const tabs = [
    {
      id: 'add-user-tab',
      label: 'Add users',
    },
    {
      id: 'add-admin-tab',
      label: 'Add admins',
    },
  ];

  // check if rbac is enabled
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);

  return (
    <FormikProvider value={formik}>
      <Form id={formId} autoComplete='off' noValidate onSubmit={handleSubmit}>
        <OrbyTabs
          selectedTab={selectedTab}
          setSelectedTab={(tabIndex: number) => handleTabChange(tabIndex)}
          tabs={isRbacEnabled ? tabs : tabs.slice(0, 1)}
        />
        {/* TAB PANELS */}
        {[0].includes(selectedTab) && (
          <>
            {/* {isEmailExtraction && ( */}
            <Box
              display={'flex'}
              width={STEP_CONTENT_LAST_STEP_WIDTH}
              gap={'30px'}
            >
              <Box
                width={'632px'}
                display={'flex'}
                flexDirection={'column'}
                gap={'15px'}
                padding={'30px'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <AddUserReviewMode
                    ariaDescribedBy='default_mode'
                    selectedMode={values.selectedMode}
                    mode={WorkflowMode.DEFAULT}
                    onClick={handleModeChange}
                  />
                  <AddUserReviewMode
                    ariaDescribedBy='assisted_mode'
                    selectedMode={values.selectedMode}
                    mode={WorkflowMode.ASSISTED}
                    onClick={handleModeChange}
                  />
                  <AddUserReviewMode
                    ariaDescribedBy='auto-pilot_mode'
                    selectedMode={values.selectedMode}
                    mode={WorkflowMode.AUTOPILOT}
                    onClick={handleModeChange}
                  />
                </Box>
                <Box>
                  {formik.values.selectedMode === WorkflowMode.AUTOPILOT && (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      paddingY={'12px'}
                    >
                      <OrbyTypography size={'md'}>
                        Orby will automate without the need for user review.
                      </OrbyTypography>
                    </Box>
                  )}
                  {formik.values.selectedMode !== WorkflowMode.AUTOPILOT && (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      paddingY={'12px'}
                    >
                      <AssignmentFilterSelect
                        value={formik.values.assignmentMode}
                        setValue={(val) => {
                          if (val === AssignmentMode.UNASSIGNED) {
                            setShowAdvanced(false);
                          }
                          setFieldValue('assignmentMode', val);
                        }}
                      />
                    </Box>
                  )}
                  {formik.values.selectedMode !== WorkflowMode.AUTOPILOT && (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      paddingY={'12px'}
                    >
                      <CustomTypography
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#344054',
                          marginLeft: '4px',
                        }}
                      >
                        {formik.values.assignmentMode !==
                        AssignmentMode.UNASSIGNED
                          ? 'Add Users'
                          : 'Select users who can view this task'}
                      </CustomTypography>
                      {formik.values.assignmentMode !==
                        AssignmentMode.UNASSIGNED &&
                        (showAdvanced ? (
                          <TextButton
                            label='Back to default'
                            onClick={() => setShowAdvanced(false)}
                          />
                        ) : (
                          <TextButton
                            label='Add advanced conditions'
                            onClick={() => setShowAdvanced(true)}
                          />
                        ))}
                    </Box>
                  )}
                  <Box>
                    {showAdvanced &&
                      formik.values.selectedMode !== WorkflowMode.AUTOPILOT && (
                        <Box
                          padding={'20px'}
                          borderRadius={'12px'}
                          border={'1px solid #EAECF0'}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <CustomTypography
                              sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#344054',
                              }}
                            >
                              Create conditions to assign to reviewers
                            </CustomTypography>
                            <CloseIcon
                              onClick={() => setShowAdvanced(false)}
                              sx={{
                                fontSize: '20px',
                                color: '#667085',
                                cursor: 'pointer',
                              }}
                            />
                          </Box>
                          <FieldArray name='conditions'>
                            {({ push, form, remove }) => {
                              return (
                                <>
                                  <Box
                                    padding={'10px'}
                                    sx={{ cursor: 'pointer' }}
                                    role={'button'}
                                    tabIndex={0}
                                    onClick={() =>
                                      push({
                                        operator: Operator.EXISTS,
                                        value: '',
                                        attributeType: { parent: '', name: '' },
                                        assignees: [],
                                      })
                                    }
                                    onKeyDown={(e) => {
                                      if (e.code === 'enter') {
                                        push({
                                          operator: Operator.EXISTS,
                                          value: '',
                                          attributeType: {
                                            parent: '',
                                            name: '',
                                          },
                                          assignees: [],
                                        });
                                      }
                                    }}
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                    gap={'3px'}
                                  >
                                    <AddCircleOutline
                                      sx={{
                                        color: '#1669F7',
                                        marginTop: '1px',
                                        fontSize: '20px',
                                      }}
                                    />
                                    <TextButton label='Add condition' />
                                  </Box>
                                  {form.values.conditions.map(
                                    (_: any, conditionIndex: number) => {
                                      const attributeName = getFieldProps(
                                        `conditions.${conditionIndex}.attributeType.name`,
                                      ).value;
                                      const assigneesName = `conditions.${conditionIndex}.assignees`;
                                      const errAssignees =
                                        touched.conditions?.[conditionIndex]
                                          ?.assignees &&
                                        (
                                          errors.conditions?.[
                                            conditionIndex
                                          ] as any
                                        )?.assignees;
                                      const assignees =
                                        getFieldProps(assigneesName).value;
                                      return (
                                        <Box
                                          key={conditionIndex}
                                          bgcolor={'#FCFAFF'}
                                          padding={'13px'}
                                          marginTop={
                                            conditionIndex > 0 ? '13px' : ''
                                          }
                                          border={'1px solid #FCFCFD'}
                                        >
                                          <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                          >
                                            <CustomTypography
                                              sx={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#475467',
                                              }}
                                            >
                                              Condition
                                            </CustomTypography>
                                            {form.values.conditions.length >
                                              1 && (
                                              <DeleteOutlineRounded
                                                onClick={() =>
                                                  remove(conditionIndex)
                                                }
                                                sx={{
                                                  fontSize: '20px',
                                                  color: '#4B4B4B',
                                                  cursor: 'pointer',
                                                }}
                                              />
                                            )}
                                          </Box>
                                          <CustomTypography
                                            sx={{
                                              fontWeight: 400,
                                              fontSize: '14px',
                                              lineHeight: '16px',
                                              color: '#475467',
                                              paddingTop: '20px',
                                            }}
                                          >
                                            When
                                          </CustomTypography>
                                          <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            paddingTop={'12px'}
                                          >
                                            <EntityFilterSelect
                                              width='27%'
                                              name={`conditions.${conditionIndex}.attributeType`}
                                              padding={'8.7px'}
                                              height={'23px'}
                                              entityDetails={getAttributeFilters(
                                                workflow,
                                              )}
                                              getFieldProps={getFieldProps}
                                            />
                                            <CustomFilterSelect
                                              width='23%'
                                              name={`conditions.${conditionIndex}.operator`}
                                              extraProps={getFieldProps(
                                                `conditions.${conditionIndex}.operator`,
                                              )}
                                              height={'23px'}
                                              padding={'8.7px'}
                                              filters={getOperatorOptions(
                                                attributeName,
                                              )}
                                              value={''}
                                            />
                                            <Box width={'45%'}>
                                              {getOperatorBasedAttributeContent(
                                                attributeName,
                                                `conditions.${conditionIndex}.value`,
                                                getFieldProps(
                                                  `conditions.${conditionIndex}.operator`,
                                                ).value,
                                              )}
                                            </Box>
                                          </Box>
                                          <CustomTypography
                                            sx={{
                                              fontWeight: 400,
                                              fontSize: '14px',
                                              lineHeight: '16px',
                                              color: '#475467',
                                              marginTop: '30px',
                                              paddingBottom: '12px',
                                            }}
                                          >
                                            Assign to
                                          </CustomTypography>
                                          <AddUserField
                                            hasError={Boolean(errAssignees)}
                                            onChange={(email) => {
                                              updateLearningSettingOnAdd(email);
                                              setFieldValue(assigneesName, [
                                                ...assignees,
                                                WorkflowUser.create({
                                                  user: email,
                                                  enabled: true,
                                                }),
                                              ]);
                                            }}
                                            searchList={userEmailList}
                                            searchValues={assignees}
                                          />
                                          {Boolean(errAssignees) && (
                                            <CustomTypography
                                              component={'span'}
                                              sx={{
                                                color: '#EB0000',
                                                fontSize: '12px',
                                                display: 'block',
                                                marginTop: '8px',
                                              }}
                                            >
                                              {errAssignees}
                                            </CustomTypography>
                                          )}
                                          <FieldArray name={assigneesName}>
                                            {({ remove }) => {
                                              return assignees.map(
                                                (
                                                  user: WorkflowUser,
                                                  userIndex: number,
                                                ) => {
                                                  return (
                                                    <UserChip
                                                      key={user.user}
                                                      email={
                                                        user.user as string
                                                      }
                                                      profileImageUrl={
                                                        userDetailsMap[
                                                          user.user as string
                                                        ]?.profileImageUrl
                                                      }
                                                      fullName={
                                                        userDetailsMap[
                                                          user.user as string
                                                        ]?.fullName
                                                      }
                                                      onDelete={() => {
                                                        setRemovedPerson(
                                                          user.user as string,
                                                        );
                                                        remove(userIndex);
                                                      }}
                                                      usedForLearning={isUserForLearning(
                                                        user.user as string,
                                                      )}
                                                      toggleLearningSettings={() =>
                                                        toggleLearningSettings(
                                                          user.user as string,
                                                        )
                                                      }
                                                    />
                                                  );
                                                },
                                              );
                                            }}
                                          </FieldArray>
                                        </Box>
                                      );
                                    },
                                  )}
                                </>
                              );
                            }}
                          </FieldArray>

                          <Box
                            marginTop={'20px'}
                            bgcolor={'#FCFAFF'}
                            padding={'13px'}
                            border={'1px solid #FCFCFD'}
                          >
                            <CustomTypography
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16px',
                                color: '#475467',
                                paddingBottom: '12px',
                              }}
                            >
                              Otherwise, assign to
                            </CustomTypography>
                            <AddUserField
                              hasError={Boolean(touched.users && errors.users)}
                              onChange={handleAddUser}
                              searchList={userEmailList}
                              searchValues={users}
                            />
                            {Boolean(touched.users && errors.users) && (
                              <CustomTypography
                                component={'span'}
                                sx={{
                                  color: '#EB0000',
                                  fontSize: '12px',
                                  display: 'block',
                                  marginTop: '8px',
                                }}
                              >
                                {(touched.users && errors.users) as string}
                              </CustomTypography>
                            )}
                            {error !== '' && (
                              <CustomTypography
                                component={'span'}
                                sx={{
                                  color: '#EB0000',
                                  fontSize: '12px',
                                  display: 'block',
                                  marginTop: '8px',
                                }}
                              >
                                {error}
                              </CustomTypography>
                            )}
                            {users.map((user) => (
                              <UserChip
                                key={user.user}
                                email={user.user as string}
                                usedForLearning={isUserForLearning(
                                  user.user as string,
                                )}
                                profileImageUrl={
                                  userDetailsMap[user.user as string]
                                    ?.profileImageUrl
                                }
                                fullName={
                                  userDetailsMap[user.user as string]?.fullName
                                }
                                toggleLearningSettings={() =>
                                  toggleLearningSettings(user.user as string)
                                }
                                onDelete={() => handleDelete(user)}
                              />
                            ))}
                          </Box>
                        </Box>
                      )}
                    {!showAdvanced &&
                      formik.values.selectedMode !== WorkflowMode.AUTOPILOT && (
                        <AddUserField
                          disabled={
                            (formik.values.selectedMode as WorkflowMode) ===
                            WorkflowMode.AUTOPILOT
                          }
                          hasError={Boolean(touched.users && errors.users)}
                          onChange={handleAddUser}
                          searchList={userEmailList}
                          searchValues={users}
                        />
                      )}
                    {!showAdvanced &&
                      Boolean(touched.users && errors.users) && (
                        <CustomTypography
                          component={'span'}
                          sx={{
                            color: '#EB0000',
                            fontSize: '12px',
                            display: 'block',
                            marginTop: '8px',
                          }}
                        >
                          {(touched.users && errors.users) as string}
                        </CustomTypography>
                      )}
                    {!showAdvanced && error !== '' && (
                      <CustomTypography
                        component={'span'}
                        sx={{
                          color: '#EB0000',
                          fontSize: '12px',
                          display: 'block',
                          marginTop: '8px',
                        }}
                      >
                        {error}
                      </CustomTypography>
                    )}
                    {formik.values.selectedMode === WorkflowMode.ASSISTED && (
                      <Box
                        position={'relative'}
                        marginTop={'10px'}
                        width={'calc(100% - 10px)'}
                        borderRadius={'8px'}
                        paddingLeft={'10px'}
                      >
                        <Box
                          position={'absolute'}
                          left={0}
                          component={'img'}
                          src={AssistedArrow}
                          alt='info'
                        />
                        <Box
                          width={'100%'}
                          style={{ background: '#E3FCEF4D' }}
                          borderRadius={'8px'}
                          padding={'30px'}
                          paddingTop={'18px'}
                        >
                          {!classificationLabelsIndex && (
                            <Box display={'flex'} alignItems={'center'}>
                              <CustomTypography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: '#333333',
                                  marginRight: '10px',
                                }}
                              >
                                For
                              </CustomTypography>
                              <Select
                                style={{
                                  height: '32px',
                                  padding: 0,
                                  fontSize: '12px',
                                  background: '#ffffff',
                                }}
                                defaultValue={values.conditionType}
                                onChange={(e) => {
                                  setFieldValue(
                                    'conditionType',
                                    e.target.value,
                                  );
                                  setSelectedExtractedField([]);
                                  setFieldValue('selectedExtractedField', []);
                                  if (
                                    e.target.value ===
                                    ConditionType.ANY_EMPTY_PREDICTIONS
                                  ) {
                                    setFieldValue('confidenceScore', 0);
                                  }
                                }}
                              >
                                {ConditionTypesList.map((conditionType) => (
                                  <MenuItem
                                    key={conditionType.label}
                                    sx={{
                                      fontSize: '12px',
                                    }}
                                    value={conditionType.value}
                                  >
                                    {conditionType.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                          {formik.values.conditionType ===
                            ConditionType.SPECIFIC_EXTRACTED_FIELD &&
                            !classificationLabelsIndex && (
                              <Box
                                position={'relative'}
                                marginTop={'15px'}
                                width={'97%'}
                              >
                                <SearchNestedEntities
                                  top='40px'
                                  height='32px'
                                  searchStyle={{
                                    fontSize: '12px',
                                    width: '100%',
                                    border:
                                      Boolean(
                                        touched.selectedExtractedField &&
                                          errors.selectedExtractedField,
                                      ) && '0.75px solid #B6B6B6',
                                  }}
                                  className={'search-extracted-input'}
                                  inputProps={{
                                    startAdornment: (
                                      <Box>
                                        <InputAdornment position='start'>
                                          <IconButton sx={{ width: '10px' }}>
                                            <GridSearchIcon fontSize='medium' />
                                          </IconButton>
                                        </InputAdornment>
                                      </Box>
                                    ),
                                  }}
                                  dropdownValues={selectedEntities}
                                  selectedValues={selectedExtractedField}
                                  handleAdd={handleAddSpecificExtractedField}
                                  placeholder='Search extracted field'
                                  ariaLabel='Search extracted field'
                                  ariaDescribeBy='search_extracted_field'
                                  dropdownWidth={'100%'}
                                />
                                {Boolean(
                                  touched.selectedExtractedField &&
                                    errors.selectedExtractedField,
                                ) && (
                                  <CustomTypography
                                    component={'span'}
                                    sx={{
                                      display: 'block',
                                      color: '#EB0000',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {
                                      (touched.selectedExtractedField &&
                                        errors.selectedExtractedField) as string
                                    }
                                  </CustomTypography>
                                )}
                                {/* For parents */}
                                {getAllParentExtractedFields().map((entity) => {
                                  const childEntities = selectedEntities.filter(
                                    (e) => e.parent === entity.entityName,
                                  );
                                  return (
                                    <ParentEntityChip
                                      key={entity.entityName}
                                      entity={entity}
                                      handleAddEntity={
                                        handleAddSpecificExtractedField
                                      }
                                      childEntities={childEntities}
                                      getEntityDataType={getEntityDataType}
                                      selectedEntities={selectedExtractedField}
                                      handleDelete={
                                        handleDeleteSpecificExtractedField
                                      }
                                    />
                                  );
                                })}
                                {getAllNormalExtractedFields().map((entity) => {
                                  return (
                                    <ParentEntityChip
                                      key={entity.entityName}
                                      entity={entity}
                                      handleAddEntity={
                                        handleAddSpecificExtractedField
                                      }
                                      childEntities={[]}
                                      getEntityDataType={getEntityDataType}
                                      selectedEntities={selectedExtractedField}
                                      handleDelete={
                                        handleDeleteSpecificExtractedField
                                      }
                                      isParentEntity={false}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                          {formik.values.conditionType !==
                            ConditionType.ANY_EMPTY_PREDICTIONS && (
                            <Box
                              paddingTop={
                                classificationLabelsIndex ? '0px' : '10px'
                              }
                              display={'flex'}
                              alignItems={'center'}
                            >
                              <CustomTypography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: '#333333',
                                  marginRight: '10px',
                                  width: '80%',
                                }}
                              >
                                When the{' '}
                                {classificationLabelsIndex &&
                                  CLASSIFICATION_SCHEMA_DOCUMENT_TYPE}{' '}
                                confidence score is lower than
                              </CustomTypography>
                              <TextField
                                // @ts-ignore
                                value={Number(values.confidenceScore)}
                                placeholder={'Confidence Score'}
                                type='number'
                                variant='outlined'
                                size={'small'}
                                {...getFieldProps('confidenceScore')}
                                InputProps={{
                                  'aria-label': 'Confidence Score',
                                  'aria-required': true,
                                  'aria-describedby': 'confidence_score',
                                  inputMode: 'numeric',
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ background: '#fff' }}
                                      position='end'
                                    >
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  width: '95px',
                                  borderRadius: '4px',
                                  '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                  },
                                  '& input[type=number]::-webkit-outer-spin-button':
                                    {
                                      WebkitAppearance: 'none',
                                      margin: 0,
                                    },
                                  '& input[type=number]::-webkit-inner-spin-button':
                                    {
                                      WebkitAppearance: 'none',
                                      margin: 0,
                                    },
                                  '& .MuiOutlinedInput-root': {
                                    background: '#ffffff',
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#CCCCCC !important',
                                  },
                                  '& .MuiOutlinedInput-input  ': {
                                    lineHeight: '16px',
                                    height: '15px',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    background: '#ffffff',
                                    WebkitTextFillColor: '#000000 !important',
                                  },
                                }}
                              />
                            </Box>
                          )}
                          {Boolean(
                            touched.confidenceScore && errors.confidenceScore,
                          ) && (
                            <CustomTypography
                              component={'span'}
                              sx={{ color: '#EB0000', fontSize: '12px' }}
                            >
                              {
                                (touched.confidenceScore &&
                                  errors.confidenceScore) as string
                              }
                            </CustomTypography>
                          )}
                          <Box
                            paddingTop={'15px'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <CustomTypography
                              sx={{
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#333333',
                                marginRight: '10px',
                              }}
                            >
                              It will trigger human reviews, and the predictions
                              will be highlighted in red for the reviewers.
                            </CustomTypography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {entityExtractionIndex.stepIndex! >= 0 &&
                      formik.values.selectedMode === WorkflowMode.DEFAULT && (
                        <Box
                          position={'relative'}
                          width={'calc(100% - 10px)'}
                          borderRadius={'8px'}
                          marginTop={'10px'}
                        >
                          <Box
                            position={'absolute'}
                            left={0}
                            component={'img'}
                            src={AssistedArrow}
                            alt={'info'}
                          />
                          <Box
                            width={'100%'}
                            style={{ background: '#E3FCEF4D' }}
                            borderRadius={'8px'}
                            marginLeft={'10px'}
                            padding={'30px'}
                            paddingTop={'18px'}
                          >
                            {
                              <Box display={'flex'} alignItems={'center'}>
                                <CustomTypography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#333333',
                                  }}
                                >
                                  For entities that need attention to the
                                  reviewers, the predictions will be highlighted
                                  in red under the “Need Attention” tab in the
                                  task review page for empty predictions as well
                                  as the following entities:
                                </CustomTypography>
                              </Box>
                            }

                            <Box
                              paddingTop={'10px'}
                              display={'flex'}
                              alignItems={'center'}
                            >
                              <CustomTypography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '22px',
                                  color: '#333333',
                                  width: '70%',
                                }}
                              >
                                When the confidence score is lower than
                              </CustomTypography>
                              <TextField
                                // @ts-ignore
                                value={Number(
                                  values.needAttentionThresholdDefaultMode,
                                )}
                                placeholder={'Confidence Score'}
                                type='number'
                                variant='outlined'
                                size={'small'}
                                {...getFieldProps(
                                  'needAttentionThresholdDefaultMode',
                                )}
                                InputProps={{
                                  'aria-label': 'Need Attention Threshold',
                                  'aria-required': true,
                                  inputMode: 'numeric',
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ background: '#fff' }}
                                      position='end'
                                    >
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  width: '95px',
                                  borderRadius: '4px',
                                  '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                  },
                                  '& input[type=number]::-webkit-outer-spin-button':
                                    {
                                      WebkitAppearance: 'none',
                                      margin: 0,
                                    },
                                  '& input[type=number]::-webkit-inner-spin-button':
                                    {
                                      WebkitAppearance: 'none',
                                      margin: 0,
                                    },
                                  '& .MuiOutlinedInput-root': {
                                    background: '#ffffff',
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#CCCCCC !important',
                                  },
                                  '& .MuiOutlinedInput-input  ': {
                                    lineHeight: '16px',
                                    height: '15px',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    background: '#ffffff',
                                    WebkitTextFillColor: '#000000 !important',
                                  },
                                }}
                              />
                            </Box>
                            {Boolean(
                              touched.needAttentionThresholdDefaultMode &&
                                errors.needAttentionThresholdDefaultMode,
                            ) && (
                              <CustomTypography
                                component={'span'}
                                sx={{ color: '#EB0000', fontSize: '12px' }}
                              >
                                {
                                  errors.needAttentionThresholdDefaultMode as string
                                }
                              </CustomTypography>
                            )}
                          </Box>
                        </Box>
                      )}
                  </Box>
                  {!showAdvanced &&
                    formik.values.selectedMode !== WorkflowMode.AUTOPILOT &&
                    users.map((user) => {
                      return (
                        <UserChip
                          profileImageUrl={
                            userDetailsMap[user.user as string]?.profileImageUrl
                          }
                          fullName={
                            userDetailsMap[user.user as string]?.fullName
                          }
                          key={user.user}
                          email={user.user as string}
                          usedForLearning={isUserForLearning(
                            user.user as string,
                          )}
                          toggleLearningSettings={() =>
                            toggleLearningSettings(user.user as string)
                          }
                          onDelete={() => handleDelete(user)}
                        />
                      );
                    })}
                  {formik.values.selectedMode !== WorkflowMode.AUTOPILOT && (
                    <Box paddingTop={'30px'} height={'fit-content'}>
                      <TextButton
                        label='Add 2nd-round reviewers'
                        onClick={() => setShowSecondRoundReviewBox(true)}
                      />
                      {showSecondRoundReviewBox && (
                        <Box
                          height={'fit-contetn'}
                          padding={'20px'}
                          marginTop={'20px'}
                          border={'1px solid #E8E8FC'}
                          borderRadius={'8px'}
                          position={'relative'}
                        >
                          <IconButton
                            onClick={() => {
                              setRoundTwoUsers([]);
                              setPrevRoundTwoUsers([]);
                              setFieldValue('roundTwoUsers', []);
                              setFieldValue('samplePercentage', 0);
                              setShowSecondRoundReviewBox(false);
                            }}
                            sx={{ position: 'absolute', right: 10, top: 8 }}
                          >
                            <CloseIcon
                              sx={{ width: '20px', color: '#828282' }}
                            />
                          </IconButton>
                          <CustomTypography
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                              lineHeight: '16px',
                              color: '#333333',
                            }}
                          >
                            2nd-round review
                          </CustomTypography>
                          <CustomTypography
                            sx={{
                              paddingTop: '8px',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '22px',
                              color: '#333333',
                            }}
                          >
                            Random samples of document will be auto-selected
                          </CustomTypography>
                          <Box
                            paddingTop={'10px'}
                            display={'block'}
                            width={'100%'}
                            alignItems={'center'}
                          >
                            <Box
                              display={'flex'}
                              sx={{
                                width: '100%',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Box paddingRight={'20px'}>
                                <TextField
                                  placeholder={''}
                                  disabled={false}
                                  type='number'
                                  variant='outlined'
                                  // @ts-ignore
                                  value={Number(values.samplePercentage)}
                                  size={'small'}
                                  {...getFieldProps('samplePercentage')}
                                  InputProps={{
                                    'aria-label': 'Sample Percentage',
                                    'aria-required': true,
                                    'aria-describedby': 'sample_percentage',
                                    inputMode: 'numeric',
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    maxWidth: '80px',
                                    width: '80px',
                                    borderRadius: '8px',
                                    height: '41px',
                                    '& input[type=number]': {
                                      MozAppearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button':
                                      {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                      },
                                    '& input[type=number]::-webkit-inner-spin-button':
                                      {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                      },
                                  }}
                                />
                              </Box>
                              <Box width={'177px'}>
                                <Slider
                                  // @ts-ignore
                                  name='samplePercentage'
                                  // @ts-ignore
                                  value={Number(values.samplePercentage)}
                                  {...getFieldProps('samplePercentage')}
                                  aria-label='Default'
                                  valueLabelDisplay='off'
                                  sx={{
                                    color: '#5924EB',
                                    height: 4,
                                    '& .MuiSlider-track': {
                                      border: 'none',
                                    },
                                    '& .MuiSlider-rail': {
                                      color: '#E6E0F6',
                                      opacity: 1,
                                    },
                                    '& .MuiSlider-thumb': {
                                      height: 16,
                                      width: 17,
                                      backgroundColor: '#fff',
                                      border: '2px solid currentColor',
                                      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible':
                                        {
                                          boxShadow: 'inherit',
                                        },
                                      '&:before': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                            {Boolean(
                              touched.samplePercentage &&
                                errors.samplePercentage,
                            ) && (
                              <FormHelperText
                                sx={{ color: '#EB0000', fontSize: '12px' }}
                                id='outlined-weight-helper-text'
                              >
                                {
                                  (touched.samplePercentage &&
                                    errors.samplePercentage) as string
                                }
                              </FormHelperText>
                            )}
                          </Box>
                          <Box paddingTop={'20px'}>
                            <CustomTypography
                              sx={{
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#333333',
                              }}
                            >
                              Reviewers for the 2nd round
                            </CustomTypography>
                            <CustomTypography
                              sx={{
                                paddingTop: '4px',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '22px',
                                color: '#717171',
                              }}
                            >
                              The tasks will go to these reviewers after the
                              initial review
                            </CustomTypography>
                          </Box>
                          <Box
                            sx={{
                              paddingTop: '20px',
                            }}
                          >
                            <AddUserField
                              disabled={
                                (formik.values.selectedMode as WorkflowMode) ===
                                WorkflowMode.AUTOPILOT
                              }
                              hasError={Boolean(
                                touched.roundTwoUsers && errors.roundTwoUsers,
                              )}
                              onChange={handleAddSecondRoundUser}
                              searchList={userEmailList}
                              searchValues={roundTwoUsers}
                            />
                            {Boolean(
                              touched.roundTwoUsers && errors.roundTwoUsers,
                            ) && (
                              <CustomTypography
                                component={'span'}
                                sx={{
                                  color: '#EB0000',
                                  fontSize: '12px',
                                  display: 'block',
                                  marginTop: '8px',
                                }}
                              >
                                {
                                  (touched.roundTwoUsers &&
                                    errors.roundTwoUsers) as string
                                }
                              </CustomTypography>
                            )}
                            {errorRoundTwo !== '' && (
                              <CustomTypography
                                component={'span'}
                                sx={{ color: '#EB0000', fontSize: '12px' }}
                              >
                                {errorRoundTwo}
                              </CustomTypography>
                            )}
                            <Box sx={{}}>
                              {roundTwoUsers.map((user) => {
                                return (
                                  <UserChip
                                    key={user.user}
                                    email={user.user as string}
                                    usedForLearning={isUserForLearning(
                                      user.user as string,
                                    )}
                                    profileImageUrl={
                                      userDetailsMap[user.user as string]
                                        ?.profileImageUrl
                                    }
                                    fullName={
                                      userDetailsMap[user.user as string]
                                        ?.fullName
                                    }
                                    toggleLearningSettings={() =>
                                      toggleLearningSettings(
                                        user.user as string,
                                      )
                                    }
                                    onDelete={() =>
                                      handleDeleteSecondRoundUser(user)
                                    }
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {/* Past Users only show if past users exist */}
                  {pastUsers.length > 0 && (
                    <Accordion
                      sx={{
                        borderRadius: '8px !important',
                        boxShadow: 'none !important',
                        border: `1px solid ${OrbyColorPalette['grey-300']} !important`,
                        marginTop: '24px',
                        '&:before': {
                          display: 'none',
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'center'}
                          gap={'8px'}
                        >
                          <img src={UserIcon} alt={'user'} />
                          <OrbyTypography
                            weight='semibold'
                            sx={{ textAlign: 'center' }}
                            color={OrbyColorPalette['blue-700']}
                          >
                            View past users
                          </OrbyTypography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          border: `1px solid ${OrbyColorPalette['grey-25']}`,
                          margin: '16px',
                          padding: '16px',
                          backgroundColor: OrbyColorPalette['purple-25'],
                        }}
                      >
                        <Box display={'flex'} flexDirection={'column'} gap={4}>
                          {pastUsers.map((pu) => (
                            <UserChip
                              key={pu.username}
                              email={pu.username ?? ''}
                              fullName={pu.fullName}
                              profileImageUrl={pu.imageUrl}
                              usedForLearning={isUserForLearning(
                                pu.username as string,
                              )}
                              isPastUser
                              toggleLearningSettings={() =>
                                toggleLearningSettings(pu.username as string)
                              }
                            />
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Box>
              </Box>
              <Box>
                <Box
                  bgcolor={'#E3FCEF'}
                  borderRadius={'10px'}
                  height={'fit-content'}
                  padding={'16px'}
                  display={'flex'}
                  gap={'10px'}
                  marginY={3}
                  marginRight={5}
                  width={'315px'}
                >
                  <SettingsOutlined sx={{ color: '#3BA755' }} />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'8px'}
                    paddingRight={'10px'}
                  >
                    <>
                      <span id='default_mode'>
                        <CustomTypography color='#545454' size='14px'>
                          Default mode: Orby AI will present all tasks for user
                          review.
                        </CustomTypography>
                      </span>
                      <span id='auto-pilot_mode'>
                        <CustomTypography color='#545454' size='14px'>
                          Auto-pilot mode: Orby AI will automate without the
                          need for user review.
                        </CustomTypography>
                      </span>
                      <span id='assisted_mode'>
                        <CustomTypography color='#545454' size='14px'>
                          Assisted mode: Orby will ask for review based on
                          conditions like low confidence
                          {classificationLabelsIndex
                            ? ` and ${ORBYAI_UNKNOWN} predictions will always be sent for user review.`
                            : '.'}
                        </CustomTypography>
                      </span>
                      {!edit && (
                        <CustomTypography
                          sx={{ marginTop: '10px' }}
                          color='#545454'
                          size='14px'
                        >
                          This field can be changed after workflow creation.{' '}
                        </CustomTypography>
                      )}
                    </>
                  </Box>
                </Box>
                <Box
                  bgcolor={'#E3FCEF'}
                  borderRadius={'10px'}
                  height={'fit-content'}
                  padding={'16px'}
                  display={'flex'}
                  gap={'10px'}
                  marginY={3}
                  marginRight={5}
                  width={'315px'}
                >
                  <SettingsOutlined sx={{ color: '#3BA755' }} />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'8px'}
                    paddingRight={'10px'}
                  >
                    <span id='invite_users'>
                      <CustomTypography color='#545454' size='14px'>
                        Add activated users (a.k.a users who have logged into
                        our website) in your organization to this workflow.
                        Tasks created by the workflow will be assigned to them.
                        For{' '}
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#545454',
                          }}
                        >
                          {' '}
                          &quot;Autopilot&quot;{' '}
                        </span>
                        mode, created tasks will only be assigned to the last
                        admin who created or edited the workflow mode.
                      </CustomTypography>
                      <Box>
                        {gsheetsIndex.actionIndex !== -1 && (
                          <CustomTypography
                            color='#545454'
                            size='14px'
                            sx={{ marginTop: '10px' }}
                          >
                            Make sure all these users have the
                            <CustomTypography
                              color='#545454'
                              size='14px'
                              sx={{ fontWeight: 600, display: 'inline' }}
                            >
                              {' '}
                              &quot;Editor&quot;{' '}
                            </CustomTypography>
                            role for the workflow&apos;s Google Drive folder and
                            Sheets.
                          </CustomTypography>
                        )}
                        {!edit && (
                          <CustomTypography
                            sx={{ marginTop: '10px' }}
                            color='#545454'
                            size='14px'
                          >
                            This field can be changed after workflow creation.{' '}
                          </CustomTypography>
                        )}
                      </Box>
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
            {isLearningSettingModified() && (
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'start'}
                padding={'40px'}
              >
                <OrbyTypography
                  color={OrbyColorPalette['warning-700']}
                  sx={{ maxWidth: '557px' }}
                >
                  Modifying this setting will impact all future predictions by
                  either including or removing feedback given by the users you
                  select.
                </OrbyTypography>
              </Box>
            )}
          </>
        )}
        {isRbacEnabled && [1].includes(selectedTab) && (
          <Box
            width={'632px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'15px'}
            padding={'30px'}
          >
            <CustomTypography
              sx={{
                marginBottom: '14px',
                lineHeight: '21px',
                fontSize: '14px',
              }}
            >
              Share this workflow with other workflow admins, they will be able
              to edit and delete this workflow as well.
            </CustomTypography>
            <Box>
              <CustomTypography
                sx={{
                  marginBottom: '6px',
                  lineHeight: '21px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Add more workflow admins
              </CustomTypography>
              <AddUserField
                hasError={false}
                onChange={(email) => {
                  const index = values.workflowAdmins.findIndex(
                    (u) => u == email,
                  );
                  if (index !== -1) {
                    return;
                  }
                  setFieldValue('workflowAdmins', [
                    ...values.workflowAdmins,
                    email,
                  ]);
                }}
                searchList={userEmailList}
                searchValues={values.workflowAdmins.map((email) =>
                  WorkflowUser.create({ user: email, enabled: true }),
                )}
              />
              <Box>
                {values.workflowAdmins.map((user) => {
                  return (
                    <ShareWorkflowUserChip
                      key={user}
                      email={user}
                      profileImageUrl={userDetailsMap[user]?.profileImageUrl}
                      fullName={userDetailsMap[user]?.fullName}
                      isDeletable={workflow.creator?.username !== user}
                      onDelete={() => {
                        if (workflow.creator?.username === user) {
                          return;
                        }
                        setFieldValue(
                          'workflowAdmins',
                          values.workflowAdmins.filter((u) => u !== user),
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <CustomTypography
                sx={{
                  marginTop: '14px',
                  marginBottom: '6px',
                  lineHeight: '21px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Message
              </CustomTypography>
              <TextField
                label=''
                placeholder='Message'
                multiline
                fullWidth
                rows={4}
                {...getFieldProps('adminEmailMessage')}
              />
            </Box>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};

export default memo(AddUserStepContent);
