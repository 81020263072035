import { Box } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import React, { FC, memo, useEffect } from 'react';
import sftpIcon from '../../../static/icons/sftpIcon.svg';
import docsIcon from '../../../static/icons/Docs.svg';
import { SettingsOutlined } from '@mui/icons-material';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import CustomTypography from '../../../components/CustomTypography';

interface SftpTriggerContentProps {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  formId: string;
  previousClicked: boolean;
}

const SftpTriggerContent: FC<SftpTriggerContentProps> = ({
  workflow,
  onSubmit,
  formId,
  previousClicked,
}) => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const folderPath = `${selectedOrgInfo?.orgDisplayName}/${workflow.displayName}`;

  const formik = useFormik({
    initialValues: {
      folder_path: folderPath,
    },
    onSubmit: (values) => onSubmit(values, true),
  });

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  const { handleSubmit } = formik;

  return (
    <main>
      <FormikProvider value={formik}>
        <Form id={formId} autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box display={'flex'} gap={'100px'} marginY={'16px'} marginX={'34px'}>
            <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
              <Box>
                <CustomTextField
                  label='Application'
                  name='application'
                  size='large'
                  color={'#DDDBDA'}
                  startAdornment={
                    <img
                      alt='SFTP'
                      style={{ marginRight: '6px', marginLeft: '10px' }}
                      src={sftpIcon}
                    />
                  }
                  value={'SFTP Server'}
                  disabled
                />
              </Box>
              <Box>
                <CustomTextField
                  label='Folder Path'
                  name='folder_path'
                  size='large'
                  color={'#DDDBDA'}
                  disabled
                  value={`${selectedOrgInfo?.orgDisplayName}/${workflow.displayName}`}
                />
              </Box>
              <Box>
                <CustomTextField
                  label='Trigger'
                  name='trigger'
                  size='large'
                  color='#DDDBDA'
                  startAdornment={
                    <img
                      alt='Document.'
                      style={{ marginRight: '2px', marginLeft: '10px' }}
                      src={docsIcon}
                    />
                  }
                  disabled
                  value={'Add new files'}
                />
              </Box>
            </Box>
            <Box
              bgcolor={'#E3FCEF'}
              borderRadius={'10px'}
              height={'fit-content'}
              padding={'16px'}
              display={'flex'}
              gap={'10px'}
              marginRight={5}
              marginTop={'25px'}
              width={'360px'}
            >
              <SettingsOutlined sx={{ color: '#3BA755' }} />
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                paddingRight={'10px'}
              >
                <span id='folder_path'>
                  <CustomTypography color='#545454' size='14px'>
                    Task will be triggered for every new file added at this
                    location in the SFTP server. A few new subfolders will be
                    created in this folder. Processed files will be moved to
                    subfolders that reflect their associated task statuses.
                  </CustomTypography>
                </span>
              </Box>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </main>
  );
};

export default memo(SftpTriggerContent);
