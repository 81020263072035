import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OrbyColorPalette from 'orby-ui/src/components/colors/ColorPalette';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import {
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { toastService } from '../../services/ToastService';
import OktaLogo from '../../static/icons/okta.svg';
import DeleteIcon from '../../static/icons/deleteV2.svg';
import EditIcon from '../../static/icons/edit.svg';
import CustomModal from '../../components/CustomModal';
import {
  organizationDetailsLoadingErrorSelector,
  organizationDetailsLoadingSelector,
  organizationDetailsSelector,
  organizationUpdatedSelector,
  organizationUpdatingErrorSelector,
} from '../../redux/selectors/organization.selectors';
import {
  clearOrganizationUpdateStatusAction,
  getOrganizationAction,
  getOrganizationErrorAction,
  updateOrganizationAction,
  updateOrganizationErrorAction,
} from '../../redux/actions/organization.actions';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { SAMLConfig } from 'protos/pb/v1alpha1/saml';
import { getIdFromResourceName } from '../../utils/WorkflowUtils';
import { OrbyButton, OrbyCheckbox, OrbyTypography } from 'orby-ui/src';
import { IOSSwitch } from '../../components/switch/IosType';
import { samlService } from '../../services/SAMLService';
import { storageService } from '../../services/StorageService';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(selectedOrgInfoSelector);
  const organizationDetails = useSelector(organizationDetailsSelector);
  const organizationDetailsLoadingError = useSelector(
    organizationDetailsLoadingErrorSelector,
  );
  const loading = useSelector(organizationDetailsLoadingSelector);
  const organizationUpdated = useSelector(organizationUpdatedSelector);
  const organizationUpdatingError = useSelector(
    organizationUpdatingErrorSelector,
  );

  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [openModal, setOpenModal] = useState<{
    status: boolean;
    isDisable: boolean;
  }>({ status: false, isDisable: false });
  const [isEnableConfigTriggered, setIsEnableConfigTriggered] = useState(false);

  const handleCloseModal = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
    setAcceptAgreement(false);
  };

  const handleDeleteSamlConfig = () => {
    const req = UpdateOrganizationRequest.create({
      fieldMask: ['saml_config'],
      organization: Organization.create({
        name: selectedOrg?.orgResourceName,
        samlConfig: SAMLConfig.create({}), // empty the config
      }),
    });
    dispatch(updateOrganizationAction(req));
  };

  const handleToggleSamlConfig = () => {
    const req = UpdateOrganizationRequest.create({
      fieldMask: ['saml_config.is_activated'],
      organization: Organization.create({
        name: selectedOrg?.orgResourceName,
        samlConfig: SAMLConfig.create({
          isActivated: !organizationDetails?.samlConfig?.isActivated,
        }),
      }),
    });
    dispatch(updateOrganizationAction(req));
  };

  useEffect(() => {
    if (selectedOrg && !loading) {
      const req = GetOrganizationRequest.create({
        name: selectedOrg.orgResourceName,
      });
      dispatch(getOrganizationAction(req));
    }
  }, [selectedOrg?.orgDisplayName]);

  useEffect(() => {
    if (organizationDetailsLoadingError) {
      toastService.showError('Failed to get org', { position: 'top-right' });
      dispatch(getOrganizationErrorAction());
    }
  }, [organizationDetailsLoadingError]);

  useEffect(() => {
    if (organizationUpdated) {
      if (
        isEnableConfigTriggered &&
        organizationDetails?.samlConfig?.isActivated
      ) {
        setIsEnableConfigTriggered(false);
        toastService.showSuccess('This configuration is successfully enabled', {
          position: 'top-right',
        });
      } else {
        toastService.showSuccess(
          openModal.isDisable
            ? 'This configuration is successfully disabled'
            : 'This configuration is permanently deleted',
          {
            position: 'top-right',
          },
        );
      }

      dispatch(clearOrganizationUpdateStatusAction());
      setOpenModal((prev) => ({ ...prev, status: false }));
      setAcceptAgreement(false);
    }
  }, [organizationUpdated]);

  useEffect(() => {
    if (organizationUpdatingError) {
      toastService.showError(organizationUpdatingError.message, {
        position: 'top-right',
      });
      dispatch(updateOrganizationErrorAction());
    }
  }, [organizationUpdatingError]);

  const handleSamlRedirect = async () => {
    try {
      const message = 'Verification failed, please re-check configuration';
      const cookie = await samlService.checkForSamlResponse(message);
      if (!!cookie && 'access_token' in cookie) {
        toastService.showSuccess('Configuration Verified successfully', {
          position: 'top-right',
        });
      } else {
        if (cookie && 'error' in cookie) {
          toastService.showError(cookie.error, { position: 'top-right' });
          return;
        }
        toastService.showError(message, {
          position: 'top-right',
        });
      }
    } catch (e) {
      toastService.showError(e as string, { position: 'top-right' });
    }
  };

  // Checks for saml redirect
  useEffect(() => {
    if (storageService.isSamlRedirect()) {
      handleSamlRedirect();
    }
  });

  return (
    <Box
      height={'100vh'}
      overflow={'hidden'}
      bgcolor={OrbyColorPalette['white-0']}
      padding={'48px'}
      sx={{ overflow: 'auto' }}
    >
      <OrbyTypography size='display-sm' weight='medium'>
        Settings
      </OrbyTypography>
      {loading ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <OrbyTypography weight='medium' sx={{ pt: '30px' }}>
            General
          </OrbyTypography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              borderRadius: '12px',
              mt: '35px',
              p: '24px 32px 24px 24px',
              border: `1px solid ${OrbyColorPalette['purple-100']}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <OrbyTypography size='lg' weight='medium'>
                  Single sign-on
                </OrbyTypography>
                <OrbyTypography
                  color={OrbyColorPalette['grey-500']}
                  sx={{ pt: '12px' }}
                >
                  Enable single sign-on to streamline user access
                </OrbyTypography>
              </Box>
              <OrbyButton
                disabled={!!organizationDetails?.samlConfig?.domain}
                variant='primary-outline'
                onClick={() => {
                  const orgId = getIdFromResourceName(
                    selectedOrg?.orgResourceName as string,
                  );
                  navigate(`/settings/configure-sso/${orgId}`);
                }}
                label='Configure SSO account'
                ariaLabel={'Configure SSO account'}
              />
            </Box>
            {organizationDetails?.samlConfig?.domain && (
              <>
                <Divider
                  sx={{
                    borderBottomWidth: '2px',
                    my: '36px',
                    color: OrbyColorPalette['grey-200'],
                  }}
                />
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <Box sx={{ pl: '24px', pr: '16px' }}>
                      <IOSSwitch
                        onChange={() => {
                          if (organizationDetails.samlConfig?.isActivated) {
                            setOpenModal({ status: true, isDisable: true });
                            return;
                          }
                          setIsEnableConfigTriggered(true);
                          handleToggleSamlConfig();
                        }}
                        checked={organizationDetails.samlConfig.isActivated}
                      />
                    </Box>
                    <img src={OktaLogo} alt='okta-logo' />
                    <OrbyTypography weight={'medium'} sx={{ pl: '16px' }}>
                      OKTA
                    </OrbyTypography>
                    <OrbyTypography weight={'medium'} sx={{ pl: '48px' }}>
                      {`@${organizationDetails.samlConfig.domain}`}
                    </OrbyTypography>
                  </Box>

                  <Box display={'flex'} alignItems={'center'}>
                    <IconButton
                      onClick={() => {
                        setOpenModal({ status: true, isDisable: false });
                      }}
                    >
                      <img src={DeleteIcon} alt='delete' />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        const orgId = getIdFromResourceName(
                          selectedOrg?.orgResourceName as string,
                        );
                        navigate(`/settings/configure-sso/${orgId}`);
                      }}
                    >
                      <img src={EditIcon} alt='edit' />
                    </IconButton>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      <CustomModal
        open={openModal.status}
        containerSx={{ maxWidth: '550px' }}
        heading={`${openModal.isDisable ? 'Disable' : 'Deleting'} OKTA configurations`}
        content={
          <Box>
            <OrbyTypography sx={{ pb: '24px' }}>
              {`Any users currently using this single sign-on method to access
              Orby will be unable to access after the configuration is ${openModal.isDisable ? 'disabled' : 'deleted'}.`}
            </OrbyTypography>
            <OrbyCheckbox
              size='sm'
              checked={acceptAgreement}
              onClick={() => setAcceptAgreement((prev) => !prev)}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  setAcceptAgreement((prev) => !prev);
                }
              }}
              label={`I understand that SSO users will ${openModal.isDisable ? 'temporarily' : 'permanently'}  lose access to OKTA`}
              title={`I understand that SSO users will ${openModal.isDisable ? 'temporarily' : 'permanently'}  lose access to OKTA`}
            />
          </Box>
        }
        primaryDisabled={!acceptAgreement}
        handleClose={handleCloseModal}
        primaryLabel={openModal.isDisable ? 'Disable' : 'Delete'}
        secondaryLabel={'Cancel'}
        secondaryButtonSx={{
          maxWidth: '168px',
        }}
        actionSx={{ justifyContent: 'end' }}
        primaryButtonSx={{
          maxWidth: '168px',
        }}
        buttonSize='large'
        onPrimaryClick={
          openModal.isDisable ? handleToggleSamlConfig : handleDeleteSamlConfig
        }
      />
    </Box>
  );
};
export default React.memo(Settings);
