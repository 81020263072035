import { Box } from '@mui/material';
import CustomTypography from '../../../components/CustomTypography';
import React, { useRef, FC, useState } from 'react';
import UploadIcon from '../../../static/icons/upload_icon.svg';
import UploaderIcon from '../../../static/icons/uploader_icon.svg';
import CheckCircle from '../../../static/icons/check-circle.svg';
import CustomModal from '../../../components/CustomModal';

interface Props {
  style: any;
  handleFileChange: any;
  error?: string | undefined;
  setError: (x: string | undefined) => void;
}

const TextFilePickerComponent: FC<Props> = ({
  style,
  handleFileChange,
  error,
  setError,
}) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(false);
    const files = e?.dataTransfer?.files;
    if (files?.length) {
      const file = files[0];
      setFile(file);
    }
    setError(undefined);
  };

  const downloadSampleFile = () => {
    // Create the CSV content
    const csvContent =
      'entity_name,entity_type,parent_entity_name\n' +
      'order date,date\n' +
      'amount,money\n' +
      'id,integer\n' +
      'decimal value,float\n' +
      'simba id,text\n' +
      'amount,money,line item\n' +
      'annotation,notes,line item';

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'samplefile.csv';

    // Trigger a click event on the download link
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Box sx={{ ...style }}>
        <CustomTypography
          onClick={() => {
            setOpen(true);
            setError(undefined);
          }}
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#1570EF',
          }}
        >
          <img
            alt='Upload from'
            style={{ height: '18px', paddingRight: '4px' }}
            src={UploadIcon}
          />{' '}
          Upload CSV
        </CustomTypography>

        <input
          type='file'
          accept='.csv'
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(event) => {
            const file = event.target.files?.[0];
            if (file) {
              setFile(file);
            }
            (inputRef.current as unknown as HTMLInputElement).value = '';
            setError(undefined);
          }}
        />
      </Box>
      <CustomModal
        containerSx={{ width: '400px' }}
        open={open}
        closable={false}
        primaryButtonSx={{ maxWidth: '50%' }}
        heading={'Upload'}
        handleClose={() => {
          setOpen(false);
          setFile(null);
        }}
        primaryLabel={'Upload File'}
        secondaryLabel={'Cancel'}
        onPrimaryClick={() => {
          handleFileChange(file, setOpen, setFile);
        }}
        content={
          <>
            <CustomTypography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400,
                color: '#667085',
                marginBottom: '20px',
              }}
            >
              Download the
              <span
                onClick={() => downloadSampleFile()}
                style={{
                  color: '#1570EF',
                  cursor: 'pointer',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                sample file
              </span>
              to get started
            </CustomTypography>
            <Box
              component={'div'}
              onClick={() =>
                (inputRef.current as unknown as HTMLInputElement).click()
              }
              height={'189px'}
              sx={{
                cursor: 'pointer',
              }}
              border={'2px dashed #F8F8FF'}
              bgcolor={
                isHovered ? 'rgb(56 78 183 / 30%)' : 'rgb(56 78 183 / 9%)'
              }
              padding={'30px'}
              textAlign={'center'}
              id='drop-zone'
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <img
                alt='Uploader Icon'
                style={{ height: '54px' }}
                src={UploaderIcon}
              />

              <CustomTypography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 700,
                  color: '#0F0F0F',
                  marginTop: '20px',
                }}
              >
                Drag & drop files or
                <span
                  style={{
                    color: '#483EA8',
                    cursor: 'pointer',
                    marginLeft: '4px',
                    borderBottom: '1px solid #483EA8',
                  }}
                >
                  Browse
                </span>
              </CustomTypography>

              <CustomTypography
                sx={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontWeight: 400,
                  color: '#676767',
                  marginTop: '20px',
                }}
              >
                Supported format: CSV
              </CustomTypography>
            </Box>

            {file && (
              <Box
                paddingTop={'20px'}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <CustomTypography>{file?.name}</CustomTypography>
                <img
                  alt='Check Icon'
                  style={{ height: '18px' }}
                  src={CheckCircle}
                />
              </Box>
            )}
            {error && (
              <Box paddingTop={'10px'}>
                <CustomTypography
                  component={'span'}
                  sx={{ color: '#EB0000', fontSize: '12px', display: 'block' }}
                >
                  {error}
                </CustomTypography>
              </Box>
            )}
          </>
        }
      />
    </>
  );
};

export default TextFilePickerComponent;
