import React from 'react';
import {
  PersonOutlineOutlined,
  DescriptionOutlined,
  InsertChartOutlinedRounded,
  AccessTimeOutlined,
  TaskOutlined,
  AssignmentLateOutlined,
  KeyOutlined,
  KitchenTwoTone,
} from '@mui/icons-material';
import { ReactComponent as DeletedItemsIcon } from '../../static/icons/deleted-items.svg';
import { ReactComponent as SettingsIcon } from '../../static/icons/settings.svg';
import { DrawerTabType } from './DrawerTab';

export const DRAWER_TABS: DrawerTabType[] = [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: <InsertChartOutlinedRounded />,
    hoverIcon: <InsertChartOutlinedRounded />,
  },
  {
    title: 'Workflow',
    route: 'workflow',
    icon: <DescriptionOutlined />,
    hoverIcon: <DescriptionOutlined />,
  },
  {
    title: 'Pending Tasks',
    route: 'pending-tasks',
    icon: <AccessTimeOutlined />,
    hoverIcon: <AccessTimeOutlined />,
  },
  {
    title: 'Completed Tasks',
    route: 'completed-tasks',
    icon: <TaskOutlined />,
    hoverIcon: <TaskOutlined />,
  },
  {
    title: 'Declined Tasks',
    route: 'declined-tasks',
    icon: <AssignmentLateOutlined />,
    hoverIcon: <AssignmentLateOutlined />,
  },
  {
    title: 'Deleted Items',
    route: 'deleted-items',
    icon: <DeletedItemsIcon />,
    hoverIcon: <DeletedItemsIcon />,
  },
  {
    title: 'Teams',
    route: 'teams',
    icon: <PersonOutlineOutlined />,
    hoverIcon: <PersonOutlineOutlined />,
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: <SettingsIcon />,
    hoverIcon: <SettingsIcon />,
  },
  {
    title: 'ML Testing',
    route: 'dev-testing',
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
  {
    title: 'Integration Test',
    route: 'integration-test',
    icon: <KitchenTwoTone />,
    hoverIcon: <KitchenTwoTone />,
  },
  {
    title: 'Feature Flags',
    route: 'feature-flags',
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
];
