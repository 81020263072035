import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import FloatingModalHeader from './components/FloatingModalHeader';
import FloatingModalFooter from './components/FloatingModalFooter';
import FloatingModalBody from './components/FloatingModalBody/FloatingModalBody';
import { useDispatch, useSelector } from 'react-redux';
import {
  allowEditingTaskSelector,
  selectedEntityInfoSelector,
  taskForReviewSelector,
} from '../../../../redux/selectors/review_task.selectors';
import FloatingModalNormalizedSection from './components/FloatingModalNormalizedSection/FloatingModalNormalizedSection';
import { FLOATING_MODAL_WIDTH } from '../../../../utils/constants';
import FloatingModalChips from './components/FloatingModalChips';
import FocusTrap from '@mui/material/Unstable_TrapFocus';
import {
  actionRedo,
  actionUndo,
  clearActionHistory,
} from '../../../../redux/actions/review_task.action';

interface Props {
  scale: number;
  maxHeight?: number;
}

const ReviewPageFloatingModal: React.FC<Props> = ({ scale, maxHeight }) => {
  const selectedEntity = useSelector(selectedEntityInfoSelector);
  const task = useSelector(taskForReviewSelector);
  const floatingModalRef = useRef(null);
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const dispatch = useDispatch();

  /**
   * REMOVED THIS BECAUSE WE ARE SHIFTING BOUNDING BOX POSITION ON CLICKING ANY PLACE OVER PDF
   * KEEPING THIS CODE FOR REFERENCE, MAY BE WE WILL NEED THIS IN FUTURE
   */
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (floatingModalRef.current && !floatingModalRef.current.contains(event.target) && !event.target.classList.contains('resizers')  && !event.target.classList.contains('text-segment-normal-bounding-box')) {
  //       dispatch(setSelectedEntityIdAction(null))
  //     }
  //   }
  //   document.addEventListener('mousedown', handleOutsideClick)
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick)
  //   }
  // }, [])

  const handleUndoRedo = (e: KeyboardEvent) => {
    // undo
    if (allowEditingTask) {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ') {
        e.stopImmediatePropagation();
        e.stopPropagation();
        dispatch(actionUndo());
      }
      // redo
      else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyY') {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        dispatch(actionRedo());
      }
    }
  };

  useEffect(() => {
    if (selectedEntity?.id) {
      dispatch(clearActionHistory());
      document.addEventListener('keydown', handleUndoRedo);
      return () => {
        dispatch(clearActionHistory());
        document.removeEventListener('keydown', handleUndoRedo);
      };
    }
  }, [selectedEntity?.id, allowEditingTask]);

  return (
    <FocusTrap open={!!selectedEntity?.id}>
      <Box
        ref={floatingModalRef}
        flex={'0 0 auto'}
        id={'review-page-floating-modal'} // this id will be used apply shake effect to this modal
        width={`${FLOATING_MODAL_WIDTH}px`}
        bgcolor={'red'}
        boxShadow={'1px 1px 10px 0px #00000040'}
        borderRadius={'4px'}
        display='flex'
        flexDirection={'column'}
        maxHeight={maxHeight ? `${maxHeight}px` : undefined}
      >
        {task && selectedEntity && (
          <>
            <Box
              borderRadius={'4px 4px 0px 0px'}
              bgcolor={'#FFFFFF'}
              pb={'16px'}
            >
              <FloatingModalHeader
                key={selectedEntity?.id}
                entity={selectedEntity}
              />
              <FloatingModalChips task={task} entity={selectedEntity} />
            </Box>
            <Box
              bgcolor={'#FFFFFF'}
              sx={{
                boxShadow:
                  '0px 4px 4px -4px #ececec,4px 0px 4px -4px #ececec,-4px 0px 4px -4px #ececec',
              }}
              display={'flex'}
              padding={'0px 24px 16px 24px'}
              flexDirection={'column'}
              overflow={'auto'}
            >
              <FloatingModalBody
                key={selectedEntity?.id}
                task={task}
                entity={selectedEntity}
                scale={scale}
              />
              {/* Only show Normalization Section when there is at least one text segment */}
              {Object.keys(selectedEntity.textSegments).length > 0 && (
                <FloatingModalNormalizedSection
                  task={task}
                  selectedEntity={selectedEntity}
                />
              )}
            </Box>
            {allowEditingTask && (
              <Box
                bgcolor={'#FFFFFF'}
                sx={{
                  borderRadius: '0 0 4px 4px',
                  boxShadow:
                    '0px 4px 4px -4px #ececec,4px 0px 4px -4px #ececec,-4px 0px 4px -4px #ececec',
                }}
                display={'flex'}
                padding={'0px 24px 16px 24px'}
                flexDirection={'column'}
                flexShrink={0}
              >
                <FloatingModalFooter
                  key={selectedEntity?.id}
                  task={task}
                  entity={selectedEntity}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </FocusTrap>
  );
};

export default React.memo(ReviewPageFloatingModal);
