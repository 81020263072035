import { Box, Chip, Typography, styled } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import { Element, ControlType } from 'protos/pb/v1alpha1/element';
import React from 'react';
import { getElementTypeText } from 'workflow-utils/src/helper';

interface IElementsGroupProps {
  type: ControlType;
  elements: Element[];
  onSelect: (element: Element) => void;
}

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${OrbyColorPalette['grey-200']}`,
  backgroundColor: OrbyColorPalette['grey-50'],
  color: OrbyColorPalette['grey-600'],
}));

const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const ElementView = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(0, 3),
  lineHeight: '40px',
  ':hover': {
    backgroundColor: OrbyColorPalette['grey-100'],
  },
}));

const ElementsGroup: React.FC<IElementsGroupProps> = ({
  type,
  elements,
  onSelect,
}) => {
  return (
    <Box>
      <Header>
        <Typography lineHeight='40px'>{getElementTypeText(type)}</Typography>
        <Chip size='small' label={elements.length} />
      </Header>
      <Body>
        {elements.map((element, index) => (
          <ElementView key={index} onClick={() => onSelect(element)}>
            {element.label}
          </ElementView>
        ))}
      </Body>
    </Box>
  );
};

export default ElementsGroup;
