import { Box, Button, IconButton } from '@mui/material';
import React, { FC, memo } from 'react';
import Close from '@mui/icons-material/Close';
import CustomTypography, {
  TypographyType,
} from '../../../../../components/CustomTypography';
import { useDispatch } from 'react-redux';
import { handleAnnotationSuggestionAction } from '../../../../../redux/actions/review_task.action';

interface Props {
  top: number;
  left: number;
}

// When any suggestion is available, this is the popup that is displayed below the suggestion to
// either accept or decline the suggestion for the user
const TableAnnotationPopup: FC<Props> = ({ top, left }) => {
  const dispatch = useDispatch();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={'297px'}
      padding={'16px'}
      position={'absolute'}
      gap={'11px'}
      borderRadius={'12px'}
      bgcolor={'#FFF'}
      boxShadow={
        '0px 4px 12px 0px rgba(16, 24, 40, 0.10), 0px 0px 15px -4px rgba(16, 24, 40, 0.56)'
      }
      zIndex={7}
      // 32 to leave space for decline/accept single row buttons
      top={top + 32}
      left={left}
    >
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <CustomTypography
          typographyType={TypographyType.Header4}
          size='16px'
          lineHeight='28px'
          color='#101828'
        >
          Apply changes
        </CustomTypography>
        <IconButton
          onClick={() => {
            dispatch(handleAnnotationSuggestionAction(false));
          }}
        >
          <Close sx={{ color: '#090909' }} />
        </IconButton>
      </Box>
      <CustomTypography size='12px' lineHeight='24px' color='#101828'>
        Would you like to apply similar annotations to the rest of the table?
      </CustomTypography>
      <Box
        width={'100%'}
        paddingTop={'12px'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'12px'}
      >
        <Button
          onClick={() => {
            dispatch(handleAnnotationSuggestionAction(false));
          }}
          sx={{
            display: 'flex',
            padding: '10px 18px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '40px',
            border: '1px solid #912018',
            background: '#FFF',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            flex: '1 0 0',
          }}
        >
          <CustomTypography
            typographyType={TypographyType.Header4}
            size='16px'
            lineHeight='24px'
            color='#912018'
          >
            Decline
          </CustomTypography>
        </Button>

        <Button
          onClick={() => {
            dispatch(handleAnnotationSuggestionAction(true));
          }}
          sx={{
            display: 'flex',
            padding: '10px 18px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '40px',
            border: '1px solid #027A48',
            background: '#027A48',
            ':hover': { bgcolor: '#027A48' },
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            flex: '1 0 0',
          }}
        >
          <CustomTypography
            typographyType={TypographyType.Header4}
            size='16px'
            lineHeight='24px'
            color='#FFF'
          >
            Apply
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(TableAnnotationPopup);
