import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CustomComboButtonsNew from '../../../components/CustomComboButtonsNew';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { User } from 'protos/pb/v1alpha1/user';
import { UpdateRequest } from 'protos/pb/v1alpha1/users_service';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUpdatedUserAction,
  updateUserAction,
  updateUserErrorAction,
} from '../../../redux/actions/user.action';
import {
  selectedOrgInfoSelector,
  updatedUserSelector,
  updateUserErrorSelector,
} from '../../../redux/selectors/user.selectors';
import { toastService } from '../../../services/ToastService';
import { shiftFocus } from '../../../utils/FocusUtils';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: User;
  onSuccess: () => void;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const DeleteUserModal: React.FC<Props> = ({
  open,
  setOpen,
  user,
  onSuccess,
  setSelectedUser,
}) => {
  const dispatch = useDispatch();
  const updatedUser = useSelector(updatedUserSelector);
  const updateUserError = useSelector(updateUserErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const [creationTriggered, setCreationTriggered] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (updateUserError) {
      toastService.showError(updateUserError.message, {
        position: 'top-right',
      });
      dispatch(updateUserErrorAction(undefined));
      setSelectedUser(undefined);
    }
  }, [updateUserError]);

  useEffect(() => {
    if (creationTriggered && updatedUser) {
      toastService.showSuccess(
        `User ${updatedUser.email} is removed successfully`,
        {
          position: 'top-right',
        },
      );
      onSuccess();
      dispatch(setUpdatedUserAction(undefined));
      setCreationTriggered(false);
      setOpen(false);
      setSelectedUser(undefined);
    }
  }, [creationTriggered, updatedUser]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  // To Trap Focus within the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const boxContentElement = boxRef.current;
        if (boxContentElement) {
          const focusableElements = boxContentElement.querySelectorAll(
            'button, [tabindex]:not([tabindex="-1"])',
          );
          if (focusableElements.length > 0) {
            shiftFocus(focusableElements, event);
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (open) {
      const elm = document.getElementById('root');
      if (elm) {
        elm.removeAttribute('aria-hidden');
      }
    }
  }, [open]);

  return (
    <Dialog
      ref={boxRef}
      PaperProps={{
        style: {
          width: '408px',
          borderRadius: '10px',
          boxShadow: '0px 4px 40px 0px #00000026',
          padding: '8px 0px',
        },
      }}
      open={open}
      onClose={() => {
        setSelectedUser(undefined);
        setOpen(false);
      }}
    >
      <DialogTitle>
        <OrbyTypography
          color={OrbyColorPalette['grey-900']}
          size='xl'
          weight='semibold'
        >
          Delete user from organization
        </OrbyTypography>
      </DialogTitle>

      <DialogContent>
        <OrbyTypography
          color={OrbyColorPalette['grey-900']}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
          weight='medium'
        >
          Are you sure you want to remove <b> {user?.email ?? ''} </b>from your
          organization?
        </OrbyTypography>
      </DialogContent>

      <DialogActions>
        <CustomComboButtonsNew
          primaryLabel='Cancel'
          secondaryLabel='Confirm'
          secondaryDisabled={false}
          size={'medium'}
          onPrimaryClick={() => {
            setOpen(false);
            setSelectedUser(undefined);
          }}
          onSecondaryClick={() => {
            if (selectedOrgInfo) {
              const req: UpdateRequest = {};
              req.orgResourceName = selectedOrgInfo.orgResourceName;
              const u = { ...user };
              // Backend expects an empty orgInfos array to remove the user from the selected org
              u.orgInfos = [];
              req.fieldMask = ['org_infos'];
              req.user = u;
              dispatch(updateUserAction(req));
              setCreationTriggered(true);
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DeleteUserModal);
