import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectedTaskEntityInfoSelector,
  suggestionAnnotationTaskEntityInfoSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import EntitiesInfo from './EntitiesInfo';
import { EntityFilter } from '../../../../../../utils/constants';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { Box } from '@mui/material';

interface Props {
  selectedReviewFilterSection: EntityFilter | undefined;
  task: Task;
}

const SideBarEntitiesList: React.FC<Props> = ({
  task,
  selectedReviewFilterSection,
}) => {
  const entityInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const suggestionAnnotationTaskEntityInfo = useSelector(
    suggestionAnnotationTaskEntityInfoSelector,
  );

  return (
    <Box
      padding={'0 10px 10px 10px'}
      flexGrow={1}
      sx={{
        borderBottom: '1px solid rgba(209, 209, 209, 1)',
      }}
      overflow={'auto'}
    >
      {/* This entities info list displays all the entities info present in the document */}
      <EntitiesInfo
        task={task}
        selectedReviewFilterSection={selectedReviewFilterSection}
        entityInfoMap={entityInfoMap}
      />
      {/* This entities info list displays all the entities info of the annotation suggestion entities */}
      <EntitiesInfo
        task={task}
        selectedReviewFilterSection={selectedReviewFilterSection}
        entityInfoMap={suggestionAnnotationTaskEntityInfo || {}}
        isSuggestionEntityBox
      />
    </Box>
  );
};

export default React.memo(SideBarEntitiesList);
