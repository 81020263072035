import produce from 'immer';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { Reducer } from 'redux';
import { WorkflowActionType } from '../actions/actions.constants';
import { colorValuesUtil } from '../../utils/ColorValuesUtil';

export interface WorkflowState {
  workflows: Workflow[];
  nextPageToken?: string;
  totalSize?: number;
  workflowTemplates: Workflow[];
  workflowTemplatesNextPageToken?: string;
  workflowTemplateTotalSize?: number;
  createdWorkflow?: Workflow;
  updatedWorkflow?: Workflow;
  loadingWorkflows: boolean;
  loadedWorkflows: boolean;
  loadingWorkflowTemplates: boolean;
  loadedWorkflowTemplates: boolean;
  listWorkflowError?: Error;
  listTemplateWorkflowError?: Error;
  processingWorkflow: boolean;
  processWorkflowError?: Error;
  workflowColors: { [x: string]: string };
  selectedWorkflow?: Workflow;
  selectedWorkflowLoading: boolean;
  selectedWorkflowError?: Error;
}

const initialState: WorkflowState = {
  workflows: [],
  workflowTemplates: [],
  loadingWorkflows: false,
  loadedWorkflows: false,
  loadingWorkflowTemplates: false,
  loadedWorkflowTemplates: false,
  processingWorkflow: false,
  workflowColors: {},
  selectedWorkflowLoading: false,
};

export const workflowReducer: Reducer<WorkflowState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: WorkflowState = initialState,
  action: any,
) =>
  produce(state, (draft: WorkflowState) => {
    switch (action.type) {
      case WorkflowActionType.LIST_WORKFLOWS:
        draft.loadingWorkflows = true;
        break;
      case WorkflowActionType.LIST_WORKFLOWS_COMPLETED: {
        if (action.refresh) {
          draft.workflows = action.payload;
        } else {
          draft.workflows.push(...action.payload);
        }
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingWorkflows = false;
        draft.loadedWorkflows = true;
        break;
      }
      case WorkflowActionType.LIST_WORKFLOWS_ERROR: {
        draft.loadingWorkflows = false;
        draft.listWorkflowError = action.payload;
        break;
      }
      case WorkflowActionType.LIST_TEMPLATE_WORKFLOWS:
        draft.loadingWorkflowTemplates = true;
        break;
      case WorkflowActionType.LIST_TEMPLATE_WORKFLOWS_COMPLETED: {
        if (action.refresh) {
          draft.workflowTemplates = action.payload;
        } else {
          draft.workflowTemplates.push(...action.payload);
        }
        draft.workflowTemplatesNextPageToken = action.nextPageToken;
        draft.workflowTemplateTotalSize = action.totalSize;
        draft.loadingWorkflowTemplates = false;
        draft.loadedWorkflowTemplates = true;
        break;
      }
      case WorkflowActionType.LIST_TEMPLATE_WORKFLOWS_ERROR: {
        draft.loadingWorkflowTemplates = false;
        draft.listTemplateWorkflowError = action.payload;
        break;
      }
      case WorkflowActionType.CREATE_WORKFLOW:
        draft.processingWorkflow = true;
        break;
      case WorkflowActionType.CREATE_WORKFLOW_COMPLETED: {
        draft.createdWorkflow = action.payload;
        draft.workflows.unshift(action.payload);
        draft.totalSize = (draft.totalSize ?? 0) + 1;
        draft.processingWorkflow = false;
        break;
      }
      case WorkflowActionType.CREATE_WORKFLOW_ERROR: {
        draft.processWorkflowError = action.payload;
        draft.processingWorkflow = false;
        break;
      }
      case WorkflowActionType.UPDATE_WORKFLOW:
        draft.processingWorkflow = true;
        break;
      case WorkflowActionType.UPDATE_WORKFLOW_COMPLETED: {
        const workflow: Workflow = action.payload;
        const index: number = draft.workflows.findIndex(
          (w) => w.name === workflow.name,
        );
        draft.updatedWorkflow = action.payload;
        draft.workflows.splice(index, 1, workflow);
        draft.processingWorkflow = false;
        break;
      }
      case WorkflowActionType.UPDATE_WORKFLOW_ERROR: {
        draft.processWorkflowError = action.payload;
        draft.processingWorkflow = false;
        break;
      }
      case WorkflowActionType.DELETE_WORKFLOW: {
        draft.processingWorkflow = true;
        break;
      }
      case WorkflowActionType.DELETE_WORKFLOW_COMPLETED: {
        const updatedWorkflow = draft.workflows.find(
          (w) => w.name === action.payload,
        );
        draft.workflows = draft.workflows.filter(
          (w) => w.name !== action.payload,
        );
        draft.totalSize = (draft.totalSize ?? 0) - 1;
        draft.updatedWorkflow = updatedWorkflow;
        draft.processingWorkflow = false;
        break;
      }
      case WorkflowActionType.DELETE_WORKFLOW_ERROR: {
        draft.processingWorkflow = false;
        draft.processWorkflowError = action.payload;
        break;
      }
      case WorkflowActionType.SET_WORKFLOW_COLORS: {
        const workflowNames = [...new Set(action.payload)];
        const colorCodes = colorValuesUtil.getColorList(
          workflowNames.map((w) => w as string),
        );
        workflowNames.map((w, i) => {
          if (!draft?.workflowColors?.[w as string]) {
            draft.workflowColors[w as string] = colorCodes[i];
          }
        });
        break;
      }
      case WorkflowActionType.SET_SELECTED_WORKFLOW: {
        draft.selectedWorkflow = action.payload;
        break;
      }
      case WorkflowActionType.GET_WORKFLOW: {
        draft.selectedWorkflowLoading = true;
        break;
      }
      case WorkflowActionType.GET_WORKFLOW_COMPLETED: {
        draft.selectedWorkflowLoading = false;
        draft.selectedWorkflow = action.payload;
        break;
      }
      case WorkflowActionType.GET_WORKFLOW_ERROR: {
        draft.selectedWorkflowLoading = false;
        draft.selectedWorkflowError = action.payload;
        break;
      }
      default:
        break;
    }
  });
