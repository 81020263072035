import * as React from 'react';
import CustomTypography from '../../components/CustomTypography';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomModal from '../../components/CustomModal';
import { ArchivedResourceType } from '../../utils/constants';
import { getPathAccToResource } from '../Utils/archivedResourcesUtils';

interface RestoreItemsSuccessModelProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  isShowLink?: boolean;
  resourceType: ArchivedResourceType;
}

const RestoreItemsSuccessModel: React.FC<RestoreItemsSuccessModelProps> = ({
  open,
  handleClose,
  onSubmit,
  isShowLink = true,
  resourceType,
}) => {
  const isTask = resourceType === ArchivedResourceType.TASK;
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={onSubmit}
      heading={'Restoration successful'}
      content={
        <Box>
          <CustomTypography>
            {`Selected items have been restored ${isShowLink ? 'here:' : ''}`}
          </CustomTypography>
          {isShowLink && (
            <Link to={`/${getPathAccToResource(resourceType)}`}>
              <CustomTypography sx={{ pt: '8px' }} color='#0500FF'>
                {`${process.env.REACT_APP_REDIRECT_URI}/${getPathAccToResource(
                  resourceType,
                )}`}
              </CustomTypography>
            </Link>
          )}
        </Box>
      }
      primaryLabel={isTask ? 'OK' : `Go to restored ${resourceType}s`}
      secondaryLabel={isTask ? '' : 'OK'}
    />
  );
};

export default React.memo(RestoreItemsSuccessModel);
