// @ts-ignore
import { PublicClientApplication } from '@azure/msal-browser';
import { MicrosoftUserInfo } from 'protos/pb/v1alpha1/user';
import {
  MICROSOFT_COMMON_URL,
  MICROSOFT_CONSUMER_URL,
  MICROSOFT_USER_INFO_TYPE,
} from '../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { storageService } from './StorageService';

// Source code here: https://learn.microsoft.com/en-us/onedrive/developer/controls/file-pickers/?view=odsp-graph-online
class OnedriveFilePickerService {
  private static instance: OnedriveFilePickerService;

  public static getInstance(): OnedriveFilePickerService {
    if (!this.instance) {
      this.instance = new OnedriveFilePickerService();
    }
    return this.instance;
  }

  private origin = process.env.REACT_APP_REDIRECT_URI;
  private clientId = process.env.REACT_APP_AZURE_CLIENT_ID || '';
  private channelId = uuidv4();

  private options = {
    sdk: '8.0',
    entry: {
      oneDrive: {},
    },
    authentication: {},
    messaging: {
      origin: this.origin,
      channelId: this.channelId,
    },
  };

  // @ts-ignore
  private combine(...paths) {
    return paths
      .map((path: any) => path.replace(/^[\\|/]/, '').replace(/[\\|/]$/, ''))
      .join('/')
      .replace(/\\/g, '/');
  }

  // This is used to authenticate microsoft user on sharepoint for him to access all
  // files that the account has access to. This token is valid till the time picker is open
  // Opens a new window for authentication and then opens file picker once authenticated
  // Source code here: https://learn.microsoft.com/en-us/onedrive/developer/controls/file-pickers/?view=odsp-graph-online#get-token
  async getToken(
    app: PublicClientApplication,
    command: any,
    microsoftInfo: MicrosoftUserInfo,
  ): Promise<string> {
    let accessToken = '';
    const authParams = {
      scopes:
        microsoftInfo.type === MICROSOFT_USER_INFO_TYPE.PERSONAL
          ? // Using OneDrive.ReadWrite for personal user
            ['OneDrive.ReadWrite']
          : [`${this.combine(command.resource, '.default')}`],
    };

    try {
      // see if we have already the idtoken saved
      const resp = await app.acquireTokenSilent(authParams);
      accessToken = resp.accessToken;
    } catch (e) {
      // per examples we fall back to popup
      const resp = await app.loginPopup(authParams);
      app.setActiveAccount(resp.account);
      if (resp.idToken) {
        const resp2 = await app.acquireTokenSilent(authParams);
        accessToken = resp2.accessToken;
      } else {
        // throw the error that brought us here
        throw e;
      }
    }
    return accessToken;
  }

  private port: MessagePort | null = null;

  async openFilePicker(): Promise<{ id: string; url: string } | undefined> {
    const microsoftUserInfo = await storageService.getStoredMicrosoftInfo();
    const msalParams = {
      auth: {
        authority:
          microsoftUserInfo?.type === MICROSOFT_USER_INFO_TYPE.PERSONAL
            ? MICROSOFT_CONSUMER_URL
            : MICROSOFT_COMMON_URL,
        clientId: this.clientId,
        redirectUri: this.origin,
      },
    };
    const app = new PublicClientApplication(msalParams);
    await app.initialize();
    // create a new window. The Picker's recommended maximum size is 1080x680, but it can scale down to
    // a minimum size of 250x230 for very small screens or very large zoom.
    const win = window.open('', 'Picker', 'width=1080,height=680');
    if (!win) return undefined;
    let authToken;
    try {
      // we need to get an authentication token to use in the form below (more information in auth section)
      authToken = await this.getToken(
        app,
        {
          resource: microsoftUserInfo?.baseDriveUrl,
          command: 'authenticate',
          type: 'SharePoint',
        },
        microsoftUserInfo!,
      );
    } catch (e: any) {
      win.close();
      throw e;
    }

    if (!authToken) return undefined;

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(this.options),
      locale: 'en-us',
    });

    // we create the absolute url by combining the base url, appending the _layouts path, and including the query string for business type account
    const url =
      microsoftUserInfo?.type === MICROSOFT_USER_INFO_TYPE.PERSONAL
        ? `${microsoftUserInfo.baseDriveUrl}?${queryString}`
        : `${microsoftUserInfo!.baseDriveUrl}/_layouts/15/FilePicker.aspx?${queryString}`;

    // create a form
    const form = win.document.createElement('form');

    // set the action of the form to the url defined above
    // This will include the query string options for the picker.
    form.setAttribute('action', url);

    // must be a post request
    form.setAttribute('method', 'POST');

    // Create a hidden input element to send the OAuth token to the Picker.
    // This optional when using a popup window but required when using an iframe.
    const tokenInput = win.document.createElement('input');
    tokenInput.setAttribute('type', 'hidden');
    tokenInput.setAttribute('name', 'access_token');
    tokenInput.setAttribute('value', authToken);
    form.appendChild(tokenInput);

    // append the form to the body
    win.document.body.append(form);

    // submit the form, this will load the picker page
    form.submit();
    // this adds a listener to the current (host) window, which the popup or embed will message when ready
    return new Promise((resolve) => {
      window.addEventListener('message', (event) => {
        if (event.source && event.source === win) {
          const message = event.data;

          // the channelId is part of the configuration options, but we could have multiple pickers so that is supported via channels
          // On initial load and if it ever refreshes in its window, the Picker will send an 'initialize' message.
          // Communication with the picker should subsequently take place using a `MessageChannel`.
          if (
            message.type === 'initialize' &&
            message.channelId === this.options.messaging.channelId
          ) {
            // grab the port from the event
            this.port = event.ports[0];

            // add an event listener to the port (example implementation is in the next section)
            this.port.addEventListener('message', (e) =>
              this.channelMessageListener(
                e,
                app,
                win,
                (command) => {
                  const item = command.items[0];
                  resolve({
                    id: item.id,
                    url: item.webDavUrl,
                  });
                },
                microsoftUserInfo!,
              ),
            );

            // start ("open") the port
            this.port.start();

            // tell the picker to activate
            this.port.postMessage({
              type: 'activate',
            });
          }
        }
      });
    });
  }

  async channelMessageListener(
    message: MessageEvent,
    app: PublicClientApplication,
    window: Window,
    onFilePick: (command: any) => void,
    microsoftInfo: MicrosoftUserInfo,
  ): Promise<void> {
    const payload = message.data;

    if (!this.port) return;

    switch (payload.type) {
      case 'notification': {
        const notification = payload.data;

        if (notification.notification === 'page-loaded') {
          // here we know that the picker page is loaded and ready for user interaction
        }
        break;
      }
      case 'command': {
        // all commands must be acknowledged
        this.port.postMessage({
          type: 'acknowledge',
          id: message.data.id,
        });

        // this is the actual command specific data from the message
        const command = payload.data;

        // command.command is the string name of the command
        switch (command.command) {
          case 'authenticate':
            // the first command to handle is authenticate. This command will be issued any time the picker requires a token
            // 'getToken' represents a method that can take a command and return a valid auth token for the requested resource
            try {
              const token = await this.getToken(app, command, microsoftInfo);

              if (!token) {
                throw new Error('Unable to obtain a token.');
              }

              // we report a result for the authentication via the previously established port
              this.port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'token',
                  token: token,
                },
              });
            } catch (error: any) {
              this.port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'error',
                  error: {
                    code: 'unableToObtainToken',
                    message: error.message,
                  },
                },
              });
            }
            break;
          case 'close':
            window.close();
            break;
          case 'pick':
            try {
              onFilePick(command);
              // let the picker know that the pick command was handled (required)
              this.port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'success',
                },
              });
            } catch (error: any) {
              this.port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'error',
                  error: {
                    code: 'unusableItem',
                    message: error.message,
                  },
                },
              });
            }
            window.close();
            break;
          default:
            // Always send a reply, if that reply is that the command is not supported.
            this.port.postMessage({
              type: 'result',
              id: message.data.id,
              data: {
                result: 'error',
                error: {
                  code: 'unsupportedCommand',
                  message: command.command,
                },
              },
            });
            window.close();
            break;
        }
        break;
      }
    }
  }
}

export const onedriveFilePickerService =
  OnedriveFilePickerService.getInstance();
