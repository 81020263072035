import React from 'react';
import { OrbyBadge, OrbyBodyLabel, OrbyColorPalette } from 'orby-ui/src';
import { Field } from 'protos/pb/v1alpha1/field';
import { ELLIPSIS_STYLE } from '../../../utils/constants';

export const getSNo = (index: number, noLabel = false): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={`${index + 1}`}
      key={`${index + 1}`}
      label={noLabel ? '' : `${index + 1}`}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

export const getLineItemQuantity = (fields?: Field[]): JSX.Element => {
  let description = '';

  if (fields?.length) {
    const descField = fields?.find((f) => f.name === 'line item/quantity');
    if (descField) {
      description = descField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={description}
      key={description}
      label={description}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export const getLineItemAmount = (fields?: Field[]): JSX.Element => {
  let amount = '';

  if (fields?.length) {
    const amountField = fields?.find((f) => f.name === 'line item/amount');
    if (amountField) {
      amount = amountField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={amount}
      key={amount}
      label={amount}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export const getLineItemTax = (fields?: Field[]): JSX.Element => {
  let taxRate = '';

  if (fields?.length) {
    const taxField = fields?.find((f) => f.name === 'line item/tax rate');
    if (taxField) {
      taxRate = taxField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={taxRate}
      key={taxRate}
      label={taxRate}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export const getLineItemUnitCost = (fields?: Field[]): JSX.Element => {
  let unitCost = '';

  if (fields?.length) {
    const costField = fields?.find((f) => f.name === 'line item/unit cost');
    if (costField) {
      unitCost = costField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={unitCost}
      key={unitCost}
      label={unitCost}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export const getLineItemDescription = (fields?: Field[]): JSX.Element => {
  let description = '';

  if (fields?.length) {
    const costField = fields?.find((f) => f.name === 'line item/description');
    if (costField) {
      description = costField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={description}
      key={description}
      label={description}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

export const getLineItemDate = (fields?: Field[]): JSX.Element => {
  let date = '';

  if (fields?.length) {
    const dateField = fields?.find((f) => f.name === 'line item/date');
    if (dateField) {
      date = dateField.value?.text || '';
      // if(date){
      //  formatDate(new Date(task?.serviceDate), 'MM/YYYY'),
      // }
    }
  }

  return (
    <OrbyBodyLabel
      title={date}
      key={date}
      label={date}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export const getPOlineNumber = (
  lineNo: string,
  noMatch: boolean,
): JSX.Element => {
  return (
    <OrbyBadge
      backgroundColor={
        noMatch ? OrbyColorPalette['yellow-25'] : OrbyColorPalette['green-100']
      }
      textColor={
        noMatch ? OrbyColorPalette['orange-700'] : OrbyColorPalette['green-700']
      }
      badgeName={noMatch ? 'No match' : lineNo}
      size='small'
      boxSx={{
        width: '76px',
        justifyContent: 'center',
      }}
      textSx={ELLIPSIS_STYLE}
    />
  );
};

export const getTableColumnsForExtractedInvoice = [
  {
    width: `34px`,
    maxWidth: `34px`,
    minWidth: `34px`,
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderBottom: `1px solid ${OrbyColorPalette['grey-200']}`,
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    padding: '8px 8px',
  },
];

export const getTableColumnsForExtractedInvoiceExpanded = [
  {
    width: `34px`,
    maxWidth: `34px`,
    minWidth: `34px`,
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderBottom: `1px solid ${OrbyColorPalette['grey-200']}`,
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '132px',
    maxWidth: '132px',
    minWidth: '132px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '86px',
    maxWidth: '86px',
    minWidth: '86px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '60px',
    maxWidth: '60px',
    minWidth: '60px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    padding: '8px 8px',
  },
];

export const getTableHeaderForExtarctedInvoice = [
  {
    width: `34px`,
    maxWidth: `34px`,
    minWidth: `34px`,
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    background: OrbyColorPalette['grey-50'],
    padding: '8px 8px',
  },
];

export const getTableHeaderForExtarctedInvoiceExpanded = [
  {
    width: `34px`,
    maxWidth: `34px`,
    minWidth: `34px`,
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '132px',
    maxWidth: '132px',
    minWidth: '132px',
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '108px',
    maxWidth: '108px',
    minWidth: '108px',
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '86px',
    maxWidth: '86px',
    minWidth: '86px',
    padding: '8px 8px',
    background: OrbyColorPalette['grey-50'],
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '60px',
    maxWidth: '60px',
    minWidth: '60px',
    background: OrbyColorPalette['grey-50'],
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    background: OrbyColorPalette['grey-50'],
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    background: OrbyColorPalette['grey-50'],
    padding: '8px 8px',
    borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
  },
  {
    width: '80px',
    maxWidth: '80px',
    minWidth: '80px',
    background: OrbyColorPalette['grey-50'],
    padding: '8px 8px',
  },
];
