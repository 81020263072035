import { createSelector } from 'reselect';
import { workflowSelector } from './app.selectors';

export const workflowListSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflows,
);

export const workflowsLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.loadingWorkflows,
);

export const workflowsLoadedSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.loadedWorkflows,
);

export const workflowsNextPageTokenSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.nextPageToken,
);

export const workflowsTotalSizeSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.totalSize,
);

export const workflowTemplatesListSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplates,
);

export const workflowTemplatesLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.loadingWorkflowTemplates,
);

export const workflowTemplatesLoadedSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.loadedWorkflowTemplates,
);

export const workflowTemplatesNextPageTokenSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplatesNextPageToken,
);

export const workflowTemplatesTotalSizeSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowTemplateTotalSize,
);

export const selectedWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.selectedWorkflow,
);

export const selectedWorkflowLoadingSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.selectedWorkflowLoading,
);

export const selectedWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.selectedWorkflowError,
);

export const processWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.processWorkflowError,
);

export const processingWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.processingWorkflow,
);

export const createdWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.createdWorkflow,
);

export const updatedWorkflowSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.updatedWorkflow,
);

export const listWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.listWorkflowError,
);

export const listTemplateWorkflowErrorSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.listTemplateWorkflowError,
);

export const workflowColorsSelector = createSelector(
  [workflowSelector],
  (workflowState) => workflowState.workflowColors,
);
