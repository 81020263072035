import { Box, IconButton, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { EditableText } from './EditableText';
import { ReactComponent as BackArrowIcon } from '../../../static/icons/back-arrow.svg';
import CustomModal from '../../../components/CustomModal';
import { OrbyTypography } from 'orby-ui/src';

const FullPage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  flex: 1,
}));

const BgBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  background:
    'linear-gradient(2deg, #E4DDED 26.97%, rgba(255, 255, 255, 0.00) 100%)',
}));

const Page = styled(Box)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(0, 15),
  background: theme.palette.background.default,
  boxShadow: '0px 9px 10px 0px rgba(0, 0, 0, 0.25)',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 100,
  padding: theme.spacing(0, 15, 0, 8),
  background: 'rgba(243, 244, 252, 0.60)',
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(7),
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  letterSpacing: 1,
}));

interface IProps {
  children: JSX.Element;
  title?: string;
  description?: string;
  // If true, the logo will be replaced with a back button
  enableLogoBack?: boolean;
  action?: JSX.Element;
  onTitleChange?: (newTitle: string) => void;
  hasUnsavedChanges?: boolean;
  onSave?: () => void;
  onDiscard?: () => void;
}

const Frame: React.FC<IProps> = ({
  title,
  description,
  children,
  onTitleChange,
  action,
  enableLogoBack,
  hasUnsavedChanges,
  onSave,
  onDiscard,
}) => {
  const [shouldShowUnsavedChanges, setShouldShowUnsavedChanges] =
    useState(false);

  const navigate = useNavigate();

  const back = () => {
    if (hasUnsavedChanges) {
      setShouldShowUnsavedChanges(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    if (!history.state || history.state.idx === 0) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <FullPage>
      <Header>
        <LogoBox>
          {enableLogoBack && (
            <IconButton onClick={back} aria-label='Go Back'>
              <BackArrowIcon />
            </IconButton>
          )}
          <Box flex={1} marginRight={6}>
            {description && <Description>{description}</Description>}
            <EditableText
              disabled={!onTitleChange}
              fontSize={20}
              fontWeight={700}
              initialText={title || ''}
              onTextChange={onTitleChange}
              emptyErrorMessage={'Title cannot be empty'}
            />
          </Box>
        </LogoBox>
        {action}
      </Header>
      <BgBox>
        <Page>{children}</Page>
      </BgBox>
      <CustomModal
        open={shouldShowUnsavedChanges || false}
        heading='You have unsaved change'
        content={
          <OrbyTypography>Do you want to save your changes?</OrbyTypography>
        }
        handleClose={() => {
          setShouldShowUnsavedChanges(false);
        }}
        primaryLabel='Save'
        secondaryLabel='Discard'
        onPrimaryClick={() => {
          onSave?.();
          setShouldShowUnsavedChanges(false);
          navigateBack();
        }}
        onSecondaryClick={() => {
          onDiscard?.();
          setShouldShowUnsavedChanges(false);
          navigateBack();
        }}
      />
    </FullPage>
  );
};

export default Frame;
