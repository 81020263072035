import React, { memo } from 'react';
import { isFeatureFlagEnabled } from '../../FeatureFlags/FeatureFlagUtils';
import { useSelector } from 'react-redux';
import {
  getFeatureFlagsForOrgAndUserLoadingSelector,
  getFeatureFlagsForOrgAndUserSelector,
} from '../../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../../utils/constants';
import NewConnectorCreationPage from './ConnectorCreationPage';
import ConnectorCreationPage from '../../../pages/WorkflowConnector/ConnectorCreationPage';
import { Box, CircularProgress } from '@mui/material';

const CreateConnector = () => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const featureFlagsLoading = useSelector(
    getFeatureFlagsForOrgAndUserLoadingSelector,
  );
  const isWebAppIntegrationEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.WEB_APP_INTEGRATION,
    featureFlags,
  );

  if (featureFlagsLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vH'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isWebAppIntegrationEnabled ? (
    <NewConnectorCreationPage />
  ) : (
    <ConnectorCreationPage />
  );
};

export default memo(CreateConnector);
