import { Box } from '@mui/material';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityInfo } from '../../../../../redux/reducers/review_task.reducer';
import { suggestionAnnotationTaskEntityInfoSelector } from '../../../../../redux/selectors/review_task.selectors';
import { boxPositionValuesUtilV2 } from '../../../../../utils/BoxPositionValuesUtilV2';
import { v4 as uuidv4 } from 'uuid';
import TableAnnotationPopup from './TableAnnotationPopup';
import { Close, Done } from '@mui/icons-material';
import CustomTypography from '../../../../../components/CustomTypography';
import {
  handleAnnotationSuggestionAction,
  setSelectedEntityIdAction,
} from '../../../../../redux/actions/review_task.action';

interface Props {
  scale: number;
  currentPage: number;
}

const TableAnnotationSuggestionLayout: React.FC<Props> = ({
  scale,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const suggestionAnnotationTaskEntityInfo = useSelector(
    suggestionAnnotationTaskEntityInfoSelector,
  );
  const suggestionsInsideTable = Object.values(
    suggestionAnnotationTaskEntityInfo || {},
  ).filter((e: EntityInfo) => e.isInsideTable);
  const [selectedParentEntityId, setSelectedParentEntityId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!suggestionAnnotationTaskEntityInfo) {
      setSelectedParentEntityId(undefined);
    } else {
      // If there is some suggestion info to display, we do not want to show floating modal window
      dispatch(setSelectedEntityIdAction());
    }
  }, [suggestionAnnotationTaskEntityInfo]);

  // Gets all the vertices of entities present in suggestion map
  const getAllTextSegmentVertices = () => {
    const vertices: Vertex[] = [];
    suggestionsInsideTable.map((entityInfo: EntityInfo) => {
      const textSegment = entityInfo.textSegments[0];
      if (textSegment.page === currentPage) {
        vertices.push(...textSegment.vertices);
      }
    });
    return vertices;
  };

  // Get the bottom left vertex from vertices of all the entities combined
  // that shares the same parent id
  const getBottomLeftVertexOfSelectedParent = (
    selectedParentEntityId: string,
  ): Vertex => {
    const vertices: Vertex[] = [];
    suggestionsInsideTable.map((entityInfo: EntityInfo) => {
      const textSegment = entityInfo.textSegments[0];
      if (
        textSegment.page === currentPage &&
        entityInfo.parentEntityId === selectedParentEntityId
      ) {
        vertices.push(...textSegment.vertices);
      }
    });
    return boxPositionValuesUtilV2.getBottomLeftCornerVertices(vertices);
  };

  // If no suggestion info, or suggestion info's first textSegment page is not equal to current page, do not show any component
  if (
    !suggestionAnnotationTaskEntityInfo ||
    suggestionsInsideTable?.[0].textSegments[0].page !== currentPage
  )
    return <></>;

  const boxPositionValues = boxPositionValuesUtilV2.getBoxPositionValues(
    getAllTextSegmentVertices(),
    scale,
  );
  const left = boxPositionValues.left;
  const bottomLeftVertex = getBottomLeftVertexOfSelectedParent(
    selectedParentEntityId || '',
  );
  const suggestionAnnotationLastEntityId = Object.values(
    suggestionAnnotationTaskEntityInfo,
  )[suggestionsInsideTable.length - 1];

  return (
    <>
      <Box
        key={uuidv4()}
        position={'absolute'}
        top={boxPositionValues.top}
        left={boxPositionValues.left}
        width={boxPositionValues.width}
        height={boxPositionValues.height}
        sx={{ borderStyle: 'dashed' }}
        border={'2px solid #6941C6'}
      />
      {suggestionsInsideTable.map((entityInfo: EntityInfo) => {
        const textSegment = entityInfo.textSegments[0];

        if (textSegment.page !== currentPage) return;

        const boxPositionValues = boxPositionValuesUtilV2.getBoxPositionValues(
          textSegment.vertices,
          scale,
        );
        return (
          <Box
            key={uuidv4()}
            position={'absolute'}
            top={boxPositionValues.top}
            left={boxPositionValues.left}
            width={boxPositionValues.width}
            height={boxPositionValues.height}
            sx={{ borderStyle: 'dashed', zIndex: 1 }}
            border={
              entityInfo.parentEntityId === selectedParentEntityId
                ? '2px solid #6941C6'
                : '0px'
            }
            onClick={() => {
              setSelectedParentEntityId(entityInfo.parentEntityId);
            }}
          />
        );
      })}
      {selectedParentEntityId && bottomLeftVertex && (
        <Box
          display={'flex'}
          position={'absolute'}
          width={'500px'}
          top={(bottomLeftVertex.y as number) * scale}
          left={(bottomLeftVertex.x as number) * scale}
          zIndex={6}
        >
          <Box
            display={'flex'}
            gap={'5px'}
            padding={'3px 6px'}
            bgcolor={'#DD6A6A'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(handleAnnotationSuggestionAction(false));
            }}
          >
            <Close sx={{ color: 'white' }} />
            <CustomTypography color='white'>Decline</CustomTypography>
          </Box>
          <Box
            display={'flex'}
            gap={'5px'}
            padding={'3px 6px'}
            bgcolor={'#0F8470'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(
                handleAnnotationSuggestionAction(true, selectedParentEntityId),
              );
            }}
          >
            <Done sx={{ color: 'white' }} />
            <CustomTypography color='white'>Accept Prediction</CustomTypography>
          </Box>
        </Box>
      )}
      {getBottomLeftVertexOfSelectedParent(
        suggestionAnnotationLastEntityId.parentEntityId || '',
      ) && (
        <TableAnnotationPopup
          left={left > 297 ? left - 297 : left}
          top={boxPositionValues.top + boxPositionValues.height}
        />
      )}
    </>
  );
};

export default React.memo(TableAnnotationSuggestionLayout);
