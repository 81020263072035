import { ControlPoint } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/CustomTypography';
import CustomFilterSelect from '../../../components/generic/CustomFilterSelect';
import { FieldArray } from 'formik';
import {
  AttributeType,
  Condition,
  LogicalOperator,
  Operator,
} from 'protos/pb/v1alpha2/connector';
import React, { FC, memo, useEffect, useState } from 'react';
import deleteIcon from '../../../static/icons/delete.svg';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  getAttributeDataType,
  getAttributeFilters,
  getClassificationLabels,
} from '../../../utils/helpers';
import EntityFilterSelect from './components/EntityFilterSelect';
import AttributeContent from '../../../components/AttributeContent';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { workflowService } from '../../../services/WorkflowService';

const LogicalOperatorValues = [
  {
    value: LogicalOperator.AND,
    label: 'and',
  },
  {
    value: LogicalOperator.OR,
    label: 'or',
  },
  {
    value: LogicalOperator.NOT,
    label: 'not',
  },
];

const OperatorValues = [
  {
    value: Operator.GREATER_THAN,
    label: 'Greater than',
  },
  {
    value: Operator.LESS_THAN,
    label: 'Less than',
  },
  {
    value: Operator.EQUAL,
    label: 'Equal to',
  },
];
interface ConditionGroupBoxProps {
  groupIndex: number;
  getFieldProps: any;
  group: any;
  removeGroup: any;
  setFieldValue: any;
  values: any;
  isLast: boolean;
}

const ConditionGroupBox: FC<ConditionGroupBoxProps> = ({
  groupIndex,
  getFieldProps,
  group,
  removeGroup,
  setFieldValue,
  values,
  isLast,
}) => {
  // TODO: use store to fetch workflow Details after this PR is merged https://github.com/orby-ai-engineering/orby-web-app/pull/930
  const [workflow, setWorkflow] = useState<Workflow | null>(null);

  const fetchWorkflowDetails = async (id: string) => {
    const { response } = await workflowService.getWorkflow(id);
    if (response) {
      setWorkflow(response);
    }
  };

  useEffect(() => {
    if (values?.source_workflow) {
      setWorkflow(null);
      fetchWorkflowDetails(values.source_workflow);
    }
  }, [values?.source_workflow]);

  // This function categorizes conditions into parent-child relationships,
  // ensuring nested child conditions inside parent entities.
  // It also preserves the condition index for easy editing or deletion.
  const getUpdatedConditions = () => {
    return group.conditions.reduce(
      (
        accumulator: {
          parent?: string;
          children?: { attributeType: AttributeType; conditionIndex: number }[];
          conditionIndex?: number;
        }[],
        condition: Condition,
        conditionIndex: number,
      ) => {
        const parent = condition?.attributeType?.parent;
        if (parent) {
          // Check if the parent already exists in the accumulator
          const existingParent = accumulator.find(
            (item) => item.parent === parent,
          );

          if (existingParent) {
            // If the parent exists, add the current condition as a child
            existingParent.children!.push({
              attributeType: condition.attributeType!,
              conditionIndex,
            });
          } else {
            // If the parent doesn't exist, create a new parent with the current condition as a child
            accumulator.push({
              parent,
              children: [
                { attributeType: condition.attributeType!, conditionIndex },
              ],
            });
          }
        } else {
          // If there's no parent, add the condition as a standalone item
          accumulator.push({
            conditionIndex,
          });
        }

        return accumulator;
      },
      [],
    );
  };

  useEffect(() => {
    if (!isLast && !group?.conditions?.length) {
      removeGroup(groupIndex);
    }
  }, [isLast, group]);

  const getClassificationLabelsList = (): {
    value: string;
    label: string;
  }[] => {
    if (!values.source_workflow && !workflow) return [];
    return getClassificationLabels(workflow as Workflow);
  };

  const getAttributes = () => {
    if (!values.source_workflow && !workflow) return [];
    return getAttributeFilters(workflow as Workflow);
  };

  const getAttributeType = (attribute: string) => {
    if (!values.source_workflow && !workflow)
      return EntityDataType.ENTITY_TYPE_NESTED;
    return getAttributeDataType(attribute, workflow as Workflow);
  };

  const getAttributeContent = (attribute: string, name: string) => {
    return (
      <AttributeContent
        attributeType={getAttributeType(attribute)}
        name={name}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        getClassificationLabelsList={getClassificationLabelsList}
      />
    );
  };

  const renderConditionBox = (
    groupIndex: number,
    conditionIndex: number,
    remove: (index: number) => void,
  ) => {
    // If for any first condition in the list, logical operator has a value then we set it to undefined because there is no condition
    // above it to add a logical operator. Otherwise this results in wrong condition group object
    if (
      conditionIndex === 0 &&
      values.conditionGroups[groupIndex].conditions[conditionIndex]
        .logical_operator !== undefined
    ) {
      setFieldValue(
        `conditionGroups.${groupIndex}.conditions.${conditionIndex}.logical_operator`,
        undefined,
      );
    }
    return (
      <Box
        paddingBottom={'10px'}
        paddingTop={'10px'}
        key={`conditionGroups.${groupIndex}.conditions.${conditionIndex}`}
        paddingX={'10px'}
        paddingLeft={'20px'}
        display={'flex'}
        gap={'14px'}
        alignItems={'center'}
      >
        {conditionIndex > 0 ? (
          <CustomFilterSelect
            name={`conditionGroups.${groupIndex}.conditions.${conditionIndex}.logical_operator`}
            width={'90px'}
            padding={'8.4px'}
            height={'32px'}
            filters={LogicalOperatorValues}
            value={''}
            extraProps={getFieldProps(
              `conditionGroups.${groupIndex}.conditions.${conditionIndex}.logical_operator`,
            )}
          />
        ) : (
          <span style={{ width: '90px' }} />
        )}
        <CustomTypography
          component={'span'}
          typographyType={TypographyType.Header5}
          weight={400}
          sx={{ paddingTop: '9px' }}
        >
          When
        </CustomTypography>
        <EntityFilterSelect
          entityDetails={getAttributes()}
          name={`conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType`}
          getFieldProps={getFieldProps}
        />
        <CustomFilterSelect
          name={`conditionGroups.${groupIndex}.conditions.${conditionIndex}.operator`}
          width={'30%'}
          padding={'8.7px'}
          height={'32px'}
          filters={OperatorValues}
          value={''}
          extraProps={getFieldProps(
            `conditionGroups.${groupIndex}.conditions.${conditionIndex}.operator`,
          )}
        />
        <Box width={'40%'}>
          {getAttributeContent(
            getFieldProps(
              `conditionGroups.${groupIndex}.conditions.${conditionIndex}.attributeType.name`,
            ).value,
            `conditionGroups.${groupIndex}.conditions.${conditionIndex}.value`,
          )}
        </Box>
        <IconButton
          aria-label='Delete Group'
          size='small'
          sx={{ marginTop: '10px' }}
          onClick={() => {
            remove(conditionIndex);
          }}
        >
          <img src={deleteIcon} alt='Delete' />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box key={groupIndex}>
      <FieldArray name={`conditionGroups.${groupIndex}.conditions`}>
        {({ push, remove }) => (
          <Box
            key={`conditionGroups.${groupIndex}`}
            paddingX={'30px'}
            width={'100%'}
            marginTop={'20px'}
          >
            {groupIndex > 0 && (
              <CustomFilterSelect
                name={`conditionGroups.${groupIndex}.logical_operator`}
                width={'80px'}
                padding={'10px'}
                height={'32px'}
                bottom={'30px'}
                filters={LogicalOperatorValues}
                value={' '}
                extraProps={getFieldProps(
                  `conditionGroups.${groupIndex}.logical_operator`,
                )}
              />
            )}
            <Box
              minHeight={'112px'}
              bgcolor={'#f8f7ff'}
              borderRadius={'8px'}
              padding={'10px'}
              paddingBottom={'25px'}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'97%'}
              >
                <span />
                <IconButton
                  sx={{
                    color: '#1669F7',
                    borderRadius: '6px',
                  }}
                  onClick={() => {
                    push({
                      attributeType: {
                        name: '',
                        parent: '',
                      },
                      operator: Operator.GREATER_THAN,
                      value: '',
                      logical_operator: LogicalOperator.AND,
                    });
                  }}
                >
                  <ControlPoint fontSize='small' sx={{ marginRight: '5px' }} />
                  <CustomTypography color='span' weight={500}>
                    Add condition
                  </CustomTypography>
                </IconButton>
              </Box>
              {getUpdatedConditions().map(
                ({
                  parent,
                  conditionIndex,
                  children,
                }: {
                  parent: string;
                  conditionIndex: number;
                  children: {
                    attributeType: AttributeType;
                    conditionIndex: number;
                  }[];
                }) => {
                  if (parent) {
                    return (
                      <Box
                        marginTop={'10px'}
                        paddingBottom={'10px'}
                        borderRadius={'8px'}
                        border={'1px solid #EAECF0'}
                        key={parent}
                        bgcolor={'#EFF8FF'}
                      >
                        <Box
                          paddingBottom={'10px'}
                          paddingTop={'20px'}
                          paddingRight={'10px'}
                          paddingLeft={'30px'}
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <CustomTypography
                            lineHeight='16px'
                            typographyType={TypographyType.MediumPara}
                          >
                            {parent}
                          </CustomTypography>
                          <IconButton
                            aria-label='Delete parent'
                            size='small'
                            sx={{ marginTop: '10px' }}
                            onClick={() => {
                              // Deleting all the related children when a parent entity is deleted.
                              setFieldValue(
                                `conditionGroups.${groupIndex}.conditions`,
                                group.conditions.filter(
                                  (c: Condition) =>
                                    c.attributeType!.parent !== parent,
                                ),
                              );
                            }}
                          >
                            <img src={deleteIcon} alt='Delete' />
                          </IconButton>
                        </Box>
                        {children.map(
                          ({ conditionIndex }: { conditionIndex: number }) => {
                            return renderConditionBox(
                              groupIndex,
                              conditionIndex,
                              remove,
                            );
                          },
                        )}
                      </Box>
                    );
                  } else {
                    return renderConditionBox(
                      groupIndex,
                      conditionIndex,
                      remove,
                    );
                  }
                },
              )}
            </Box>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

export default memo(ConditionGroupBox);
