import React, { useEffect, useState } from 'react';
import {
  styled,
  BoxProps,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Collapse,
  badgeClasses,
  iconButtonClasses,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { chipClasses } from '@mui/material/Chip';
import { ActionObject } from 'workflow-utils/src/types';
import { getClickableWidgetInfo } from 'workflow-utils/src/helper';
import {
  getNumStepsForActions,
  getNumStepsForAction,
} from 'workflow-utils/src/v2/helper';
import { useDispatch } from 'react-redux';
import { ItemDescription } from '../../ExecutionHistory/tabs/ui-automations/details/component/Styled';
import { ScreenChip } from '../../ExecutionHistory/tabs/ui-automations/details/component/Chip';
import { WidgetType } from '../../../redux/reducers/workflow_detail.reducer';

import IfCondition from './IfCondition';
import GotoActionDescription from './GotoActionDescription';
import DropdownWidget from './DropdownWidget';
import { OrbyBadge, OrbyColorPalette } from 'orby-ui/src';
import {
  addActionAfter,
  addFalseConditionAction,
  removeAction,
} from '../../../redux/actions/workflow_details.constants';
import SetValueActionDescription from './SetValueActionDescription';
import LinkedWorkflow from './LinkedWorkflow';
import FlagKeywordsDescription from './FlagKeywordsDescription';
import ClickableWidget from './Clickable';
import PlainDescription from './PlainDescription';
import {
  containFlagKeywordsAction,
  containControlFlow,
  containConditionAction,
  containClickAction,
  containGotoAction,
  containSetValueAction,
  containCreateTaskAction,
} from 'workflow-utils/src/v2/action-classifier';
import { allowAddingElseBlock } from 'workflow-utils/src/v2/workflow';
import { cumulativeSum } from 'workflow-utils/src/helper';

interface IActionGroupProps extends BoxProps {
  action: ActionObject;
  startIndex: number;
  onAddStep: () => void;
  onRemoveStep: () => void;
  onAddElseStep: () => void;
  isEditable: boolean;
  processId?: string;
  traceIndices?: number[];
}

const RowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}));

const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  flex: 1,
}));

const ActionContainer = styled(ColumnContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  margin: 0,
  width: '100%',
}));

const BranchBox = styled(RowContainer)(({ theme }) => ({
  gap: theme.spacing(1),
  width: '100%',
}));

const StyledActionItem = styled(ColumnContainer)({
  padding: 0,
  margin: 0,
  overflow: 'hidden',
  [`& .${badgeClasses.badge}`]: {
    position: 'static',
    transform: 'none',
    marginRight: '4px',
  },
});

interface DescriptionContainerProps {
  isMenuOpen?: boolean;
}

const DescriptionContainer = styled(RowContainer, {
  shouldForwardProp(propName) {
    return propName !== 'isMenuOpen';
  },
})<DescriptionContainerProps>(({ isMenuOpen }) => {
  const hoverStyle = {
    '& .widget-container': {
      backgroundColor: grey[50],
    },
    [`& .${iconButtonClasses.root}, & .${chipClasses.root}`]: {
      visibility: 'visible',
    },
  };
  const normalStyle = {
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
    '&:hover': hoverStyle,
  };
  return isMenuOpen ? { ...normalStyle, ...hoverStyle } : normalStyle;
});

const ScreenShot = styled(Box)<BoxProps>(() => ({
  width: '100%',
  borderRadius: '20px',
  padding: '32px 44px',
  backgroundColor: '#FAFAFA',
}));

const renderControlFlowActions = (
  action: ActionObject,
  startIndex: number,
  isEditable: boolean,
  processId?: string,
) => {
  if (action.foreach) {
    return (
      <ForeachAction
        action={action}
        startIndex={startIndex}
        isEditable={isEditable}
        processId={processId}
      />
    );
  }
  if (action.condition) {
    return (
      <ConditionAction
        action={action}
        startIndex={startIndex}
        isEditable={isEditable}
        processId={processId}
      />
    );
  }
};

interface IForeachActionProps {
  action: ActionObject;
  isEditable: boolean;
  startIndex: number;
  processId?: string;
}

const ForeachAction: React.FC<IForeachActionProps> = ({
  action,
  isEditable,
  startIndex,
  processId,
}) => {
  const dispatch = useDispatch();

  const [subActions, setSubActions] = useState(
    action.foreach?.loopActions || [],
  );

  useEffect(() => {
    setSubActions(action?.foreach?.loopActions || []);
  }, [action.foreach?.loopActions]);

  const handleAddStep = (action: ActionObject) => {
    dispatch(addActionAfter(action.id!, processId!));
  };

  const handleRemoveStep = (action: ActionObject) => {
    dispatch(removeAction(action.id!, processId!));
  };

  const addElseConditionAction = (action: ActionObject) => {
    dispatch(addFalseConditionAction(action.id!, processId!));
  };

  const startIndices = cumulativeSum(subActions.map(getNumStepsForAction)).map(
    (i) => i + startIndex,
  );

  return (
    <ActionContainer key={startIndex}>
      {subActions
        .filter((a) => !a.jsFunction)
        .map((a, i) => (
          <ActionItem
            key={i}
            action={a}
            startIndex={startIndices[i]}
            onAddStep={() => handleAddStep(a)}
            onRemoveStep={() => handleRemoveStep(a)}
            onAddElseStep={() => addElseConditionAction(a)}
            isEditable={isEditable}
            processId={processId}
          />
        ))}
    </ActionContainer>
  );
};

interface IConditionActionProps {
  action: ActionObject;
  isEditable: boolean;
  startIndex: number;
  processId?: string;
}

const ConditionAction: React.FC<IConditionActionProps> = ({
  action,
  isEditable,
  startIndex,
  processId,
}) => {
  const dispatch = useDispatch();

  const [trueActions, setTrueActionGroups] = useState(
    action.condition!.thenActions || [],
  );
  const [falseActionGroups, setFalseActionGroups] = useState(
    action.condition!.elseActions || [],
  );

  useEffect(() => {
    setTrueActionGroups(action.condition!.thenActions || []);
  }, [action?.condition?.thenActions]);

  useEffect(() => {
    setFalseActionGroups(action?.condition!.elseActions || []);
  }, [action?.condition?.elseActions]);

  const handleAddStep = (action: ActionObject) => {
    dispatch(addActionAfter(action.id!, processId!));
  };

  const handleRemoveStep = (action: ActionObject) => {
    dispatch(removeAction(action.id!, processId!));
  };

  const addElseConditionActionGroup = (action: ActionObject) => {
    // TODO: test this, not sure if it works
    dispatch(addFalseConditionAction(action.id!, processId!));
  };

  const numTrueSteps = getNumStepsForActions(trueActions);
  return (
    <ActionContainer key={startIndex}>
      <BranchBox>
        <ColumnContainer>
          {trueActions
            ?.filter((a) => !a.jsFunction)
            ?.map((a, i) => (
              <ActionItem
                key={i}
                action={a}
                startIndex={startIndex + i}
                onAddStep={() => handleAddStep(a)}
                onRemoveStep={() => handleRemoveStep(a)}
                onAddElseStep={() => addElseConditionActionGroup(a)}
                isEditable={isEditable}
                processId={processId}
              />
            ))}
        </ColumnContainer>
      </BranchBox>
      {(falseActionGroups || []).length > 0 && (
        <>
          <DescriptionContainer>
            <ItemDescription sx={{ ml: '26px' }}>Otherwise</ItemDescription>
          </DescriptionContainer>
          <BranchBox>
            <ColumnContainer>
              {falseActionGroups
                ?.filter((a) => !a.jsFunction)
                ?.map((a, i) => (
                  <ActionItem
                    key={i}
                    action={a}
                    startIndex={numTrueSteps + startIndex + i}
                    onAddStep={() => handleAddStep(a)}
                    onRemoveStep={() => handleRemoveStep(a)}
                    onAddElseStep={() => addElseConditionActionGroup(a)}
                    isEditable={isEditable}
                    processId={processId}
                  />
                ))}
            </ColumnContainer>
          </BranchBox>
        </>
      )}
    </ActionContainer>
  );
};

const renderDescription = (
  action: ActionObject,
  isEditable: boolean,
  hasRecording: boolean,
  processId?: string,
): JSX.Element => {
  if (containConditionAction(action)) {
    return (
      <IfCondition
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containClickAction(action)) {
    const description = action.description || '';
    const clickableWidgetInfo = getClickableWidgetInfo(description);

    if (clickableWidgetInfo) {
      return (
        <ClickableWidget
          action={action}
          clickableWidgetInfo={clickableWidgetInfo}
          isEditable={isEditable}
          hasRecording={hasRecording}
          processId={processId}
        />
      );
    }
  } else if (containGotoAction(action)) {
    return (
      <GotoActionDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containSetValueAction(action)) {
    return (
      <SetValueActionDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containCreateTaskAction(action)) {
    return <LinkedWorkflow action={action} />;
  } else if (containFlagKeywordsAction(action)) {
    return (
      <FlagKeywordsDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  }
  return <PlainDescription action={action} />;
};

const renderWidget = (
  action: ActionObject,
  processId?: string,
): JSX.Element => {
  const widget = action.widget!;
  switch (widget) {
    case WidgetType.WidgetSelector:
      return <DropdownWidget action={action} processId={processId} />;
    case WidgetType.Click:
      return <></>; // TODO: implement UX Design, for now, use Editing Clickable Widget as default
    default:
      return <PlainDescription action={action} />;
  }
};

const ActionItem: React.FC<IActionGroupProps> = ({
  action,
  startIndex,
  onAddStep,
  onRemoveStep,
  onAddElseStep,
  isEditable,
  processId,
  ...props
}: IActionGroupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isScreenShotOpen, setIsScreenShotOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const addStepAndCloseMenu = () => {
    onAddStep();
    closeMenu();
  };

  const addAddElseStepAndCloseMenu = () => {
    onAddElseStep();
    closeMenu();
  };

  const removeStepAndCloseMenu = () => {
    onRemoveStep();
    closeMenu();
  };

  const hasControlFlow = containControlFlow(action!);
  const imgUrl = !hasControlFlow && action.screenshot?.url;
  const hasRecording = !!imgUrl;

  return (
    <StyledActionItem {...props}>
      <ColumnContainer>
        <DescriptionContainer sx={{ gap: 3 }} isMenuOpen={!!anchorEl}>
          <RowContainer sx={{ gap: '12px', alignItems: 'center' }}>
            <OrbyBadge
              size={'medium'}
              badgeName={`${startIndex + 1}`}
              textColor={OrbyColorPalette['purple-600']}
              backgroundColor={OrbyColorPalette['grey-100']}
              textSx={{
                fontSize: '0.75rem',
              }}
              boxSx={{
                height: '32px',
              }}
            />
            {action.widget
              ? renderWidget(action, processId)
              : renderDescription(action, isEditable, hasRecording, processId)}
          </RowContainer>
          {imgUrl && (
            <ScreenChip
              sx={{ visibility: 'hidden' }}
              isOpen={isScreenShotOpen}
              label='1 screen'
              onClick={() => setIsScreenShotOpen(!isScreenShotOpen)}
            />
          )}
          {isEditable && (
            <IconButton
              sx={{ visibility: 'hidden' }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </DescriptionContainer>
        {imgUrl && (
          <Collapse sx={{ width: '100%' }} in={isScreenShotOpen}>
            <ScreenShot>
              <img src={imgUrl} width='100%' alt='' />
            </ScreenShot>
          </Collapse>
        )}
        {hasControlFlow &&
          renderControlFlowActions(
            action,
            startIndex + 1,
            isEditable,
            processId,
          )}
      </ColumnContainer>
      {isEditable && (
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem onClick={addStepAndCloseMenu}>Add Step</MenuItem>
          <MenuItem onClick={removeStepAndCloseMenu}>Remove Step</MenuItem>
          {/* If condition actions has only true action, allow user to add false action (else block) */}
          {allowAddingElseBlock(action) && (
            <MenuItem onClick={addAddElseStepAndCloseMenu}>
              Add otherwise
            </MenuItem>
          )}
          {/* <MenuItem onClick={deleteAllSteps}>Delete All Steps</MenuItem> */}
        </Menu>
      )}
    </StyledActionItem>
  );
};

export default ActionItem;
