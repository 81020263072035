import React, { forwardRef } from 'react';
import { Box, BoxProps, Typography, styled } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';

const RowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}));

const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  flex: 1,
}));

const ExecutionContainer = styled(RowContainer)(() => ({
  width: '100%',
  marginBottom: '10px',
  justifyContent: 'space-between',
  '& .widget-container': {
    backgroundColor: OrbyColorPalette['indigo-50'],
    '&:hover': {
      backgroundColor: OrbyColorPalette['indigo-100'],
    },
  },
}));

const ActionContainer = styled(ColumnContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  margin: 0,
  width: '100%',
}));

const WidgetContainer = forwardRef<unknown, BoxProps>((props, ref) => (
  <RowContainer
    ref={ref}
    className='widget-container'
    pl={3}
    pr={3}
    gap={2}
    {...props}
  >
    {props.children}
  </RowContainer>
));

WidgetContainer.displayName = 'WidgetContainer';

const ItemDescription = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  fontSize: 18,
  lineHeight: '36px',
  borderRadius: '7px',
  color: theme.palette.text.secondary,
}));

export {
  RowContainer,
  ColumnContainer,
  WidgetContainer,
  ActionContainer,
  ExecutionContainer,
  ItemDescription,
};
