import { ControlPoint } from '@mui/icons-material';
import { Box, Checkbox, IconButton } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/CustomTypography';
import CustomFilterSelect from '../../../components/generic/CustomFilterSelect';
import {
  FieldArray,
  Form,
  FormikProvider,
  FormikValues,
  useFormik,
} from 'formik';
import React, { FC, memo, useState } from 'react';
import * as Yup from 'yup';
import {
  Connector,
  LogicalOperator,
  Operator,
} from 'protos/pb/v1alpha2/connector';
import { getGroupConditionsFromCompositeGroupConditions } from '../../../utils/helpers';
import ConditionGroupBox from './ConditionGroupBox';
import { Filter, STEP_CONTENT_LAST_STEP_WIDTH } from '../../../utils/constants';

interface Props {
  connector?: Connector;
  workflowFilters: Filter[];
  onSubmit: (values: FormikValues) => void;
}

const validationSchema = Yup.object().shape({
  source_workflow: Yup.string().required('Source Workflow is required'),
  destination_workflow: Yup.string().required(
    'Destination Workflow is required',
  ),
  conditionGroups: Yup.array().of(
    Yup.object().shape({
      conditions: Yup.array().of(
        Yup.object().shape({
          operator: Yup.string().required('Operator is required'),
          value: Yup.string().required('Value is required'),
          attributeType: Yup.object().shape({
            parent: Yup.string(),
            name: Yup.string().required('Attribute is required'),
          }),
        }),
      ),
    }),
  ),
});

const CreateConnectorCondition: FC<Props> = ({
  onSubmit,
  connector,
  workflowFilters,
}) => {
  const [checked, setChecked] = useState(
    connector?.assignmentConfig?.preserveAssignee || false,
  );

  const initialValues = () => {
    if (!connector) {
      return {
        source_workflow: undefined,
        destination_workflow: undefined,
        preserve_assignee: checked,
        conditionGroups: [
          {
            conditions: [
              {
                operator: Operator.GREATER_THAN,
                value: '',
                logical_operator: undefined,
                attributeType: { parent: '', name: '' },
              },
            ],
            logical_operator: undefined,
          },
        ],
      };
    }
    return {
      source_workflow: connector.sourceWorkflow?.workflowResourceName,
      destination_workflow: connector.destinationWorkflow?.workflowResourceName,
      preserve_assignee: checked,
      conditionGroups: getGroupConditionsFromCompositeGroupConditions(
        connector.groupCondition!,
        undefined,
        undefined,
        true,
      ),
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id='form2' autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box
          gap={6}
          display={'flex'}
          flexDirection={'column'}
          paddingX={'10px'}
          paddingY={'40px'}
          paddingBottom={'60px'}
          width={STEP_CONTENT_LAST_STEP_WIDTH}
        >
          <Box
            paddingX={'10px'}
            paddingLeft={'30px'}
            display={'flex'}
            gap={2}
            alignItems={'center'}
          >
            <CustomTypography
              component={'span'}
              typographyType={TypographyType.Header5}
              weight={400}
              sx={{ paddingTop: '9px' }}
            >
              For
            </CustomTypography>
            <CustomFilterSelect
              ellipsis={true}
              name='source_workflow'
              width={'350px'}
              padding={'10px'}
              height={'32px'}
              error={Boolean(touched.source_workflow && errors.source_workflow)}
              helperText={touched.source_workflow && errors.source_workflow}
              placeholder='Select source workflow'
              filters={workflowFilters}
              value={' '}
              extraProps={getFieldProps('source_workflow')}
              autoFocus={true}
            />
          </Box>
          <FieldArray name='conditionGroups'>
            {({ push, form, remove }) => {
              return (
                <Box marginTop={'-33px'}>
                  <Box
                    sx={{
                      position: 'relative',
                      top: '-45px',
                      right: '-77%',
                      display: 'inline',
                    }}
                  >
                    <IconButton
                      sx={{ color: '#1669F7', borderRadius: '6px' }}
                      onClick={() => {
                        push({
                          conditions: [
                            {
                              operator: Operator.GREATER_THAN,
                              value: '',
                              attributeType: {
                                name: '',
                                parent: '',
                              },
                            },
                          ],
                          logical_operator: LogicalOperator.AND,
                        });
                      }}
                    >
                      <ControlPoint
                        fontSize='small'
                        sx={{ marginRight: '5px' }}
                      />
                      <CustomTypography color='span' weight={500}>
                        Add condition group
                      </CustomTypography>
                    </IconButton>
                  </Box>

                  {form.values.conditionGroups.map(
                    (group: any, groupIndex: number) => (
                      <ConditionGroupBox
                        key={groupIndex}
                        getFieldProps={getFieldProps}
                        groupIndex={groupIndex}
                        group={group}
                        removeGroup={remove}
                        setFieldValue={setFieldValue}
                        values={values}
                        isLast={form.values.conditionGroups.length === 1}
                      />
                    ),
                  )}
                </Box>
              );
            }}
          </FieldArray>
          <Box
            paddingLeft={'30px'}
            display={'flex'}
            gap={2}
            alignItems={'center'}
          >
            <CustomTypography
              component={'span'}
              typographyType={TypographyType.Header5}
              weight={400}
              sx={{ paddingTop: '9px' }}
            >
              Go to
            </CustomTypography>
            <CustomFilterSelect
              ellipsis={true}
              name='destination_workflow'
              width={'350px'}
              padding={'10px'}
              height={'32px'}
              error={Boolean(
                touched.destination_workflow && errors.destination_workflow,
              )}
              helperText={
                touched.destination_workflow && errors.destination_workflow
              }
              placeholder='Select destination workflow'
              filters={workflowFilters}
              value={''}
              extraProps={getFieldProps('destination_workflow')}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          margin={'40px'}
          gap={'8px'}
        >
          <Checkbox
            checked={checked}
            onChange={(_, value) => {
              setFieldValue('preserve_assignee', value);
              setChecked(value);
            }}
            sx={{
              margin: '0px',
              padding: '0px',
              color: '#D0D5DD',
              '&.Mui-checked': {
                color: '#7F56D9',
              },
            }}
          />
          <Box>
            <CustomTypography typographyType={TypographyType.Header5}>
              Assign destination workflow&apos;s tasks to the first round
              reviewers of associated source workflow&apos;s tasks if possible.
            </CustomTypography>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default memo(CreateConnectorCondition);
