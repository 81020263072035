import { Box } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import React, { FC, memo, useEffect } from 'react';
import jsonIcon from '../../../static/icons/json.svg';
import CustomTypography from '../../../components/CustomTypography';

interface GenerateOutputContentProps {
  onSubmit: (isNextClicked: boolean) => void;
  formId: string;
  previousClicked: boolean;
}

const GenerateOutputContent: FC<GenerateOutputContentProps> = ({
  onSubmit,
  formId,
  previousClicked,
}) => {
  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(false);
    }
  }, [previousClicked]);

  return (
    <main>
      <form
        id={formId}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(true);
        }}
        style={{ width: '100%' }}
      >
        <Box display={'flex'} gap={'100px'} marginY={'16px'} marginX={'34px'}>
          <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
            <Box>
              <CustomTextField
                label='Output format'
                name='jsonfile'
                size='large'
                color='#DDDBDA'
                startAdornment={
                  <img
                    alt='JSON ICON'
                    style={{
                      marginRight: '6px',
                      marginLeft: '14px',
                      width: '18px',
                    }}
                    src={jsonIcon}
                  />
                }
                value={'JSON file'}
                disabled
              />
            </Box>
            <CustomTypography size='12px' color='#545454'>
              Prediction results in JSON format will be stored in the
              “OrbyAI_System_Predictions” folder
            </CustomTypography>
          </Box>
        </Box>
      </form>
    </main>
  );
};

export default memo(GenerateOutputContent);
