import { Button, Collapse, SnackbarContent, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { WorkflowTaskStatus } from 'protos/pb/v1alpha1/orbot_workflow';
import React, { useState } from 'react';
import { OrbyColorPalette } from 'orby-ui/src';
import { WorkflowTaskStatusEnum } from '../../ui-table-body-helpers';
import {
  getFontSize,
  getLineHeight,
} from 'orby-ui/src/components/typography/typography-utils';

interface TaskBannerProps {
  taskId?: string;
  taskStatus?: WorkflowTaskStatus;
  errorMessage?: string;
  activateTab?(): void;
}

const TaskStyledSnackbarContent = styled(SnackbarContent)(() => ({
  borderRadius: 8,
  padding: '20px',
  boxShadow: 'none',
  flexWrap: 'nowrap',
}));

const getMessage = (status?: WorkflowTaskStatus, errorMessage?: string) => {
  switch (status) {
    case WorkflowTaskStatus.PENDING:
      return 'This task is waiting to be executed.';
    case WorkflowTaskStatus.EXECUTING:
      return 'The task is in execution.';
    case WorkflowTaskStatus.WAITING_FOR_REVIEW:
      return 'This task is waiting for review, the execution will resume right after all reviews are completed';
    case WorkflowTaskStatus.SUCCESS:
      return 'The task was executed successfully.';
    case WorkflowTaskStatus.FAIL:
      return `${errorMessage}, please rerun the workflow`;
    case WorkflowTaskStatus.TERMINATED:
      return 'The execution was cancelled by users, please rerun the workflow';
    default:
      return 'The task is loading.';
  }
};

const TaskBanner: React.FC<TaskBannerProps> = ({
  taskId,
  taskStatus,
  errorMessage,
  activateTab,
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const closeBtn = (
    <IconButton
      sx={{ width: 24, height: 24 }}
      color='inherit'
      onClick={() => setIsClosed(true)}
      aria-label='Close Icon'
    >
      <CloseIcon sx={{ width: 20 }} />
    </IconButton>
  );
  const gotoTaskBtn = taskId && (
    <Button
      sx={{ height: 36 }}
      color='inherit'
      variant='outlined'
      onClick={activateTab}
    >
      Go to task
    </Button>
  );

  const action = (
    <>
      {activateTab !== undefined &&
        (taskStatus === WorkflowTaskStatus.EXECUTING ||
          taskStatus === WorkflowTaskStatus.WAITING_FOR_REVIEW) &&
        gotoTaskBtn}
      {closeBtn}
    </>
  );

  return (
    <Collapse sx={{ width: '100%' }} in={!isClosed}>
      <TaskStyledSnackbarContent
        sx={{
          backgroundColor:
            WorkflowTaskStatusEnum?.[taskStatus!]?.backgroundColor,
          color: OrbyColorPalette['grey-900'],
          fontSize: getFontSize('md'),
          lineHeight: getLineHeight('md'),
        }}
        message={getMessage(taskStatus, errorMessage)}
        action={action}
      />
    </Collapse>
  );
};

export default TaskBanner;
