import { Box } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { ReactComponent as SftpIcon } from '../../../../static/icons/sftp-connected.svg';
import { ReactComponent as TriggerIcon } from '../../../../static/icons/trigger.svg';
import { ReactComponent as SettingsIcon } from '../../../../static/icons/settings-rounded.svg';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { OrbyColorPalette, OrbyTextField, OrbyTypography } from 'orby-ui/src';

interface SftpTriggerContentProps {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  formId: string;
  previousClicked: boolean;
  step: number;
  moveToStep: number | null;
}

const SftpTriggerContent: FC<SftpTriggerContentProps> = ({
  workflow,
  onSubmit,
  formId,
  previousClicked,
  step,
  moveToStep,
}) => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const folderPath = `${selectedOrgInfo?.orgDisplayName}/${workflow.displayName}`;

  const formik = useFormik({
    initialValues: {
      folder_path: folderPath,
    },
    onSubmit: (values) => onSubmit(values, true),
  });

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  useEffect(() => {
    if (moveToStep && moveToStep !== step) {
      formik.submitForm();
    }
  }, [moveToStep]);

  const { handleSubmit } = formik;

  return (
    <main>
      <FormikProvider value={formik}>
        <Form id={formId} autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box
            display={'flex'}
            marginY={'32px'}
            paddingLeft={'19px'}
            justifyContent={'space-between'}
          >
            {/* FORM */}
            <Box>
              <Box>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Application
                </OrbyTypography>
                <OrbyTextField
                  placeholder='Application'
                  value='SFTP Server'
                  name='application'
                  width='448px'
                  disabled
                  startAdornment={<SftpIcon />}
                />
              </Box>

              <Box marginTop={'16px'}>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Folder URL
                </OrbyTypography>
                <OrbyTextField
                  name='folder_path'
                  placeholder='Folder Url'
                  value={`${selectedOrgInfo?.orgDisplayName}/${workflow.displayName}`}
                  width='448px'
                  disabled
                />
              </Box>

              <Box marginTop={'16px'}>
                <OrbyTypography
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  Trigger
                </OrbyTypography>
                <OrbyTextField
                  name='trigger'
                  placeholder='Trigger'
                  width='448px'
                  disabled
                  startAdornment={<TriggerIcon />}
                  value={'Add new file'}
                />
              </Box>
            </Box>

            <Box
              bgcolor={OrbyColorPalette['green-50']}
              borderRadius={'10px'}
              height={'fit-content'}
              padding={'16px'}
              display={'flex'}
              gap={'16px'}
              width={'315px'}
            >
              <Box>
                <SettingsIcon
                  style={{ color: OrbyColorPalette['green-500'] }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                paddingRight={'10px'}
              >
                <span id='folder_path'>
                  <OrbyTypography color={OrbyColorPalette['grey-700']}>
                    Task will be triggered for every new file added at this
                    location in the SFTP server. A few new subfolders will be
                    created in this folder. Processed files will be moved to
                    subfolders that reflect their associated task statuses.
                  </OrbyTypography>
                </span>
              </Box>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </main>
  );
};

export default memo(SftpTriggerContent);
