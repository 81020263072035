import { OrbyButton } from 'orby-ui/src';
import React, { FC, memo } from 'react';

interface TextButtonProps {
  label: string;
  onClick?: () => void;
}

const TextButton: FC<TextButtonProps> = ({ label, onClick }) => {
  return (
    <>
      <OrbyButton
        variant='primary-text-flat'
        onClick={onClick}
        label={label}
        sx={{
          cursor: 'pointer',
        }}
      />
    </>
  );
};

export default memo(TextButton);
