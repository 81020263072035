import { defaultSelectedOption } from '../PendingTasks/WorkflowFilterSelect';
import { NavigateFunction } from 'react-router-dom';
import {
  ADMIN_TASK_FILTER,
  DISPLAY_NAME_PREFIX,
  ORBY_UNASSIGNED,
  SELECTED_TAB,
  TASKS_TAB_INDEX,
  USERNAMES,
  USER_IDS,
  USER_TASK_FILTER,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import { User } from 'protos/pb/v1alpha1/user';
import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { DEFAULT_WORKFLOW_VALUE } from '../Tasks/TaskHelpers';

// This function extracts the workflow ids from params to get all the applied
// workflow filters and adds them in an array and returns it
export const getWorkflowIdsFromParamString = (
  idsParam?: string,
  defaultValue = defaultSelectedOption,
) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [defaultValue];
};
export const getWorkflowIdsFromParamStringV2 = (
  idsParam?: string,
  defaultValue = DEFAULT_WORKFLOW_VALUE.value,
) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [defaultValue];
};

export const getUsersFromParamStringV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split('-')
    : [];
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

export const getUserIdsFromParamStringV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USER_IDS);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split('-')
    : [];
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

export const getWorkflowFromParamString = (idsParam?: string) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [];
};

export const getApiExecutionStatusesFromParamString = (idsParam?: string) => {
  if (idsParam) {
    // Split the value to get individual IDs
    const ids = idsParam.split('-');
    return ids;
  }
  // Return a default value
  return [];
};

export const getExecutionStatusesFJoinedByDash = (
  selectedWorkflows: string[],
) => {
  const ids = selectedWorkflows.join('-');
  return ids;
};

// This function takes the array of workflow names and returns a string
// with all the workflow names joined by `-`
export const getWorkflowIdsJoinedByDash = (
  selectedWorkflows: string[],
  defaultValue = defaultSelectedOption,
) => {
  if (selectedWorkflows.find((w) => w === defaultValue)) {
    return undefined;
  }
  const ids = selectedWorkflows.join('-');
  return ids;
};

export const composeTaskFiltersAndUpdateNetworkURL = (
  status: string,
  email: string,
  isAdminView: boolean,
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  displayNamePrefix: string,
  selectedReviewers: string[] = [], // only for pending tasks
  selectedTaskFilter?: string, // only for pending tasks
): string => {
  const searchParams = new URLSearchParams();

  // Convert selected workflows to a dash-separated string
  const ids: string | undefined = getWorkflowIdsJoinedByDash(selectedWorkflows);

  // If workflows are selected, append to status string
  if (ids) {
    status += `,${WORKFLOW_RESOURCE_NAMES}=${ids}`;
    searchParams.append(WORKFLOW_RESOURCE_NAMES, ids);
  }

  // If display name prefix is not empty, append to status string
  if (displayNamePrefix) {
    status += `,${DISPLAY_NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }
  if (selectedTaskFilter) {
    if (!isAdminView) {
      let usernames = '';
      if (
        [USER_TASK_FILTER.ALL_TASKS, USER_TASK_FILTER.UNASSIGNED_ONLY].includes(
          selectedTaskFilter as USER_TASK_FILTER,
        )
      ) {
        usernames += ORBY_UNASSIGNED;
      }
      if (
        [USER_TASK_FILTER.ALL_TASKS, USER_TASK_FILTER.ASSIGNED_TO_ME].includes(
          selectedTaskFilter as USER_TASK_FILTER,
        )
      ) {
        usernames += (usernames.includes(ORBY_UNASSIGNED) ? ';' : '') + email;
      }
      if (USER_TASK_FILTER.ALL_TASKS !== selectedTaskFilter) {
        searchParams.append(USERNAMES, usernames);
      }
      status += `,${USERNAMES}=${usernames}`;
    } else {
      if (selectedTaskFilter !== ADMIN_TASK_FILTER.ALL_TASKS) {
        let usernames = '';
        if (
          [
            ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
            ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
          ].includes(selectedTaskFilter as ADMIN_TASK_FILTER)
        ) {
          usernames += ORBY_UNASSIGNED;
        }
        if (
          [
            ADMIN_TASK_FILTER.REVIEWERS_ONLY,
            ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
          ].includes(selectedTaskFilter as ADMIN_TASK_FILTER)
        ) {
          usernames +=
            (usernames.includes(ORBY_UNASSIGNED) ? ';' : '') +
            selectedReviewers.join(';');
        }
        searchParams.append(USERNAMES, usernames);
        status += `,${USERNAMES}=${usernames}`;
      }
    }
  } else {
    if (!isAdminView) {
      status += `,${USERNAMES}=${email}`;
    }
  }
  // Update URL search parameters for workflow and display name
  navigate({ search: `?${searchParams.toString()}` });

  // Return the updated status string
  return status.length && status[0] === ',' ? status.substring(1) : status;
};

export const getPathAccToTaskStatus = (status: TaskSTATUS): string => {
  if (
    [
      TaskSTATUS.REJECTED_INCORRECT,
      TaskSTATUS.REJECTED_ALREADY_COMPLETED,
      TaskSTATUS.OBSOLETE, // System Declined
    ].includes(status)
  ) {
    return 'declined-tasks';
  } else if ([TaskSTATUS.ACCEPTED, TaskSTATUS.COMPLETED].includes(status)) {
    return 'completed-tasks';
  } else if ([TaskSTATUS.READY, TaskSTATUS.CREATED].includes(status)) {
    return 'pending-tasks';
  }
  return '';
};

export const getPathAccToTaskStatusV2 = (status: TaskSTATUS): string => {
  if (
    [
      TaskSTATUS.REJECTED_INCORRECT,
      TaskSTATUS.REJECTED_ALREADY_COMPLETED,
      TaskSTATUS.OBSOLETE, // System Declined
    ].includes(status)
  ) {
    return `tasks?tab=${TASKS_TAB_INDEX.DECLINED_TAB}`;
  } else if ([TaskSTATUS.ACCEPTED, TaskSTATUS.COMPLETED].includes(status)) {
    return `tasks?tab=${TASKS_TAB_INDEX.COMPLETED_TAB}`;
  } else if ([TaskSTATUS.READY, TaskSTATUS.CREATED].includes(status)) {
    return `tasks?tab=${TASKS_TAB_INDEX.PENDING_TAB}`;
  }
  return '';
};

export const getFilterValuesFromUrl = (
  isAdminView: boolean,
  urlSearchParams: URLSearchParams,
  user: User,
): { filterOption: string; selectedUsers: Set<string> } => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  let usernames: string[] = userNameParamString
    ? userNameParamString.split(';')
    : [];
  const isUnassigned = !!usernames?.find((name) => name === ORBY_UNASSIGNED);
  usernames = usernames?.filter((name) => name !== ORBY_UNASSIGNED);
  if (isAdminView) {
    const hasReviewers = usernames.length > 0;
    if (isUnassigned && hasReviewers) {
      return {
        filterOption: ADMIN_TASK_FILTER.UNASSIGNED_WITH_REVIEWERS,
        selectedUsers: new Set(usernames),
      };
    }
    if (isUnassigned) {
      return {
        filterOption: ADMIN_TASK_FILTER.UNASSIGNED_ONLY,
        selectedUsers: new Set(),
      };
    }
    if (hasReviewers) {
      return {
        filterOption: ADMIN_TASK_FILTER.REVIEWERS_ONLY,
        selectedUsers: new Set(usernames),
      };
    }
    return {
      filterOption: ADMIN_TASK_FILTER.ALL_TASKS,
      selectedUsers: new Set(),
    };
  }
  const includesOwnEmail = usernames.find((u) => u === user?.email);
  if (isUnassigned && includesOwnEmail) {
    return {
      filterOption: USER_TASK_FILTER.ALL_TASKS,
      selectedUsers: new Set(),
    };
  }
  if (isUnassigned) {
    return {
      filterOption: USER_TASK_FILTER.UNASSIGNED_ONLY,
      selectedUsers: new Set(),
    };
  }
  if (includesOwnEmail) {
    return {
      filterOption: USER_TASK_FILTER.ASSIGNED_TO_ME,
      selectedUsers: new Set(),
    };
  }
  return {
    filterOption: USER_TASK_FILTER.ALL_TASKS,
    selectedUsers: new Set(),
  };
};

export const getFilterValuesFromUrlV2 = (
  urlSearchParams: URLSearchParams,
): Array<string> => {
  const userNameParamString = urlSearchParams.get(USERNAMES);
  const usernames: string[] = userNameParamString
    ? userNameParamString.split(';')
    : [];
  if (usernames.length === 0) {
    return [];
  }
  return usernames;
};

// This function takes the array of workflow names and returns a string
// with all the workflow names joined by `-`
export const getWorkflowIdsJoinedByDashV2 = (
  selectedWorkflows: string[],
  defaultValue = DEFAULT_WORKFLOW_VALUE.value,
) => {
  if (selectedWorkflows.find((w) => w === defaultValue)) {
    return undefined;
  }
  const ids = selectedWorkflows.join('-');
  return ids;
};

// This function takes the array of strings and returns a string
// with all the workflow names joined by `:`
export const getUsersJoinedByColon = (data: string[]) => {
  const ids = data.join(':');
  return ids;
};

export const composeTaskFiltersAndUpdateNetworkURLV2 = (
  status: string,
  email: string,
  isAdminView: boolean,
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  displayNamePrefix: string,
  selectedReviewers: string[] = [], // only for pending tasks
): string => {
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // Convert selected workflows to a dash-separated string
  const ids: string | undefined =
    getWorkflowIdsJoinedByDashV2(selectedWorkflows);

  // If workflows are selected, append to status string
  if (ids) {
    status += `,${WORKFLOW_RESOURCE_NAMES}=${ids}`;
    searchParams.append(WORKFLOW_RESOURCE_NAMES, ids);
  }

  // If display name prefix is not empty, append to status string
  if (displayNamePrefix) {
    status += `,${DISPLAY_NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  if (!isAdminView) {
    if (selectedReviewers.length > 0) {
      const usernames = selectedReviewers.join(';');
      searchParams.append(USERNAMES, usernames);
      status += `,${USERNAMES}=${usernames}`;
    } else {
      status += `,${USERNAMES}=${email};${ORBY_UNASSIGNED}`;
    }
  } else if (selectedReviewers.length) {
    const usernames = selectedReviewers.join(';');
    searchParams.append(USERNAMES, usernames);
    status += `,${USERNAMES}=${usernames}`;
  }

  // GET THE TAB
  const selectedTab =
    existingParams.get(SELECTED_TAB) || TASKS_TAB_INDEX.PENDING_TAB;
  navigate({
    search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
  });

  // Return the updated status string
  return status.length && status[0] === ',' ? status.substring(1) : status;
};
