import { Box } from '@mui/material';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMultipleCellsSelectedSelector } from '../../../../../redux/selectors/review_task.selectors';

interface Props {
  documentStep: ExecutionStep;
  currentPage: number;
  scale: number;
}

// This component is used to display crosshair cursor over pdf when user is annotating
// multiple entities for nested table layout
const DocumentCursorBox: React.FC<Props> = ({
  documentStep,
  currentPage,
  scale,
}) => {
  const isMultipleCellsSelected = useSelector(isMultipleCellsSelectedSelector);
  const width = useMemo(() => {
    return (
      documentStep.documents![0].pages![currentPage].image!.width! * scale +
      'px'
    );
  }, [documentStep]);

  const height = useMemo(() => {
    return (
      documentStep.documents![0].pages![currentPage]!.image!.height! * scale +
      'px'
    );
  }, [documentStep]);

  return (
    <Box
      width={width}
      height={height}
      position={'absolute'}
      top={0}
      left={0}
      sx={{ cursor: isMultipleCellsSelected ? 'crosshair' : 'auto' }}
    />
  );
};

export default React.memo(DocumentCursorBox);
