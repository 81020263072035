export const calculateWidths = (totalWidth: number) => {
  const WHEN = 39;

  // Fixed width for WHEN, subtract it from totalWidth
  const remainingWidth = totalWidth - WHEN;

  // Original widths
  const originalWidths = {
    SELECT: 320,
    EQUALITY: 72,
    INPUT: 320,
    DELETE: 24,
  };

  // Total of the original widths
  const totalOriginalWidth =
    originalWidths.SELECT +
    originalWidths.EQUALITY +
    originalWidths.INPUT +
    originalWidths.DELETE;

  // Calculate new widths based on proportion
  const newWidths = {
    selectWidth: (originalWidths.SELECT / totalOriginalWidth) * remainingWidth,
    equalityWidth:
      (originalWidths.EQUALITY / totalOriginalWidth) * remainingWidth,
    inputWidth: (originalWidths.INPUT / totalOriginalWidth) * remainingWidth,
    deleteWidth: (originalWidths.DELETE / totalOriginalWidth) * remainingWidth,
  };

  return newWidths;
};
