import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { ArchivedResourceType } from '../../utils/constants';
import {
  getPathAccToTaskStatus,
  getPathAccToTaskStatusV2,
} from './taskV2Utils';
import { WORKFLOW_PAGE } from '../MyWorkflows/MyWorkflows';

export const getPathAccToResource = (
  resourceType: ArchivedResourceType,
  status?: TaskSTATUS,
) => {
  switch (resourceType) {
    case ArchivedResourceType.TASK: {
      return status && TaskSTATUS[status] ? getPathAccToTaskStatus(status) : '';
    }
    default: {
      return 'workflow';
    }
  }
};

export const getPathAccToResourceV2 = (
  resourceType: ArchivedResourceType,
  status?: TaskSTATUS,
) => {
  switch (resourceType) {
    case ArchivedResourceType.TASK: {
      return status && TaskSTATUS[status]
        ? getPathAccToTaskStatusV2(status)
        : '';
    }
    case ArchivedResourceType.CONNECTOR: {
      return `workflow?tab=${WORKFLOW_PAGE.CONNECTOR_TAB_INDEX}`;
    }
    default: {
      return `workflow?tab=${WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX}`;
    }
  }
};
