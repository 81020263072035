import {
  Box,
  FormControl,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserToOrganizationAction,
  addUserToOrganizationErrorAction,
  setAddedUserAction,
  setUpdatedUserAction,
  updateUserAction,
  updateUserErrorAction,
} from '../../../redux/actions/user.action';
import {
  addUserErrorSelector,
  addedUserSelector,
  createUserLoadingSelector,
  selectedOrgInfoSelector,
  updateUserErrorSelector,
  updateUserLoadingSelector,
  updatedUserSelector,
} from '../../../redux/selectors/user.selectors';
import * as Yup from 'yup';
import { shiftFocus } from '../../../utils/FocusUtils';
import { IS_PROD } from '../../../utils/constants';
import {
  OrbyCheckbox,
  OrbyColorPalette,
  OrbySelect,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import CustomComboButtonsNew from '../../../components/CustomComboButtonsNew';
import { toastService } from '../../../services/ToastService';
import { User, UserOrgRole } from 'protos/pb/v1alpha1/user';
import {
  AddUserToOrganizationRequest,
  UpdateRequest,
} from 'protos/pb/v1alpha1/users_service';

interface Props {
  edit?: boolean;
  user?: User;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  isRbacEnabled?: boolean;
}

const AddUserModal: React.FC<Props> = ({
  open,
  setOpen,
  edit = false,
  onSuccess,
  user,
  setSelectedUser,
  isRbacEnabled = false,
}) => {
  const dispatch = useDispatch();
  const [creationTriggered, setCreationTriggered] = useState(false);
  const addUserError = useSelector(addUserErrorSelector);
  const addedUser = useSelector(addedUserSelector);
  const updateUserError = useSelector(updateUserErrorSelector);
  const updatedUser = useSelector(updatedUserSelector);
  const updateLoading = useSelector(updateUserLoadingSelector);
  const createLoading = useSelector(createUserLoadingSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const loading = updateLoading || createLoading;
  const [menuOpen, setMenuOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const getUserRoleForSelectedOrg = (user?: User) => {
    if (!user) {
      return '';
    }
    const role = user?.orgInfos?.find(
      (i) => i.orgResourceName === selectedOrgInfo?.orgResourceName,
    )?.role;

    if (role === UserOrgRole.ROLE_ADMIN) {
      return 'ROLE_ADMIN';
    }
    if (role === UserOrgRole.ROLE_USER) {
      return 'ROLE_USER';
    }
    if (role === UserOrgRole.ROLE_CREATOR) {
      return 'ROLE_CREATOR';
    }
  };

  const getUserRoleByValue = (role: string) => {
    if (role === 'ROLE_ADMIN') {
      return 'Admin';
    }
    if (role === 'ROLE_USER') {
      return 'User';
    }
    if (role === 'ROLE_CREATOR') {
      return 'Creator';
    }
  };

  useEffect(() => {
    if (addUserError && !edit) {
      toastService.showError(addUserError.message, {
        position: 'top-right',
      });
      dispatch(addUserToOrganizationErrorAction(undefined));
    }
  }, [addUserError]);

  useEffect(() => {
    if (updateUserError && edit) {
      toastService.showError(updateUserError.message, {
        position: 'top-right',
      });
      dispatch(updateUserErrorAction(undefined));
    }
  }, [updateUserError]);

  useEffect(() => {
    if (creationTriggered && addedUser) {
      toastService.showSuccess(
        `User ${addedUser.email} is added successfully`,
        {
          position: 'top-right',
        },
      );
      onSuccess();
      setTimeout(() => {
        dispatch(setAddedUserAction(undefined));
        setCreationTriggered(false);
        setSelectedUser(undefined);
        setOpen(false);
        formik.resetForm();
      }, 1000);
    }
  }, [creationTriggered, addedUser]);

  useEffect(() => {
    if (creationTriggered && updatedUser) {
      toastService.showSuccess(
        `User ${updatedUser.email} is updated successfully`,
        {
          position: 'top-right',
        },
      );
      onSuccess();
      setTimeout(() => {
        dispatch(setUpdatedUserAction(undefined));
        setCreationTriggered(false);
        setSelectedUser(undefined);
        setOpen(false);
      }, 1000);
    }
  }, [creationTriggered, updatedUser]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: user?.email || '',
      role: getUserRoleForSelectedOrg(user),
      sendEmail: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required.'),
      role: Yup.string().required('Role is required.'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setCreationTriggered(true);
      if (edit) {
        const req: UpdateRequest = {};
        req.orgResourceName = selectedOrgInfo?.orgResourceName as string;
        const u = { ...(user as User) };
        const index: number = u?.orgInfos?.findIndex(
          (o) =>
            o.orgResourceName === (selectedOrgInfo?.orgResourceName as string),
        ) as number;
        if (u.orgInfos?.[index]) {
          const updatedOrgInfos = [...u.orgInfos]; // Shallow copy of orgInfos array
          updatedOrgInfos[index] = {
            ...updatedOrgInfos[index], // Shallow copy of the object at the given index
            role: UserOrgRole[values.role as keyof typeof UserOrgRole], // Update the role property
          };
          // u.orgInfos[index].role = UserOrgRole[values.role as keyof typeof UserOrgRole]
          const changedOrgInfo = updatedOrgInfos[index];
          u.orgInfos = [changedOrgInfo];
        }
        u.email = values.email.toLowerCase().trim();
        req.user = u;
        req.fieldMask = ['org_infos'];
        dispatch(updateUserAction(req));
      } else {
        const req: AddUserToOrganizationRequest = {};
        req.email = values.email.toLowerCase().trim();
        req.role = UserOrgRole[values.role as keyof typeof UserOrgRole];
        req.orgResourceName = selectedOrgInfo?.orgResourceName as string;
        req.sendEmail = values.sendEmail;
        dispatch(addUserToOrganizationAction(req));
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  // To Trap Focus within the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const boxContentElement = boxRef.current;
        if (boxContentElement) {
          const focusableElements = boxContentElement.querySelectorAll(
            'button, input:not([aria-hidden="true"]), [tabindex]:not([tabindex="-1"])',
          );
          if (focusableElements.length > 0) {
            shiftFocus(focusableElements, event);
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (menuOpen || open) {
      const elm = document.getElementById('root');
      elm?.removeAttribute('aria-hidden');
    }
  }, [menuOpen || open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      ref={boxRef}
      PaperProps={{
        style: {
          width: '408px',
          borderRadius: '10px',
          boxShadow: '0px 4px 40px 0px #00000026',
          padding: '8px 0px',
        },
      }}
      open={open}
      onClose={() => {
        formik.resetForm();
        setSelectedUser(undefined);
        setOpen(false);
      }}
    >
      <DialogTitle>
        <OrbyTypography
          color={OrbyColorPalette['grey-900']}
          size='xl'
          weight='semibold'
        >
          {edit ? 'Edit' : 'Add User'}
        </OrbyTypography>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form
            id='form1'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Box>
              <OrbyTextField
                label='Email'
                error={touched.email && errors.email ? errors.email : ''}
                {...getFieldProps('email')}
                width={'100%'}
                tabIndex={0}
                name={'email'}
                disabled={edit}
                placeholder='Enter Email'
              />
            </Box>
            {!(IS_PROD || edit) && (
              <Box marginTop={'15px'}>
                <OrbyCheckbox
                  label='Send Email'
                  title='Send Email'
                  size='sm'
                  checked={values.sendEmail}
                  onClick={(e) => {
                    formik.setFieldValue(
                      'sendEmail',
                      (e.target as HTMLInputElement).checked,
                    );
                  }}
                />
              </Box>
            )}
            <Box marginTop={'11px'}>
              <FormControl
                size='small'
                sx={{ width: '100%' }}
                error={Boolean(touched.role && errors.role)}
              >
                <Box id={'role-label'} display={'none'} tabIndex={-1}>
                  {values.role ? values.role + 'selected' : 'Select Role'}
                </Box>
                <OrbyTypography
                  sx={{ marginBottom: '6px' }}
                  size='sm'
                  weight='medium'
                  color={OrbyColorPalette['grey-700']}
                >
                  Role
                </OrbyTypography>
                <OrbySelect
                  width='100%'
                  renderValue={() => {
                    return (
                      <OrbyTypography
                        color={
                          values.role
                            ? OrbyColorPalette['grey-900']
                            : OrbyColorPalette['grey-500']
                        }
                        size={'md'}
                      >
                        {values.role
                          ? getUserRoleByValue(values.role)
                          : 'Select a role'}
                      </OrbyTypography>
                    );
                  }}
                  aria-hidden={false}
                  value={values.role}
                  onChange={(e) => {
                    formik.setFieldValue('role', e.target.value);
                  }}
                  onClose={() => setMenuOpen(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem key={'admin'} value={'ROLE_ADMIN'}>
                    Admin
                  </MenuItem>
                  {isRbacEnabled && (
                    <MenuItem key={'creator'} value={'ROLE_CREATOR'}>
                      Creator
                    </MenuItem>
                  )}
                  <MenuItem key={'user'} value={'ROLE_USER'}>
                    User
                  </MenuItem>
                </OrbySelect>
                {Boolean(touched.role && errors.role) && (
                  <OrbyTypography
                    color={OrbyColorPalette['error-500']}
                    sx={{ marginTop: '3px' }}
                  >
                    {touched.role && errors.role}
                  </OrbyTypography>
                )}
              </FormControl>
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <CustomComboButtonsNew
          primaryLabel='Cancel'
          secondaryLabel={edit ? 'Save Changes' : 'Submit'}
          onPrimaryClick={() => {
            formik.resetForm();
            setSelectedUser(undefined);
            setOpen(false);
          }}
          onSecondaryClick={() => {
            formik.handleSubmit();
          }}
          secondaryDisabled={false}
          secondaryLoading={loading}
          size={'medium'}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddUserModal);
