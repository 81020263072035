import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  allowEditingTaskSelector,
  columnOrderInfoForTableEntitiesSelector,
  rowOrderInfoForSelectedParentEntitySelector,
  hiddenEntityTypesForTableAnnotationSelector,
  lastLocatedEntityTypeSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoSelector,
  selectedTableEntitiesInfoMapSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import { Table, TableContainer } from '@mui/material';
import {
  deleteTextSegmentsFromEntitiesAction,
  setLastLocatedEntityTypeAction,
  setSelectedEntityIdsForAnnotationAction,
  updateEntityInfoForTableAnnotationAction,
} from '../../../../../../../redux/actions/review_task.action';
import { MIN_TABLE_POPOVER_CELL_WITH } from '../../../../../../../utils/constants';
import { checkFocusOnInputField } from '../../../../../../../utils/FocusUtils';
import './tableModalBody.css';
import TableTextPopover from './TableTextPopover';
import TableHeader from './TableHeader';
import TableBody from './TableBody/TableBody';
import { useVirtualizer } from '@tanstack/react-virtual';

interface Props {
  showNormalizedValues: boolean;
}

const TableModalBodyV2: React.FC<Props> = ({ showNormalizedValues }) => {
  const dispatch = useDispatch();
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedTableEntitiesInfo = useSelector(
    selectedTableEntitiesInfoSelector,
  );
  const selectedTableEntitiesInfoMap = useSelector(
    selectedTableEntitiesInfoMapSelector,
  );
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const lastLocatedEntityType = useSelector(lastLocatedEntityTypeSelector);
  const columnOrderInfoForTableEntities = useSelector(
    columnOrderInfoForTableEntitiesSelector,
  );

  const hiddenEntityTypesForTableAnnotation = useSelector(
    hiddenEntityTypesForTableAnnotationSelector,
  );
  const headerRows = useMemo(() => {
    // Retrieve the column order information for the selected parent entity type
    return columnOrderInfoForTableEntities[
      selectedParentEntityInfo?.type || ''
    ].filter(
      (e) =>
        // Return entities that are not hidden based on the hiddenEntityTypesForTableAnnotation configuration
        !hiddenEntityTypesForTableAnnotation[
          selectedParentEntityInfo?.type || ''
        ]?.includes(e.type),
    );
  }, [
    selectedParentEntityInfo,
    columnOrderInfoForTableEntities,
    hiddenEntityTypesForTableAnnotation,
  ]);

  const [draggingElemId, setDraggingElemId] = React.useState('');

  const [columnTypeToResize, setColumnTypeToResize] = React.useState('');

  // keeps track of column element to size
  const columnToResizeRef = React.useRef('');
  const [selectedPopoverCellId, setSelectedPopoverCellId] = React.useState<
    string | undefined
  >(undefined);
  // This is to get current value of selectedPopoverCell
  const selectedPopoverCell = useMemo(
    () => selectedTableEntitiesInfoMap[selectedPopoverCellId ?? ''],
    [selectedTableEntitiesInfoMap, selectedPopoverCellId],
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<any>(null);

  // This state basically keeps track of entity which is copied and can be pasted
  const [clipboardEntity, setClipboardEntity] = React.useState<
    EntityInfo | undefined
  >(undefined);

  const rowOrderInfoForTableEntities = useSelector(
    rowOrderInfoForSelectedParentEntitySelector,
  );

  const getHiddenHeaderRows = useCallback(() => {
    // Retrieve the column order information for the selected parent entity type
    return columnOrderInfoForTableEntities[
      selectedParentEntityInfo?.type || ''
    ].filter((e) =>
      // Return entities that are hidden based on the hiddenEntityTypesForTableAnnotation configuration
      hiddenEntityTypesForTableAnnotation[
        selectedParentEntityInfo?.type || ''
      ]?.includes(e.type),
    );
  }, [
    columnOrderInfoForTableEntities,
    selectedParentEntityInfo?.type,
    hiddenEntityTypesForTableAnnotation,
  ]);

  const selectedEntitiesByRowOrder = useMemo(() => {
    // Create a Map for faster lookups
    const rowOrderMap = new Map(
      rowOrderInfoForTableEntities.map((id, index) => [id, index]),
    );

    return [...selectedTableEntitiesInfo].sort((a, b) => {
      const indexA =
        rowOrderMap.get(a?.parentEntityId ?? '') ?? Number.MAX_SAFE_INTEGER;
      const indexB =
        rowOrderMap.get(b?.parentEntityId ?? '') ?? Number.MAX_SAFE_INTEGER;
      return indexA - indexB;
    });
  }, [selectedTableEntitiesInfo, rowOrderInfoForTableEntities]);

  const getEntitiesWithSameType = useCallback(
    (type: string) => {
      return selectedEntitiesByRowOrder?.filter((e) => e.type === type);
    },
    [selectedEntitiesByRowOrder],
  );

  useEffect(() => {
    // Selecting next header rows
    if (lastLocatedEntityType) {
      if (headerRows?.length) {
        const index = headerRows.findIndex(
          (row) => row.type === lastLocatedEntityType,
        );
        const nextHeaderRow = headerRows[index + 1];
        if (nextHeaderRow) {
          const entitiesWithSameType = getEntitiesWithSameType(
            nextHeaderRow.type,
          );
          dispatch(
            setSelectedEntityIdsForAnnotationAction(
              entitiesWithSameType?.map((e) => e.id || '') || [],
            ),
          );
        }
        dispatch(setLastLocatedEntityTypeAction());
      }
    }
  }, [lastLocatedEntityType]);

  // When entity header is clicked, it checks if any values inside it is selected or not
  // If selected, it removes the selection otherwise selects all entities inside that header
  const handleEntityHeaderClick = useCallback(
    (row: EntityInfo, canDeselect = false) => {
      const entitiesWithSameType = getEntitiesWithSameType(row.type);
      // Check if all entities are selected
      if (
        canDeselect &&
        !entitiesWithSameType?.some(
          (e) => !selectedEntityIdsForAnnotation.includes(e.id),
        )
      ) {
        dispatch(setSelectedEntityIdsForAnnotationAction([]));
      } else {
        dispatch(
          setSelectedEntityIdsForAnnotationAction(
            entitiesWithSameType?.map((e) => e.id || '') || [],
          ),
        );
      }
    },
    [getEntitiesWithSameType, selectedEntityIdsForAnnotation],
  );

  // This is used when any single entity is selected or when multiple is selected using
  // shift key. If an entity is already selected, then clicking it removes the selection
  const handleEntityRowClick = useCallback(
    (
      e: React.MouseEvent | React.KeyboardEvent | React.FocusEvent,
      row: EntityInfo,
    ) => {
      if ('shiftKey' in e && e.shiftKey) {
        // Handle shift + click event
        // Add entity of the same type only if any of them selected
        if (
          selectedEntitiesByRowOrder?.some(
            (e) =>
              e.type === row.type &&
              selectedEntityIdsForAnnotation.includes(e.id),
          )
        ) {
          if (!selectedEntityIdsForAnnotation.includes(row.id)) {
            dispatch(
              setSelectedEntityIdsForAnnotationAction([
                ...selectedEntityIdsForAnnotation,
                row.id,
              ]),
            );
          }
          // only deselect if we more than 1 selected cells
          else if (selectedEntityIdsForAnnotation.length > 1) {
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                selectedEntityIdsForAnnotation.filter((id) => id !== row.id),
              ),
            );
          }
        }
      } else if (
        !selectedEntityIdsForAnnotation.includes(row.id) ||
        selectedEntityIdsForAnnotation.length > 1
      ) {
        dispatch(setSelectedEntityIdsForAnnotationAction([row.id]));
      }
    },
    [selectedEntitiesByRowOrder, selectedEntityIdsForAnnotation],
  );

  const getChildEntitiesFromParentId = useCallback(
    (parentEntityId: string) => {
      // Filter the selectedTableEntitiesInfo to find child entities that belong to the specified parentEntityId
      // Also exclude entities that are hidden based on hiddenEntityTypesForTableAnnotation configuration
      const parentType = selectedParentEntityInfo?.type || '';
      const hiddenTypes = hiddenEntityTypesForTableAnnotation[parentType] || [];
      const headerRowTypes = new Map(
        headerRows?.map((row, index) => [row.type, index]) || [],
      );

      return selectedTableEntitiesInfo
        ?.filter(
          (e) =>
            e.parentEntityId === parentEntityId &&
            !hiddenTypes.includes(e.type),
        )
        .sort((a, b) => {
          const indexA = headerRowTypes.get(a.type) ?? Number.MAX_SAFE_INTEGER;
          const indexB = headerRowTypes.get(b.type) ?? Number.MAX_SAFE_INTEGER;
          return indexA - indexB;
        });
    },
    [
      selectedTableEntitiesInfo,
      hiddenEntityTypesForTableAnnotation,
      selectedParentEntityInfo?.type,
      headerRows,
    ],
  );

  useEffect(() => {
    // Add an event listener for the Delete key
    const handleKeyDown = (event: KeyboardEvent) => {
      // Since we are making use of delete and backspace and these can be used while editing
      // any field present in UI which would weirdly trigger this and clear segments
      // To avoid it, checking whether focus is not on any input and then proceeding further
      if (
        allowEditingTask &&
        !checkFocusOnInputField() &&
        (event.key === 'Delete' || event.key === 'Backspace')
      ) {
        const selectedEntities = selectedTableEntitiesInfo?.filter((e) =>
          selectedEntityIdsForAnnotation.some((id) => id === e.id),
        );
        if (selectedEntities) {
          dispatch(deleteTextSegmentsFromEntitiesAction(selectedEntities));
        }
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedEntityIdsForAnnotation]);

  // This is to scroll the cell to viewport when a highlight is clicked to open modal
  useEffect(() => {
    if (selectedEntityIdsForAnnotation.length === 1) {
      const element = document.getElementById(
        `cell-${selectedEntityIdsForAnnotation[0]}`,
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [selectedParentEntityInfo?.type]);

  const draggingElem = document.getElementById(draggingElemId);

  useEffect(() => {
    if (draggingElem) {
      const element = document.getElementById(draggingElemId);
      // If the element exists, scroll it into view
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'nearest', // Align vertically
          inline: 'nearest', // Align horizontally
        });
      }
    }
  }, [draggingElem?.getClientRects()]); // Depend on changes in draggingElem's bounding rectangle

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // If the currently focused element's id starts with 'cell', do nothing
      const activeElementId = document.activeElement?.id;
      if (
        activeElementId?.startsWith('cell') &&
        !activeElementId.includes('menu-button')
      ) {
        return;
      }
      if (selectedEntityIdsForAnnotation.length === 1) {
        const row = selectedTableEntitiesInfo.find(
          (e) => e.id === selectedEntityIdsForAnnotation[0],
        );
        // Handle copy for both mac and windows os
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') {
          setClipboardEntity(row);
        }

        // Handle copy for both mac and window os
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
          handlePaste(row!);
        }
      }

      // If no table table cell is focused we start from
      // 1. Last Selected Cell if there is any
      // 2. Otherwise we start from first column header
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        if (selectedEntityIdsForAnnotation.length) {
          const lastSelectedEntityId = selectedEntityIdsForAnnotation?.at(-1);
          if (lastSelectedEntityId)
            // Focus on the last selected entity
            handleKeyBoardNavigation(
              e as any as React.KeyboardEvent,
              selectedTableEntitiesInfo.find(
                (e) => e.id === lastSelectedEntityId,
              )!,
            );
        } else {
          const type = headerRows[0]?.type;
          // Focus on the first header cell and select
          if (type) {
            document.getElementById(`cell-${type}`)?.focus();
            const allEntitiesWithSameType = getEntitiesWithSameType(type);
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                allEntitiesWithSameType.map((e) => e.id),
              ),
            );
          }
        }
      }
    };
    // Add the keydown event listener
    document.addEventListener('keydown', handleKeyDown);
    // Cleanup the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [headerRows, selectedEntityIdsForAnnotation, selectedTableEntitiesInfo]);

  // This is to handle header cell key down event
  const handleHeaderCellKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'ArrowLeft') {
      const type = headerRows[index - 1]?.type;
      const allEntitiesWithSameType = getEntitiesWithSameType(type);
      const columnElement = document.getElementById(`cell-${type}`);
      if (columnElement) {
        // Move focus to the left header cell and select
        columnElement?.focus();
        dispatch(
          setSelectedEntityIdsForAnnotationAction(
            allEntitiesWithSameType.map((e) => e.id),
          ),
        );
      }
    }

    if (e.key === 'ArrowRight') {
      const type = headerRows[index + 1]?.type;
      const allEntitiesWithSameType = getEntitiesWithSameType(type);
      const columnElement = document.getElementById(`cell-${type}`);
      if (columnElement) {
        // Move focus to the right header cell and select
        columnElement?.focus();
        dispatch(
          setSelectedEntityIdsForAnnotationAction(
            allEntitiesWithSameType.map((e) => e.id),
          ),
        );
      }
    }

    if (e.key === 'ArrowDown') {
      const type = headerRows[index]?.type;
      if (type) {
        const firstEntityWithSameType = selectedEntitiesByRowOrder?.find(
          (e) => e.type === type,
        );
        // Move focus to the first entity of the same type and select
        document.getElementById(`cell-${firstEntityWithSameType?.id}`)?.focus();
        if (firstEntityWithSameType) {
          dispatch(
            setSelectedEntityIdsForAnnotationAction([
              firstEntityWithSameType.id,
            ]),
          );
        }
      }
    }
  };

  // This is to handle paste table cell info.
  const handlePaste = useCallback(
    (row: EntityInfo | undefined) => {
      if (clipboardEntity && row) {
        // Handle notes entity paste
        if (clipboardEntity.isExtra && row.isExtra) {
          const newEntityInfo = {
            ...row,
            isReviewed: true,
            isModified: true,
            extraEntityText: clipboardEntity.extraEntityText,
          };
          dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo));
        }
        // Handle normal entity paste
        if (!clipboardEntity.isExtra && !row.isExtra) {
          const segmentKeys = Object.keys(clipboardEntity.textSegments);
          const newEntityInfo = {
            ...row,
            entityText: clipboardEntity.entityText,
            isModified: true,
            textSegments: segmentKeys.reduce((acc, key) => {
              // Restrict copying of entityId
              return {
                ...acc,
                [key]: {
                  ...clipboardEntity.textSegments[key],
                  entityId: row.id,
                },
              };
            }, {}),
          };
          dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo));
        }
      }
    },
    [clipboardEntity],
  );

  const handleKeyBoardNavigation = useCallback(
    (event: React.KeyboardEvent, row: EntityInfo) => {
      // Filter entities that have the same type as the current row
      const entitiesWithSameType = getEntitiesWithSameType(row?.type);

      // Find the index of the currently focused cell within entities of the same type
      const focusedCellIndex = entitiesWithSameType.findIndex(
        (e) => e.id === row.id,
      );

      // Find the index of the current header based on the row type
      const headerIndex = headerRows.findIndex((h) => h.type === row.type);

      const navigateToCell = (id: string | undefined) => {
        if (id) {
          document.getElementById(`cell-${id}`)?.focus();
          dispatch(setSelectedEntityIdsForAnnotationAction([id]));
        }
      };

      switch (event.key) {
        case 'ArrowLeft': {
          const leftColumnEntities = selectedEntitiesByRowOrder?.filter(
            (e) => e.type === headerRows[headerIndex - 1]?.type,
          );
          navigateToCell(leftColumnEntities[focusedCellIndex]?.id);
          break;
        }
        case 'ArrowRight': {
          const rightColumnEntities = selectedEntitiesByRowOrder?.filter(
            (e) => e.type === headerRows[headerIndex + 1]?.type,
          );
          navigateToCell(rightColumnEntities[focusedCellIndex]?.id);
          break;
        }
        case 'ArrowUp':
          if (entitiesWithSameType[0].id === row.id) {
            document.getElementById(`cell-${row?.type}`)?.focus();
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                entitiesWithSameType.map((e) => e.id),
              ),
            );
          } else {
            navigateToCell(entitiesWithSameType[focusedCellIndex - 1]?.id);
          }
          break;
        case 'ArrowDown':
          navigateToCell(entitiesWithSameType[focusedCellIndex + 1]?.id);
          break;
      }
    },
    [getEntitiesWithSameType, headerRows, selectedEntitiesByRowOrder],
  );

  // This is to handle table cell key down event
  const handleTableCellKeyDown = useCallback(
    (event: React.KeyboardEvent, row: EntityInfo) => {
      if (!row.isExtra && selectedEntityIdsForAnnotation.length === 1) {
        // Handle copy for both mac and windows os
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyC') {
          setClipboardEntity(row);
          return;
        }

        // Handle copy for both mac and window os
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyV') {
          handlePaste(row);
          return;
        }
      }

      handleKeyBoardNavigation(event, row);
    },
    [
      selectedEntityIdsForAnnotation.length,
      handleKeyBoardNavigation,
      handlePaste,
    ],
  );

  const showResizeCursor = (isResizing: boolean) => {
    document.body.style.cursor = isResizing ? 'col-resize' : 'auto';
  };

  useEffect(() => {
    const tableElement = document.getElementById('table-modal-body');
    if (tableElement) {
      tableElement.onmousemove = handleResize;
      tableElement.onmouseup = handleResizeMouseUp;
      return () => {
        tableElement.onmousemove = null;
        tableElement.onmouseup = null;
      };
    }
  }, []);

  const handleResize = (e: MouseEvent) => {
    const type = columnToResizeRef.current;
    if (type) {
      const elm = document.getElementById(type);
      if (elm) {
        const newWidth = e.clientX - elm.getBoundingClientRect().left;
        const finalWidth =
          newWidth < MIN_TABLE_POPOVER_CELL_WITH
            ? MIN_TABLE_POPOVER_CELL_WITH
            : newWidth;
        elm.style.width = finalWidth + 'px';
      }
    }
  };

  const handleResizeMouseUp = () => {
    columnToResizeRef.current = '';
    setColumnTypeToResize('');
    showResizeCursor(false);
  };

  const startResizingColumn = useCallback(
    (e: React.MouseEvent, type: string) => {
      // Prevents text selection while initiating cell resizing
      e.preventDefault();
      columnToResizeRef.current = type;
    },
    [],
  );

  const showResizeLine = useCallback((rowType: string) => {
    setColumnTypeToResize(rowType);
    showResizeCursor(true);
  }, []);

  const hideResizeLine = useCallback(() => {
    if (!columnToResizeRef.current) {
      setColumnTypeToResize('');
      showResizeCursor(false);
    }
  }, [columnToResizeRef.current]);

  const isCellInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const tableBodyRect = document
      .getElementById('table-modal-body')
      ?.getBoundingClientRect();

    if (!tableBodyRect) {
      return false;
    }
    return (
      rect.top >= tableBodyRect.top &&
      rect.left >= tableBodyRect.left &&
      rect.right <= tableBodyRect.right &&
      rect.bottom <= tableBodyRect.bottom
    );
  };

  const openTextPopover = useCallback(
    (row: EntityInfo) => {
      if (allowEditingTask) {
        setSelectedPopoverCellId(row.id);
        dispatch(setSelectedEntityIdsForAnnotationAction([row.id]));
        const elem = document.getElementById(`cell-${row.id}`);
        if (elem && !isCellInViewport(elem)) {
          elem?.scrollIntoView();
        }
        setPopoverAnchorEl(elem);
      }
    },
    [allowEditingTask],
  );

  const selectNextCellInColumn = (row: EntityInfo) => {
    // Mark Entity as reviewed
    const newEntityInfo = { ...row, isReviewed: true };
    dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo));

    setPopoverAnchorEl(null);
    // Filter cells that have the same type as the currently selected cell
    const cellsWithSameType = selectedEntitiesByRowOrder?.filter(
      (e) => e.type === row.type,
    );
    // Find the index of the currently selected cell in the filtered list
    const selectedCellIndex = cellsWithSameType.findIndex(
      (cell) => cell.id === row?.id,
    );
    // Get the next cell in the list after the currently selected one
    const nextCell = cellsWithSameType[selectedCellIndex + 1];
    // If a valid index is found and there is a next cell, select the next cell
    if (selectedCellIndex !== -1 && nextCell) {
      dispatch(setSelectedEntityIdsForAnnotationAction([nextCell.id]));
      // Adding a delay before focusing the next cell
      // This ensures the textPopover has enough time to disappear
      // (it may interfere with focusing on the current element)
      setTimeout(() => document.getElementById(`cell-${nextCell.id}`)?.focus());
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: rowOrderInfoForTableEntities.length,
    getScrollElement: () => document.getElementById('table-modal-body'),
    estimateSize: () => (showNormalizedValues ? 75 : 30), // Estimate row height, adjust as needed
    overscan: 10, // Buffer elements
  });

  useEffect(() => {
    // recompute the dimensions
    rowVirtualizer.measure();
  }, [showNormalizedValues]);

  return (
    <TableContainer
      id={'table-modal-body'}
      className='enable-scroll-visibility'
      sx={{
        overflowY: 'scroll',
        flex: 1,
        paddingY: '20px',
      }}
    >
      <Table
        sx={{
          tableLayout: 'fixed',
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <TableHeader
          rows={headerRows}
          getHiddenRows={getHiddenHeaderRows}
          setDraggingElemId={setDraggingElemId}
          columnTypeToResize={columnTypeToResize}
          handleEntityHeaderClick={handleEntityHeaderClick}
          handleHeaderCellKeyDown={handleHeaderCellKeyDown}
          hideResizeLine={hideResizeLine}
          selectedEntitiesByRowOrder={selectedEntitiesByRowOrder}
          showResizeLine={showResizeLine}
          startResizingColumn={startResizingColumn}
        />
        <TableBody
          virtualItems={rowVirtualizer.getVirtualItems()}
          getChildEntitiesFromParentId={getChildEntitiesFromParentId}
          handleEntityRowClick={handleEntityRowClick}
          setDraggingElemId={setDraggingElemId}
          columnTypeToResize={columnTypeToResize}
          showResizeLine={showResizeLine}
          startResizingColumn={startResizingColumn}
          hideResizeLine={hideResizeLine}
          clipboardEntity={clipboardEntity}
          handlePaste={handlePaste}
          handleTableCellKeyDown={handleTableCellKeyDown}
          openTextPopover={openTextPopover}
          setClipboardEntity={setClipboardEntity}
          showNormalizedValues={showNormalizedValues}
          getEntitiesWithSameType={getEntitiesWithSameType}
        />

        {popoverAnchorEl && (
          <TableTextPopover
            onCopy={() => setClipboardEntity(selectedPopoverCell)}
            onPaste={() => handlePaste(selectedPopoverCell)}
            // Enable only if clipboardEntity is present and is not a notes entity
            enablePaste={!!clipboardEntity && !clipboardEntity.isExtra}
            selectedCell={selectedPopoverCell}
            anchorEl={popoverAnchorEl}
            selectNextCell={() =>
              selectNextCellInColumn(selectedPopoverCell as EntityInfo)
            }
            setAnchorEl={setPopoverAnchorEl}
          />
        )}
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableModalBodyV2);
