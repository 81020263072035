import React from 'react';
import {
  OrbySelect,
  OrbyMenuItem,
  OrbyTypography,
  OrbyColorPalette,
} from 'orby-ui/src';
import { ArchivedResourceType, ELLIPSIS_STYLE } from '../../../utils/constants';

export interface Props {
  selectedType: ArchivedResourceType;
  setSelectedType: (data: ArchivedResourceType) => void;
  width?: string;
  menuWidth?: string;
}

const ResourceTypeFilter: React.FC<Props> = ({
  selectedType,
  setSelectedType,
  width = '280px',
  menuWidth = '280px',
}) => {
  const FILTERS = [
    {
      label: 'All tasks',
      value: 'task',
    },
    {
      label: 'All workflows',
      value: 'workflow',
    },
    {
      label: 'All connectors',
      value: 'connector',
    },
  ];

  return (
    <>
      <OrbySelect
        value={selectedType}
        renderValue={(value) => {
          return (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
              sx={{
                ...ELLIPSIS_STYLE,
              }}
            >
              {FILTERS.find((r) => r.value === value)?.label}
            </OrbyTypography>
          );
        }}
        width={width}
        onChange={(event) => {
          const value = event.target.value as string;
          if (value) {
            setSelectedType(value as ArchivedResourceType);
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {FILTERS.map((filter) => {
          return (
            <OrbyMenuItem
              width={menuWidth}
              key={filter.value}
              title={filter.label}
              value={filter.value}
              isSelected={selectedType === filter.value}
            />
          );
        })}
      </OrbySelect>
    </>
  );
};

export default React.memo(ResourceTypeFilter);
