import { Box, IconButton } from '@mui/material';
import React, { useRef, FC, useState } from 'react';
import UploadIcon from '../../../../static/icons/upload-icon.svg';
import UploaderIcon from '../../../../static/icons/uploader_icon.svg';
import CheckCircle from '../../../../static/icons/check-circle.svg';
import CustomModal from '../../../../components/CustomModal';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  style: any;
  handleFileChange: any;
  error?: string | undefined;
  setError: (x: string | undefined) => void;
}

const TextFilePickerComponent: FC<Props> = ({
  style,
  handleFileChange,
  error,
  setError,
}) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(false);
    const files = e?.dataTransfer?.files;
    if (files?.length) {
      const file = files[0];
      setFile(file);
    }
    setError(undefined);
  };

  const downloadSampleFile = () => {
    // Create the CSV content
    const csvContent =
      'entity_name,entity_type,parent_entity_name\n' +
      'order date,date\n' +
      'amount,money\n' +
      'id,integer\n' +
      'decimal value,float\n' +
      'simba id,text\n' +
      'amount,money,line item\n' +
      'annotation,notes,line item';

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'samplefile.csv';

    // Trigger a click event on the download link
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Box sx={{ ...style }}>
        <IconButton
          disableRipple={false}
          sx={{
            borderRadius: 2,
            padding: '0 20px',
          }}
        >
          <OrbyTypography
            size='sm'
            weight='medium'
            color={OrbyColorPalette['blue-700']}
            onClick={() => {
              setOpen(true);
              setError(undefined);
            }}
          >
            <img
              alt='Upload from'
              style={{ height: '18px', paddingRight: '4px' }}
              src={UploadIcon}
            />{' '}
            Upload CSV
          </OrbyTypography>
        </IconButton>

        <input
          type='file'
          accept='.csv'
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(event) => {
            const file = event.target.files?.[0];
            if (file) {
              setFile(file);
            }
            (inputRef.current as unknown as HTMLInputElement).value = '';
            setError(undefined);
          }}
        />
      </Box>
      <CustomModal
        containerSx={{ width: '400px' }}
        open={open}
        closable={false}
        primaryButtonSx={{ maxWidth: '50%' }}
        heading={'Upload'}
        handleClose={() => {
          setOpen(false);
          setFile(null);
        }}
        primaryLabel={'Upload File'}
        secondaryLabel={'Cancel'}
        onPrimaryClick={() => {
          handleFileChange(file, setOpen, setFile);
        }}
        content={
          <>
            <OrbyTypography
              color={OrbyColorPalette['grey-500']}
              sx={{
                marginBottom: '20px',
              }}
            >
              Download the
              <span
                role='button'
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    downloadSampleFile();
                  }
                }}
                onClick={() => downloadSampleFile()}
                style={{
                  color: '#1570EF',
                  cursor: 'pointer',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                sample file
              </span>
              to get started
            </OrbyTypography>
            <Box
              tabIndex={0}
              role='none'
              component={'div'}
              onClick={() =>
                (inputRef.current as unknown as HTMLInputElement).click()
              }
              height={'189px'}
              sx={{
                cursor: 'pointer',
              }}
              border={'2px dashed #F8F8FF'}
              bgcolor={
                isHovered ? 'rgb(56 78 183 / 30%)' : 'rgb(56 78 183 / 9%)'
              }
              padding={'30px'}
              textAlign={'center'}
              id='drop-zone'
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <img
                alt='Uploader Icon'
                style={{ height: '54px' }}
                src={UploaderIcon}
              />

              <OrbyTypography
                weight='bold'
                size='md'
                sx={{
                  marginTop: '20px',
                }}
              >
                Drag & drop files or
                <span
                  style={{
                    color: OrbyColorPalette['purple-700'],
                    cursor: 'pointer',
                    marginLeft: '4px',
                    borderBottom: `1px solid ${OrbyColorPalette['purple-700']}`,
                  }}
                >
                  Browse
                </span>
              </OrbyTypography>

              <OrbyTypography
                size='xs'
                color={OrbyColorPalette['grey-600']}
                sx={{
                  marginTop: '20px',
                }}
              >
                Supported format: CSV
              </OrbyTypography>
            </Box>

            {file && (
              <Box
                paddingTop={'20px'}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <OrbyTypography>{file?.name}</OrbyTypography>
                <img
                  alt='Check Icon'
                  style={{ height: '18px' }}
                  src={CheckCircle}
                />
              </Box>
            )}
            {error && (
              <Box paddingTop={'10px'}>
                <OrbyTypography color={OrbyColorPalette['error-500']}>
                  {error}
                </OrbyTypography>
              </Box>
            )}
          </>
        }
      />
    </>
  );
};

export default TextFilePickerComponent;
