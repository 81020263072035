import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, ListSubheader } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyTextField,
  OrbyTypography,
  OrbySelect,
  OrbyMenuItem,
} from 'orby-ui/src';

import { Filter, ELLIPSIS_STYLE } from '../../../utils/constants';
import { useFetchWorkflowFilters } from '../../../hooks/useFetchWorkflowFilters';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { DEFAULT_WORKFLOW_VALUE } from '../TaskHelpers';
import _ from 'lodash';
import { GridSearchIcon } from '@mui/x-data-grid';

export interface Props {
  selectedWorkflows: Array<string>;
  setSelectedWorkflows: (data: Array<string>) => void;
  width?: string;
  menuWidth?: string;
}

const WorkflowFilter: React.FC<Props> = ({
  selectedWorkflows,
  setSelectedWorkflows,
  width = '160px',
  menuWidth = '320px',
}) => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  // WORKFLOWS OPTIONS
  const [workflowOptions, setWorkflowOptions] = useState<Filter[]>([]);
  const [options, setOptions] = useState<Filter[]>([]);

  /**
   * HANDLE WORKFLOW FILTER SEARCH
   */
  const handleWorkflowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = workflowOptions.filter(
      (opt) => opt.label.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setOptions(newOptions);
  };

  /**
   * HANDLE RENDER VALUE
   */
  const handleRenderValue = () => {
    if (selectedWorkflows[0] === DEFAULT_WORKFLOW_VALUE.value) {
      return (
        <Box overflow={'hidden'} title={DEFAULT_WORKFLOW_VALUE.label}>
          {renderLabel(DEFAULT_WORKFLOW_VALUE.label)}
        </Box>
      );
    } else if (selectedWorkflows.length === 1) {
      const value = workflowFilters.find(
        (w) => w.value === selectedWorkflows[0],
      )?.label;
      if (value) {
        return (
          <Box overflow={'hidden'} title={value}>
            {renderLabel(value)}
          </Box>
        );
      }
    } else {
      const value = (
        <Box
          overflow={'hidden'}
          display={'flex'}
          gap={'4px'}
          alignItems={'center'}
        >
          <OrbyTypography
            size='sm'
            weight='semibold'
            color={OrbyColorPalette['blue-700']}
          >
            {selectedWorkflows.length}
          </OrbyTypography>
          <OrbyTypography
            size='sm'
            weight='medium'
            color={OrbyColorPalette['grey-700']}
            sx={{
              paddingRight: '10px',
              ...ELLIPSIS_STYLE,
            }}
          >
            selected workflows
          </OrbyTypography>
        </Box>
      );
      return (
        <Box
          overflow={'hidden'}
          title={`${selectedWorkflows.length} workflows selected`}
        >
          {value}
        </Box>
      );
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <OrbyTypography
        size='sm'
        color={OrbyColorPalette['grey-900']}
        weight={'regular'}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </OrbyTypography>
    );
  };

  /**
   * Set Filter Options
   */
  const setFilterOptions = () => {
    const workflowOptions = workflowFilters.map((w) => ({
      value: w.value,
      label: w.label,
    }));
    setWorkflowOptions(workflowOptions);
    setOptions(workflowOptions);
  };

  /**
   * USE EFFECT
   */
  useEffect(() => {
    if (workflowFilters) {
      setFilterOptions();
    }
  }, [workflowFilters]);

  return (
    <>
      <OrbySelect
        onClose={() => {
          setFilterOptions();
        }}
        renderValue={handleRenderValue}
        multiple={true}
        value={selectedWorkflows}
        width={selectedWorkflows.length >= 2 ? '200px' : width}
        onChange={(event) => {
          const value = event.target.value as Array<string>;
          if (value) {
            if (
              value[value.length - 1] === DEFAULT_WORKFLOW_VALUE.value ||
              value.length === 0
            ) {
              setSelectedWorkflows([DEFAULT_WORKFLOW_VALUE.value]);
            } else {
              // REMOVE THE DEFAULT VALUE
              const filteredSelectedWorkflows = value.filter(
                (o) => o !== DEFAULT_WORKFLOW_VALUE.value,
              );
              setSelectedWorkflows(filteredSelectedWorkflows);
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OrbyMenuItem
          width={menuWidth}
          key={DEFAULT_WORKFLOW_VALUE.value}
          title={DEFAULT_WORKFLOW_VALUE.label}
          value={DEFAULT_WORKFLOW_VALUE.value}
          isSelected={selectedWorkflows.includes(DEFAULT_WORKFLOW_VALUE.value)}
        />
        <ListSubheader
          sx={{
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <OrbyTextField
            variant='flat'
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
              e.stopPropagation()
            }
            width={'100%'}
            tabIndex={0}
            name={'search'}
            disabled={false}
            placeholder='Search'
            startAdornment={
              <GridSearchIcon
                sx={{ color: OrbyColorPalette['grey-900'] }}
                fontSize='medium'
              />
            }
            onChange={_.debounce((event) => handleWorkflowSearch(event), 300)}
          />
        </ListSubheader>
        {workflowFiltersLoading ? (
          <Box
            display={'flex'}
            padding={'10px 0px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress
              sx={{ color: OrbyColorPalette['purple-900'] }}
              size={'30px'}
            />
          </Box>
        ) : options.length > 0 ? (
          options.map((workflow) => {
            return (
              <OrbyMenuItem
                width='320px'
                key={workflow.value}
                title={workflow.label}
                value={workflow.value}
                isSelected={selectedWorkflows.includes(workflow.value)}
              />
            );
          })
        ) : (
          <Box
            display={'flex'}
            padding={'10px 0px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
            >
              No workflows found
            </OrbyTypography>
          </Box>
        )}
      </OrbySelect>
    </>
  );
};

export default React.memo(WorkflowFilter);
