import React, { memo, useMemo, useState } from 'react';
import { Avatar, Box, Tooltip } from '@mui/material';
import { colorValuesUtil } from '../../utils/ColorValuesUtil';
import { addAlpha, getInitials } from '../../utils/helpers';
import { ELLIPSIS_STYLE } from '../../utils/constants';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  email: string;
  imageUrl?: string;
  fullName?: string;
  collapse: boolean;
}

const DrawerUserCard: React.FC<Props> = ({
  email,
  imageUrl,
  fullName,
  collapse,
}) => {
  const [imgError, setImgError] = useState(false);
  const displayName = fullName?.trim() || email;

  const initials = useMemo(() => getInitials(displayName), [displayName]);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        minHeight: '44px',
      }}
      width={'100%'}
    >
      <Box alignItems={'center'} display={'flex'}>
        {imgError || !imageUrl ? (
          <Avatar
            alt='user-initials'
            sx={{
              width: 32,
              height: 32,
              fontSize: 20,
              bgcolor: addAlpha(colorValuesUtil.getColor(email), 0.4),
            }}
          >
            <OrbyTypography
              size='md'
              weight='semibold'
              color={OrbyColorPalette['white-0']}
            >
              {initials}
            </OrbyTypography>
          </Avatar>
        ) : (
          <Avatar
            alt='user-photo'
            sx={{
              width: 32,
              height: 32,
              fontSize: 20,
            }}
            slotProps={{
              img: { referrerPolicy: 'no-referrer' }, // this is added for security purpose
            }}
            src={imageUrl}
            onError={() => setImgError(true)}
          />
        )}
      </Box>
      <Tooltip
        title={
          <Box>
            <OrbyTypography
              size='xs'
              weight='semibold'
              color={OrbyColorPalette['grey-700']}
            >
              {displayName}
            </OrbyTypography>
            {fullName?.trim() && (
              <OrbyTypography
                size='xs'
                weight='semibold'
                color={OrbyColorPalette['grey-700']}
              >
                {email}
              </OrbyTypography>
            )}
          </Box>
        }
        arrow
        placement='right-end'
        PopperProps={{
          sx: {
            '& .MuiTooltip-arrow': { color: OrbyColorPalette['white-0'] },
            '& .MuiTooltip-tooltip': {
              backgroundColor: OrbyColorPalette['white-0'],
              borderRadius: '8px',
              padding: '8px 12px',
            },
          },
        }}
      >
        <Box
          sx={{
            display: collapse ? 'none' : 'flex',
            flexDirection: 'column',
            minWidth: '0px',
            transition: 'all 0.1s ease',
            opacity: collapse ? 0 : 1,
            pointerEvents: collapse ? 'none' : 'unset',
          }}
        >
          <OrbyTypography
            size='md'
            weight='semibold'
            color={OrbyColorPalette['white-0']}
            sx={{ ...ELLIPSIS_STYLE, textWrap: 'nowrap' }}
          >
            {displayName}
          </OrbyTypography>
          {fullName?.trim() && (
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-400']}
              sx={{ ...ELLIPSIS_STYLE, textWrap: 'nowrap' }}
            >
              {email}
            </OrbyTypography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default memo(DrawerUserCard);
