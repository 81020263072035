import { automationMiningSelector } from './app.selectors';
import { createSelector } from 'reselect';

export const mimeTypeSelector = createSelector(
  [automationMiningSelector],
  (automationMiningSelector) => automationMiningSelector.mimeType,
);

export const activityGraphSelector = createSelector(
  [automationMiningSelector],
  (automationMiningSelector) => automationMiningSelector.activityGraph,
);

export const loadingActivityGraphSelector = createSelector(
  [automationMiningSelector],
  (automationMiningSelector) => automationMiningSelector.loadingActivityGraph,
);

export const activityGraphLoadedSelector = createSelector(
  [automationMiningSelector],
  (automationMiningSelector) => automationMiningSelector.activityGraphLoaded,
);
