import { Box } from '@mui/material';
import { debounce } from 'lodash';
import {
  Document,
  DocumentPage,
  DocumentPageToken,
} from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  copySelectedEntity,
  openAddRowModalAction,
  setAddLocationAction,
  setLastLocatedEntityTypeAction,
  setSelectedEntityIdsForAnnotationAction,
  setTokenListToHighlightAction,
  setSuggestionDataAction,
  updateEntityInfoForTableAnnotationAction,
} from '../../../../../../../redux/actions/review_task.action';
import {
  EntityInfo,
  TextSegmentInfo,
} from '../../../../../../../redux/reducers/review_task.reducer';
import {
  addLocationSelector,
  allowEditingTaskSelector,
  isMultipleCellsSelectedSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoSelector,
  suggestedTableEntitiesInfoSelector,
  suggestionDataSelector,
  taskForReviewSelector,
  tokenListPageToHighlightSelector,
  tokenListToHighlightSelector,
  tokensInDocumentSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import {
  doesStyleHaveValues,
  getPdfPageIndexForBoundingbox,
  getYCoordForCurrentPage,
  makeClickResizableBox,
  makeDraggableDiv,
  makeResizableDiv,
} from '../../../../../../../utils/BoundingBoxUtils';
import { boxPositionValuesUtilV2 } from '../../../../../../../utils/BoxPositionValuesUtilV2';
import {
  getNewTextSegmentId,
  getNormalizedVertices,
  getTextFromTextSegments,
  goToVisibleElement,
} from '../../../../../../../utils/ReviewTaskUtils';
import { getCellVerticesForColumnAnnotation } from '../../../../../../../utils/SmartAnnotationUtil';
import { tokenValuesUtil } from '../../../../../../../utils/TokenValuesUtil';
import {
  getEntityInfoWithHighlightedTextSegments,
  getPositionToHighlightTokens,
  getVerticesAndPageOfHighlightedTokens,
} from '../../../../../../../utils/TokensHighlightUtils';
import { PADDING_BW_PDF_PAGES } from '../../../../../../../utils/constants';
import {
  addAlpha,
  getSelectedTaskDocument,
} from '../../../../../../../utils/helpers';

interface Props {
  scale: number;
  currentPage: number;
}

const TableLayoutBoundingBox: React.FC<Props> = ({ scale, currentPage }) => {
  const dispatch = useDispatch();
  const selectedTask = useSelector(taskForReviewSelector);
  const tokensInDocument = useSelector(tokensInDocumentSelector);
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedEntityIdsForAnnotationRef = useRef(
    selectedEntityIdsForAnnotation,
  );
  const selectedTableEntitiesInfo =
    useSelector(selectedTableEntitiesInfoSelector) ?? [];
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const suggestedTableEntitiesInfo =
    useSelector(suggestedTableEntitiesInfoSelector) ?? [];
  const suggestionData = useSelector(suggestionDataSelector);
  const selectedParentEntityInfoRef = useRef(selectedParentEntityInfo);
  const selectedTableEntitiesInfoRef = useRef(selectedTableEntitiesInfo);
  const isMultipleCellsSelected = useSelector(isMultipleCellsSelectedSelector);
  const isMultipleCellsSelectedRef = useRef(isMultipleCellsSelected);
  const addLocation = useSelector(addLocationSelector);
  const addLocationRef = useRef(addLocation);
  const refBox = useRef<HTMLElement>(null);
  const resizerSize = 10 * scale + 'px';
  const resizerStyles = { width: resizerSize, height: resizerSize };
  const resizerPosition = 1 - (10 * scale) / 2;
  const resizerAdjustment = 5 * scale;
  const padding = PADDING_BW_PDF_PAGES / scale;
  const highlightedTokenList = useSelector(tokenListToHighlightSelector);
  const highlightedTokenListPage = useSelector(
    tokenListPageToHighlightSelector,
  );
  const [stopHighlight, setStopHighlight] = useState(false);

  // This function returns the page and vertices as per that page when a bounding box is drawn over pdf
  // Since annotated bounding box is independent of the page and can be drawn on any page from the
  // reference page on which it previously lies, this calculation is necessary to get exact data for page
  const getPageAndVerticesFromInputBoxVertices = (
    vertices: Vertex[],
    selectedTaskDocument?: Document,
  ): { page: number; pageCorrespondingVertices: Vertex[] } => {
    const page = getPdfPageIndexForBoundingbox(
      selectedTaskDocument?.pages || [],
      vertices[0].y!,
      padding,
      currentPage,
    );
    const pageCorrespondingVertices = vertices.map((v) => {
      const ver = Vertex.create(v);
      ver.y = getYCoordForCurrentPage(
        selectedTaskDocument?.pages || [],
        ver.y!,
        padding,
        currentPage,
      );
      return ver;
    });
    return { page, pageCorrespondingVertices };
  };

  const debouncedUpdateEntityInfo = useCallback(
    debounce((newEntityInfo, isSuggestion) => {
      dispatch(
        updateEntityInfoForTableAnnotationAction(
          newEntityInfo as EntityInfo,
          true,
          isSuggestion,
          false,
          true,
          true,
        ),
      );
    }, 300),
    [],
  );

  // This function updates the text segment of entities using entity id when annotated
  // using nested entity table layout.
  // It takes entity Id whose info has to be updated
  // And vertices which has to be updated and using this the text that is captured by the box
  // is also calculated
  const updateEntityVertices = (
    entityId: string,
    vertices: Vertex[],
    page: number,
    document?: Document,
    isSuggestion?: boolean,
    excludeFromHistory = false,
    debounceHistory = false,
  ) => {
    // If isSuggestion true set data to suggestedTableEntitiesInfo
    const entitiesInfo = isSuggestion
      ? suggestedTableEntitiesInfo
      : selectedTableEntitiesInfoRef.current;
    const entityInfo = entitiesInfo?.find((e) => e.id === entityId);
    const pdfBoxHeight = document?.pages![page].image!.height || 0;
    const pdfBoxWidth = document?.pages![page].image!.width || 0;

    const tokensInPage = tokensInDocument![page];
    const collidingTokens = boxPositionValuesUtilV2.getCollidingTokens(
      tokensInPage,
      vertices,
    );
    const textForDocument = document?.text || '';
    const textCapturedByBox =
      tokenValuesUtil.getTextFromTokens(collidingTokens, textForDocument) || '';
    let newEntityInfo = {
      ...entityInfo,
      isModified: true,
    };
    if (addLocationRef.current) {
      // append new segment
      const newTextSegmentInfo: TextSegmentInfo = {
        id: getNewTextSegmentId(),
        page: page,
        modifiedPage: page,
        entityId: entityInfo?.id || '',
        vertices: vertices,
        pageCorrespondingVertices: vertices,
        normalizedVertices: getNormalizedVertices(
          pdfBoxHeight,
          pdfBoxWidth,
          vertices,
        ),
        text: textCapturedByBox,
        textFromTokens: textCapturedByBox,
        startIndex:
          collidingTokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
            ?.startIndex || 0,
        endIndex:
          collidingTokens?.[collidingTokens.length - 1]?.layout?.textAnchor
            ?.textSegments?.[0]?.endIndex || 0,
        lastAdjustedPadding: padding,
      };
      newEntityInfo = {
        ...newEntityInfo,
        textSegments: {
          ...newEntityInfo.textSegments,
          [newTextSegmentInfo.id]: newTextSegmentInfo,
        },
        entityText: newEntityInfo.entityText + ' ' + newTextSegmentInfo.text,
      };
    } else {
      // override all text segments with new textSegment
      const newTextSegmentInfo: TextSegmentInfo = {
        id: '0',
        page: page,
        modifiedPage: page,
        entityId: entityInfo?.id || '',
        vertices: vertices,
        pageCorrespondingVertices: vertices,
        normalizedVertices: getNormalizedVertices(
          pdfBoxHeight,
          pdfBoxWidth,
          vertices,
        ),
        text: textCapturedByBox,
        textFromTokens: textCapturedByBox,
        startIndex:
          collidingTokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
            ?.startIndex || 0,
        endIndex:
          collidingTokens?.[collidingTokens.length - 1]?.layout?.textAnchor
            ?.textSegments?.[0]?.endIndex || 0,
        lastAdjustedPadding: padding,
      };
      newEntityInfo = {
        ...newEntityInfo,
        textSegments: {
          [newTextSegmentInfo.id]: newTextSegmentInfo,
        },
        entityText: getTextFromTextSegments({
          [newTextSegmentInfo.id]: newTextSegmentInfo,
        }),
      };
    }
    dispatch(setAddLocationAction(false));
    dispatch(
      updateEntityInfoForTableAnnotationAction(
        newEntityInfo as EntityInfo,
        true,
        isSuggestion,
        excludeFromHistory,
        undefined,
        true,
      ),
    );

    // Update history after a delay when 'debounceHistory' is true
    // This applies specifically to drag/drop and resize bbox actions
    if (debounceHistory) {
      debouncedUpdateEntityInfo({ ...newEntityInfo }, isSuggestion);
    }
  };

  // It draws and update b-box for selected child entities when user click to draw/ drag to draw a
  // bounding box. It splits the vertices into equal parts as per the number of entities selected for
  // annotation when user stops annotating the bounding box
  // It takes styles which helps to determine the position of b-box to calculate vertices. When any bounding box
  // is drawn, there are some styles associated with it using which we calculate top, left and width, height
  // of the box to determine its position in the document which helps to identify vertices
  // A boolean variable to determine if the annotation has been done by the user. When user draws bounding box
  // it updates it's info in real time with its value false and when the user stops drawing the box (here when user
  // lifts the mouse - mouseup) its value becomes true and then we compute the value of each entity selected
  const annotateMultipleEntitiesWithOneBox = (
    styles: CSSStyleDeclaration,
    finishAnnotation = false,
  ) => {
    // If the styles values are empty no calculation should be done
    if (!doesStyleHaveValues(styles)) return;

    // ALL THE POSITION AND DIMENSIONS ARE ADJUSTED BACK TO ORIGINAL USING SCALE
    // e.g IF SCALE IS 0.6, ACTUAL PDF HEIGHT = 2200px, DISPLAYED HEIGHT OF DOCUMENT WILL BE 2200 * 0.6 = 1320px
    // THE CALCULATED STYLES WILL BE AS PER 1320px. TO STORE THEM ACC TO 2200px, WE DIVIDE BY 0.6
    const left = parseInt(styles.left, 10) / scale;
    const top = parseInt(styles.top, 10) / scale;
    const width = parseInt(styles.width, 10) / scale;
    const height = parseInt(styles.height, 10) / scale;
    const topLeft = Vertex.create({ x: left, y: top });
    const topRight = Vertex.create({ x: left + width, y: top });
    const bottomLeft = Vertex.create({ x: left, y: top + height });
    const bottomRight = Vertex.create({ x: left + width, y: top + height });

    if (finishAnnotation) {
      const vertices = [topLeft, topRight, bottomRight, bottomLeft];
      const selectedTaskDocument =
        getSelectedTaskDocument(selectedTask)!.documents![0];
      const pageAndVertices = getPageAndVerticesFromInputBoxVertices(
        vertices,
        selectedTaskDocument,
      );
      const cellAnnotation = getCellVerticesForColumnAnnotation(
        pageAndVertices.pageCorrespondingVertices,
        pageAndVertices.page,
        selectedTaskDocument,
      );
      let index = 0;
      const selectedIds = selectedEntityIdsForAnnotationRef.current ?? [];
      let isExtraCellsAnnotated = false;
      if (isMultipleCellsSelectedRef.current) {
        // Get selected row
        const selectedRow = selectedTableEntitiesInfoRef.current?.find(
          (e) => e.id === selectedEntityIdsForAnnotationRef.current![0],
        );
        // Get all entities with same type
        const entitesWithSameType =
          selectedTableEntitiesInfoRef.current?.filter(
            (e) => e.type === selectedRow?.type,
          );
        const isAllEntitiesSelected = !entitesWithSameType?.some(
          (e) => !selectedEntityIdsForAnnotationRef.current!.includes(e.id),
        );
        // Check if all entities with same type are selected and annotated cells are more than selectedIds
        if (
          isAllEntitiesSelected &&
          cellAnnotation &&
          cellAnnotation.length > 4 * selectedIds.length &&
          selectedParentEntityInfoRef.current
        ) {
          isExtraCellsAnnotated = true;
          // Get the count of extra annotated cells
          const extraCellCount =
            cellAnnotation.slice(4 * selectedIds.length).length / 4;
          // Add rows based on extra annotated cells count
          for (let cellIndex = 0; cellIndex < extraCellCount; cellIndex++) {
            // Here we copy entities in suggestedTableEntitiesInfo key in reducer by passing true for isSuggestion in 2nd parameter
            dispatch(
              copySelectedEntity(
                selectedParentEntityInfoRef.current.id,
                true,
                true,
              ),
            );
          }
          // Get extra cells
          const extraCells = cellAnnotation?.slice(4 * selectedIds.length);
          if (extraCells?.length) {
            // Set data for the suggested rows
            dispatch(
              setSuggestionDataAction({
                cells: extraCells,
                page: pageAndVertices.page,
                entityType: selectedRow?.type || '',
              }),
            );
          }
        }
        // Check if all entities are selected and no extra cells annotated
        if (isAllEntitiesSelected && !isExtraCellsAnnotated) {
          dispatch(setLastLocatedEntityTypeAction(selectedRow?.type));
        }
        // Check if no extra cells added
        if (!isExtraCellsAnnotated) {
          dispatch(setSelectedEntityIdsForAnnotationAction([]));
        }
      }
      for (const id of selectedIds) {
        // If table OCR data if present and successfully calculated, we utilize it
        if (
          cellAnnotation &&
          cellAnnotation.length &&
          (selectedIds.length > 1 || isExtraCellsAnnotated)
        ) {
          // It handles the following cases:
          // 1) If no. of entities selected = no. of cells annotated, fills all entities with respective cells
          // 2) If no. of entities < no. of cells annotated, fills entities with cells info and ignore extra cell info
          // 3) If no. of entities > no. of cells annotated, fills initial entities till no. of cells reached and
          //    leave rest remaining entities as it is
          // Checking if no. of cells annotated are less than no. of entities selected
          if (cellAnnotation.length < 4 * (index + 1)) break;

          updateEntityVertices(
            id,
            cellAnnotation.slice(index * 4, (index + 1) * 4), // Slice the vertices for the current entity index
            pageAndVertices.page,
            selectedTaskDocument,
            false,
            !(cellAnnotation.length < 4 * (index + 2)), // if there is no vertices present for next annotated cell then include the action for history
          );
        } else {
          // If outside table split the vertices into equal parts based on the number of selectedEntityIds for annotation
          const splitVertices =
            boxPositionValuesUtilV2.splitVerticesIntoEqualParts(
              vertices,
              selectedEntityIdsForAnnotationRef.current!.length,
            );
          const pageAndVertices = getPageAndVerticesFromInputBoxVertices(
            splitVertices.slice(index * 4, (index + 1) * 4), // Slice the split vertices for the current entity index
            selectedTaskDocument,
          );
          updateEntityVertices(
            id,
            pageAndVertices.pageCorrespondingVertices,
            pageAndVertices.page,
            selectedTaskDocument,
            false,
            true,
            true,
          );
        }
        index++;
      }
    }
  };

  const setHighlightedTokens = (styles?: {
    startX: string;
    startY: string;
    endX: string;
    endY: string;
  }) => {
    // If styles are not provided, stop highlighting and return
    if (!styles) {
      setStopHighlight(true);
      return;
    }
    // If styles are provided, continue highlighting
    setStopHighlight(false);
    const { pageCorrespondingStyles, page } =
      getVerticesAndPageOfHighlightedTokens(
        styles,
        Task.create(selectedTask),
        scale,
      );
    // Dispatch an action to set the token list to highlight
    dispatch(setTokenListToHighlightAction(pageCorrespondingStyles, page));
  };

  // THIS IS TO ADD TEXT SEGMENTS TO THE ENTITY INFO BASED ON HIGHLIGHTED TOKENS, AND UPDATE ENTITY INFO
  useEffect(() => {
    if (stopHighlight && highlightedTokenList?.length) {
      const entityInfo = getEntityInfoWithHighlightedTextSegments(
        highlightedTokenList as DocumentPageToken[],
        {
          ...(selectedTableEntitiesInfo.find(
            (e) => e.id === selectedEntityIdsForAnnotation![0],
          ) as EntityInfo),
        },
        selectedTask as Task,
        highlightedTokenListPage as number,
        undefined,
        addLocationRef.current,
        true,
      );
      const newEntityInfo = {
        ...entityInfo,
        isModified: true,
      };
      dispatch(setAddLocationAction(false));
      dispatch(
        dispatch(
          updateEntityInfoForTableAnnotationAction(
            newEntityInfo as EntityInfo,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          ),
        ),
      );
      dispatch(setTokenListToHighlightAction());
    }
  }, [stopHighlight]);

  // THIS MAKES THE SELECTED BOUNDING BOX RESIZABLE, DRAGGABLE AND MOVABLE ACROSS PAGE ON CLICK
  useEffect(() => {
    if (!allowEditingTask) return;
    const ele = refBox.current;
    if (ele) {
      let heightToRemove = 0;
      const pages: DocumentPage[] =
        getSelectedTaskDocument(selectedTask)!.documents![0].pages!;
      for (let page = 0; page < currentPage; page++) {
        heightToRemove +=
          pages[page].image!.height! * scale + PADDING_BW_PDF_PAGES;
      }
      makeClickResizableBox(
        ele,
        annotateMultipleEntitiesWithOneBox,
        heightToRemove,
        isMultipleCellsSelected,
      );
      makeResizableDiv(ele, annotateMultipleEntitiesWithOneBox);
      makeDraggableDiv(ele, annotateMultipleEntitiesWithOneBox);
      getPositionToHighlightTokens(setHighlightedTokens);
    }
  }, [currentPage, allowEditingTask, isMultipleCellsSelected]);

  useEffect(() => {
    selectedEntityIdsForAnnotationRef.current = selectedEntityIdsForAnnotation;
    const ele = refBox.current;
    const selectedEntity = selectedTableEntitiesInfo?.find(
      (info) => info.id === selectedEntityIdsForAnnotation![0],
    );
    const selectedEntitySegments = Object.values(
      selectedEntity?.textSegments || {},
    );
    // Check if only one entity is selected
    if (ele?.style && selectedEntityIdsForAnnotation!.length === 1) {
      const segment = selectedEntitySegments[0];
      const textSegmentBoxPosition =
        boxPositionValuesUtilV2.getBoxPositionValues(
          segment?.pageCorrespondingVertices || segment?.vertices,
          scale,
        );
      // Set the style of the bounding box according to textSegmentBoxPosition style
      ele.style.width = textSegmentBoxPosition.width + 'px';
      ele.style.height = textSegmentBoxPosition.height + 'px';
      ele.style.top = textSegmentBoxPosition.top + 'px';
      ele.style.left = textSegmentBoxPosition.left + 'px';

      if (textSegmentBoxPosition.top >= 0 && textSegmentBoxPosition.left >= 0) {
        goToVisibleElement('bounding-box');
      }
    } else if (ele?.style && !isMultipleCellsSelected) {
      // If multiple entities are selected or no entity is selected
      const segment = selectedEntitySegments[0];
      ele.style.width = '0px';
      ele.style.height = '0px';
      ele.style.top = '0px';
      ele.style.left = '-5px';

      goToVisibleElement(`${segment?.entityId}-${segment?.id}`);
    }
  }, [
    selectedEntityIdsForAnnotation,
    isMultipleCellsSelected,
    selectedTableEntitiesInfo,
  ]);

  useEffect(() => {
    isMultipleCellsSelectedRef.current = isMultipleCellsSelected;
  }, [isMultipleCellsSelected]);

  useEffect(() => {
    addLocationRef.current = addLocation;
  }, [addLocation]);

  useEffect(() => {
    // Check if suggestionData and suggestedTableEntitiesInfo available
    if (suggestionData && suggestedTableEntitiesInfo.length) {
      const selectedTaskDocument =
        getSelectedTaskDocument(selectedTask)!.documents![0];
      // Filter out the entities that match selected entity type and then add cell data to them
      suggestedTableEntitiesInfo
        .filter((info) => suggestionData.entityType === info.type)
        .forEach((entityInfo, i, arr) => {
          updateEntityVertices(
            entityInfo.id,
            suggestionData.cells.slice(i * 4, (i + 1) * 4),
            suggestionData.page,
            selectedTaskDocument,
            true,
            i !== arr.length - 1, // Only record last action for history
          );
        });
      // Open Add row confirmation Popup
      dispatch(openAddRowModalAction(true, true));
    }
  }, [suggestionData]);

  useEffect(() => {
    selectedTableEntitiesInfoRef.current = selectedTableEntitiesInfo;
  }, [selectedTableEntitiesInfo]);

  useEffect(() => {
    selectedParentEntityInfoRef.current = selectedParentEntityInfo;
  }, [selectedParentEntityInfo]);

  return (
    <Box className='wrapper'>
      <Box
        id={'bounding-box'}
        className='resizable draggable'
        ref={refBox}
        bgcolor={addAlpha('#1669F7', 0.4)}
        zIndex={2}
      >
        <Box className='resizers'>
          <Box
            style={{
              ...resizerStyles,
              left: resizerPosition,
              top: resizerPosition,
            }}
            className='resizer top-left'
          />
          <Box
            style={{
              ...resizerStyles,
              right: resizerPosition,
              top: resizerPosition,
            }}
            className='resizer top-right'
          />
          <Box
            style={{
              ...resizerStyles,
              left: resizerPosition,
              bottom: resizerPosition,
            }}
            className='resizer bottom-left'
          />
          <Box
            style={{
              ...resizerStyles,
              right: resizerPosition,
              bottom: resizerPosition,
            }}
            className='resizer bottom-right'
          />
        </Box>
        <Box
          style={{
            left: resizerAdjustment,
            right: resizerAdjustment,
            height: resizerAdjustment,
          }}
          className='resizer top'
        />
        <Box
          style={{
            top: resizerAdjustment,
            bottom: resizerAdjustment,
            width: resizerAdjustment,
          }}
          className='resizer right'
        />
        <Box
          style={{
            left: resizerAdjustment,
            right: resizerAdjustment,
            height: resizerAdjustment,
          }}
          className='resizer bottom'
        />
        <Box
          style={{
            top: resizerAdjustment,
            bottom: resizerAdjustment,
            width: resizerAdjustment,
          }}
          className='resizer left'
        />
      </Box>
    </Box>
  );
};

export default React.memo(TableLayoutBoundingBox);
