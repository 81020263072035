/**
 * The purpose of this file is to show
 * the Automation or Declining process banner
 * on the Review Page
 */
import React, { FC, memo } from 'react';
import AutomateIcon from '../../../../static/icons/automate.svg';
import DoneIcon from '../../../../static/icons/done.svg';
import CustomTypography from '../../../../components/CustomTypography';
import { Box } from '@mui/material';
import { AutomationProgressStatus } from '../../../../utils/constants';

interface ReviewPageProcessToastProps {
  open: boolean;
  taskAutomated: boolean;
  automationProgressStatus: AutomationProgressStatus;
}

const ReviewPageProcessToast: FC<ReviewPageProcessToastProps> = ({
  open,
  taskAutomated,
  automationProgressStatus,
}) => {
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'start',
        paddingTop: '70px',
        background: '#0000001c',
        zIndex: 999,
      }}
    >
      <Box
        width={'600px'}
        zIndex={990}
        bgcolor={taskAutomated ? '#F2FFEB' : '#CFE1FF'}
        padding={'14px'}
        display={'flex'}
        alignItems={'center'}
        gap={'18px'}
        border={`1.5px solid ${taskAutomated ? '#72BE47' : '#1669F7'}`}
        borderRadius={'0 0 8px 8px'}
      >
        <Box
          component={'img'}
          src={taskAutomated ? DoneIcon : AutomateIcon}
          padding={'6px'}
          bgcolor={taskAutomated ? '#72BE47' : '#1669F7'}
          borderRadius={'100%'}
        />
        <CustomTypography>
          {taskAutomated
            ? `${
                automationProgressStatus ===
                AutomationProgressStatus.DECLINING_TASK
                  ? 'Declining'
                  : 'Automation'
              } Complete! You can check the updates in your applications`
            : `${
                automationProgressStatus ===
                AutomationProgressStatus.DECLINING_TASK
                  ? 'Declining'
                  : 'Automation'
              } In Progress ...`}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default memo(ReviewPageProcessToast);
