import { Box, FormControl } from '@mui/material';
import { FormikValues, getIn } from 'formik';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import { Operator } from 'protos/pb/v1alpha2/connector';
import React, { FC } from 'react';

interface Props {
  name: string;
  formik: FormikValues;
  width: string;
}

const OperatorValues = [
  {
    value: Operator.GREATER_THAN,
    label: '>',
  },
  {
    value: Operator.LESS_THAN,
    label: '<',
  },
  {
    value: Operator.EQUAL,
    label: '=',
  },
];

export const ConditionOperatorSelect: FC<Props> = ({ name, formik, width }) => {
  return (
    <FormControl
      size='small'
      sx={{ width: width }}
      error={Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))}
    >
      <Box id={'role-label'} display={'none'} tabIndex={-1}>
        {getIn(formik.values, name)
          ? getIn(formik.values, name)?.name + 'selected'
          : 'Please select'}
      </Box>
      <OrbySelect
        width={width}
        aria-hidden={false}
        value={formik.values[name]}
        renderValue={() => {
          const renderValue = getIn(formik.values, name) || null;
          const renderText =
            OperatorValues.find((v) => v.value === renderValue)?.label || '';
          return (
            <OrbyTypography
              size={renderText ? 'md' : 'sm'}
              color={
                renderText
                  ? OrbyColorPalette['grey-900']
                  : OrbyColorPalette['grey-500']
              }
              weight={renderText ? 'medium' : 'regular'}
            >
              {renderText}
            </OrbyTypography>
          );
        }}
        onChange={(e) => {
          formik.setFieldValue(name, e.target.value);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {OperatorValues.map((operator) => (
          <OrbyMenuItem
            width={width}
            key={operator.value}
            value={operator.value}
            title={operator.label}
          />
        ))}
      </OrbySelect>
    </FormControl>
  );
};

export default ConditionOperatorSelect;
